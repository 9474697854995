function stringToColor(string) {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    
    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    
    return color;
}

function stringAvatar(name) {
    if (!name) {
        return {
            sx: {bgcolor: "#ccc"}, children: "?", 
        }
    }

    const nameParts = name.split(' ').filter(part => part.trim().length > 0); 

    return {
        sx: {bgcolor: stringToColor(name)},
        children: (nameParts.length === 0) ? "?" : (nameParts.length === 1) ? 
            nameParts[0][0].toUpperCase() 
        : 
            `${nameParts[0][0].toUpperCase()}${nameParts[1][0].toUpperCase()}`,
    }
}

function stringToAvatarEmail(email) {
    console.log("email nnnn", email);
    
    if (typeof email !== 'string' || !email.includes('@')) {
        throw new Error('Invalid email format');
    }

    // Extract the part before the "@" symbol
    const namePart = email.split('@')[0];
    const nameParts = namePart.split('.');

    return {
        sx: {
            bgcolor: stringToColor(email), // Using the entire email to get the color
        },
        children: nameParts.length === 1 
            ? `${nameParts[0][0].toUpperCase()}` 
            : `${nameParts[0][0].toUpperCase()}${nameParts[1][0].toUpperCase()}`,
    };
}

export { 
    stringAvatar,
    stringToColor,
    stringToAvatarEmail
}