import routes from "../../common/api-routes";
import {HttpCustomPublicGet, HttpAuthDelete, HttpAuthGet, HttpAuthPost, HttpPublicPost2, HttpCustomAuthDelete, HttpAuthPut} from "../../../../../core/services/common/HttpRequestBuilder";

const getMemberList = (parameters) => {
    let result = HttpAuthGet(routes.LIST_MEMBERS, parameters);

    return result;
}

const inviteMember = (payload) => {
    let result = HttpAuthPost(routes.INVITE_MEMBER, payload);

    return result;
}

const acceptInvite = (formData) => {
    let result = HttpAuthPost(routes.ACCEPT_INVITE, formData);

    return result;
}

const getInvitedMemberList = (parameters) => {
    let result = HttpAuthGet(routes.LIST_INVITED_MEMBERS, parameters);

    return result;
}

const getViewMemberDetails = (parameters) => {
    let result = HttpAuthGet(routes.GET_VIEW_MEMBER_DETAILS, parameters);

    return result;
}

const countMembers = (parameters) => {
    let result = HttpAuthGet(routes.COUNT_MEMBERS, parameters);

    return result;
}

const listMembersEmail = (parameters) => {
    let result = HttpAuthGet(routes.LIST_MEMBERS_EMAIL, parameters);

    return result;
}

const declineInvite = (formData) => {
    let result = HttpPublicPost2(routes.DECLINE_INVITE, formData);

    return result;
}

const deleteMember = (parameters) => {
    let result = HttpAuthDelete(routes.DELETE_MEMBER, parameters);

    return result;
}

const getInvitationDetails = (parameters) => {
    let result = HttpCustomPublicGet(routes.GET_INVITATION_DETAILS, parameters);

    return result;
}

const cancelInvitation = (formData) => {
    let result = HttpCustomAuthDelete(routes.CANCEL_INVITATION, formData);

    return result;
}

const resendInvitation = (parameters) => {
    let result = HttpAuthGet(routes.RESEND_INVITATION, parameters);

    return result;
}

const countRemainingWorkspaceMembers = (parameters) => {
    let result = HttpAuthGet(routes.COUNT_REMAINING_WORKSPACE_MEMBERS, parameters);

    return result;
}

const updateWorkspaceMemberStatus = (payload) => {
    let parameters = null;
    let result = HttpAuthPut(routes.UPDATE_WORKSPACE_MEMBER_STATUS, parameters, payload);

    return result;
}

const updateWorkspaceMemberRole = (payload) => {
    let parameters = null;
    let result = HttpAuthPut(routes.UPDATE_WORKSPACE_MEMBER_ROLE, parameters, payload);

    return result;
}

const getWSGuestSigneeList = (parameters) => {
    let result = HttpAuthGet(routes.LIST_WSGUEST_SIGNEE, parameters);

    return result;
}

const addWSGuestSignee = (payload) => {
    let result = HttpAuthPost(routes.ADD_WSGUEST_SIGNEE, payload);

    return result;
}

const deleteWSGuestSignee = (payload) => {
    let result = HttpCustomAuthDelete(routes.DELETE_WSGUEST_SIGNEE, payload);

    return result;
}


const MembersManagementService = {
    getMemberList: getMemberList,
    inviteMember: inviteMember,
    acceptInvite: acceptInvite,
    getInvitedMemberList: getInvitedMemberList,
    getViewMemberDetails: getViewMemberDetails,
    countMembers,
    listMembersEmail,
    declineInvite,
    deleteMember: deleteMember,
    getInvitationDetails: getInvitationDetails,
    cancelInvitation: cancelInvitation,
    resendInvitation: resendInvitation,
    countRemainingWorkspaceMembers: countRemainingWorkspaceMembers,
    updateWorkspaceMemberStatus: updateWorkspaceMemberStatus,
    updateWorkspaceMemberRole: updateWorkspaceMemberRole,
    getWSGuestSigneeList: getWSGuestSigneeList,
    addWSGuestSignee: addWSGuestSignee,
    deleteWSGuestSignee: deleteWSGuestSignee
}

export default MembersManagementService