import React, {useState} from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Menu, MenuItem, IconButton, ListItemIcon, ListItemText} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CancelIcon from "@mui/icons-material/Cancel";
import useMembersManagement from "../hooks/useMembersManagement";
import SendIcon from "../../../assets/icons/send.png";

const CustomMenuPopperComponent = (props) => {

  const {customIconStyles, data, canCancelInvitation, canResendInvitation} = props;

  const {cancelInvitation, resendInvitation} = useMembersManagement();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }
  
  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleCancelInvitationClick = (e, data) => {
    let formData = new FormData();

    formData.append("invitation_code", data?.invitation_secret);
    cancelInvitation(formData, data?.email_address);
  }

  const handleResendInvitationClick = (e, data) => {
    let parameters = {invitation_code: data?.invitation_secret};

    resendInvitation(parameters, data?.email_address);
  }

  const menuItems = [
    {
      icon: <CancelIcon fontSize="small" sx={{color: "#dd533e"}} />,
      label: "Cancel",
      onClick: (e) => handleCancelInvitationClick(e, data)
    },
    {
      icon: <img alt="" src={SendIcon} style={{height: 15, marginLeft: "4px"}} />,
      label: "Resend",
      onClick: (e) => handleResendInvitationClick(e, data),
    },
  ]
  
  
  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
  
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 300,
          }
        }}
       >
        {menuItems?.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleClose();
              item.onClick();
            }}
            sx={{display: (
                (item.label === "Cancel" && data?.status === "Declined") ||
                (item.label === "Cancel" && !canCancelInvitation) ||
                (item.label === "Resend" && !canResendInvitation)
              ) ? "none" 
            : 
              {}}}
          >
            <ListItemIcon sx={customIconStyles}>
              {item.icon}
            </ListItemIcon>
  
            <ListItemText sx={{ml: "-3px"}} primary={item.label} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

const WorkspaceInvitedMembersTable = (props) => {
  const {headers, data, canCancelInvitation, canResendInvitation} = props;


  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{
          backgroundColor: "inherit",
          color: "inherit",
        }}>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell
                key={index}
                align="start"
                sx={{color: "inherit"}}
              >
                {header}
              </TableCell>
            ))}                   
          </TableRow>
        </TableHead>

        <TableBody>
          {data?.length > 0 ? (
            data?.map((invited_member) => (
              <TableRow
                key={invited_member?.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">{invited_member?.email_address}</TableCell>

                <TableCell align="start">
                  {(invited_member.role === "WSAdmin") ? "Admin" 
                    :
                      (invited_member.role === "WSMember") ? "Member" 
                    :  
                      (invited_member.role === "WSGuest") ?  "Signee" 
                    : 
                      "N / A"
                  }
                </TableCell>

                <TableCell align="start" 
                  sx={{ 
                    color: (invited_member?.status === "Pending") ? "#FFA500"
                      : 
                        (invited_member?.status === "Approved") ? "#40BF7E"
                      : 
                        (invited_member?.status === "Cancelled" || invited_member?.status === "Declined") ? "#FF0000"
                      : 
                        "inherit"
                  }}
                >
                  {invited_member?.status}
                </TableCell>
                
                {(!canCancelInvitation && !canResendInvitation) ?
                  <TableCell align="start"></TableCell>
                :
                  <TableCell align="start">
                    <CustomMenuPopperComponent 
                      data={invited_member} 
                      canCancelInvitation={canCancelInvitation}
                      canResendInvitation={canResendInvitation}
                    />
                  </TableCell>
                }
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={headers?.length} align="center">
                No data to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default WorkspaceInvitedMembersTable