import React from "react";
import {Box, Typography, Card, CardContent } from "@mui/material";
import Grid from "@mui/material/Grid2";
import TeamImage from "../../../assets/images/feat_team.png";
import WorkspaceImage from "../../../assets/images/feat_workspace.png";
import SignatoriesImage from "../../../assets/images/feat_signatories.png";
import AutomationImage from "../../../assets/images/feat_automation.png";

const features = [
  {
    image: TeamImage,
    title: "Team Management",
    description:
      "We make it easy to add team members to your account. Our team features allow you to separate departments like Sales and Finance so they cannot see each other’s documents.",
  },
  {
    image: SignatoriesImage,
    title: "Multiple Signatories",
    description:
      "Send your documents to one or multiple signers for review and signing. Track who has signed and who needs a follow-up. All signers automatically receive the completed, signed documents.",
  },
  {
    image: WorkspaceImage,
    title: "Workspaces",
    description:
      "Invite your team members for free and work together seamlessly. Our powerful team features simplify collaboration, enhance productivity, and keep your business running smoothly every step of the way.",
  },
  {
    image: AutomationImage,
    title: "Document Automation",
    description:
      "Set up auto reminders and scheduling to ensure documents are signed on time, without manual follow-ups. Keep your processes efficient, organized, and hassle-free.",
  },
]

const FeaturesComponent = ({breakpoints}) => {

  return (
    <Box sx={{p: "36px 32px 36px", backgroundColor: "#eeebf8", textAlign: "center" }}>
      <Typography variant="h4" fontWeight={700} color="#5b3cc4" sx={{fontSize: "45px"}}>
        Why choose SuperSign?
      </Typography>

      <Typography variant="subtitle1" color="#FF6600" sx={{mb: 4.5, fontSize: "22px"}}>
        Explore all features SuperSign offers
      </Typography>

      <Grid container spacing={5} justifyContent="center">
        {features.map((feature, index) => (
          <Grid size={{xs: 12, sm: 6, md: 5}} key={index}>
            <Card 
              sx={{
                display: "flex", 
                alignItems: "center", 
                height: 250, 
                boxShadow: "0px 1px 0px -2px #5b3cc475, 0px 0px 4px 0px #5b3cc475, 0px 01px 0px 0px #5b3cc475",
                transition: "box-shadow 0.3s ease-in-out",
                "&:hover": {
                  transition: "box-shadow 0.3s ease-in-out",
                  boxShadow: "#5b3cc442 0px 7px 32px 0px"
                }
              }}
            >

              <Box component="img" alt="" src={feature.image} sx={{width: "100%", height: "100%", paddingLeft: "16px"}} />

              <CardContent>
                <Typography variant="h6" fontWeight={600} sx={{color: "#424546", textAlign: "start", fontSize: 22}}>
                  {feature.title}
                </Typography>
                  
                <Typography variant="body2" color="textSecondary" sx={{textAlign: "start", mt: 1.2, fontSize: 16}}>
                  {feature.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default FeaturesComponent
