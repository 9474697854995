import {create} from "zustand";

const useCreatedWorkspaceStore = create((set) => ({
    createdWorkspaceData: null,
    isWorkspaceCreated: false,

    setCreatedWorkspaceData: (data) => set({createdWorkspaceData: data}),
    setWorkspaceCreated: (status) => set({isWorkspaceCreated: status}),
}));

export default useCreatedWorkspaceStore