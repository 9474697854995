import { Box, Button, ButtonGroup, Chip, Container, FormHelperText, TextField, Typography } from "@mui/material";
import FileUploaderComponent from "../../../core/components/common/file-uploader/file-uploader-component";
import PageHeaderComponent from "../../../core/components/common/custom-page-header.component";
import useFilename from "../../../core/zustand/useFilename";
import CustomTypography from "../../../core/components/ui/CustomTypography";
import CustomButton from "../../../core/components/ui/CustomButton";
import { useNavigate } from "react-router-dom";
import DocumentService from "../services/document-service";
import useDocument from "../hooks/useDocument";
import { ThreeDots } from "react-loader-spinner";
import { useEffect, useState } from "react";
import AuthenticationService from "../../profile-plugin/services/authentication-service";
import WelcomeDialog from "../../../core/components/common/welcome-dialog/welcome-dialog.component";
import InsufficientTokenDialog from "../common/dialog/insufficient-token.dialog";

export default function DocumentCreateScreen() {

    const { filename, file, setFilename, fileSize } = useFilename();
    const navigate = useNavigate();
    const { createDocument, isCreatingDocument, getDocumentTransactionPrice, documentTransaction, createDocumentError } = useDocument();
    const [isEmail, setIsEmail] = useState(JSON.parse(localStorage.getItem('isEmail')));
    const [isWorkspace, setIsWorkspace] = useState(JSON.parse(localStorage.getItem('isWorkspace')));
    const [menuSelected, setMenuSelected] = useState(localStorage.getItem('menuSelected'));
    const [openWelcomeDialog, setOpenWelcomeDialog] = useState(false);
    const [openInsufficientTokenDialog, setOpenInsufficientTokenDialog] = useState(false);

    const [error, setError] = useState(false);

    useEffect(() => {
        AuthenticationService.getProfile().then((res) => {
            let isSignatureSetup = res.data.data.profile.signatureSetup
            console.log("isSignatureSetup bbb", isSignatureSetup);
            if (!isSignatureSetup) {
                setOpenWelcomeDialog(true)
            }
        })
    }, [])

    useEffect(() => {
        const syncLocalStorage = () => {
            const storedIsEmail = JSON.parse(localStorage.getItem('isEmail'));
            const storedIsWorkspace = JSON.parse(localStorage.getItem('isWorkspace'));
            const storedMenuSelected = localStorage.getItem('menuSelected');

            if (storedIsEmail !== isEmail) setIsEmail(storedIsEmail);
            if (storedIsWorkspace !== isWorkspace) setIsWorkspace(storedIsWorkspace);
            if (storedMenuSelected !== menuSelected) setMenuSelected(storedMenuSelected);
        };

        const intervalId = setInterval(syncLocalStorage, 500);

        return () => clearInterval(intervalId);
    }, [isEmail, isWorkspace, menuSelected]);

    const handleChangeFileName = (e) => {
        const value = e.target.value;

        setFilename(value);
    }

    const handleCreateDocument = () => {

        let formData = new FormData();

        formData.append("title", filename)
        formData.append("file", file);
        formData.append("workspace_id", isWorkspace ? menuSelected : '')


        console.log('get workspace id', isWorkspace && menuSelected)

        createDocument(formData)
    }

    const handleCloseWelcomeDialog = () => {
        setOpenWelcomeDialog(false)
    }
    useEffect(()=>{
        if(fileSize){
            let fileSizeInKb = file.size / 1024;
            let convertedFileSize = fileSizeInKb.toFixed(2)
            getDocumentTransactionPrice(convertedFileSize)
        }
    }, [fileSize])

    useEffect(() => {
        if (createDocumentError) {
            setOpenInsufficientTokenDialog(true)
        }
    }, [createDocumentError])

    console.log('get filename', documentTransaction)

    return (
        <Container sx={{ textAlign: 'start', mt: 5 }}>
            {/* reusable page header component */}

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <PageHeaderComponent text={'Create Document'} />
                <Box sx={{ flexGrow: 1 }} />
                {fileSize &&
                    <Box>
                        <ButtonGroup variant="text" aria-label="Basic button group">
                            <Button disableRipple sx={{ textTransform: 'none', color: '#604CC3' }}>File Size: {fileSize}</Button>
                            <Button disableRipple sx={{ textTransform: 'none', color: '#604CC3' }}>Token Cost: {documentTransaction ? documentTransaction?.token_amount_cost.toLocaleString() : 0}</Button>
                        </ButtonGroup>
                    </Box>
                }
            </Box>

            {/* reusable file uploader component */}
            <FileUploaderComponent/>

            <Box sx={{alignItems:'center', display:'flex'}}>
                <TextField 
                    margin="normal"
                    placeholder={"Filename"}
                    value={filename}
                    onChange={handleChangeFileName}
                    inputProps={{maxLength: 30}}
                    sx={{
                        textAlign: "start",
                        "& .MuiOutlinedInput-root": {
                            borderRadius: 1, 
                            height: 40,
                            width: 300, 
                            bgcolor: "#fff",
                        },
                    }} 
                    helperText={
                        <p style={{fontSize: 13, color: "red", margin: "3px 0px 0px -10px"}}>
                           {(filename?.length > 30) ? "Filename must not exceed 30 characters" : ""}
                        </p>
                    }
                />
           
            <Box sx={{flexGrow: 1}}/>
                <Button 
                    disabled={(!file) || isCreatingDocument || (file && (filename?.length > 30 || filename?.length === 0))}
                    onClick={() => handleCreateDocument()} variant="contained" sx={{bgcolor:'#604CC3', textTransform:'none', width: 119}}
                    startIcon={isCreatingDocument &&
                        <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
                    }
                >
                    {isCreatingDocument ? 'Creating' : 'Create'}
                </Button>
            </Box>

            {/* <Box sx={{mt: 5}}>
                <CustomTypography variant={'body1'} text={'More Options'} />

                <CustomButton variant={'none'} label={'Use a Pre-made Template Instead'} sx={{p: 0, color: '#604CC3'}} onClick={()=>navigate('/templates')}/>
            </Box> */}

            {
                openWelcomeDialog &&
                <WelcomeDialog openWelcomeDialog={openWelcomeDialog} handleCloseWelcomeDialog={handleCloseWelcomeDialog} />
            }

            {openInsufficientTokenDialog && <InsufficientTokenDialog openInsufficientTokenDialog={openInsufficientTokenDialog} setOpenInsufficientTokenDialog={setOpenInsufficientTokenDialog} />}

        </Container>
    )
}