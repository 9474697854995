import {useState} from "react";
import MembersManagementService from "../services/api/members-management";
import {useSnackbar} from "notistack";
import useInvitedMemberStore from "../../workspace-plugin/zustand/useUpdateInvitedMemberStore";
import useSelectedWorkspaceMemberStore from "../zustand/useSelectedWorkspaceMemberStore";
import {useNavigate} from "react-router-dom";
import useSigneeStore from "../zustand/useSigneeStore";

const useMembersManagement = () => {

    const navigate = useNavigate();

    const {enqueueSnackbar} = useSnackbar();
    const {setMemberInvited, setInvitationCancelled, setInvitationResend, setGuestAddedAsMember} = useInvitedMemberStore();
    const {setMemberRemoved, setMemberStatusUpdated, setMemberRoleUpdated} = useSelectedWorkspaceMemberStore();
    const {setSigneeStatusUpdated, setSigneeDeleted, setSigneeAdded} = useSigneeStore();
    
    const [memberList, setMemberList] = useState([]);
    const [isSendingInvitation, setSendingInvitation] = useState(false);
    const [isInvitationAccepted, setInvitationAccepted] = useState(false);
    const [isAcceptingInvitation, setAcceptingInvitation] = useState(false);
    const [invitedMemberList, setInvitedMemberList] = useState([]);
    const [isFetchingInvitees, setFetchingInvitees] = useState(true);
    const [isFetchingMembers, setFetchingMembers] = useState(true);
    const [totalMembers, setTotalMembers] = useState(0);
    const [isDecliningInvitation, setDecliningInvitation] = useState(false);
    const [invitationDetails, setInvitationDetails] = useState(null);
    const [isFetchingInvitationDetails, setFetchingInvitationDetails] = useState(true);
    const [memberDetails, setMemberDetails] = useState(null);
    const [isFetchingMemberDetails, setFetchingMemberDetails] = useState(false);
    const [memberSlots, setMemberSlots] = useState(null);
    const [isUpdatingStatus, setUpdatingStatus] = useState(false);
    const [isUpdatingRole, setUpdatingRole] = useState(false);
    const [isFetchingWSGuestSigneeList, setFetchingWSGuestSigneeList] = useState(false);
    const [WSGuestSigneeList, setWSGuestSigneeList] = useState([]);
    const [isAddingAsSignee, setAddingAsSignee] = useState(false);
    const [isDeletingMember, setDeletingMember] = useState(false);
    const [isRetainingAsSignee, setRetainingAsSignee] = useState(false);
    
    const setInvalidWorkspaceInvitation = useSelectedWorkspaceMemberStore((state) => state.setInvalidWorkspaceInvitation);


    const getMemberList = (parameters) => {
        setFetchingMembers(true);
        setMemberRemoved(false);
        setGuestAddedAsMember(false);
        setMemberStatusUpdated(false);
        setMemberRoleUpdated(false);
    
        MembersManagementService.getMemberList(parameters).then((res) => {
            if (res.data.success) {
                console.log("getMemberList data xxxx", res);
    
                const fetchedMemberList = res.data.data;

                setMemberList(fetchedMemberList);
                setFetchingMembers(false);
            }
        }).catch((error) => {
            console.log("getMemberList error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
    
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3500,
            });

            setFetchingMembers(false);
        })
    }
    
    const getViewMemberDetails = (userId, workspaceId) => {
        setFetchingMemberDetails(true);

        let parameters = {user_id: userId, workspace_id: workspaceId};

        MembersManagementService.getViewMemberDetails(parameters).then((res) => {
            if (res.data.success) {
                console.log("getViewMemberDetails data xxxx", res);

                setMemberDetails(res?.data?.data);
                    
                setMemberList((prevMemberList) =>
                    prevMemberList.map((member) =>
                        member.user_id === userId ? {...member, email: res.data?.data?.email || "N / A"} : member
                    )
                )

                setFetchingMemberDetails(false);
            }
        }).catch((error) => {
            console.log("getViewMemberDetails error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
    
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3500,
            });

            setMemberDetails(null);
            setFetchingMemberDetails(false);
        })
    }

    const inviteMember = (payload, role) => {
        setSendingInvitation(true);

        MembersManagementService.inviteMember(payload).then((res) => {
            if (res.data.success) {
                console.log("inviteMember data xxxx", res);

                setSendingInvitation(false);

                if (role === "WSGuest") {
                    setGuestAddedAsMember(true);

                    enqueueSnackbar(`Successfully added ${res.data.data?.email} as guest`, {
                        variant: "success",
                        autoHideDuration: 3500
                    })

                } else {
                    setMemberInvited(true);

                    enqueueSnackbar(`Successfully sent an invitation to ${res.data.data?.email_address}`, {
                        variant: "success",
                        autoHideDuration: 3500
                    })
                }
            }

        }).catch((error) => {
            console.log("inviteMember error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            setSendingInvitation(false);
            setMemberInvited(false);
            setGuestAddedAsMember(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3500
            })
        })
    }

    const acceptInvite = (formData, workspaceId) => {
        setAcceptingInvitation(true);
        
        MembersManagementService.acceptInvite(formData).then((res) => {
            if (res.data.success) {
                console.log("acceptInvite data xxxx", res);

                setAcceptingInvitation(false);
                enqueueSnackbar(`Successfully accepted the invitation`, {
                    variant: "success",
                    autoHideDuration: 3500
                })

                navigate("/workspaces/view?workspaceId=" + workspaceId);
                setInvitationAccepted(true);

                localStorage.removeItem("invitationDetails");
                localStorage.removeItem("hasSignInUsingEmailAddress");
            }

        }).catch((error) => {
            console.log("acceptInvite error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;

            if (errorMessage === "Invalid invitation" && window.location.pathname === "/dashboard") {
                setInvalidWorkspaceInvitation(true);

            } else {
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    autoHideDuration: 2500
                })
            }
            
            localStorage.removeItem("invitationDetails");
            setAcceptingInvitation(false);
            setInvitationAccepted(false);
        })
    }

    const getInvitedMemberList = (parameters) => {
        setFetchingInvitees(true);

        setInvitationCancelled(false);
        setInvitationResend(false);

        MembersManagementService.getInvitedMemberList(parameters).then((res) => {
            if (res.data.success) {
                console.log("getInvitedMemberList data xxxx", res);

                setInvitedMemberList(res.data.data);
                setMemberInvited(false);
                setFetchingInvitees(false);
            }

        }).catch((error) => {
            console.log("getInvitedMemberList error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            setFetchingInvitees(false);
            setMemberInvited(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3500
            })
        })
    }

    const countMembers = (parameters) => {
        setFetchingMembers(true);
    
        MembersManagementService.countMembers(parameters).then((res) => {
            if (res.data.success) {
                console.log("countMembers data xxxx", res);
                setTotalMembers(res.data.data);
                setFetchingMembers(false);
              
            }
        }).catch((error) => {
            console.log("countMembers error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
    
            setFetchingMembers(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3500,
            });
        })
    }

    const declineInvite = (formData) => {
        setDecliningInvitation(true);
        
        MembersManagementService.declineInvite(formData).then((res) => {
            if (res.data.success) {
                console.log("declineInvite data xxxx", res);
                setDecliningInvitation(false);
                enqueueSnackbar(`Successfully declined the invitation`, {
                    variant: "success",
                    autoHideDuration: 3500
                })

                window.location.href = '/';
            }

        }).catch((error) => {
            console.log("declineInvite error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            setDecliningInvitation(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3500
            })
        })
    }

    const deleteMember = (parameters, email, workspaceName, setOpen) => {   
        setDeletingMember(true);

        MembersManagementService.deleteMember(parameters).then((res) => {
            if (res.data.success) {
                console.log("deleteMember data xxxx", res);

                setMemberRemoved(true);
                setOpen(false);
                setDeletingMember(false);
                enqueueSnackbar(`Successfully removed ${email} from ${workspaceName}`, {
                    variant: "success",
                    autoHideDuration: 3500
                })
    
            }
        }).catch((error) => {
            console.log("deleteMember error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
    
            setMemberRemoved(false);
            setDeletingMember(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3500,
            });
        })
    }

    const getInvitationDetails = (parameters) => {
        setFetchingInvitationDetails(true);

        MembersManagementService.getInvitationDetails(parameters).then((res) => {
            if (res.data.success) {
                console.log("getInvitationDetails data xxxx", res);

                setInvitationDetails(res?.data?.data);
                setFetchingInvitationDetails(false);
            }

        }).catch((error) => {
            console.log("getInvitationDetails error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3500
            })

            setFetchingInvitationDetails(false);
        })
    }

    const cancelInvitation = (formData, email) => {    
        MembersManagementService.cancelInvitation(formData).then((res) => {
            if (res.data.success) {
                console.log("cancelInvitation data xxxx", res);

                setInvitationCancelled(true);
                enqueueSnackbar(`Successfully canceled the workspace invitation from ${email}`, {
                    variant: "success",
                    autoHideDuration: 3500
                })
            }
        }).catch((error) => {
            console.log("cancelInvitation error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
    
            setInvitationCancelled(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3500,
            });
        })
    }

    const resendInvitation = (parameters, email) => {
        MembersManagementService.resendInvitation(parameters).then((res) => {
            if (res.data.success) {
                console.log("resendInvitation data xxxx", res);

                setInvitationResend(true);
                enqueueSnackbar(`Successfully resent the workspace invitation to ${email}`, {
                    variant: "success",
                    autoHideDuration: 3500
                })
            }

        }).catch((error) => {
            console.log("resendInvitation error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            setInvitationResend(false);
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 4500
            })
        })
    }

    const countRemainingWorkspaceMembers = (parameters) => {
        MembersManagementService.countRemainingWorkspaceMembers(parameters).then((res) => {
            if (res.data.success) {
                console.log("countRemainingWorkspaceMembers data xxxx", res);

                setMemberSlots(res?.data?.data);
            }

        }).catch((error) => {
            console.log("countRemainingWorkspaceMembers error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3500
            })
        })
    }

    const updateWorkspaceMemberStatus = (payload) => {
        setUpdatingStatus(true);

        MembersManagementService.updateWorkspaceMemberStatus(payload).then((res) => {
            if (res.data.success) {
                console.log("updateWorkspaceMemberStatus data xxxx", res);

                setUpdatingStatus(false);
                setMemberStatusUpdated(true);

                enqueueSnackbar(`Successfully updated status`, {
                    variant: "success",
                    autoHideDuration: 3500
                })
            }

        }).catch((error) => {
            console.log("updateWorkspaceMemberStatus error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            setUpdatingStatus(false);
            setMemberStatusUpdated(false);
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3500
            })
        })
    }

    const updateWorkspaceMemberRole = (payload, selectedMember, setOpen) => {
        setUpdatingRole(true);

        MembersManagementService.updateWorkspaceMemberRole(payload).then((res) => {
            if (res.data.success) {
                console.log("updateWorkspaceMemberRole data xxxx", res);

                setUpdatingRole(false);
                setMemberRoleUpdated(true);

                if (selectedMember?.role !== "WSGuest") {
                    enqueueSnackbar( `Successfully updated the role of ${selectedMember?.email} to ${res?.data?.data?.role}`, {
                        variant: "success",
                        autoHideDuration: 3500
                    })

                } else {
                    setMemberInvited(true);

                    enqueueSnackbar(`Successfully sent an invitation to ${selectedMember?.email}`, {
                        variant: "success",
                        autoHideDuration: 3500
                    })
                }

            } else {
                const errorMessage = res?.data?.error;

                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    autoHideDuration: 3500
                })
            }

            setOpen(false);

        }).catch((error) => {
            console.log("updateWorkspaceMemberRole error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            setUpdatingRole(false);
            setMemberRoleUpdated(false);
            setOpen(false);
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3500
            })
        })
    }

    const getWSGuestSigneeList = (parameters) => {
        setFetchingWSGuestSigneeList(true);
        setSigneeStatusUpdated(false);
        setSigneeDeleted(false);
        setSigneeAdded(false);
    
        MembersManagementService.getWSGuestSigneeList(parameters).then((res) => {
            if (res.data.success) {
                console.log("getWSGuestSigneeList data xxxx", res);
    
                setFetchingWSGuestSigneeList(false);
                setWSGuestSigneeList(res.data.data);
            }
        }).catch((error) => {
            console.log("getWSGuestSigneeList error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;

            setFetchingWSGuestSigneeList(false);
            setWSGuestSigneeList([]);
    
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3500,
            });            
        })
    }

    const updateWorkspaceSigneeStatus = (payload) => {
        MembersManagementService.updateWorkspaceMemberStatus(payload).then((res) => {
            if (res.data.success) {
                console.log("updateWorkspaceSigneeStatus data xxxx", res);

                setSigneeStatusUpdated(true);
                enqueueSnackbar(`Successfully updated status`, {
                    variant: "success",
                    autoHideDuration: 3500
                })
            }
        }).catch((error) => {
            console.log("updateWorkspaceSigneeStatus error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            setSigneeStatusUpdated(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3500
            })
        })
    }

    const deleteWSGuestSignee = (payload, email) => {
        MembersManagementService.deleteWSGuestSignee(payload).then((res) => {
            if (res.data.success) {
                console.log("deleteWSGuestSignee data xxxx", res);

                setSigneeDeleted(true);
                enqueueSnackbar(`Successfully removed ${email} as a signee`, {
                    variant: "success",
                    autoHideDuration: 3500
                })
            }
        }).catch((error) => {
            console.log("deleteWSGuestSignee error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            setSigneeDeleted(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3500
            })
        })
    }

    const addWSGuestSignee = (payload, setOpen) => {
        setAddingAsSignee(true);

        MembersManagementService.addWSGuestSignee(payload).then((res) => {
            if (res.data.success) {
                console.log("addWSGuestSignee data xxxx", res);

                setAddingAsSignee(false);
                setSigneeAdded(true);
                setOpen(false);
                enqueueSnackbar(`Successfully saved as signee`, {
                    variant: "success",
                    autoHideDuration: 3000
                })
            }
        }).catch((error) => {   
            console.log("addWSGuestSignee error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            setAddingAsSignee(false);
            setSigneeAdded(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3500
            })
        })
    }

    const sendSigneeInvitation = (payload, email) => {
        MembersManagementService.updateWorkspaceMemberRole(payload).then((res) => {
            if (res.data.success) {
                console.log("sendSigneeInvitation data xxxx", res);
                
                setMemberInvited(true);
                enqueueSnackbar(`Successfully sent an invitation to ${email}`, {
                    variant: "success",
                    autoHideDuration: 3500
                })
            } 
        }).catch((error) => {
            console.log("sendSigneeInvitation error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3500
            })
        })
    }

    const retainAsSignee = (payload, email, setOpen) => {
        setRetainingAsSignee(true);

        MembersManagementService.updateWorkspaceMemberRole(payload).then((res) => {
            if (res.data.success) {
                console.log("retainAsSignee data xxxx", res);

                setRetainingAsSignee(false);
                setMemberRoleUpdated(true);
                setSigneeAdded(true);
                setOpen(false);
                
                enqueueSnackbar(`Successfully updated the role of ${email} to ${res?.data?.data?.role}`, {
                    variant: "success",
                    autoHideDuration: 3500
                })
            }
        }).catch((error) => {
            console.log("retainAsSignee error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            setRetainingAsSignee(false);
            setMemberRoleUpdated(false);
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3500
            })
        })
    }


    return {memberList, isSendingInvitation, isInvitationAccepted, isAcceptingInvitation, invitedMemberList, isFetchingInvitees, isFetchingMembers,
        getMemberList, inviteMember, acceptInvite, getInvitedMemberList, getViewMemberDetails, countMembers, totalMembers, declineInvite, isDecliningInvitation,
        deleteMember, getInvitationDetails, invitationDetails, isFetchingInvitationDetails, memberDetails, isFetchingMemberDetails, cancelInvitation, resendInvitation,
        countRemainingWorkspaceMembers, memberSlots, updateWorkspaceMemberStatus, isUpdatingStatus, updateWorkspaceMemberRole, isUpdatingRole, isFetchingWSGuestSigneeList, 
        WSGuestSigneeList, getWSGuestSigneeList, updateWorkspaceSigneeStatus, deleteWSGuestSignee, isAddingAsSignee, addWSGuestSignee, sendSigneeInvitation, isDeletingMember, 
        isRetainingAsSignee, retainAsSignee
    }

}

export default useMembersManagement
