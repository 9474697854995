import routes from "../common/api-routes";
import {HttpAuthGet, HttpAuthPost, HttpAuthPut, HttpAuthDelete, HttpAuthPostMultipart} from "../../../../core/services/common/HttpRequestBuilder";

const getProfile = () => {

    let query_params = null;

    let result = HttpAuthGet(routes.GET_PROFILE, query_params);

    return result;
}


const viewProfile = () => {

    let query_params = null;

    let result = HttpAuthGet(routes.GET_PROFILE, query_params);

    return result;
}


const uploadProfilePhoto = (formData) => {
    let result = HttpAuthPostMultipart(routes.UPLOAD_PROFILE_PHOTO, formData);
    
    return result;
}

const uploadSignature = (formData) => {
    let result = HttpAuthPostMultipart(routes.UPLOAD_SIGNATURE, formData);
    
    return result;
}


const AuthenticationService = {
    getProfile,
    viewProfile,
    uploadProfilePhoto,
    uploadSignature
}

export default AuthenticationService