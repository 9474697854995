import {Outlet} from "react-router-dom";
import {Box} from "@mui/material";

const WorkspacePluginPage = () => {

    return (
        <Box>
            <Outlet />
        </Box>
    )
}

export default WorkspacePluginPage