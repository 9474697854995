import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid2';
import DashboardStatusMetricsComponent from '../components/dashboard-status-metrics.component';
import DashboardRecentActivityComponent from '../components/dashboard-recent-activity.component';
import DashboardMonthlyRecapComponent from '../components/dashboard-montly-recap.component';
import DashboardConsumerBaseComponent from '../components/dashboard-consumer-base.component';
import { Box} from '@mui/material';
import PageHeaderComponent from '../../../core/components/common/custom-page-header.component';
import useMembersManagement from '../../workspace-plugin/hooks/useMembersManagement';
import useAuthentication from '../../profile-plugin/hooks/useAuthentication';
import useSelectedWorkspaceMemberStore from '../../workspace-plugin/zustand/useSelectedWorkspaceMemberStore';
import InvalidWorkspaceInvitationDialog from '../../workspace-plugin/components/dialog/invalid-workspace-invitation.dialog';

const DashboardScreen = () => {
  const {acceptInvite} = useMembersManagement();
  const {profileData, viewProfile} = useAuthentication();

  const {isInvalidWorkspaceInvitation} = useSelectedWorkspaceMemberStore();
  const hasInvitationDetails = JSON.parse(localStorage.getItem("invitationDetails"));

  const [open, setOpen] = useState(false);

  
  useEffect(() => { 
    viewProfile();
  }, [])
  
  useEffect(() => {
    if (hasInvitationDetails && (profileData?.profile?.email === hasInvitationDetails?.invited_user?.email)) {
      const formData = new FormData();
            
      formData.append("invitation_code", hasInvitationDetails?.invitation?.code);
      acceptInvite(formData, hasInvitationDetails?.workspace?.id);
    }
  }, [hasInvitationDetails, profileData]) 


  return (
    <div>
      <Box sx={{ textAlign: 'start' }}>
        <PageHeaderComponent text={'Dashboard'} />
      </Box>
      <Box sx={{ flexGrow: 1, margin: "30px auto auto" }}>
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, sm: 12, md: 8.5 }}>
            <DashboardStatusMetricsComponent />
            <Box sx={{ width: '100%' }}>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid size={{ xs: 12, sm: 6, md: 7 }}>
                  <DashboardMonthlyRecapComponent />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 5 }}>
                  <DashboardConsumerBaseComponent />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, sm: 12, md: 3.5 }}>
            <DashboardRecentActivityComponent />
          </Grid>
        </Grid>
      </Box>

      {isInvalidWorkspaceInvitation &&
        <InvalidWorkspaceInvitationDialog 
          open={isInvalidWorkspaceInvitation}
          setOpen={setOpen}
        />
      }
    </div>
  );
};

export default DashboardScreen;
