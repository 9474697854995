// firebaseConfig.js
import { initializeApp, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBjQLluZrnzCkOGN23JRdE1G7FWsruazxU",
  // authDomain: process.env.REACT_APP_ENVIRONMENT === "development" 
  // ? "localhost" : "super-sign-54034.firebaseapp.com",
  authDomain: "super-sign-54034.firebaseapp.com",
  projectId: "super-sign-54034",
  storageBucket: "super-sign-54034.appspot.com",
  messagingSenderId: "809370926586",
  appId: "1:809370926586:web:9890dc8c218970ee1bf843",
  measurementId: "G-FBMY0QGPFK"
};

const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];

console.log('Firebase app initialized:', app.name); // Debugging initialization

export const auth = getAuth(app);
