import React, {useState} from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Menu, MenuItem, IconButton, ListItemIcon, ListItemText} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import useMembersManagement from "../hooks/useMembersManagement";
import WorkspaceViewMemberDialog from "./dialog/workspace-view-member.dialog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import UpdateMemberRoleComponent from "./dialog/update-member-role.dialog";
import BlockedIcon from "../../../assets/icons/block.png";
import RemoveMemberConfirmationDialog from "./dialog/remove-member-confirmation.dialog";

const CustomMenuPopperComponent = (props) => {

  const {customIconStyles, data, profileData, setOpenViewMember, setSelectedMember, 
    canViewMember, canUpdateMemberStatus, canDeleteMember, setOpenUpdateRole, canUpdateMemberRole, setOpenConfirmation
  } = props;

  const {updateWorkspaceMemberStatus} = useMembersManagement();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }
  
  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleViewProfileClick = (e, data) => {
    setSelectedMember(data);
    setOpenViewMember(true);
  }

  const handleUpdateMemberStatus = (e, data, type) => {
    if (type === "activate") {
      let payload = {
        id: data?.id,
        workspace_id: data?.workspace_id,
        status: 1
      }

      updateWorkspaceMemberStatus(payload);

    } else if (type === "inactivate") {
      let payload = {
        id: data?.id,
        workspace_id: data?.workspace_id,
        status: 0
      }

      updateWorkspaceMemberStatus(payload);
    }
  }

  const handleUpdateRoleClick = (e, data) => {
    setSelectedMember(data);
    setOpenUpdateRole(true);
  }

  const handleRemoveMemberClick = (e, data) => {
    setOpenConfirmation(true);
    setSelectedMember(data);
  }

  const menuItems = [
    {
      icon: <VisibilityIcon fontSize="small" sx={{color: "#604CC3"}} />,
      label: "View Profile",
      onClick: (e) => handleViewProfileClick(e, data)
    },
    {
      icon: <EditIcon fontSize="small" sx={{color: "#604CC3"}} />,
      label: "Update Role",
      onClick: (e) => handleUpdateRoleClick(e, data)
    },
    {
      icon: <CheckCircleIcon fontSize="small" sx={{color: "#40BF7E"}} />,
      label: "Activate Status",
      onClick: (e) => handleUpdateMemberStatus(e, data, "activate"),
    },
    {
      icon: <img alt="" src={BlockedIcon} style={{height: 17, width: 17}} />,
      label: "Deactivate Status",
      onClick: (e) => handleUpdateMemberStatus(e, data, "inactivate"),
    },
    {
      icon: <DeleteIcon fontSize="small" sx={{color: "#dd533e"}} />,
      label: "Remove Member",
      onClick: (e) => handleRemoveMemberClick(e, data),
    },
  ]
  
  
  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
  
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 300,
          }
        }}
       >
        {menuItems?.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleClose();
              item.onClick();
            }}
            sx={{display: (
                (item.label === "View Profile" && data?.role === "WSGuest") || 
                (item.label === "View Profile" && !canViewMember) ||
                (item.label === "Activate Status" && data?.status === "Active") ||
                (item.label === "Activate Status" && !canUpdateMemberStatus) ||
                (item.label === "Activate Status" && data?.role === "WSAdmin") ||
                (item.label === "Deactivate Status" && data?.status === "Inactive") ||
                (item.label === "Deactivate Status" && !canUpdateMemberStatus) ||
                (item.label === "Deactivate Status" && data?.role === "WSAdmin") ||
                (item.label === "Remove Member" && data?.role === "WSAdmin") || 
                (item.label === "Remove Member" && data?.user_id === profileData?.profile?.userId) ||
                (item.label === "Remove Member" && !canDeleteMember) ||
                (item.label === "Update Role" && !canUpdateMemberRole)
              ) ? "none" 
            : 
              {}}}
          >
            <ListItemIcon sx={customIconStyles}>
              {item.icon}
            </ListItemIcon>
  
            <ListItemText sx={{ml: "-3px"}} primary={item.label} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

const WorkspaceMembersTable = (props) => {
  const {headers, data, workspaceDetails, canViewMember, canUpdateMemberStatus, canDeleteMember, workspaceRoles, canUpdateMemberRole, profileData} = props;

  const [openViewMember, setOpenViewMember] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [openUpdateRole, setOpenUpdateRole] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);


  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{
            backgroundColor: "inherit",
            color: "inherit",
          }}>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  key={index}
                  align="start"
                  sx={{color: "inherit"}}
                >
                  {header}
                </TableCell>
              ))}                   
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.map((member) => (
                <TableRow
                  key={member?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{member?.email}</TableCell>

                  <TableCell align="start">
                    {(member.role === "WSAdmin") ? "Admin" 
                    :
                      (member.role === "WSMember") ? "Member" 
                    :  
                      (member.role === "WSGuest") ?  "Signee" 
                    : 
                      "N / A"
                    }
                  </TableCell>

                  <TableCell align="start" 
                    sx={{color: (member?.status === "Active") ? "#40BF7E" : (member?.status === "Inactive") ? "#FF0000" : "inherit"}}
                  >
                    {member?.status}
                  </TableCell>
                  
                  {(!canViewMember && !canUpdateMemberStatus && !canDeleteMember && !canUpdateMemberRole) ?
                    null
                  :
                    <TableCell align="start">
                      <CustomMenuPopperComponent 
                        data={member} 
                        profileData={profileData}
                        workspaceDetails={workspaceDetails}
                        setOpenViewMember={setOpenViewMember}
                        setSelectedMember={setSelectedMember}
                        canViewMember={canViewMember}
                        canUpdateMemberStatus={canUpdateMemberStatus}
                        canDeleteMember={canDeleteMember}
                        setOpenUpdateRole={setOpenUpdateRole}
                        canUpdateMemberRole={canUpdateMemberRole}
                        setOpenConfirmation={setOpenConfirmation}
                      />
                    </TableCell>
                  }
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {openViewMember && 
        <WorkspaceViewMemberDialog 
          open={openViewMember}
          setOpen={setOpenViewMember}
          selectedMember={selectedMember}
        />
      }

      {openUpdateRole &&
        <UpdateMemberRoleComponent 
          open={openUpdateRole}
          setOpen={setOpenUpdateRole}
          selectedMember={selectedMember}
          workspaceRoles={workspaceRoles}
        />
      }

      {openConfirmation &&
        <RemoveMemberConfirmationDialog 
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          selectedMember={selectedMember}
          workspaceDetails={workspaceDetails}
        />
      }
    </>
  )
}

export default WorkspaceMembersTable