import React from "react";
import {Skeleton, Card, CardContent, CardActions, Box} from "@mui/material";

const WorkspaceItemSkeleton = (props) => {
  const {index} = props;

  return (
    <Card key={index} variant="outlined"
      sx={{
        cursor: "default", maxWidth: {}, justifyContent: "space-between", 
        border: "none", 
        boxShadow: "0px 3px 6px -2px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.08), 0px 2px 12px 0px rgba(0, 0, 0, 0.05)",
        transition: "box-shadow 0.3s ease-in-out",
      }}
    >
      <CardContent>
        <Box sx={{display: "flex", alignItems: "center"}}>
            <Skeleton variant="rectangular" sx={{backgroundColor: "#eeebf8", mr: "10px"}} height={40} width={60} />
            <Skeleton variant="text" sx={{backgroundColor: "#eeebf8"}} width={"150px"} />
        </Box>     
      </CardContent>

      <CardActions sx={{padding: 2}}>
        <Box sx={{textAlign: "start"}}>
            <Skeleton variant="text" sx={{backgroundColor: "#eeebf8"}} width={"150px"} />
        </Box>

        <Box sx={{ml: "auto !important"}}>
            <Skeleton variant="text" sx={{backgroundColor: "#eeebf8"}} width={"150px"} />
        </Box>
      </CardActions>     
    </Card>
  )
}

export default WorkspaceItemSkeleton 






