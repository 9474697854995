import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import NotificationService from "../services/api";


const useNotification = () => {

    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const [notificationData, setNotificationData] = useState([]);
    const [fetchingNotification, setFetchingNotification] = useState(false);
    const [notificationDeleted, setNotificationDeleted] = useState(false);
    const [deletingNotification, setDeletingNotification] = useState(false);

    const getNotification = () => {
        NotificationService.getNotification().then((res) => {
            setFetchingNotification(true);
            if (res.data.success) {
                console.log("getNotification data xxxx", res);
                setFetchingNotification(false);
                setNotificationData(res.data.data);
            }

        }).catch((error) => {
            console.log("getNotification error xxxx", error);
            setFetchingNotification(false);
            const errorMessage = error?.response?.data?.error || error.message;
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    const readNotification = (parameters, reference) => {
        NotificationService.readNotification(parameters).then((res) => {
            if (res.data.success) {
                console.log("readNotification data xxxx", res);
                console.log("reference herexxx", reference)
                navigate(`/accept?invite=${reference}`);
            }

        }).catch((error) => {
            console.log("readNotification error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    const deleteNotification = (parameters) => {
        setDeletingNotification(true);
        NotificationService.deleteNotification(parameters).then((res) => {
            const uniqueValue = Date.now();
            let notification_date = `${true}?t=${uniqueValue}`
            if (res.data.success) {
                setDeletingNotification(false);
                setNotificationDeleted(notification_date);
                enqueueSnackbar('Successfully deleted notification.', {
                    variant: "success",
                    autoHideDuration: 2000,
                });
            }
        }).catch((error) => {
            console.log("readNotification error xxxx", error);
            setDeletingNotification(false);
            setNotificationDeleted(false);
            const errorMessage = error?.response?.data?.error || error.message;
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    

    return {  getNotification, readNotification, deleteNotification, notificationData, fetchingNotification, notificationDeleted, deletingNotification }

}

export default useNotification