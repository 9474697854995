import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Typography, Switch  } from "@mui/material";
import { useEffect, useState } from "react";
import useSignatoryStore from "../../../zustand/useSignatoryStore";
import SigneeCardComponent from "./signee-card-component";


export default function ManageSignatoryDialogComponent ({openManageSignatoryDialog, handleCloseManageSignatoryDialog, label}) {

    const signatory = useSignatoryStore((state) => state.signatory)
    const setSignatory = useSignatoryStore((state) => state.setSignatory)
    const [hasSigningOrder, setHasSigningOrder] = useState(true);

    useEffect(() => {
        console.log("signatories xaaa", signatory);
    }, [JSON.stringify(signatory)])

    const handleOnMoveCard = (dragIndex, hoverIndex) => {
        const result = Array.from(signatory);
        const [removed] = result.splice(dragIndex, 1);
        result.splice(hoverIndex, 0, removed);
        setSignatory(result)
    }

    const handleDoneManage = () => {
        handleCloseManageSignatoryDialog()
    }

    const handleChangeHasSigningOrder = (e) => {
        setHasSigningOrder(e.target.checked)
    }

    return(
        <Dialog open={openManageSignatoryDialog} onClose={handleCloseManageSignatoryDialog} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5}}}>
            <DialogTitle>{label}</DialogTitle>
            <Divider sx={{boxShadow:'0px 4px 10px rgba(0, 0, 0, 0.5);'}}/>
            <DialogContent>
                {/* <FormControl component="fieldset">
                    <FormControlLabel
                        value="start"
                        control={
                            <Switch color="primary" 
                                checked={hasSigningOrder}
                                onChange={handleChangeHasSigningOrder}
                                inputProps={{ 'aria-label': 'controlled' }} />
                        }
                        label="Set signing order"
                        labelPlacement="start"
                    />
                </FormControl> */}
                <br />
                {signatory.length == 0 && <Typography align="center">No signees found.</Typography>}
                {
                    signatory.map((signee, i) => (
                        <SigneeCardComponent signee={signee} index={i} onMoveCard={handleOnMoveCard} hasSigningOrder={hasSigningOrder} />
                    ))
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseManageSignatoryDialog} variant="outlined" sx={{textTransform:'none', color: '#604CC3', borderColor:'#604CC3'}}>Cancel</Button>
                <Button onClick={handleDoneManage} variant="contained" sx={{mr: 2, bgcolor: '#604CC3', textTransform:'none'}}>Done</Button>
            </DialogActions>
        </Dialog>
    )
}