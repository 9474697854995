import React, {useEffect, useState} from "react";
import moment from "moment";
import Grid from "@mui/material/Grid2";
import {Divider, Avatar, Box, Typography, Chip, IconButton} from "@mui/material";
import {stringAvatar} from "../../../core/utils/avatarImageUtil";
import CustomTypography from "../../../core/components/ui/CustomTypography";
import useWorkspaceManagement from "../hooks/useWorkspaceManagement";
import WorkspaceDetailsSkeleton from "./skeletons/workspace-details.skeleton";
import useMembersManagement from "../hooks/useMembersManagement";
import useTokenManagement from "../../token-plugin/hooks/useTokenManagement";
import useDashboard from "../../dashboard-plugin/hooks/useDashboard";
import WorkspaceDocumentsComponent from "./workspace-documents.component";
import CustomButton from "../../../core/components/ui/CustomButton";
import CustomFileUploaderComponent from "../../../core/components/common/custom-file-uploader.component";
import useUploadWorkspaceLogoStore from "../zustand/useUploadWorkspaceLogoStore";
import WorkspaceMembersOverviewComponent from "./workspace-members-overview.component";
import UploadIcon from "../../../assets/icons/upload-white.png";
import useWidth from "../../../core/utils/useWidth";
import EditIcon from "../../../assets/icons/edit-text.png";
import CustomTooltip from "../../../core/components/common/custom-tooltip.component";
import EditWorkspaceComponent from "./workspace-edit.component";
import useWorkspaceStore from "../zustand/useUpdateWorkspaceStore";


const WorkspaceDetailsComponent = ({workspaceId, handleBackButtonLabel}) => {

  const {uploadedWorkspaceLogo} = useUploadWorkspaceLogoStore();
  const {isWorkspaceUpdated} = useWorkspaceStore();
  const breakpoints = useWidth();

  const {workspaceDetails, isFetchingWorkspaceDetails, isLogoUploaded, viewWorkspace, uploadWorkspaceLogo, 
    getWorkspaceRBACPermissions, filteredWorkspaceRoles, isUpdatingLogo, userWorkspaceRole, getUserWorkspaceRole} = useWorkspaceManagement();
  const {countMembers, totalMembers} = useMembersManagement();
  const {getWalletBalance, walletBalance} = useTokenManagement();
  const {getDashboardCount, dashboardCount, isLoadingDashboardCount} = useDashboard();

  const [openUploadLogo, setOpenUploadLogo] = useState(false);
  const [totalDocumentCount, setTotalDocumentCount] = useState(0);
  const [open, setOpen] = useState(false);

  const [canViewWorkspace, setCanViewWorkspace] = useState(false);
  const [canUploadLogo, setCanUploadLogo] = useState(false);
  const [canCountMembers, setCanCountMembers] = useState(false);
  const [canEditWorkspace, setCanEditWorkspace] = useState(false);


  useEffect(() => {
    if (workspaceId) {
      const parameters = {
        workspace_id: workspaceId
      }
      
      getWorkspaceRBACPermissions(parameters);
    }
  }, [workspaceId])
  
  useEffect(() => {
    if (filteredWorkspaceRoles?.length) {
      const userPermissions = {
        canViewWorkspace: filteredWorkspaceRoles?.some(role => role["WORKSPACE_VIEW"]),
        canUploadLogo: filteredWorkspaceRoles?.some(role => role["WORKSPACE_UPLOAD_LOGO"]),
        canCountMembers: filteredWorkspaceRoles?.some(role => role["WORKSPACE_MEMBERS_COUNT"]),
        canEditWorkspace: filteredWorkspaceRoles?.some(role => role["WORKSPACE_UPDATE"]),
      }
        
      setCanViewWorkspace(userPermissions?.canViewWorkspace);
      setCanUploadLogo(userPermissions?.canUploadLogo);
      setCanCountMembers(userPermissions?.canCountMembers); 
      setCanEditWorkspace(userPermissions?.canEditWorkspace);
    }
  }, [filteredWorkspaceRoles]); 

  useEffect(() => {
    if (!workspaceId) return; 

    if ((workspaceId && canViewWorkspace) || isLogoUploaded || isWorkspaceUpdated) {
      const parameters = {
        id: workspaceId
      }

      setOpenUploadLogo(false);
      viewWorkspace(parameters);
    }
  }, [workspaceId, isLogoUploaded, canViewWorkspace, isWorkspaceUpdated])

  useEffect(() => {
    if (workspaceId) {
      const parameters = {id: workspaceId}

      getUserWorkspaceRole(parameters);
    }
  }, [workspaceId])

  useEffect(() => {
    if (workspaceDetails) {
      const parameters = {
        walletId: workspaceDetails?.wallet_id
      }

      getWalletBalance(parameters);
    }
  }, [workspaceDetails])

  useEffect(() => {
    if (workspaceId) {
      const parameters = {workspace_id: workspaceId}
  
      getDashboardCount(parameters);

      if (canCountMembers) {
        countMembers(parameters);
      }
    }
  }, [workspaceId, canCountMembers])

  useEffect(() => {
    if (dashboardCount) {
      const totalCount = Object.values(dashboardCount).reduce((sum, count) => sum + count, 0);

      setTotalDocumentCount(totalCount);
    }
  }, [dashboardCount])

  useEffect(() => {
    if (workspaceDetails) {
      handleBackButtonLabel(workspaceDetails?.name);
    }
  }, [workspaceDetails])


  const handleOpenUploadLogo = () => {
    setOpenUploadLogo(true);
  }

  const handleSaveLogo = () => {
    let formData = new FormData();
  
    formData.append("file", uploadedWorkspaceLogo);
    formData.append("workspace_id", workspaceId);
  
    uploadWorkspaceLogo(formData);
  }


  return (
    <>
      {(isFetchingWorkspaceDetails) ?
        <WorkspaceDetailsSkeleton />
      :
        <>
          <Box sx={{padding: "18px 0px 40px"}}>
            <Grid container spacing={2}>
              <Grid size={{xs: 9, sm: 9}} sx={{textAlign: "start"}}>
                <Box sx={{display: "flex"}}>
                    <Avatar
                      variant="square"
                      src={workspaceDetails?.logo || null}
                      {...(workspaceDetails?.logo ? {} : (workspaceDetails?.name) ? stringAvatar(workspaceDetails?.name) : {})} 
                      style={{boxShadow: "rgb(67 71 85 / 12%) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em", 
                        width: (breakpoints == "md") ? 150 : 200, height: 150, fontSize: 50, marginRight: 8, backgroundColor: (workspaceDetails?.logo) ? {} : {}}}
                      slotProps={{img: {style: {objectFit: "contain"}}}}
                    />
                    
                  <Box sx={{ml: 1.2, mt: "6px", textAlign: "start"}}>
                    <Box sx={{display: "flex"}}>
                      <CustomTypography 
                        variant="body1" 
                        text={workspaceDetails?.name} 
                        customStyles={{fontWeight: "bold", margin: 0, fontSize: (breakpoints == "md") ? 15 : 22, color: "#000000D9"}} 
                      />
                      
                      <Chip 
                        variant="outlined"
                        label={workspaceDetails?.status} 
                        color={(workspaceDetails?.status === "Active") ? "success" : "error"} 
                        size="small" 
                        sx={{borderRadius: 6, fontSize: "12px", height: "20px", m: "4px 0px 0px 8px", padding: "12px"}} 
                      />
                    </Box>

                    <CustomTypography 
                      variant="body1" 
                      text={workspaceDetails?.wallet_id} 
                      customStyles={{fontWeight: 400, margin: 0, fontSize: (breakpoints == "md") ? 12 : 16, color: "#58565fc7", mt: "-2px"}} 
                    />

                    {canUploadLogo && 
                      <CustomButton 
                        variant="contained" 
                        startIcon={<img alt="" src={UploadIcon} style={{height: 15, width: 15, marginRight: "5px"}} />}
                        label={(workspaceDetails?.logo) ? "Update Logo" : "Upload Logo"}
                        customStyles={{width: (breakpoints == "md") ? {} : "190px", mt: (breakpoints == "lg" || breakpoints == "xl") ? "34px" : 3, 
                        fontSize: (breakpoints == "md") ? 14 : 16}}
                        onClick={handleOpenUploadLogo}
                      />
                    }
                  </Box>
                </Box>
              </Grid>

              <Grid size={{xs: 3, sm: 3}} sx={{justifyContent: "flex-end", textAlign: "end"}}>
                {canEditWorkspace &&
                  <CustomTooltip placement="top" title="Edit Workspace">
                    <IconButton onClick={(e) => setOpen(true)}>
                      <img alt="" src={EditIcon} style={{height: 24, width: 24}} />
                    </IconButton>
                  </CustomTooltip>
                }

                <Box sx={{display: "flex", justifyContent: "flex-end", mt: (canEditWorkspace) ? "50px" : {}}}>
                  <Typography variant="body1" sx={{color: "#58565f9e", fontSize: 14, mr: "5px"}}>
                    Token Balance:
                  </Typography>

                  <Chip label={`${walletBalance?.toLocaleString()} Tokens`} color="primary" variant="outlined" size="medium" 
                    sx={{borderRadius: "4px", fontSize: "16px", height: "20px", padding: "18px 2px",             
                      color: "#604cc3", fontWeight: 500, border: "none", mt: "-8px", backgroundColor: "#DDD9F2",
                    }} 
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={1} sx={{mt: 4}}>
              <Grid size={{xs: 6, sm: 6}} sx={{textAlign: "start"}}>
                <Typography variant="body1" sx={{color: "#58565f9e", fontSize: 14}}>
                  Email Address:
                </Typography>

                <Typography variant="body1" sx={{color: "rgb(0 0 0 / 75%)", fontSize: 16}}>
                  {userWorkspaceRole?.email}
                </Typography>
              </Grid>

              <Grid size={{xs: 6, sm: 6}} sx={{textAlign: "start"}}>
                <Typography variant="body1" sx={{color: "#58565f9e", fontSize: 14}}>
                  Description:
                </Typography>

                <Typography variant="body1" sx={{color: "rgb(0 0 0 / 75%)", fontSize: 16}}>
                  {workspaceDetails?.description}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1} sx={{mt: 3}}>
              <Grid size={{xs: 6, sm: 6}} sx={{textAlign: "start"}}>
              <Typography variant="body1" sx={{color: "#58565f9e", fontSize: 14}}>
                  Workspace Role:
                </Typography>

                <Typography variant="body1" sx={{color: "rgb(0 0 0 / 75%)", fontSize: 16}}>
                  {(userWorkspaceRole?.role === "WSAdmin") ? "Admin" 
                  :
                    (userWorkspaceRole?.role === "WSMember") ? "Member" 
                  :  
                    (userWorkspaceRole?.role === "WSGuest") ?  "Signee" 
                  : 
                    "N / A"
                  }
                </Typography>
              </Grid>

              <Grid size={{xs: 6, sm: 6}} sx={{textAlign: "start"}}>
                <Typography variant="body1" sx={{color: "#58565f9e", fontSize: 14}}>
                  Date Created:
                </Typography>

                <Typography variant="body1" sx={{color: "rgb(0 0 0 / 75%)", fontSize: 16}}>
                  {moment(workspaceDetails?.created_at).format("MMMM DD, YYYY, h:mm:ss A")}
                </Typography>
              </Grid>
            </Grid>

            <Divider style={{marginTop: "30px"}} />

            <Grid size={{xs: 12}} sx={{textAlign: "start", mt: 3}}>
              <Typography variant="body1" sx={{color: "rgb(0 0 0 / 75%)"}}>
                Total Documents: <span style={{fontWeight: 500, color: "#4a4950"}}>{totalDocumentCount?.toLocaleString()}</span> 
              </Typography>
            </Grid>

            <Grid size={{xs: 12}} sx={{textAlign: "start", mt: 1}}>
              <WorkspaceDocumentsComponent 
                dashboardCount={dashboardCount} 
                isLoadingDashboardCount={isLoadingDashboardCount} 
                breakpoints={breakpoints}
              />         
            </Grid>

            <Divider sx={{mt: 6}} />

            {canCountMembers && 
              <>
                <Grid size={{xs: 12}} sx={{textAlign: "start", mt: 3}}>
                  <Typography variant="body1" sx={{color: "rgb(0 0 0 / 75%)"}}>
                    Total Members: <span style={{fontWeight: 500, color: "#4a4950"}}>{(totalMembers?.total || 0)?.toLocaleString()}</span> 
                  </Typography>
                </Grid>

                <Grid size={{xs: 12}} sx={{textAlign: "start", mt: 1}}>
                  <WorkspaceMembersOverviewComponent 
                    totalMembersOverview={totalMembers} 
                  />
                </Grid>
              </>
            } 
          </Box>

          {openUploadLogo &&
            <CustomFileUploaderComponent 
              open={openUploadLogo}
              setOpen={setOpenUploadLogo}
              title={(workspaceDetails?.logo) ? "Update Logo" : "Upload Logo"}
              maxWidth="sm" 
              handleSave={handleSaveLogo}
              isLoading={isUpdatingLogo}
            />
          }

          {open &&
            <EditWorkspaceComponent
              open={open} setOpen={setOpen}
              workspaceId={workspaceId}
            />
          }
        </>
      }
    </>
  )
}

export default WorkspaceDetailsComponent
