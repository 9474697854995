import {Box, Toolbar} from "@mui/material";
import AppbarComponent from "../components/landing/apbbar.component";
import BannerComponent from "../components/landing/banner.component";
import EasyStepsComponent from "../components/landing/easy-steps.component";
import FeaturesComponent from "../components/landing/features.component";
import TrustedCompaniesComponent from "../components/landing/companies.component";
import FooterComponent from "../components/landing/footer.component";
import UserFeedbackComponent from "../components/landing/user-feedbacks.component";
import useWidth from "../utils/useWidth";

const LandingScreen = () => {
    const breakpoints = useWidth();

    localStorage.removeItem("invitationDetails");
 
    return (
        <Box display="flex" flexDirection="column" position="relative" sx={{backgroundColor: "#ede9fe"}}>
            <AppbarComponent breakpoints={breakpoints} />

            <Box component="main">
                <Toolbar />
                <BannerComponent breakpoints={breakpoints} /> 
                <EasyStepsComponent breakpoints={breakpoints} />
                <FeaturesComponent breakpoints={breakpoints} />
                <UserFeedbackComponent breakpoints={breakpoints} />
                <TrustedCompaniesComponent breakpoints={breakpoints} />
                <FooterComponent breakpoints={breakpoints} />
            </Box>
        </Box>
    )
}

export default LandingScreen