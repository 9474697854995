import React from "react";
import {Box, Typography} from "@mui/material";
import CustomButton from "../ui/CustomButton";

const PageNotFoundComponent = (props) => {

    const {imageSrc, headerErrorMessage, headerTextStyles, bodyErrorMessage, buttonVariant, buttonText, buttonStyles, buttonHandleClick} = props;

    return (
        <Box sx={{mt: 3}}> 
            <img
                alt=""
                src={imageSrc}
                style={{
                    height: "72vh",
                    margin: "auto",
                }}
            />

            <Typography sx={{fontWeight: "bold", letterSpacing: "0.4px", fontSize: 20, ...headerTextStyles}}>
                {headerErrorMessage}
            </Typography>

            <Typography sx={{letterSpacing: "0.2px", color: "rgba(88, 86, 95, 0.79)"}}>
                {bodyErrorMessage}
            </Typography>

            <Box sx={{textAlign: "center", mt: 2, justifyContent: "center"}}>
                <CustomButton 
                    variant={buttonVariant} 
                    label={buttonText}
                    customStyles={buttonStyles}
                    onClick={buttonHandleClick}
                />
            </Box> 
        </Box>
    )
}

export default PageNotFoundComponent