
const NOTIFICATION_API_ENDPOINT = process.env.REACT_APP_NOTIFICATION_API_ENDPOINT;

   
export default { 

//NOTIFICATION
GET_NOTIFICATION  :                     NOTIFICATION_API_ENDPOINT + "/api/v1/recipient/notification",
READ_NOTIFICATION  :                    NOTIFICATION_API_ENDPOINT + "/api/v1/recipient/notification/read",
DELETE_NOTIFICATION  :                  NOTIFICATION_API_ENDPOINT + "/api/v1/recipient/notification/delete",

}