import React, { useState } from "react";
import {Box, Typography} from "@mui/material";
import {CustomTabs, CustomTab} from "../ui/CustomTabs";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const CustomDynamicTabsComponent = ({ tabs }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  const a11yProps = (index) => ({
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  })

  return (
    <Box sx={{width: "100%"}}>
      <Box>
        <CustomTabs
          centered
          variant="scrollable"
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{sx: {top: "25px !important", height: "4px !important"}}}
        >
          {tabs?.map((tab, index) => (
            <CustomTab 
              key={index} 
              label={tab.name} 
              disableRipple 
              fontSize={tab.fontSize} 
              marginRight={tab.marginRight} 
              {...a11yProps(index)} 
            />
          ))}
        </CustomTabs>
        
        {tabs?.map((tab, index) => (
          <TabPanel key={index} value={value} index={index} style={{padding: "0px"}}>
            {tab.component}
          </TabPanel>
        ))}
      </Box>
    </Box>
  )
}

export default CustomDynamicTabsComponent 
