import React, {useState, useEffect} from "react";
import CustomDialog from "../../../core/components/ui/CustomDialog";
import {Box, CircularProgress, Typography} from "@mui/material";
import CustomTypography from "../../../core/components/ui/CustomTypography";
import CustomTextField from "../../../core/components/ui/CustomTextFieldV2";
import CustomDialogLoaderComponent from "../../../core/components/common/custom-dialog-loader.component";
import useWorkspaceManagement from "../hooks/useWorkspaceManagement";
import useCreatedWorkspaceStore from "../zustand/useCreatedWorkspaceStore"; 

const CreateWorkspaceComponent = ({open, setOpen}) => {
    
    const {createWorkspace, isCreatingWorkspace} = useWorkspaceManagement();
    const {isWorkspaceCreated} = useCreatedWorkspaceStore();

    const [temporaryFields, setTemporaryFields] = useState({name: "", description: ""});
    const [isFormValid, setIsFormValid] = useState(false);
    const [activeField, setActiveField] = useState(null);

    const nameCharacterLimit = 50;
    const descriptionCharacterLimit = 120;


    useEffect(() => {
        validateForm();
    }, [temporaryFields?.name, temporaryFields?.description]);

    useEffect(() => {
        if (isWorkspaceCreated) {
            handleClose(); 
        }
    }, [isWorkspaceCreated])
    

    const validateForm = () => {
        const {name, description} = temporaryFields;
        const isValid = name?.trim() !== "" && description?.trim() !== "";

        setIsFormValid(isValid);
    }
    
    const handleTextfieldChange = (key) => (e) => {
        setTemporaryFields(prev => {
            return {
                ...prev,
                [key]: e.target.value
            }
        })
    }

    const handleFocus = (field) => () => {
        setActiveField(field);
    }

    const handleBlur = () => {
        setActiveField(null);
    }
    
    const handleClose = () => {
        setOpen(false);
        setTemporaryFields({name: "", description: ""});
    }

    const handleCreateWorkspace = () => {
        if (isFormValid) {
            let payload = {
                "name": temporaryFields?.name,
                "description": temporaryFields?.description,
            }
    
            createWorkspace(payload);    
        }
    }

    const actions = [
        {
            label: "Create",
            onClick: () => {
                handleCreateWorkspace();
            },
        }
    ]



    return (
        <>
            <CustomDialog
                open={open}
                onClose={(isCreatingWorkspace) ? null : handleClose}
                title="Create Workspace"
                content={
                    <Box>
                        {(isCreatingWorkspace) ? 
                            <CustomDialogLoaderComponent 
                                icon={<CircularProgress size={40} sx={{color: "#604CC3"}} />} 
                                message="Saving..." 
                            />
                        : 
                            <>
                                <CustomTypography 
                                    variant="subtitle1" 
                                    text={"Name"} 
                                    customStyles={{marginBottom: 0, fontWeight: 400, color: "#58565F", fontSize: 18}}
                                />

                                <CustomTextField 
                                    placeholder={"Name"}
                                    variant={"filled"}
                                    onChange={handleTextfieldChange("name")}
                                    value={temporaryFields?.name}
                                    fullWidth={true}
                                    multiline={false}
                                    hiddenLabel
                                    inputProps={{maxLength: nameCharacterLimit}}
                                    onFocus={handleFocus("name")}
                                    onBlur={handleBlur}
                                />   
                                {activeField === "name" && 
                                    (<Typography align="right" sx={{color: (temporaryFields?.name?.length === nameCharacterLimit) ? "red" : "#58565f", fontSize: 12, mt: "5px"}}>
                                        {temporaryFields?.name?.length}/{nameCharacterLimit}
                                    </Typography>)
                                }

                                {(activeField === "name") ? <></> : <><br /> <br /></>}

                                <CustomTypography 
                                    variant="subtitle1" 
                                    text={"Description"} 
                                    customStyles={{marginBottom: 0, fontWeight: 400, color: "#58565F", fontSize: 18}}
                                />

                                <CustomTextField 
                                    placeholder={"Description"}
                                    variant={"filled"}
                                    onChange={handleTextfieldChange("description")}
                                    value={temporaryFields?.description}
                                    fullWidth={true}
                                    rows={3}
                                    multiline={true}
                                    hiddenLabel
                                    inputProps={{maxLength: descriptionCharacterLimit}}
                                    onFocus={handleFocus("description")}
                                    onBlur={handleBlur}
                                />
                                {activeField === "description" && 
                                    (<Typography align="right" sx={{color: (temporaryFields?.description?.length === descriptionCharacterLimit) ? "red" : "#58565f", fontSize: 12, mt: "5px"}}>
                                        {temporaryFields?.description?.length}/{descriptionCharacterLimit}
                                    </Typography>)
                                }
                            </>
                        }
                    </Box>
                }
                actions={!isCreatingWorkspace && actions}
                maxWidth={"sm"}
                customStyles={{
                    actions: {
                        opacity: (isFormValid && !isCreatingWorkspace) ? "100%" : "55%",
                        cursor: (isFormValid && !isCreatingWorkspace) ? "pointer" : "not-allowed",
                        visibility: (isCreatingWorkspace) ? "hidden" : "visible",
                    }
                }}
                globalSx={{}}
            />  
        </>
    )
}

export default CreateWorkspaceComponent