import { Box, Stack, Typography, Button, Backdrop, CircularProgress, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import DocumentSigneePageComponent from "./document-signee.component";
import CustomButton from "../../../../core/components/ui/CustomButton";
import { getWidthByPercentage } from "../../../../core/utils/ProportionDimenUtil";
import useFillableElementsStore from "../zustand/useFillableElementsStore";
import { elementTypes } from "../common/staticVariables";
import '../../../../App.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function DocumentSigneeBoxComponent({ file_url, elements, setElements, signatory, elements_to_plot, onUpdateElements, pdfResult, setPDFResult, currentUser, currentUserSignature, elRefs,
    isViewing, onChangeSignature, onUpdateSignatoryCounter, viewDownloadedPdf, hasContinued, setFillableDate, setFillableTextfield, setDocumentLoaded,
    fillable_textfield, fillable_date, setCurrentElement, currentElement, documentLoaded, open }) {

    const isMobile = useMediaQuery('(max-width:600px)');

    const [pdfPages, setPdfPages] = useState([])
    // const [elements, setElements] = useState([])
    const [error, setError] = useState(null)
    const [currentScrollElementId, setCurrentScrollElementId] = useState(0)
    const [buttonTop, setButtonTop] = useState(0);
    const [buttonLeft, setButtonLeft] = useState(0);
    const [hasDoneLoadDocument, setHasDoneLoadDocument] = useState(false);
    const [hasStartSigning, setHasStartSigning] = useState(false);
    const [hasDoneSigning, setHasDoneSigning] = useState(false);

    const pdfEditorRef = useRef()
    const pdfDocumentRef = useRef()
    const scrollElements = useRef([]);

    const fillable_elements = useFillableElementsStore((state) => state.fillable_elements)

    const elementProps = {
        elements,
        elements_to_plot,
        setElements,
        signatory,
        pdfResult,
        setPDFResult,
        currentUser,
        currentUserSignature,
        elRefs
    }

    console.log("elements bbbb", elements);


    useEffect(() => {
        if (hasDoneLoadDocument) {
            setTimeout(() => {
                if (pdfDocumentRef.current) {
                    const rect = pdfDocumentRef.current.getBoundingClientRect();
                    setButtonTop(window.scrollY + 50);
                    setButtonLeft(rect.left - 80);
                }
            }, 300);
        }
    }, [hasDoneLoadDocument])

    useEffect(() => {
        // Adjust button's vertical position based on the PDF container
        const updateButtonPosition = () => {
            if (pdfDocumentRef.current) {
                const rect = pdfDocumentRef.current.getBoundingClientRect();
                setButtonTop(window.scrollY + 50);
                setButtonLeft(rect.left - 80);
            }
        };

        // Update position on window resize or scroll
        window.addEventListener("resize", updateButtonPosition);
        window.addEventListener("scroll", updateButtonPosition);

        return () => {
            window.removeEventListener("resize", updateButtonPosition);
            window.removeEventListener("scroll", updateButtonPosition);
        };
    }, [pdfDocumentRef]);

    useEffect(() => {
        if (fillable_elements.length > 0) {
            let filled_element_counter = fillable_elements
                .filter((el) => (el.type === elementTypes.signatory && el.signature)
                    || (el.type === "fillable_textfield" && el.el_value)
                    || (el.type === "fillable_date" && el.el_value)).length

            setHasDoneSigning(filled_element_counter == fillable_elements.length)
        }
    }, [JSON.stringify(fillable_elements)])

    useEffect(() => {
        if (elements.length > 0) onUpdateElements(elements)
    }, [JSON.stringify(elements)])

    function handleOnDocumentLoadSuccess(pdf) {
        console.log("hit handleOnDocumentLoadSuccess PDFSigneeScreen", pdf)
        setDocumentLoaded(true);
        handleSetPDFPages(pdf)
    }

    const handleSetPDFPages = (pdf) => {

        [...Array(pdf.numPages)].map((item, i) => {
            let pageNum = i + 1
            pdf.getPage(pageNum).then((page) => {
                let pdfEditorWrapperWidth = pdfEditorRef?.current?.clientWidth

                let percentage = 70
                let percentageWidth = getWidthByPercentage(percentage, pdfEditorWrapperWidth)


                let rotate = page.rotate
                let isLandscape = rotate == 90 || rotate == 270 ? true : false


                let obj = {
                    numPages: pdf.numPages,
                    page_number: page.pageNumber,
                    isLandscape,
                    adjustedPDFWidth: percentageWidth,
                    page,
                }

                setPdfPages((prev) => {
                    return [...prev, obj]
                })

            })
        })

        setHasDoneLoadDocument(true)
    }

    function getPercentageIncreaseDecrease(newNum, origNum) {
        return ((newNum - origNum) / origNum) * 100;
    }


    const handleStartSigning = () => {
        console.log('Signing process started');
    };

    function handleOnLoadError(error) {
        setError(error.message); // react-pdf validates by default
    }


    const scrollToElement = () => {
        setHasStartSigning(true)
        console.log("currentScrollElementId", currentScrollElementId);

        const nextElementId = getNextToScroll(currentScrollElementId);
        console.log("nextElementId", nextElementId);

        if (nextElementId) {
            setCurrentScrollElementId(nextElementId);

            Object.values(scrollElements.current).forEach((el) => {
                if (el) el.style.backgroundColor = "transparent";
            });

            const currentElement = scrollElements.current[nextElementId];
            if (currentElement) {
                currentElement.style.backgroundColor = "#ffd50073";
                currentElement.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        } else {
            console.log("No more elements without a value.");
        }
    };

    const getNextToScroll = (startId) => {
        const startIndex = elements.findIndex((el) => el.id === startId);

        // Look for the next element from the current position
        for (let i = startIndex + 1; i < elements.length; i++) {

            if ((elements[i].type === elementTypes.signatory && !elements[i]?.signature) || (elements[i].type === "fillable_textfield" && !elements[i]?.el_value)
                || (elements[i].type === "fillable_date" && !elements[i]?.el_value)) {
                return elements[i].id;
            }
        }

        // If no matching element is found, loop from the beginning
        for (let i = 0; i < startIndex; i++) {
            if ((elements[i].type === elementTypes.signatory && !elements[i]?.signature) || (elements[i].type === "fillable_textfield" && !elements[i]?.el_value)
                || (elements[i].type === "fillable_date" && !elements[i]?.el_value)) {
                return elements[i].id;
            }
        }

        // No elements without a value
        return null;
    };


    return (
        <Box sx={{ position: 'relative', height: '100%' }}>
            {
                isMobile ? (
                    !hasDoneSigning && documentLoaded && (
                        <Box sx={{ position: "absolute", top: buttonTop, left: 20, zIndex: 1000 }}>
                            <CustomButton
                                onClick={scrollToElement}
                                label={hasStartSigning ? "Next" : "Start"}
                                variant="contained"
                                sx={{ fontSize: 14, height: 28 }}
                            />
                        </Box>
                    )
                ) : (
                    (buttonTop > 0 && buttonLeft > 0 && !hasDoneSigning) && (
                        <Box sx={{ position: "absolute", top: buttonTop, left: buttonLeft, zIndex: 1000 }}>
                            <CustomButton
                                onClick={scrollToElement}
                                label={hasStartSigning ? "Next" : "Start"}
                                variant="contained"
                            />
                        </Box>
                    )
                )
            }

            <Box ref={pdfEditorRef} sx={{ bgcolor: 'lightgray', height: '100%' }} display="flex" justifyContent="center" alignItems="center"> 
                {/* <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={viewDownloadedPdf} >
                    <Box sx={{ margin: 'auto', textAlign: 'center' }}>
                        <CircularProgress color="inherit" />
                        <Typography sx={{ fontSize: 14 }}>Decrypting.....</Typography>
                    </Box>
                </Backdrop> */}
                {/* {!(open && isMobile) && ( */}
                     <Document inputRef={pdfDocumentRef} loading="Please wait" file={file_url} onLoadSuccess={handleOnDocumentLoadSuccess} onLoadError={handleOnLoadError}>
                        <Stack direction="column" justifyContent="space-evenly" alignItems="center">
                            {pdfPages.map((page, i) => (
                                <DocumentSigneePageComponent
                                    key={i}
                                    index={i}
                                    numPages={page.numPages}
                                    page_number={page.page_number}
                                    adjustedPDFWidth={page.adjustedPDFWidth}
                                    elementProps={elementProps}
                                    isViewing={isViewing}
                                    onChangeSignature={onChangeSignature}
                                    onUpdateSignatoryCounter={onUpdateSignatoryCounter}
                                    setFillableTextfield={setFillableTextfield}
                                    setFillableDate={setFillableDate}
                                    scrollElements={scrollElements}
                                    setCurrentElement={setCurrentElement}
                                    currentElement={currentElement}
                                />
                            ))}
                        </Stack>
                    </Document> 
                {/* )} */}
             </Box>

         </Box>
    );
}
