import React from 'react';
import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} arrow classes={{popper: className }} />
    ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#EEEBF8",
        // color: "#000000de",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#EEEBF8",
        color: "#604CC3",
        fontSize: "14px",
        padding: "10px",
        borderRadius: theme.shape.borderRadius,
        // backgroundColor: "#000000de",
        // color: theme.palette.common.white,
    },
}));

export default CustomTooltip