import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useEffect, useRef, useState } from "react";
import { resizeImageWithAspectRatio } from "../../../../core/utils/AspectRatioUtil";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { format } from "date-fns";
import useDocumentPublicAccount from "../hooks/useDocumentPublicAccount";
import CreateEsignatureCommon from "../common/digital-signature/create-esignature-common";
import { ThreeDots } from "react-loader-spinner";


export default function DocumentElementSignatoriesComponent(props) {

    const { currentUserSignature, signatory, sender, cc_emails,  onChangeSignature, setCurrentElement } = props;

    const { uploadPublicSignature, uploading_signature, uploadedSignature } = useDocumentPublicAccount();


    const signatureRef = useRef();

    const [signedCounter, setSignedCounter] = useState(0)
    const [imgDimens, setImgDimens] = useState(null);
    const [adjustedImgDimens, setAdjustedImgDimens] = useState(null);

    const [openSignatureDialog, setOpenSignatureDialog] = useState(false);


    useEffect(() => {
        if (uploadedSignature) {
            console.log("uploadedSignature hhhhh", uploadedSignature.toString());
            const uniqueValue = Date.now(); // Use timestamp to create a unique query parameter
            let unique_img_signatory = `${uploadedSignature}?t=${uniqueValue}`
            onChangeSignature(unique_img_signatory);
        }
    }, [uploadedSignature])


    const handleEditElement = () => {
        setOpenSignatureDialog(true);
    }

    const handleCloseEsignatureDialog = () => {
        setOpenSignatureDialog(false);
    }



    useEffect(() => {
        if (imgDimens) {
            let { width, height } = resizeImageWithAspectRatio(imgDimens?.width, imgDimens?.height, signatureRef.current?.clientWidth,
                signatureRef.current?.clientHeight)
            setAdjustedImgDimens({ width, height })
        }
    }, [JSON.stringify(imgDimens)])

    const onLoadImg = (e) => {
        setImgDimens({ width: e.target.naturalWidth, height: e.target.naturalHeight })
    }

    useEffect(() => {
        if (signatory?.length > 0) {
            let signed_counter = signatory?.filter((item) => item.status == "DONE").length
            setSignedCounter(signed_counter)
        }
    }, [JSON.stringify(signatory)])

    signatory?.sort((a, b) => a.sequence - b.sequence);

    console.log("sender here",sender)
    console.log("currentUserSignature side panel", currentUserSignature)

    return (
        <Box sx={{ margin: 3, mt: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>

                <Typography sx={{ fontWeight: "bold", textAlign: 'start' }}>
                    Sender
                </Typography>

                <Box sx={{ padding: "12px", mt: "20px", backgroundColor: "#e6e2f5", borderRadius: "5px" }}>
                    <List sx={{ backgroundColor: "#fff", borderRadius: 2, margin: 1 }}>
                        <ListItem >
                            <ListItemText primary={
                                <Typography sx={{ fontSize: 14, color: '#000', width: 150, textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                   {sender}
                                </Typography>} />
                        </ListItem>
                    </List>
                </Box>

                <>
                    <br />
                    <Box sx={{display: 'flex'}}>
                        <Typography sx={{ fontWeight: "bold", textAlign: 'start', mb: "20px" }}>
                            Signature
                        </Typography>

                        <Box sx={{flexGrow: 1}} />

                        <Typography 
                            sx={{ fontWeight: "bold", textAlign: 'end', mb: "20px", color: '#604CC3' , cursor: 'pointer'}}
                            onClick={handleEditElement}
                        >
                            {currentUserSignature ? 'Edit' : 'Add'}
                        </Typography>
                    </Box>

                    <Box ref={signatureRef} sx={{
                        display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: 'center',
                        width: "100%", height: signatureRef.current?.clientWidth / 3, border: "2px solid #ffd500", borderRadius: 2
                    }}>
                        {uploading_signature ?
                            <ThreeDots height="20" width="20" radius="9" color="#604CC3" ariaLabel="three-dots-loading" visible={true} />
                        :
                        !currentUserSignature ? 
                        <Typography textAlign={'center'} sx={{p: 3}}>
                            No available signature
                        </Typography>
                        :
                            <img src={currentUserSignature} style={{ width: adjustedImgDimens?.width, height: adjustedImgDimens?.height }} onLoad={(e) => onLoadImg(e)} />
                        }
                    </Box>
                </>
            </Box>

            <br />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontWeight: "bold", textAlign: 'start' }}>
                    Signatories
                </Typography>

                <Grid container direction="column" sx={{ padding: "12px", mt: "20px", backgroundColor: "#e6e2f5", borderRadius: "5px" }}>
                    {signatory?.length == 0 &&
                        <Typography align="center">
                            No available signatories.
                        </Typography>
                    }

                    {signatory?.map((signatory) => {
                        return (
                            <List sx={{ backgroundColor: "#fff", borderRadius: 2, margin: 1 }}>
                                <ListItem >
                                    {signatory?.status == "DONE" && <CheckCircleIcon sx={{ color: "#357a38" }} />}
                                    <ListItemText primary={<Typography sx={{ fontSize: 14, width: 150, textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}><b style={{ color: "#604CC3", marginRight: 8 }}>{signatory.sequence}</b>{signatory.email_address}</Typography>} />
                                </ListItem>
                                {signatory?.status == "DONE" && <Typography sx={{ fontSize: 10, textAlign: 'center', color: 'gray' }}><strong>Signed:</strong> {format(new Date(signatory?.updated_time), 'PPpp')}</Typography>}
                            </List>
                        )
                    })}
                </Grid>
            </Box>

            <br />

            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontWeight: "bold", textAlign: 'start' }}>
                    CC Emails
                </Typography>
                <Grid container direction="column" sx={{ padding: "12px", mt: "20px", backgroundColor: "#e6e2f5", borderRadius: "5px" }}>
                    {cc_emails.length == 0 &&
                        <Typography align="center">
                           No CC added.
                        </Typography>
                    }

                    {cc_emails.map((data) => {
                        return (
                            <List sx={{ backgroundColor: "#fff", borderRadius: 2, margin: 1 }}>
                                <ListItem >
                                    <ListItemText primary={<Typography sx={{ fontSize: 14, width: 150, textAlign: 'start', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data?.emails}</Typography>} />
                                </ListItem>
                            </List>
                        )
                    })}
                </Grid>
            </Box>

             {openSignatureDialog &&
                <CreateEsignatureCommon 
                    openEsignatureDialog={openSignatureDialog} 
                    handleCloseEsignatureDialog={handleCloseEsignatureDialog} 
                    uploadPublicSignature={uploadPublicSignature}
                    uploading_signature={uploading_signature}
                />
            }
        </Box>
    )
}