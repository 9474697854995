import { Box, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import useElements from "../../zustand/useElements";
import { QRCodeCanvas } from "qrcode.react";
import DocumentEditorService from "../../services/document-editor-service";
import { getWidthByPercentage } from "../../utils/ProportionDimenUtil";
import { ResizableBox } from "react-resizable";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import useSignatoryStore from "../../zustand/useSignatoryStore"
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

export default function DocumentPreviewPageComponent ({decryptedFile, documentById}) {

    const [numPages, setNumPages] = useState(null);
    const [pdfPages, setPdfPages] = useState([])

    const elements = useElements((state) => state.elements);
    const addElement = useElements((state) => state.addElement);
    const currentSelectedSignatory = useSignatoryStore((state) => state.currentSelectedSignatory);
    const [viewingAsElements, setViewingAsElements] = useState([])

    const pdfEditorRef = useRef()

    useEffect(() => {
        setViewingAsElements(elements)
    }, [JSON.stringify(elements)])

    useEffect(() => {
        if (currentSelectedSignatory == 'all') {
            setViewingAsElements(elements)
        } else {
            let regular_elements = elements.filter((item) => item.type != "signatory" && item.type != "fillable_textfield" && item.type != "fillable_date")
    
            const filtered_fillable_elements = elements.filter((item) => item.value == currentSelectedSignatory && 
                (item.type == "signatory" || item.type == "fillable_textfield" || item.type == "fillable_date"))
            
            if (filtered_fillable_elements.length > 0) {
                setViewingAsElements([...regular_elements, ...filtered_fillable_elements])
            }
        }
    }, [currentSelectedSignatory])

    function handleOnLoadDocumentSuccess(pdf) {
        setNumPages(pdf.numPages); // Set the total number of pages
        handleSetPDFPages(pdf)
      }
    
      const handleSetPDFPages = (pdf) => {
        //TODO make comments

        [...Array(pdf.numPages)].map((item, i) => {
            let pageNum = i + 1
            pdf.getPage(pageNum).then((page) => {
                let pdfEditorWrapperWidth = pdfEditorRef?.current?.clientWidth

                let percentage = 63
                let percentageWidth = getWidthByPercentage(percentage, pdfEditorWrapperWidth)

                let rotate = page.rotate
                
                let pageNo = page?.pageNumber


                let obj = {
                    numPages: pdf.numPages,
                    pageNo,
                    rotate,
                    adjustedPDFWidth: percentageWidth
                }

                setPdfPages((prev) => {
               
                    return [...prev, obj]
                })
            })
        })
    }

    return(
        <Box component="main" sx={{ flexGrow: 1, mt: 1, textAlign:'center' }}>
            <Box ref={pdfEditorRef} display="flex" sx={{bgcolor: '#EEEBF8', height:'100%'}} justifyContent="center" alignItems="center">
            <Document
                file={decryptedFile} 
                loading="Loading template..."
                onLoadSuccess={handleOnLoadDocumentSuccess}
            >
                {pdfPages.map((page, i) => {
                return(
                    <>
                        <p style={{color: "#5e5d5d", fontSize: 12}}>
                        Page {page?.pageNo} / {pdfPages?.length}
                    </p>
                        <Page
                            className={"pdf_page_custom"}
                            pageNumber={page.pageNo} // Render each page
                            width={page.adjustedPDFWidth} 
                            scale={1}
                        >
                            {viewingAsElements.map((element)=>(
                                <>
                                    {(element.pageNumber === page.pageNo) && 
                                        <Box sx={{ position: 'absolute', left: element?.x_axis, top: element?.y_axis}}>
                                            <Box className={'editor-box'} style={{ backgroundColor: element?.assign_color}} axis={'both'} width={element?.width} height={element?.height}>
                                                {element.type === 'textfield' && 
                                                    <textarea
                                                    style={{
                                                        width: '100%',
                                                        border: 'none',
                                                        resize: 'none',
                                                        outline: 'none',
                                                        backgroundColor: "#dfdbf37e",
                                                        borderRadius: "5px",
                                                        padding: 0,
                                                        color: "#1a1a1a",
                                                        fontSize: element?.fontSize,
                                                        fontFamily: 'Helvetica',
                                                        lineHeight: 0.95,
                                                    }}
                                                    autoFocus disabled
                                                    value={element.value}
                                                    placeholder='Type here'
                                                />
                                                }
                                                {element.type === 'esignature' && <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={element.value} />}
                                                {element.type === 'date' && <Typography sx={{ textAlign: "start", fontSize: element?.fontSize, whiteSpace: 'nowrap', overflow: 'hidden', 
                                                    textOverflow: 'ellipsis', width: '100%', lineHeight: 0.95, letterSpacing: '0px' }}>{element.value}</Typography>}
                                                {element.type === 'qrcode' && 
                                                <img src={element.value} style={{ width: '100%', height: '100%', objectFit: 'contain', padding: 0, margin: 0 }}  />}
                                                                        {element.type === 'signatory' && 
                                                    <Box  sx={{display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: 'center', width: "100%", height: "100%"}}>
                                                        <Typography align='center' noWrap sx={{textAlign: 'center',whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', margin:'auto'}}>{!element.value ? "Signatory" : element.value}</Typography>
                                                    </Box>
                                                }
                                                {element.type === 'fillable_signatory' && 
                                                    <Box sx={{display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: 'center', width: "100%", height: "100%"}}>
                                                        <Typography align='center' noWrap sx={{textAlign: 'center',whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', margin:'auto'}}>{element.value}</Typography>
                                                    </Box>
                                                }
                                                {element.type === 'fillable_textfield' && 
                                                    <Box sx={{display:'flex', p: 0, m: 0, alignItems:'center', justifyContent:'center', width:'100%', height:'100%',whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                        <Typography sx={{fontSize: element.fontSize, height: 'auto'}}>Textfield</Typography> 
                                                    </Box>
                                                }
                                                {element.type === 'fillable_date' && 
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DateField
                                                        fullWidth
                                                        format={element.date_format}
                                                        readOnly
                                                        sx={{
                                                            '.MuiInputBase-input': { fontSize: element.fontSize, padding: 0, height: 'auto' },
                                                            width: element.width,
                                                            height: element.height
                                                        }}
                                                        />
                                                    </LocalizationProvider>
                                                }
                                            </Box>
                                        </Box>
                                    }
                                </>
                                
                            ))}
                        </Page>
                    </>
                    
                )
            })}
        </Document>
            </Box>
        </Box>
    )
}