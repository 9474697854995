import { Box, Stack, Tooltip, Typography } from '@mui/material';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {useDragHook} from '../../hooks/useDragHook'
import elements from '../../common/elements';
import signatoryElements from '../../common/signatory-elements';
import { useEffect } from 'react';
import useSignatoryEmailStore from '../../zustand/useSignatoyEmailStore';

export default function DocumentDraggableSignatoryElementComponent(prop){

    const {draggable_ui_element, addSignatory} = prop;
    const element = signatoryElements.find((item)=> item.type === draggable_ui_element.type)
    const { personName } = useSignatoryEmailStore()

    element.value = personName?.name?.value
    const {dragRef} = useDragHook('element_fields', element)

    console.log('signatory email hereeee', personName)

    return(
        <Box sx={{
                height: '45px', p: 1, 
                color: 'draggable_element.text.primary',
                bgcolor: personName?.name?.value ? personName.color  : '#dbd9d9', 
                borderColor: '#16840100', 
                borderStyle: 'solid',
                cursor: personName?.length === 0 || personName?.name?.value == '' ? 'not-allowed' : 'all-scroll'
            }}
            // ref={addSignatory.value !== '' ? dragRef : null}
            ref={personName?.length === 0 || personName?.name?.value == '' ? null : dragRef}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0.5}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0.5}
                    sx={{flex: 1, pl: 1, alignSelf: 'center', transition: 'padding 0.15s ease 0s'}}
                >
                    <Typography sx={{fontSize: 13, color: 'rgba(24, 24, 24, 0.85)'}}>
                        {draggable_ui_element.label}
                    </Typography>
                </Stack>
                    <>
                        {draggable_ui_element.icon}
                    </>
            </Stack>
        </Box>
    )
}