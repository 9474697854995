import React from "react";
import {Box, Typography} from "@mui/material";

const CustomDialogLoaderComponent = ({icon, message, customStyles}) => {

    return (
        <Box
            sx={{
                padding: "100px",
                display: "block",
                textAlign: "center",
                height: "100px",
                justifyContent: "center",
                alignItems: "center",
                ...customStyles
            }}
        >
            <Box sx={{mt: -1.5}}>
                {icon}
            </Box>

            <Typography style={{fontSize: 18, color: "rgb(88 86 95)"}}>
                {message}
            </Typography> 
        </Box>
    )
}

export default CustomDialogLoaderComponent
