import {create} from "zustand";

const useSigneeStore = create((set) => ({
    isSigneeStatusUpdated: false,
    isSigneeDeleted: false,
    isSigneeAdded: false,

    setSigneeStatusUpdated: (status) => set({isSigneeStatusUpdated: status}),
    setSigneeDeleted: (status) => set({isSigneeDeleted: status}),
    setSigneeAdded: (status) => set({isSigneeAdded: status}),
}));

export default useSigneeStore