import React, {useState, useEffect} from "react";
import CustomDialog from "../../../../core/components/ui/CustomDialog";
import {Select, FormControl, OutlinedInput, Box, MenuItem, CircularProgress, Typography} from "@mui/material";
import CustomTypography from "../../../../core/components/ui/CustomTypography";
import CustomTextField from "../../../../core/components/ui/CustomTextFieldV2";
import useMembersManagement from "../../hooks/useMembersManagement";
import CustomDialogLoaderComponent from "../../../../core/components/common/custom-dialog-loader.component";

const UpdateMemberRoleComponent = (props) => {
    const {open, setOpen, workspaceRoles, selectedMember} = props;

    const {isUpdatingRole, updateWorkspaceMemberRole} = useMembersManagement();

    const [memberRole, setMemberRole] = useState("");
    const [updatedWorkspaceRoles, setUpdatedWorkspaceRoles] = useState([]);


    useEffect(() => {
        if (selectedMember) {
            const updatedRole = (selectedMember?.role === "WSAdmin") ? "Admin" 
                : 
                    (selectedMember?.role === "WSMember") ? "Member" 
                : 
                    (selectedMember?.role === "WSGuest") ? "Signee" 
                :
                    selectedMember?.role
    
            setMemberRole(updatedRole);
        }
    }, [selectedMember]);

    useEffect(() => {
        if (workspaceRoles?.length > 0) {
            const filteredRoles = workspaceRoles
            .filter(role => role !== "WSGuest") 
            .map(role => (role === "WSAdmin" ? "Admin" : role === "WSMember" ? "Member" : role)); 
    
            setUpdatedWorkspaceRoles(filteredRoles);
        }
    }, [workspaceRoles]);


    const handleClose = () => {
        setOpen(false);
    }

    const handleTextfieldChange = (e) => {
        setMemberRole(e.target.value);
    }

    const handleUpdateMemberRole = () => {
        if ((memberRole === "Admin" ? "WSAdmin" : memberRole === "Member" ? "WSMember" : "N / A") !== selectedMember?.role) {
            const payload = {     
                id: selectedMember?.id,
                workspace_id: selectedMember?.workspace_id,
                role: memberRole === "Admin" ? "WSAdmin" : memberRole === "Member" ? "WSMember" : "N / A"
            }
            
            updateWorkspaceMemberRole(payload, selectedMember, setOpen);    
        }
    }

    const actions = [
        {
            label: (selectedMember?.role === "WSGuest") ? "Send Invitation" : "Update",
            onClick: () => {
                handleUpdateMemberRole();
            },
        }
    ]


    return (
        <>
            <CustomDialog
                    open={open}
                    onClose={(isUpdatingRole) ? null : handleClose}
                    title="Update Role"
                    content={
                        <Box>
                            {(isUpdatingRole) ? 
                                <CustomDialogLoaderComponent 
                                    icon={<CircularProgress size={40} sx={{color: "#604CC3"}} />} 
                                    message={(selectedMember?.role === "WSGuest") ? "Sending an invitation..." : "Updating..."}
                                />
                            : 
                                <>
                                    <CustomTypography 
                                        variant="subtitle1" 
                                        text={"Email Address"} 
                                        customStyles={{marginBottom: 0, fontWeight: 400, color: "#58565F", fontSize: 18}}
                                    />

                                    <CustomTextField 
                                        variant={"filled"}
                                        fullWidth={true}
                                        multiline={false}
                                        hiddenLabel
                                        value={selectedMember?.email}
                                        disabled
                                        customStyles={{
                                            '& .MuiFilledInput-root.Mui-disabled': {
                                                color: "rgba(0, 0, 0, 0.87) !important",
                                                backgroundColor: "#eeebf8",
                                            },
                                            '& .MuiInputBase-input.Mui-disabled': {
                                                color: "rgba(0, 0, 0, 0.87) !important",
                                                WebkitTextFillColor: "rgba(0, 0, 0, 0.87) !important",
                                            },
                                        }}
                                    />   <br/> <br/> 

                                    <CustomTypography 
                                        variant="subtitle1" 
                                        text={"Workspace Role"} 
                                        customStyles={{marginBottom: 0, fontWeight: 400, color: "#58565F", fontSize: 18}}
                                    />

                                    <FormControl sx={{width: "100%"}}>
                                        <Select
                                            displayEmpty
                                            value={memberRole}
                                            onChange={(e) => handleTextfieldChange(e)}
                                            input={<OutlinedInput />}
                                            MenuProps={{PaperProps: {style: {maxHeight: 48 * 4.5 + 8,  width: 250}}}}  
                                            inputProps={{"aria-label": "Without label"}}
                                            sx={{
                                                borderRadius: "2px",
                                                backgroundColor: "#604cc31c",
                                                borderBottom: "none",

                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: "none",
                                                },

                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    border: "none", 
                                                },

                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    border: "none", 
                                                },

                                                '& .MuiOutlinedInput-root': {
                                                    border: "none",
                                                    
                                                    '&:hover': {
                                                        backgroundColor: "#604cc336"
                                                    },
                                                    '&.Mui-focused': {
                                                        backgroundColor: "#604cc33d", 
                                                    },
                                                },

                                                '& .MuiInputBase-input::placeholder': {
                                                    color: "#acb0be"
                                                },
                                            }}  
                                        >
                                            <MenuItem disabled value="">
                                                <em>Select Workspace Role</em>
                                            </MenuItem>

                                            {updatedWorkspaceRoles?.map((role) => (
                                                <MenuItem key={role} value={role}>
                                                    {role}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl> 

                                    {(selectedMember?.role === "WSGuest" && memberRole !== "WSGuest") ?
                                        (<Typography sx={{color: "#ff2121", mt: "10px", fontStyle: "italic", textAlign: "start", fontSize: 14}}>
                                            Note: If the WSGuest role is changed to either WSAdmin or WSMember, he/she will be sent an invitation. 
                                            Upon accepting the invitation, their role will be updated to the selected role.
                                        </Typography>)
                                    :
                                        null
                                    }
                                </>
                            }
                        </Box>
                    }
                    actions={!isUpdatingRole && actions}
                    maxWidth={"sm"}
                    customStyles={{
                        actions: {
                            opacity: (!isUpdatingRole && 
                                ((memberRole === "Admin" ? "WSAdmin" : memberRole === "Member" ? "WSMember" : "N / A") !== selectedMember?.role)) ? 
                                    "100%" 
                                : 
                                    "55%",
                            cursor: (!isUpdatingRole && 
                                ((memberRole === "Admin" ? "WSAdmin" : memberRole === "Member" ? "WSMember" : "N / A") !== selectedMember?.role)) ? 
                                    "pointer" 
                                : 
                                    "not-allowed",
                            visibility: (isUpdatingRole) ? "hidden" : "visible",
                        }
                    }}
                    globalSx={{}}
                />
        </>
    )
}

export default UpdateMemberRoleComponent