import { useState } from "react"
import DocumentPublicUserAccountService from "../services/document-public-user-account-service"
import { useSnackbar } from "notistack";

const useDocumentPublicAccount = () => {

    const { enqueueSnackbar } = useSnackbar();
    const [sender_email, setSenderEmail] = useState('');
    const [uploading_signature, setUploadingSignature] = useState(false);
    const [uploadedSignature, setUploadedSignature] = useState('');
    const [signatureUploaded, seSignatureUploaded] = useState(false);

    const getSenderEmail = (parameters, accessToken) => {
        DocumentPublicUserAccountService.getSenderEmail(parameters, accessToken).then((res)=> {
            console.log('getSenderEmail res', res)
            setSenderEmail(res?.data?.data?.email)
          
        }).catch((err)=>{
            console.log('getSenderEmail err', err)
            const errorMessage = err?.response?.data?.error || err.message;
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
           
        })
    }


    const uploadPublicSignature = (formData, accessToken, handleCloseEsignatureDialog) => {
        setUploadingSignature(true);
        setUploadedSignature("")
        DocumentPublicUserAccountService.uploadPublicSignature(formData, accessToken).then((res)=> {
            console.log('uploadPublicSignature res', res)
            handleCloseEsignatureDialog(true);
            if(res?.data.success){
                setUploadingSignature(false);
                setUploadedSignature(res?.data?.data?.signature);
                seSignatureUploaded(true);
            }
        }).catch((err)=>{
            console.log('uploadPublicSignature err', err)
            setUploadingSignature(false);
            const errorMessage = err?.response?.data?.error || err.message;
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
           
        })
    }


    return {getSenderEmail, sender_email, uploadPublicSignature, uploading_signature, uploadedSignature, signatureUploaded}
}

export default useDocumentPublicAccount