import { auth } from "../../firebaseConfig";

const getIdToken = async () => {
    // Ensure Firebase is initialized
    if (!auth) {
        console.error("Firebase is not initialized.");
        return null;
    }

    const user = auth.currentUser;
    if (!user) {
        console.error("No authenticated user found.");
        return null;
    }

    try {
        const idToken = await user.getIdToken(true); // Force refresh the token
        // console.log('ID Token:', idToken);
        return idToken;
    } catch (error) {
        console.error('Error getting ID token:', error);
        // Handle logout or redirect to login here
        // Optionally: auth.signOut(); 
        return null; // or throw new Error('Token retrieval failed');
    }
};

export default getIdToken;
