import React from "react";
import { Routes, Route } from "react-router-dom";
import { PrivateRoute } from "./private-routes";
import AppBarComponent from "../components/layout/app-bar.component";
import { routes } from "./routes";
import { PublicRoute } from "./public-routes";
import MainLayotScreen from "../pages/MainLayoutScreen";

const Router = function() {
    return (
        <Routes>
            {routes.map((route) => (
                <Route
                    key={route.path}
                    path={route.path}
                    element={
                        route.isPrivate ? (
                            route.path === "/document-editor" || route.path === "/sign" || route.path === "/view-document" || route.path === "/thankyou" ? (
                                <PrivateRoute>
                                    {/* No AppBar for /document-editor */}
                                    {route.element}
                                </PrivateRoute>
                            ) : (
                                <PrivateRoute>
                                    {/* Regular routes with AppBar */}
                                    <MainLayotScreen>{route.element}</MainLayotScreen>
                                </PrivateRoute>
                            )
                        ) : (
                            // Use PublicRoute for public routes
                            <PublicRoute>
                                {route.element}
                            </PublicRoute>
                        )
                    }
                >
                    {route.children &&
                        route.children.map((childRoute) => (
                            <Route
                                key={childRoute.path}
                                index={childRoute.index}
                                path={childRoute.path}
                                element={childRoute.element}
                            />
                        ))}
                </Route>
            ))}
        </Routes>
    );
};

export default Router;
