
import { AppBar, Box, Toolbar, Typography, FormControl, Select, MenuItem } from "@mui/material";
import CustomButton from "../../../../../core/components/ui/CustomButton";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import CustomTypography from "../../../../../core/components/ui/CustomTypography";
import useElements from "../../zustand/useElements"
import useSignatoryStore from "../../zustand/useSignatoryStore"
import { useEffect, useState } from "react";
import { elementTypes } from "../../common/staticVariables";
import DocumentEditorService from "../../services/document-editor-service";


export default function DocumentViewAppbarComponent ({}) {

  const navigate = useNavigate()
  const elements = useElements((state) => state.elements);
  const setSignatory = useSignatoryStore((state) => state.setSignatory);
  const signatory = useSignatoryStore((state) => state.signatory);
  const setCurrentSelectedSignatory = useSignatoryStore((state) => state.setCurrentSelectedSignatory);
  const currentSelectedSignatory = useSignatoryStore((state) => state.currentSelectedSignatory);
  const [hasSetSignatories, setHasSetSignatories] = useState(false)

  console.log("currentSelectedSignatory xxx11", currentSelectedSignatory);
  
  useEffect(() => { 
    if (!hasSetSignatories) {
      let signatories = [...new Set(elements.filter((item) => item.type == elementTypes.signatory).map((item) => item.value))]
      console.log("signatories xxx11", signatories);
      if (signatories.length > 0) {
        setSignatory(signatories)
        setHasSetSignatories(true)
      }
    }
  }, [hasSetSignatories, JSON.stringify(elements)])

  const handleSelectChange = (event) => {
    const value = event.target.value;
    console.log("value xxx11", value);
    setCurrentSelectedSignatory(value)
  }
  
    return(
        <AppBar  position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor:'white' }}>
          <Toolbar>
          <CustomTypography text={'Viewing as:'} variant={'caption'} sx={{color:'black', fontSize: 13, fontWeight:'bold', mt: 1}} />
          <FormControl>
            <Select
              defaultValue={"all"}
              value={currentSelectedSignatory}
              onChange={handleSelectChange}
              sx={{
                bgcolor: 'white',
                height: '40px',
                marginLeft: 2,
                padding: '0 8px',
                '& .MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',
                  width: 200,
                  height: '100%',
                }
              }}
            >
              <MenuItem value="all">All</MenuItem>
              {
                signatory.map((item) => {
                  return(<MenuItem value={item}>{item}</MenuItem>)
                })
              }
            </Select>
          </FormControl>
          <Box sx={{flexGrow:1}}/>
            <CustomButton onClick={()=>navigate(-1)} label={<CloseIcon/>} variant={'none'} sx={{color: 'black'}}/>
          </Toolbar>
        </AppBar>
    )
}