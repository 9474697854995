  import { create } from "zustand";


  const useElements = create((set) => ({
      elements: [],
      pdfPages: [],
      newElements: [],
      updatedElements: [],
      deletedElements: [],
      hasPDFChanges: false,
      setElements: (elements) => set({ elements }),
      // addElement: (element) => set((state) => ({ elements: [...state.elements, element] })),
      addElement: (element) => set((state) => {
        const elementExists = state.elements.some((el) => el.id === element.id);
        if (!elementExists) {
            return { elements: [...state.elements, element], newElements: typeof element.id === 'string' ? [...state.newElements, element] : [...state.newElements] };
        } else {
            console.log(`Element with id ${element.id} already exists.`);
            return state; // No changes if element with same id exists
        }
    }),
      updateElement: (updatedElement) =>
        set((state) => {

          // Update All Elements
          const getAllElementsState = () => {
            const elements = state.elements.map((item) =>
              item.id === updatedElement.id ? updatedElement : item
            );
            return elements;
          }

          // Update New Elements State
          const getUpdatedNewElementsState = () => {
            const elements = state.newElements.map((item) =>
              item.id === updatedElement.id ? updatedElement : item
            );
            return elements;
          }

          // Update Updated Elements State
          const getUpdatedElementsState = () => {
            const isExistInUpdatedElementsState = state.updatedElements.some((el) => el.id === updatedElement.id)
            if(isExistInUpdatedElementsState){
              return state.updatedElements.map((item) => {
                  if(item.id === updatedElement.id && typeof updatedElement.id === 'number'){
                    return updatedElement;
                  }
                  return item;
              });
            } else {
              if (typeof updatedElement.id === 'number') {
                return [...state.updatedElements, updatedElement];
              }
              return [...state.updatedElements]
            }
          }

          const element = getAllElementsState().find((el) => el.id === updatedElement.id);

          return { elements: getAllElementsState(), newElements: getUpdatedNewElementsState(), updatedElements: getUpdatedElementsState(), updatedElement: element || null};
        }),
      removeElement: (id) =>
      set((state) => {
        const elementToRemove = state.elements.find((element) => element.id === id);
        return {
          elements: state.elements.filter((element) => element.id !== id),
          newElements: state.newElements.filter((element) => element.id !== id),
          updatedElements: state.updatedElements.filter((element) => element.id !== id),
          deletedElements: elementToRemove ? [...state.deletedElements, elementToRemove] : state.deletedElements,
        };
      }),
      addPDFPage: (page) => set((state) => ({ pdfPages: [...state.pdfPages, page] })),
      updatePDFPage: (updated_page) => 
        set((state) => ({
          pdfPages: state.pdfPages.map((page) =>
            page.pageNo === updated_page.pageNo ? { ...page, ...updated_page } : page
          ),
        })),
      setHasPDFChanges: (hasChanges) => set(() => ({ hasPDFChanges: hasChanges })),
    }));

  export default useElements