import React, { useEffect, useState } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import useUserAuthenticatedStore from "../zustand/useUserAuthenticatedStore";
import WorkspaceAcceptInviteComponent from "../components/workspace-accept-invite.component";
import useMembersManagement from "../hooks/useMembersManagement";
import WorkspaceAcceptInviteSkeleton from "../components/skeletons/workspace-accept-invite.skeleton";
import PageNotFoundComponent from "../../../core/components/common/page-not-found.component";
import NotFoundImage from "../../../assets/images/404-img.png";
import {auth} from "../../../firebaseConfig";
import {signOut} from "firebase/auth";


const WorkspaceAcceptInvitePage = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const {isUserAuthenticated} = useUserAuthenticatedStore();
    const {getInvitationDetails, invitationDetails, isFetchingInvitationDetails} = useMembersManagement();

    const [isUserLogIn, setIsUserLogin] = useState(false);

    const queryParams = new URLSearchParams(location.search);
    const invite_code = queryParams.get("invite");
    
    
    useEffect(() => {
        if (!invite_code) {
            navigate("/");
        }
    }, [])

    useEffect(() => {
        if (invite_code) {
            let parameters = {
                invitationCode: invite_code
            }

            getInvitationDetails(parameters);
        }
    }, [invite_code])

    useEffect(() => {
        if ((!isFetchingInvitationDetails && isUserAuthenticated !== null) && 
            (isUserAuthenticated?.email !== "undefined" && invitationDetails?.invited_user?.email !== "undefined") && 
            (isUserAuthenticated?.email !== invitationDetails?.invited_user?.email)) {
                signOut(auth);
                localStorage.removeItem("invitationDetails");
                localStorage.removeItem("hasSignInUsingEmailAddress");
                localStorage.removeItem("wallet_id");
                localStorage.removeItem("isEmail");
                localStorage.removeItem("isWorkspace");
                localStorage.removeItem("profile_wallet");
                localStorage.removeItem("menuSelected");
                localStorage.removeItem("cartItems");
                localStorage.removeItem("addedCustomAmount");
                localStorage.removeItem("addedToCart");
                localStorage.removeItem("orderId");
                localStorage.removeItem("orderPlaced");
                localStorage.removeItem("orderCreated");
                localStorage.clear();
                window.location.reload();
        }
    }, [isUserAuthenticated, invitationDetails, isFetchingInvitationDetails])



    const handleButtonClick = () => {
        if (isUserAuthenticated?.accessToken) {
            navigate("/dashboard");
        } else {
            navigate("/");  
        }
    }


    if (!isFetchingInvitationDetails && !invitationDetails) return <PageNotFoundComponent 
            imageSrc={NotFoundImage}
            headerErrorMessage={"Page Not Found"}
            headerTextStyles={{color: "#263238", marginTop: "-65px"}}
            bodyErrorMessage={"Sorry, the page you requested could not be found"}
            buttonVariant="contained"
            buttonText={"Navigate back home"}
            buttonStyles={{}}
            buttonHandleClick={handleButtonClick} 
        />


    return (
        <>
            {(isFetchingInvitationDetails) ?
                <WorkspaceAcceptInviteSkeleton />
            :
                <WorkspaceAcceptInviteComponent 
                    invite_code={invite_code} 
                    isUserLogIn={isUserLogIn} 
                    isUserAuthenticated={isUserAuthenticated} 
                    setIsUserLogin={setIsUserLogin} 
                    invitationDetails={invitationDetails}
                />
            } 
        </>
    )
}

export default WorkspaceAcceptInvitePage

