import React, {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import DonutChart from "react-donut-chart";

const WorkspaceMembersOverviewComponent = (props) => {
    const {totalMembersOverview} = props;

    const [memberRoles, setMemberRoles] = useState([]);
    const [memberStatus, setMemberStatuses] = useState([]);
   
    const reactDonutChartBackgroundColor = ["#2E7D32", "#FF0000"];
    const reactDonutChartInnerRadius = 0.5;
    const reactDonutChartSelectedOffset = 0.08;
    let reactDonutChartStrokeColor = "#FFFFFF";


    useEffect(() => {
        if (totalMembersOverview) {
            const rolesArray = Object.keys(totalMembersOverview?.role).map(key => ({
                role: key,
                value: totalMembersOverview?.role[key]
            }));

            const statusArray = Object.keys(totalMembersOverview?.status).map((key, index) => ({
                label: key,
                value: totalMembersOverview?.status[key],
                color: key.toLowerCase() === "active" ? "#2E7D32" : "#FF0000", 
                
            }));

            setMemberRoles(rolesArray);
            setMemberStatuses(statusArray);
        }
    }, [totalMembersOverview])


    const reactDonutChartOnMouseEnter = (item) => {
        let color = memberStatus?.find((q) => q.label === item.label).color;
        reactDonutChartStrokeColor = color;
    }
    

    return (
        <Grid container spacing={2}>
            <Grid size={{xs: 6, sm: 6}}>
                <Box sx={{backgroundColor: "#fff", border: "1px solid rgb(0 0 0 / 8%)", padding: "16px 24px", borderRadius: "4px", height: 290}}>
                    <Typography variant="body1" sx={{color: "rgb(0 0 0 / 75%)", fontSize: 16, mb: "12px"}}>
                        Role Overview
                    </Typography>

                    <Grid container sx={{background: "#dadafc", color: "#5d5ed8", p: 1.5, borderRadius: "6px", textAlign: "center"}}>
                        <Grid size={{xs: 6, sm: 6}}>
                            <Typography>Member Role</Typography>
                        </Grid>

                        <Grid size={{xs: 6, sm: 6}}>
                            <Typography>Count</Typography>
                        </Grid>
                    </Grid>

                    {memberRoles?.map((member) => (
                        <Grid container sx={{color: "#58565f", background: "#f4f4fe", p: 1.5, mt: 2, mb: 2, borderRadius: "6px", textAlign: "center", cursor: "default"}}>
                            <Grid item size={{xs: 6}}>
                                <Typography>
                                    {(member.role === "WSAdmin") ? "Admin" 
                                    :
                                        (member.role === "WSMember") ? "Member" 
                                    :  
                                        (member.role === "WSGuest") ?  "Signee" 
                                    : 
                                        "N / A"
                                    }
                                </Typography>
                            </Grid>

                            <Grid item size={{xs: 6}}>
                                <Typography>{member.value}</Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
            </Grid>

            <Grid size={{xs: 6, sm: 6}}>
                <Box sx={{backgroundColor: "#fff", border: "1px solid rgb(0 0 0 / 8%)", padding: "16px 24px", borderRadius: "4px", height: 290}}>
                    <Typography variant="body1" sx={{color: "rgb(0 0 0 / 75%)", fontSize: 16, mb: "12px"}}>
                        Status Overview
                    </Typography>

                    <Box sx={{margin: "auto", textAlign: "center",  position: "relative",}}>
                        <DonutChart
                            strokeColor={reactDonutChartStrokeColor}
                            data={memberStatus}
                            colors={reactDonutChartBackgroundColor}
                            innerRadius={reactDonutChartInnerRadius}
                            selectedOffset={reactDonutChartSelectedOffset}
                            width={310}
                            height={310}
                            onMouseEnter={(item) => reactDonutChartOnMouseEnter(item)}
                            className={"workspacemember-status-donutchart"}
                            legend={true}
                        />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default WorkspaceMembersOverviewComponent
