import {create} from "zustand";

const useEnterpriseProjectStore = create((set) => ({
    isOVCodeKeysUpdated: false,
    isOVCodeKeysDeleted: false,
    isOVCodeKeysAdded: false,

    setOVCodeKeysUpdated: (status) => set({isOVCodeKeysUpdated: status}),
    setOVCodeKeysDeleted: (status) => set({isOVCodeKeysDeleted: status}),
    setOVCodeKeysAdded: (status) => set({isOVCodeKeysAdded: status}),
}));

export default useEnterpriseProjectStore