import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Rings } from "react-loader-spinner";
import useUserAuthenticatedStore from "../../plugins/workspace-plugin/zustand/useUserAuthenticatedStore";

function PublicRoute({ children }) {
    const { setUserAuthenticated } = useUserAuthenticatedStore();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    console.log("user here", user)

    useEffect(() => {
        const auth = getAuth();
        const unregisterAuthObserver = onAuthStateChanged(auth, (authUser) => {
            setUser(authUser);
            setUserAuthenticated(authUser);
            setLoading(false);
        });

        return () => unregisterAuthObserver();
    }, []);

    if (loading) {
        // Show loader while authentication state is being determined
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <Rings color="#3498db" height={280} width={280} />
            </div>
        );
    }

    if (user) {
        // Handle authenticated user
        if (location.pathname.includes('/accept')|| location.pathname === "/sign") {
            return children; // Allow access to specific routes
        } else {
            // Redirect to dashboard for other routes
            return <Navigate to="/dashboard" />;
        }
    } else {
        // Render children for unauthenticated users
        return children;
    }

  
}

export { PublicRoute };
