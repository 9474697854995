import { Box, Typography } from "@mui/material"
import { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";


const DocumentSignatoryElementComponent = ({ index, element, onLoadImg, isViewing, isUploading, scrollElements }) => {

    console.log("isViewing 123", isViewing);
    console.log("element 123", element);
    console.log("isUploading 123", isUploading);

    return (
        isUploading ? 
            <Box sx={{display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: 'center', cursor: 'pointer', width: 'inherit', height: 'inherit'}}>
                <ThreeDots height="20" width="20" radius="9" color="#604CC3" ariaLabel="three-dots-loading" visible={true} />
            </Box>
        :
        <Box ref={(el) => (scrollElements.current[element.id] = el)} sx={{display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: 'center', cursor: 'pointer', width: 'inherit', height: 'inherit'}}>
            {element.isSigned ?
                <img src={element.signature} style={{width: element.width, height: element.height, objectFit: "contain",}} onLoad={(e) => onLoadImg(e)} /> 
            :
                <Typography noWrap sx={{textAlign: 'center', whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', margin:'auto'}}>{isViewing ? !element.value ? "Signatory" : element.value : "Click to sign"}</Typography>
            }
        </Box>
    )
}

export default DocumentSignatoryElementComponent