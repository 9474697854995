import { Box, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDropHook } from "../../hooks/useDropHook";
import { Page } from "react-pdf";
import useElements from "../../zustand/useElements"
import { v4 as uuidv4 } from 'uuid';
import DocumentEditorContainerComponent from "./document-editor-container.component";
import DocumentEditorService from "../../services/document-editor-service";
import { useElementHook } from "../../hooks/useElementHook";
import useSignatoryEmailStore from "../../zustand/useSignatoyEmailStore";
import useSignatoryStore from "../../zustand/useSignatoryStore";
import { elementTypes } from "../../common/staticVariables";
import { isOutsideParentBoundingBox } from "../../utils/BoundingBoxUtil";
import useProfileStore from "../../../../profile-plugin/zustand/useUpdateProfileStore";

export default function DocumentEditorPageComponent (props) {

    const {page, documentById, setOpenEsignatureDialog, handleCloseEsignatureDialog} = props;

    const pdfEditorRef = useRef();
    const addElement = useElements((state) => state.addElement);
    const elements = useElements((state) => state.elements);
    const updateElement = useElements((state) => state.updateElement);
    const removeElement = useElements((state) => state.removeElement);
    const pdfPages = useElements((state) => state.pdfPages);
    const updatePDFPage = useElements((state) => state.updatePDFPage);
    const newElements = useElements((state) => state.newElements);
    const updatedElements = useElements((state) => state.updatedElements);
    const setHasPDFChanges = useElements((state) => state.setHasPDFChanges);
    const profileESignature = useProfileStore((state) => state.profileESignature);
    const { clearPersonNames } = useSignatoryEmailStore()
    const signatory = useSignatoryStore((state) => state.signatory)
    const updatedSignatories = useSignatoryStore((state) => state.updatedSignatories)
    const deletedSignatories = useSignatoryStore((state) => state.deletedSignatories)
    const resetDeletedSignatories = useSignatoryStore((state) => state.resetDeletedSignatories)
    
    
    const {getReverseProportionElementCoords, getReverseProportionElementDimens, getReverseProportionalElementFontSize} = useElementHook();

    console.log('GET NEW ELEMENTS PLOTTED AAAAAA', newElements)
    console.log('GET UPDATED ELEMENTS PLOTTED BBBBBB', updatedElements)
    console.log('GET ALL ELEMENTS', elements)

    const {dndRef, dropResult} = useDropHook(pdfEditorRef, 'element_fields');
    const [textValue, setTextValue] = useState('');

    // Handle Load elements
    useEffect(() => {
        DocumentEditorService.documentElementProperties(documentById.id).then((res)=>{
            res?.data?.data?.documentProperties?.forEach((el) => {

                // Hanlde Reverse Proportion Elements
                let current_page = pdfPages.find((page) => page.pageNo == el.page_no)
                let rotation = current_page?.rotate
                let dropRefCurrentWidth = current_page?.dropRefCurrentWidth
                let dropRefCurrentHeight = current_page?.dropRefCurrentHeight
                let pageOrigWidth = current_page?.pageOrigWidth
                let pageOrigHeight = current_page?.pageOrigHeight

                if (el.page_no == current_page?.pageNo) {
                    let {x: reversedX, y: reversedY} = getReverseProportionElementCoords(parseFloat(el.x_axis), parseFloat(el.y_axis), dropRefCurrentWidth, dropRefCurrentHeight, rotation, pageOrigWidth, pageOrigHeight)
                    let {w: reversedWidth, h: reversedHeight} = getReverseProportionElementDimens(rotation, dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, parseFloat(el.width), parseFloat(el.height))
                    
                    let updated_element_props = {
                        type: el.type,
                        value: el.value,
                        x_axis: reversedX,
                        y_axis: reversedY,
                        pageNumber: el.page_no,
                        date_format: el.date_format,
                        width: reversedWidth,
                        height: reversedHeight,
                        id: el.id,
                        assign_color: el.assign_color
                    }

                    console.log('updated_element_props aax', el)
                    console.log('GGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGGG', updated_element_props)
                    
                    if(el.type == 'textfield' || el.type == 'date' || el.type == 'fillable_textfield' || el.type == 'fillable_date'){
                        let {font_size} = getReverseProportionalElementFontSize(rotation, dropRefCurrentWidth, pageOrigWidth, pageOrigHeight, parseFloat(el.font_size))
                        updated_element_props.fontSize = font_size;
                    }

                    console.log('updated_element_props uuuuu', updated_element_props)

                    addElement(updated_element_props); // Add each individual element
                }
            })
        })
    }, [JSON.stringify(pdfPages)])

    useEffect(() => {
        if (dropResult) {
            console.log("dropResult xxx", dropResult);
            handleDropElement(dropResult, profileESignature)
        }
    }, [JSON.stringify(dropResult), profileESignature])

    const handleDropElement = (elementDropRes, profileESignature) => {
        // For eSignature
        if (elementDropRes.type == elementTypes.userSignature && !profileESignature) {
            setOpenEsignatureDialog(true)
        }

        setHasPDFChanges(true)

        const isUpdate = !!elementDropRes.id; // Check if the element already exists
        const newElement = { 
          ...elementDropRes, 
          id: isUpdate ? elementDropRes.id : uuidv4(), 
          pageNumber: page.pageNo 
        };
      
        console.log('dropResult:', newElement);
      
        if (!isUpdate) {
          // Add new element if it doesn’t exist
          addElement(newElement);
        } else {
          // Update existing element
          const updatedElement = elements.find((el) => el.id === elementDropRes.id);
          console.log("updatedElement xx", updatedElement);
          
          updatedElement.x_axis = elementDropRes.x_axis
          updatedElement.y_axis = elementDropRes.y_axis
          updatedElement.pageNumber = page.pageNo
          // Call `updateElement` if a matching element was found
          if (updatedElement) {
            console.log("updatedElement yyy", updatedElement);
            updateElement(updatedElement);
          }
        }
    };

    // Update Elements if signatory changed
    useEffect(() => {
        if (updatedSignatories.length > 0) {

            console.log('updatedSignatories aaaaa', updatedSignatories)
            let updated_filable_elements = elements.filter((el) =>
                updatedSignatories.find((sig) => sig.old === el.value)?.old == el.value
            );
            
            console.log('updated_filable_elements hjh', updated_filable_elements)

            if(updated_filable_elements.length > 0){
                updated_filable_elements.forEach((item) => {
                    const updated_signatory = updatedSignatories.find((sig) => sig.old === item.value);
                    if (updated_signatory) {
                        item.value = updated_signatory;
                        updateElement(item);
                    }
                });
            }
            
            setHasPDFChanges(true)
        }
    }, [JSON.stringify(updatedSignatories)])

    // Delete Elements if signatory deleted
    useEffect(() => {
        console.log("deletedSignatories bbb", deletedSignatories);
        if (deletedSignatories.length > 0) {
            let to_be_remove_filable_elements = elements.filter((el) =>
                deletedSignatories.some((sig) => sig === el.value)
            );

            console.log("to_be_remove_filable_elements bbb", to_be_remove_filable_elements);

            to_be_remove_filable_elements.forEach((element) => {
                removeElement(element.id)
            });

            resetDeletedSignatories()
            clearPersonNames()
            setHasPDFChanges(true)
        }
    }, [JSON.stringify(deletedSignatories)])

    const handleLoadInitialElements = (page) => {
        let rotate = page?.rotate

        let isLandscape = rotate == 90 || rotate == 270 ? true : false
        let pageWidth = isLandscape ? page?.height : page?.width
        let pageHeight = isLandscape ? page?.width : page?.height

        let dropRefCurrentWidth = pageWidth
        let dropRefCurrentHeight = pageHeight

        let pageOrigWidth = page?.originalWidth
        let pageOrigHeight = page?.originalHeight - page?.view[1]
        let pageNo = page?.pageNumber

        let updated_pdf_page = pdfPages.find((item) => item.pageNo == pageNo)
        if (updated_pdf_page) {
            updated_pdf_page.dropRefCurrentWidth = dropRefCurrentWidth
            updated_pdf_page.dropRefCurrentHeight = dropRefCurrentHeight
            updated_pdf_page.pageOrigWidth = pageOrigWidth
            updated_pdf_page.pageOrigHeight = pageOrigHeight

            updatePDFPage(updated_pdf_page)
        }
    }

    function handleOnLoadSuccessPDFPage(page) {
        handleLoadInitialElements(page)
        const elements = document.getElementsByClassName("react-pdf__Page__textContent");
        while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }
    }

    const handleTextfieldChange = (element) => {
        setHasPDFChanges(true)
        updateElement(element);
    }

    const handleDuplicateElements = (element) => {
        const newElement = { 
            ...element, 
            id: uuidv4(), 
            pageNumber: page.pageNo ,
            x_axis: element.x_axis + 20,
            y_axis: element.y_axis + 20,
        };

        const parentRect = dndRef.current.getBoundingClientRect();
        const isOutsideBox = isOutsideParentBoundingBox(parentRect, newElement.x_axis, newElement.y_axis, newElement.width, newElement.height)
        
        if(isOutsideBox){
            newElement.x_axis = newElement.x_axis - 20
            newElement.y_axis = newElement.y_axis - 20
        }

        addElement(newElement);
    }

    return(
        <Box>
            <p style={{color: "#5e5d5d", fontSize: 12}}>
                Page {page?.pageNo} / {pdfPages?.length}
            </p>
            <Page
                className={"pdf_page_custom"}
                pageNumber={page.pageNo} // Render each page
                width={page.adjustedPDFWidth} 
                scale={1}
                inputRef={dndRef}
                onLoadSuccess={handleOnLoadSuccessPDFPage}
            >
                {elements.map((element)=>(
                    <>
                        {(element.pageNumber === page.pageNo) && 
                            <Box >
                                <DocumentEditorContainerComponent element={element} parentRef={dndRef} textValue={textValue} 
                                handleTextfieldChange={handleTextfieldChange} documentById={documentById} handleDuplicateElements={handleDuplicateElements} handleCloseEsignatureDialog={handleCloseEsignatureDialog} />
                            </Box>
                        }
                    </>
                ))}
            </Page>
        </Box>
    )
}