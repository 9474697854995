import { useEffect } from 'react';
import CustomDialog from '../../../../core/components/common/custom-dialog.component';
import { Typography } from '@mui/material';
import useDocument from '../../hooks/useDocument';

export default function DocumentResendDialog (props) {
    const { openResend, handleCloseResendDialog, document_id, document_title } = props;
    const { resendDocument, isResendingDocument } = useDocument();

    const handleResendDocument = () => {
        let payload ={
            document_id : document_id 
        }
        resendDocument(payload, handleCloseResendDialog);
    };

  return (
    <CustomDialog
      open={openResend}
      onClose={handleCloseResendDialog}
      title="Resend Document Confirmation"
      content={<Typography sx={{ fontSize: 16 }}>Are you sure you want to resend <b>{document_title}</b> ?</Typography>}
      confirmButtonText={isResendingDocument ? 'Resending' : 'Confirm'}
      onConfirm={handleResendDocument}
      isLoading={isResendingDocument}
      cancelButtonText="Cancel"
      onCancel={handleCloseResendDialog}
      isAction={true}
    />
  );
};

