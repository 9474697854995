import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Divider, Typography, Box } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";

const CustomDialog = ({
    open,
    onClose,
    title,
    content,
    confirmButtonText,
    onConfirm,
    isLoading = false,
    confirmButtonDisabled = false,
    cancelButtonText = "Cancel",
    onCancel,
    dialogActions,
    isAction
}) => {

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={'sm'} PaperProps={{ style: { boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 2 } }}>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 18 }}> {title} </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton onClick={onClose} sx={{ color: '#604CC3' }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                {content}
            </DialogContent>

            {isAction &&
                <DialogActions sx={{ padding: 6, paddingTop: 0, paddingBottom: 3 }}>
                    <Button variant="outlined" sx={{ width: 120, borderRadius: 10, border: '1px solid #604CC3', color: '#604CC3' }} onClick={onCancel}>
                        {cancelButtonText}
                    </Button>

                    <Button
                        variant="contained"
                        sx={{
                            width: isLoading ? 180 : 120,
                            backgroundColor: '#604CC3',
                            borderRadius: 10,
                            boxShadow: 'none'
                        }}
                        onClick={onConfirm}
                        disabled={confirmButtonDisabled}
                        startIcon={isLoading && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                    >
                        {isLoading ? 'Processing' : confirmButtonText}
                    </Button>
                </DialogActions>
            }
        </Dialog>
    );
}

export default CustomDialog;
