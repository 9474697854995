import { Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography, useMediaQuery } from "@mui/material";
import DocumentElementSignatoriesComponent from "./document-element-signatories.component";
import { useEffect, useState } from "react";
import CustomButton from "../../../../core/components/ui/CustomButton";
import useFillableElementsStore from "../zustand/useFillableElementsStore";
import { ThreeDots } from "react-loader-spinner";
import { elementTypes } from "../common/staticVariables";
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const drawerWidth = 320;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

export default function DocumentElementSideDrawerComponent(props) {

    const { currentUserSignature, signatory, sender, cc_emails, onChangeSignature, setCurrentElement,
        handleOnClickFinish, isDeclineClicked, isLoading, handleClickOpen, isFinishClicked, documentLoaded, open,
        handleDrawerClose, document_title
    } = props;

    const theme = useTheme();

    useEffect(() => {
        console.log("currentUserSignature b111", currentUserSignature);
    }, [currentUserSignature])

    const isMobile = useMediaQuery('(max-width:600px)');

    const fillable_elements = useFillableElementsStore((state) => state.fillable_elements)
    const [filledElementCounter, setFilledElementCounter] = useState(0)

    useEffect(() => {
        if (fillable_elements.length > 0) {
            console.log("fillable_elements xxxx", fillable_elements);
            let filled_element_counter = fillable_elements
                .filter((el) => (el.type === elementTypes.signatory && el.signature)
                    || (el.type === "fillable_textfield" && el.el_value)
                    || (el.type === "fillable_date" && el.el_value)).length
            console.log("filled_element_counter xxxx", filled_element_counter);
            setFilledElementCounter(filled_element_counter)
        }
    }, [JSON.stringify(fillable_elements)])

    return (
        <Drawer
            sx={{ width: drawerWidth, flexShrink: 0, '& .MuiDrawer-paper': { width: drawerWidth }, }}
            variant="persistent"
            anchor="right"
            open={open}
        >
            <DrawerHeader>
                {theme.direction === 'rtl' ?
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon fontSize='large' sx={{ color: '#604CC3' }} />
                    </IconButton>
                :
                    <>
                        <IconButton onClick={handleDrawerClose} >
                            <ChevronRightIcon fontSize="large" sx={{ color: '#604CC3' }} />
                        </IconButton>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 18, color: '#604CC3', mt: 0, ml: 1 }}>
                            {document_title}
                        </Typography>
                    </>
                }
            </DrawerHeader>

            <DocumentElementSignatoriesComponent
                currentUserSignature={currentUserSignature}
                signatory={signatory}
                sender={sender}
                cc_emails={cc_emails}
                onChangeSignature={onChangeSignature}
                setCurrentElement={setCurrentElement}
            />

            {isMobile &&
                <Box sx={{ margin: 3, mt: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <CustomButton
                            label={'Decline'}
                            variant={'outlined'}
                            sx={{ height: isMobile ? 30 : 40, fontSize: isMobile ? 14 : 16, mr: isMobile ? 1 : 3, textTransform: 'uppercase', border: '1px solid red', color: 'red' }}
                            onClick={handleClickOpen}
                            disabled={isFinishClicked || !documentLoaded}
                        />
                        <br />
                        <CustomButton
                            label={'Finish & Submit'}
                            variant={'contained'}
                            sx={{ height: isMobile ? 30 : 40, fontSize: isMobile ? 14 : 16, textTransform: 'uppercase' }}
                            onClick={handleOnClickFinish}
                            startIcon={isLoading && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                            disabled={isDeclineClicked || !documentLoaded || filledElementCounter !== fillable_elements.length}
                        />
                    </Box>
                </Box>
            }
        </Drawer>
    )
}