import React from "react";
import {Dialog, DialogContent,  Typography, Button, IconButton, Box} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Grid from "@mui/material/Grid2";


const DocumentCancelledDialog = (props) => {

    const {openCancelledDialog, handleCloseCancelledDialog, documentStatus, cancel_reason} = props;

    const gotoLink = () => {
        window.open("/", "_self")
    }

    return (
        <Dialog open={openCancelledDialog} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5}}}>
            <Box sx={{textAlign:'end', padding: "5px 10px 10px 0px"}}>
                <IconButton onClick={gotoLink}>
                    <CloseIcon sx={{color:'#0178f2'}}/>
                </IconButton>
            </Box>
            <DialogContent sx={{padding: "0px 24px 35px 24px"}}>
                <Grid container justifyContent="center" direction="column" sx={{textAlign: 'center', margin: "auto"}}>
                    <Grid size={12} sx={{margin: "auto"}}>
                    {documentStatus === "CANCELLED" ? 
                        <>
                            <Typography variant="h5" sx={{textAlign: 'center'}} >
                                This document has been <b style={{color: 'red'}}>CANCELLED</b>
                                </Typography>
                            <br/>
                            <Typography sx={{ fontSize: 16 }}><i>Reason :</i> &nbsp;{cancel_reason}</Typography>
                        </>
                    :
                        <Typography variant="h5" sx={{textAlign: 'center'}}>
                            This document has been <b style={{color: 'red'}}>DECLINED</b>
                        </Typography>
                    }

                    </Grid>

                    <Grid size={12} sx={{margin: "auto", mt: "20px"}}>
                        <Typography variant="h6" sx={{fontWeight: "bold", color: "#604CC3", textAlign: 'center'}}>
                            Thank you for using SuperSign
                        </Typography>
                    </Grid>

                    <Grid size={12} sx={{margin: "auto"}}>
                        <Typography variant="subtitle2" sx={{color: "#000", textAlign: 'center'}}>
                            Create, customize and send document for signatures
                        </Typography>
                    </Grid>

                    <Grid size={12} sx={{margin: "auto", mt: "22px"}}>
                        <Button onClick={gotoLink} variant="contained" sx={{width: 220,  color: '#fff', backgroundColor: '#604CC3', borderRadius: 10, boxShadow: 'none'}}>
                            Done
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default DocumentCancelledDialog