import {create} from 'zustand';

// Create the Zustand store
const useSignatoryStore = create((set) => ({

  signatory: [],
  currentSelectedSignatory: "all",
  updatedSignatories: [],
  deletedSignatories: [],
  addSignatory: (email) => set((state) => {
    const isExists = state.signatory.some((sig) => sig === email);
    if (!isExists) {
        return { signatory: [...state.signatory, email]};
    }
    return state.signatory
  }),
  updateSignatory: (old, updated) => set((state) => {
    let updatedSignatory = state.signatory.map((sig) => {
      if (sig == old) {
        sig = updated
      }
      return sig
    });
    return { signatory: updatedSignatory, updatedSignatories: [...state.updatedSignatories, {old, updated}]}
  }),
  setCurrentSelectedSignatory: (signatory) => set({ currentSelectedSignatory: signatory }),
  setSignatory: (signatory) => set({ signatory }),
  resetDeletedSignatories: () => set({ deletedSignatories: [] }),
  removeSignatory: (email) => set((state) => {
    return { signatory: state.signatory.filter((sig) => sig !== email), deletedSignatories:  state.signatory.filter((sig) => sig == email)}
  }),
}));

export default useSignatoryStore;
