import React, { useEffect, useState } from "react";
import {
  getAuth,
  signInWithEmailLink,
  isSignInWithEmailLink,
  EmailAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { Typography, useMediaQuery } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import superSignLogo from "../../../assets/icons/supersign-logo.png";
import { Rings } from "react-loader-spinner";
import { auth } from "../../../firebaseConfig";
import useUserAuthenticatedStore from "../../workspace-plugin/zustand/useUserAuthenticatedStore";
import VerifyEmailDialog from "../components/dialog/verify-email.dialog";
import AuthenticationService from "../../profile-plugin/services/authentication-service";
import useMembersManagement from "../../workspace-plugin/hooks/useMembersManagement";

const FirebaseAuth = () => {
  const {acceptInvite} = useMembersManagement();


  const storedInvitationDetails = JSON.parse(localStorage.getItem("invitationDetails"));

  const { setUserAuthenticated } = useUserAuthenticatedStore();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const authInstance = getAuth();

  const url = window.location.href;

  const isMobile = useMediaQuery('(max-width:600px)');

  const [email, setEmail] = useState("");
  const [isDialogOpen, setDialogOpen] = useState(false);


  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    const handlePopState = () => {
      if (window.location.pathname === "/login") {
        navigate("/");
      }
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  const handleAcceptInvitation = () => {
    const formData = new FormData();
            
    formData.append("invitation_code", storedInvitationDetails?.invitation?.code);
    acceptInvite(formData, storedInvitationDetails?.workspace?.id);
  } 

  useEffect(() => {

    const uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: (authResult) => {
          console.log("login authResult xxxx", authResult, authResult.additionalUserInfo, authResult.additionalUserInfo.providerId === EmailAuthProvider.PROVIDER_ID);
 
          const user = authResult.user;
          setUserAuthenticated(user);

          if (storedInvitationDetails) {
            handleAcceptInvitation();
            
          } else {
            handleRedirectAfterLoginSuccess();
          }
          

          if (authResult.additionalUserInfo && authResult.additionalUserInfo.providerId === EmailAuthProvider.PROVIDER_ID) {
            handleSignInLink(user?.email);
          }

          
          // handleRedirectAfterLoginSuccess();
          return false;
        },
        signInFailure: (error) => {
          enqueueSnackbar(error.message, { variant: "error" });
          return Promise.resolve();
        },
        uiShown: () => {
          document.getElementById("loader").style.display = "none";
        },
      },
      signInFlow: "popup",
      signInOptions: [
        GoogleAuthProvider.PROVIDER_ID,
        // {
        //   provider: EmailAuthProvider.PROVIDER_ID,
        //   signInMethod: EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
        //   requireDisplayName: true,
        //   disableSignUp: true,
        // },
      ],
      tosUrl: "<terms-of-service-url>",
      privacyPolicyUrl: "<your-privacy-policy-url>",
    };

  
    let ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(authInstance);
    ui.start("#firebaseui-auth-container", uiConfig);

    const googleButtonInterval = setInterval(() => {
      const googleButton = document.querySelector('.firebaseui-idp-google');
      if (googleButton) {
        googleButton.style.backgroundColor = '#458ff1';
        googleButton.style.minHeight = '0px';
      }

      const googleSpan = document.querySelector('.firebaseui-idp-google .firebaseui-idp-text');
      if (googleSpan) {
        googleSpan.style.color = '#fff';
        clearInterval(googleButtonInterval);
      }


      const iconWrapper = document.querySelector('.firebaseui-idp-google .firebaseui-idp-icon-wrapper');
      if (iconWrapper) {
        iconWrapper.style.backgroundColor = '#fff';
        iconWrapper.style.width = '24px';
        iconWrapper.style.height = '24px';
        iconWrapper.style.margin = 'auto';
        iconWrapper.style.textAlign = 'center';
      }

      if (googleButton && googleSpan && iconWrapper) {
        clearInterval(googleButtonInterval);
      }


    }, 100);
  }, [authInstance, enqueueSnackbar, navigate, storedInvitationDetails]);

  const handleRedirectAfterLoginSuccess = () => {
    console.log("handleRedirectAfterLoginSuccess bbb");

    AuthenticationService.getProfile().then((res) => {
      let isSignatureSetup = res.data.data.profile.signatureSetup
      if (!isSignatureSetup) {
        navigate("/documents/create");
      } else {
        navigate("/dashboard");
      }
    })
  }

  const handleSignInLink = async () => {
    console.log("handleSignInLink bbb");

    if (!isSignInWithEmailLink(authInstance, url)) {
      console.log("isSignInWithEmailLink bbb");

      enqueueSnackbar("The link is invalid or expired. Please request a new one.", {
        variant: "error",
      });

      return;
    }

    const storedEmail = localStorage.getItem("emailForSignIn");
    console.log("storedEmail bbb", storedEmail);

    if (storedEmail ) {
      console.log("storedEmail bbb", storedEmail);
      completeSignIn(storedEmail);

    } else {
      setDialogOpen(true);
    }
  }

  const completeSignIn = async (email) => {
    console.log("completeSignIn bbb", email);

    try {
      const result = await signInWithEmailLink(auth, email, url);
      console.log("result bbb", result);

      localStorage.setItem("hasSignInUsingEmailAddress", "true");

      if (storedInvitationDetails) {
        handleAcceptInvitation();
      }
      
      enqueueSnackbar("Sign-in successful! Welcome back.", { variant: "success" });
      localStorage.removeItem("emailForSignIn");

    } catch (error) {
      enqueueSnackbar(`Sign-in failed: ${error.message}`, { variant: "error" });
    }
  }

  const handleEmailSubmit = () => {
    if (!email) {
      enqueueSnackbar("Please provide a valid email address.", { variant: "error" });
      return;
    }
    setDialogOpen(false);
    localStorage.setItem("emailForSignIn", email);
    completeSignIn(email);
  };


  return (
    <>
      <div
        style={{
          backgroundImage: `url(${require('../../../assets/images/background.png')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center', 
          margin: 'auto',
        }}
      >
        <div
          id="firebaseui-auth-container"
          style={{
            backgroundColor: '#fff',
            width: isMobile ? '90%' : '30%',
            height: isMobile ? '60%' : '50%',
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
          }}
        >
          <img src={superSignLogo} style={{width: isMobile ? {} : {}, height: isMobile ? {} : {}}} alt="" />
          <Typography sx={{ fontSize: isMobile ? 24 : 28, fontWeight: 'medium', mt: 3}}>
            Get started today with <br /> 100 free tokens.
          </Typography>
          <Typography sx={{ color: '#FF6600', fontSize: isMobile ? 16 : 18, mb: 3 }}>
            No credit card required.
          </Typography>
        </div>
        <div id="loader">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Rings color="#3498db" height={280} width={280} />
          </div>
        </div>
      </div>

      {isDialogOpen &&
        <VerifyEmailDialog
          isDialogOpen={isDialogOpen}
          setDialogOpen={setDialogOpen}
          email={email}
          setEmail={setEmail}
          handleEmailSubmit={handleEmailSubmit}
        />
      }

    </>
  );
};

export default FirebaseAuth;

