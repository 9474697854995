import React, { useEffect, useRef, useState } from "react";
import { Card, CardContent, Typography, Skeleton } from "@mui/material";
import Grid from '@mui/material/Grid2';
import useDashboard from "../hooks/useDashboard";
import DocumentRequiredActionScreen from "../../documents-plugin/pages/DocumentRequiredActionScreen";
import useWidth from "../../../core/utils/useWidth";

export default function DashboardStatusMetricsComponent() {
    const { getDashboardCount, isLoadingDashboardCount, dashboardCount } = useDashboard();
    const breakpoints = useWidth();

    const [isEmail, setIsEmail] = useState(JSON.parse(localStorage.getItem('isEmail')));
    const [isWorkspace, setIsWorkspace] = useState(JSON.parse(localStorage.getItem('isWorkspace')));
    const [menuSelected, setMenuSelected] = useState(localStorage.getItem('menuSelected'));

    // Ref to track previous state values and avoid infinite loop
    const prevIsEmail = useRef(isEmail);
    const prevIsWorkspace = useRef(isWorkspace);
    const prevMenuSelected = useRef(menuSelected);

    useEffect(() => {
        const syncLocalStorage = () => {
            const storedIsEmail = JSON.parse(localStorage.getItem('isEmail'));
            const storedIsWorkspace = JSON.parse(localStorage.getItem('isWorkspace'));
            const storedMenuSelected = localStorage.getItem('menuSelected');

            // Check if stored values are different from previous ones, update state if necessary
            if (storedIsEmail !== prevIsEmail.current) {
                setIsEmail(storedIsEmail);
                prevIsEmail.current = storedIsEmail;
            }

            if (storedIsWorkspace !== prevIsWorkspace.current) {
                setIsWorkspace(storedIsWorkspace);
                prevIsWorkspace.current = storedIsWorkspace;
            }

            if (storedMenuSelected !== prevMenuSelected.current) {
                setMenuSelected(storedMenuSelected);
                prevMenuSelected.current = storedMenuSelected;
            }
        };

        // Set interval to sync localStorage every 500ms
        const intervalId = setInterval(syncLocalStorage, 500);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);  // Empty dependency array to run once on mount

    useEffect(() => {
        let parameters = {};

        if (isEmail) {
            parameters = {};
        } else if (isWorkspace) {
            parameters.workspace_id = menuSelected ? menuSelected : null;
        }

        getDashboardCount(parameters);
    }, [isEmail, isWorkspace, menuSelected]);


    const cardData = [
        {
            title: "Draft",
            count: dashboardCount?.drafts,
            label: "Documents",
            bgColor: "#e6e2f5",
            borderColor: "#604CC3",
            textColor: "#9f92da",
            countColor: "#604CC3",
            link: '/documents?status=draft'
        },
        {
            title: "Ongoing",
            count: dashboardCount?.ongoing,
            label: "Documents",
            bgColor: "#efe7e9",
            borderColor: "#FFA500",
            textColor: "#f9bc51",
            countColor: "#FFA500",
            link: '/documents?status=ongoing'
        },
        {
            title: "Completed",
            count: dashboardCount?.completed,
            label: "Documents",
            bgColor: "#e0e5e9",
            borderColor: "#008000",
            textColor: "#4ea351",
            countColor: "#008000",
            link: '/documents?status=completed'
        },
        {
            title: "Required Action",
            count: dashboardCount?.required,
            label: "Documents",
            bgColor: "#e0e4f8",
            borderColor: "#0178F2",
            textColor: "#4f9ef4",
            countColor: "#0178F2",
            link: "/documents?status=required-action"
        },
        {
            title: "Cancelled",
            count: dashboardCount?.cancelled,
            label: "Documents",
            bgColor: "#c628281f",
            borderColor: "#c62828",
            textColor: "#C62828",
            countColor: "#C62828",
            link: '/documents?status=cancelled'
        },
        {
            title: "Denied",
            count: dashboardCount?.denied,
            label: "Documents",
            bgColor: "#e8ddeb",
            borderColor: "#830221",
            textColor: "#b26980",
            countColor: "#830221",
            link: '/documents?status=denied'
        },
        {
            title: "Expired",
            count: dashboardCount?.expired,
            label: "Documents",
            bgColor: "#efdde9",
            borderColor: "#FF0000",
            textColor: "#f94d51",
            countColor: "#FF0000",
            link: '/documents?status=expired'
        },
        {
            title: "Scheduled",
            count: dashboardCount?.scheduled,
            label: "Documents",
            bgColor: "#bdb6221a",
            borderColor: "#bdb622",
            textColor: "#bdb622",
            countColor: "#bdb622",
            link: '/documents?status=scheduled'
        },
    ];

    return (
        <Grid container spacing={3}>
            {cardData.map((card, index) => (
                <Grid key={index} size={{xs: 6, md: 3}}>
                    <Card variant="outlined" onClick={() => card.link ? window.location.href= card.link : null}
                        sx={{ 
                            height: "130px",
                            backgroundColor: card.bgColor, 
                            border: `0.1px solid ${card.borderColor}`, 
                            cursor: 'pointer',
                        }} 
                    >
                        <CardContent sx={{padding: (breakpoints == "md") ? "10px" : {}, pb: "16px !important" }}>
                            {isLoadingDashboardCount ? (
                                <>
                                    <Skeleton variant="text" width="60%" sx={{ backgroundColor: card.textColor }} />
                                    <Skeleton variant="text" width="40%" sx={{ fontSize: '30px', mb: 1, backgroundColor: card.textColor }} />
                                    <Skeleton variant="text" width="50%" sx={{ backgroundColor: card.textColor }} />
                                </>
                            ) : (
                                <>
                                    <Typography sx={{fontSize: (breakpoints == "md" || breakpoints == "lg") ? 16 : 18, color: card.textColor, textAlign: 'start', fontWeight: 'medium' }}>
                                        {card.title}
                                    </Typography>
                                    <Typography sx={{fontSize: (breakpoints == "md") ? 26 : 30, color: card.countColor, textAlign: 'start', fontWeight: "bold" }}>
                                        {card.count}
                                    </Typography>
                                    <Typography sx={{fontSize: (breakpoints == "md") ? 16 : 18, color: card.textColor, textAlign: 'start', fontWeight: 'medium' }}>
                                        {card.label}
                                    </Typography>
                                </>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}
