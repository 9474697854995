import {Box, Typography} from "@mui/material";
import OVCodeLogo from "../../../assets/logo/new-ovcode-reverse.png";

const FooterComponent = () => {

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="5vh"
            textAlign="start"
            sx={{backgroundColor: "#5b3cc4", paddingLeft: 2, paddingRight: 2, color: "#fff", bottom: 0, left: 0, right: 0, width: "100%", boxSizing: "border-box"}}
        >
            <Typography sx={{fontSize: 16}}>
                All rights reserved © SuperSign 2024
            </Typography>
            
            <Box sx={{flexGrow: 1}} />

            <Box display="flex" alignItems="center">
                <Typography sx={{fontWeight: 700, fontSize: 16}}>
                    Powered By
                </Typography>

                <img alt="" src={OVCodeLogo} style={{width: 120, marginLeft: 8}} />
            </Box>
        </Box>
    )
}

export default FooterComponent
