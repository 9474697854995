import React, {useEffect} from "react";
import Grid from "@mui/material/Grid2";
import {Box} from "@mui/material";
import CustomTypography from "../../../core/components/ui/CustomTypography";
import WorkspaceItemComponent from "../components/workspace-item.component";
import WorkspaceItemSkeleton from "../components/skeletons/workspace-item.skeleton";
import useWorkspaceStore from "../zustand/useUpdateWorkspaceStore";
import WorkspaceImage from "../../../assets/images/no-workspace.png";
import useUploadWorkspaceLogoStore from "../zustand/useUploadWorkspaceLogoStore";
import useWorkspaceManagement from "../hooks/useWorkspaceManagement";
import useCreatedWorkspaceStore from "../zustand/useCreatedWorkspaceStore";
import useWidth from "../../../core/utils/useWidth";

const MyWorkspaceComponent = () => {
    const breakpoints = useWidth();

    const {isFetchingWorkspaceList, getOwnAndSharedWorkspaceList, ownWorkspaceList} = useWorkspaceManagement();

    const {isWorkspaceUpdated} = useWorkspaceStore();
    const {isLogoSaved} = useUploadWorkspaceLogoStore();
    const {isWorkspaceCreated} = useCreatedWorkspaceStore();

    
    useEffect(() => {
        const parameters = {
            "page": 1,
            "limit": 10,    
            "name": " ",
        }

        getOwnAndSharedWorkspaceList(parameters);
    }, [isWorkspaceCreated, isWorkspaceUpdated, isLogoSaved])


    return (
        <> 
            <Grid container spacing={5} sx={{mt: 3}}>
                {(isFetchingWorkspaceList) ?
                    [0, 1, 2].map((index) => (
                        <Grid size={{xs: 12, sm: 12, md: 6, lg: 4, xl: 4}}>
                            <WorkspaceItemSkeleton index={index} />
                        </Grid> 
                    ))
                :
                    (!isFetchingWorkspaceList && ownWorkspaceList?.length > 0) ?
                        ownWorkspaceList?.map((workspace, index) => (
                            <Grid size={{xs: 12, sm: 12, md: 6, lg: 4, xl: 4}}>
                                <WorkspaceItemComponent 
                                    index={index} 
                                    workspace={workspace} 
                                    breakpoints={breakpoints}
                                />
                            </Grid>
                    ))
                :   
                    <Box sx={{margin: "auto", padding: "0px", mt: 3.5}}>
                        <Box>
                            <img alt="workspace-img" src={WorkspaceImage} style={{height: 340}} />
                                
                            <Box sx={{mt: -4.8}}>
                                <CustomTypography 
                                    variant="body1"  
                                    text={"No available owned workspaces to display."} 
                                    customStyles={{mb: 0, color: "rgb(0 0 0 / 44%)"}} 
                                />
                            </Box>
                        </Box>
                    </Box>
                }
            </Grid>
        </>
    )
}

export default MyWorkspaceComponent