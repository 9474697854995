import {create} from "zustand";

const useProfileStore = create((set) => ({
    isProfileUpdated: false,
    profileESignature: false,
    userProfileData: null,

    setProfileUpdated: (status) => set({isProfileUpdated: status}),
    setProfileESignature: (signature) => set({profileESignature: signature}),
    setUserProfileData: (data) => set({userProfileData: data}),
}));

export default useProfileStore