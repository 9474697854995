import {Tab, Tabs} from "@mui/material";
import {styled} from "@mui/material/styles";

const CustomTabs = styled(Tabs)({
  "& .MuiTab-root": {
    padding: "0px 15px 0px 15px",
    minHeight: 5,
    textTransform: "none",

    "&.Mui-selected": {
      color: "#604CC3",
      fontWeight: "500",
    },

    ":hover": {
      color: "#9a9a9a",
    },
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#604CC3",
  },      
});

const CustomTab = styled((props) => <Tab disableRipple {...props} />)(({theme, fontSize, marginRight}) => ({
  minWidth: "auto", 
  bgcolor: "#604CC3",
  borderRadius: 2,
  marginRight: marginRight || 12,
  fontWeight: "400",
  color: "#58565F",
  fontSize: fontSize || 16
}));

export {CustomTabs, CustomTab}