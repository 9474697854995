import {Box, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import unileverLogo from "../../../assets/logo/unilever.png";
import nextixLogo from "../../../assets/logo/nextix-logo.png";
import radixLogo from "../../../assets/logo/radix.png";
import flowserveLogo from "../../../assets/logo/flowserve.png";

const logos = [
    {src: flowserveLogo, alt: ""},
    {src: nextixLogo, alt: ""},
    {src: radixLogo, alt: ""},
    {src: unileverLogo, alt: ""},
]

const TrustedCompaniesComponent = ({breakpoints}) => {

    return (
        <Box sx={{display: "block", padding: "36px 30px 70px", width: (breakpoints == "xs") ? "90%" : "80%", margin: "auto"}}>   
            <Box mb={2}> 
                <Typography sx={{fontWeight: 700, fontSize: 45, color: "#5b3cc4"}}>
                    Trusted by companies that matter
                </Typography>

                <Typography variant="subtitle1" color="#FF6600" sx={{mb: 4, fontSize: "22px", mt: "-13px"}}>
                    Helping companies for their secure document workflows
                </Typography>
            </Box>

            <Grid container spacing={4} sx={{marginTop: 8}}>
                {logos.map((logo, index) => (
                    <Grid key={index} size={{xs: 6, sm: 3, md: 3}}>
                        <img src={logo.src} alt={logo.alt} style={{height: "60px"}} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default TrustedCompaniesComponent
