import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../firebaseConfig';
import { signInWithEmailLink } from 'firebase/auth';
import { Rings } from 'react-loader-spinner';
import { useSnackbar } from 'notistack';
import AuthenticationService from '../../profile-plugin/services/authentication-service';
import useMembersManagement from "../../workspace-plugin/hooks/useMembersManagement";

const AuthLinkScreen = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  
  const {acceptInvite} = useMembersManagement();

  const urlParams = new URLSearchParams(window.location.search);
  const oobCode = urlParams.get('oobCode');
  const email = localStorage.getItem('emailForSignIn');

  const storedInvitationDetails = JSON.parse(localStorage.getItem("invitationDetails"));


  useEffect(() => {
    console.log("oobCode:", oobCode);
    console.log("email:", email);
    console.log("Full URL:", window.location.href);

    if (oobCode && email) {
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          console.log("User signed in:", result.user);

          
          if (storedInvitationDetails) {
            handleAcceptInvitation();
            
          } else {
            handleRedirectAfterLoginSuccess();
          }

          localStorage.removeItem('emailForSignIn');
        })
        .catch((error) => {
          console.error("Error signing in:", error.message);
          enqueueSnackbar(error.message, { variant: "error", autoHideDuration: 3000 });
          // alert("Failed to sign in. The link may be invalid or expired.");
        });
    } else {
      // alert("Invalid link. Please try signing in again.");
      enqueueSnackbar("Invalid link. Please try signing in again.", { variant: "error", autoHideDuration: 3000 });

      navigate('/');
    }
  }, [oobCode, email, storedInvitationDetails]);

  const handleAcceptInvitation = () => {
    const formData = new FormData();
            
    formData.append("invitation_code", storedInvitationDetails?.invitation?.code);
    acceptInvite(formData, storedInvitationDetails?.workspace?.id);
  } 

  const handleRedirectAfterLoginSuccess = () => {
    AuthenticationService.getProfile().then((res) => {
      let isSignatureSetup = res.data.data.profile.signatureSetup
      if (!isSignatureSetup) {
        navigate("/documents/create");
      } else {
        navigate("/dashboard");
      }
    })
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Rings
        color="#3498db"
        height={80}
        width={80}
        ariaLabel="loading"
      />
      <p>Verifying your email...</p>
    </div>
  );
};

export default AuthLinkScreen;
