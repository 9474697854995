import {Tab, Tabs} from '@mui/material';
import {styled} from '@mui/material/styles';

const CustomTabs = styled(Tabs)({
  '& .MuiTab-root': {
    padding: '5px 15px 5px 15px',
    minHeight: 5,
    textTransform: 'none',
    // border: '1px solid #0178f238',
    backgroundColor: 'transparent',
    color: 'gray',

    '&.Mui-selected': {
      color: "#604CC3",
      // borderBottom: '2px solid #0178f2',
      // backgroundColor: '#0178f21c'
    },

    ':hover': {
      color: "#604CC3",
    },
  },       
});

const CustomTab = styled((props) => <Tab disableRipple {...props} />)(({theme}) => ({
  minWidth: "auto", 
  bgolor: "red",
  borderRadius: 2,
  marginRight: 10,
  fontWeight: "bold",
  color: "#000",
}));

export { CustomTabs, CustomTab };