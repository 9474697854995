import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import CustomDynamicTabsComponent from "../../../core/components/common/custom-dynamic-tabs.component";
import BackButtonComponent from "../../../core/components/common/custom-back-button.component";
import WorkspaceDetailsComponent from "../components/workspace-details.component";
import WorkspaceMembersComponent from "../components/workspace-members.component";
import {useNavigate} from "react-router-dom";
import WorkspaceProjectDetailsComponent from "../components/project/project-details.component";
import useSelectedWorkspaceStore from "../zustand/useSelectedWorkspaceStore";

const WorkspaceViewScreen = () => {

    const navigate = useNavigate();
    const {selectedWorkspace} = useSelectedWorkspaceStore();

    const workspaceId = new URLSearchParams(window.location.search).get("workspaceId");

    const [workspaceName, setWorkspaceName] = useState("");

    useEffect(() => {
        if (selectedWorkspace) {
            setWorkspaceName(selectedWorkspace?.name)
        }
    }, [selectedWorkspace])


    const handleBackButtonLabel = (child) => {
        setWorkspaceName(child);
    }

    const tabs = [
        {name: "Details", component: 
            <div>
                <WorkspaceDetailsComponent 
                    workspaceId={workspaceId} 
                    handleBackButtonLabel={handleBackButtonLabel} 
                />
            </div>
        },
        {name: "Members", component: <div><WorkspaceMembersComponent workspaceId={workspaceId} /></div>},
        {name: "OVCODE API Key", component: <div><WorkspaceProjectDetailsComponent workspaceId={workspaceId} /></div>}
    ]

   
    return (
        <Box sx={{margin: "0px auto auto"}}>
            <BackButtonComponent label={workspaceName} labelStyles={{color: "#58565F", fontWeight: "500", fontSize: "20px"}} 
                onClick={() => navigate("/workspaces")}
            />

            <Box sx={{backgroundColor: "#fff", borderRadius: "6px", padding: "24px 48px",
                border: "1px solid rgb(158 158 158 / 27%)",
                boxShadow: "0px 3px 6px -2px rgb(0 0 0 / 0%), 0px 5px 8px 0px rgb(0 0 0 / 0%), 0px 2px 12px 0px rgb(0 0 0 / 0%)",
                mt: "18px"
            }}>
                <CustomDynamicTabsComponent tabs={tabs} />
            </Box> 
        </Box>
    )
}

export default WorkspaceViewScreen