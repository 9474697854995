import { useSnackbar } from "notistack";
import OrderManagementService from "../services/api/order-management";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const useOrderManagement = () => {

    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();
    const [isCreatingOrder, setCreatingOrder] = useState(false);
    const [isFetchingOrders, setFetchingOrders] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [orderData, setOrderData] = useState("");
    const [orderCreated, setOrderCreated] = useState(false);
    const [isPlacingOrder, setPlacingOrder] = useState(false);
    const [isPaying, setPaying] = useState(false);
    const [orderDataArray, setOrderDataArray] = useState([]);
    const [isDeletingItem, setDeletingItem] = useState(false);
    const [isUpdatingItem, setUpdatingItem] = useState(false);
    const [isCreatingItem, setCreatingItem] = useState(false);
    const [orderLogs, setOrderLogs] = useState();
    const [itemDeleted, setItemDeleted] = useState(false);
    const [orderCancel, setOrderCancel] = useState(false);
    const [isCancelOrder, setCancelOrder] = useState(false);
    const [hasPending, setHasPending] = useState(false);
    const [isFetchingViewOrder, setFetchingViewOrders] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    


    const createOrder = (payload) => {
        setCreatingOrder(true);
        OrderManagementService.createOrder(payload).then((res) => {
            if (res.data.success) {
                console.log("createOrder data xxxx", res);
                // localStorage.removeItem('cartItems');
                // localStorage.removeItem('addedCustomAmount');
                // localStorage.removeItem('addedToCart');
                localStorage.removeItem('orderId');
                localStorage.removeItem('orderEmpty');
                localStorage.setItem('orderCreated', true);
                
                setOrderCreated(true);
                navigate(`checkout?orderId=${res.data.data.order.order_id}`);
            }

        }).catch((error) => {
            console.log("createOrder error xxxx", error);
            setCreatingOrder(false);
            setOrderCreated(false);
            const errorMessage = error?.response?.data?.error || error.message;
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    const getListOrder = (parameters) => {
        setFetchingOrders(true);
        OrderManagementService.listOrder(parameters).then((res) => {
            if (res.data.success) {
                console.log("getListOrder data xxxx", res);
                setFetchingOrders(false);
                setOrderList(res.data.data);
            }

        }).catch((error) => {
            console.log("getListOrder error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            setFetchingOrders(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    const getListOrderItems = (parameters) => {
        setFetchingOrders(true);
        OrderManagementService.listOrderItems(parameters).then((res) => {
            if (res.data.success) {
                console.log("getListOrderItems data xxxx", res);
                setFetchingOrders(false);
                setOrderList(res.data.data);
                
            }

        }).catch((error) => {
            console.log("getListOrderItems error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            setFetchingOrders(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }



    const viewOrder = (parameters) => {
        setFetchingOrders(true);
        setFetchingViewOrders(true);
        OrderManagementService.viewOrder(parameters).then((res) => {
            if (res.data.success) {
                console.log("viewOrder data xxxx", res);
                setFetchingOrders(false);
                setFetchingViewOrders(false);
                setOrderData(res.data.data.order);
                setOrderDataArray(res.data.data.items);
                setOrderLogs(res.data.data);
            }

        }).catch((error) => {
            console.log("viewOrder error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            setFetchingOrders(false);
            setFetchingViewOrders(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }


    const placeOrder = (payload, setOpenSnackbar) => {
        setPlacingOrder(true);
        OrderManagementService.placeOrder(payload).then((res) => {
            if (res.data.success) {
                console.log("placeOrder data xxxx", res);
                setPlacingOrder(false);
                localStorage.removeItem('cartItems');
                localStorage.removeItem('addedCustomAmount');
                localStorage.removeItem('addedToCart');
                localStorage.removeItem('orderCreated');
                localStorage.setItem('orderPlaced', true);
                // const cartItemsSerialized = JSON.stringify(res.data.data.items);
                // const encodedCartItems = btoa(cartItemsSerialized); 
                // navigate(`/place-order?orderData=${encodedCartItems}&orderId=${res.data.data.order.order_id}`);
                navigate(`/place-order?orderId=${res.data.data.order.order_id}`);

            }

        }).catch((error) => {
            console.log("placeOrder error xxxx", error);
            setPlacingOrder(false);
            const errorMessage = error?.response?.data?.error || error.message;
            if (errorMessage === `You have exceeded the limit of 3 'For Payment' orders. Please complete payment or cancel existing orders to continue.`) {
                setOpenSnackbar(true);
                setErrorMessage(errorMessage);
            } else {
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    autoHideDuration: 2000
                })
            }
            
        })
    }

    const payOrder = (parameters) => {
        setPaying(true);
        OrderManagementService.payOrder(parameters).then((res) => {
            if (res.data.success) {
                console.log("payOrder data xxxx", res);
                localStorage.removeItem('cartItems');
                localStorage.removeItem('addedCustomAmount');
                localStorage.removeItem('addedToCart');
                setPaying(false);
                localStorage.removeItem('cartItems');
                localStorage.removeItem('orderId');
                localStorage.removeItem('orderPlaced');
                window.location.href = `${res.data.data.link}`;
            }

        }).catch((error) => {
            console.log("payOrder error xxxx", error);
            setPaying(false);
            const errorMessage = error?.response?.data?.error || error.message;
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    const createItem = (payload) => {
        // setCreatingOrder(true);
        OrderManagementService.createItem(payload).then((res) => {
            if (res.data.success) {
                console.log("createItem data xxxx", res);
                // setCreatingOrder(false);
                setOrderCreated(true);
                localStorage.setItem('itemCreated', true);
                // navigate(`checkout?orderId=${res.data.data.order.order_id}`);
            }

        }).catch((error) => {
            console.log("createItem error xxxx", error);
            // setCreatingOrder(false);
            setOrderCreated(false);
            const errorMessage = error?.response?.data?.error || error.message;
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    const updateItem = (payload) => {
        setUpdatingItem(true);
        OrderManagementService.updateItem(payload).then((res) => {
            if (res.data.success) {
                console.log("updateItem data xxxx", res);
                setUpdatingItem(false);
                localStorage.setItem('itemCreated', true);
            }

        }).catch((error) => {
            console.log("updateItem error xxxx", error);
            setUpdatingItem(false);
            const errorMessage = error?.response?.data?.error || error.message;
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    const deleteItem = (payload) => {
        setDeletingItem(true);
        OrderManagementService.deleteItem(payload).then((res) => {
            if (res.data.success) {
                console.log("deleteItem data xxxx", res);
                if(res?.data?.data?.items?.length === 0){
                    localStorage.setItem('orderEmpty', true);
                }
                setDeletingItem(false);
                setItemDeleted(true);
                enqueueSnackbar('Successfully deleted item.', {
                    variant: "success",
                    autoHideDuration: 2000
                })
                window.location.reload();
            }

        }).catch((error) => {
            console.log("deleteItem error xxxx", error);
            setDeletingItem(false);
            setItemDeleted(false);
            const errorMessage = error?.response?.data?.error || error.message;
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }


    const deleteItemv2 = (payload, setIsCartEmpty) => {
        setDeletingItem(true);
        OrderManagementService.deleteItem(payload).then((res) => {
            if (res.data.success) {
                console.log("deleteItem data xxxx", res);
                if(res?.data?.data?.items?.length === 0){
                    setIsCartEmpty(true);
                    localStorage.setItem('orderEmpty', true);
                }
                setDeletingItem(false);
                setItemDeleted(true);
                enqueueSnackbar('Successfully deleted item.', {
                    variant: "success",
                    autoHideDuration: 2000
                })
                // window.location.reload();
            }

        }).catch((error) => {
            console.log("deleteItem error xxxx", error);
            setDeletingItem(false);
            setItemDeleted(false);
            const errorMessage = error?.response?.data?.error || error.message;
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }


    const cancelOrder = (parameters) => {
        setCancelOrder(true);
        OrderManagementService.cancelOrder(parameters).then((res) => {
            if (res.data.success) {
                console.log("cancelOrder data xxxx", res);
                setCancelOrder(false);
                setOrderCancel(true);
                localStorage.removeItem('orderId');
                localStorage.removeItem('orderEmpty');
                // navigate("/dashboard", { replace: true });
            }
        }).catch((error) => {
            console.log("cancelOrder error xxxx", error);
            setCancelOrder(false);
            setOrderCancel(false);
            const errorMessage = error?.response?.data?.error || error.message;
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }
    

    const orderListStatus = (payload) => {
        OrderManagementService.orderListStatus(payload).then((res) => {
            console.log("orderListStatus data xxxx", res);
            if (res.data.success) {
                const orderId = res.data.data[0].order_id
                localStorage.setItem('orderId', orderId);
            }
        }).catch((error) => {
            console.log("orderListStatus error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            if(errorMessage !==  `Cannot read properties of undefined (reading 'order_id')`){
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    autoHideDuration: 2000
                })
            }
        })
    }

    const pendingOrder = (parameters) => {
        OrderManagementService.pendingOrder(parameters).then((res) => {
            if (res.data.success) {
                console.log("pendingOrder data xxxx", res);
                if(res.data.data === false){
                    setHasPending(false);
                    localStorage.removeItem('orderId');
                    localStorage.removeItem("addedToCart");
                }else{
                    const orderId = res.data.data.order && res.data.data.order.order_id;
                    if(orderId){
                        setHasPending(true);
                        localStorage.setItem('orderId', orderId);
                    } else {
                        setHasPending(false);
                        localStorage.removeItem('orderId');
                    }
                }
            }
        }).catch((error) => {
            console.log("pendingOrder error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            if(errorMessage !== "No pending order" ){
                setHasPending(false);
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    autoHideDuration: 2000
                })
            }
        })
    }

    return { createOrder, isCreatingOrder, getListOrder, isFetchingOrders, orderList, viewOrder, orderData,
        placeOrder, orderCreated, payOrder, isPlacingOrder, isPaying, orderDataArray, itemDeleted, orderCancel,
        createItem, updateItem, deleteItem, isDeletingItem, isUpdatingItem, isCreatingItem, orderLogs, cancelOrder,
        orderListStatus, isCancelOrder, pendingOrder, getListOrderItems, hasPending, isFetchingViewOrder, errorMessage,
        deleteItemv2
     }

}

export default useOrderManagement