import { useEffect, useState } from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import { Box, Typography, Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import { format } from 'date-fns';
import useDocument from '../../hooks/useDocument';
import CustomDialog from '../../../../core/components/common/custom-dialog.component';

const getStatusColor = (status) => {
    switch (status) {
        case 'PENDING':
        case 'ONGOING':
        case 'RESEND':
            return '#f3bb1b';
        case 'CREATE':
        case 'CREATED':
            return '#0178f2';
        case 'CANCELLED':
            return '#C62828';
        case 'UPDATE':
            return '#fd8e39';
        case 'DENIED':
        case 'ERROR':
        case 'DECLINED':
            return '#b26980';
        case 'COMPLETED':
            return '#4ea351';
        case 'DRAFT':
            return '#604CC3';
        case 'SCHEDULED':
            return '#bdb622';
        default:
            return 'gray';
    }
};

const CustomTimelineItem = styled(TimelineItem)(({ theme }) => ({
    '&:before': {
        content: 'none',
    },
}));

const SkeletonTimeline = () => (
    <Timeline position="right">
        {[1, 2, 3].map((_, index) => (
            <CustomTimelineItem key={index}>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot>
                        <Skeleton variant="circular" width={24} height={24} />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Skeleton width="60%" />
                    <Skeleton width="40%" />
                    <Skeleton width="80%" />
                </TimelineContent>
            </CustomTimelineItem>
        ))}
    </Timeline>
);

export default function DocumentHistoryDialog(props) {
    const { openHistory, handleCloseHistoryDialog, document_id, document_title } = props;
    const { getDocumentHistory, isFetchingHistory, historyData } = useDocument();

    useEffect(() => {
        if (document_id) {
            getDocumentHistory({ document_id });
        }
    }, [document_id]);

    console.log('historyData hereee', historyData)

    const content = isFetchingHistory ? (
        <SkeletonTimeline />
    ) : (
        <Timeline position="right">
            {historyData.map((item, index) => (
                <CustomTimelineItem key={index}>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot sx={{ bgcolor: getStatusColor(item.status) }} />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="body2" color="text.secondary">
                            {format(new Date(item && item?.updated_time), 'PPpp')}
                        </Typography>
                        <Typography sx={{ fontSize: 18 }}>
                            {item.status}
                        </Typography>
                        <Typography sx={{ fontSize: 14 }}>{item.activity} by {item.email}</Typography>
                    </TimelineContent>
                </CustomTimelineItem>
            ))}
        </Timeline>
    );

    return (
        <CustomDialog
            open={openHistory}
            onClose={handleCloseHistoryDialog}
            title={`${document_title} Document History`}
            content={content}
            isAction={false}
        />
    );
}
