import { Box, Typography } from "@mui/material";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { Page } from 'react-pdf';
import { v4 as uuidv4 } from 'uuid';
import { useElementHook } from "../hooks/useElementHook";
import { useDropHook } from "../hooks/useDropHook";
import { getAdjFontSize } from "../../../../core/utils/FontUtil";
import { elementTypes } from "../common/staticVariables";
import DocumentElementSigneeContainerComponent from "./document-element-signee-container.component";
import useFillableElementsStore from "../zustand/useFillableElementsStore";


let pagesArr = []
let elements_from_api = []

export default function DocumentSigneePageComponent({ index, numPages, page_number, adjustedPDFWidth, elementProps, isViewing, onChangeSignature, onUpdateSignatoryCounter,
    setFillableTextfield,setFillableDate, scrollElements, setCurrentElement, currentElement
 }) {

    const [currentPage, setCurrentPage] = useState({})
    // const [currentElement, setCurrentElement] = useState(null)
    const [pdfPages, setPdfPages] = useState([])
    const [hasUpdatedUserSigOnLoad, setHasUpdatedUserSigOnLoad] = useState(false)
    const updateFillableElement = useFillableElementsStore((state) => state.updateFillableElement)
    const fillable_elements = useFillableElementsStore((state) => state.fillable_elements)

    const pdfPageRef = useRef()

    const { dndRef, dropResult } = useDropHook(pdfPageRef, "pdfSignee")

    const { getProportionalElementCoords, getReverseProportionElementCoords, getReverseProportionElementDimens, getProportionalElementDimens, getProportionalElementFontSize, getReverseProportionalElementFontSize } = useElementHook(pdfPageRef, currentPage)

    const elements = elementProps.elements
    const elements_to_plot = elementProps.elements_to_plot
    const setElements = elementProps.setElements
    const pdfResult = elementProps.pdfResult
    const setPDFResult = elementProps.setPDFResult
    const signatory = elementProps.signatory
    const currentUser = elementProps.currentUser
    const currentUserSignature = elementProps.currentUserSignature
    const elRefs = elementProps.elRefs

    useEffect(() => {
        console.log("fillable_elements a1", fillable_elements);
    }, [JSON.stringify(fillable_elements)])

    useEffect(() => {
        console.log("elements mx", elements);
    }, [JSON.stringify(elements)])

    // handle PDF Pages
    useEffect(() => {
        if (elements_to_plot?.length > 0 && pagesArr?.length > 0) {
            elements_to_plot.forEach((element) => {
                console.log("elements_to_plot in PDFSigneeComponent: ", elements_to_plot)
                let page = pagesArr.find((item) => item.pageNo == element.page_no)
                console.log("page in PDFSigneeComponent: ", page)

                if (element.page_no == page.pageNo) {
                    console.log("elements_to_plot page: ", page, element.page_no)

                    let rotation = page.rotate

                    let { x, y } = getReverseProportionElementCoords(element.x, element.y, page.dropRefCurrentWidth, page.dropRefCurrentHeight, rotation, page.pageOrigWidth, page.pageOrigHeight)
                    let { w: width, h: height } = getReverseProportionElementDimens(rotation, page.dropRefCurrentWidth, page.dropRefCurrentHeight, page.pageOrigWidth, page.pageOrigHeight, element.width, element.height)
                    let { font_size } = getReverseProportionalElementFontSize(rotation, page.dropRefCurrentWidth, page.pageOrigWidth, page.pageOrigHeight, element.font_size)
                    console.log("proportional_font_size bbb 111", font_size, element.type);


                    console.log("sign element page: ", element.type)
                    console.log("sign x page: ", x)
                    console.log("sign y page: ", y)
                    console.log("sign width page: ", width)
                    console.log("sign height page: ", height)


                    element = { ...element, id: element.id != undefined ? element.id : uuidv4(), x, y, width, height };
                    if (element.type == elementTypes.currentDate || element.type == elementTypes.textField || element.type == 'fillable_textfield' || element.type == 'fillable_date') {
                        element.font_size = font_size
                    }
                    elements_from_api.push(element)
                }
            })
            let uniqueJsonArray = Array.from(new Set(elements_from_api.map(obj => JSON.stringify(obj))), JSON.parse);
            console.log("elements_from_api aaaa", uniqueJsonArray);
            setElements(uniqueJsonArray)
        }
    }, [JSON.stringify(pagesArr), JSON.stringify(elements_to_plot)])

    // handle create/update elements
    useEffect(() => {
        console.log("current element aa", currentElement);
        if (currentElement) handleElement(currentElement)
    }, [JSON.stringify(currentElement)])

    // handle update PDF Result after updating elements
    useEffect(() => {
        console.log("elements lll", elements);
        if (elements.length > 0) {
            handlePDFResult(elements)
            handleUpdateSignatoryCounter(elements)
        }
        else
            setPDFResult({ ...pdfResult, elements: [], total_page: numPages })
    }, [JSON.stringify(elements)])

    function handleOnLoadSuccessPDFPage(page) {
        console.log("current page kkk", page);
        setCurrentPage(page)
        handleLoadInitialElements(page)
        const elements = document.getElementsByClassName("react-pdf__Page__textContent");

        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }
    }

    function getFontSizes(element, currentPage) {
        var orig_font_size = element.font_size ? element.font_size : 16; // 16px as default
        var adjusted_font_size = getAdjFontSize(orig_font_size, currentPage.originalWidth, currentPage.width);
        element.font_size = orig_font_size;
        element.adjusted_font_size = adjusted_font_size;
        return element;
    }

    const handleLoadInitialElements = (page) => {
        let rotate = page?.rotate

        let isLandscape = rotate == 90 || rotate == 270 ? true : false
        let pageWidth = isLandscape ? page?.height : page?.width
        let pageHeight = isLandscape ? page?.width : page?.height

        let dropRefCurrentWidth = pageWidth
        let dropRefCurrentHeight = pageHeight

        let pageOrigWidth = page?.originalWidth
        let pageOrigHeight = page?.originalHeight - page?.view[1]
        let pageNo = page?.pageNumber
        let originalWidth = pageOrigWidth
        let originalHeight = pageOrigHeight
        let width = page?.width
        let height = page?.height

        let pdf_page = { dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, pageNo, rotate, originalWidth, originalHeight, width, height }
        pagesArr.push(pdf_page)

    }

    const handleCurrentElement = (element) => {
        setCurrentElement(element)
    }

    const handlePDFResult = (elements) => {
        let pdf_result_elements = elements.map((el) => handleGetPDFResultElement(el))
        setPDFResult({ ...pdfResult, elements: pdf_result_elements, signatory, total_page: numPages })
    }

    const handleUpdateSignatoryCounter = (elements) => {
        let signatories = elements.filter((el) => el.type == elementTypes.signatory);
        let signatories_counter = { remainingSig: 0, totalSig: signatories.length };

        if (signatories.length > 0) {
            let hasSignedCounter = signatories.filter((el) => el.isSigned)
            signatories_counter.remainingSig = hasSignedCounter.length
        }
        onUpdateSignatoryCounter(signatories_counter)
    }

    const handleGetPDFResultElement = (element) => {
        console.log("element nnnn1", element);

        let current_page = pdfPages.find((page) => page.pageNo == element.pageNumber)
        
        let { x, y } = getProportionalElementCoords(element.x, element.y)
        let { w: width, h: height } = getProportionalElementDimens(element.width, element.height)
        
        let updated_element_props = {
            id: element.id,
            type: element.type,
            value: element.value,
            fillable_value: element.fillable_value,
            xAxis: x.toString(),
            yAxis: y.toString(),
            pageNo: element.page_no,
            width: width.toString(),
            height: height.toString(),
            fontSize: element.font_size,
            date_format: element.date_format,
            assign_color: element.assign_color,
            isSigned: element.isSigned,
            signature: element.signature,
            disabled: element.disabled,
        }

        if(element.type == 'fillable_textfield' || element.type == 'fillable_date'){
            let el_font_size = updated_element_props.fontSize
            console.log("el_font_size xx", el_font_size);
            
            let {font_size: proportional_font_size} = getProportionalElementFontSize(el_font_size)
            updated_element_props.fontSize = proportional_font_size.toString();
            console.log("updated_element_props xx", updated_element_props);
        }

        return updated_element_props
    }

    const handleElement = (currentElement) => {
        console.log("currentElement oooo", currentElement);
            setElements((prev) => prev.map(el => {
                let updatedEl
                if (el.id == currentElement.id) {
                    updatedEl = currentElement
                } else {
                    updatedEl = el
                }
                return updatedEl
            }))


        updateFillableElement(currentElement)
    }

    const handleOnEditElement = () => {

    }

    
    return (
        <>
            <p style={{ color: '#5e5d5d' }}>Page {currentPage?.pageNumber} / {numPages}</p>
            <Page inputRef={dndRef} className={'pdf_page_custom'} pageNumber={page_number}
                width={adjustedPDFWidth}
                scale={1}
                onLoadSuccess={handleOnLoadSuccessPDFPage}>
                {
                    elements.map((el, i) => {
                        return (
                            <>
                                {(el.page_no == page_number) &&
                                    <>
                                        <DocumentElementSigneeContainerComponent
                                            index={index}
                                            element={el}
                                            currentPage={currentPage}
                                            setCurrentElement={setCurrentElement}
                                            onEditElement={handleOnEditElement}
                                            currentUser={currentUser}
                                            currentUserSignature={currentUserSignature}
                                            elRefs={elRefs}
                                            isViewing={isViewing}
                                            signatory={signatory}
                                            onChangeSignature={onChangeSignature}
                                            setFillableTextfield={setFillableTextfield}
                                            setFillableDate={setFillableDate}
                                            scrollElements={scrollElements}
                                        />
                                    </>
                                }
                            </>
                        )
                    })
                }
            </Page>
        </>
        
    );
}
