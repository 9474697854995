import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Card, CardContent, CardActions, Box, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { stringAvatar } from "../../../core/utils/avatarImageUtil";
import CustomTypography from "../../../core/components/ui/CustomTypography";
import CustomMenuPopperComponent from "../../../core/components/common/custom-menu-popper.component";
import EditWorkspaceComponent from "./workspace-edit.component";
import UploadLogoIcon from "./../../../assets/icons/upload-logo.png";
import CustomFileUploaderComponent from "../../../core/components/common/custom-file-uploader.component";
import useWorkspaceManagement from "../hooks/useWorkspaceManagement";
import useUploadWorkspaceLogoStore from "../zustand/useUploadWorkspaceLogoStore";
import useSelectedWorkspaceStore from "../zustand/useSelectedWorkspaceStore";


const WorkspaceItemComponent = (props) => {
  const {workspace, index, breakpoints} = props;

  const navigate = useNavigate();
  const {isUpdatingLogo, uploadWorkspaceLogo, getWorkspaceRBACPermissions, filteredWorkspaceRoles} = useWorkspaceManagement();
  const {uploadedWorkspaceLogo} = useUploadWorkspaceLogoStore();
  const {setSelectedWorkspace} = useSelectedWorkspaceStore();

  const [open, setOpen] = useState(false);
  const [workspaceId, setWorkspaceId] = useState(null);
  const [openUploadLogo, setOpenUploadLogo] = useState(false);

  const [canUploadLogo, setCanUploadLogo] = useState(false);
  const [canEditWorkspace, setCanEditWorkspace] = useState(false);
  const [canViewWorkspace, setCanViewWorkspace] = useState(false);


  useEffect(() => {
    if (workspace) {
      const parameters = {
        workspace_id: workspace?.workspace?.id
      }

      getWorkspaceRBACPermissions(parameters);
    }
  }, [workspace]);

  useEffect(() => {
    if (filteredWorkspaceRoles?.length) {
      const userPermissions = {
        canUploadLogo: filteredWorkspaceRoles?.some(role => role["WORKSPACE_UPLOAD_LOGO"]),
        canEditWorkspace: filteredWorkspaceRoles?.some(role => role["WORKSPACE_UPDATE"]),
        canViewWorkspace: filteredWorkspaceRoles?.some(role => role["WORKSPACE_VIEW"])
      }

      setCanUploadLogo(userPermissions?.canUploadLogo);
      setCanEditWorkspace(userPermissions?.canEditWorkspace);
      setCanViewWorkspace(userPermissions?.canViewWorkspace);
    }
  }, [filteredWorkspaceRoles]);


  const menuItems = [
    ...(canUploadLogo ? [{
      icon: <img alt="" src={UploadLogoIcon} style={{ height: 16 }} />,
      label: (workspace?.workspace?.logo) ? "Update Logo" : "Upload Logo",
      onClick: () => setOpenUploadLogo(true),
    }] : []),

    ...(canEditWorkspace ? [{
      icon: <EditIcon fontSize="small" sx={{ color: "#604CC3" }} />,
      label: "Edit Workspace",
      onClick: () => setOpen(true),
    }] : []),
  ]

  const handleWorkspaceClick = (e) => {
    if (canViewWorkspace) {
      navigate("view?workspaceId=" + workspace?.workspace?.id);
      setSelectedWorkspace(workspace?.workspace);
    }
  }

  const handleClickMenu = (e, workspace_id) => {
    e.stopPropagation();
    setWorkspaceId(workspace_id);
  }

  const handleSave = () => {
    let formData = new FormData();

    formData.append("file", uploadedWorkspaceLogo);
    formData.append("workspace_id", workspace?.workspace?.id);

    uploadWorkspaceLogo(formData, workspaceId);
  }


  return (
    <>
      <Card key={index} variant="outlined" aria-disabled={!canViewWorkspace}
        sx={{
          cursor: (canViewWorkspace) ? "pointer" : "not-allowed", maxWidth: {}, justifyContent: "space-between",
          border: "none",
          boxShadow: "0px 3px 6px -2px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.08), 0px 2px 12px 0px rgba(0, 0, 0, 0.05)",
          "&:hover": {
            transition: "box-shadow 0.3s ease-in-out",
            boxShadow: "rgb(100 100 111 / 22%) 0px 7px 32px 0px"
          },
          transition: "box-shadow 0.3s ease-in-out",
        }}
        onClick={(e) => handleWorkspaceClick(e)}
      >
        <CardContent>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              variant="square"
              src={workspace?.workspace.logo || null}
              {...(workspace?.workspace.logo ? {} : stringAvatar(workspace?.workspace.name))}
              style={{ width: (workspace?.logo) ? 50 : 50, height: 40, marginRight: 8 }}
              slotProps={{ img: { style: { objectFit: (workspace?.workspace.logo) ? "contain" : {} } } }}
            />

            <CustomTypography
              variant="subtitle1"
              text={workspace?.workspace.name}
              customStyles={{ fontWeight: "500", margin: 0, fontSize: (breakpoints == "md") ? 16 : 18, color: "#58565F" }}
            />

            {(!canUploadLogo && !canEditWorkspace) ?
              null
              :
              <Box sx={{ ml: "auto !important" }} onClick={(e) => handleClickMenu(e, workspace?.workspace.id)}>
                <CustomMenuPopperComponent menuItems={menuItems} />
              </Box>
            }
          </Box>
        </CardContent>

        <CardActions sx={{ padding: 2 }}>
          <Box sx={{ textAlign: "start" }}>
            <CustomTypography
              variant="caption"
              style={{ margin: 0 }}
              text={
                  (workspace?.workspaceMember.role === "WSAdmin") ? "Admin" 
                :
                  (workspace?.workspaceMember.role === "WSMember") ? "Member" 
                :  
                  (workspace?.workspaceMember.role === "WSGuest") ? "Signee" 
                : 
                  "N / A"
              }
            />
          </Box>

          <Box sx={{ ml: "auto !important" }}>
            <Button
              disableElevation
              disableTouchRipple
              size="small"
              variant="contained"
              sx={{
                textTransform: "none",
                backgroundColor: workspace?.workspace.status === "Active" ? "#008000" : "#8c8c8c",
                color: "#fff",
                marginRight: 1,
                borderRadius: "2px",
                fontWeight: "inherit",
                cursor: "default"
              }}
            >
              {workspace?.workspace.status}
            </Button>
          </Box>
        </CardActions>
      </Card>

      {open &&
        <EditWorkspaceComponent
          open={open} setOpen={setOpen}
          workspaceId={workspaceId}
        />
      }

      {openUploadLogo &&
        <CustomFileUploaderComponent
          open={openUploadLogo}
          setOpen={setOpenUploadLogo}
          title={(workspace?.workspace?.logo) ? "Update Logo" : "Upload Logo"}
          maxWidth="sm"
          handleSave={handleSave}
          isLoading={isUpdatingLogo}
        />
      }
    </>
  )
}

export default WorkspaceItemComponent






