import {create} from 'zustand';

// Create the Zustand store
const useFillableElementsStore = create((set) => ({

  fillable_elements: [],
  addFillableElements: (fillable_element) => set((state) => {
    const isExists = state.fillable_elements.some((el) => el.id === fillable_element.id);
        if (!isExists) {
            return { fillable_elements: [...state.fillable_elements, fillable_element]};
        }
        return state.fillable_elements
  }),
  setFillableElements: (fillable_elements) => set({ fillable_elements }),
  updateFillableElement: (fillable_element) => set((state) => {
    console.log("fillable_element bbbb", fillable_element);
    
    let updated_fillable_elements = state.fillable_elements.map((el) => {
      if (el.id == fillable_element.id) {
        el = fillable_element
      }
      return el
    })
    return { fillable_elements: updated_fillable_elements};
  }),
  removeSignatory: (id) => set((state) => ({ fillable_elements: state.fillable_elements.filter((el) => el.id !== id) })),
}));

export default useFillableElementsStore;
