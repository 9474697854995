import React from "react";
import CustomDialog from "../../../../core/components/ui/CustomDialog";
import { Box, CircularProgress } from "@mui/material";
import CustomTypography from "../../../../core/components/ui/CustomTypography";
import CustomDialogLoaderComponent from "../../../../core/components/common/custom-dialog-loader.component";
import useEnterpriseProjectManagement from "../../hooks/useEnterpriseProjectManagement";

const DeleteProjectDialog = (props) => {
    const {open, setOpen, projectDetails} = props;

    const {isDeletingProject, deleteWorkspaceProject} = useEnterpriseProjectManagement();

    const handleClose = () => {
        setOpen(false);
    }

    const handleDeleteProject = () => {
        const parameters = {id: projectDetails?.id};
    
        deleteWorkspaceProject(parameters, setOpen);
    }

    const actions = [
        {
            label: "Cancel",
            variant: "text",
            onClick: () => {
                setOpen(false);
            },
        },
        {
            label: "Confirm",
            onClick: () => {
                handleDeleteProject();
            },
        }
    ]

    const globalSx = {
        actions: {padding: "15px 20px 30px 30px"}
    }


    return (
        <>
            <CustomDialog
                open={open}
                onClose={(isDeletingProject) ? null : handleClose}
                title="Delete Confirmation"
                content={
                    <Box>
                        {(isDeletingProject) ?
                            <CustomDialogLoaderComponent
                                icon={<CircularProgress size={40} sx={{ color: "#604CC3" }} />}
                                message={"Deleting..."}
                            />
                        :
                            <>
                                <CustomTypography
                                    variant="subtitle1"
                                    text={`Are you sure you want to remove <strong>${projectDetails?.project_name}</strong> from workspace?`}
                                    customStyles={{marginBottom: 0, fontWeight: 400, color: "#454349", fontSize: 18}}
                                />

                                <CustomTypography
                                    variant="subtitle1"
                                    text={`Please note that once its API key is removed, tokens from the workspace will be used for authentication instead of credits.`}
                                    customStyles={{marginBottom: 0, fontWeight: 400, color: "#ff2121", fontSize: 16, marginTop: "16px", fontStyle: "italic"}}
                                />
                            </>
                        }
                    </Box>
                }
                actions={(!isDeletingProject && actions) ? actions : []}
                maxWidth={"sm"}
                customStyles={{
                    actions: {
                        opacity: (!isDeletingProject) ? "100%" : "55%",
                        cursor: (!isDeletingProject) ? "pointer" : "not-allowed",
                        visibility: (isDeletingProject) ? "hidden" : "visible",
                    }
                }}
                globalSx={globalSx}
            />
        </>
    )
}

export default DeleteProjectDialog