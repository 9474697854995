const WORKSPACE_MANAGEMENT_API_ENDPOINT =  process.env.REACT_APP_WORKSPACE_MANAGEMENT_API_ENDPOINT;

export default {
    // WORKSPACE MANAGEMENT
    LIST_WORKSPACE:                                                 WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace/list",
    CREATE_WORKSPACE:                                               WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace/create",
    VIEW_WORKSPACE:                                                 WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace/view",
    EDIT_WORKSPACE:                                                 WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace/update",
    UPLOAD_WORKSPACE_LOGO:                                          WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace/logo/upload",
    GET_WORKSPACE_ROLES:                                            WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace/roles",
    GET_WORKSPACE_RBAC_PERMISSIONS:                                 WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/rbac/permissions",
    GET_OWN_AND_SHARED_WORKSPACE:                                   WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace/list/separate",
    GET_WORKSPACE_ROLE:                                             WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace/role",

    // MEMBERS MANAGEMENT
    LIST_MEMBERS:                                                   WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace-members/list",
    INVITE_MEMBER:                                                  WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace-members/invite",
    ACCEPT_INVITE:                                                  WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace-members/invite/accept",
    LIST_INVITED_MEMBERS:                                           WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace-members/list/invite",
    GET_VIEW_MEMBER_DETAILS:                                        WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace-members/view",  
    COUNT_MEMBERS:                                                  WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace-members/count",
    LIST_MEMBERS_EMAIL:                                             WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace-members/list/email",
    DECLINE_INVITE:                                                 WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/public/workspace-members/invite/decline",                                                        
    DELETE_MEMBER:                                                  WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace-members/delete",
    GET_INVITATION_DETAILS:                                         WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/public/workspace-members/invite/code",
    CANCEL_INVITATION:                                              WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace-members/invite/cancel",
    RESEND_INVITATION:                                              WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace-members/invite/retry",
    COUNT_REMAINING_WORKSPACE_MEMBERS:                              WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace-members/remaining/count",
    UPDATE_WORKSPACE_MEMBER_STATUS:                                 WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace-members/update/status",
    UPDATE_WORKSPACE_MEMBER_ROLE:                                   WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace-members/update/role",
    LIST_WSGUEST_SIGNEE:                                            WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace-members/signee/list",
    ADD_WSGUEST_SIGNEE:                                             WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace-members/signee/add",
    DELETE_WSGUEST_SIGNEE:                                          WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace-members/signee/delete",

    // ENTERPRISE PROJECT MANAGEMENT
    GET_PROJECT_API_KEY:                                            WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace/project/api",
    ADD_PROJECT:                                                    WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace/project/create",
    GET_PROJECT_DETAILS:                                            WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace/project/view",    
    CHANGE_PROJECT_KEYS:                                            WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace/project/update",  
    DELETE_WORKSPACE_PROJECT:                                       WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace/project/delete",
    GET_PROJECT_CREDITS:                                            WORKSPACE_MANAGEMENT_API_ENDPOINT + "/api/v1/workspace/project/credits/view",
    
}