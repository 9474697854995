import React from "react";
import {Skeleton, Box} from "@mui/material";

const WorkspaceViewMemberSkeleton = () => {

    return (
        <>
            <Box>
                <Box sx={{padding: "0px 40px 40px"}}>
                    <Box sx={{display: "flex", textAlign: "center"}}>
                        <Skeleton variant="circular" sx={{backgroundColor: "#eeebf8", mr: "8px"}} height={120} width={120} />
                               
                        <Box sx={{display: "block", textAlign: "start", mt: "30px", ml: "18px"}}>
                            <Skeleton variant="text" sx={{backgroundColor: "#eeebf8", mt: "-5px"}} height={40} width={300} />

                            <Skeleton variant="text" sx={{backgroundColor: "#eeebf8"}} height={40} width={300} />
                        </Box>
                    </Box>
                </Box>
            </Box> 
        </>
    )
}

export default WorkspaceViewMemberSkeleton
