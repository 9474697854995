import { Avatar, Box, Button, Divider, Fade, IconButton, InputAdornment, Link, MenuItem, Skeleton, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { stringAvatar, stringToAvatarEmail } from "../../../core/utils/avatarImageUtil";
import CustomButton from "../../../core/components/ui/CustomButton";
import CustomTypography from "../../../core/components/ui/CustomTypography";
import CustomTextField from "../../../core/components/ui/CustomTextField3";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import ProfileDetailsSkeletonComponent from "./skeletons/profile-details.skeleton.component";
import { removeBackground } from "../../../core/utils/removeBackground";
import useAuthentication from "../hooks/useAuthentication";
import AuthenticationService from "../services/authentication-service";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CreateEsignatureCommon from "../../../core/components/common/digital-signature/create-esignature-common";
import useDigitalSignature from "../../supersign-docs-plugin/document-editor-plugin/zustand/useDigitalSignature";
import supersignLogo from '../../../assets/icons/supersign-logo.png';
import { useNavigate } from "react-router-dom";



export default function ProfileComponent(props) {

    const { profile, isFetchingProfileDetails, setPhotoUpdated, workspaceList, isFetchingWorkspaceList } = props;

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { uploadProfilePhoto, isProfilePhotoUploaded, uploadingProfilePhoto, uploadSignature, isSignatureUploaded, uploadingSignature } = useAuthentication();
    const { signatureUrl } = useDigitalSignature();

    const [photoPreview, setPhotoPreview] = useState(null);
    const [photoFile, setPhotoFile] = useState(null);
    const [signaturePreview, setSignaturePreview] = useState(null);
    const [signatureFile, setSignatureFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openEsignatureDialog, setOpenEsignatureDialog] = useState(false)

    const handleOpenEsignatureDialog = () => {
        setOpenEsignatureDialog(true)
    }

    const handleCloseEsignatureDialog = () => {
        setOpenEsignatureDialog(false)
    }

    const validatePhoto = (e) => {
        const file = e.target.files[0];

        const maxSizeInBytes = 100 * 1024;
        if (file.size > maxSizeInBytes) {
            enqueueSnackbar("File size is invalid, must be below or equal to 100KB", {
                variant: "error",
                autoHideDuration: 2000
            });
            return;
        }

        var fileName = document.getElementById("upload-photo").value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
            setPhotoPreview(URL.createObjectURL(e.target.files[0]));
            setPhotoFile(file);

        } else {
            enqueueSnackbar("Only image files are supported", {
                variant: "error",
                autoHideDuration: 2000
            })
        }
    }

    const validateSignature = async (e) => {
        const file = e.target.files[0];

        const maxSizeInBytes = 100 * 1024;
        if (file.size > maxSizeInBytes) {
            enqueueSnackbar("File size is invalid, must be below or equal to 100KB", {
                variant: "error",
                autoHideDuration: 2000
            });
            return;
        }

        const fileName = document.getElementById("upload-signature").value;
        const idxDot = fileName.lastIndexOf(".") + 1;
        const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

        if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
            setSignaturePreview(URL.createObjectURL(file));

            try {
                setLoading(true);
                const modifiedImage = await removeBackground(file, 30);
                setSignaturePreview(modifiedImage);
                setSignatureFile(modifiedImage);
            } catch (error) {
                enqueueSnackbar("Error processing the image", { variant: "error", autoHideDuration: 2000 });
            } finally {
                setLoading(false);
            }

        } else {
            enqueueSnackbar("Only image files are supported", {
                variant: "error",
                autoHideDuration: 2000
            });
        }
    };

    const handleSavePhoto = (e) => {
        e.stopPropagation();

        let formData = new FormData();

        formData.append("file", photoFile);

        uploadProfilePhoto(formData);

        setPhotoFile(null);
        setPhotoPreview(null);
    };

    const handleSaveSignature = async (e) => {
        e.stopPropagation();

        if (!signatureFile) {
            enqueueSnackbar("No signature to save", { variant: "error", autoHideDuration: 2000 });
            return;
        }

        const blob = await fetch(signatureFile).then((res) => res.blob());

        const formData = new FormData();
        formData.append("file", blob, "signature.png");

        try {
            uploadSignature(formData);
            enqueueSnackbar("Signature saved successfully", {
                variant: "success",
                autoHideDuration: 2000
            });
            setSignatureFile(null);
            setSignaturePreview(null);
        } catch (error) {
            enqueueSnackbar("Error saving signature", { variant: "error", autoHideDuration: 2000 });
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                enqueueSnackbar("Successfully copied wallet address", { variant: "success", autoHideDuration: 2000 });
            })
            .catch(err => {
                console.error("Failed to copy text: ", err);
            });
    }

    useEffect(() => {
        if (isProfilePhotoUploaded || isSignatureUploaded) {
            setPhotoUpdated(true);
            AuthenticationService.viewProfile();
        }
    }, [isProfilePhotoUploaded, isSignatureUploaded])

    const workspaceData = workspaceList.data;


    return (
        <>
            {(isFetchingProfileDetails) ?
                <ProfileDetailsSkeletonComponent />
                :
                <> {profile &&
                    <Grid container spacing={2} sx={{ mt: 2, padding: "30px 50px" }}>

                        <Grid
                            size={{ xs: 12, sm: 4, md: 3 }}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >

                            {(photoPreview) ?
                                <Avatar
                                    variant="square"
                                    src={photoPreview}
                                    style={{
                                        cursor: "pointer",
                                        width: 100,
                                        height: 100,
                                        backgroundColor: "#fff",
                                        color: '#604CC3',
                                        fontSize: 30,
                                        border: "5px solid #604CC3",
                                        fontWeight: 'bold',
                                        borderRadius: '50%'
                                    }}
                                    slotProps={{ img: { style: { objectFit: "contain" } } }}
                                />
                                :
                                <Avatar
                                    {...(profile?.profile?.photo
                                        ? { src: profile?.profile?.photo }
                                        : stringToAvatarEmail(profile?.profile?.email))}
                                    style={{
                                        cursor: "pointer",
                                        width: 100,
                                        height: 100,
                                        backgroundColor: "#fff",
                                        color: '#604CC3',
                                        fontSize: 30,
                                        border: "5px solid #604CC3",
                                        fontWeight: 'bold'
                                    }}
                                    slotProps={{ img: { style: { objectFit: "contain" } } }}
                                />
                            }

                            <Button sx={{ fontWeight: "400", cursor: "pointer", color: "#0178f2", textTransform: "none", borderRadius: 0, fontSize: 14, padding: 0, minWidth: "auto" }}>
                                <input
                                    type="file"
                                    accept="image/*"
                                    id="upload-photo"
                                    onChange={(e) => validatePhoto(e)}
                                    hidden
                                />

                                <CustomButton
                                    variant="outlined"
                                    label={photoPreview ? "Change" : (!photoPreview && profile.profile?.photo) ? "Update Photo" : "Upload Photo"}
                                    onClick={() => document.getElementById('upload-photo').click()}
                                    sx={{ color: '#604CC3', border: '1px solid #604CC3', mt: 3, height: 40 }}
                                    disabled={uploadingProfilePhoto}
                                />

                            </Button>

                            {(photoPreview) &&
                                <Button sx={{ color: '#604CC3', border: '1px solid #604CC3', mt: 3, height: 40, textTransform: 'none' }}
                                    onClick={(e) => handleSavePhoto(e)}
                                >
                                    Save
                                </Button>
                            }

                            <Box sx={{ mt: 5, mb: 5, }}>

                                <Divider />

                                <CustomTypography
                                    variant="subtitle1"
                                    text={"Workspaces"}
                                    customStyles={{ marginBottom: 0, mt: 2, mb: 1, fontWeight: 500, color: "#58565F", fontSize: 18, textAlign: 'start' }}
                                />

                                <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                    {isFetchingWorkspaceList ? (
                                        Array.from(new Array(5)).map((_, index) => (
                                            <Skeleton key={index} variant="rectangular" width={30} height={30} sx={{ margin: 2, backgroundColor: "#eeebf8" }} />
                                        ))
                                    ) : (
                                        workspaceData?.map((workspace) => (
                                            <Tooltip
                                                key={workspace?.workspace.id}
                                                disableFocusListener
                                                disableTouchListener
                                                title={workspace?.workspace.name}
                                                arrow
                                                slots={{
                                                    transition: Fade,
                                                }}
                                                slotProps={{
                                                    transition: { timeout: 600 },
                                                    tooltip: {
                                                        sx: {
                                                            backgroundColor: "#604CC3",
                                                            color: "#fff",
                                                            fontSize: "12px",
                                                            borderRadius: "4px",
                                                            padding: "4px 8px",
                                                        },
                                                    },
                                                    arrow: {
                                                        sx: {
                                                            color: "#604CC3",
                                                        },
                                                    },
                                                }}
                                            >
                                                <Avatar
                                                    variant="square"
                                                    src={workspace?.workspace.logo || null}
                                                    {...(workspace?.workspace.logo ? {} : stringAvatar(workspace?.workspace.name))}
                                                    style={{
                                                        width: 30,
                                                        height: 30,
                                                        marginRight: 8,
                                                        borderRadius: 4,
                                                        fontSize: 14,
                                                        margin: 2,
                                                        cursor: 'pointer',
                                                        border: workspace?.workspace.logo ? '1px solid #aca3e0' : {}
                                                    }}
                                                    slotProps={{
                                                        img: {
                                                            style: { objectFit: workspace?.workspace.logo ? "contain" : "unset" },
                                                        },
                                                    }}
                                                    onClick={() =>
                                                        navigate("/workspaces/view?workspaceId=" + workspace?.workspace.id)
                                                    }
                                                />
                                            </Tooltip>
                                        ))
                                    )}
                                </Box>
                            </Box>
                        </Grid>


                        <Grid size={{ xs: 12, sm: 8, md: 9 }} sx={{ justifyContent: 'flex-start', textAlign: 'start', paddingLeft: 0, paddingTop: 0, paddingRight: '180px' }}>
                            <CustomTypography
                                variant="subtitle1"
                                text={"Email"}
                                customStyles={{ marginBottom: 0, fontWeight: 400, color: "#58565F", fontSize: 18, cursor: 'default' }}
                            />

                            <CustomTextField
                                variant={"outlined"}
                                value={profile?.profile?.email}
                                fullWidth={true}
                                multiline={false}
                                hiddenLabel
                                InputProps={{
                                    readOnly: true,
                                    cursor: 'default'
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#aca3e0',
                                            borderRadius: 0,
                                            cursor: 'default',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#aca3e0',
                                            cursor: 'default',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#aca3e0',
                                            cursor: 'default',
                                        },
                                    },
                                    backgroundColor: '#f6f8fb',
                                    cursor: 'default',
                                    '& .MuiInputBase-input': {
                                        color: '#666',
                                        cursor: 'default',
                                    }
                                }}
                            /> <br /><br />


                            <CustomTypography
                                variant="subtitle1"
                                text={"Wallet Address"}
                                customStyles={{ marginBottom: 0, fontWeight: 400, color: "#58565F", fontSize: 18, cursor: 'default' }}
                            />


                            <CustomTextField
                                variant={"outlined"}
                                // disabled
                                value={profile?.profile?.walletId}
                                fullWidth={true}
                                multiline={false}
                                hiddenLabel
                                InputProps={{
                                    readOnly: true,
                                    cursor: 'not-allowed',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => copyToClipboard(profile?.profile?.walletId)}>
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#aca3e0',
                                            borderRadius: 0,
                                            cursor: 'default',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#aca3e0',
                                            cursor: 'default',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#aca3e0',
                                            cursor: 'default',
                                        },
                                    },
                                    backgroundColor: '#f6f8fb',
                                    cursor: 'default',
                                    '& .MuiInputBase-input': {
                                        color: '#666',
                                        cursor: 'default',
                                    }
                                }}
                            />

                            <Grid container spacing={2} sx={{ mt: 3 }}>
                                <Grid
                                    size={{ xs: 12, sm: 5 }} sx={{ textAlign: 'start', justifyContent: 'flex-start' }}
                                >
                                    <CustomTypography
                                        variant="subtitle1"
                                        text={"Signature"}
                                        customStyles={{ marginBottom: 0, fontWeight: 400, color: "#58565F", fontSize: 18 }}
                                    />
                                </Grid>
                                <Grid size={{ xs: 12, sm: 7 }} sx={{ display: 'flex', justifyContent: 'flex-end', marginLeft: 'auto' }}>
                                    <Button onClick={handleOpenEsignatureDialog} sx={{ fontWeight: "400", cursor: "pointer", color: "#0178f2", textTransform: "none", borderRadius: 0, fontSize: 14, padding: 0, minWidth: "auto" }}>
                                        Change Signature
                                    </Button>
                                </Grid>

                            </Grid>
                            <br />

                            <Box sx={{ border: '1px solid #aca3e0', padding: 4, pt: 0, pb: 0, backgroundColor: '#f6f8fb' }}>
                                {signatureUrl ? (
                                    <img src={`${signatureUrl}?${Date.now()}`} alt="Signature Preview" style={{ width: '50%', objectFit: 'contain' }} />
                                ) : (
                                    <>
                                        {profile?.profile?.signature ? (
                                            <img src={profile.profile.signature} alt="Profile Signature" style={{ width: '50%', objectFit: 'contain' }} />
                                        ) : (
                                            <div style={{ textAlign: 'center', color: '#604CC3' }}>No available signature</div>
                                        )}
                                    </>
                                )}
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 5, alignSelf: 'center', alignContent: 'center', flexWrap: 'wrap' }}>
                                <img src={supersignLogo} alt="Logo" style={{ marginRight: '1px', height: '20px' }} />
                                <span style={{ fontSize: '12px', fontWeight: 500, marginRight: '10px', color: '#604CC3' }}>
                                    © 2025 Super Sign
                                </span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Link href="/my-token" underline="none" sx={{ fontSize: '12px', color: '#604CC3', fontWeight: 400 }}> My Token</Link> &nbsp;&nbsp;&nbsp;&nbsp;
                                <Link href="/my-token" underline="none" sx={{ fontSize: '12px', color: '#604CC3', fontWeight: 400 }}> Workspaces</Link> &nbsp;&nbsp;&nbsp;&nbsp;
                                <Link href="/my-token" underline="none" sx={{ fontSize: '12px', color: '#604CC3', fontWeight: 400 }}> My Purchase</Link>
                            </Box>

                        </Grid>
                    </Grid>
                }
                </>
            }

            <CreateEsignatureCommon openEsignatureDialog={openEsignatureDialog} handleCloseEsignatureDialog={handleCloseEsignatureDialog} />
        </>

    )

}