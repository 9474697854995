

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import CustomButton from "../../../../core/components/ui/CustomButton";
import { Link } from "react-router-dom";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InfoIcon from '@mui/icons-material/Info';
import CustomTypography from "../../../../core/components/ui/CustomTypography";
import BannerIcon from "../../../../assets/images/banner1.png"
import TokenIcon from "../../../../assets/icons/token.png"

export default function InsufficientTokenDialog (props) {

    const {openInsufficientTokenDialog, setOpenInsufficientTokenDialog} = props;

 

    const handleCloseModal = () => {
        setOpenInsufficientTokenDialog(false)
    }

   
    return(
    <Dialog open={openInsufficientTokenDialog} onClose={handleCloseModal} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 2}}}>
        <DialogContent sx={{p: 0, textAlign:'center'}} >
            <img src={BannerIcon} style={{height: 150, width:'100%'}}/>
            <img src={TokenIcon} style={{marginTop: '-50px', width: 100, height: 100}}/>
        </DialogContent>
        <DialogContent sx={{textAlign:'center'}}>
            {/* <InfoIcon sx={{width: 60, height: 60, color:'gray'}} /> */}
                <CustomTypography text={"Insufficient Token Balance"} sx={{fontSize: 25, fontWeight:'bold', color: '#604CC3'}} />
                <CustomTypography text={"Please purchase more tokens to continue."} />
        </DialogContent>
        <DialogActions sx={{justifyContent:'center', mb: 2}}>
            <CustomButton label={"Not now"} onClick={handleCloseModal} variant={'outlined'} sx={{ pt: 2, pb: 2 , height: 40, mr: 1}} />
            <CustomButton onClick={() => window.location.href="/token"} label={"Buy Token"} variant={'contained'} sx={{ml: 1, pt: 2, pb: 2, height: 40}} />
        </DialogActions>
    </Dialog>
    )
}