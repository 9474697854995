const DOCUMENT_API_ENDPOINT =  process.env.REACT_APP_PUBLIC_DOCUMENT_SIGNING_API_ENDPOINT;
const USER_ACCOUNT_API_ENDPOINT = process.env.REACT_APP_PUBLIC_USER_ACCOUNT_API_ENDPOINT;

export default {
   
    // PUBLIC_DOCUMENT
    GET_DOCUMENT_WITH_PROPERTIES_PUBLIC:                     DOCUMENT_API_ENDPOINT + "/ss-dw/properties",
    SIGN_DOCUMENT:                                           DOCUMENT_API_ENDPOINT + "/ss-dw/sign",
    DECLINE_DOCUMENT:                                        DOCUMENT_API_ENDPOINT + "/signatory/decline",


    //PUBLIC_USER_ACCOUNT
    GET_SIGNATURE_PUBLIC:                                    USER_ACCOUNT_API_ENDPOINT + "/signature/view",
    UPLOAD_SIGNATURE_PUBLIC:                                 USER_ACCOUNT_API_ENDPOINT + "/signature/upload",
    GET_SENDER_EMAIL_PUBLIC:                                 USER_ACCOUNT_API_ENDPOINT + "/users/retrieve/email"
 
}