import { Box, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid2, InputLabel, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import SignatorySectionComponent from "../SignatorySectionComponent";
import useElements from "../../zustand/useElements";
import { useEffect, useState } from "react";
import useCheckedStore from '../../zustand/useCheckStore';
import AddCarbonCopySectionComponent from "../AddCarbonCopySectionComponent";
import CustomButton from "../../../../../core/components/ui/CustomButton";
import useCarbonCopyStore from "../../zustand/useCarbonCopyStore";
import { ThreeDots } from "react-loader-spinner";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import useSignatoryStore from "../../zustand/useSignatoryStore";
import CustomTextfieldComponent from "../../../../../core/components/common/custom-textfield.component";
import { TimePicker } from "@mui/x-date-pickers";
import moment from "moment";

export default function ConfirmationDialogComponnet (props) {

    const {openConfirmation, handleCloseConfirmationModal, handleSaveDocuments, signatories, isSavingDocument, setOpenConfirmation} = props;

    const { carbon_copy_list, setCarbonCopyList} = useCarbonCopyStore();
    const { checked, auto_reminder, document_expiration, auto_scheduled, expired_days, setChecked, setAutoReminder, 
        setDocumentExpiration, setAutoScheduled, selectedDate, setSelectedDate, setExpiredDays, setSelectedTime} = useCheckedStore();

    const [time, setTime] = useState(null);
    const [dateRequired, setDateRequired] = useState(false);
    const [timeRequired, setTimeRequired] = useState(false);
    const [daysRequired, setDaysRequired] = useState(false);
    const [dateSelect, setDateSelect] = useState(null)

    const signatory = useSignatoryStore((state) => state.signatory)

    useEffect(() => {
        if (signatory.length == 0) {
            setAutoReminder(false); 
            setAutoScheduled(false)
        }
    }, [JSON.stringify(signatory)])

    const handleDateChange = (newValue) => {
        setSelectedDate(newValue); 
        const dateWithTime = new Date(newValue);
      
        const time = new Date(); 
        
        dateWithTime.setHours(time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
        
        const isSameDay = dayjs(newValue).isSame(dayjs(), 'day');

        if(isSameDay){
            setDateSelect(dayjs(dateWithTime));
        }else{
            setDateSelect(selectedDate)
        }
        // setDateSelect(dayjs(dateWithTime))
        if (newValue) {
            setDateRequired(false);
            console.log('setSelectedDate', newValue)
        }
    };


    const now = dayjs()

    console.log('GET DATE NOW', now)
    console.log('GET SELECTED DATE', dateSelect)

    const handleTimeChange = (newValue) => {
        const formattedTime = dayjs(newValue).format("HH:mm");
        setTime(newValue)
        setSelectedTime(formattedTime);

        if (selectedDate) {
            setTimeRequired(false);
        }
        console.log("Selected Time: xxxxxxxxxxxxxx", formattedTime);
    };

    const handleDocumentExpiration = (e) => {
        setExpiredDays(e.target.value); 
        if (e.target.value) {
            setDaysRequired(false);
        }
    };

    function handleOnCheck() {
        setChecked(!checked); 
    }

    function handleOnAutoReminder() {
        setAutoReminder(!auto_reminder); 
    }

    function handleOnDocumentExpiration() {
        setDocumentExpiration(!document_expiration); 
    }

    function handleOnDocumentSchedule() {
        setAutoScheduled(!auto_scheduled); 
    }

    function handleOnCarbonCopyListChange(carbonCopyList){
        console.log("handleOnCarbonCopyListChange carbonCopyList: ", carbonCopyList);
        setCarbonCopyList(carbonCopyList);
    }

    useEffect(() => {
        if(setOpenConfirmation === false){
            setChecked(false); 
            setAutoReminder(false);
            setDocumentExpiration(false)
            setAutoScheduled(false)
        }
    }, [setOpenConfirmation]);

    useEffect(() => {
        if(!auto_scheduled){
            setSelectedDate(null);
            setSelectedTime(null)
            setTime(null)
            setDateRequired(false);
            setTimeRequired(false)
        }
    },[auto_scheduled])

    useEffect(() => {
        if(!document_expiration){
            setExpiredDays(null)
            setDaysRequired(false)
        }
    },[document_expiration])

    const handleSend = () => {
        if (auto_scheduled ) {
            if (!selectedDate) {
                setDateRequired(true);
            } else {
                setDateRequired(false); 
            }
    
            if (!time) {
                setTimeRequired(true);
            } else {
                setTimeRequired(false); 
            }

            if (!selectedDate || !time ) {
                return;
            }
        }

        if(document_expiration){
            if(!expired_days){
                setDaysRequired(true)
            }else {
                setDaysRequired(false)
            }

            if (!expired_days) {
                return;
            }
        }
    
        handleSaveDocuments();
    };
   
    console.log('is certificate check', checked)
    console.log('is document expire', document_expiration)
    console.log('is auto reminder', auto_reminder)

    return(
        <Dialog open={openConfirmation} onClose={handleCloseConfirmationModal} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5, backgroundColor: '#f4f3f7'}}}>
            <DialogTitle>Confirmations</DialogTitle>
            <Divider sx={{boxShadow:'0px 4px 10px rgba(0, 0, 0, 0.5);'}}/>
            <DialogContent>
                <SignatorySectionComponent emails={signatory} />

                <FormGroup>
                <FormControlLabel
                    control={
                    <Checkbox
                        sx={{
                        color: '#604cc3',
                        '&.Mui-checked': {
                            color: '#604cc3',
                        },
                        }}
                        checked={checked} // Controlled by Zustand state
                        onChange={(event) => handleOnCheck(event.target.checked)} // Pass event.checked
                    />
                    }
                    label="Include last page certificate"
                />
                </FormGroup>
                <AddCarbonCopySectionComponent onCarbonCopyListChange={handleOnCarbonCopyListChange}/>

                <Typography sx={{mt: 3, fontWeight:'bold'}}>Document Automation</Typography>
                <Card sx={{bgcolor:'#fff', p: '10px 10px 10px 20px', mt: 2,}}>
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        <Typography sx={{fontWeight:'bold', fontSize: 14}}>Auto Reminder</Typography>
                        <Box sx={{flexGrow: 1}}/>
                        <FormControlLabel
                            control={
                            <Switch
                                disabled={signatory.length == 0}
                                checked={auto_reminder} // Controlled by Zustand state
                                onChange={(event) => handleOnAutoReminder(event.target.checked)} // Pass event.checked
                            />
                            }
                        />
                    </Box>
                </Card>

                <Card sx={{bgcolor:'#fff', p: '10px 10px 10px 20px', mt:1 }}>
                    <Box sx={{display:'flex', alignItems: 'center'}}>
                        <Typography sx={{fontWeight:'bold', fontSize: 14}}>Auto Schedule</Typography>
                        <Box sx={{flexGrow: 1}}/>
                        <FormControlLabel
                            control={
                            <Switch
                                sx={{
                                color: '#604cc3',
                                '&.Mui-checked': {
                                    color: '#604cc3',
                                },
                                }}
                                disabled={signatory.length == 0}
                                checked={auto_scheduled} 
                                onChange={(event) => handleOnDocumentSchedule(event.target.checked)}
                            />
                            }
                        />
                    </Box>
                    

                    {/* {auto_scheduled === true &&  */}
                    <Grid2 container spacing={2} sx={{mb: 1, mr: 2}}>
                        <Typography sx={{fontSize: 12, color: '#604cc3'}}>
                        Document scheduling with a date picker and adjustable time intervals.
                        </Typography>
                        <Grid2 size={{ xs: 6, md: 6 }}>
                            <LocalizationProvider  dateAdapter={AdapterDayjs} >
                                <DemoContainer fullWidth components={['DatePicker']}>
                                    <DatePicker sx={{ width: '100%', bgcolor:'white', '.MuiInputBase-input': { fontSize: 13, padding: 1.5, height: 'auto' }}} 
                                        minDate={dayjs()} 
                                        maxDate={dayjs().add(31, 'day')}
                                        disabled={!auto_scheduled}
                                        value={selectedDate} 
                                        onChange={handleDateChange} // Handle changes
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                            {dateRequired && <FormHelperText dateRequired sx={{color:'red'}}>*Date is required.</FormHelperText>}
                        </Grid2>
                        <Grid2 size={{ xs: 6, md: 6 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['TimePicker']}>
                                    <TimePicker
                                        sx={{ width: '100%', bgcolor:'white', '.MuiInputBase-input': { fontSize: 13, p: 1.5,  height: 'auto' }, color: auto_scheduled && 'black'}}
                                        value={time}
                                        onChange={handleTimeChange}
                                        ampm 
                                        minutesStep={5}
                                        disabled={!auto_scheduled}
                                        minTime={dateSelect}
                                        reduceAnimations={true}
                                        closeOnSelect={true}
                                        autoFocus={true}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    // Make the time input field non-editable
                                                    readOnly: true, // Prevent typing but allow dialog interaction
                                                }}
                                            />
                                        )} 
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                            {timeRequired && <FormHelperText timeRequired sx={{color:'red'}}>*Time is required.</FormHelperText>}
                        </Grid2>
                    </Grid2>
                        
                    
                </Card>
                <Card sx={{bgcolor:'#fff', p: '10px 10px 10px 20px', mt: 1,}}>
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        <Typography sx={{fontWeight:'bold', fontSize: 14}}>Document Expiration</Typography>
                        <Box sx={{flexGrow: 1}}/>
                        <FormControlLabel
                            control={
                                <Switch
                                    sx={{
                                    color: '#604cc3',
                                    '&.Mui-checked': {
                                        color: '#604cc3',
                                    },
                                    }}
                                    checked={document_expiration} 
                                    onChange={(event) => handleOnDocumentExpiration(event.target.checked)} 
                                />
                            }
                        />
                    </Box>
                    

                    {/* {document_expiration === true &&  */}
                        <Box sx={{display:'flex', alignItems:'center'}}>
                                <CustomTextfieldComponent disabled={!document_expiration} value={expired_days} placeholder={'0'} 
                                type="number"
                                sx={{textAlign:'start',
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: 1,
                                        height: 40,
                                        width: 100, bgcolor:'white'
                                    },
                                }} 
                                onChange={handleDocumentExpiration}
                            />
                            <Typography sx={{mt: 1, ml: 2}}>Days</Typography>
                        </Box>
                        {daysRequired && <FormHelperText daysRequired sx={{color:'red'}}>*Input the number of days.</FormHelperText>}
                        
                    
                </Card>
            </DialogContent>
            <DialogActions>
                <CustomButton startIcon={isSavingDocument && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />} onClick={()=>handleSend()} label={isSavingDocument ? 'Sending' : 'Send'} variant={'contained'} sx={{fontSize: 16, m:2}}/>
            </DialogActions>
        </Dialog>
    )
}