import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import useDocument from "../../hooks/useDocument";
import { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import CustomButton from "../../../../core/components/ui/CustomButton";


export default function DocumentCancelDialog (props) {

    const {openCancelConfirmation, setOpenCancelConfirmation, document_title, document_id} = props;

    const [cancel_reason, setCancelReason] = useState('');
    const [error, setError] = useState(false);
    const {cancelDocument, isCancelDocument}= useDocument()

    const handleCloseModal = () => {
        setOpenCancelConfirmation(false)
    }

    const handleReasonChange = (event) => {
        setCancelReason(event.target.value);
        if (event.target.value) {
            setError(false);
        }
    };

    const handleSubmit = () => {
        cancelDocument(document_id, cancel_reason, setOpenCancelConfirmation)
    }

    return(
    <Dialog open={openCancelConfirmation} onClose={handleCloseModal} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 2}}}>
        <DialogTitle style={{fontWeight: "bold", fontSize: 16}}>Cancel Document</DialogTitle>
        <DialogContent dividers>
            <Typography sx={{ fontSize: 16 }}>
                Please provide a reason to cancel the document <strong>{document_title}</strong>
            </Typography>
            <TextField
                fullWidth
                variant="outlined"
                autoComplete="off"
                sx={{ "& .MuiInputBase-root": { borderRadius: 1, backgroundColor: 'transparent', mt: 2 } }}
                inputProps={{ maxLength: 120, style: { fontSize: 14 } }}
                multiline
                rows={3}
                placeholder="Enter reason here..."
                value={cancel_reason}
                onChange={handleReasonChange}
                error={error}
                helperText={error ? "Reason is required" : `${cancel_reason.length}/120`}
            />
        </DialogContent>
        <DialogActions>
            <CustomButton label={'Cancel'} onClick={()=>handleCloseModal()} variant={'outlined'} sx={{fontSize: 16, mr: 1, color: "#604CC3", border: '.5px solid #604CC3', height: 40}}/>
            <CustomButton disabled={!cancel_reason} startIcon={
                isCancelDocument &&
                <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />
            
            } onClick={()=>handleSubmit()} label={ isCancelDocument ? 'Cancelling' : 'Confirm'} variant={'contained'} sx={{fontSize: 16, mr: 1.5}}/>
            
        </DialogActions>
    </Dialog>
    )
}