import { Box, Button, ClickAwayListener, List, ListItem, ListItemText, ListSubheader, Popper, IconButton, Divider,
    FormControl, Select, MenuItem, Card, CardContent, OutlinedInput, Chip, Checkbox
 } from "@mui/material";
import Typography from '@mui/material/Typography';

import { Outlet, useLocation, useNavigate, useNavigation, useSearchParams } from "react-router-dom";
import BackButtonComponent from "../../../core/components/common/custom-back-button.component";
import AllDocumentsComponent from "../components/document-list-table.component";
import CustomTextfieldComponent from "../../../core/components/common/custom-textfield.component";
import { useState, useRef, useEffect } from "react";
import useSearchDocumentStore from "../zustand/useSearchDocumentStore";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

export default function DocumentListViewScreen() {

    const [title, setTitle] = useState("")
    const [showFilter, setShowFilter] = useState(false)
    const [selectStatusOpen, setSelectStatusOpen] = useState(false)
    const {searchInput, setSearchInput} = useSearchDocumentStore()

    const location = useLocation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()

    const status = searchParams.get('status')
    const array_status = status ? status.split(',') : [];

    const [currentSelectedStatuses, setCurrentSelectedStatuses] = useState(array_status)

    const anchorRef = useRef(null); // Reference for the Popper anchor

    useEffect(() => {
        setCurrentSelectedStatuses(status ? status.split(',') : [])
    }, [status])

    const handleSearchFilename = (e) => {
        setTitle(e.target.value)
    }

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            setSearchInput(title)
        } else {
            setSearchInput('')
        }
    }


    const isDocumentRoute = ['/documents/create', '?status=ongoing', '?status=completed', '?status=draft', '/documents/shared'].includes(location.pathname || location.search);
    const statusHeaderData = status === null 
    ? 'All Documents' 
    : status === 'draft'
    ? 'Draft'
    : status === 'ongoing' 
    ? 'Ongoing' 
    : status === 'completed' 
    ? 'Completed' 
    : ''

    const handleShowFilter = () => {
        setShowFilter(!showFilter); // Toggle the popper
    };

    const handleClickAway = (e) => {
        if (selectStatusOpen) return;
        if (anchorRef.current && !anchorRef.current.contains(e.target)) {
            setShowFilter(false); // Close the Popper
        }
    };

    const handleFilterStatusChange = (e, newValue) => {
        const {target: { value }} = e;
        setCurrentSelectedStatuses(typeof value === "string" ? value.split(",") : value);
    }

    const handleFilterStatusClick = (event) => {
        setShowFilter(true); 
    };

    const handleApplyFilter = () => {
        setShowFilter(false)
        if (currentSelectedStatuses.length == 0) {
            navigate("/documents");
            return
        }
        navigate("/documents?status=" + currentSelectedStatuses.join(','));
    }

    const handleResetFilter = () => {
        setCurrentSelectedStatuses([])
    }

    return (
        <Box sx={{width:'100%'}}>
            {isDocumentRoute  ? (
                <Box  sx={{textAlign:'start', display:'flex', alignItems:'center'}}>
                    <BackButtonComponent/> {status && <Typography sx={{textAlign:'start', fontSize: 16, fontWeight:'bold'}}>{status.toPas()}</Typography>}
                </Box>
            ) : (
                <Box sx={{display:'flex', alignItems:'center'}}>
                    <Typography sx={{textAlign:'start', fontSize: 20, fontWeight:'bold'}} >
                        {statusHeaderData}
                    </Typography>

                    {/* Temp textfield (make it reusable) */}
                    <Box sx={{flexGrow:1}}/>
                    <Button ref={anchorRef} onClick={handleShowFilter} sx={{mt: -1, mr: 1, color: '#604CC3', textTransform: 'capitalize'}} startIcon={<FilterAltIcon />}>Filter</Button>
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Popper open={showFilter} anchorEl={anchorRef.current} placement="bottom-start" sx={{zIndex: 99}}>
                            <Card>
                                <CardContent sx={{p: 2}}>
                                    <Typography sx={{pb: 1}}>Filter</Typography>
                                    <Divider />
                                    <Box sx={{pt: 2}}>
                                        <Box sx={{display: 'flex', pb: 1}}>
                                            <Typography>Status</Typography>
                                            <Box sx={{flexGrow: 1}} />
                                            <Typography color="primary" sx={{cursor: 'pointer'}} onClick={handleResetFilter}>Reset</Typography>
                                        </Box>
                                        <Box sx={{ width: 200, mb: 1 }}>
                                            {currentSelectedStatuses.map((value) => (
                                                <Chip key={value} label={value} sx={{m: 0.3, textTransform: 'capitalize'}} />
                                            ))}
                                        </Box>
                                        <Select
                                            multiple
                                            displayEmpty
                                            defaultValue={currentSelectedStatuses}
                                            open={selectStatusOpen}
                                            value={currentSelectedStatuses}
                                            onOpen={() => setSelectStatusOpen(true)}
                                            onClose={() => setSelectStatusOpen(false)}
                                            onChange={handleFilterStatusChange}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return <em>--Select Status--</em>;
                                                }
                                                return <Typography noWrap style={{textTransform: 'capitalize'}}>{selected.join(', ')}</Typography>;
                                            }}
                                            sx={{
                                                bgcolor: 'white',
                                                height: '40px',
                                                padding: '0 8px',
                                                '& .MuiSelect-select': {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: 200,
                                                    height: '100%',
                                                }
                                            }}
                                        >
                                            <MenuItem value={""} disabled>--Select Status--</MenuItem>
                                            <MenuItem value={'completed'}>
                                                <Checkbox checked={currentSelectedStatuses.includes("completed")} />
                                                <ListItemText primary={"Completed"} />
                                            </MenuItem>
                                            <MenuItem value={'ongoing'}>
                                                <Checkbox checked={currentSelectedStatuses.includes("ongoing")} />
                                                <ListItemText primary={"Ongoing"} />
                                            </MenuItem>
                                            <MenuItem value={'draft'}>
                                                <Checkbox checked={currentSelectedStatuses.includes("draft")} />
                                                <ListItemText primary={"Draft"} />
                                            </MenuItem>
                                            <MenuItem value={'scheduled'}>
                                                <Checkbox checked={currentSelectedStatuses.includes("scheduled")} />
                                                <ListItemText primary={"Scheduled"} />
                                            </MenuItem>
                                            <MenuItem value={'canceled'}>
                                                <Checkbox checked={currentSelectedStatuses.includes("canceled")} />
                                                <ListItemText primary={"Canceled"} />
                                            </MenuItem>
                                            <MenuItem value={'error'}>
                                                <Checkbox checked={currentSelectedStatuses.includes("error")} />
                                                <ListItemText primary={"Error"} />
                                            </MenuItem>
                                        </Select>
                                    </Box>
                                    <br />
                                    <Divider />
                                    <Box sx={{pt: 2}}>
                                        <Box sx={{display: 'flex'}}>
                                            <Box sx={{flexGrow: 1}} />
                                            <Button variant="contained" sx={{textTransform: 'capitalize', bgcolor: '#604CC3'}}
                                                onClick={handleApplyFilter}>Apply now</Button>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Popper>
                    </ClickAwayListener>
                    <CustomTextfieldComponent 
                        sx={{textAlign:'start',
                            "& .MuiOutlinedInput-root": {
                                borderRadius: 1, // Custom border radius
                                height: 40, mt: -2,
                                width: 300, bgcolor:'white', 
                            },
                        }} placeholder={'Search'} value={title} onChange={handleSearchFilename} 
                        onKeyDown={keyPress}
                    />
                    
                </Box>
            )}
            <Outlet/>
        </Box>
    );
}
