import { Box, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CustomTypography from '../../../core/components/ui/CustomTypography';
import CustomTextField from '../../../core/components/ui/CustomTextField3';

export default function ViewOrderComponent({ orderData, isFetchingOrders }) {

    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return new Date(date).toLocaleDateString(undefined, options);
    };

    const orderDetails = [
        { label: "Order ID", value: orderData.order_id },
        { label: "Status", value: orderData.status },
        { label: "Total Amount", value: `${orderData.total_amount} ${orderData.currency}` },
        { label: "Wallet Type", value: orderData.wallet_type },
        { label: "Token Amount", value: `${orderData.token_amount} tokens` },
        { label: "Wallet ID", value: orderData.wallet_id },
        { label: "Last Date Modified", value: formatDate(orderData.updated_at) },
    ];

    return (
        <Box sx={{ padding: 3, textAlign: 'start' }}>
            <Grid container spacing={2}>
                {orderDetails.map((detail, index) => (
                    <Grid key={index} size={12}>
                        <Grid container sx={{ alignSelf: 'center', alignItems: 'center' }}>
                            <Grid size={{ xs: 12, sm: 6, md: 2, lg: 2 }}>
                                {isFetchingOrders ? (
                                    <Skeleton variant="rectangular" width="80%" height={40} sx={{backgroundColor: '#eeebf8'}} />
                                ) : (
                                    <CustomTypography
                                        variant="subtitle1"
                                        text={`${detail.label} :`}
                                        customStyles={{ marginBottom: 0, fontWeight: 500, color: "#58565F", fontSize: 18 }}
                                    />
                                )}
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6, md: 6, lg: 6 }}>
                                {isFetchingOrders ? (
                                    <Skeleton variant="rectangular" width="100%" height={40} sx={{backgroundColor: '#eeebf8'}} />
                                ) : (
                                    <CustomTextField
                                        variant="outlined"
                                        value={detail.value}
                                        fullWidth={true}
                                        multiline={false}
                                        hiddenLabel
                                        InputProps={{
                                            readOnly: true,
                                            cursor: 'not-allowed'
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: detail.value === 'Paid' ? 'green' : '#aca3e0',
                                                    borderRadius: 0
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: detail.value === 'Paid' ? 'green' : '#aca3e0',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: detail.value === 'Paid' ? 'green' : '#aca3e0',
                                                },
                                            },
                                            backgroundColor: detail.value === 'Paid' ? '#0080000a' : '#f6f8fb',
                                            cursor: 'not-allowed',
                                            '& .MuiInputBase-input': {
                                                color: detail.value === 'Paid' ? 'green' : '#666'
                                            }
                                        }}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}