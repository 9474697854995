import { Alert, Box, Button, Grid2 } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import {ThreeDots} from "react-loader-spinner";

import useAuthentication from "../../../../plugins/profile-plugin/hooks/useAuthentication";
import {convertUrlToFile} from "../../../utils/convertUrlToFileUtil"
import useDigitalSignature from "../../../../plugins/supersign-docs-plugin/document-editor-plugin/zustand/useDigitalSignature";

export default function CreateEsignatureByUploadComponent ({props}) {

    const {handleCloseEsignatureDialog} = props;

    console.log('get propsssssssssssssss', props)
    const [signatureFile, setSignatureFile] = useState(null);
    const [signaturePreview, setSignaturePreview] = useState(null);

    const { enqueueSnackbar } = useSnackbar();
    const {  isSignatureUploaded, uploadSignature, isUploadingSignature} = useAuthentication();

    const handleChange = (file) => {
        var fileName = file.name;
        var fileExt = fileName.lastIndexOf(".") + 1;
        var mimetype = fileName.substr(fileExt, fileName.length).toLowerCase();
        
        // setFilename(fileName.split('.').slice(0, -1).join('.'));
        
        if (mimetype == "jpg" || mimetype == "jpeg" || mimetype == "png" || mimetype == "jfif"){
            setSignatureFile(file);
            setSignaturePreview(URL.createObjectURL(file));
        } 
       
    };

    const handleSaveSignature = async (e) => {
        const blob = await fetch(signatureFile).then((res) => res.blob());

        const formData = new FormData();
        formData.append("file", blob, "signature.png");

        try {
            uploadSignature(formData, handleCloseEsignatureDialog);
           
        } catch (error) {
            enqueueSnackbar("Error saving signature", { variant: "error", autoHideDuration: 2000 });
        }
    }

    const removeWhiteBackground = (imageFile) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
      
          reader.onload = (event) => {
            const image = new Image();
            image.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              canvas.width = image.width;
              canvas.height = image.height;
      
              // Draw the image on the canvas
              ctx.drawImage(image, 0, 0);
      
              // Get the image data
              const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
              const data = imageData.data;
      
              // Remove the background (assuming light background and dark signature lines)
              const tolerance = 30; // Adjust this value to set the tolerance for background removal
              for (let i = 0; i < data.length; i += 4) {
                const red = data[i];
                const green = data[i + 1];
                const blue = data[i + 2];
                const alpha = data[i + 3];
            
                // Check if the pixel color is close to red, green, yellow, blue, or white
                if (
                    // Red
                    (red >= 200 && green <= 100 && blue <= 100) ||
                    // Green
                    (red <= 100 && green >= 150 && blue <= 100) || // Adjusted the green range
                    // Blue
                    (red <= 100 && green <= 100 && blue >= 200) ||
                    // Yellow
                    (red >= 200 && green >= 200 && blue <= 100) ||
                    // White
                    (red >= 200 && green >= 200 && blue >= 200)
                ) {
                    data[i + 3] = 0; // Set alpha to 0 (transparent)
                }
            }


      
              // Put the modified image data back to the canvas
              ctx.putImageData(imageData, 0, 0);
      
              // Convert the canvas content back to base64 string
              const modifiedImage = canvas.toDataURL();
      
              resolve(modifiedImage);
            };
      
            image.src = event.target.result;
          };
      
          reader.readAsDataURL(imageFile);
        });
    };

    const bytesToKilobytes = (bytes) => {
        const kilobytes = bytes / 1024;
        return kilobytes;
        };
        
        const sizeInBytes = signatureFile?.size;
        const sizeInKilobytes = bytesToKilobytes(sizeInBytes);
    

    const handleUploadSignature = async (e) => {

        removeWhiteBackground(signatureFile)
        .then((modifiedImage) => {
            // Use the modified image as needed (e.g., display or upload)
            convertUrlToFile(modifiedImage, "edited_signature_file.png", "image/png").then(function (editedSignatureFile) {

                    let formData = new FormData();

                    if(sizeInKilobytes < 100){
                        formData.append("file", editedSignatureFile);
                        uploadSignature(formData, handleCloseEsignatureDialog);
                        
                    }else{
                        console.log('File size is invalid, must below or equal to 100KB')
                    }
            });

        })
        .catch((error) => {
            console.error(error);
        });
    }

    const handleResetFile = () => {
        setSignatureFile(null);
        setSignaturePreview(null);
    }


    return(
        <Box>
            <Alert severity="info">Maximum of 100KB file size</Alert>
            <br/>
             {signaturePreview &&
                <Grid2 sx={{textAlign: "end"}}>
                    <Button onClick={(e) => handleResetFile(e)} sx={{cursor: "pointer", textTransform: "none", borderRadius: 0, fontSize: 14, color: '#604CC3'}}>
                        Clear
                    </Button>
                </Grid2>
            }
            <Grid2 item xs={12}>
                {(signatureFile) ? 
                    <div 
                        style={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            minWidth: "100% !important",
                            maxWidth: "100% !important",
                            border: "dashed 2px #604CC3",
                            borderRadius: "8px",
                            cursor: "pointer",
                            flexGrow: 0,
                            height: "210px",
                            justifyContent: "center",
                            margin: "auto",
                            transition: "border-color .15s linear",
                            backgroundColor:'white'
                        }}
                    >
                        <img alt="signature-preview" src={signaturePreview} style={{height:150, objectFit:'contain', width:'100%'}} />
                    </div>

                :
                    <FileUploader
                        hoverTitle=""
                        multiple={false}
                        handleChange={handleChange}
                        name="file"
                        label="Drag and Drop file here or click here to upload"
                        children={
                            <div
                                style={{
                                    position: "relative",
                                    display: "flex",
                                    alignItems: "center",
                                    minWidth: "100% !important",
                                    maxWidth: "100% !important",
                                    border: "dashed 2px #604CC3",
                                    borderRadius: "2px",
                                    cursor: "pointer",
                                    flexGrow: 0,
                                    height: "210px",
                                    justifyContent: "center",
                                    margin: "auto",
                                    transition: "border-color .15s linear",
                                    backgroundColor:'white'
                                    
                                }}
                            >
                                <p style={{color: "#666", fontWeight: 600, fontSize:10}}>
                                    Drag and Drop file here or click here to upload
                                </p>
                            </div>
                        }
                        
                    
                    />
                }
                    {/* <div>
                        <p style={{color: "#db2828", fontWeight: 20, fontSize: 12}}>
                            Maximum of 100KB file size
                        </p>
                    </div> */}
            </Grid2>

            <Box sx={{paddingTop: 3.2, textAlign: "end"}}>
                <Button onClick={() => handleCloseEsignatureDialog()} variant="outlined" sx={{width: 120, borderRadius: 2 }} >
                    Close
                </Button>
                &nbsp;
                <Button variant="contained" sx={{width: 120, backgroundColor: '#604CC3',  boxShadow: 'none'}}
                onClick={(e) => handleUploadSignature(e)}
                  startIcon={isUploadingSignature && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />} 
                >
                    Save
                </Button>
            </Box>
        </Box>
    )
}