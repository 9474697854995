import { create } from 'zustand';
import dayjs from 'dayjs';

const useCheckedStore = create((set) => ({
  checked: true, // Initial state
  auto_reminder: true,
  document_expiration: false,
  auto_scheduled: false,
  selectedDate: null,
  expired_days: null,
  selectedTime: null,
  setChecked: (value) => set({ checked: value }),
  setAutoReminder: (value) => set({ auto_reminder: value }),
  setDocumentExpiration:(value) => set({ document_expiration: value }),
  setAutoScheduled:(value) => set({ auto_scheduled: value }),
  setSelectedDate: (date) => set({ selectedDate: date }),
  setExpiredDays: (days) => set({ expired_days: days }),
  setSelectedTime: (minutes) => set({ selectedTime: minutes }),
}));

export default useCheckedStore;
