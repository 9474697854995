const isOutsideParentBoundingBox = (parentRect, child_x, child_y, child_width, child_height) => {
    const isChildOutsideParent =
    child_x < 0 ||
    child_y < 0 ||
    child_x + child_width > parentRect.width ||
    child_y + child_height > parentRect.height;

    return isChildOutsideParent;
}

export {isOutsideParentBoundingBox}