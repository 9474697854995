import React, { useEffect, useRef, useState } from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemAvatar, ListItemText, Skeleton, CardHeader, Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import useDocument from '../../documents-plugin/hooks/useDocument';
import ActivityFeed from '../../../assets/icons/activity-feed.png'
import EmptyBox from '../../../assets/icons/empty-box.png'
import useAuthentication from '../../profile-plugin/hooks/useAuthentication';

const colorMapping = {
    'DRAFT': '#604CC3',
    'COMPLETED': '#008000',
    'PENDING': '#FF0000',
    'ONGOING': '#FFA500',
    'DECLINE': '#830221',
    'EXPIRED': '#FF0000',
    'DENIED': '#830221',
    'SCHEDULED' : '#bdb622',
    'CANCELLED' : '#FF0000'
};

const dummyColorMapping = {
    'DRAFT': '#e6e2f5',
    'COMPLETED': '#e0e5e9',
    'PENDING': '#efdde9',
    'ONGOING': '#efe7e9',
    'DECLINE': '#e8ddeb',
    'EXPIRED': '#efdde9',
    'SCHEDULED' : '#bdb6221a',
    'CANCELLED' : '#c628281f'
};

const dummyStatuses = ['DRAFT', 'ONGOING', 'PENDING', 'COMPLETED', 'DECLINED', 'EXPIRED'];

export default function DashboardRecentActivityComponent() {
    const { getDocumentList, documentList, isLoadingList } = useDocument();
    const { viewProfile, profileData, isFetchingProfileDetails } = useAuthentication();

    const [isEmail, setIsEmail] = useState(JSON.parse(localStorage.getItem('isEmail')));
    const [isWorkspace, setIsWorkspace] = useState(JSON.parse(localStorage.getItem('isWorkspace')));
    const [menuSelected, setMenuSelected] = useState(localStorage.getItem('menuSelected'));
    const [wallet_id, setWalletId] = useState(localStorage.getItem('wallet_id'));

    // Ref to track previous state values and avoid infinite loop
    const prevIsEmail = useRef(isEmail);
    const prevIsWorkspace = useRef(isWorkspace);
    const prevMenuSelected = useRef(menuSelected);
    const prevWalletId = useRef(wallet_id)

    useEffect(() => {
        const syncLocalStorage = () => {
            const storedIsEmail = JSON.parse(localStorage.getItem('isEmail'));
            const storedIsWorkspace = JSON.parse(localStorage.getItem('isWorkspace'));
            const storedMenuSelected = localStorage.getItem('menuSelected');
            const storedWalletId = localStorage.getItem('wallet_id');

            // Check if stored values are different from previous ones, update state if necessary
            if (storedIsEmail !== prevIsEmail.current) {
                setIsEmail(storedIsEmail);
                prevIsEmail.current = storedIsEmail;
            }

            if (storedIsWorkspace !== prevIsWorkspace.current) {
                setIsWorkspace(storedIsWorkspace);
                prevIsWorkspace.current = storedIsWorkspace;
            }

            if (storedMenuSelected !== prevMenuSelected.current) {
                setMenuSelected(storedMenuSelected);
                prevMenuSelected.current = storedMenuSelected;
            }

            if (storedWalletId !== prevWalletId.current) {
                setWalletId(storedWalletId);
                prevWalletId.current = storedWalletId;
            }
        };

        // Set interval to sync localStorage every 500ms
        const intervalId = setInterval(syncLocalStorage, 500);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);  // Empty dependency array to run once on mount

    useEffect(() => { getDocumentList(isWorkspace && menuSelected); }, [isEmail, isWorkspace, menuSelected]);
    
    useEffect(()=>{
        viewProfile()
    },[])


    const activities = documentList?.map(doc => ({
        id: doc.document.id,
        primary: (
            <span>
                Document <strong>{doc.document.title}</strong> is in <span style={{ color: colorMapping[doc.document.status] || '#000' }}>{` ${doc.document.status}`}</span> status.
            </span>
        ),
        secondary: new Date(doc.document.updated_time).toLocaleString(),
        color: colorMapping[doc.document.status] || '#000',
        iconColor: '#fff',
    }));

    console.log("GET WALLET IDDDDDDDDDDDDDDDDDDD",wallet_id)

    return (
        <Card style={{ padding: '10px', paddingBottom: '1px', paddingTop: '1px', borderRadius: 10, height: 862 }}>
            <CardHeader sx={{pb: 0}} title={<Typography variant="h6" component="div"  sx={{ textAlign: 'start', pb: 0 }}>
                    Recent Activity
                </Typography>}/>
            <CardContent sx={{
                maxHeight: 700,
                overflowY: "auto",
            }}>
                
                <List>
                    {isLoadingList ? (
                        Array.from(new Array(10)).map((_, index) => {
                            const randomStatus = dummyStatuses[Math.floor(Math.random() * dummyStatuses.length)];
                            return (
                                <ListItem key={index}>
                                    <ListItemAvatar>
                                        <Skeleton variant="circular" width={40} height={40} style={{ backgroundColor: dummyColorMapping[randomStatus] }} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<Skeleton variant="text" width="80%" style={{ backgroundColor: dummyColorMapping[randomStatus] }} />}
                                        secondary={<Skeleton variant="text" width="40%" style={{ backgroundColor: dummyColorMapping[randomStatus] }} />}
                                    />
                                </ListItem>
                            );
                        })
                    ) : documentList?.length === 0 ? (
                        <Box sx={{display:'grid', justifyContent:'center', mt: 20}}>
                            <img src={EmptyBox} style={{height: 70, width: 70, margin:"auto"}}/>
                            <br/>
                            <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold', color: '#58565f', fontSize: 12 }}>
                                No Recent Activity to Display
                            </Typography>
                        </Box>
                        
                    ) : (
                        activities?.slice(0, 10).map(activity => (
                            <ListItem key={activity.id}>
                                <ListItemAvatar>
                                    <Avatar style={{ backgroundColor: activity.color }}>
                                        <ImageIcon style={{ color: activity.iconColor }} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<span style={{ fontSize: 14 }}>{activity.primary}</span>}
                                    secondary={<span style={{ fontSize: 12 }}>{activity.secondary}</span>}
                                />
                            </ListItem>
                        ))
                    )}
                </List>
            </CardContent>
        </Card>
    );
}
