import React from "react";
import Grid from "@mui/material/Grid2";
import {Skeleton, Box} from "@mui/material";

const WorkspaceProjectDetailsSkeleton = () => {

    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", minHeight: "50vh", backgroundColor: "#f5f5f5", p: 2}}>
            <Box
                sx={{
                    width: 510,
                    height: 300,
                    borderRadius: 4,
                    background: "white",
                    color: "#fff",
                    padding: 2,
                    boxShadow: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <Grid container justifyContent="space-between">
                    <Box sx={{display: "block"}}>
                        <Skeleton variant="text" sx={{backgroundColor: "#eeebf8"}} height={30} width={300} />
                        <Skeleton variant="text" sx={{backgroundColor: "#eeebf8"}} height={30} width={200} />
                    </Box>

                    <Skeleton variant="text" sx={{backgroundColor: "#eeebf8", borderRadius: "32px", mt: "-9px"}} height={60} width={110} />
                </Grid>

                <Box sx={{textAlign: "start"}}>
                    <Skeleton variant="text" sx={{backgroundColor: "#eeebf8"}} height={30} width={300} />
                    <Skeleton variant="text" sx={{backgroundColor: "#eeebf8"}} height={30} width={350} />

                    <Skeleton variant="text" sx={{backgroundColor: "#eeebf8"}} height={30} width={300} />
                    <Skeleton variant="text" sx={{backgroundColor: "#eeebf8"}} height={30} width={350} />
                </Box>

                <Grid container justifyContent="space-between" sx={{mt: 1}}>
                    <Skeleton variant="rectangular" sx={{backgroundColor: "#eeebf8", borderRadius: "6px"}} height={30} width={120} />

                    <Skeleton variant="rectangular" sx={{backgroundColor: "#eeebf8", borderRadius: "6px"}} height={30} width={120} />
                </Grid>
            </Box>
        </Box>
    )
}

export default WorkspaceProjectDetailsSkeleton
