import React from "react";
import {Dialog, DialogContent, Typography, Button, IconButton, Box} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid2';
import useUserAuthenticatedStore from "../../../workspace-plugin/zustand/useUserAuthenticatedStore";
import {auth} from "../../../../firebaseConfig";
import {signOut} from "firebase/auth";


const FinishNotificationDialog = (props) => {

    const {currentUser} = props;

    const {isUserAuthenticated} = useUserAuthenticatedStore();

    const gotoLink = (e) => {
        if (isUserAuthenticated) {
            if (isUserAuthenticated?.email === currentUser) {
                window.location.assign("/dashboard");

            } else {
                signOut(auth).then(() => {
                    localStorage.clear();
                    window.location.assign("/login"); 
                }).catch((error) => {
                    console.error("Error signing out:", error);
                });
            }

        } else {
            window.location.assign("/login");
        }
    }
    

    return (
        <Dialog open={true} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5}}}>
            <Box sx={{textAlign:'end', padding: "5px 10px 10px 0px"}}>
                <IconButton onClick={gotoLink}>
                    <CloseIcon sx={{color:'#604CC3'}}/>
                </IconButton>
            </Box>
            <DialogContent sx={{padding: "0px 24px 35px 24px"}}>
                <Grid container justifyContent="center" direction="column">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{margin: "auto"}}>
                        <Typography variant="h5" sx={{ fontWeight: "bold", color: "#604CC3"}}>
                            You're Done
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{margin: "auto"}}>
                        <Typography variant="subtitle2" sx={{color: "#000"}}>
                            You will receive an email once everyone has signed
                        </Typography>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{margin: "auto", mt: "20px"}}>
                        <Typography variant="h6" sx={{fontWeight: "bold", color: "#604CC3"}}>
                            Thank you for using SuperSign
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{margin: "auto"}}>
                        <Typography variant="subtitle2" sx={{color: "#000"}}>
                            Create, customize and send document for signatures
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{margin: "auto", mt: "22px"}}>
                        <Button onClick={(e) => gotoLink(e)} variant="contained" sx={{width: 220, backgroundColor: '#48870B', borderRadius: 10, boxShadow: 'none'}}>
                            Done
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default FinishNotificationDialog