import React, {useState, useEffect } from "react";
import {Box, Divider, Typography} from "@mui/material";

import { CustomTab, CustomTabs } from "../../../../../core/components/common/custom-tabs";
import CreateEsignatureByUploadCommon from "./create-esignature-by-upload.common";
import CreateEsignatureByDrawCommon from "./create-esignature-by-draw.common";

function useSignatureTabs (props) {

  const url = window.location.pathname;
  const [signatureTabs, setSignatureTabs] = useState(null);

  const setupTabs = () => {
    var default_tabs = [
      {name: "Draw", component: <CreateEsignatureByDrawCommon  props={props}/>},
      {name: "Upload", component: <CreateEsignatureByUploadCommon props={props}/>},
    ]
    setSignatureTabs(default_tabs);
      
  }

  useEffect(() => { 
    setupTabs(); 
  },[])

  return signatureTabs;
}


const SignatureTabs = (props) => {
  const {breakpoints, sendTabLabelToParent, handleCloseEsignatureDialog, uploadPublicSignature, uploading_signature} = props;

  const tabs = useSignatureTabs(props);

  const [value, setValue] = useState(0);

  const TabPanel = (props) => {
    const {children, value, index, ...other} = props;
      
    return (
      <div
        role="tabpanel"
        component="div" 
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && 
          <Box>
            <Typography>
              {children}
            </Typography>
          </Box>
        }
      </div>
    )
  }

  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (newValue == 3) {
      sendTabLabelToParent(true);
    } else {
      sendTabLabelToParent(false);
    }
  }

  
  return (
    <Box sx={{width: "100%"}}>
      <Box>
        <CustomTabs
          variant={(breakpoints == 'xs' || breakpoints == 'sm') ? "scrollable" : ""}
          value={value} 
          onChange={handleChange} 
          sx={{mt: 2, ml: 1.5}} 
          TabIndicatorProps={{sx: {top: "25px !important", height: "4px !important", bgcolor:'transparent'}}}
          // centered
        >
          {tabs?.map((tab, index) => {
            return (
              <CustomTab label={tab.name} disableRipple {...a11yProps(index)} />
            )
          })}
        </CustomTabs>
        <Divider/>
        {tabs?.map((tab, index) => {
          return (
            <TabPanel value={value} index={index} style={{padding: "25px"}}>
              {tab.component}
            </TabPanel> 
          ) 
        })}
      </Box>
    </Box>
  )
}

export default SignatureTabs