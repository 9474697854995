import routes from "../../common/api-routes";
import {HttpAuthDelete1, HttpAuthGet, HttpAuthPost, HttpAuthPut1} from "../../../../../core/services/common/HttpRequestBuilder";

const createOrder = (payload) => {
    let result = HttpAuthPost(routes.CREATE_ORDER, payload);

    return result;
}

const listOrder = (parameters) => {
    let result = HttpAuthGet(routes.LIST_ORDER, parameters);

    return result;
}

const listOrderItems = (parameters) => {
    let result = HttpAuthGet(routes.LIST_ORDER_ITEMS, parameters);

    return result;
}

const viewOrder = (parameters) => {
    let result = HttpAuthGet(routes.VIEW_ORDER, parameters);

    return result;
}

const placeOrder = (payload) => {
    let result = HttpAuthPost(routes.PLACE_ORDER, payload);

    return result;
}

const payOrder = (parameters) => {
    let result = HttpAuthGet(routes.PAY_ORDER, parameters);

    return result;
}

const cancelOrder = (parameters) => {
    let result = HttpAuthGet(routes.CANCEL_ORDER, parameters);

    return result;
}

const orderListStatus = (payload) => {
    let result = HttpAuthPost(routes.ORDER_LIST_STATUS, payload);

    return result;
}

const pendingOrder = (parameters) => {
    let result = HttpAuthGet(routes.PENDING_ORDER, parameters);

    return result;
}

const createItem = (payload) => {
    let result = HttpAuthPost(routes.CREATE_ITEM, payload);

    return result;
}

const updateItem = (payload) => {
    let result = HttpAuthPut1(routes.UPDATE_ITEM, payload);

    return result;
}

const deleteItem = (payload) => {
    let result = HttpAuthDelete1(routes.DELETE_ITEM, payload);

    return result;
}


const OrderManagementService = {
    createOrder,
    listOrder,
    viewOrder,
    placeOrder,
    payOrder,
    cancelOrder,
    pendingOrder,
    orderListStatus,
    createItem,
    updateItem,
    deleteItem,
    listOrderItems
}

export default OrderManagementService