import { Alert, Box, Button, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import {FileUploader} from "react-drag-drop-files";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import useFilename from "../../../zustand/useFilename";
import { useNavigate } from "react-router-dom";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CustomButton from "../../ui/CustomButton";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function FileUploaderComponent () {

    // const [file, setFile] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const { setFilename, resetFilename, file, setFile, fileSize, setFileSize, resetFile } = useFilename();
    const navigate = useNavigate()
    const [showError, setShowError] = useState(false)
    const [fileErrMsg, setFileErrMsg] = useState("")

    const allowedImageTypes = [
        "image/jpeg",
        "image/png",
        "image/webp",
        "image/svg+xml",
        "image/pjpeg", // .jfif
        "image/jp2",   // .jp2
        "image/tiff",  // .tiff
        "image/tiff",  // .tif (tiff can have the same MIME type)
        "image/svg+xml" // .svg (assuming it's SVG)];
    ];

    // const [fileSize, setFileSize] = useState(null)

    const handleChange = (file) => {
        setShowError(false)
        setFileErrMsg("")

        const fileName = file.name;
        const fileExt = fileName.lastIndexOf(".") + 1;
        const mimetype = fileName.substr(fileExt, fileName.length).toLowerCase();
        const fileSize = file.size;

        let fileSizeDisplay = '';
        if (fileSize < 1024 * 1024) {
            // Less than 1 MB, display in KB
            fileSizeDisplay = `${(fileSize / 1024).toFixed(2)} KB`;
        } else {
            // 1 MB or larger, display in MB
            fileSizeDisplay = `${(fileSize / (1024 * 1024)).toFixed(2)} MB`;
        }
        setFileSize(fileSizeDisplay)
            
        setFilename(fileName.split('.').slice(0, -1).join('.'));
        
        if (mimetype === "pdf"){
            setFile(file);
        } 
       
    };

    const handleResetFile = (e) => {
        setFile(null);
        setFileSize(null);
        setFilename("");
        document.querySelector('input[type="file"]').value = '';
    };

    useEffect(() => {
        return () => {
          resetFilename(); // Resets filename when component unmounts or page changes
          resetFile()
        };
      }, [navigate, resetFilename, resetFile]);

      console.log('get file size hereee', fileSize)

    return (
        <Box>
             {file &&
                <Box sx={{textAlign: "end"}}>
                    <Button onClick={(e) => handleResetFile(e)} sx={{cursor: "pointer", textTransform: "none", borderRadius: 0, fontSize: 14, color: '#604CC3'}}>
                        Clear
                    </Button>
                </Box>
            }
            <FileUploader
                multiple={false}
                handleChange={handleChange}
                name="file"
                label="Drag and Drop file here or click here to upload"
                types={["PDF"]}
                onTypeError={(err) => {
                    setFileErrMsg(err)
                    setShowError(true)
                }}
                children={
                    <>
                        {(file) ? 
                            <div 
                                style={{
                                    position: "relative",
                                    display: "flex",
                                    alignItems: "center",
                                    minWidth: "100% !important",
                                    maxWidth: "100% !important",
                                    border: "dashed 2px #604CC3",
                                    borderRadius: "8px",
                                    cursor: "pointer",
                                    flexGrow: 0,
                                    height: "400px",
                                    justifyContent: "center",
                                    margin: "auto",
                                    transition: "border-color .15s linear",
                                    backgroundColor:'#8080802e'
                                }} 
                            >
                                <Document file={file} renderMode="canvas">
                                        <Page pageNumber={pageNumber} height={300} />
                                </Document>
                            </div>
                        :
                            <div
                                style={{
                                    position: "relative",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    minWidth: "100% !important",
                                    maxWidth: "100% !important",
                                    border: "dashed 2px #604CC3",
                                    borderRadius: "2px",
                                    cursor: "pointer",
                                    flexGrow: 0,
                                    height: "400px",
                                    margin: "auto",
                                    transition: "border-color .15s linear",
                                    backgroundColor: "white",
                                    textAlign: "center",
                                    padding: "20px",

                                }}
                            >
                                <CloudUploadIcon sx={{ color: "#604CC3", width: 50, height: 50, mb: 1 }} />

                                <Typography sx={{ color: "#666", fontWeight: 600, fontSize: 16 }}>
                                    Drag and Drop file here or click here to upload
                                </Typography>

                                <CustomButton label="Upload" variant="contained" sx={{ mt: 1, pt: 0, pb: 0, fontSize: 13 }} />

                                <Typography
                                    sx={{
                                        fontSize: "15px",
                                        fontStyle: "italic",
                                        color: "red",
                                        position: "absolute",
                                        bottom: "20px",
                                    }}
                                >
                                    Please note that only PDF files are accepted.
                                </Typography>
                            </div>
                        }
                    </>
                }
            />
            {/* {file && <p style={{fontSize: 12, textAlign:'start', color:'#0178f2', marginBottom: 0}}>Image or PDF file.</p>} */}
            {
                showError && <Alert sx={{mt: 1}} severity="error" variant="filled">{fileErrMsg}</Alert>
            }
        </Box>
    )
}