import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import SignatureTabs from "./signature-tabs.component";
import { useEffect, useState } from "react";



export default function CreateEsignatureCommon (props) {

    const {openEsignatureDialog, handleCloseEsignatureDialog, uploadPublicSignature, uploading_signature } = props;

    const [isLinkTab, setLinkTab] = useState(false);

    const sendTabLabelToParent = (child) => {
        setLinkTab(child);
    }
    

    return(
        <Dialog open={openEsignatureDialog} onClose={handleCloseEsignatureDialog} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5}}}>
            <DialogTitle style={{fontWeight: "bold", fontSize: 16, display: "flex"}}>
                Create Electronic Signature
            </DialogTitle>
            <DialogContent sx={{p: 0}}>
                <SignatureTabs 
                    sendTabLabelToParent={sendTabLabelToParent}
                    handleCloseEsignatureDialog={handleCloseEsignatureDialog}
                    uploadPublicSignature={uploadPublicSignature}
                    uploading_signature={uploading_signature}
                />
            </DialogContent>
        </Dialog>
    )
}