import React, {useEffect, useState} from "react";
import {Avatar, Container, Paper, Divider, Box, CircularProgress, Typography} from "@mui/material";
import CustomTypography from "../../../core/components/ui/CustomTypography";
import CustomButton from "../../../core/components/ui/CustomButton";
import useMembersManagement from "../hooks/useMembersManagement";
import CustomDialogLoaderComponent from "../../../core/components/common/custom-dialog-loader.component";
import {stringAvatar} from "../../../core/utils/avatarImageUtil";
import SuperSignLogo from "../../../assets/icons/supersign-logo.png";

const WorkspaceAcceptInviteComponent = (props) => {
    const {invite_code, isUserAuthenticated, setIsUserLogin, invitationDetails} = props;
    const {isAcceptingInvitation, acceptInvite, declineInvite, isDecliningInvitation} = useMembersManagement();

    const [workspaceRole, setWorkspaceRole] = useState(null);


    useEffect(() => {
        if (invitationDetails) {
            let role = invitationDetails.invitation.role === "WSAdmin" ? "Admin" : invitationDetails.invitation.role === "WSMember" ? "Member" : "N / A";

            setWorkspaceRole(role);
        }
    }, [invitationDetails])


    const handleDeclineInvitation = () => {
        const formData = new FormData();
        
        formData.append("invitation_code", invite_code);
        declineInvite(formData);
    }

    const handleAcceptInvitation = () => {
        if (isUserAuthenticated?.accessToken) {
            const formData = new FormData();
            
            formData.append("invitation_code", invite_code);
            acceptInvite(formData, invitationDetails?.workspace?.id);
            setIsUserLogin(false);  

        } else {
            localStorage.setItem("invitationDetails", JSON.stringify(invitationDetails));
            
            window.location.href = "/login";
            setIsUserLogin(true);
        }
    }
    

  
    return (
        <Container maxWidth="md" sx={{width: 650}}>
            <Paper elevation={3} sx={{mt: 20, boxShadow: "0px 3px 6px -2px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.08), 0px 2px 12px 0px rgba(0, 0, 0, 0.05)"}}>
                <Box sx={{p: "30px 10px 12px"}}>
                    <Avatar
                        variant="square"
                        src={invitationDetails?.workspace?.logo || null}
                        {...(invitationDetails?.workspace?.logo ? {} : (invitationDetails?.workspace?.name) ? stringAvatar(invitationDetails?.workspace?.name) : {})} 
                        style={{width: (invitationDetails?.workspace?.logo) ? "120px" : 75, height: (invitationDetails?.workspace?.logo) ? "100%" : 50, margin: "auto"}}
                        slotProps={{img: {style: {objectFit: (invitationDetails?.workspace.logo) ? "contain" : {}}}}}
                    />

                    <CustomTypography 
                        variant="subtitle2" 
                        customStyles={{fontWeight: "600", fontSize: 22, marginTop: "12px"}}
                        text={invitationDetails?.workspace?.name} 
                    />
                </Box>

                <Divider sx={{border: "2.5px solid #604CC3"}} />

                <Box sx={{padding: "42px 32px 0px"}}>
                    {(isAcceptingInvitation || isDecliningInvitation) ? 
                        <CustomDialogLoaderComponent 
                            icon={<CircularProgress size={40} sx={{color: "#604CC3"}} />} 
                            message="Please wait for a moment..." 
                            customStyles={{padding: "50px"}}
                        />
                    :
                        <>
                            <CustomTypography 
                                variant="body1" 
                                customStyles={{fontWeight: "500", fontSize: 20, marginBottom: "26px", textAlign: "center", color: "#58565f"}}
                                text={"SuperSign - Workspace Invitation"} 
                            />

                            <CustomTypography 
                                variant="subtitle2" 
                                customStyles={{fontWeight: "400", fontSize: 18, textAlign: "start"}}
                                text={`Hello ${invitationDetails?.invited_user?.email},`}
                            />

                            <CustomTypography 
                                variant="subtitle2" 
                                customStyles={{fontWeight: "400", fontSize: 18, textAlign: "start", mt: "20px"}}
                                text={`You have been invited by ${invitationDetails?.requester?.email} to join <strong>"${invitationDetails?.workspace?.name}"</strong>, a workspace in SuperSign, as a ${workspaceRole}. Would you like to accept the invitation?`}
                            />

                            <Box sx={{mt: "40px", display: "flex"}}>
                                <Box sx={{ml: "auto"}}>
                                    <CustomButton 
                                        variant="text" 
                                        label="Decline"
                                        customStyles={{
                                            opacity: (invitationDetails) ? "100%" : "55%",
                                            cursor: (invitationDetails) ? "pointer" : "not-allowed",
                                            fontSize: 17
                                        }}
                                        onClick={(invitationDetails) ? handleDeclineInvitation : null}  
                                    /> &nbsp;

                                    <CustomButton 
                                        variant="contained" 
                                        label="Accept" 
                                        customStyles={{
                                            opacity: (invitationDetails) ? "100%" : "55%",
                                            cursor: (invitationDetails) ? "pointer" : "not-allowed",
                                        }}
                                        onClick={(invitationDetails) ? handleAcceptInvitation : null}
                                    />
                                </Box>
                            </Box>

                            <Typography sx={{textAlign: "center", fontSize: 10, display: "flex", alignItems: "center", color: "#58565f", cursor: "default", padding: "0px 0px 10px"}}>
                                Powered by:<img alt="" src={SuperSignLogo} style={{height: 19, objectFit: "contain", marginLeft: "4px"}} />
                            </Typography> 
                        </>
                    }
                </Box>
            </Paper>
        </Container>
    )
}

export default WorkspaceAcceptInviteComponent
