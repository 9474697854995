import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardActions, Button, Typography, Box, TextField, FormControl, OutlinedInput, FormHelperText, Skeleton, Badge, styled, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Snackbar, Alert } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CustomTypography from '../../../core/components/ui/CustomTypography';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import BuyTokenComponent from '../components/buy-token.component';
import { useNavigate } from 'react-router-dom';
import tokenIcon from '../../../assets/icons/token.png';
import useOrderManagement from '../hooks/useOrderManagement';
import { ThreeDots } from "react-loader-spinner";
import DeleteIcon from '@mui/icons-material/Delete';




const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#604CC3',
        color: '#fff',
    },
}));


export default function BuyTokenScreen() {

    const navigate = useNavigate();
    const orderId = new URLSearchParams(window.location.search).get("orderId");
    const { createOrder, isCreatingOrder, viewOrder, orderDataArray, orderData, isFetchingViewOrder } = useOrderManagement();

    useEffect(() => {
        if (orderId === null || orderId === undefined) {
            console.error("Order ID is invalid. Aborting viewOrder.");
            return;
        }
        if(orderId){
            let parameters = { "order_id": orderId };
            viewOrder(parameters);
        }
    }, [orderId])


    // console.log("orderDataArray herexxxx", orderDataArray)


    const [orderDataListArray, setOrderDataListArray] = useState(orderDataArray);
    const [cartCount, setCartCount] = useState(0);
    // const [cartItems, setCartItems] = useState(JSON.parse(localStorage.getItem('cartItems')) || []);
    const [openCartDialog, setOpenCartDialog] = useState(false);

    const profileWalletId = localStorage.getItem('profile_wallet');
    const [isEmail, setIsEmail] = useState(JSON.parse(localStorage.getItem('isEmail')));
    const [isWorkspace, setIsWorkspace] = useState(JSON.parse(localStorage.getItem('isWorkspace')));
    const [wallet_id, setWalletId] = useState(localStorage.getItem('wallet_id'));
    const [addedToCart, setAddedToCart] = useState(() => {
        return JSON.parse(localStorage.getItem('addedToCart')) || [];
    });

    const [cartItems, setCartItems] = useState(() => {
        return JSON.parse(localStorage.getItem('cartItems')) || [];
    });
    // const [addedToCart, setAddedToCart] = useState(JSON.parse(localStorage.getItem('addedToCart')) || []);


    useEffect(() => {
        if (orderDataArray) {
            setOrderDataListArray(orderDataArray);
        }
    }, [orderDataArray]);



    useEffect(() => {
        const syncLocalStorage = () => {
            const storedIsEmail = JSON.parse(localStorage.getItem('isEmail'));
            const storedIsWorkspace = JSON.parse(localStorage.getItem('isWorkspace'));
            const storedWalletId = localStorage.getItem('wallet_id');
            const storedAddedToCart = JSON.parse(localStorage.getItem('addedToCart')) || [];
            const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];


            if (storedIsEmail !== isEmail) setIsEmail(storedIsEmail);
            if (storedIsWorkspace !== isWorkspace) setIsWorkspace(storedIsWorkspace);
            if (storedWalletId !== wallet_id) setWalletId(storedWalletId);
            if (storedAddedToCart !== addedToCart) setAddedToCart(storedAddedToCart);
            if (storedCartItems !== cartItems) setCartItems(storedCartItems);
        };

        const intervalId = setInterval(syncLocalStorage, 500);

        return () => clearInterval(intervalId);
    }, [isEmail, isWorkspace, wallet_id, addedToCart]);

    useEffect(() => {
        if (addedToCart?.length === 0 && cartItems?.length > 0) {
            const packageCodes = cartItems?.map(item => item.packageCode);
            setAddedToCart(packageCodes);
            localStorage.setItem('addedToCart', JSON.stringify(packageCodes));  // Save to localStorage as well
        }
    }, [cartItems, addedToCart]);


    useEffect(() => {
        const storedCart = JSON.parse(localStorage.getItem('cartItems')) || [];
        const storedAddedToCart = JSON.parse(localStorage.getItem('addedToCart')) || [];
        const addedCustomAmount = JSON.parse(localStorage.getItem('addedCustomAmount'));

        const nonNullCount = storedAddedToCart.length + (addedCustomAmount ? 1 : 0);

        if (storedCart.length === 0 && orderId) {
            // Use orderDataArray only if orderId is present in the URL and storedCart is empty
            setCartItems(orderDataListArray);
            setCartCount(nonNullCount);
        } else {
            setCartItems(storedCart);
            setCartCount(storedCart.length);
        }
    }, [orderId, orderDataListArray]);



    const handleAddToCart = (identifier, price, amount) => {
        // Add quantity property to new items
        const isCustom = identifier === 'Custom';

        const newItem = isCustom
            ? { type: 'custom', packageCode: identifier, price: amount, quantity: price }
            : { type: 'package', packageCode: identifier, price: price, quantity: 1 };

        // Add the new item to the cart and update the state
        const updatedCart = [...cartItems, newItem];
        setCartItems(updatedCart);
        setCartCount(updatedCart.length);

        const updatedAddedToCart = [...addedToCart];
        if (!updatedAddedToCart.includes(identifier)) {
            updatedAddedToCart.push(identifier);
            setAddedToCart(updatedAddedToCart);
        }

        // Save the updated cart to localStorage
        localStorage.setItem('cartItems', JSON.stringify(updatedCart));
        localStorage.setItem('addedToCart', JSON.stringify(updatedAddedToCart));
    };

    useEffect(() => {
        localStorage.setItem('addedToCart', JSON.stringify(addedToCart));
    }, [addedToCart]);

    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);


    // Open the cart dialog
    const handleOpenCartDialog = () => {
        setOpenCartDialog(true);
    };

    // Close the cart dialog
    const handleCloseCartDialog = () => {
        setOpenCartDialog(false);
    };

    const handleCreateOrder = () => {
        localStorage.removeItem('storedCartCount');

        const payload = {
            currency: "USD",
            createItemsRequestList: [],
            wallet_type: isEmail ? "Personal" : isWorkspace ? "Workspace" : null,
            wallet_id: isEmail ? profileWalletId : wallet_id,
        };

        if (!payload.wallet_type || !payload.wallet_id) {
            console.error('No valid wallet type or wallet id selected');
            return;
        }

        cartItems.forEach((item) => {
            payload.createItemsRequestList.push({
                package_code: item.type === 'package' ? item.packageCode : null,
                token_amount: item.type === 'package' ? null : item.quantity,
                is_package: item.type === 'package' ? true : false,
            });
        });

        createOrder(payload);
    };


    const handleRemoveFromCart = (packageCode) => {
        // Filter out the item that matches the packageCode
        const updatedCart = cartItems.filter(item => item.packageCode !== packageCode);

        // Update the state and cart count after removal
        setCartItems(updatedCart);
        setCartCount(updatedCart.length);

        // Update localStorage with the modified cart
        localStorage.setItem('cartItems', JSON.stringify(updatedCart));
    };


    useEffect(() => {
        // console.log("orderData ere", orderData)
        if (orderId) {
            if (orderData && orderData?.status === "For Payment") {
                navigate(`/place-order?orderId=${orderId}`);
            } 
        }
    }, [orderId, orderData])


    return (
        <Box sx={{
            backgroundColor: "transparent", padding: "26px 50px", paddingBottom: '10px',
            border: "none", boxShadow: "none", mt: "1px", overflow: 'hidden !important'
        }}>
            <Box sx={{ flexGrow: 1, margin: "0px auto auto" }}>
                <Grid container spacing={2}>
                    <Grid size={12} sx={{ textAlign: "start", mb: 0, pb: 0 }}>
                        <CustomTypography
                            variant="h6"
                            text={"BUY TOKENS"}
                            customStyles={{ mb: 0, textTransform: 'uppercase' }}
                        />
                    </Grid>
                    <Grid size={12} sx={{ textAlign: "start", mt: -2, pt: 0 }}>
                        <CustomTypography
                            variant="subtitle2"
                            text={"Purchase tokens to unlock exclusive features, premium templates, and access advanced document features."}
                            customStyles={{ mt: 0, fontWeight: 'regular' }}
                        />
                    </Grid>

                    <Grid size={12} sx={{ mt: 2, pt: 0, justifyContent: 'flex-end', justifyItems: 'flex-end', mr: 1 }}>
                        <Box sx={{ display: 'flex', alignSelf: 'center', alignItems: 'center' }}>
                            {orderId ?
                                null
                                :
                                <StyledBadge badgeContent={cartCount} color='primary'
                                // onClick={handleOpenCartDialog}
                                >
                                    <ShoppingCartOutlinedIcon style={{ color: '#604CC3' }} />
                                </StyledBadge>
                            }
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {orderId && orderDataArray?.length !== 0 ?
                                <Button
                                    sx={{
                                        textTransform: 'uppercase',
                                        color: '#604CC3',
                                        backgroundColor: '#fff',
                                        border: '1px solid #c7c0ea',
                                    }}
                                    onClick={() => navigate(`checkout?orderId=${orderId}`)}
                                >
                                    Update Item
                                </Button>
                            :
                                <Button
                                    sx={{
                                        textTransform: 'uppercase',
                                        color: '#604CC3',
                                        backgroundColor: '#fff',
                                        border: '1px solid #c7c0ea',
                                    }}
                                    onClick={handleCreateOrder}
                                    disabled={cartCount === 0 || cartCount === null}
                                    startIcon={isCreatingOrder && <ThreeDots height="20" width="20" radius="9" color="#604CC3" ariaLabel="three-dots-loading" visible={true} />}
                                >
                                    {isCreatingOrder ? 'Creating Order' : 'Check out'}
                                </Button>
                            }
                        </Box>
                    </Grid>
                </Grid>

                <br />

                <BuyTokenComponent 
                    setOrderDataListArray={setOrderDataListArray} 
                    isFetchingOrders={isFetchingViewOrder} 
                    onAddToCart={handleAddToCart} 
                    orderDataArray={orderDataListArray} 
                    orderId={orderId} 
                    setCartItems={setCartItems} 
                    setCartCount={setCartCount}
                    viewOrder={viewOrder}
                    cartItems={cartItems}
                    addedToCart={addedToCart}
                    setAddedToCart={setAddedToCart}
                 />

            </Box>

            {/* <Dialog open={openCartDialog} onClose={handleCloseCartDialog} fullWidth maxWidth="sm">
                <DialogTitle>Cart Details</DialogTitle>
                <DialogContent>
                    {cartItems.length > 0 ? (
                        // Group items by packageCode
                        Object.entries(
                            cartItems.reduce((acc, item) => {
                                const { packageCode, packageName, quantity } = item;

                                if (packageCode === "Custom") {
                                    // Combine all 'Custom' items with the same packageName
                                    const customKey = `${packageCode}_${packageName || 'default'}`; // Use 'default' if packageName is undefined
                                    if (acc[customKey]) {
                                        acc[customKey].quantity += parseInt(quantity, 10); // Convert quantity to a number before adding
                                    } else {
                                        acc[customKey] = { ...item, quantity: parseInt(quantity, 10) }; // Initialize quantity as a number
                                    }
                                } else {
                                    // Handle normal items by grouping and incrementing quantity
                                    if (acc[packageCode]) {
                                        acc[packageCode].quantity += parseInt(quantity, 10); // Convert quantity to a number
                                    } else {
                                        acc[packageCode] = { ...item, quantity: parseInt(quantity, 10) }; // Initialize quantity as a number
                                    }
                                }
                                return acc;
                            }, {})
                        ).map(([key, groupedItem], index) => (
                            <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2, alignItems: 'center' }}>
                                {console.log("groupedItemxxx", groupedItem)}
                                <Box sx={{ marginRight: 2 }}>
                                    <img src={tokenIcon} alt="Package Icon" style={{ width: '40px', height: '40px' }} />
                                </Box>

                                <Box sx={{ flex: 1 }}>
                                    <Typography variant="h6">{groupedItem.packageCode}</Typography>
                                    <Typography>Price: ${groupedItem.price}</Typography>
                                </Box>

                                <Box sx={{ display: 'flex', alignItems: 'center', mr: 5 }}>
                                    <Typography>Quantity: {groupedItem.quantity}</Typography>
                                </Box>


                                <Box sx={{ display: 'flex', alignItems: 'center', ml: 5 }}>
                                    <Button
                                        sx={{ color: '#f63539', backgroundColor: '#fff', border: '1px solid #ff00006b', alignSelf: 'center', justifyContent: 'center', margin: '0px' }}
                                        onClick={() => handleRemoveFromCart(groupedItem.packageCode)}
                                    >
                                        <DeleteIcon sx={{ width: 20 }} />
                                    </Button>
                                </Box>
                            </Box>
                        ))
                    ) : (
                        <Typography>No items in the cart</Typography>
                    )}

                    <Box sx={{ marginTop: 3 }}>
                        <Typography variant="h6">
                            Total Amount: $
                            {cartItems.reduce((total, item) => {
                                const price = parseFloat(item.price) || 0; // Ensure price is a number
                                const quantity = parseInt(item.quantity, 10) || 0; // Ensure quantity is a number
                                console.log('Price:', price, 'Quantity:', quantity); // Log price and quantity for debugging
                                if (isNaN(price) || isNaN(quantity)) {
                                    console.warn("Invalid item data", item); // Log invalid items
                                    return total; // Skip invalid item
                                }
                                return total + price * quantity;
                            }, 0).toFixed(2)}

                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCartDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog> */}
           
        </Box>
    );
}