import {Box, Typography, Card, CardContent, Avatar} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {Keyboard, Pagination, Navigation} from "swiper/modules";
import UserOne from "../../../assets/images/user_one.jpg";
import UserTwo from "../../../assets/images/user_two.jpg";
import UserThree from "../../../assets/images/user_three.jpg";
import UserFour from "../../../assets/images/user-four.jpg";
import UserFive from "../../../assets/images/user-five.jpg";

const testimonials = [
  {
    name: "Kim Namjoon",
    role: "Accounting Staff",
    avatar: UserOne, 
    title: "Great PDF editor.",
    text: `"SuperSign is easy to use, easy to learn, has great functionality (edit, add text, erase text, assign signing, dates, initials etc.). The price is lower than other comparable programs. It is easy to share documents and templates with other team members."`,
  },
  {
    name: "Kim Seokjin",
    role: "Bighit CEO",
    avatar: UserTwo,
    title: "SuperSign is user-friendly.",
    text: `"We needed the process to be user-friendly, legally immutable and trusted."`,
  },
  {
    name: "Jeon Jungkook",
    role: "Finance Team Staff",
    avatar: UserThree,
    title: "Great PDF editor.",
    text: `"Ease of use and editable, I can customize a document quickly and send it for signatory. Sending documents, agenda and edits is relatively quick. Saves me so much time I can seal the deal and move onto the next task."`,
  },
  {
    name: "Min Yoongi",
    role: "Director",
    avatar: UserFour,
    title: "SuperSign is user-friendly.",
    text: `"We needed the process to be user-friendly, legally immutable and trusted."`,
  },
  {
    name: "Sergeant Kim Seokjin",
    role: "Commander Sergeant",
    avatar: UserFive,
    title: "SuperSign is user-friendly.",
    text: `"We needed the process to be user-friendly, legally immutable and trusted."`,
  },
  {
    name: "Jeon Jungkook",
    role: "COO",
    avatar: UserThree,
    title: "Great PDF editor.",
    text: `"Ease of use and editable, I can customize a document quickly and send it for signatory. Sending documents, agenda and edits is relatively quick. Saves me so much time I can seal the deal and move onto the next task."`,
  },
]

const UserFeedbackComponent = ({breakpoints}) => {

  return (
    <Box sx={{p: "18px 0px 8px"}}>
      <Box sx={{width: (breakpoints == "xs") ? "90%" : "80%", margin: "auto"}}>
        <Typography sx={{fontWeight: "bold", fontSize: 45, color: "#5b3cc4", textAlign: "center"}}>
          Here's what our customers are saying
        </Typography>

        <Typography variant="subtitle1" color="#FF6600" sx={{mb: 4.5, fontSize: "22px", mt: "-13px"}}>
          Discover how we make a difference
        </Typography>

        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          keyboard={{enabled: true}}
          pagination={{clickable: true, el: ".custom-pagination"}}
          navigation={true}
          modules={[Keyboard, Pagination, Navigation]}
          className="mySwiper"
          style={{ paddingRight: "6px" }}
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index} style={{overflow: "visible"}}>
              <Card sx={{p: 2, borderRadius: "4px", height: "250px !important", boxShadow: "0px 2px 8px rgba(91, 60, 196, 0.3)", overflow: "visible",
                    display: "flex",
                    flexDirection: "column",
              }}>
                <CardContent sx={{display: "flex", flexDirection: "column", flexGrow: 1, height: "100%", p: "8px !important"}}>
                  <Typography variant="h6" gutterBottom sx={{textAlign: "start", fontWeight: "bold", color: "#424546"}}>
                    {testimonial.title}
                  </Typography>

                  <Box sx={{flexGrow: 1}}>
                    <Typography variant="body1" color="text.secondary" sx={{textAlign: "start"}}>
                      {testimonial.text}
                    </Typography>
                  </Box>

                  <Box sx={{display: "flex", alignItems: "center", mt: "auto"}}>
                    <Avatar src={testimonial.avatar} sx={{ width: 40, height: 40, mr: 2 }} />
                  <Box>

                  <Typography sx={{color: "#424546", fontWeight: "bold", textAlign: "start"}}>
                    {testimonial.name}
                  </Typography>

                  <Typography variant="body2" color="text.secondary" sx={{textAlign: "start"}}>
                    {testimonial.role}
                  </Typography>
                </Box>
                </Box>
                </CardContent>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="custom-pagination"></div>
      </Box>
    </Box>
  )
}

export default UserFeedbackComponent