import { Box, Tooltip, Typography, IconButton, Popper, ClickAwayListener, Divider, ButtonGroup, Dialog, Grid2, List, ListItemButton, ListItemIcon, ListItemText, TextareaAutosize, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { QRCodeCanvas } from 'qrcode.react';
import useElements from "../../zustand/useElements";
import DeleteIcon from "@mui/icons-material/Delete";
import { Edit } from "@mui/icons-material";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import useAuthentication from "../../../../profile-plugin/hooks/useAuthentication";
import AddSignatoryDialogComponent from "../../common/dialog/add-signatory-dialog.component";
import useSignatoryEmailStore from "../../zustand/useSignatoyEmailStore";
import { generateQRCodeBase64 } from "../../utils/QRCodeUtil";
import DocumentEditorPopperComponent from "./document-editor-popper.component";
import {elementTypes, minElementWidth, minElementHeight} from "../../common/staticVariables";
import { getAdjustedWidth, getAdjustedHeight } from "../../utils/AspectRatioUtil";
import elements from "../../common/elements";
import useDigitalSignature from "../../zustand/useDigitalSignature";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import useSignatoryStore from "../../zustand/useSignatoryStore";
import useSignatoryElementStore from "../../zustand/useSignatoryElementStore";
import { DateCalendar, DateField, DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { format, parse } from "date-fns";
import { calculateLines } from "../../utils/TextFieldUtil";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import useProfileStore from "../../../../profile-plugin/zustand/useUpdateProfileStore";
import AlertDialogComponent from "../../common/dialog/signatory/alert-dialog.component";
import dayjs, { Dayjs } from 'dayjs';

export default function DocumentEditorContainerComponent(props) {
    const { element, parentRef, textValue, handleTextfieldChange, documentById, handleDuplicateElements } = props;

    const [isDraggingUsingToolbarDragItem, setIsDraggingUsingToolbarDragItem] = useState(false);
    const [elementRef, setElementRef] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSignatoryDialog, setOpenSignatoryDialog] = useState(false)
    const [emailSignatory, setEmailSignatory] = useState('')
    const [dynamicColor, setDynamicColor] = useState('');


    const { viewProfile, profileData, isFetchingProfileDetails } = useAuthentication();
    const { removeElement, updateElement } = useElements();
    const elementToolbarRef = useRef();
    const { personName, setPersonName } = useSignatoryEmailStore()
    const { signatureUrl } = useDigitalSignature();
    const removeSignatory = useSignatoryStore((state) => state.removeSignatory)

    const setHasPDFChanges = useElements((state) => state.setHasPDFChanges);
    const elements = useElements((state) => state.elements);
    const { signatoryEmailValue, setSignatoryEmailValue } = useSignatoryElementStore();
    const profileESignature = useProfileStore((state) => state.profileESignature);

    const [label, setLabel] = useState('')

    const elRef = useRef();
    const open = Boolean(anchorEl);
    const popperId = open ? "simple-popper" : undefined;
    const [qrCodeBase64, setQRCodeBase64] = useState(null)
    const [isElementClicked, setIsElementClicked] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [elMinConstraints, setElMinConstraints] = useState({width: 0, height: 0});
    const [elMaxConstraints, setElMaxConstraints] = useState({width: 0, height: 0, x: 0, y: 0});
    const [textFieldValue, setTextFieldValue] = useState(null);
    const [dateFormat, setDateFormat] = useState('MM/dd/yyyy'); // Default format
    const [selectedDate, setSelectedDate] = useState(dayjs(format(new Date(), dateFormat)).toDate());
    const [formattedDate, setFormattedDate] = useState('');
    const [displayDate, setDisplayDate] = useState('');
    const today = new Date();
    const [openDeleteSigneeAlertDialog, setOpenDeleteSigneeAlertDialog] = useState(false)    


    const isElementLockedAspectRatio = (element.type == elementTypes.date || element.type == elementTypes.textField || element.type === 'fillable_date' || element.type === 'fillable_textfield') ? false : true;

    console.log('get elements plotted', element)
    console.log('isElementLockedAspectRatio', isElementLockedAspectRatio)

    const textareaRef = useRef(null);
    const fillableTextFieldRef = useRef(null);
    const dateRef = useRef(null);
    const fillableDateRef = useRef(null);

    useEffect(() => {
        console.log("selectedDate bbb ", selectedDate);
    }, [selectedDate])

    const handleOpenSignatory = (element, signatoryLabel) => {
        setLabel(signatoryLabel)
        // if(element?.value === ""){
        //     setOpenSignatoryDialog(true)
        // } else {
            setOpenSignatoryDialog(true)
        // }
    }

    const handleCloseSignatory = () => {
        setOpenSignatoryDialog(false)
    }

    const handleElementClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget); // Toggle Popper
    };

    const handleClose = () => {
        setAnchorEl(null); // Close Popper when clicking away
    };

    const handleOnMouseEnter = (isDraggable) => {
        console.log("handleOnMouseEnter bbbb", isDraggable);
        setIsDraggingUsingToolbarDragItem(true);
    };

    const handleDelete = () => {
        setHasPDFChanges(true)
        if (element.type === elementTypes.signatory && element.value !== "") {
            const signatoryElements = elements.filter(el => el.type === elementTypes.signatory && el.value === element.value);
            const assignedElements = elements.filter(el => el.type !== elementTypes.signatory && el.value === element.value);
        
            if (signatoryElements.length === 1) {
                if (assignedElements.length > 0) {
                    setOpenDeleteSigneeAlertDialog(true);
                    return;
                } 
                removeSignatory(element.value);
            }
            removeElement(element.id);
        } else {
            removeElement(element.id);
        }
    };

    const [{ isDragging }, dragRef, preview] = useDrag(() => ({
        type: 'element_fields',
        item: { ...element, elementToolbarRef: elementToolbarRef },
        canDrag: (item, monitor) => {
            console.log("isDraggingUsingToolbarDragItem bbbb", isDraggingUsingToolbarDragItem);
            return isDraggingUsingToolbarDragItem;
        },
        end: (item, monitor) => { },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }), [JSON.stringify(element), elementToolbarRef, isDraggingUsingToolbarDragItem]);

    const elDragRef = dragRef(elRef);

    const handleResizeStart = () => {
        setIsDraggingUsingToolbarDragItem(false)
    }

    const handleResizeStop = (e, data, element, updateElement) => {
        setHasPDFChanges(true)
        const updatedElement = { ...element, width: data.size.width, height: data.size.height };
        updateElement(updatedElement);
    };

    function handleOnIsEditing(bool){
        setIsEditing(bool);
    }

    const handleDateChange = (newDate) => {
        console.log("newDate bbb", newDate);
        
        if (newDate) {
            setSelectedDate(newDate); 
            const formatted = format(newDate, dateFormat); 
            console.log("newDate bbbb", formatted);
            setDisplayDate(formatted); 
            updateElement({ ...element, value: formatted });
        } else {
            setSelectedDate(null); 
            setDisplayDate(''); 
            updateElement({ ...element, value: '' });
        }
        setIsDatePickerOpen(false)
    };

    const handleFormatChange = (event) => {
        const newFormat = event.target.value;
        setDateFormat(newFormat);
        if (selectedDate) {
            const formatted = format(selectedDate, newFormat); // Reformat current date
            console.log("formatted nnn", formatted);
            
            setDisplayDate(formatted); // Update the displayed date
            updateElement({ ...element, value: formatted, date_format: newFormat });
        }
    };

    useEffect(() => {
        if (elDragRef != null) {
            setElementRef(elDragRef);
        }
    }, [elDragRef, JSON.stringify(element)]);

    useEffect(() => {
        viewProfile()
    }, []);

    useEffect(() => {

        let qrcodeEl = documentById.verification_link;

        const generateElementsQRCodeBase64 = async () => {
            const qrcodeBase64 = await generateQRCodeBase64(qrcodeEl);
            console.log("qrcodeBase64 111", qrcodeBase64);

            const updatedElement = {
                ...element,
                value: qrcodeBase64, // Set the new value
            };
            updateElement(updatedElement);
        }
    
       if (element.type === 'qrcode') { generateElementsQRCodeBase64(); }
    
    }, [JSON.stringify(element)]); // Include documentById if it can change

    
    useEffect(() => {
        let signatureValue = signatureUrl !== null ? signatureUrl : profileData?.profile?.signature;
    
        if (element.type === 'esignature') {
        
            const updatedElement = {
                ...element,
                value: signatureValue,
            };
            updateElement(updatedElement);
    
        }
    }, [updateElement, profileData, signatureUrl]);
    

    useEffect(()=>{
        if(signatureUrl){
            // window.location.reload()
        }
    },[signatureUrl])
    


    // useEffect(() => {
    //     if (element.element_type && element.type === 'signatory' || element.type === 'fillable_date' || element.type === 'fillable_textfield' ) {
    //         const updatedElement = {
    //             ...element,
    //             value: personName?.name?.value,
    //         };
    //         updateElement(updatedElement);
    //     }
    // }, []);

    // set minimum constraints for non image elements
    // TODO refactor code
    useEffect(() => {

        let elWidth, elHeight;

        if (element.type == elementTypes.date) {
            elWidth = elements.find((item) => item.type == elementTypes.date).width
            elHeight = elements.find((item) => item.type == elementTypes.date).height
        } 
        
        else if (element.type == elementTypes.textField) {
            elWidth = elements.find((item) => item.type == elementTypes.textField).width
            elHeight = elements.find((item) => item.type == elementTypes.textField).height
        } 
        
        else if (element.type == elementTypes.signatory) {
            elWidth = elements.find((item) => item.type == elementTypes.signatory).width
            elHeight = elements.find((item) => item.type == elementTypes.signatory).height
        }

        handleSetElMinConstraints(elWidth, elHeight, false)
    }, [])

    useEffect(() => {
        if (element.type === 'esignature' && element.value) {
            console.log('E-Signature value changed:', element.value);
        }
    }, [element.type, element.value]);
    

    // set maximum constraints for all elements on load
    // TODO move this to onDrop Element flow
    useEffect(() => {
        if (parentRef.current != null && elDragRef.current != null) {
            const parentRect = parentRef.current.getBoundingClientRect();
            const childRect = elDragRef.current.getBoundingClientRect();
    
            if (element.type == elementTypes.textField) {
                let maxWidth = (parentRect.right - childRect.right) + childRect.width
                let maxHeight = (parentRect.bottom - childRect.bottom) + childRect.height
                setElMaxConstraints({width: maxWidth, height: maxHeight})
                return;
            }
    
            let maxWidth, maxHeight;
            let rightDiff = parentRect.right - childRect.right;
            let bottomDiff = parentRect.bottom - childRect.bottom;
    
            maxWidth = rightDiff + childRect.width
            maxHeight = bottomDiff + childRect.height

            let hasCoordsChange = elMaxConstraints.x != childRect.x && elMaxConstraints.y != childRect.y ? true : false;
            if (hasCoordsChange) setElMaxConstraints({width: Math.floor(maxWidth), height: Math.floor(maxHeight), x: childRect.x, y: childRect.y})
        }
    }, [JSON.stringify(element), parentRef, elDragRef])

    const onResize = (event, {el, size, handle}) => {
        // get max constraints with maintained aspect ratio
        if (element.type == elementTypes.qrcode || element.type == elementTypes.userSignature || element.type == elementTypes.signatory) {
            handleSetElMaxConstraints(size)
        }
    }

    const handleSetElMaxConstraints = (size) => {
        let sizeWidth = Math.floor(size.width)
        let sizeHeight = Math.floor(size.height)

        if (parentRef.current != null) {
            const parentRect = parentRef.current.getBoundingClientRect();
            
            let rightDiff = parentRect.width - (element.x_axis + element.width);
            let bottomDiff = parentRect.height - (element.y_axis + element.height);
            
            let maxWidth = Math.floor(rightDiff + element.width)
            let maxHeight = Math.floor(bottomDiff + element.height)

            if (sizeWidth >= maxWidth) {
                let adjustedHeight = getAdjustedHeight(maxWidth, sizeWidth, sizeHeight)
                setElMaxConstraints({width: maxWidth, height: Math.floor(adjustedHeight)})
                return
            }
            if (sizeHeight >= maxHeight) {
                let adjustedWidth = getAdjustedWidth(maxHeight, sizeWidth, sizeHeight)
                setElMaxConstraints({width: Math.floor(adjustedWidth), height: maxHeight})
                return
            }
        }
    }

    const handleSetElMinConstraints = (origWidth, origHeight, hasAspectRatio) => {
        let minWidth, minHeight
        if (hasAspectRatio) {
            minWidth = getAdjustedWidth(minElementHeight, origWidth, origHeight)
            minHeight = getAdjustedHeight(minWidth, origWidth, origHeight)
        } else {
            minWidth = minElementWidth
            minHeight = minElementHeight
        }
        setElMinConstraints({width: minWidth, height: minHeight})
    }

    const onLoadImg = (e) => {
        // set min constraints for image elements (QRCode, UserSignature)
        handleSetElMinConstraints(e.target.width, e.target.height, true)

        // reset el max constraints when updating image
        setElMaxConstraints({width: 0, height: 0})
    }

    useEffect(() => {
        console.log("personName xxxx", personName);
        
        if (element.type === 'signatory' || element.element_type && element?.value) {
            const matchingPerson = personName?.name?.value === element.value;
            if (matchingPerson) {
                setDynamicColor(personName.color);
            }
        }
    }, [JSON.stringify(personName), JSON.stringify(element)]); 

    useEffect(() => {
        if (element.type == elementTypes.date && dateRef.current) {
            updateElement({...element, width: dateRef.current.clientWidth, height: dateRef.current.clientHeight})
        }
    }, [dateRef, fillableDateRef])

    useEffect(() => {
    
        if (element.type === 'signatory') {

            console.log("dynamicColor bbcvcv", dynamicColor);
            
            const updatedElement = {
                ...element,
                assign_color: dynamicColor ? dynamicColor : element.assign_color,
            };
            updateElement(updatedElement);
    
        }
    }, [dynamicColor]);

    useEffect(() => {
    
        if (element.type === 'fillable_date') {

            const updatedElement = {
                ...element,
                assign_color: dynamicColor ? dynamicColor : element.assign_color,
            };
            updateElement(updatedElement);
        }

        if (element.type === 'fillable_textfield' ) {

            const updatedElement = {
                ...element,
                assign_color: dynamicColor ? dynamicColor : element.assign_color,
            };
            updateElement(updatedElement);
    
        }
    }, [dynamicColor]);

    useEffect(() => {
        if (element.type == elementTypes.date) {
            updateElement({...element, width: dateRef.current.clientWidth, height: dateRef.current.clientHeight});
        }
        if (element.type == "fillable_date") {
            updateElement({...element, width: fillableDateRef.current.clientWidth, height: fillableDateRef.current.clientHeight});
        }
        if (element.type == elementTypes.textField) {
            updateElement({...element, height: element.fontSize * calculateLines(textareaRef, element.fontSize)});
        }
        if (element.type == "fillable_textfield") {
            updateElement({...element, height: element.fontSize * calculateLines(fillableTextFieldRef, element.fontSize)});
        }
    }, [element.fontSize])

    console.log('dynamicColordynamicColordynamicColor', dynamicColor)

    const handleOnChangeTextField = (e, element) => {
        const textarea = textareaRef.current;
        if (textarea) {
            handleTextfieldChange({...element, value: e.target.value, height: element.fontSize * calculateLines(textareaRef, element.fontSize)});
        }
    }

    const handleSelectElement = (element) => {
        console.log('ASDF ELEMENT', element)
        setSignatoryEmailValue(element?.value)
    }

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const anchorRef = useRef(null); // Reference for the Popper anchor

    const handleClickAway = () => {
        setIsElementClicked(isEditing ? true : false)
        setIsDatePickerOpen(false)
    }

    const handleOnChangeDateField = (value) => {
        
        console.log("value xxx", value);
        setSelectedDate(value); 
        const formatted = format(value, dateFormat); 
        updateElement({ ...element, value: formatted });
    }

    const handleBlur = () => {
        // Validate the date after input is blurred
        const [month, day, year] = element.value.split("/").map((part) => parseInt(part, 10));

        if (month < 1 || month > 12 || day < 1 || day > 31 || year < 1900 || year > 2100) {
        alert("Invalid date. Please enter a valid date.");
        }
      };

    const handleCloseAlertDialog = () => {
        setOpenDeleteSigneeAlertDialog(false)
    }

    const handleOnDeleteSignee = () => {
        removeSignatory(element.value)
        handleCloseAlertDialog()
    }

    return (
        <ClickAwayListener onClickAway={()=> handleClickAway()}>
            <Box sx={{ position: 'absolute', left: element?.x_axis, top: element?.y_axis, opacity: isDragging ? 0 : 1, zIndex: 1 }} 
                ref={elDragRef} onClick={()=>setIsElementClicked(true)} 
            >
                {/* ELEMENT TOOLTIP */}
                <DocumentEditorPopperComponent elementToolbarRef={elementToolbarRef} handleOnMouseEnter={handleOnMouseEnter} dateFormat={dateFormat} handleFormatChange={handleFormatChange}
                    handleOnIsEditing={handleOnIsEditing} isElementClicked={isElementClicked} handleOpenSignatory={handleOpenSignatory}
                    element={element} handleDelete={handleDelete} handleDuplicateElements={handleDuplicateElements} 
                        textareaRef={textareaRef} fillableTextFieldRef={fillableTextFieldRef} dateRef={dateRef} fillableDateRef={fillableDateRef}
                />
                <ResizableBox 
                    className={'editor-box'} style={{
                        // backgroundColor: element.value === personName.name ? personName.color : '#ffe2a3',
                        backgroundColor: element.assign_color,
                        padding: 0,
                        margin: 0,
                    }}
                    onMouseEnter={() => handleOnMouseEnter(false)} 
                    width={element.type === 'date' || element.type === 'fillable_date' ? "auto" : element?.width} 
                    height={element.type === 'date' || element.type === 'fillable_date' ? "auto" : element?.height}  
                    axis={'both'} 
                    onResizeStop={(e, data) => handleResizeStop(e, data, element, updateElement)} 
                    onResizeStart={handleResizeStart}
                    minConstraints={elMinConstraints.width > 0 && elMinConstraints.height > 0 ? [elMinConstraints.width, elMinConstraints.height] : null}
                    maxConstraints={elMaxConstraints.width > 0 && elMaxConstraints.height > 0 ? [elMaxConstraints.width, elMaxConstraints.height] : null}
                    onResize={onResize} 
                    lockAspectRatio={isElementLockedAspectRatio}
                    onClick={()=> handleSelectElement(element)}
                    resizeHandles={element.type === 'date' || element.type === 'fillable_date' ? [] : undefined}
                >
                    {element.type === 'textfield' &&
                        <textarea
                            ref={textareaRef}
                            rows="1"
                            maxLength={200}
                            style={{
                                width: '100%',
                                height: 'auto',
                                border: 'none',
                                resize: 'none',
                                outline: 'none',
                                backgroundColor: "#dfdbf37e",
                                borderRadius: "2px",
                                padding: 0,
                                // color: "#1a1a1a",
                                fontSize: element?.fontSize,
                                fontFamily: 'Helvetica',
                                lineHeight: 0.95,
                                overflow: "hidden"
                            }}
                            autoFocus
                            value={element.value || ""}
                            defaultValue={element.value || ""}
                            onChange={(e) => handleOnChangeTextField(e, element)}
                            placeholder={ element.element_type ? 'Input label' : 'Input text'}
                        />
                    }

                    {element.type === 'esignature' && 
                        <>
                            {element?.value == undefined ? 
                                <div style={{textAlign:'center', position:'absolute', transform:'translate(50%, -50%)', top: '50%', padding: 15,}}>Signature</div>
                            :
                                <img key={element.value} style={{ width: '100%', height: '100%', objectFit: 'contain', padding: 0, margin: 0 }} src={`${element.value}?${Date.now()}`} />
                            }
                        </>
                    }
                    
                    {element.type === 'date' && 
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateField
                                ref={dateRef}
                                inputRef={anchorRef}
                                fullWidth
                                format={dateFormat}
                                onChange={handleOnChangeDateField}
                                defaultValue={selectedDate}
                                value={selectedDate}
                                onClick={() => setIsDatePickerOpen(true)}
                                sx={{
                                    '.MuiInputBase-input': { fontSize: element.fontSize, padding: 0, height: 'auto' },
                                }}
                                />
                                <Popper open={isDatePickerOpen} anchorEl={anchorRef.current} placement="bottom-start" sx={{zIndex: 99}}>
                                    <DateCalendar sx={{bgcolor: 'white'}} disablePast value={selectedDate} onChange={handleDateChange} />
                                </Popper>
                            </LocalizationProvider>
                    }
                    {element.type === 'qrcode' && 
                    <img src={element.value} style={{ width: '100%', height: '100%', objectFit: 'contain', padding: 0, margin: 0 }} onLoad={(e) => onLoadImg(e)} />}
                    {element.type === 'signatory' && 
                        <>
                            {element.element_type ?
                                <Box sx={{display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: 'center', width: "100%", height: "100%"}}>
                                    <Typography align='center' noWrap sx={{textAlign: 'center',whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', margin:'auto'}}>{element.value}</Typography>
                                </Box>
                            :
                                <Box  sx={{display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: 'center', width: "100%", height: "100%"}}>
                                    <Typography align='center' noWrap sx={{textAlign: 'center',whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', margin:'auto'}}>{!element.value ? "Signatory" : element.value}</Typography>
                                </Box>
                            }
                            
                        </>
                    }
                    {element.type === 'fillable_textfield' && 
                        // <Box sx={{display:'flex', p: 0, m: 0, whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        //     <Typography ref={fillableTextFieldRef} sx={{fontSize: element.fontSize, height: 'auto'}}>Textfield</Typography> 
                        // </Box>
                        <textarea
                            ref={fillableTextFieldRef}
                            rows="1"
                            style={{
                                width: '100%',
                                height: 'auto',
                                border: 'none',
                                resize: 'none',
                                outline: 'none',
                                // backgroundColor: "#dfdbf37e",
                                backgroundColor: element.assign_color,
                                borderRadius: "2px",
                                padding: 0,
                                // color: "#1a1a1a",
                                fontSize: element?.fontSize,
                                fontFamily: 'Helvetica',
                                lineHeight: 0.95,
                                overflow: "hidden"
                            }}
                            readOnly
                            value={'Text Field'}
                        />
                    }
                    {element.type === 'fillable_date' && 
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateField
                            ref={fillableDateRef}
                            fullWidth
                            format={dateFormat}
                            readOnly
                            sx={{
                                '.MuiInputBase-input': { fontSize: element.fontSize, padding: 0},
                            }}
                            />
                        </LocalizationProvider>
                    }
                </ResizableBox>

                {/* Add Signatory Dialog */}
                <AddSignatoryDialogComponent element={element} emailSignatory={emailSignatory} setEmailSignatory={setEmailSignatory} 
                openSignatoryDialog={openSignatoryDialog} handleCloseSignatory={handleCloseSignatory} label={label} />
            
                <AlertDialogComponent openAlertDialog={openDeleteSigneeAlertDialog} handleDelete={handleOnDeleteSignee} handleCloseAlertDialog={handleCloseAlertDialog} />
            
            </Box>
        </ClickAwayListener>
    );
}
