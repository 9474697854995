import { Skeleton, TableCell, TableRow } from "@mui/material";

const RequiredListSkeleton = () => {

    let items = [];

    for (let i = 0; i < 5; i++) {
        items.push(
            <TableRow key={i} sx={{'&:hover': {backgroundColor: "#fff"}}}>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "#EEEBF8"}} height={28}/></TableCell>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "#EEEBF8"}} height={28}/></TableCell>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "#EEEBF8"}} height={28}/></TableCell>
                <TableCell style={{border: "none"}}><Skeleton variant="text" sx={{backgroundColor: "#EEEBF8"}} height={28}/></TableCell>
            </TableRow>
        )
    }

    return <>{items}</>
}

export default RequiredListSkeleton