import routes from "../common/api-routes";
import { HttpPublicGet, CustomHttpPostMultipart } from "../../../../../core/services/common/HttpRequestBuilder";

const subscriptionKey = process.env.REACT_APP_SUPERSIGN_PRODUCT_SUB_KEY;

console.log("Subscription Key:", subscriptionKey); // Debug log

const getPublicSignature = (accessToken) => {
    let query_params = null;
    let result = HttpPublicGet(routes.GET_SIGNATURE_PUBLIC, query_params, accessToken, subscriptionKey);
    return result;
}

const uploadPublicSignature = (formData, accessToken) => {
    let result = CustomHttpPostMultipart(routes.UPLOAD_SIGNATURE_PUBLIC, formData, accessToken, subscriptionKey);
    return result;
}

const getSenderEmail = (parameters, accessToken) => {
    let result = HttpPublicGet(routes.GET_SENDER_EMAIL_PUBLIC, parameters, accessToken, subscriptionKey);
    return result;
}

const DocumentPublicUserAccountService = {
    getPublicSignature,
    uploadPublicSignature,
    getSenderEmail
}

export default DocumentPublicUserAccountService;
