import React, {useState, useEffect} from "react";
import CustomDialog from "../../../../core/components/ui/CustomDialog";
import {Box, CircularProgress } from "@mui/material";
import CustomTypography from "../../../../core/components/ui/CustomTypography";
import CustomDialogLoaderComponent from "../../../../core/components/common/custom-dialog-loader.component";
import useMembersManagement from "../../hooks/useMembersManagement";

const RemoveMemberConfirmationDialog = (props) => {
    const {open, setOpen, selectedMember, workspaceDetails} = props;

    const {isDeletingMember, deleteMember, isRetainingAsSignee, retainAsSignee} = useMembersManagement();

    const [memberRole, setMemberRole] = useState("");
    const [isRetainAsSigneeButtonClicked, setRetainAsSigneeButtonClicked] = useState(false);


    useEffect(() => {
        if (selectedMember) {
            const updatedRole = (selectedMember?.role === "WSAdmin") ? "admin" 
                : 
                    (selectedMember?.role === "WSMember") ? "member" 
                : 
                    (selectedMember?.role === "WSGuest") ? "signee" 
                :
                    selectedMember?.role
        
            setMemberRole(updatedRole);
        }
    }, [selectedMember]);


    const handleClose = () => {
        setOpen(false);
    }

    const handleRemoveMember = () => {
        const parameters = {
            workspace_id: selectedMember?.workspace_id,
            id: selectedMember?.id
        }

        deleteMember(parameters, selectedMember?.email, workspaceDetails?.name, setOpen);
    }

    const handleRetainAsSignee = () => {
        const payload = {     
            id: selectedMember?.id,
            workspace_id: selectedMember?.workspace_id,
            role: "WSGuest"
        }
        
        retainAsSignee(payload, selectedMember?.email, setOpen);    
    }

    const actions = [
        ...(!isRetainAsSigneeButtonClicked ? 
            [
                {
                    label: "Retain as Signee",
                    variant: "text",
                    onClick: () => {
                        setRetainAsSigneeButtonClicked(true)
                    },
                },
                {
                    label: "Remove as Member",
                    onClick: () => {
                        setRetainAsSigneeButtonClicked(false);
                        handleRemoveMember();
                    },
                },
            ] 
        : 
            [
                {
                    label: "Cancel",
                    variant: "text",
                    onClick: () => {
                        setOpen(false);
                        setRetainAsSigneeButtonClicked(false);
                    }
                },
                {
                    label: "Confirm",
                    onClick: () => {
                        handleRetainAsSignee();
                    }
                }
            ]
        )
    ]

    const globalSx = {
        actions: {padding: "18px 25px 30px 30px"}
    }

  
    return (
        <>
            <CustomDialog
                open={open}
                onClose={(isDeletingMember || isRetainingAsSignee) ? null : handleClose}
                title="Confirmation"
                content={
                    <Box>
                        {(isDeletingMember && !isRetainingAsSignee) ?
                            <CustomDialogLoaderComponent
                                icon={<CircularProgress size={40} sx={{color: "#604CC3"}} />}
                                message={"Removing as member..."}
                            />
                        :
                            (!isDeletingMember && isRetainingAsSignee) ?
                                <CustomDialogLoaderComponent
                                    icon={<CircularProgress size={40} sx={{color: "#604CC3"}} />}
                                    message={"Retaining as signee..."}
                                />
                        :   
                            (!isRetainAsSigneeButtonClicked && !isDeletingMember && !isRetainingAsSignee) ?
                                <>
                                    <CustomTypography
                                        variant="subtitle1"
                                        text={`Are you sure you want to remove <strong>${selectedMember?.email}</strong> as a ${memberRole} from the workspace?`}
                                        customStyles={{marginBottom: 0, fontWeight: 400, color: "#454349", fontSize: 18}}
                                    />
                                </>
                        :
                            (isRetainAsSigneeButtonClicked && !isDeletingMember && !isRetainingAsSignee) ?
                                <>
                                    <CustomTypography
                                        variant="subtitle1"
                                        text={`Are you sure you want to retain <strong>${selectedMember?.email}</strong> as a signee in the workspace?`}
                                        customStyles={{marginBottom: 0, fontWeight: 400, color: "#454349", fontSize: 18}}
                                    />

                                    <CustomTypography
                                        variant="subtitle1"
                                        text={`Please note that retaining the role of signee will revoke the user's rights as a member.`}
                                        customStyles={{marginBottom: 0, fontWeight: 400, color: "#ff2121", fontSize: 16, marginTop: "16px", fontStyle: "italic"}}
                                    />
                                </>
                        :
                            null
                        } 
                    </Box>
                }
                actions={(!isDeletingMember && !isRetainingAsSignee && actions) ? actions : []}
                maxWidth={"sm"}
                customStyles={{
                    actions: {visibility: (isDeletingMember || isRetainingAsSignee) ? "hidden" : "visible"}
                }}
                globalSx={globalSx}
            />
        </>
    )
}

export default RemoveMemberConfirmationDialog