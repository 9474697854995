import React from "react";
import {Card, CardContent, Typography, Skeleton} from "@mui/material";
import Grid from '@mui/material/Grid2';

const WorkspaceDocumentsComponent = (props) => {
    const {dashboardCount, isLoadingDashboardCount, breakpoints} = props;

    const cardData = [
        {
            title: "Draft",
            count: dashboardCount?.drafts,
            label: (dashboardCount?.drafts <= 1) ? "Document" : "Documents",
            bgColor: "#e6e2f5",
            textColor: "#9f92da",
            countColor: "#604CC3",
        },
        {
            title: "Ongoing",
            count: dashboardCount?.ongoing,
            label: (dashboardCount?.ongoing <= 1) ? "Document" : "Documents",
            bgColor: "#efe7e9",
            textColor: "#f9bc51",
            countColor: "#FFA500",
        },
        {
            title: "Completed",
            count: dashboardCount?.completed,
            label: (dashboardCount?.completed <= 1) ? "Document" : "Documents",
            bgColor: "#e0e5e9",
            borderColor: "#008000",
            textColor: "#4ea351",
            countColor: "#008000",
        },
        {
            title: "Required Action",
            count: dashboardCount?.required,
            label: (dashboardCount?.required <= 1) ? "Document" : "Documents",
            bgColor: "#e0e4f8",
            borderColor: "#0178F2",
            textColor: "#4f9ef4",
            countColor: "#0178F2",
        },
        {
            title: "Cancelled",
            count: dashboardCount?.cancelled,
            label: (dashboardCount?.cancelled <= 1) ? "Document" : "Documents",
            bgColor: "#c628281f",
            borderColor: "#c62828",
            textColor: "#C62828",
            countColor: "#C62828",
        },
        {
            title: "Decline",
            count: dashboardCount?.denied,
            label: (dashboardCount?.denied <= 1) ? "Document" : "Documents",
            bgColor: "#e8ddeb",
            borderColor: "#830221",
            textColor: "#b26980",
            countColor: "#830221",        
        },
        {
            title: "Expired",
            count: dashboardCount?.expired,
            label: (dashboardCount?.expired <= 1) ? "Document" : "Documents",
            bgColor: "#ff00001a",
            borderColor: "#FF0000",
            textColor: "#f94d51",
            countColor: "#FF0000",
        },
        {
            title: "Scheduled",
            count: dashboardCount?.scheduled,
            label: (dashboardCount?.scheduled <= 1) ? "Document" : "Documents",
            bgColor: "#bdb6221a",
            borderColor: "#bdb622",
            textColor: "#bdb622",
            countColor: "#bdb622",
        }, 
    ]

     
    return (
        <>
            <Grid container spacing={2}>
                {cardData.map((card, index) => (
                    <Grid key={index} size={{xs: 6, sm: 3, md: 3}}>
                        <Card variant="outlined" sx={{backgroundColor: card.bgColor, cursor: "default", border: "none", height: "130px"}}>
                            <CardContent sx={{pb: "16px !important"}}>
                                {isLoadingDashboardCount ? (
                                    <>
                                        <Skeleton variant="text" width="60%" sx={{backgroundColor: card.textColor}} />
                                        <Skeleton variant="text" width="40%" sx={{fontSize: "30px", mb: 1, backgroundColor: card.textColor}} />
                                        <Skeleton variant="text" width="50%" sx={{backgroundColor: card.textColor}} />
                                    </>
                                    
                                ) : (
                                    <>
                                        <Typography sx={{fontSize: (breakpoints == "md" || breakpoints == "lg") ? 16 : 16, color: card.textColor, textAlign: 'start', fontWeight: "medium"}}>
                                            {card.title}
                                        </Typography>
                                        <Typography sx={{fontSize: (breakpoints == "md") ? 24 : 26, color: card.countColor, textAlign: 'start', fontWeight: "bold"}}>
                                            {card.count}
                                        </Typography>
                                        <Typography sx={{fontSize: (breakpoints == "md") ? 16 : 16, color: card.textColor, textAlign: 'start', fontWeight: "medium"}}>
                                            {card.label}
                                        </Typography>
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default WorkspaceDocumentsComponent