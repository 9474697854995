import { Button, Dialog, DialogContent, DialogTitle, Divider, Typography, Stack, Item } from "@mui/material";
import welcomePopupImg from "../../../../assets/images/welcome_popup.png";
import {ThreeDots} from "react-loader-spinner";

import CreateEsignatureCommon from "../digital-signature/create-esignature-common";
import { useEffect, useState } from "react";
import UserService from "../../../../plugins/profile-plugin/services/user-service";


export default function WelcomeDialog ({openWelcomeDialog, handleCloseWelcomeDialog}){

    const [openEsignatureDialog, setOpenEsignatureDialog] = useState(false)
    const [isLoadingUpdateSignatureSetupFirstLogin, setIsLoadingUpdateSignatureSetupFirstLogin] = useState(false)

    const handleContinue = () => {
        setOpenEsignatureDialog(true)
    }

    const handleCloseEsignatureDialog = (signature) => {
        setOpenEsignatureDialog(false)
        if (signature) {
            handleClose()
        }
    }

    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
          // Prevent closing the dialog
          return;
        }

        setIsLoadingUpdateSignatureSetupFirstLogin(true)
        UserService.updateSignatureSetupFirstLogin(true).then((res) => {
            setIsLoadingUpdateSignatureSetupFirstLogin(false)
            handleCloseWelcomeDialog()
        }).catch((error) => {
            setIsLoadingUpdateSignatureSetupFirstLogin(false)
        })
    };

    return(
        <>
            <Dialog open={openWelcomeDialog} disableEscapeKeyDown onClose={handleClose} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5}}}>
                <DialogTitle>Getting Started</DialogTitle>
                <Divider sx={{boxShadow:'0px 4px 10px rgba(0, 0, 0, 0.5);'}}/>
                <DialogContent>
                    <Typography variant="h4" align="center">Welcome to SuperSign!</Typography>
                    <br />
                    <Typography variant="subtitle1" color="textSecondary" align="center">The simplest way to sign documents.</Typography>
                    <br />
                    <Stack direction="row" spacing={2} sx={{justifyContent: "center", alignItems: "center"}}>
                        <img src={welcomePopupImg} width={200} height={200} />
                    </Stack>
                    <Typography variant="subtitle1" color="textSecondary" align="center">Get started by adding your <b>Electronic Signature</b> to have it ready whenever you create your document.</Typography>
                    <br />
                    <Button variant="contained" fullWidth onClick={handleContinue}>Continue</Button>
                    <Button fullWidth sx={{mt: 1}} onClick={handleClose} startIconstartIcon={isLoadingUpdateSignatureSetupFirstLogin && <ThreeDots height="20" width="20" radius="9" ariaLabel="three-dots-loading" visible={true} />} >Skip</Button>
                </DialogContent>
            </Dialog>
            <CreateEsignatureCommon openEsignatureDialog={openEsignatureDialog} handleCloseEsignatureDialog={handleCloseEsignatureDialog} />
        </>
    )
}