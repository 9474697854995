import React from "react";
import Grid from "@mui/material/Grid2";
import {Skeleton, Box, Typography, Divider} from "@mui/material";

const WorkspaceDetailsSkeleton = () => {

    return (
        <>
            <Box sx={{padding: "18px 0px 40px"}}>
                <Grid container spacing={2}>
                    <Grid size={{xs: 9, sm: 9}} sx={{textAlign: "start"}}>
                        <Box sx={{display: "flex"}}>
                            <Skeleton variant="rectangular" sx={{backgroundColor: "#eeebf8", mr: "8px"}} height={150} width={200} />
                        
                            <Box sx={{ml: 1.2, mt: "6px", textAlign: "start"}}>
                                <Box sx={{display: "block"}}>
                                    <Skeleton variant="text" sx={{backgroundColor: "#eeebf8"}} height={30} width={380} />
                                    <Skeleton variant="text" sx={{backgroundColor: "#eeebf8"}} height={30} width={380} />

                                    <Skeleton variant="rectangular" sx={{backgroundColor: "#eeebf8", mt: 4}} height={45} width={"55%"} />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid size={{xs: 3, sm: 3}} sx={{display: "flex", justifyContent: "flex-end"}}>
                        <Skeleton variant="text" sx={{backgroundColor: "#eeebf8"}} height={30} width={380} />
                    </Grid>
                </Grid>

                <Grid container spacing={1} sx={{mt: 4}}>
                    <Grid size={{xs: 6, sm: 6}} sx={{textAlign: "start"}}>
                        <Typography variant="body1" sx={{color: "#58565f9e", fontSize: 14}}>
                            Email Address:
                        </Typography>

                        <Skeleton variant="text" sx={{backgroundColor: "#eeebf8"}} height={30} width={"50%"} />
                    </Grid>

                    <Grid size={{xs: 6, sm: 6}} sx={{textAlign: "start"}}>
                        <Typography variant="body1" sx={{color: "#58565f9e", fontSize: 14}}>
                            Description:
                        </Typography>

                        <Skeleton variant="text" sx={{backgroundColor: "#eeebf8"}} height={30} width={"50%"} />
                    </Grid>
                </Grid>

                <Grid container spacing={1} sx={{mt: 3}}>
                    <Grid size={{xs: 6, sm: 6}} sx={{textAlign: "start"}}>
                        <Typography variant="body1" sx={{color: "#58565f9e", fontSize: 14}}>
                            Workspace Role:
                        </Typography>

                        <Skeleton variant="text" sx={{backgroundColor: "#eeebf8"}} height={30} width={"50%"} />
                    </Grid>

                    <Grid size={{xs: 6, sm: 6}} sx={{textAlign: "start"}}>
                        <Typography variant="body1" sx={{color: "#58565f9e", fontSize: 14}}>
                            Date Created:
                        </Typography>

                        <Skeleton variant="text" sx={{backgroundColor: "#eeebf8"}} height={30} width={"50%"} />
                    </Grid>
                </Grid>

                <Divider style={{marginTop: "30px"}} />

                <Grid size={{xs: 12}} sx={{textAlign: "start", mt: 3}}>
                    <Typography variant="body1" sx={{color: "rgb(0 0 0 / 75%)"}}>
                        Total Documents:
                    </Typography>
                </Grid>

                <Grid size={{xs: 12}} sx={{textAlign: "start", mt: 1}}>
                    <Grid container spacing={2} sx={{mt: 1}}>
                        {[0, 1, 2, 3, 4, 5, 6, 7].map((card, index) => (
                            <Grid key={index} size={{xs: 6, sm: 3}}>
                                <Skeleton variant="rectangular" sx={{backgroundColor: "#eeebf8", mr: "8px", borderRadius: "6px"}} height={140} width={"90"} />
                            </Grid>
                        ))}     
                    </Grid>
                </Grid>

                <Divider sx={{mt: 6}} />
            </Box>
        </>
    )
}

export default WorkspaceDetailsSkeleton
