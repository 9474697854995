import React, { useState, useRef, useEffect } from "react";
import { Box, Button, Grid, InputBase, Typography } from "@mui/material";
import SignaturePad from "react-signature-canvas";
import { ThreeDots } from "react-loader-spinner";
import useWidth from "../../../../../core/utils/useWidth"
import useUpdateProfileStore from "../../../../../plugins/profile-plugin/zustand/useUpdateProfileStore";


const CreateEsignatureByDrawCommon = ({ props }) => {

  const { handleCloseEsignatureDialog, uploadPublicSignature, uploading_signature } = props;

  let accessToken = (new URLSearchParams(window.location.search)).get("token");

  const { breakpoints } = useWidth;

  const signaturePadRef = useRef(null);
  const profileESignature = useUpdateProfileStore((state) => state.profileESignature);

  const [isCanvasEmpty, setCanvasEmpty] = useState(true);

  useEffect(() => {
    console.log("ni sud ko dri", profileESignature);
  }, [profileESignature])

  const handleCanvasChange = () => {
    setCanvasEmpty(signaturePadRef.current.isEmpty());
  }

  const handleClearSignature = () => {
    signaturePadRef.current.clear();
    setCanvasEmpty(true);
  }

  const convertUrlToFile = (url, filename, mimeType) => {
    return (fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })

      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      })
    )
  }

  const handleUploadSignature = async () => {
    let signaturePad = signaturePadRef.current;

    if (signaturePad.isEmpty()) {
      // do nothing

    } else {
      // const signatureDataUrl = signaturePad.toDataURL();
      const signatureDataUrl = signaturePad.getTrimmedCanvas().toDataURL("image/png");

      convertUrlToFile(signatureDataUrl, 'file.png', 'image/png').then(function (file) {
        let formData = new FormData();

        formData.append("file", file);
        uploadPublicSignature(formData, accessToken, handleCloseEsignatureDialog)


      })
    }
  }


  return (
    <Box>
      {(!isCanvasEmpty) &&
        <Grid sx={{ textAlign: "end" }}>
          <Button sx={{ cursor: "pointer", textTransform: "none", borderRadius: 0, fontSize: 14 }}
            onClick={() => { handleClearSignature() }}
          >
            Clear
          </Button>
        </Grid>
      }

      <Box>
        <SignaturePad ref={signaturePadRef} clearOnResize={false} onEnd={handleCanvasChange}
          canvasProps={{ width: (breakpoints == 'xs' || breakpoints == 'sm') ? 260 : 480, height: 200 }}
        />
      </Box>

      <div style={{ borderBottom: "1px solid #d3d3d3", marginTop: "-45px" }}>
        <InputBase disabled sx={{ color: "#b9b9b9", mb: 1, ml: 1, width: "100%" }} />
      </div>

      <Typography sx={{ fontSize: "14px", color: "#b9b9b9", textAlign: "center", mt: "10px", letterSpacing: "0.6px" }}>
        Draw Signature
      </Typography>

      <Box sx={{ paddingTop: 3.2, textAlign: "end" }}>
        <Button onClick={() => handleCloseEsignatureDialog()} variant="outlined" sx={{ width: 120, borderRadius: 2, border: '1px solid #604CC3', color: '#604CC3' }} >
          Close
        </Button>
        &nbsp;
        <Button variant="contained" sx={{ width: 120, backgroundColor: '#604CC3', boxShadow: 'none' }}
          onClick={(e) => handleUploadSignature(e)}
          startIcon={uploading_signature && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
        >
          {uploading_signature ? 'Uploading' : 'Save'}
        </Button>
      </Box>
    </Box>
  )
}

export default CreateEsignatureByDrawCommon