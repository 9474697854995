import { useState, useEffect, useRef } from 'react';
import { Box, Button, ClickAwayListener } from "@mui/material"
import Grid from '@mui/material/Grid2';
import { elementTypes } from '../common/staticVariables';
import DocumentElementSigneeComponent from './document-element-signee.component';
import DocumentElementSigneeToolbarComponent from './document-element-signee-toolbar.component';
import DocumentPublicUserAccountService from '../services/document-public-user-account-service';
import signature_file from '../../../../assets/images/signature.png'
import { useSnackbar } from 'notistack';
import useDocumentPublicAccount from '../hooks/useDocumentPublicAccount';
import CreateEsignatureCommon from '../common/digital-signature/create-esignature-common';
import useFillableElementsStore from "../zustand/useFillableElementsStore";



const DocumentElementSigneeContainerComponent = ({ index, element, currentPage, setCurrentElement, currentUser, currentUserSignature, elRefs,
    isViewing, signatory, onChangeSignature, setFillableTextfield,setFillableDate, scrollElements }) => {

    const { enqueueSnackbar } = useSnackbar();

    const accessToken = (new URLSearchParams(window.location.search)).get('token');

    const { uploadPublicSignature, uploading_signature, uploadedSignature } = useDocumentPublicAccount();

    const [isElementClicked, setIsElementClicked] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    console.log("element bbbb", element);
    console.log("currentUser bbbb", currentUser);
    console.log("currentUserSignature bbbb", currentUserSignature);
    console.log("elRefs bbbb", elRefs);

    useEffect(() => {
        console.log("openSignatureDialog bbbvv",openSignatureDialog);
    }, openSignatureDialog)

    useEffect(() => {
        if (uploadedSignature) {
            console.log("uploadedSignature hhhhh", uploadedSignature.toString());
            const uniqueValue = Date.now(); // Use timestamp to create a unique query parameter
            let unique_img_signatory = `${uploadedSignature}?t=${uniqueValue}`
            setCurrentElement({...element, signature: unique_img_signatory, isSigned: true});
            onChangeSignature(unique_img_signatory);
        }
    }, [uploadedSignature])
 
    const handleResize = (width, height, origWidth, origHeight) => {
        console.log("handleResize ccc ", { width, height });
        setCurrentElement({ ...element, width, height, origWidth, origHeight });
    }


    const handleOnChangeSignature = (signature) => {
        setCurrentElement({ ...element, isSigned: true, signature });
    }


    const handleOnClickElement = () => {
        if (!isViewing) {
            let isDisabled = element.disabled || element.type !== elementTypes.signatory ? true : false;

            if (!isDisabled) {
                if (currentUser == element.value && currentUserSignature) {
                    if (element.isSigned) {
                        setIsElementClicked(true)
                    } else handleOnChangeSignature(currentUserSignature)
                } else {
                    handleEditElement()
                }
            }
        }
    };


    const handleEditElement = () => {
        setOpenSignatureDialog(openSignatureDialog ? false : true);
    }

    const handleCloseEsignatureDialog = (signature) => {
        setOpenSignatureDialog(false);
    }

    const handleClickAway = () => {
        setIsElementClicked(isEditing ? true : false)
        setIsDatePickerOpen(false)
    }

    useEffect(() => {
        if(openSignatureDialog){
            setIsElementClicked(false)
        }
    },[openSignatureDialog])


    return (
        <>
            <ClickAwayListener onClickAway={()=> handleClickAway()}>
                <Box ref={(ref) => currentUser == element.value ? elRefs.current[element.id] = ref : null} sx={{position: 'absolute', left: element?.x, top: element?.y}} onClick={() => handleOnClickElement()}>
                    <Grid container justifyContent="center">
                        <DocumentElementSigneeToolbarComponent
                            element={element} 
                            isElementClicked={isElementClicked} 
                            onEditElement={handleEditElement} 
                        />
                    </Grid>
                    <DocumentElementSigneeComponent
                        index={index}
                        element={element}
                        onResize={handleResize}
                        currentUser={currentUser}
                        isViewing={isViewing}
                        signatory={signatory}
                        setTextfieldValue={setFillableTextfield}
                        setDateFieldValue={setFillableDate}
                        isUploading={uploading_signature}
                        scrollElements={scrollElements}
                        setCurrentElement={setCurrentElement}
                        isDatePickerOpen={isDatePickerOpen}
                        setIsDatePickerOpen={setIsDatePickerOpen}
                    />
                </Box>
            </ClickAwayListener>

            {openSignatureDialog &&
                <CreateEsignatureCommon 
                    openEsignatureDialog={openSignatureDialog} 
                    handleCloseEsignatureDialog={handleCloseEsignatureDialog} 
                    uploadPublicSignature={uploadPublicSignature}
                    uploading_signature={uploading_signature}
                />
            }
        </>
    )
}

export default DocumentElementSigneeContainerComponent