import { useEffect, useState, useRef } from "react"
import { Typography, Box, Stack, TextareaAutosize, TextField, ClickAwayListener,Popper } from "@mui/material"
import { elementTypes } from "../common/staticVariables"
import { resizeImageWithAspectRatio } from "../../../../core/utils/AspectRatioUtil"
import DocumentSignatoryElementComponent from "./document-signatory-element.component"
import '../styles/signee.css';
import CustomTextField from "../../../../core/components/ui/CustomTextField3";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateCalendar, DateField, DatePicker, LocalizationProvider, PickersDay } from "@mui/x-date-pickers";

import EditCalendarRoundedIcon from '@mui/icons-material/EditCalendarRounded';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import dayjs from 'dayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { format, parse } from "date-fns";

const StyledButton = styled(IconButton)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    color: '#604CC3',
}));
const StyledDay = styled(PickersDay)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
}));


function CurrentDateElementComponent({ element, elDragRef, setDateFieldValue, setCurrentElement, scrollElements }) {

    const [date, setDate] = useState(element?.value);

    console.log("datexxx",date);

    // const handleChangeDate = (newValue) => {
    //     const formattedDate = newValue ? dayjs(newValue).format('MM/DD/YYYY') : null;
    //     setDate(formattedDate);
    //     setDateFieldValue(formattedDate)
    //     setCurrentElement({ ...element, el_value: formattedDate});
    // };

    // if (element?.type === 'fillable_date') {
    //     return (
    //         <LocalizationProvider dateAdapter={AdapterDayjs}>
    //             <DemoContainer components={['DatePicker']}>
    //                 <DatePicker
    //                     ref={(el) => (scrollElements.current[element.id] = el)}
    //                     value={date ? dayjs(date) : null}
    //                     onChange={handleChangeDate}
    //                     slots={{
    //                         openPickerIcon: EditCalendarRoundedIcon,
    //                         openPickerButton: StyledButton,
    //                         day: StyledDay,
    //                     }}
    //                     slotProps={{
    //                         openPickerIcon: { fontSize: 'small' },
    //                         openPickerButton: { color: '#ffd500' },
    //                         textField: {
    //                             variant: 'filled',
    //                             focused: true,
    //                             color: '#ffd500',
    //                             fontSize: element?.font_size,
    //                         },
    //                     }}
    //                     sx={{
    //                         fontSize: element?.font_size,
    //                         width: element?.width,
    //                         height: element?.height,
    //                         padding: '0px',
    //                         overflow: 'hidden',
    //                         textAlign: 'start',
    //                         justifyContent: 'center',
    //                         border: '1px solid #ffd500',
    //                         '& .MuiInputBase-root': {
    //                             '&.Mui-focused': {
    //                                 backgroundColor: 'transparent',
    //                                 fontSize: element?.font_size,
    //                             },
    //                         },
    //                         '& .MuiInputBase-input': {
    //                             padding: 0,
    //                             fontSize: element?.font_size,
    //                         },
    //                         '& .MuiFilledInput-root.Mui-error::before, & .MuiFilledInput-root.Mui-error::after': {
    //                             borderBottom: 'none',
    //                             fontSize: element?.font_size,
    //                         },
    //                     }}
    //                 />
    //             </DemoContainer>
    //         </LocalizationProvider>
    //     );
    // }

    return (
        <Typography
            sx={{
                textAlign: "start",
                fontSize: element?.font_size,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: element?.width,
                lineHeight: 0.95,
                letterSpacing: '0px'
            }}
        >
            {element?.value}
        </Typography>
    )
}

const FillableDateElement = ({element, setDateFieldValue, setCurrentElement, isDatePickerOpen, setIsDatePickerOpen, scrollElements}) => {
    const [dateFormat, setDateFormat] = useState(element.date_format || 'MM/dd/yyyy'); // Default format
    const [selectedDate, setSelectedDate] = useState(format(new Date(), dateFormat));
    
    const anchorRef = useRef(null); // Reference for the Popper anchor
    const dateRef = useRef(null);

    const handleOnChangeDateField = (value) => {
        console.log("value xxx", value);
        setSelectedDate(value); 
        const formatted = format(value, dateFormat); 
        setDateFieldValue(formatted)
        setCurrentElement({ ...element, el_value: formatted, fillable_value: formatted});
        setIsDatePickerOpen(false)
    }

    const handleDateChange = (newDate) => {
        if (newDate) {
            setSelectedDate(newDate); 
            const formatted = format(newDate, dateFormat); 
            console.log("newDate bbbb", formatted);
            setDateFieldValue(formatted)
            setCurrentElement({ ...element, el_value: formatted, fillable_value: formatted });
        } else {
            setSelectedDate(null);
            setDateFieldValue('')
            setCurrentElement({ ...element, el_value: '', fillable_value: '' });
        }
        setIsDatePickerOpen(false)
    };

    
    console.log("element type xxx ", element.type);
    console.log("element signeeeeee", element);

    return(
        <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateField
                    // ref={dateRef}
                    ref={(el) => (scrollElements.current[element.id] = el)}
                    inputRef={anchorRef}
                    fullWidth
                    format={dateFormat}
                    onChange={handleOnChangeDateField}
                    value={selectedDate}
                    onClick={() => setIsDatePickerOpen(true)}
                    sx={{
                        fontSize: element.font_size,
                        backgroundColor: "white",
                        border: '1px solid #ffd500',
                        '.MuiInputBase-input': { 
                            fontSize: element.font_size, 
                            padding: 0, 
                            width: element.width, 
                            height: element.height 
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none', 
                        },
                    }}
                    />
                    <Popper open={isDatePickerOpen} anchorEl={anchorRef.current} placement="bottom-start" sx={{zIndex: 99}}>
                        <DateCalendar sx={{backgroundColor: 'white'}} disablePast value={selectedDate} onChange={handleDateChange} />
                    </Popper>
            </LocalizationProvider>
        </Box>
    )
}

const TextFieldElement = ({ element, setTextfieldValue, setCurrentElement, scrollElements }) => {
    const [textValue, setTextValue] = useState();
    const [textFieldValue, setTextFieldValue] = useState();

    useEffect(() => {
        if (element.type == "textfield") setTextFieldValue(element.value);
    }, [JSON.stringify(element)]);

    const handleTextChange = (e) => {
        const newValue = e.target.value;
        setTextValue(newValue);
        setTextfieldValue(newValue);
        setCurrentElement({ ...element, el_value: newValue, fillable_value: newValue});
    };

    const defaultStyle = {
        fontSize: element.font_size,
        padding: '0px',
        color: "#000",
        width: element?.width,
        height: element?.height,
        resize: "none",
        border: 0,
        fontFamily: 'Helvetica',
        lineHeight: 0.95,
        backgroundColor: 'transparent',
    };

    return element.type === "fillable_textfield" ? (
        <textarea
            ref={(el) => (scrollElements.current[element.id] = el)}
            style={{
                width: element?.width,
                height: element?.height,
                fontSize: element?.font_size,
                border: '1px solid #ffd500',
                resize: 'none',
                outline: 'none',
                backgroundColor: "transparent",
                color: "#1a1a1a",
                padding: '0px',
            }}
            onChange={(e) => handleTextChange(e)}
            placeholder='Type here'
            maxLength={200}
        />
    ) : (
        <TextareaAutosize
            value={textFieldValue}
            disabled={true}
            style={defaultStyle}
        />
    );
};



const DocumentElementSigneeComponent = ({ index, element, currentUser, onResize, isViewing, signatory, setTextfieldValue, setDateFieldValue, isUploading, 
    scrollElements, setCurrentElement, isDatePickerOpen, setIsDatePickerOpen }) => {
    const [imgDimens, setImgDimens] = useState(null);
    const [elSignatoryStatus, setElSignatoryStatus] = useState(null);
    const [elDimens, setElDimens] = useState({ width: element.width, height: element.height });

    useEffect(() => {
        if (imgDimens) {
            const { width, height } = getResizableBoxOnLoadDimens();
            onResize(width, height, imgDimens.width, imgDimens.height);
        }
    }, [JSON.stringify(imgDimens)]);

    useEffect(() => {
        if (signatory.length > 0) {
            let status = signatory.find((item) => item.email_address == element.value)?.status.toLowerCase()
            setElSignatoryStatus(status)
        }
    }, [JSON.stringify(signatory)])

    const getResizableBoxOnLoadDimens = () => {
        let width, height

        if (element.isSigned) {
            let { width: newWidth, height: newHeight } = resizeImageWithAspectRatio(imgDimens?.width, imgDimens?.height, elDimens?.width, elDimens?.height)
            width = newWidth
            height = newHeight
        }
        else {
            width = element?.width
            height = element?.height
        }

        return { width, height }
    }

    const onLoadImg = (e) => {
        setImgDimens({ width: e.target.naturalWidth, height: e.target.naturalHeight })
    }

    return (
        <>
            {element?.type == elementTypes.signatory ?
                <Box className={currentUser == element?.value || elSignatoryStatus == "ongoing" || elSignatoryStatus == "draft" ? "signee-signatory-box-element" : ""} sx={{ width: elDimens.width, height: elDimens.height }}>
                    <DocumentSignatoryElementComponent index={index} element={element} isViewing={isViewing} onLoadImg={onLoadImg} isUploading={isUploading} scrollElements={scrollElements} />
                </Box>
            : element?.type == elementTypes.textField || element?.type == "fillable_textfield" ?
                <TextFieldElement element={element} setTextfieldValue={setTextfieldValue} scrollElements={scrollElements} setCurrentElement={setCurrentElement} />
            : element?.type == elementTypes.currentDate ?
                <CurrentDateElementComponent element={element} setDateFieldValue={setDateFieldValue} scrollElements={scrollElements} setCurrentElement={setCurrentElement} />
            : element?.type == "fillable_date" ? 
                <FillableDateElement element={element} setDateFieldValue={setDateFieldValue} setCurrentElement={setCurrentElement} scrollElements={scrollElements}  isDatePickerOpen={isDatePickerOpen} setIsDatePickerOpen={setIsDatePickerOpen} />
            : (element?.type == elementTypes.userSignature || element?.type == elementTypes.qrcode) ?
                <Box sx={{ width: elDimens.width, height: elDimens.height }}>
                    <img alt="" src={element?.value} style={{ width: element.width, height: element.height, objectFit: "contain", }} onLoad={(e) => onLoadImg(e)} />
                </Box>
            :
                null
            }
        </>
    )
}

export default DocumentElementSigneeComponent