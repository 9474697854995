import { Avatar, Box, Button, IconButton, List, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Paper, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid2';
import moment from "moment/moment";
import { ThreeDots } from "react-loader-spinner";
import WorkspacesIcon from "../../../assets/icons/menu.png";
import FlagIcon from '@mui/icons-material/Flag';
import useNotification from "../hooks/useNotification";
import NotificationsIcon from '@mui/icons-material/Notifications';
import InfoIcon from '@mui/icons-material/Info';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeletNotification from '../../../assets/icons/delete-notification.png'


export default function NotificationScreen({ anchorEl3, handleCloseNotification, notificationData, fetchingNotification, getNotification, combinedNotifications }) {
    const { readNotification, deleteNotification, notificationDeleted, deletingNotification } = useNotification();

    const [menuState, setMenuState] = useState({ anchorEl: null, notificationId: null });

    const handleOpenDeleteNotification = (event, notificationId) => {
        setMenuState({ anchorEl: event.currentTarget, notificationId });
    };

    const handleCloseDeleteNotification = () => {
        setMenuState({ anchorEl: null, notificationId: null });
    };


    const handleClick = (id, reference) => {
        let parameters = {};
        parameters = { "notificationId": id }

        readNotification(parameters, reference);
    }

    const handleRemoveNotification = () => {
        const { notificationId } = menuState;
        console.log("notification id here aaaaaa", notificationId);
        deleteNotification({ notificationId });
    };

    useEffect(() => {
        console.log("notificationDeleted here", notificationDeleted)
        if (notificationDeleted) {
            getNotification();
            handleCloseDeleteNotification();
        }
    }, [notificationDeleted]);


    return (
        <Menu
            anchorEl={anchorEl3}
            open={Boolean(anchorEl3)}
            onClose={handleCloseNotification}
            sx={{ mt: 2, "& .MuiMenu-list": { paddingTop: 0, paddingBottom: 0 }, outline: 'none' }}
        >
            {fetchingNotification ? (
                <Grid container spacing={2}>
                    {[...Array(5)].map((_, index) => (
                        <Grid size={{ xs: 12 }} key={index}>
                            <Grid container>
                                <Grid size={{ xs: 12, sm: 12, md: 5, lg: 6 }}>
                                    <List sx={{ width: '100%', maxWidth: 280, bgcolor: 'background.paper', mt: 1 }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar sx={{ minWidth: 120 }}>
                                                <Skeleton variant="circular" width={60} height={60} sx={{ backgroundColor: '#eeebf8' }} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={<Skeleton variant="text" width="80%" sx={{ backgroundColor: '#eeebf8' }} />}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography component="span" variant="body2" sx={{ color: 'text.primary', display: 'inline' }}>
                                                            <Skeleton variant="text" width="60%" sx={{ backgroundColor: '#eeebf8' }} />
                                                        </Typography>
                                                        <br />
                                                        <Skeleton variant="text" width="60%" sx={{ backgroundColor: '#eeebf8' }} />
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 12, md: 7, lg: 6 }} sx={{ textAlign: 'center', alignSelf: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Skeleton variant="rectangular" width={50} height={20} sx={{ backgroundColor: '#eeebf8' }} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            ) : notificationData?.length === 0 ? (
                <Box sx={{ m: 2 }}>
                    <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold', color: '#58565f', fontSize: 14 }}>
                        No Available Notification to Display
                    </Typography>
                </Box>
            ) : (
                <Paper sx={{ width: 420, maxWidth: '100%', boxShadow: 'none', outline: 'none' }}>
                    {combinedNotifications?.map((data, index) => {
                        const { notification, recipient, count } = data;
                        const { title, message, updated_at, sender } = notification;
                        const is_read = recipient?.is_read;

                        // Highlight specific text in the message
                        const sanitizedMessage = message.split(/(\b\w+ Workspace\b)/).map((part, idx) =>
                            part.match(/\b\w+ Workspace\b/) ? <strong key={idx}>{part}</strong> : part
                        );


                        return (
                            <Grid container sx={{ backgroundColor: is_read ? '#fff' : '#604cc31a', }} key={data.notification.id}>
                                <Grid size={{ xs: 10 }}>
                                    <List disablePadding sx={{ cursor: is_read ? 'auto' : 'pointer', width: '100%', minWidth: 280, backgroundColor: 'transparent', "&.MuiList-root": { paddingTop: 0, paddingBottom: 0 } }}
                                        onClick={() => !is_read && handleClick(data.notification.id, data.notification.reference)} >
                                        <ListItem alignItems="flex-start" >
                                            <ListItemAvatar sx={{ minWidth: 70, display: 'flex' }}>
                                                <Box position="relative">
                                                    <Avatar sx={{ width: 60, height: 60, backgroundColor: '#604cc336' }} >
                                                        {data?.notification.category === 'WS_INV' ?
                                                            <img alt="" src={WorkspacesIcon} style={{ height: 22 }} />
                                                            :
                                                            <NotificationsIcon sx={{ height: 22, width: 22 }} />
                                                        }
                                                    </Avatar>
                                                    <Box
                                                        sx={{
                                                            width: 20,
                                                            height: 20,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            borderRadius: '30px',
                                                            color: 'white',
                                                            backgroundImage: data?.notification.category === 'WS_INV' ? 'linear-gradient(45deg, #FFA500, #FFA500)' : 'linear-gradient(45deg, #604CC3, #604CC3)',
                                                            position: 'absolute',
                                                            bottom: 0,
                                                            right: 0,
                                                        }}
                                                    >
                                                        {data?.notification.category === 'WS_INV' ?
                                                            <FlagIcon sx={{ width: 14, height: 14, margin: 'auto', color: '#fff' }} />
                                                            :
                                                            <InfoIcon sx={{ width: 14, height: 14, margin: 'auto', color: '#fff' }} />
                                                        }
                                                    </Box>
                                                </Box>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography sx={{ marginBottom: 0.5, display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 14 }} >
                                                        <strong>{sender}</strong>&nbsp;{sanitizedMessage}
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Typography sx={{ color: '#1876F2', fontWeight: 600, fontSize: '14px' }}>
                                                            {(() => {
                                                                const now = moment();
                                                                const updatedAt = moment.utc(updated_at).local();
                                                                const minutesAgo = now.diff(updatedAt, 'minutes');
                                                                const hoursAgo = now.diff(updatedAt, 'hours');
                                                                const daysAgo = now.diff(updatedAt, 'days');
                                                                const weeksAgo = Math.floor(daysAgo / 7);

                                                                if (daysAgo === 0) {
                                                                    if (hoursAgo === 0) {
                                                                        return minutesAgo <= 1 ? '1m' : `${minutesAgo}m`;
                                                                    }
                                                                    return hoursAgo === 1 ? '1h' : `${hoursAgo}h`;
                                                                }

                                                                if (daysAgo <= 6) {
                                                                    return daysAgo === 1 ? '1d' : `${daysAgo}d`;
                                                                }

                                                                return weeksAgo === 1 ? '1w' : `${weeksAgo}w`;
                                                            })()}
                                                        </Typography>
                                                        &nbsp;&nbsp;
                                                        <Typography sx={{ color: 'light gray', fontWeight: 400, fontSize: '13px' }} >{title}</Typography>
                                                    </Box>
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid
                                    size={{ xs: 2 }}
                                    sx={{ textAlign: "center", alignSelf: "center", justifyContent: "center", justifyItems: "center" }}
                                >
                                    <Button
                                        sx={{
                                            color: "#0178F2",
                                            backgroundColor: "transparent",
                                            border: "none",
                                            alignSelf: "center",
                                            justifyContent: "center",
                                            margin: "0px",
                                            cursor: "pointer",
                                        }}
                                        onClick={(e) => handleOpenDeleteNotification(e, data.notification.id)}
                                    >
                                        <MoreHorizIcon sx={{ width: 20 }} />
                                    </Button>
                                    {menuState.notificationId === data.notification.id && menuState.anchorEl && (
                                        <Menu
                                            anchorEl={menuState.anchorEl}
                                            open={Boolean(menuState.anchorEl)}
                                            onClose={handleCloseDeleteNotification}
                                            sx={{
                                                mt: 1,
                                                boxShadow: "none !important",
                                            }}
                                        >
                                            <MenuItem
                                                onClick={handleRemoveNotification}
                                                sx={{ boxShadow: "none" }}
                                            >
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <IconButton
                                                        sx={{
                                                            backgroundColor: "#80808075",
                                                            "&:hover": {
                                                                backgroundColor: "#80808075",
                                                            },
                                                        }}
                                                    >
                                                        {deletingNotification ?
                                                            <ThreeDots height="20" width="20" radius="9" color="#604CC3" ariaLabel="three-dots-loading" visible={true} />
                                                            :
                                                            <img src={DeletNotification} style={{ height: 12 }} alt="Delete Notification" />
                                                        }
                                                    </IconButton>
                                                    &nbsp;&nbsp;
                                                    <Typography sx={{ fontSize: 15 }}>{deletingNotification ? 'Deleting' : 'Delete this notification'}</Typography>
                                                </Box>
                                            </MenuItem>
                                        </Menu>
                                    )}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Paper>
            )}
        </Menu>
    );
}
