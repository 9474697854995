
// const today = new Date();
// const options = { year: 'numeric', month: 'long', day: 'numeric' };
// const formattedDate = today.toLocaleDateString('en-US', options);

const today = new Date();

// Extract month, day, and year
const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
const day = String(today.getDate()).padStart(2, '0');
const year = today.getFullYear();

// Format as "MM/dd/yyyy"
const formattedDate = `${month}/${day}/${year}`;

const elements = [
    {
        type: 'esignature',
        value: '',
        width: 200,
        height: 100,
        x_axis: 0,
        y_axis: 0,
        pageNumber: 0
    },
    {
        type: 'textfield',
        value: '',
        fontSize: 18,
        width: 200,
        height: 18,
        x_axis: 0,
        y_axis: 0,
        pageNumber: 0
    },
    {
        type: 'qrcode',
        value: '',
        width: 100,
        height: 100,
        x_axis: 0,
        y_axis: 0,
        pageNumber: 0
    },
    {
        type: 'date',
        date_format: "mm/dd/yyyy",
        value: formattedDate,
        // width: 150,
        fontSize: 18,
        x_axis: 0,
        y_axis: 0,
        pageNumber: 0
    },
    {
        type: 'signatory',
        value: '',
        height: 100,
        width: 150,
        x_axis: 0,
        y_axis: 0,
        pageNumber: 0,
        assign_color: ""
    },
]

export default elements