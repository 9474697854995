import {HttpActualGet, HttpActualPost, HttpActualPut, HttpActualDelete, HttpActualGetBlob, HttpActualDelete1, HttpActualPostMultipart, HttpActualPublicGet, HttpActualPublicPost, HttpActualPublicPostMultipart, HttpActualPostParams, HttpActualPut1, HttpActualPublicPost2, HttpActualCustomPublicGet, HttpCustomActualDelete} from './HttpCoreOperations'
import getIdToken from '../../utils/getAuthToken';

const SUPERSIGN_PRODUCT_SUB_KEY =  process.env.REACT_APP_SUPERSIGN_PRODUCT_SUB_KEY


const HttpAuthGet = async (path, parameters = null, subscriptionKey = null) => {
    const accessToken = await getIdToken();

    // Handle the case where the access token is not available
    if (!accessToken) {
        console.warn("No access token available, proceeding without it.");
        return HttpActualGet(path, parameters, subscriptionKey); // Proceed without the token
    }

    // Use the provided subscription key or fallback to the default
    if (!subscriptionKey) {
        subscriptionKey = SUPERSIGN_PRODUCT_SUB_KEY; // Default subscription key
    }

    return HttpActualGet(path, parameters, subscriptionKey, accessToken);
};


const HttpAuthGetBlob = async (path, parameters = null,  subscriptionKey = null) => {

    // TODO: Get new access token using refresh token
    const accessToken = await getIdToken();
    if(!accessToken){
        return HttpActualGetBlob(path, parameters, subscriptionKey, accessToken);
    }

    if(!subscriptionKey)
        subscriptionKey = SUPERSIGN_PRODUCT_SUB_KEY

    return HttpActualGetBlob(path, parameters, subscriptionKey, accessToken);
}

const HttpAuthPost = async (path, payload = null,  subscriptionKey = null) => {
    
    // TODO: Get new access token using refresh token
    const accessToken = await getIdToken();

    if(!accessToken){
        return HttpActualPost(path, payload, subscriptionKey, accessToken);
    }

    if(!subscriptionKey)
        subscriptionKey = SUPERSIGN_PRODUCT_SUB_KEY

    return HttpActualPost(path, payload, subscriptionKey, accessToken);
}

const HttpAuthPostParams = async (path, parameters = null, subscriptionKey = null) => {
    // TODO: Get new access token using refresh token
    const accessToken = await getIdToken();

    if(!accessToken){
        return HttpActualPostParams(path, parameters, subscriptionKey, accessToken);
    }

    if(!subscriptionKey)
        subscriptionKey = SUPERSIGN_PRODUCT_SUB_KEY

    return HttpActualPostParams(path, parameters, subscriptionKey, accessToken);
};


const HttpAuthPut = async (path, parameters = null, payload = null,  subscriptionKey = null) => {

    // TODO: Get new access token using refresh token
    const accessToken = await getIdToken();

    if (!accessToken) {
        console.warn("No access token available, proceeding without it.");
        return HttpActualPut(path, parameters, payload, subscriptionKey); 
    }

    if(!subscriptionKey)
        subscriptionKey = SUPERSIGN_PRODUCT_SUB_KEY

    return HttpActualPut(path, parameters, payload, subscriptionKey, accessToken);
}

const HttpAuthPut1 = async (path, payload = null,  subscriptionKey = null) => {
    
    // TODO: Get new access token using refresh token
    const accessToken = await getIdToken();

    if(!accessToken){
        return HttpActualPut1(path, payload, subscriptionKey, accessToken);
    }

    if(!subscriptionKey)
        subscriptionKey = SUPERSIGN_PRODUCT_SUB_KEY

    return HttpActualPut1(path, payload, subscriptionKey, accessToken);
}


const HttpAuthDelete = async (path, parameters = null, subscriptionKey = null) => {

    // TODO: Get new access token using refresh token
    const accessToken = await getIdToken();

    if (!accessToken) {
        console.warn("No access token available, proceeding without it.");
        return HttpActualDelete(path, parameters, subscriptionKey); 
    }

    if(!subscriptionKey)
        subscriptionKey = SUPERSIGN_PRODUCT_SUB_KEY

    return HttpActualDelete(path, parameters, subscriptionKey, accessToken);
}

const HttpAuthDelete1 = async (path, payload = null,  subscriptionKey = null) => {
    
    // TODO: Get new access token using refresh token
    const accessToken = await getIdToken();

    if(!accessToken){
        return HttpActualDelete1(path, payload, subscriptionKey, accessToken);
    }

    if(!subscriptionKey)
        subscriptionKey = SUPERSIGN_PRODUCT_SUB_KEY

    return HttpActualDelete1(path, payload, subscriptionKey, accessToken);
}

const HttpAuthPostMultipart = async (path, payload = null,  subscriptionKey = null) => {

    // TODO: Get new access token using refresh token
    const accessToken = await getIdToken();

    if (!accessToken) {
        console.warn("No access token available, proceeding without it.");
        return HttpActualPostMultipart(path, payload, subscriptionKey); 
    }

    if(!subscriptionKey)
        subscriptionKey = SUPERSIGN_PRODUCT_SUB_KEY

    return HttpActualPostMultipart(path, payload, subscriptionKey, accessToken);
}


const HttpPublicGet = async (path, parameters, accessToken, subscriptionKey) => {
    try {
        const result = await HttpActualPublicGet(path, parameters, accessToken, subscriptionKey);
        return result;
    } catch (error) {
        console.error("HttpPublicGet error: ", error);
        throw error; 
    }
};

const HttpPublicPost = (path, parameters, payload, accessToken, subscriptionKey) => {

    var promise = new Promise((resolve, reject) => {
        try {   
            const result = HttpActualPublicPost(path, parameters, payload, accessToken, subscriptionKey);
            resolve(result);
        } catch (error) {
            console.log("HttpPublicPost error xxxx", error);
            reject(error);
        }
    })

    return promise

}


const HttpPublicPost2 = async (path, payload = null,  subscriptionKey = null) => {

    if(!subscriptionKey)
        subscriptionKey = SUPERSIGN_PRODUCT_SUB_KEY

    return HttpActualPublicPost2(path, payload, subscriptionKey);
}

const CustomHttpPostMultipart = (path, payload, accessToken, subscriptionKey) => {

    var promise = new Promise((resolve, reject) => {
        const result = HttpActualPublicPostMultipart(path, payload, accessToken, subscriptionKey);

        resolve(result);
    })
     
    return promise
}

const HttpCustomPublicGet = async (path, parameters) => {
    let subscriptionKey = SUPERSIGN_PRODUCT_SUB_KEY;

    try {
        const result = await HttpActualCustomPublicGet(path, parameters, subscriptionKey);

        return result;

    } catch (error) {
        console.error("HttpCustomPublicGet error: ", error);

        throw error; 
    }
}

const HttpCustomAuthDelete = async (path, payload) => {
    const accessToken = await getIdToken();

    let subscriptionKey = SUPERSIGN_PRODUCT_SUB_KEY;

    return HttpCustomActualDelete(path, payload, subscriptionKey, accessToken);
}


export {HttpAuthGet, HttpAuthPost, HttpPublicPost2, HttpAuthPut, HttpAuthDelete, HttpAuthPostMultipart, HttpAuthGetBlob, HttpPublicGet, 
    CustomHttpPostMultipart, HttpPublicPost, HttpAuthPostParams, HttpAuthPut1, HttpAuthDelete1,
    HttpCustomPublicGet, HttpCustomAuthDelete
}
