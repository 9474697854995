
import ovcodeLogo from "../../../assets/logo/new-ovcode-reverse.png"
import { Box, Typography, Button } from "@mui/material";
import logo from "../../../assets/icons/supersign-logo.png";
import Grid from '@mui/material/Grid2';



export default function LandingConfirmationComponent() {

    return (
        <Box style={{ height: '100vh', position: 'relative' }} class="content">
            <Box sx={{ margin: '0px 50px 0px 60px' }}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ alignSelf: 'start', textAlign: 'left', margin: 0, position: 'absolute', top: '50% ', transform: 'translate(0, -50%)' }} >
                    <img src={logo} style={{ height: 180, paddingLeft: 0, pb: 0, pr: 0 }} />
                    <Box sx={{ mt: 2 }} />
                    <Typography sx={{ textAlign: 'justify', maxWidth: 400, color: '#000', textJustify: 'inter-word', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', ml: 1, margin: '0px' }}>
                        Thank you for using SuperSign. You just signed, secured, and authenticated your digital document with just a few easy steps.
                    </Typography>
                    <br />
                    <Typography sx={{ textAlign: 'justify', maxWidth: 400, color: '#000', textJustify: 'inter-word', whiteSpace: 'pre-wrap', overflowWrap: 'break-word', ml: 1, margin: '0px' }}>
                        We look forward to your next document in our eSign platform!
                    </Typography>

                    <Button variant="contained" sx={{ mt: 3, textTransform: 'none', backgroundColor: '#0178f2', borderRadius: 10, boxShadow: 'none', mb: 2 }} onClick={() => window.location.href = "/"} >
                        Return Home
                    </Button>
                </Grid>
            </Box>

            <Box sx={{ alignSelf: 'center', justifyContent: 'center', textAlign: 'right', padding: '1.125em 0', position: 'fixed', width: '100%', bottom: 0, ml: '-50px' }}>
                <Button sx={{ textShadow: "3px 3px 3px rgb(0 0 0 / 43%)", textTransform: 'none', fontSize: 15, fontWeight: 500, color: 'white', fontWeight: 500 }} endIcon={<img src={ovcodeLogo} style={{ height: 28 }} />}>
                    Powered by
                </Button>
            </Box>

        </Box>
    )
}