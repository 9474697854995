import {AppBar, Toolbar, Box} from "@mui/material";
import {useNavigate} from "react-router-dom";
import CustomIconButton from "../ui/CustomIconButton";
import SuperSignLogo from "../../../assets/icons/supersign-logo.png";
import CustomButton from "../ui/CustomButton";

const AppbarComponent = ({breakpoints}) => {

    const navigate = useNavigate();

    const menuItems = [
        {label: "Who We Are", onClick: () => alert("Who We Are Under Development")},
        {label: "Contact Us", onClick: () => alert("Contact Us Under Development")},
    ]

    const handleClickLogin = (e) => {
        navigate("/login");
    }


    return (
        <AppBar component="nav" position="fixed" sx={{bgcolor: "#fff", boxShadow: "0px 2px 4px -1px #604cc32b, 0px 4px 5px 0px #604cc32b, 0px 1px 10px 0px #604cc32b"}}>
            <Toolbar variant="dense" sx={{width: (breakpoints == "xs" || breakpoints == "sm") ? "90%" : "85%", margin: (breakpoints === "xs" || breakpoints === "sm") ? "8px auto" : "12px auto",
                paddingLeft: (breakpoints == "xs" || breakpoints == "sm") ? "0px !important" : {}}
            }>
                <img alt="" src={SuperSignLogo} style={{height: "100%", width: (breakpoints === "xs" || breakpoints === "sm") ? "150px" : "190px", cursor: "pointer"}} onClick={(e) => window.location.href = "/"} />

                {/* <CustomIconButton  
                    content={<img alt="" src={SuperSignLogo} style={{height: 55}} />} 
                    color={"inherit"} style={`mr: '2'`} 
                    onClick={() => window.location.href = "/"}
                /> */}

                <Box sx={{flexGrow: 1}} />

                <Box display="flex" gap={2}>
                    {menuItems.map(({label, onClick}) => (
                        <CustomButton
                            key={label}
                            label={label}
                            onClick={onClick}
                            variant="text"
                            sx={{
                                textTransform: "none",
                                cursor: "pointer",
                                color: "#5b3cc4",
                                display: (breakpoints === "xs") ? "none" : {}
                            }}
                        />
                    ))}

                    <CustomButton 
                        variant="contained" 
                        label="Log In"
                        customStyles={{ml: 1, mr: 3, fontWeight: 500, backgroundColor: "#5b3cc4"}}
                        onClick={handleClickLogin}
                    />    
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default AppbarComponent