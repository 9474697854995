const calculateLines = (textRef, fontSize) => {
    if (textRef.current) {
      const textarea = textRef.current;
      const lineWidth = textarea.clientWidth; // Current width of the textarea
      const text = textarea.value;
      console.log("text aa111", text);

      if (!text) {
        return 1;
      }
      
      // Create a temporary span element to measure text width
      const tempSpan = document.createElement('span');
      tempSpan.style.visibility = 'hidden';
      tempSpan.style.whiteSpace = 'pre-wrap'; // Preserve white space and wrapping
      tempSpan.style.fontSize = `${fontSize}px`;
      tempSpan.style.fontFamily = 'Helvetica'; // Use monospace to calculate the width per character
      tempSpan.innerText = text;
      document.body.appendChild(tempSpan);

      const singleLineWidth = tempSpan.offsetWidth; // Get width of a single line of text
      document.body.removeChild(tempSpan); // Remove the temp span from the DOM

      // Calculate the number of wrapped lines based on the width
      const charactersPerLine = lineWidth / (singleLineWidth / text.length);
      const totalWrappedLines = Math.ceil(text.length / charactersPerLine) + 1;

      // Count the number of actual line breaks (e.g., pressing "Enter")
      const totalLineBreaks = (text.match(/\n/g) || []).length;
      console.log("totalWrappedLines aa", totalWrappedLines);
      console.log("totalLineBreaks aa", totalLineBreaks);

      // Total lines = wrapped lines + actual line breaks
      return totalWrappedLines + totalLineBreaks;
    }
    return 1; // Default to 1 line
};

export {calculateLines}