import * as React from 'react';
import { Box, Typography, Card, List, ListItem, ListItemText, Alert } from "@mui/material";
import { useEffect, useReducer, useState } from "react";

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function carbonCopyReducer(state, action) {

  switch (action.type) {

    case 'ADD_CARBON_COPY':
      return [...state, action.email];

    case 'REMOVE_CARBON_COPY':
      return [
          ...state.slice(0, action.index),
          ...state.slice(action.index + 1)
      ];

    case "UPDATE_CARBON_COPY":
      return state.map((prev_carbon_copy, i) => i === action.index 
          ? action.email
          : prev_carbon_copy
      )
  
    default:
      throw new Error();

  }

}

function emailValidation(email) {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return !(!email || regex.test(email) === false);
}

function CarbonCopyCardComponent({onClickRemoveIcon, onEmailChange}){

  const [ error, setError ] = useState(false);
  const [ email, setEmail ] = useState(null);
  
  function handleOnChange(value){
    setEmail(value);
  }

  function handleOnBlur(email){
    // validate if valid email format
    var isEmailValid = emailValidation(email);
    if(isEmailValid){
      setError(false);
      onEmailChange(email);
    } else {
      setError(true);
    }
  }

  return(
    <Card sx={{mt: 1, mb: 1}}>
      { error && <Alert severity="error">Email format is invalid.</Alert> }
      <Paper
        dense="true"
        component="form"
        sx={{ p: 1, display: 'flex', alignItems: 'center', width: '100%' }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Recepient Email"
          inputProps={{ 'aria-label': 'add recepient email' }}
          onChange={(e) => handleOnChange(e.target.value) }
          value={email}
          type="email"
          onBlur={(e) => handleOnBlur(email) }
          onKeyDown={(event) => {
            if (event.keyCode === 13){
              event.preventDefault();
            }
          }}
        />
        <IconButton color="error" aria-label="remove" onClick={onClickRemoveIcon}>
          <DeleteForeverIcon />
        </IconButton>
      </Paper>
    </Card>
  )

}

function CarbonCopyList({onCarbonCopyListChange}) {

  const [ carbon_copy_list, dispatch ] = useReducer(carbonCopyReducer, []);

  useEffect(()=>{
    onCarbonCopyListChange(carbon_copy_list);
  }, [carbon_copy_list])

  function handleOnClick(){
    dispatch({ type: 'ADD_CARBON_COPY', email: null});
  }

  function handleOnClickRemoveIcon(index){
    dispatch({ type: 'REMOVE_CARBON_COPY', index: index});
  }

  function handleOnEmailChange(index, result){
    dispatch({ type: 'UPDATE_CARBON_COPY', index: index, email: result});
  }

  return (
    <List>
      <Card sx={{mt: 1, mb: 1, cursor: 'pointer'}} onClick={handleOnClick}>
        <ListItem>
          <ListItemText primary="+ Add Carbon Copy" />
        </ListItem>
      </Card>
      {
        carbon_copy_list.map((email, index)=>{
          return(
            <CarbonCopyCardComponent
              key={index} 
              email={email}
              order_sequence={index + 1}
              onClickRemoveIcon={()=>handleOnClickRemoveIcon(index)}
              onEmailChange={(result)=>handleOnEmailChange(index, result)}
            />
          )
        })
      }
    </List>
  );

}

function AddCarbonCopySectionComponent({onCarbonCopyListChange}) {

  return (
    <>
      <Typography sx={{ mt: 4, mb: 2, fontWeight:'bold' }} component="div">
        Add Carbon Copy
      </Typography>
      <Box sx={{
        minWidth: 275, p: 1, 
        color: 'draggable_element.text.primary',
        background: '#eeebf8', 
        borderColor: '#eeebf8', 
        borderStyle: 'solid',
      }}>
        <Typography sx={{fontSize: '0.8rem'}}>
          <b>Add emails you wanted to be included after authenticating the document.</b>
        </Typography>
        <CarbonCopyList 
          onCarbonCopyListChange={onCarbonCopyListChange} />
      </Box>
    </>
  );

}

export default AddCarbonCopySectionComponent;