import {TextField} from "@mui/material";
import {styled} from "@mui/material/styles";

const StyledTextField = styled(TextField)(({variant}) => ({
    ...(variant === "outlined" && {
        '& .MuiOutlinedInput-root': {
            borderRadius: "2px", 

            '& fieldset': {
                borderColor: "#604CC3",
            },
            '&:hover fieldset': {
                borderColor: "#604CC3",
            },
            '&.Mui-focused fieldset': {
                borderColor: "#604CC3",
            },
        },
    }),

    ...(variant === "filled" && {
        '& .MuiFilledInput-root': {
            borderRadius: "2px",
            backgroundColor: "#604cc31c",

            '&:hover': {
                backgroundColor: "#604cc336"
            },

            '&.Mui-focused': {
                backgroundColor: "#604cc33d", 
            },

            '&:before': {
                borderBottom: "none", 
            },
            '&:after': {
                borderBottom: "none",
            },
        },
        '& .MuiFilledInput-underline:before': {
            borderBottom: "none",
        },
        '& .MuiFilledInput-underline:after': {
            borderBottom: "none",
        },
        '&.Mui-focused .MuiFilledInput-underline:after': {
            borderBottom: "none",
        },
    }),
}));

const CustomTextField = ({placeholder, variant, label, onChange, value, rows, multiline, hiddenLabel, fullWidth, customStyles, ...props }) => {
    return (
        <StyledTextField 
            placeholder={placeholder}
            variant={variant}  
            label={label}
            onChange={onChange}
            value={value}
            rows={rows} 
            multiline={multiline}
            fullWidth={fullWidth}
            hiddenLabel={hiddenLabel}
            sx={{
                ...customStyles 
            }} 
            slotProps={{
                input: {
                    disableUnderline: variant === "filled"
                },
            }}
            {...props}
        >
        </StyledTextField>
    );  
}

export default CustomTextField
