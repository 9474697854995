import {create} from "zustand";

const useInvitedMemberStore = create((set) => ({
    isMemberInvited: false,
    isInvitationCancelled: false,
    isInvitationResend: false,
    isGuestAddedAsMember: false,

    setMemberInvited: (status) => set({isMemberInvited: status}),
    setInvitationCancelled: (status) => set({isInvitationCancelled: status}),
    setInvitationResend: (status) => set({isInvitationResend: status}),
    setGuestAddedAsMember: (status) => set({isGuestAddedAsMember: status}),
}));

export default useInvitedMemberStore