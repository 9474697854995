import { useState } from "react";
import { useSnackbar } from "notistack";
import useProfileStore from "../zustand/useUpdateProfileStore";
import AuthenticationService from "../services/authentication-service";
import useDigitalSignature from "../../supersign-docs-plugin/document-editor-plugin/zustand/useDigitalSignature";
import useUpdateProfileStore from "../../profile-plugin/zustand/useUpdateProfileStore";


const useAuthentication = () => {

    const { enqueueSnackbar } = useSnackbar();
    const { setProfileUpdated } = useProfileStore();

    const [profile, setProfile] = useState(null);
    const [profileData, setProfileData] = useState(null);
    const [isProfilePhotoUploaded, setProfilePhotoUploaded] = useState(false);    
    const [isSignatureUploaded, setSignatureUploaded] = useState(false);
    const [uploadingProfilePhoto,setProfilePhotoUploading] = useState(false);
    const [uploadingSignature,setSignatureUploading] = useState(false);
    const [isFetchingProfileDetails, setFetchingProfileDetails] = useState(false);
    const { setSignatureUrl } = useDigitalSignature();
    const setProfileESignature = useUpdateProfileStore((state) => state.setProfileESignature);

    const {setUserProfileData} = useUpdateProfileStore();

    const getProfile = () => {
        AuthenticationService.getProfile().then((res)=> {
            console.log('getProfile res', res.data.data            )
            if(res){
                setProfile(res?.data?.data)
            }
        }).catch((err)=>{
            console.log('getProfile err', err)

            const errorMessage = err?.response?.data?.error || err.message;
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    const viewProfile = () => {
        setFetchingProfileDetails(true);
        AuthenticationService.viewProfile().then((res)=> {
            setFetchingProfileDetails(false);
            console.log('viewProfile res', res.data.data)
            if(res){
                setProfileData(res?.data?.data);
                setUserProfileData(res?.data?.data);
                
            }
        }).catch((err)=>{
            console.log('viewProfile err', err)
            setFetchingProfileDetails(false);
            const errorMessage = err?.response?.data?.error || err.message;
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    const uploadProfilePhoto = (formData) => {
        setProfilePhotoUploading(true);
        AuthenticationService.uploadProfilePhoto(formData).then((res) => {
            if (res.data.success) {
                console.log("uploadProfilePhoto data xxxx", res);
                setProfileUpdated(true);
                setProfilePhotoUploaded(true);
                setProfilePhotoUploading(false);
                enqueueSnackbar(`Successfully uploaded profile photo.`, {
                    variant: "success",
                    autoHideDuration: 2000
                })
            }

        }).catch((error) => {
            const errorMessage = error?.response?.data?.error || error.message;
            setProfilePhotoUploading(false);
            setProfilePhotoUploaded(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    const uploadSignature = (formData, handleCloseEsignatureDialog) => {
        setSignatureUploading(true);
        AuthenticationService.uploadSignature(formData).then((res) => {
            if (res.data.success) {
                console.log("uploadSignature data xxxx", res);
                let signature = res?.data?.data?.signature
                setSignatureUrl(signature)

                setSignatureUploading(false);
                setProfileUpdated(true);
                setSignatureUploaded(true);
                setProfileESignature(signature)

                handleCloseEsignatureDialog(signature)
                enqueueSnackbar(`Successfully saved signature.`, {
                    variant: "success",
                    autoHideDuration: 2000
                })
            }

        }).catch((error) => {
            const errorMessage = error?.response?.data?.error || error.message;
            setSignatureUploading(false);
            setSignatureUploaded(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }


    return {getProfile, profile, viewProfile, profileData, uploadProfilePhoto, isProfilePhotoUploaded, uploadingProfilePhoto, uploadSignature, isSignatureUploaded,
        isFetchingProfileDetails, uploadingSignature
    }
}

export default useAuthentication