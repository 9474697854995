import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Button, List, ListItem, ListItemAvatar, Avatar, ListItemText, Box, IconButton, Skeleton, TextField, Snackbar, Alert } from '@mui/material';
import Grid from '@mui/material/Grid2';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import useOrderManagement from '../hooks/useOrderManagement';
import tokenIcon from '../../../assets/icons/token.png';
import TokenManagementService from '../services/api/token-management';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add'
import { ThreeDots } from "react-loader-spinner";
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import CustomTextField from '../../../core/components/ui/CustomTextField3';
import SendIcon from '@mui/icons-material/Send';



export default function CheckoutComponent({isEmail, isWorkspace, wallet_id, profileWalletId}) {

    const orderId = new URLSearchParams(window.location.search).get("orderId");

    const { viewOrder, isFetchingOrders, orderDataArray, placeOrder, isPlacingOrder, deleteItemv2, isDeletingItem, updateItem, 
        isUpdatingItem, orderData, errorMessage, cancelOrder, orderCancel, isCancelOrder, getListOrder, itemUpdated  } = useOrderManagement();

    const navigate = useNavigate();

    const [cartItems, setCartItems] = useState([]);
    const [deletingItemId, setDeletingItemId] = useState(null);
    const [isCartLoaded, setIsCartLoaded] = useState(false);
    const [hasNavigated, setHasNavigated] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [isCartEmpty, setIsCartEmpty] = useState(false);

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    useEffect(() => {
        if (orderId) {
            let parameters = { "order_id": orderId }
            viewOrder(parameters);
        }
        // localStorage.removeItem('addedCustomAmount');
        localStorage.removeItem('addedToCart');
    }, [orderId])

    console.log("orderData xxx", orderData)

    useEffect(() => {
        if(orderData?.status === "Cancelled"){
            window.location.href = '/token'
        }
    }, [orderData])

    useEffect(() => {
        const fetchPackageDetails = async () => {
            try {
                const itemsWithPackage = orderDataArray.filter(item => item.is_package && item.package_code);
                const itemsWithoutPackage = orderDataArray.filter(item => !item.is_package);

                // Consolidate `is_package === false` items by merging quantities and totals
                const consolidatedCustomItems = Object.values(
                    itemsWithoutPackage.reduce((acc, item) => {
                        const key = 'custom'; // Unique key for non-packages
                        if (!acc[key]) {
                            acc[key] = {
                                ...item,
                                quantity: Number(item.quantity) || 0,
                                total: Number(item.total) || 0,
                                packageName: "Custom",
                                tokenAmount: 0 // Assuming no token amount for custom
                            };
                        } else {
                            acc[key].quantity += Number(item.quantity) || 0;
                            acc[key].total += Number(item.total) || 0;
                        }
                        return acc;
                    }, {})
                );

                // Consolidate `is_package === true` items with the same package_code
                const consolidatedPackageItems = Object.values(
                    itemsWithPackage.reduce((acc, item) => {
                        const key = item.package_code; // Group by package_code
                        if (!acc[key]) {
                            acc[key] = {
                                ...item,
                                quantity: Number(item.quantity) || 0,
                                total: Number(item.total) || 0,
                                tokenAmount: Number(item.token_amount) || 0
                            };
                        } else {
                            acc[key].quantity += Number(item.quantity) || 0;
                            acc[key].total += Number(item.total) || 0;
                            acc[key].tokenAmount += Number(item.token_amount) || 0;
                        }
                        return acc;
                    }, {})
                );


                console.log("consolidatedPackageItems here", consolidatedPackageItems)
                // Fetch details for the consolidated package items
                const packageCodes = Object.values(consolidatedPackageItems).map(item => item.package_code);
                // console.log("packageCodes here", packageCodes)
                const packageDetailsArray = await Promise.all(
                    packageCodes.map(async code => {
                        try {
                            const parameters = { 'code': code };
                            // console.log("parameters here", parameters)
                            const res = await TokenManagementService.viewTokenPackage(parameters);
                            // console.log("res here aaaaa", res)
                            return res.data.success
                                ? { code, name: res.data.data.name, token_amount: res.data.data.token_amount }
                                : { code, name: 'Unknown Package', token_amount: 0 };
                        } catch (error) {
                            console.error(`Error fetching details for ${code}:`, error);
                            return { code, name: 'Unknown Package', token_amount: 0 };
                        }
                    })
                );

                // Map package details to the consolidated package items
                const packageDetailsMap = packageDetailsArray.reduce((acc, detail) => {
                    acc[detail.code] = detail;
                    return acc;
                }, {});

                const mappedPackageItems = Object.values(consolidatedPackageItems).map(item => {
                    const pkgDetail = packageDetailsMap[item.package_code] || { name: 'Unknown Package', token_amount: 0 };

                    return {
                        ...item,
                        packageName: pkgDetail.name,
                        tokenAmount: pkgDetail.token_amount,
                    };
                });

                // Combine all items
                const updatedCartItems = [...consolidatedCustomItems, ...mappedPackageItems];
                setCartItems(updatedCartItems);

            } catch (error) {
                console.error("Error processing items:", error);
            }
        };

        if (orderDataArray) {
            fetchPackageDetails();
        } else {
            console.log("No cartItems found in the query string.");
        }
    }, [orderDataArray]);


    const handlePlaceOrder = () => {
        const payload = { 'order_id': orderId };
        placeOrder(payload, setOpenSnackbar);
    }

    const handleRemoveItem = (itemId) => {
        setDeletingItemId(itemId);
        const payload = {
            item_id: itemId,
            order_id: orderId,
        };

        // console.log("payload here aaa", payload)

        deleteItemv2(payload, setIsCartEmpty);

        const updatedCartItems = cartItems.filter(item => item.id !== itemId);
        // console.log("updatedCartItemsxxx", updatedCartItems)
        setCartItems(updatedCartItems); // Update cartItems state
    }

    const [isEditing, setIsEditing] = useState(false);
    const [editedQuantity, setEditedQuantity] = useState(0);

    const toggleEditMode = (quantity) => {
        if (!isEditing) {
            setEditedQuantity(quantity);
        }
        setIsEditing(!isEditing);
    };


    const handleSaveItem = (packageCode, id, editedQuantity) => {
        const updatedCart = cartItems.map(item =>
            item.packageCode === packageCode && item.id === id
                ? { ...item, quantity: editedQuantity }
                : item
        );
        setCartItems(updatedCart);
    
        // Find the specific updated item
        const updatedItem = updatedCart.find(item => item.packageCode === packageCode && item.id === id);
    
        if (updatedItem && updatedItem.quantity > 0) {
            const payload = {
                order_id: orderId,
                item_id: updatedItem.id,
                token_amount: updatedItem.quantity
            };
    
            // console.log("update payload here", payload);
            updateItem(payload);
        }
    };
    
    useEffect(() => {
        if (cartItems) {
            setIsCartLoaded(true);
        } else {
            setIsCartLoaded(false);
        }
    }, [cartItems])

    useEffect(() => {
        // console.log("State transition:", { isCartLoaded, hasNavigated, cartItems });

        if (!isCartLoaded) {
            setHasNavigated(false);
            return;
        }

        if (isCartLoaded && cartItems.length !== 0) {
            setHasNavigated(false);
        } else {
            setHasNavigated(false);
        }

    }, [isCartLoaded]);

    useEffect(() => {
        console.log("Triggered by dependency change: isCartLoaded, cartItems");
    }, [isCartLoaded, cartItems]);


    useEffect(() => {
        if (hasNavigated === true) {
            navigate(`/token?orderId=${orderId}`);
        }
    }, [hasNavigated])


    // console.log("xxxx isCartLoaded here", isCartLoaded)
    // console.log("xxxx hasNavigated here", hasNavigated)

    const handleCancelOrder = (orderId) => {
        let parameters = { "order_id": orderId }
        cancelOrder(parameters);
    }
    
    useEffect(() => {
        if (isCartLoaded) {
            // console.log("isCartEmpty here", isCartEmpty)
            if(isCartEmpty){
                console.log("Cart is empty, cancelling order.");
                handleCancelOrder(orderId);  // Trigger cancel order if cartItems is empty
            }
        }
    }, [isCartEmpty, isCartLoaded, orderId]);
    

    useEffect(() => {
        let parameters = {};

        if(orderCancel){
            window.location.href="/token";
            if (isEmail) {
                if (profileWalletId) {
                    parameters = { "wallet_id": profileWalletId }
                    getListOrder(parameters);
                }
            } else if (isWorkspace) {
                parameters = { "wallet_id": wallet_id ? wallet_id : null }
                getListOrder(parameters);
            }
        }
    },[orderCancel, profileWalletId, isEmail, isWorkspace, wallet_id ])


    return (
        <Box sx={{ maxWidth: 1200, margin: '0 auto', padding: 2 }}>
            <Card sx={{ mb: 3, border: '1px solid #c7c0ea' }}>
                <CardContent>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Items</Typography>
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }} sx={{ textAlign: 'center' }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Quantity</Typography>
                        </Grid>
                        <React.Fragment>
                            {isFetchingOrders ? (
                                <Grid container spacing={2}>
                                    {[...Array(5)].map((_, index) => (
                                        <Grid size={{ xs: 12 }} key={index}>
                                            <Grid container>
                                                <Grid size={{ xs: 12, md: 6 }}>
                                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', mt: 1 }}>
                                                        <ListItem alignItems="flex-start">
                                                            <ListItemAvatar sx={{ minWidth: 120 }}>
                                                                <Skeleton variant="circular" width={90} height={90} sx={{ backgroundColor: '#eeebf8' }} />
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                primary={<Skeleton variant="text" width="80%" sx={{ backgroundColor: '#eeebf8' }} />}
                                                                secondary={
                                                                    <React.Fragment>
                                                                        <Typography component="span" variant="body2" sx={{ color: 'text.primary', display: 'inline' }}>
                                                                            <Skeleton variant="text" width="60%" sx={{ backgroundColor: '#eeebf8' }} />
                                                                        </Typography>
                                                                        <br /><br />
                                                                        <Skeleton variant="text" width="40%" sx={{ backgroundColor: '#eeebf8' }} />
                                                                        <br />
                                                                        <Skeleton variant="text" width="30%" sx={{ backgroundColor: '#eeebf8' }} />
                                                                        <br />
                                                                        <Skeleton variant="text" width="50%" sx={{ backgroundColor: '#eeebf8' }} />
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </ListItem>
                                                    </List>
                                                </Grid>
                                                <Grid size={{ xs: 12, md: 6 }} sx={{ textAlign: 'center', alignSelf: 'center' }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Grid container spacing={2}>
                                                            <Grid item size={{ xs: 12, md: 4 }} sx={{ alignSelf: 'center' }}>
                                                                <Skeleton variant="rectangular" width={50} height={20} sx={{ backgroundColor: '#eeebf8' }} />
                                                            </Grid>
                                                            <Grid size={{ xs: 12, md: 4 }} sx={{ alignSelf: 'center' }}>
                                                                <Skeleton variant="rectangular" width={50} height={20} sx={{ backgroundColor: '#eeebf8' }} />
                                                            </Grid>
                                                            <Grid size={{ xs: 12, md: 4 }} sx={{ alignSelf: 'center' }}>
                                                                <Skeleton variant="rectangular" width={50} height={20} sx={{ backgroundColor: '#eeebf8' }} />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                cartItems.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <Grid size={{ xs: 12, md: 6 }}>
                                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', mt: 1 }}>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemAvatar sx={{ minWidth: 120 }}>
                                                        <Avatar
                                                            alt="Package Icon"
                                                            src={tokenIcon}
                                                            sx={{ width: 90, height: 90 }}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={item.packageName}
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    sx={{ color: 'text.primary', display: 'inline' }}
                                                                >
                                                                    {item.is_package ? item.package_code : item.packageName}
                                                                </Typography>
                                                                <br /><br />
                                                                {item.is_package ? item.tokenAmount : item.quantity} Tokens
                                                                <br />
                                                                Unit Price : ${item.price}
                                                                <br />
                                                                Sub Total : ${(item.total).toFixed(2)}
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                            </List>
                                        </Grid>

                                        <Grid size={{ xs: 12, md: 6 }} sx={{ textAlign: 'center', alignSelf: 'center' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Grid container spacing={2}>
                                                    <Grid item size={{ xs: 12, md: 4 }} sx={{ alignSelf: 'center' }}>
                                                        {isEditing || item.is_package ? null : (
                                                            <IconButton
                                                                sx={{ color: '#604CC3' }}
                                                                onClick={() => toggleEditMode(item.quantity)}
                                                            >
                                                                <RemoveIcon />
                                                            </IconButton>
                                                        )}
                                                    </Grid>
                                                    <Grid size={{ xs: 12, md: 4 }} sx={{ alignSelf: 'center' }}>
                                                        {isEditing && !item.is_package ? (
                                                            <CustomTextField
                                                                variant={"outlined"}
                                                                value={editedQuantity}
                                                                fullWidth={true}
                                                                multiline={false}
                                                                hiddenLabel
                                                                type="number"
                                                                onChange={(e) => setEditedQuantity(Number(e.target.value))}
                                                                sx={{
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset': {
                                                                            borderColor: '#aca3e0',
                                                                            borderRadius: 0
                                                                        },
                                                                        '&:hover fieldset': {
                                                                            borderColor: '#aca3e0',
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                            borderColor: '#aca3e0',
                                                                        },
                                                                    },
                                                                    backgroundColor: '#f6f8fb',
                                                                    cursor: 'not-allowed',
                                                                    '& .MuiInputBase-input': {
                                                                        color: '#666'
                                                                    }
                                                                }}
                                                            />
                                                        ) : (
                                                            <Typography sx={{ mx: 2, fontWeight: 'bold' }}>
                                                                {item.quantity}
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                    <Grid size={{ xs: 12, md: 4 }} sx={{ alignSelf: 'center' }}>
                                                        {isEditing && !item.is_package ? (

                                                            <Button
                                                                variant="contained"
                                                                sx={{ color: '#fff', backgroundColor: '#604CC3' }}
                                                                onClick={() => {
                                                                    handleSaveItem(item.packageCode, item.id, editedQuantity);
                                                                    setIsEditing(false);
                                                                }}
                                                            >
                                                                Save
                                                            </Button>
                                                        ) : item.is_package ? (
                                                            <Button
                                                                sx={{ color: 'red', fontSize: 12 }}
                                                                onClick={() => handleRemoveItem(item.id)}
                                                                startIcon={
                                                                    deletingItemId === item.id && (
                                                                        <ThreeDots
                                                                            height="20"
                                                                            width="20"
                                                                            radius="9"
                                                                            color="red"
                                                                            ariaLabel="three-dots-loading"
                                                                            visible={true}
                                                                        />
                                                                    )
                                                                }
                                                                disabled={deletingItemId === item.id}
                                                            >
                                                                {deletingItemId === item.id ? "Removing" : <DeleteIcon sx={{ width: 20 }} />}
                                                            </Button>
                                                        ) : (
                                                            <IconButton
                                                                sx={{ color: '#604CC3' }}
                                                                onClick={() => toggleEditMode(item.quantity)}
                                                            >
                                                                <AddIcon />
                                                            </IconButton>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </React.Fragment>
                                ))
                            )}
                        </React.Fragment>
                    </Grid>
                </CardContent>
            </Card>

            <Grid container spacing={2} sx={{ mt: 1, alignSelf: 'center' }}>
                <Grid size={12} sx={{ justifyContent: 'flex-end', textAlign: 'end', mt: 2 }}>
                    <Button
                        variant="outlined"
                        sx={{ color: '#604CC3', border: '1px solid #604CC3' }}
                        onClick={() => [navigate(`/token?orderId=${orderId}`), setHasNavigated(false), setIsCartLoaded(false)]}
                    // disabled={isCreatingOrder}
                    >
                        EDIT
                    </Button>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <Button
                        variant="contained"
                        sx={{ color: '#fff', backgroundColor: '#604CC3' }}
                        startIcon={isPlacingOrder ? <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} /> : <ShoppingCartIcon />}
                        onClick={handlePlaceOrder}
                    >
                        {isPlacingOrder ? 'PLACING ORDER' : 'PLACE ORDER'}
                    </Button>
                </Grid>
            </Grid>
            <Snackbar open={openSnackbar} onClose={handleSnackbarClose}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}
