import {useState} from "react";
import EnterpriseProjectManagementService from "../services/api/enterprise-project-management";
import {useSnackbar} from "notistack";
import useEnterpriseProjectStore from "../zustand/useEnterpriseProjectStore";

const useEnterpriseProjectManagement = () => {

    const {enqueueSnackbar} = useSnackbar();

    const {setOVCodeKeysUpdated, setOVCodeKeysDeleted, setOVCodeKeysAdded} = useEnterpriseProjectStore();

    const [isAddingProject, setAddingProject] = useState(false);
    const [projectDetails, setProjectDetails] = useState(null);
    const [isFetchingProjectKeys, setFetchingProjectKeys] = useState(true);
    const [projectAPIKeys, setProjectAPIKeys] = useState(null);
    const [isChangingKeys, setChangingKeys] = useState(false);
    const [isDeletingProject, setDeletingProject] = useState(false);
    const [isFetchingProjectDetails, setFetchingProjectDetails] = useState(false);
    const [isNoAPIKeyConfigured, setNoAPIKeyConfigured] = useState(false);
    const [isFetchingProjectCredits, setFetchingProjectCredits] = useState(false);
    const [projectCredits, setProjectCredits] = useState(0);


    const getProjectAPIKey = (parameters) => {
        setFetchingProjectKeys(true);
        
        EnterpriseProjectManagementService.getProjectAPIKey(parameters).then((res) => {
            if (res.data.success) {
                console.log("getProjectAPIKey data xxxx", res);

                setProjectAPIKeys(res?.data?.data);
                setFetchingProjectKeys(false);
            }

        }).catch((error) => {
            console.log("getProjectAPIKey error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;

            setProjectAPIKeys(null);
            setFetchingProjectKeys(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3000
            })
        })
    }

    const addProject = (payload, setOpen) => {
        setAddingProject(true);

        EnterpriseProjectManagementService.addProject(payload).then((res) => {
            if (res.data.success) {
                console.log("addProject data xxxx", res);

                setAddingProject(false);
                setOpen(false);
                setOVCodeKeysAdded(true);

                enqueueSnackbar(`Successfully added ${res.data.data?.project_name} as a workspace project`, {
                    variant: "success",
                    autoHideDuration: 3000
                })
            }

        }).catch((error) => {
            console.log("addProject error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            setAddingProject(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3000
            })

            setOVCodeKeysAdded(false);
        })
    }

    const getProjectDetails = (parameters) => {
        setNoAPIKeyConfigured(false);
        setFetchingProjectDetails(true);
        setOVCodeKeysUpdated(false);
        setOVCodeKeysDeleted(false);
        setOVCodeKeysAdded(false);

        EnterpriseProjectManagementService.getProjectDetails(parameters)
            .then(async (res) => {
                if (res?.data.success) {
                    console.log("getProjectDetails data xxxx", res);

                    const project_details = res?.data?.data;

                    try {
                        const apiKeyResponse = await EnterpriseProjectManagementService.getProjectCredits({
                            workspace_id: project_details?.workspace_id
                        });
                       
                        if (apiKeyResponse?.data.success) {
                            project_details.credits = apiKeyResponse?.data?.data.balance;
                        }  

                        setProjectDetails(project_details);
                        setFetchingProjectDetails(false);

                    } catch (error) {
                        console.log("getProjectCredits error xxxx", error);

                        setFetchingProjectDetails(false);
                    }
                }

        }).catch((error) => {
            console.log("getProjectDetails error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;

            if (errorMessage === "No OVCODE API key configured.") {
                setNoAPIKeyConfigured(true);
            } else {
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    autoHideDuration: 3000
                })
            }

            setFetchingProjectDetails(false);

            
        })
    }

    const changeProjectKeys = (payload, setOpen) => {
        setChangingKeys(true);

        EnterpriseProjectManagementService.changeProjectKeys(payload).then((res) => {
            if (res?.data.success) {
                console.log("changeProjectKeys data xxxx", res);

                setChangingKeys(false);
                setOpen(false);
                setOVCodeKeysUpdated(true);

                enqueueSnackbar(`Successfully changed project keys`, {
                    variant: "success",
                    autoHideDuration: 3000
                })
            }

        }).catch((error) => {
            console.log("changeProjectKeys error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            setChangingKeys(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3000
            })

            setOVCodeKeysUpdated(false);
        })
    }

    const deleteWorkspaceProject = (parameters, setOpen) => {
        setDeletingProject(true);

        EnterpriseProjectManagementService.deleteWorkspaceProject(parameters).then((res) => {
            if (res?.data.success) {
                console.log("deleteWorkspaceProject data xxxx", res);

                setDeletingProject(false);
                enqueueSnackbar(`Successfully removed workspace project`, {
                    variant: "success",
                    autoHideDuration: 3000
                })

                setOpen(false);
                setOVCodeKeysDeleted(true);
                setProjectDetails(null);
            }

        }).catch((error) => {
            console.log("deleteWorkspaceProject error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;

            setDeletingProject(false);
            setOVCodeKeysDeleted(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 3000
            })
        })
    }

    const getProjectCredits = (parameters) => {
        setFetchingProjectCredits(true);
        
        EnterpriseProjectManagementService.getProjectCredits(parameters).then((res) => {
            if (res.data.success) {
                console.log("getProjectCredits data xxxx", res);

                setProjectCredits(res.data.data.balance);
                setFetchingProjectCredits(false);
            }

        }).catch((error) => {
            console.log("getProjectCredits error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;

            setProjectCredits(0);
            setFetchingProjectCredits(false);
        })
    }

    
    return {
        isFetchingProjectKeys, projectAPIKeys, getProjectAPIKey, isAddingProject, addProject, getProjectDetails, projectDetails, isChangingKeys, changeProjectKeys,
        deleteWorkspaceProject, isDeletingProject, isFetchingProjectDetails, isNoAPIKeyConfigured, isFetchingProjectCredits, getProjectCredits, projectCredits
    }
}

export default useEnterpriseProjectManagement
