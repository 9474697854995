import { Typography, Dialog, DialogContent, DialogActions, TextField, Button } from "@mui/material";
import CustomTextField from "../../../../core/components/ui/CustomTextField3";
import CustomTypography from "../../../../core/components/ui/CustomTypography";
import CustomButton from "../../../../core/components/ui/CustomButton";

export default function VerifyEmailDialog({ isDialogOpen, setDialogOpen, email, setEmail, handleEmailSubmit }) {

    return (
        <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogContent>
                <CustomTypography
                    variant="subtitle1"
                    text={"Please provide your email to complete the sign-in process:"}
                    customStyles={{ marginBottom: 0, fontWeight: 400, color: "#58565F", fontSize: 18 }}
                />
                <CustomTextField
                    variant={"outlined"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    fullWidth={true}
                    multiline={false}
                    hiddenLabel
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#aca3e0',
                                borderRadius: 0
                            },
                            '&:hover fieldset': {
                                borderColor: '#aca3e0',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#aca3e0',
                            },
                        },
                        backgroundColor: '#f6f8fb',
                        '& .MuiInputBase-input': {
                            color: '#666'
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <CustomButton
                    variant="outlined"
                    sx={{ color: '#604CC3', border: '1px solid #604CC3', height: 40 }}
                    label={"Submit"}
                    onClick={handleEmailSubmit}
                />
            </DialogActions>
        </Dialog>
    )
}