import {create} from "zustand";

const useSelectedWorkspaceMemberStore = create((set) => ({
    selectedMember: null,
    isMemberRemoved: false,
    isMemberStatusUpdated: false,
    isMemberRoleUpdated: false,
    isInvalidWorkspaceInvitation: false,
    workspaceOwner: null,

    setSelectedMember: (data) => set({selectedMember: data}),
    setMemberRemoved: (status) => set({isMemberRemoved: status}),
    setMemberStatusUpdated: (status) => set({isMemberStatusUpdated: status}),
    setMemberRoleUpdated: (status) => set ({isMemberRoleUpdated: status}),
    setInvalidWorkspaceInvitation: (status) => set ({isInvalidWorkspaceInvitation: status}),
    setWorkspaceOwner: (data) => set({workspaceOwner: data}),
}));

export default useSelectedWorkspaceMemberStore