import { Box, Drawer, FormControl, FormHelperText, InputLabel, List, ListItem, ListItemButton, ListItemIcon, MenuItem, OutlinedInput, Select, Toolbar, Typography, Button } from "@mui/material";
import CustomButton from "../../../../../core/components/ui/CustomButton";
import DocumentDraggableElementComponent from './document-draggable-element.component'
import Grid from '@mui/material/Grid2'
import draggable_ui_elements from "../../common/draggable-ui-elements";
// import draggable_ui_signatory_elements from "../../common/draggable-ui-signatory-elements"
import useElements from "../../zustand/useElements";
import React, { useEffect, useRef, useState } from "react";
import { BorderColor } from "@mui/icons-material";
import useSignatoryEmailStore from "../../zustand/useSignatoyEmailStore";
import DocumentDraggableSignatoryElementComponent from "./document-draggable-signatory-element.component ";
import DocumentEditorService from "../../services/document-editor-service";
import ConfirmationDialogComponnet from "../../common/dialog/confirmation-dialog.component";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import GestureIcon from '@mui/icons-material/Gesture';
import ManageSignatoryDialogComponent from "../../common/dialog/signatory/manage-signatory-dialog.component";
import useSignatoryStore from "../../zustand/useSignatoryStore";
import useSignatoryElementStore from "../../zustand/useSignatoryElementStore";

const drawerWidth = 400;

const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 1;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    },
  },
};

export default function DocumentEditorDrawerComponent (props) {

  const {documentById, handleSaveDocuments, isSavingDocument} = props;
  const { personName, setPersonName } = useSignatoryEmailStore()
  const [names, setNames] = useState(null)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const hasPDFChanges = useElements((state) => state.hasPDFChanges);
  const [openManageSignatoryDialog, setOpenManageSignatoryDialog] = useState(false)
  const signatoryStore = useSignatoryStore((state) => state.signatory)
  const { signatoryEmailValue, setSignatoryEmailValue } = useSignatoryElementStore();
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    console.log("signatory kkk", signatoryStore);
  }, [JSON.stringify(signatoryStore)])

  const handleOpenConfirmationModal = () => {
    setOpenConfirmation(true)
  }

  const handleCloseConfirmationModal = () => {
    setOpenConfirmation(false)
  }

  const handleChange = (event) => {
    const { value } = event.target;
    console.log('get selected email here', value)
    const matchedEntry = names?.find((entry) => 
      entry.name?.value === value
   );

   if(matchedEntry){
    setPersonName(matchedEntry)
   }
    setSelectedValue(value)
  };

  const elements = useElements((state) => state.elements);

  const signatories = elements
  .filter(element => element.type === 'signatory')
  .filter((element, index, self) => 
    index === self.findIndex(e => e.value === element.value && element.value !== null)
  );

  const addSignatory = elements.find(element => element.type === 'signatory' && element.value !== null);

  const status = documentById?.status === "COMPLETED" || documentById?.status === "ONGOING";

  const disabledDraggableElements = personName?.length > 0 ? 'orange' : 'gray';

  const draggable_ui_signatory_elements = [
    {
        icon: <TextFieldsIcon sx={{color:  disabledDraggableElements}}/>,
        label:'Textfield',
        type: 'fillable_textfield'
    },
    {
        icon:<GestureIcon sx={{color:  disabledDraggableElements}}/>,
        label:'Signature',
        type: 'signatory'
    },
   
    {
        icon:<CalendarMonthIcon sx={{color:  disabledDraggableElements}}/>,
        label:'Date',
        type: 'fillable_date'
    },
  ];

  const emailColorMap = useRef({});

  const PREDEFINED_COLORS = [
    "#FFADAD", "#9AE19D", "#FFEE99", "#A7C7E7", "#FFBE88",
    "#C79BD2", "#A4EAEA", "#F5B2D8", "#D4F4A1", "#FFDCDC",
    "#80CBC4", "#E6CCFF", "#D9A38C", "#FFF5CC", "#C48A8A",
    "#D4FFD9", "#C4C499", "#FFE0C1", "#9999FF", "#C4C4C4",
    "#A6F4FF", "#FFB3FF", "#FFB2B2", "#80CFA6", "#66B2CC",
    "#CC6699", "#FFEDF2", "#C8A080", "#8080E6", "#A1FFD4",
    "#D1B399", "#669988", "#C2B280", "#A68099", "#FF80B3",
    "#A89F9F", "#D96C6C", "#A8B967", "#66E0C2", "#FFC8A8",
    "#FFB2D9", "#C47CC4", "#E28D57", "#EEF7FF", "#666699",
    "#A67F7F", "#C9D8B6", "#80505D", "#8DD9EC", "#577D91"
];


useEffect(() => {
  if (!signatories) return;

  const tempEmailColorMap = { ...emailColorMap.current };

  signatories.forEach((signatory) => {
    if (!signatory?.value?.trim()) return; // Skip if value is null or empty

    // Keep existing color if assigned
    if (tempEmailColorMap[signatory.value]) return;

    // Always assign the same color for the same email
    tempEmailColorMap[signatory.value] = PREDEFINED_COLORS[
      Object.keys(tempEmailColorMap).length % PREDEFINED_COLORS.length
    ];
  });

  emailColorMap.current = tempEmailColorMap;

  setNames(
    signatories
      .filter((signatory) => signatory?.value?.trim()) // Ensure only valid values are processed
      .map((signatory) => ({
        name: signatory,
        color: tempEmailColorMap[signatory.value] || PREDEFINED_COLORS[0],
      }))
  );
}, [elements, emailColorMap]);



  // Add consoles here
  console.log('signatory array list hereeee', names)
  console.log('elements array list', elements)
  console.log('get add signatory logs here', addSignatory)

  const handleOpenManageSignatoryDialog = () => {
    setOpenManageSignatoryDialog(true)
  }

  const handleCloseManageSignatoryDialog = () => {
    setOpenManageSignatoryDialog(false)
  }
  
  useEffect(() => {
    if (selectedValue) {
      setSignatoryEmailValue(''); 
    }
  
    if (signatoryEmailValue) {
      setSelectedValue(''); 
    }
  
    const matchedEntry = names?.find((entry) => 
       entry.name?.value === signatoryEmailValue
    );
  
    console.log('Matched Entry:', matchedEntry);
  
    if (matchedEntry) {
      setPersonName(matchedEntry);
      setSelectedValue(matchedEntry.name.value)
    }
  }, [selectedValue, signatoryEmailValue, names]);
  
  

console.log('get selected value', selectedValue)

    return(
      <>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          }}
        >
          <Toolbar />
          {/* ELEMENT CONTENT */}
          <Box sx={{ p: 3, mt: 2 }}>
            <Typography sx={{textAlign:'start', mb: 2, fontSize: 16, color: '#604CC3'}}>{documentById?.title}</Typography>
            <Typography sx={{textAlign:'start', mb: 2, fontSize: 16}}>Elements</Typography>

            <Grid container spacing={2}>
                {draggable_ui_elements.map((draggable_ui_element, index)=> (
                  <Grid size={{ xs: 6}}>
                    <DocumentDraggableElementComponent draggable_ui_element={draggable_ui_element} />
                  </Grid>
                ))}
            </Grid>
            <br/>
            <br/>
            {signatoryStore.length > 0 &&
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}><Button onClick={handleOpenManageSignatoryDialog} sx={{textTransform: 'capitalize', textDecoration: 'underline'}}>Manage Signatory</Button></Box>
            }
            {addSignatory &&  
              <>
            <FormControl sx={{ width: '100%', "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "orange", // Default border color
              },
              "&:hover fieldset": {
                borderColor: "orange", // Hover border color
              },
              "&.Mui-focused fieldset": {
                borderColor: "orange", // Focused border color
              },
              
            }, }}
            error={personName?.length === 0}>
              <InputLabel id="demo-multiple-name-label">Signatory</InputLabel>
              <Select
                fullWidth
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={selectedValue || ''}
                onChange={handleChange}
                sx={{borderColor:'red'}}
                input={<OutlinedInput  label="Signatory" />}
                MenuProps={MenuProps}
              >
                {addSignatory.value === '' && (
                <MenuItem>
                  <Typography sx={{fontSize: 13, margin:'auto'}}>No Signatory email added yet.</Typography>
                </MenuItem>
                )}
                {names?.map((signatoryEmail) => (
                  <MenuItem
                    key={signatoryEmail?.name?.value}
                    value={signatoryEmail?.name?.value}
                  >
                    {signatoryEmail.name?.value}
                  </MenuItem>
                ))}
              </Select>
              {personName?.length === 0 && (
                <FormHelperText>Select a signatory to use the elements below.</FormHelperText>
              )}
            </FormControl>
            <br/>
            <br/>
            
                <Grid container spacing={2}>
                    {draggable_ui_signatory_elements?.map((draggable_ui_element, index)=> (
                      <Grid size={{ xs: 6}}>
                        <DocumentDraggableSignatoryElementComponent addSignatory={addSignatory} draggable_ui_element={draggable_ui_element} signatories={signatories} />
                      </Grid>
                    ))}
                </Grid>
              </>
            }
          </Box>
          <Box sx={{flexGrow:1}}/>
          <CustomButton disabled={ status } onClick={()=>handleOpenConfirmationModal()} label={'Send'} variant={'contained'} sx={{fontSize: 16, m:2}}/>
        </Drawer>

        <ManageSignatoryDialogComponent openManageSignatoryDialog={openManageSignatoryDialog} handleCloseManageSignatoryDialog={handleCloseManageSignatoryDialog} label={"Manage Signatory"} />

        <ConfirmationDialogComponnet setOpenConfirmation={setOpenConfirmation} isSavingDocument={isSavingDocument} signatories={signatories} handleSaveDocuments={handleSaveDocuments} openConfirmation={openConfirmation} handleCloseConfirmationModal={handleCloseConfirmationModal} />
      </>
    )
}