import { Box, Pagination, Paper, styled, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import CustomStatusLabel from "../../../core/components/common/custom-status-label.component";
import { format } from 'date-fns';
import CustomDocumentListPopperComponent from "../../../core/components/common/custom-document-list-popper.component";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import HistoryIcon from '@mui/icons-material/History';
import { useEffect, useState } from "react";
import DocumentHistoryDialog from "../common/dialog/document-history.dialog";
import { useNavigate } from "react-router-dom";
import DocumentListSkeleton from "./skeleton/document-list-skeleton";
import resendIcon from '../../../assets/icons/resend.png';
import DocumentResendDialog from "../common/dialog/document-resend.dialog";
import DocumentCancelDialog from "../common/dialog/document-cancel.dialog";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DocumentSignatoriesDialogComponent from "../common/dialog/document-signatories.dialog.component";
import useDocumentEditor from "../../supersign-docs-plugin/document-editor-plugin/hooks/useDocumentEditor";
import DrawIcon from '@mui/icons-material/Draw';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: theme.palette.common.black,
    borderRadius: 5,
    borderBottom: 'none',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: 'none',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: 'white',
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const headers = [
  { label: "Title" },
  { label: "Status" },
  { label: "Last Date Modified" },
  { label: "Actions" }
];

const menuItemsLabel = [
  { id: 'view', icon: <VisibilityIcon />, text: 'View', color: "#604CC3" },
  { id: 'edit', icon: <DriveFileRenameOutlineIcon />, text: 'Edit', color: "#604CC3" },
  { id: 'history', icon: <HistoryIcon />, text: 'History', color: "#604CC3" },
  { id: 'resend', icon: <img src={resendIcon} style={{ width: 24, height: 24 }} />, text: 'Resend Email', color: "#604CC3" },
  { id: 'signatories', icon: <DrawIcon />, text: 'Signatories', color: "#604CC3" },
  { id: 'cancel', icon: <HighlightOffIcon sx={{ color: 'red' }} />, text: 'Cancel', color: "red" },
];

export default function DocumentListTableComponent(props) {
  const { status, documentList, isLoadingList, page, setPage, pagination, filterDocument, isLoadingFilter, handleChangePagination, searchInput, cancelDocument } = props;

  const navigate = useNavigate();
  const { signatoryList, signatoryListByDocumentId } = useDocumentEditor();

  const [documents, setDocuments] = useState(null);
  const [openHistory, setOpenHistory] = useState(false);
  const [openResend, setOpenResend] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [selectedDocumentTitle, setSelectedDocumentTitle] = useState('');
  const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);
  const [openDocumentSignatories, setOpenDocumentSignatories] = useState(false);
  const [documentDetails, setDocumentDetails] = useState(null)

  useEffect(() => {
    if (selectedDocumentId) {
      signatoryListByDocumentId(selectedDocumentId)
    }
  }, [selectedDocumentId])

  useEffect(() => {

    if (isLoadingList) {
      setDocuments([]); // Reset data when loading
      return;
    }
    // const filteredRows = status || searchInput && page
    //   ? filterDocument?.filter((row) => row.document.status?.toLowerCase() === status?.toLowerCase())
    //   : documentList || []; 

    console.log("filterDocument aaaa", filterDocument);
    console.log("status bbbxx ", status);
    
    // const filteredRows = status !== null ? filterDocument?.filter((row) => row.document.status?.toLowerCase() === status?.toLowerCase()) : filterDocument
    // console.log("filterDocument bbbb", filteredRows);
    
    setDocuments(filterDocument);

  }, [status, searchInput, filterDocument, documentList, page, isLoadingList]);

  const handleSelectMenu = (e, row) => {

    console.log("row here:", row);

    const document_id = row.document.id;
    const document_title = row.document.title;
    const cancel_reason = "Test";

    if (e.id === 'view') {
      window.location.href = '/view-document?id=' + document_id;
    } else if (e.id === 'edit') {
      navigate('/document-editor?id=' + document_id);
    } else if (e.id === 'history') {
      setSelectedDocumentId(document_id);
      setSelectedDocumentTitle(document_title);
      setOpenHistory(true);
    } else if (e.id === 'resend') {
      setSelectedDocumentId(document_id);
      setSelectedDocumentTitle(document_title);
      setOpenResend(true);
    } else if (e.id === 'signatories') {
      setSelectedDocumentId(document_id);
      setSelectedDocumentTitle(document_title);
      setOpenDocumentSignatories(true)
      setDocumentDetails(row)
    } else if (e.id === 'cancel') {
      setSelectedDocumentId(document_id);
      setSelectedDocumentTitle(document_title);
      setOpenCancelConfirmation(true)
    }
  };

  const handleViewDocument = (docStatus, document_id) => {
    // if (docStatus !== 'COMPLETED') {
    // navigate('/view-document?id=' + document_id);
    window.location.href = '/view-document?id=' + document_id;
    // }else{

    // }
  };

  const handleCloseHistoryDialog = () => {
    setOpenHistory(false);
  };

  const handleCloseResendDialog = () => {
    setOpenResend(false);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ bgcolor: 'white' }}>
        <Table>
          <TableHead>
            <TableRow sx={{ borderBottom: 'none' }}>
              {headers.map((row) => (
                <StyledTableCell key={row.label}>{row.label}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          {isLoadingList || isLoadingFilter ? (
            <TableBody>
              <DocumentListSkeleton />
            </TableBody>
          ) : (
            <TableBody>
              {documents?.map((row) => {
                const document_id = row.document.id;
                const docStatus = row.document.status;

                const menuItems = menuItemsLabel.map((item) => {
                  const { id } = item;

                  let disabled = false;

                  // New condition to hide 'Edit' and 'Resend' if status is 'SCHEDULED'
                  if (docStatus === 'SCHEDULED') {
                    if (id === 'edit' || id === 'resend') {
                      disabled = true; // Disable 'Edit' and 'Resend' for 'SCHEDULED' status
                    }
                  } else if (id === 'history') {
                    disabled = false; // Explicitly enable 'history'
                  } else if (docStatus === 'DRAFT') {
                    disabled = id !== 'history' && id !== 'edit' && id !== 'view'; // Disable all except 'history'
                  } else if (docStatus === 'COMPLETED') {
                    disabled = id !== 'history' && id !== 'view';
                  } else if (docStatus === 'CANCELLED') {
                    disabled = id !== 'resend'; // Disable all except 'resend'
                  } else if (docStatus === 'EXPIRED') {
                    disabled = id !== 'history'; 
                  } else if (id === 'resend' && ['COMPLETED', 'DRAFT'].includes(docStatus)) {
                    disabled = true; // Disable 'resend' for COMPLETED or DRAFT
                  } else if (
                    ['COMPLETED', 'CANCELLED'].includes(docStatus) ||
                    (docStatus === 'ONGOING' && id === 'edit')
                  ) {
                    disabled = true; // Disable items for specific statuses
                  } else if (['ERROR', 'DENIED'].includes(docStatus)) {
                    disabled = id !== 'cancel' && id !== 'resend'; // Enable only 'cancel' and 'resend'
                  }

                  return { ...item, disabled };
                });



                return (
                  <StyledTableRow key={document_id}>
                    <StyledTableCell
                      onClick={() => handleViewDocument(docStatus, document_id)}
                      sx={{ color: '#978AD8', cursor: 'pointer' }}
                    >
                      {row.document.title}
                    </StyledTableCell>
                    <StyledTableCell>
                      <CustomStatusLabel label={row.document.status} />
                    </StyledTableCell>
                    <StyledTableCell>{format(new Date(row.document.updated_time), 'PPpp')}</StyledTableCell>
                    <StyledTableCell>
                      <CustomDocumentListPopperComponent
                        menuItems={menuItems}
                        handleSelectMenu={handleSelectMenu}
                        row={row}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          )}

        </Table>
        {!isLoadingList && documents?.length === 0 && 
          <Box sx={{ m: 5 }}>
            No {status
            ? status
               .split(/[-\s]/)
               .map(word => word.charAt(0).toUpperCase() + word.slice(1))
               .join(" ")
            : ''} documents found.
          </Box>
        }
      </TableContainer>
      {!isLoadingList && documents?.length !== 0 && 
      <Pagination
        count={pagination}
        page={page}
        onChange={handleChangePagination}
        style={{ margin: 'auto', width: 'fit-content', paddingTop: 30, paddingBottom: 30 }}
      />
    }

      {openHistory && (
        <DocumentHistoryDialog
          openHistory={openHistory}
          handleCloseHistoryDialog={handleCloseHistoryDialog}
          document_id={selectedDocumentId}
          document_title={selectedDocumentTitle}
        />
      )}

      {openResend && (
        <DocumentResendDialog
          openResend={openResend}
          handleCloseResendDialog={handleCloseResendDialog}
          document_id={selectedDocumentId}
          document_title={selectedDocumentTitle}
        />
      )}

      {openCancelConfirmation && (
        <DocumentCancelDialog openCancelConfirmation={openCancelConfirmation} setOpenCancelConfirmation={setOpenCancelConfirmation}
          document_title={selectedDocumentTitle} document_id={selectedDocumentId}
        />
      )}

      {openDocumentSignatories && (
        <DocumentSignatoriesDialogComponent
          openDocumentSignatories={openDocumentSignatories}
          setOpenDocumentSignatories={setOpenDocumentSignatories}
          signatoryList={signatoryList}
          document_id={selectedDocumentId}
          document_title={selectedDocumentTitle}
          documentDetails={documentDetails}
        />
      )}

    </Paper>
  );
}
