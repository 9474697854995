import React from 'react';
import { Button, Skeleton } from '@mui/material';

const WalletBalanceButton = ({ walletBalance, isFetchingWalletBalance, sx, ...props }) => {
    const formatBalance = () => {
        if (walletBalance === 0 || walletBalance === 1) {
            return `${walletBalance.toLocaleString()} Token`;
        }
        return `${walletBalance.toLocaleString()} Tokens`;
    };

    return (
        <Button
            variant="contained"
            disableElevation
            sx={{
                fontSize: 16,
                borderRadius: 1,
                textTransform: 'none',
                fontWeight: 'bold',
                color: '#604CC3',
                width: 'auto',
                backgroundColor: 'rgba(96, 76, 195, 0.21)',
                cursor: 'default',
                ...sx, 
            }}
            {...props} 
        >
            {isFetchingWalletBalance ? (
                <Skeleton variant="text" width={70} height={35} sx={{ backgroundColor: '#eeebf8' }} />
            ) : (
                formatBalance() 
            )}
        </Button>
    );
};

export default WalletBalanceButton;
