
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {IconButton, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

export default function BackButtonComponent ({label, labelStyles, onClick}) {

    const navigate = useNavigate();

    const handleClick = () => {
        if (onClick) {
            onClick(); 

        } else {
            navigate(-1)
        }
    }


    return (
        <IconButton onClick={handleClick} sx={{display: "flex", alignItems: "center", pl: "0px !important", "&:hover": {backgroundColor: "transparent"}}}>
            <ArrowBackIcon />

            {label && 
                <Typography variant="body1" style={{marginLeft: 16, ...labelStyles}}>
                    {label}
                </Typography>
            }
        </IconButton>
    )
}