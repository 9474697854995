import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import DocumentEditorAppbarComponent from '../components/document-editor/document-editor-appbar.component';
import DocumentEditorDrawerComponent from '../components/document-editor/document-editor-drawer.component';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DocumentEditorBoxComponent from '../components/document-editor/document-editor-box.component';
import useDocument from '../../../documents-plugin/hooks/useDocument';
import { useEffect, useState } from "react";
import useElements from '../zustand/useElements';
import DocumentEditorService from '../services/document-editor-service';
import useDocumentEditor from '../hooks/useDocumentEditor';
import useCarbonCopyStore from '../zustand/useCarbonCopyStore';
import useCheckedStore from '../zustand/useCheckStore';
import useSignatoryStore from '../zustand/useSignatoryStore';
import { useElementHook } from "../hooks/useElementHook";
import { useBlocker } from "../hooks/useBlocker";
import AuthenticationService from "../../../profile-plugin/services/authentication-service";
import useUpdateProfileStore from "../../../profile-plugin/zustand/useUpdateProfileStore";
import { useSnackbar } from "notistack";

export default function DocumentEditorScreen () {

  const id = new URLSearchParams(window.location.search).get("id");

  // const [encrypted_files, setEncryptedFile] = useState(null)

  const {getDocumentById, documentById, viewDocument, decryptedFile} = useDocument();
  const elements = useElements((state) => state.elements);
  const pdfPages = useElements((state) => state.pdfPages);
  const deletedElements = useElements((state) => state.deletedElements);
  const newElementArray = useElements((state) => state.newElements);
  const updatedElementArray = useElements((state) => state.updatedElements);
  const hasPDFChanges = useElements((state) => state.hasPDFChanges);
  const setHasPDFChanges = useElements((state) => state.setHasPDFChanges);
  const signatory = useSignatoryStore((state) => state.signatory)
  const setProfileESignature = useUpdateProfileStore((state) => state.setProfileESignature)
  
  const {getProportionalElementCoords, getProportionalElementDimens, getProportionalElementFontSize} = useElementHook();

  console.log("newElementArray 11", newElementArray);
  console.log("updatedElementArray 11", updatedElementArray);
  const { enqueueSnackbar } = useSnackbar();
  

  const {saveDocument, hasDoneSavedDocument, saveDocumentAsDraft, hasDoneSavedDocumentAsDraft, isSavingToDraft, isSavingDocument} = useDocumentEditor()
  const { carbon_copy_list} = useCarbonCopyStore();
  const { checked, auto_reminder, document_expiration, auto_scheduled, selectedDate, expired_days, selectedTime} = useCheckedStore();

  console.log('get carbon copy list here', carbon_copy_list)

  useBlocker(() => {
    if (hasPDFChanges) {
      return window.confirm("Are you sure you want to leave? You may lose your unsaved changes.");
    }
    return true;
  }, hasPDFChanges);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasPDFChanges) {
        e.preventDefault();
        e.returnValue = "";
        return true;
      }
      return null;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [hasPDFChanges]);

  useEffect(() => {
    AuthenticationService.getProfile().then((res) => {
      let signature = res.data.data.profile.signature
      setProfileESignature(signature)
    })
  }, [])

  useEffect(()=>{
    if(documentById){
      let url = documentById?.document_url;

      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = "http://" + url;
      }

      let pdfUrl = new URL(url);

      let encrypted_file = pdfUrl.searchParams.get('e');

      viewDocument(encrypted_file)

      // setEncryptedFile(encrypted_file)
    }
  },[documentById])

  useEffect(()=>{
    if(id){
      getDocumentById(id)
    }
  },[id])

  useEffect(() => {
    if (hasDoneSavedDocumentAsDraft) {
      enqueueSnackbar('Successfully saved as draft.', {
        variant: "success",
        autoHideDuration: 2000
      })
      window.location.href="/documents"
    }
  }, [hasDoneSavedDocumentAsDraft])

  useEffect(() => {
    if (hasDoneSavedDocument) {
      enqueueSnackbar('Successfully saved.', {
        variant: "success",
        autoHideDuration: 2000
      })
      window.location.href="/documents"
    }
  }, [hasDoneSavedDocument])

  const handleGetAPIPayloadlement = (element) => {
      console.log("element nnnx", element);
    
      let current_page = pdfPages.find((page) => page.pageNo == element.pageNumber)
      let rotation = current_page.rotate
      let dropRefCurrentWidth = current_page.dropRefCurrentWidth
      let dropRefCurrentHeight = current_page.dropRefCurrentHeight
      let pageOrigWidth = current_page.pageOrigWidth
      let pageOrigHeight = current_page.pageOrigHeight

      if (element.pageNumber == current_page?.pageNo) {
          let {x, y} = getProportionalElementCoords(element.x_axis, element.y_axis, dropRefCurrentWidth, dropRefCurrentHeight, rotation, pageOrigWidth, pageOrigHeight)
          let {w: width, h: height} = getProportionalElementDimens(element.width, element.height, dropRefCurrentWidth, dropRefCurrentHeight, rotation, pageOrigWidth, pageOrigHeight)

          let updated_element_props = {
              type: element.type,
              value: element.value,
              xAxis: x.toString(),
              yAxis: y.toString(),
              pageNo: element.pageNumber,
              width: width.toString(),
              height: height.toString(),
              fontSize: element.fontSize,
              date_format: element.date_format,
              assign_color: element.assign_color,
              ...(typeof element.id === 'number' && { id: element.id })
          }
          
          if(element.type == 'textfield' || element.type == 'date' || element.type == 'fillable_textfield' || element.type == 'fillable_date'){
              let el_font_size = updated_element_props.fontSize
              console.log("el_font_size xx", el_font_size);
              
              let {font_size: proportional_font_size} = getProportionalElementFontSize(rotation, dropRefCurrentWidth, pageOrigWidth, pageOrigHeight, el_font_size)
              updated_element_props.fontSize = proportional_font_size.toString();
              console.log("updated_element_props xx", updated_element_props);
          }
          return updated_element_props
      }
      return element
  }

  const handleSaveDocuments = () => {

    let documentId = JSON.parse(id); 
    let oid = documentById?.oid; 
    let filename = documentById?.title || 'Untitled Document'; // Fallback to avoid undefined filenames
    // let dateFormat = selectedDate?.format('MM-DD-YYYY').toString();
    let dateFormat = selectedDate?.format('YYYY-MM-DD').toString();

    const signatories = elements.filter(element => element.type === 'signatory');

    // const formattedDate = dateFormat?.split("-").reverse().join("-");
    const formattedTime = selectedTime?.toString() + ":00"
    const auto_schedule_date_time = `${dateFormat} ${formattedTime}`

    let newElements = newElementArray.map((el) => handleGetAPIPayloadlement(el))
    let updatedElements = updatedElementArray.map((el) => handleGetAPIPayloadlement(el));
    let deletedElementIds = deletedElements.filter(item => typeof item.id === 'number').map(item => item.id);
    // let  signatory = signatories.map(item => item.value);
    let  cc = carbon_copy_list;
    let  is_certificate = checked;
    let  is_auto_remind = auto_reminder;
    let  is_auto_expire = document_expiration;
    let  is_auto_scheduled = auto_scheduled;
    let  auto_scheduled_date_time = dateFormat ? auto_schedule_date_time : null;
    let  total_page = null;

    console.log("expired days bbb", expired_days);
    console.log("is_auto_remind bbb", is_auto_remind);
    console.log("is_auto_expire bbb", is_auto_expire);
    console.log("is_auto_scheduled bbb", is_auto_scheduled);
    console.log("is_auto_scheduled bbb", auto_scheduled_date_time);
    console.log("newElements 11", newElements);
    console.log("updatedElements 11", updatedElements);
    saveDocument(documentId, oid, newElements, filename, updatedElements, deletedElementIds, signatory, cc, is_certificate, is_auto_remind, is_auto_expire, is_auto_scheduled, expired_days, auto_scheduled_date_time, total_page)
    setHasPDFChanges(false)
  };

  const handleSaveDocumentAsDraft = () => {
    let documentId = JSON.parse(id); 
    let filename = documentById?.title || 'Untitled Document'; // Fallback to avoid undefined filenames
    const signatories = elements.filter(element => element.type === 'signatory');

    let newElements = newElementArray.map((el) => handleGetAPIPayloadlement(el))
    let updatedElements = updatedElementArray.map((el) => handleGetAPIPayloadlement(el));
    let deletedElementIds = deletedElements.filter(item => typeof item.id === 'number').map(item => item.id);
    // let  signatory = signatories.map(item => item.value);
    let  cc = [];
    let  is_certificate = documentById?.is_certificate;
    let  is_auto_remind = documentById?.is_auto_remind;
    let  total_page = null;

    console.log("newElements 11", newElements);
    console.log("updatedElements 11", updatedElements);
    console.log("deletedElementIds 11", deletedElementIds);
    console.log('get signatory array list hereeeeeeeee', signatory)
    console.log('xxxxxx aaaa')
  
    saveDocumentAsDraft(documentId, newElements, filename, updatedElements, deletedElementIds, signatory, cc, is_certificate, is_auto_remind, total_page)
    setHasPDFChanges(false)
  };

  return(
      <DndProvider backend={HTML5Backend}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {/* Document Editor Appbar */}
        <DocumentEditorAppbarComponent decryptedFile={decryptedFile} documentById={documentById} handleSaveDocumentAsDraft={handleSaveDocumentAsDraft} isSavingToDraft={isSavingToDraft}/>

        {/* Document Editor Drawer */}
        <DocumentEditorDrawerComponent documentById={documentById} handleSaveDocuments={handleSaveDocuments} isSavingDocument={isSavingDocument} />

        {/* Document Page Component */}
        <Box component="main" sx={{ flexGrow: 1, mt: 1 }}>
          <Toolbar />
            <DocumentEditorBoxComponent decryptedFile={decryptedFile} documentById={documentById} />
        </Box>
      </Box>
    </DndProvider>
  )
}