import React, { useState } from "react";
import CustomDialog from "../../../../core/components/ui/CustomDialog";
import { Box, CircularProgress } from "@mui/material";
import CustomTypography from "../../../../core/components/ui/CustomTypography";
import CustomTextField from "../../../../core/components/ui/CustomTextFieldV2";
import CustomDialogLoaderComponent from "../../../../core/components/common/custom-dialog-loader.component";
import useEnterpriseProjectManagement from "../../hooks/useEnterpriseProjectManagement";

const AddProjectDialog = (props) => {
    const { open, setOpen, workspace, isWorkspaceId } = props;

    const { isAddingProject, addProject } = useEnterpriseProjectManagement();

    const [subKey, setSubKey] = useState(null);
    const [apiKey, setApiKey] = useState(null);
    

    const handleTextfieldChange = (e, name) => {
        if (name === "apiKey") {
            setApiKey(e.target.value);
        } else {
            setSubKey(e.target.value);
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleAddProject = () => {
        if (subKey && apiKey) {
            const payload = {
                workspace_id: isWorkspaceId ? workspace : workspace?.workspace.id,
                subscription_key: subKey,
                api_key: apiKey
            }

            addProject(payload, setOpen);
        }
    }

    const actions = [
        {
            label: "Add",
            onClick: () => {
                handleAddProject();
            },
        }
    ]

    const globalSx = {
        actions: {padding: "15px 20px 30px 30px"}
    }


    return (
        <>
            <CustomDialog
                open={open}
                onClose={(isAddingProject) ? null : handleClose}
                title="Add OVCODE API Key"
                content={
                    <Box>
                        {(isAddingProject) ?
                            <CustomDialogLoaderComponent
                                icon={<CircularProgress size={40} sx={{ color: "#604CC3" }} />}
                                message={"Adding..."}
                            />
                        :
                            <>
                                <CustomTypography
                                    variant="subtitle1"
                                    text={"Subscription Key"}
                                    customStyles={{ marginBottom: 0, fontWeight: 400, color: "#58565F", fontSize: 18 }}
                                />

                                <CustomTextField
                                    placeholder={"Subscription Key"}
                                    variant={"filled"}
                                    onChange={(e) => handleTextfieldChange(e, "subKey")}
                                    value={subKey}
                                    fullWidth={true}
                                    multiline={false}
                                    hiddenLabel
                                />   <br /> <br />

                                <CustomTypography
                                    variant="subtitle1"
                                    text={"API Key"}
                                    customStyles={{marginBottom: 0, fontWeight: 400, color: "#58565F", fontSize: 18, mt: "5px"}}
                                />

                                <CustomTextField
                                    placeholder={"API Key"}
                                    variant={"filled"}
                                    onChange={(e) => handleTextfieldChange(e, "apiKey")}
                                    value={apiKey}
                                    fullWidth={true}
                                    multiline={false}
                                    hiddenLabel
                                />   <br />
                            </>
                        }
                    </Box>
                }
                actions={(!isAddingProject && actions) ? actions : []}
                maxWidth={"sm"}
                customStyles={{
                    actions: {
                        opacity: (!isAddingProject && (subKey && apiKey)) ? "100%" : "55%",
                        cursor: (!isAddingProject && (subKey && apiKey)) ? "pointer" : "not-allowed",
                        visibility: (isAddingProject) ? "hidden" : "visible",
                    }
                }}
                globalSx={globalSx}
            />
        </>
    )
}

export default AddProjectDialog