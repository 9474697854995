import { Alert, Avatar, Box, Card, CardActionArea, CardContent, CardHeader, CardMedia, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import useElements from "../../zustand/useElements";
import { QRCodeCanvas } from "qrcode.react";
import DocumentEditorService from "../../services/document-editor-service";
import { getWidthByPercentage } from "../../utils/ProportionDimenUtil";
import { ResizableBox } from "react-resizable";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useElementHook } from "../../hooks/useElementHook";
import useSignatoryStore from "../../zustand/useSignatoryStore"
import { elementTypes } from "../../common/staticVariables";
import signatoryElements from "../../common/signatory-elements";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import CompletedPdfPlaceholder from "../../../../../assets/authenticated-docs.pdf"
import BannerIcon from "../../../../../assets/images/banner1.png"
import { set } from "date-fns";
import Verified from "../../../../../assets/icons/verify.gif"
import VerifiedIcon from '@mui/icons-material/Verified';

export default function DocumentViewPageComponent ({decryptedFile, documentById, documentDetails}) {

    const [numPages, setNumPages] = useState(null);
    // const [pdfPages, setPdfPages] = useState([])

    console.log('AAAAAAAAAAAAAAAAAAAAAAAASSSSSSSSSSSSSSSSSSSSSSSSSS', documentDetails)

    const elements = useElements((state) => state.elements);
    const addElement = useElements((state) => state.addElement);
    const {getReverseProportionElementCoords, getReverseProportionElementDimens, getReverseProportionalElementFontSize} = useElementHook();

    const pdfEditorRef = useRef()

    const pdfPages = useElements((state) => state.pdfPages);
    const addPDFPage = useElements((state) => state.addPDFPage);
    const updatePDFPage = useElements((state) => state.updatePDFPage);
    const currentSelectedSignatory = useSignatoryStore((state) => state.currentSelectedSignatory);
    const setElements = useElements((state) => state.setElements);
    const [viewingAsElements, setViewingAsElements] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        setViewingAsElements(elements)
    }, [JSON.stringify(elements)])

    useEffect(() => {
        if (currentSelectedSignatory == 'all') {
            setViewingAsElements(elements)
        } else {
            let regular_elements = elements.filter((item) => item.type != "signatory" && item.type != "fillable_textfield" && item.type != "fillable_date")
    
            const filtered_fillable_elements = elements.filter((item) => item.value == currentSelectedSignatory && 
                (item.type == "signatory" || item.type == "fillable_textfield" || item.type == "fillable_date"))
            
            if (filtered_fillable_elements.length > 0) {
                setViewingAsElements([...regular_elements, ...filtered_fillable_elements])
            }
        }
    }, [currentSelectedSignatory])

    function handleOnLoadDocumentSuccess(pdf) {
        setNumPages(pdf.numPages); // Set the total number of pages
        handleSetPDFPages(pdf)
        console.log('CCCCCCCCCCCCCCCCCCCCCCCCCCCCC', pdf)
        setIsLoading(false);
    }
    
      const handleSetPDFPages = (pdf) => {
        
        [...Array(pdf.numPages)].map((item, i) => {
            let pageNum = i + 1
            pdf.getPage(pageNum).then((page) => {
    
                let pdfEditorWrapperWidth = pdfEditorRef?.current?.clientWidth
    
                let percentage = 80
                let percentageWidth = getWidthByPercentage(percentage, pdfEditorWrapperWidth)
    
                let rotate = page.rotate
                let pageNo = page?.pageNumber
                let obj = {
                    numPages: pdf.numPages,
                    adjustedPDFWidth: percentageWidth,
                    pageNo,
                    rotate,
                }


                addPDFPage(obj)
            })
        })
    }

    function getPercentageIncreaseDecrease(newNum, origNum) {
        return ((newNum - origNum) / origNum) * 100;
    }

    useEffect(() => {
        DocumentEditorService.documentElementProperties(documentById).then((res)=>{
            res?.data?.data?.documentProperties?.forEach((el) => {

                // Hanlde Reverse Proportion Elements
                let current_page = pdfPages.find((page) => page.pageNo == el.page_no)
                let rotation = current_page?.rotate
                let dropRefCurrentWidth = current_page?.dropRefCurrentWidth
                let dropRefCurrentHeight = current_page?.dropRefCurrentHeight
                let pageOrigWidth = current_page?.pageOrigWidth
                let pageOrigHeight = current_page?.pageOrigHeight

                console.log("pdfPages xx", pdfPages);
                console.log("current_page xx", current_page);
                console.log("el xx", el);
                

                if (el.page_no == current_page?.pageNo) {
                    console.log("matched xx", el);

                    let {x: reversedX, y: reversedY} = getReverseProportionElementCoords(parseFloat(el.x_axis), parseFloat(el.y_axis), dropRefCurrentWidth, dropRefCurrentHeight, rotation, pageOrigWidth, pageOrigHeight)
                    let {w: reversedWidth, h: reversedHeight} = getReverseProportionElementDimens(rotation, dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, parseFloat(el.width), parseFloat(el.height))
                    
                    console.log("el type xx", el.type);
                    console.log("reversedX xx", reversedX);
                    console.log("reversedY xx", reversedY);
                    console.log("reversedWidth xx", reversedWidth);
                    console.log("reversedHeight xx", reversedHeight);

                    let updated_element_props = {
                        type: el.type,
                        value: el.value,
                        x_axis: reversedX,
                        y_axis: reversedY,
                        pageNumber: el.page_no,
                        width: reversedWidth,
                        height: reversedHeight,
                        date_format: el.date_format,
                        id: el.id,
                        assign_color: el.assign_color,
                    }
                    
                    if(el.type == 'textfield' || el.type == 'date' || el.type == 'fillable_textfield' || el.type == 'fillable_date'){
                        if (el.type == 'fillable_textfield') {
                            console.log("el font size bbb", el);
                        }
                        
                        let {font_size} = getReverseProportionalElementFontSize(rotation, dropRefCurrentWidth, pageOrigWidth, pageOrigHeight, parseFloat(el.font_size))
                        updated_element_props.fontSize = font_size;
                    }

                    console.log("updated_element_props xx", updated_element_props);

                    addElement(updated_element_props); // Add each individual element
                }
            })


        })
    }, [JSON.stringify(pdfPages)])

    const handleLoadInitialElements = (page) => {
        let rotate = page?.rotate

        let isLandscape = rotate == 90 || rotate == 270 ? true : false
        let pageWidth = isLandscape ? page?.height : page?.width
        let pageHeight = isLandscape ? page?.width : page?.height

        let dropRefCurrentWidth = pageWidth
        let dropRefCurrentHeight = pageHeight

        let pageOrigWidth = page?.originalWidth
        let pageOrigHeight = page?.originalHeight - page?.view[1]
        let pageNo = page?.pageNumber

        let updated_pdf_page = pdfPages.find((item) => item.pageNo == pageNo)
        if (updated_pdf_page) {
            updated_pdf_page.dropRefCurrentWidth = dropRefCurrentWidth
            updated_pdf_page.dropRefCurrentHeight = dropRefCurrentHeight
            updated_pdf_page.pageOrigWidth = pageOrigWidth
            updated_pdf_page.pageOrigHeight = pageOrigHeight

            updatePDFPage(updated_pdf_page)
        }
    }

    function handleOnLoadSuccessPDFPage(page) {
        handleLoadInitialElements(page)
        const elements = document.getElementsByClassName("react-pdf__Page__textContent");
        while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }
    }

    return(
        <Box ref={pdfEditorRef} display="flex" sx={{bgcolor: '#EEEBF8', height:'100%'}} justifyContent="center" alignItems="center">
            {documentDetails?.status === "COMPLETED" ?
                <Box sx={{height:'90vh', width:'100%', alignContent:'center', textAlign:'center', margin:'auto'}}>
                    <Card sx={{ maxWidth: 700, margin:'auto' }}>
                        {/* <CardMedia  
                            sx={{ height: 150 }}
                            image={BannerIcon}
                            // title="esign-banner"
                        /> */}
                        <CardContent sx={{pt: 7}}>
                                <img src={Verified} style={{width: 100, height: 100, color:'green'}}/>
                        </CardContent>
                        <CardContent sx={{mb: 7, p: 5, pt: 0}}>
                            <Typography sx={{fontWeight:"bold", fontSize: 18}}>
                                The document has been successfully authenticated and delivered to your registered email address.
                            </Typography>
                            <br/>
                            {/* <Typography sx={{fontSize: 14}}>
                                Please note: SuperSign does not retain a copy of any document.
                            </Typography> */}
                            <Alert severity="info">
                            SuperSign does not retain copies of your documents, ensuring privacy and security.
                            </Alert>
                        
                        </CardContent>
                    </Card>
                </Box>

            :
                <Document
                file={decryptedFile} 
                loading="Loading template..."
                onLoadSuccess={handleOnLoadDocumentSuccess}
            >
                {pdfPages.map((page, i) => {
                return(
                    <>
                        <p style={{color: "#5e5d5d", fontSize: 12}}>
                        Page {page?.pageNo} / {pdfPages?.length}
                    </p>
                        <Page
                            className={"pdf_page_custom"}
                            pageNumber={page.pageNo} // Render each page
                            width={page.adjustedPDFWidth} 
                            scale={1}
                            onLoadSuccess={handleOnLoadSuccessPDFPage}
                        >
                            {documentDetails?.status !== "COMPLETED" &&
                                <>
                                    {viewingAsElements.map((element)=>(
                                        <>
                                            {(element.pageNumber === page.pageNo) && 
                                                <Box sx={{ position: 'absolute', left: element.x_axis, top: element.y_axis}}>
                                                    <Box className={'editor-box'} style={{ backgroundColor: element?.assign_color}} axis={'both'} width={element?.width} height={element?.height}>
                                                        {element.type === 'textfield' && 
                                                            <textarea
                                                            style={{
                                                                width: '100%',
                                                                border: 'none',
                                                                resize: 'none',
                                                                outline: 'none',
                                                                backgroundColor: "#dfdbf37e",
                                                                borderRadius: "5px",
                                                                padding: 0,
                                                                color: "#1a1a1a",
                                                                fontSize: element?.fontSize,
                                                                fontFamily: 'Helvetica',
                                                                lineHeight: 0.95,
                                                            }}
                                                            autoFocus disabled
                                                            value={element.value}
                                                            placeholder='Type here'
                                                        />
                                                        }
                                                        {element.type === 'esignature' && <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={element.value} />}
                                                        {element.type === 'date' && 
                                                            <Typography sx={{ textAlign: "start", fontSize: element?.fontSize, whiteSpace: 'nowrap', overflow: 'hidden', 
                                                            textOverflow: 'ellipsis', width: '100%', lineHeight: 0.95, letterSpacing: '0px' }}>{element.value}</Typography>
                                                        }
                                                        {element.type === 'qrcode' && 
                                                        <img src={element.value} style={{ width: '100%', height: '100%', objectFit: 'contain', padding: 0, margin: 0 }}  />}
                                                        {element.type === 'signatory' && 
                                                            <Box  sx={{display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: 'center', width: "100%", height: "100%"}}>
                                                                <Typography align='center' noWrap sx={{textAlign: 'center',whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', margin:'auto'}}>{!element.value ? "Signatory" : element.value}</Typography>
                                                            </Box>
                                                        }
                                                        {/* {element.type === 'signatory' && 
                                                            <Box sx={{display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: 'center', width: "100%", height: "100%"}}>
                                                                <Typography align='center' noWrap sx={{textAlign: 'center',whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', margin:'auto'}}>{!element.value ? "Signatory" : element.value}</Typography>
                                                            </Box>
                                                        } */}
                                                        {element.type === 'fillable_textfield' && 
                                                            <Box sx={{display:'flex', p: 0, m: 0, alignItems:'center', justifyContent:'center', width:'100%', height:'100%',whiteSpace: 'nowrap',overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                                <Typography sx={{fontSize: element.fontSize, height: 'auto'}}>Textfield</Typography> 
                                                            </Box>
                                                        }
                                                        {element.type === 'fillable_date' && 
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DateField
                                                                fullWidth
                                                                format={element.date_format}
                                                                readOnly
                                                                sx={{
                                                                    '.MuiInputBase-input': { fontSize: element.fontSize, padding: 0, height: 'auto' },
                                                                    width: element.width,
                                                                    height: element.height
                                                                }}
                                                                />
                                                            </LocalizationProvider>
                                                        }
                                                    </Box>
                                                </Box>
                                            }
                                        </>
                                    ))}
                                </>
                            }
                        </Page>
                    </>
                    
                )
            })}
                </Document>
            }
        </Box>
    )
}