import { IconButton } from "@mui/material"


export default function CustomIconButton ({content, variant, handleButton, color, index, props, onClick} ) {


    return(
        <IconButton color={color} variant={variant} {...props} onClick={onClick}>
            {content}
        </IconButton>
    )
}