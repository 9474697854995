import { AppBar, Avatar, Box, Breadcrumbs, Button, Chip, FormControl, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, Select, Skeleton, Stack, Toolbar, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CustomTypography from "../../../core/components/ui/CustomTypography";
import useTokenManagement from "../hooks/useTokenManagement";
import useAuthentication from "../../profile-plugin/hooks/useAuthentication";
import React, { useEffect, useState } from "react";
import CallMadeIcon from '@mui/icons-material/CallMade';
import ImageIcon from '@mui/icons-material/Image';
import TransferTokenScreen from "../../profile-plugin/pages/TransferTokenScreen";
import useWorkspaceManagement from "../../workspace-plugin/hooks/useWorkspaceManagement";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from "notistack";
import TokenTransactionsComponent from "../components/token-transactions.component";


export default function TokenScreen() {

    const { enqueueSnackbar } = useSnackbar();

    const { getWalletBalance, isFetchingWalletBalance, walletBalance } = useTokenManagement();
    const { getWorkspaceList, workspaceList, isFetchingWorkspaceList } = useWorkspaceManagement();
    const { isFetchingProfileDetails, viewProfile, profileData } = useAuthentication();
    const { getTokenTransactions, tokenTransactions, isFetchingTransactions } = useTokenManagement();


    const [openTranferToken, setOpenTransferToken] = useState(false);
    const [isEmail, setIsEmail] = useState(JSON.parse(localStorage.getItem('isEmail')));
    const [isWorkspace, setIsWorkspace] = useState(JSON.parse(localStorage.getItem('isWorkspace')));
    const [menuSelected, setMenuSelected] = useState(localStorage.getItem('menuSelected'));
    const [wallet_id, setWalletId] = useState(localStorage.getItem('wallet_id'));
    const [profile_wallet, setProfileWallet] = useState(localStorage.getItem('profile_wallet'));
    const [name, setName] = useState("");


    useEffect(() => {
        const syncLocalStorage = () => {
            const storedIsEmail = JSON.parse(localStorage.getItem('isEmail'));
            const storedIsWorkspace = JSON.parse(localStorage.getItem('isWorkspace'));
            const storedMenuSelected = localStorage.getItem('menuSelected');
            const storedWalletId = localStorage.getItem('wallet_id');
            const storedProfileWallet = localStorage.getItem('profile_wallet');

            if (storedIsEmail !== isEmail) setIsEmail(storedIsEmail);
            if (storedIsWorkspace !== isWorkspace) setIsWorkspace(storedIsWorkspace);
            if (storedMenuSelected !== menuSelected) setMenuSelected(storedMenuSelected);
            if (storedWalletId !== wallet_id) setWalletId(storedWalletId);
            if (storedProfileWallet !== profile_wallet) setProfileWallet(storedProfileWallet);
        };

        const intervalId = setInterval(syncLocalStorage, 500);

        return () => clearInterval(intervalId);
    }, [isEmail, isWorkspace, menuSelected, wallet_id]);

    useEffect(() => { viewProfile(); }, []);

    useEffect(() => {
        const parameters = { page: 1, limit: 10, name: " " };
        getWorkspaceList(parameters);
    }, []);


    useEffect(() => {
        let parameters = {};

        if (isEmail) {
            if (profileData) {
                parameters = { "walletId": profileData && profileData?.profile.walletId }
                getWalletBalance(parameters);
                getTokenTransactions(parameters);
            }
        } else if (isWorkspace) {
            parameters.walletId = wallet_id ? wallet_id : null;
            getWalletBalance(parameters);
            getTokenTransactions(parameters);
        }

    }, [isEmail, isWorkspace, wallet_id, profileData]);

    const formatBalance = () => {
        if (walletBalance === 0 || walletBalance === 1) {
            return `${walletBalance.toLocaleString()} Token`;
        }
        return `${walletBalance.toLocaleString()} Tokens`;
    };
    
    const formatWalletId = (walletId) => {
        if (!walletId || walletId.length <= 10) {
            return walletId;
        }
        return `${walletId.slice(0, 7)}...${walletId.slice(-5)}`;
    };


    const handleCloseTransferTokenDialog = () => {
        setOpenTransferToken(false);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                enqueueSnackbar("Successfully copied wallet address", { variant: "success", autoHideDuration: 2000 });
            })
            .catch(err => {
                console.error("Failed to copy text: ", err);
            });
    }

    const getInitial = (selectedValue) => {
        if (typeof selectedValue === "string" && selectedValue?.includes("@")) {
            const username = selectedValue?.split("@")[0];
            return username.charAt(0).toUpperCase();
        }

        const initials = selectedValue?.match(/[A-Z]/g);
        return initials?.slice(0, 2).join("") || selectedValue?.charAt(0).toUpperCase() || "";
    };

    useEffect(() => {
        if (isEmail) {
            setName(menuSelected)
        } else if (isWorkspace)  {
            const selectedWorkspace = workspaceList?.data?.find( item => String(item?.workspace?.id) === menuSelected );

            if (selectedWorkspace) {
                setName(selectedWorkspace?.workspace?.name)
            } else {
                console.log("No matching workspace found for ID:", menuSelected);
            }
        }
    }, [isEmail, isWorkspace, menuSelected, workspaceList])

    console.log("isFetchingWalletBalancexxx", isFetchingWalletBalance)


    return (
        <Box sx={{ margin: "0px auto auto" }}>
            <Box sx={{ flexGrow: 1, width: '80%', margin: "0px auto auto", mb: 3 }}>
                <Grid container spacing={2}>
                    <Grid size={12} sx={{ textAlign: "start", mb: 0, pb: 0, display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
                        <CustomTypography
                            variant="h6"
                            text={"MY TOKEN"}
                            customStyles={{ mb: 0, textTransform: "uppercase" }}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{
                width: '80%', margin: 'auto', backgroundColor: "#fff", borderRadius: "1px", paddingBottom: '10px',
                border: "1px solid rgb(158 158 158 / 27%)", boxShadow: '0 2px 16px 0 #0000001a', mt: "0px", overflow: 'hidden !important'
            }}>

                <Box sx={{
                    margin: 'auto',
                    borderRadius: "1px",
                    boxShadow: "0 2px 16px 0 #0000001a",
                    padding: '0px',
                    overflow: 'hidden !important',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <List sx={{ width: '100%', bgcolor: 'background.paper', margin: 'auto', padding: '0px', mt: 1 }}>
                        <ListItem sx={{ textAlign: 'center', padding: '0px' }}>
                            <ListItemText primary={
                                <React.Fragment>
                                    {isFetchingProfileDetails ? (
                                        <Skeleton variant="rectangular" width="60%" height={30} sx={{ backgroundColor: "#eeebf8", margin: 'auto', marginBottom: 1 }} />
                                    ) : (
                                        <Box display="flex" alignItems="center" gap={1} sx={{ margin: 'auto', textAlign: 'center', justifyContent: 'center', alignSelf: 'center' }}>
                                            <Avatar
                                                sx={{
                                                    bgcolor: "#604cc336",
                                                    width: 32,
                                                    height: 32,
                                                    fontSize: 16,
                                                    color: "#535a61",
                                                }}
                                            >
                                                {getInitial(name)}
                                            </Avatar>
                                            <Typography sx={{ fontWeight: 'bold', borderRadius: 10 }}>{name}</Typography>
                                        </Box>
                                    )}
                                </React.Fragment>

                            } secondary={<React.Fragment>
                                {isFetchingProfileDetails ? (
                                    <Skeleton variant="rectangular" width="50%" height={20} sx={{ backgroundColor: "#eeebf8", margin: 'auto', pb: 1 }} />
                                ) : (
                                    <Box display="flex" alignItems="center" gap={1} sx={{ margin: 'auto', textAlign: 'center', justifyContent: 'center', alignSelf: 'center' }}>
                                        <Typography sx={{
                                            fontSize: '14px', color: '#535a61', textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden', lineHeight: '1.2rem'
                                        }}>
                                            {wallet_id ? formatWalletId(wallet_id) : formatWalletId(profile_wallet)}
                                        </Typography>
                                        <IconButton onClick={() => wallet_id ? copyToClipboard(wallet_id) : copyToClipboard(profile_wallet)}>
                                            <ContentCopyIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                )}
                            </React.Fragment>
                            } />
                        </ListItem>
                    </List>
                </Box>

                <Box sx={{ padding: "26px 50px", flexGrow: 1, margin: "0px auto auto" }}>
                    <Grid container spacing={2}>
                        <Grid size={{ xs: 12 }} sx={{ mb: 0, pb: 0, alignSelf: 'center', alignItems: 'center', alignContent: 'center', justifyContent: 'center', justifyItems: 'center' }}>

                            { isFetchingWalletBalance ?
                                <Skeleton variant="text" width={250} height={80} sx={{ backgroundColor: '#eeebf8', textAlign: 'center', mb: 0, borderRadius: 1 }} />
                            :
                            
                                <CustomTypography
                                    variant="h6"
                                    text={formatBalance()}
                                    // text={walletBalance === 0 || walletBalance === 1 ? `${walletBalance} Token` : `${walletBalance} Tokens`}
                                    customStyles={{
                                        mb: 0,
                                        textAlign: 'center',
                                        fontSize: 30,
                                        borderRadius: 1,
                                        fontWeight: 'bold',
                                        color: '#604CC3',
                                        width: 'auto',
                                    }}
                                />
                            }

                            <br />

                            {!isFetchingWalletBalance && isEmail &&
                                <Box>
                                    <IconButton size="medium" sx={{ backgroundColor: '#604cc336' }} onClick={() => setOpenTransferToken(true)} >
                                        <CallMadeIcon fontSize="small" sx={{ color: '#604CC3' }} />
                                    </IconButton>
                                    <Typography variant="body1" sx={{ mt: 0.5, color: '#604CC3', fontWeight: 'bold', fontSize: 12 }} >
                                        Send
                                    </Typography>
                                </Box>
                            }

                        </Grid>

                        <Grid size={{ xs: 12 }}>
                            <CustomTypography
                                variant="h6"
                                text={"Token Activity"}
                                customStyles={{
                                    mb: 0,
                                    textAlign: 'start',
                                    fontSize: 18,
                                    borderRadius: 1,
                                    fontWeight: 'bold',
                                    color: '#000',
                                    width: 'auto',
                                }}

                            />

                            <TokenTransactionsComponent tokenTransactions={tokenTransactions} isFetchingTransactions={isFetchingTransactions} />

                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {openTranferToken &&
                <TransferTokenScreen
                    openTranferToken={openTranferToken}
                    handleCloseTransferTokenDialog={handleCloseTransferTokenDialog}
                    workspaceList={workspaceList}
                    isFetchingWorkspaceList={isFetchingWorkspaceList}
                    walletBalance={walletBalance}
                    isFetchingWalletBalance={isFetchingWalletBalance}
                />
            }
        </Box>
    )
}
