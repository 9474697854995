import DocumentSharedComponent from "../components/document-shared.component";



export default function DocumentSharedScreen () {


    return(
        <>
        Shared here
        </>
    )
}