import routes from "../common/api-routes";
import {HttpAuthDelete, HttpAuthGet} from "../../../../core/services/common/HttpRequestBuilder";

const getNotification = () => {
    let parameters = null;
    let result = HttpAuthGet(routes.GET_NOTIFICATION, parameters);

    return result;
}

const readNotification = (parameters) => {
    let result = HttpAuthGet(routes.READ_NOTIFICATION, parameters);

    return result;
}

const deleteNotification = (parameters) => {
    let result = HttpAuthDelete(routes.DELETE_NOTIFICATION, parameters);

    return result;
}


const NotificationService = {
    getNotification,
    readNotification,
    deleteNotification
}

export default NotificationService