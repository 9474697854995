import routes from "../../common/api-routes";
import {HttpAuthGet, HttpAuthPost, HttpAuthPut, HttpAuthDelete} from "../../../../../core/services/common/HttpRequestBuilder";

const getProjectAPIKey = (parameters) => {
    let result = HttpAuthGet(routes.GET_PROJECT_API_KEY, parameters);

    return result;
}

const addProject = (payload) => {
    let result = HttpAuthPost(routes.ADD_PROJECT, payload);

    return result;
}

const getProjectDetails = (parameters) => {
    let result = HttpAuthGet(routes.GET_PROJECT_DETAILS, parameters);

    return result;
}

const changeProjectKeys = (payload) => {
    let parameters = null;
    let result = HttpAuthPut(routes.CHANGE_PROJECT_KEYS, parameters, payload);

    return result;
}

const deleteWorkspaceProject = (parameters) => {
    let result = HttpAuthDelete(routes.DELETE_WORKSPACE_PROJECT, parameters);

    return result;
}

const getProjectCredits = (parameters) => {
    let result = HttpAuthGet(routes.GET_PROJECT_CREDITS, parameters);

    return result;
}


const EnterpriseProjectManagementService = {
    getProjectAPIKey: getProjectAPIKey,
    addProject: addProject,
    getProjectDetails: getProjectDetails,
    changeProjectKeys: changeProjectKeys,
    deleteWorkspaceProject: deleteWorkspaceProject,
    getProjectCredits: getProjectCredits
}

export default EnterpriseProjectManagementService