import React, { useState, useEffect } from 'react';
import { Box, Button, Drawer, FormControl, MenuItem, Select, List, ListItem, ListItemIcon, ListItemText, Skeleton, Typography, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link, useLocation } from 'react-router-dom';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import useWorkspaceManagement from '../../../plugins/workspace-plugin/hooks/useWorkspaceManagement';
import ovcodeLogo from '../../../assets/logo/new-ovcode-logo.png';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CustomButton from "../ui/CustomButton";
import WorkspacesIcon from "./../../../assets/icons/menu.png";
import {useNavigate} from "react-router-dom";
import CreateWorkspaceComponent from "../../../plugins/workspace-plugin/components/workspace-create.component";
import useCreatedWorkspaceStore from "../../../plugins/workspace-plugin/zustand/useCreatedWorkspaceStore";
import useSelectedWorkspaceStore from "../../../plugins/workspace-plugin/zustand/useSelectedWorkspaceStore";
import SettingsIcon from '@mui/icons-material/Settings';
import useOrderManagement from '../../../plugins/token-plugin/hooks/useOrderManagement';
import useAuthentication from '../../../plugins/profile-plugin/hooks/useAuthentication';
import FeedbackICon from "../../../assets/icons/feedback.png";

const listItems = [
  { label: 'All', link: '/documents', icon: <FolderIcon /> },
  { label: 'Draft', link: '/documents?status=draft', icon: <FolderOpenIcon /> },
  { label: 'Ongoing', link: '/documents?status=ongoing', icon: <AccessTimeIcon /> },
  { label: 'Completed', link: '/documents?status=completed', icon: <TaskAltIcon /> },
  // { label: 'Shared', link: '/documents/shared', icon: <ShareIcon /> },
];




const DocumentDrawer = ({ user }) => {

  const navigate = useNavigate();
  const { createdWorkspaceData, setWorkspaceCreated, setCreatedWorkspaceData, isWorkspaceCreated } = useCreatedWorkspaceStore();
  const { getWorkspaceList, workspaceList, isFetchingWorkspaceList, createWorkspace, isCreatingWorkspace } = useWorkspaceManagement();
  const { pendingOrder, hasPending } = useOrderManagement();

  let orderId = localStorage.getItem('orderId');
  let profileWallet = localStorage.getItem('profile_wallet');

  const [menuSelected, setMenuSelected] = useState(null);
  const [openWorkspaceList, setOpenWorkspaceList] = useState(false);
  const [open, setOpen] = useState(false);

  const setSelectedWorkspace = useSelectedWorkspaceStore((state) => state.setSelectedWorkspace)
  const selectedWorkspace = useSelectedWorkspaceStore((state) => state.selectedWorkspace)

  const location = useLocation();
  const drawerWidth = 300;

  const { getProfile, profile } = useAuthentication()

  useEffect(() => {
    const parameters = { page: 1, limit: 10, name: " " };
    getWorkspaceList(parameters);
  }, [isWorkspaceCreated]);

  useEffect(() => {
    getProfile()
  }, [])

  useEffect(() => {
    if (!selectedWorkspace) return;

    const { id, name } = selectedWorkspace;

    localStorage.setItem("accountHeaderName", name);

    if (name?.includes('@')) {
      setMenuSelected(name);
    
      if (location?.pathname === "/dashboard") {
        navigate("/dashboard");
      }

    } else {
      setMenuSelected(id);

      if (location?.pathname === "/workspaces/view") {
        navigate(`/workspaces/view?workspaceId=${id}`);
      }
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    if (isWorkspaceCreated && createdWorkspaceData) {
      setSelectedWorkspace(createdWorkspaceData);
      setMenuSelected(createdWorkspaceData?.id);
      navigate("/workspaces/view?workspaceId=" + createdWorkspaceData?.id); 
      // setMenuSelected(createdWorkspaceData?.name);
    }
  }, [isWorkspaceCreated, createdWorkspaceData])


  const isDisabled = location.pathname.includes('/token/checkout') || location.pathname.includes('/place-order');
  const isDashboard = location.pathname === '/dashboard';

  const currentListItems = listItems;

  const filteredListItems = isDashboard
    ? currentListItems.filter(item => item.label !== 'Pending')
    : currentListItems;


  useEffect(() => {
    const storedSelection = localStorage.getItem('menuSelected');
    const storedIsWorkspace = localStorage.getItem('isWorkspace');
    const storedWalletId = localStorage.getItem('wallet_id');


    if (storedSelection) {
      setMenuSelected(storedSelection);
    }

    if (user?.email && !storedSelection) {
      localStorage.setItem('menuSelected', user.email);
      localStorage.setItem('isEmail', 'true');
      localStorage.setItem('isWorkspace', 'false');
      setMenuSelected(user.email);
    }

    if (storedIsWorkspace === 'true' && storedWalletId) {
      localStorage.setItem('wallet_id', storedWalletId);
    }
  }, [user]);




  const handleSelectChange = (event) => {
    const value = event.target.value;
    
    setMenuSelected(value); 
    localStorage.setItem('menuSelected', value);

    setWorkspaceCreated(false);
    setCreatedWorkspaceData(null);

    let parameters;

    if (value === user?.email) {
      console.log("user herexxx", profile)
      localStorage.setItem('isEmail', 'true');
      localStorage.setItem('isWorkspace', 'false');
      localStorage.removeItem('wallet_id');
      let acc_profile = {
        name: profile.profile.email,
        logo: profile.profile.photo,
        id: profile.profile.userId
      }
      localStorage.setItem("accountHeaderName", acc_profile?.name);
      setSelectedWorkspace(acc_profile)
      setWalletId(profileWallet);
      parameters = { 'walletId': profileWallet };

      navigate("/dashboard");

    } else {
      const selectedWorkspace = workspaceList?.data.find(workspace => workspace?.workspace.id === value);
      if (selectedWorkspace) {
        console.log("selectedWorkspacexx", selectedWorkspace)
        let workspace = {
          name: selectedWorkspace.workspace.name,
          logo: selectedWorkspace.workspace.logo,
          id: selectedWorkspace.workspace.id,
          wallet_id: selectedWorkspace.workspace.wallet_id,
        }
        localStorage.setItem("accountHeaderName", workspace?.name);
        setSelectedWorkspace(workspace)
        localStorage.setItem('isEmail', 'false');
        localStorage.setItem('isWorkspace', 'true');
        localStorage.setItem('wallet_id', selectedWorkspace.workspace.wallet_id);
        setWalletId(selectedWorkspace.workspace.wallet_id);
        parameters = { 'walletId': selectedWorkspace.workspace.wallet_id };

        navigate(`/workspaces/view?workspaceId=${selectedWorkspace.workspace.id}`);
      }
    }

    if (parameters) {
      pendingOrder(parameters);
    }

  };

  const [walletId, setWalletId] = useState(null);

  useEffect(() => {
    if(walletId || profileWallet){
      let parameters = { walletId: walletId || profileWallet }; 
      pendingOrder(parameters); 
    }
  }, [walletId, profileWallet])

  const handleClickToken = () => {
    if (hasPending) {
      console.log("order_idxxx", orderId);
     navigate(`/token?orderId=${orderId}`);
    } else {
      navigate("/token");
    }
  };

  const renderWorkspaceList = () => {
    if (isFetchingWorkspaceList) {
      return <MenuItem disabled><Skeleton variant="text" sx={{ fontSize: '1.5rem', backgroundColor: '#eeebf8' }} /></MenuItem>;
    }

    if (workspaceList?.length === 0) {
      return <MenuItem disabled>No workspaces available</MenuItem>;
    }

    const workspaceData = workspaceList.data;

    return workspaceData?.map(workspace => (
      <MenuItem key={workspace?.workspace.id} value={workspace?.workspace.id}>{workspace?.workspace.name}</MenuItem>
    ));
  };

  const handleManageWorkspace = () => {
    navigate("/workspaces");
    setOpenWorkspaceList(false);
  }

  const handleCreateWorkspace = () => {
    setOpen(true);
    setOpenWorkspaceList(false);
  }



  return (
    <>
      <Drawer
        variant="permanent"
        anchor="left"
        PaperProps={{ style: { width: drawerWidth, marginTop: 75, border: 'none' } }}
      >
        <div style={{ backgroundColor: '#eeebf8', paddingTop: 20, paddingBottom: 20, padding: 10}}>
            <FormControl>
              <Select
                open={openWorkspaceList}
                onOpen={() => setOpenWorkspaceList(true)}
                onClose={() => setOpenWorkspaceList(false)}
                value={menuSelected}
                onChange={handleSelectChange}
                sx={{
                  bgcolor: 'white',
                  height: '40px',
                  padding: '0 8px',
                  '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                    width: 200,
                    height: '100%',
                  }
                }}
              >
                <MenuItem value={user?.email}>{user?.email}</MenuItem>
                {renderWorkspaceList()}

              <Divider sx={{ mt: (workspaceList?.total === 0) ? "16px !important" : {} }} />

              <Box sx={{ textAlign: "center", mt: (workspaceList?.total === 0) ? 2.2 : 2.2, mb: 1.5 }}>
                <CustomButton
                  variant="outlined"
                  startIcon={<img alt="Manage Workspaces" src={WorkspacesIcon} style={{ height: "15px", width: "15px" }} />}
                  label="Manage Workspaces"
                  customStyles={{ width: "90%", padding: "18px 16px" }}
                  onClick={handleManageWorkspace}
                />
              </Box>

              <Box sx={{ textAlign: "center", mb: 1.5 }}>
                <CustomButton
                  variant="outlined"
                  startIcon={<AddIcon sx={{ height: "18px", width: "18px", color: "#604CC3" }} />}
                  label="Create Workspace"
                  customStyles={{ width: "90%", padding: "18px 16px" }}
                  onClick={handleCreateWorkspace}
                />
              </Box>
            </Select>
          </FormControl>
        </div>

        <List sx={{ margin: '10px 20px 10px 20px' }}>
          <>
            {location.pathname === "/templates" ? (
              <Button fullWidth startIcon={<AddIcon />} variant='contained' sx={{ bgcolor: '#604CC3', textTransform: 'none', height: 40 }}>
                <ListItemText primary="Create Template" />
              </Button>
            ) : (
              <Button component={Link} to="/documents/create" fullWidth startIcon={<AddIcon />} variant='contained' sx={{ bgcolor: '#604CC3', textTransform: 'none', height: 40 }}>
                <ListItemText primary="Create Document" />
              </Button>
            )}
          </>


          {filteredListItems.map((item, index) => {
            const isSelected = location.pathname === item.link && item.label !== 'All';
            const handleClick = (e) => {
              if (item.onClick) {
                e.preventDefault();
                item.onClick();
              }
            };

            return (
              <ListItem
                button
                key={index}
                component={Link}
                to={item.link}
                selected={isSelected}
                onClick={handleClick}
                tabIndex={0}
                sx={{
                  mt: 2,
                  '&:hover': {
                    bgcolor: '#F5F5F5', // Hover effect
                  },
                  '&:focus': {
                    bgcolor: '#F5F5F5', // Hover effect
                  },
                }}
              >
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} sx={{ ml: 1 }} />
              </ListItem>
            );
          })}
        </List>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ flexGrow: 1 }} />
        <Button
          variant="text"
          startIcon={<img alt="" src={FeedbackICon} style={{height: 17}} />}
          sx={{
            textTransform: 'none',
            color: '#604cc3',
            fontSize: 15,
            fontWeight: 'normal',
            textAlign: 'start',
            margin: '10px 20px 10px 35px',
            justifyContent: 'start',
            pr: 4
          }}
          onClick={() => window.open("https://forms.office.com/r/8CjQrbBrwL", "_blank")}
        >
          &nbsp;Feedback & Suggestions
        </Button>
        
        <Button
          variant="text"
          startIcon={<ShoppingCartIcon sx={{ color: '#604cc3' }} />}
          sx={{
            textTransform: 'none',
            color: '#604cc3',
            fontSize: 15,
            fontWeight: 'normal',
            textAlign: 'start',
            margin: '10px 20px 10px 35px',
            justifyContent: 'start',
            pr: 4
          }}
          onClick={handleClickToken}
        >
          &nbsp;Buy Token
        </Button>

        <Typography sx={{ textAlign: 'center', fontSize: 16, display: 'flex', alignItems: 'center', margin: 'auto', pb: 2, mt: 5 }}>
          Powered by &nbsp;<img src={ovcodeLogo} style={{ height: 40 }} />
        </Typography>
        <Box sx={{ flexGrow: 1 }} />

      </Drawer>

      {open &&
        <CreateWorkspaceComponent
          open={open}
          setOpen={setOpen}
          createWorkspace={createWorkspace}
          isCreatingWorkspace={isCreatingWorkspace}
        />
      }
    </>
  )
}

export default DocumentDrawer;
