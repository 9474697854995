import {create} from 'zustand';

// Create the Zustand store
const useSignatoryEmailStore = create((set) => ({

  personName: [],
  setPersonName: (newPersonName) => set({ personName: newPersonName }),
  addPersonName: (name) =>
    set((state) => ({ personName: [...state.personName, name] })),
  clearPersonNames: () => set({ personName: [] }),
}));

export default useSignatoryEmailStore;
