
const today = new Date();
const options = { year: 'numeric', month: 'long', day: 'numeric' };
const formattedDate = today.toLocaleDateString('en-US', options);

const signatoryElements = [
    {
        type: 'signatory',
        value: '',
        height: 100,
        width: 150,
        x_axis: 0,
        y_axis: 0,
        pageNumber: 0,
        element_type: 'signatory',
        assign_color: ""
    },
    {
        type: 'fillable_textfield',
        value: '',
        fontSize: 18,
        width: 200,
        height: 18,
        x_axis: 0,
        y_axis: 0,
        pageNumber: 0,
        element_type: 'textfield',
        assign_color: "",
    },
    {
        type: 'fillable_date',
        value: formattedDate,
        // width: 150,
        // height: 18,
        fontSize: 18,
        x_axis: 0,
        y_axis: 0,
        pageNumber: 0,
        element_type: 'date',
        assign_color: "",
        date_format: "MM/dd/yyyy"
    },
]

export default signatoryElements