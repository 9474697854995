import React, { useEffect, useRef, useState } from 'react';
import { Box, Skeleton, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import useDashboard from '../hooks/useDashboard';
import Bargraph from '../../../assets/icons/bar-graph.png'

export default function DashboardMonthlyRecapComponent() {
    const { getAnalyticsCount, analyticsCount, isFetchingAnalyticsCount } = useDashboard();
    const currentYear = new Date().getFullYear();

    const [isEmail, setIsEmail] = useState(JSON.parse(localStorage.getItem('isEmail')));
    const [isWorkspace, setIsWorkspace] = useState(JSON.parse(localStorage.getItem('isWorkspace')));
    const [menuSelected, setMenuSelected] = useState(localStorage.getItem('menuSelected'));

    const [chartData, setChartData] = useState([]);

    // Ref to track previous state values and avoid infinite loop
    const prevIsEmail = useRef(isEmail);
    const prevIsWorkspace = useRef(isWorkspace);
    const prevMenuSelected = useRef(menuSelected);

    useEffect(() => {
        const syncLocalStorage = () => {
            const storedIsEmail = JSON.parse(localStorage.getItem('isEmail'));
            const storedIsWorkspace = JSON.parse(localStorage.getItem('isWorkspace'));
            const storedMenuSelected = localStorage.getItem('menuSelected');

            // Check if stored values are different from previous ones, update state if necessary
            if (storedIsEmail !== prevIsEmail.current) {
                setIsEmail(storedIsEmail);
                prevIsEmail.current = storedIsEmail;
            }

            if (storedIsWorkspace !== prevIsWorkspace.current) {
                setIsWorkspace(storedIsWorkspace);
                prevIsWorkspace.current = storedIsWorkspace;
            }

            if (storedMenuSelected !== prevMenuSelected.current) {
                setMenuSelected(storedMenuSelected);
                prevMenuSelected.current = storedMenuSelected;
            }
        };

        // Set interval to sync localStorage every 500ms
        const intervalId = setInterval(syncLocalStorage, 500);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);  // Empty dependency array to run once on mount

    useEffect(() => {
        if (currentYear && (isEmail || isWorkspace)) { 
            let parameters = {};
            
            if (isEmail) {
                parameters = { year: currentYear };
            } else if (isWorkspace) {
                parameters = {
                    workspace_id: menuSelected || null, 
                    year: currentYear
                };
            }
    
            getAnalyticsCount(parameters);
        }
    }, [isEmail, isWorkspace, menuSelected, currentYear]);

    const transformApiData = (data) => {
        const aggregatedData = {};

        data.forEach((item) => {
            const { month, count, status } = item;

            if (!aggregatedData[month]) {
                aggregatedData[month] = {};
            }

            if (!aggregatedData[month][status]) {
                aggregatedData[month][status] = 0;
            }

            aggregatedData[month][status] += count;
        });

        const transformedData = [];
        for (const month in aggregatedData) {
            let totalCount = 0;
            let statusDetails = [];

            for (const status in aggregatedData[month]) {
                totalCount += aggregatedData[month][status];
                statusDetails.push({
                    status,
                    count: aggregatedData[month][status],
                });
            }

            transformedData.push({
                month,
                count: totalCount,
                statusDetails,
            });
        }

        return transformedData;
    };

    // Custom tooltip to display status-wise count for the month with color coding
    const customTooltip = ({ payload, label }) => {
        if (!payload || payload.length === 0) return null;

        const monthData = payload[0].payload;  // Get the data of the month from the payload

        // Define color mapping for statuses
        const statusColors = {
            ONGOING: '#FFA500',
            DRAFT: '#604CC3',
            EXPIRED: '#FF0000',
            DECLINE: '#830221',
            COMPLETED: '#008000',
        };

        return (
            <Box sx={{ backgroundColor: 'white', padding: '8px', borderRadius: '4px', boxShadow: 2 }}>
                <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>{label}</Typography>
                {monthData.statusDetails.map((item, index) => {
                    const statusColor = statusColors[item.status] || '#000000';
                    return (
                        <Typography key={index} variant="body2" sx={{ color: statusColor, textAlign: 'start' }}>
                            {item.status}: {item.count}
                        </Typography>
                    );
                })}
                <Typography variant="body2" sx={{ fontWeight: 'bold', marginTop: '8px' }}>
                    Total : {monthData.count}
                </Typography>
            </Box>
        );
    };

    console.log("analyticsCounthere", analyticsCount)

    useEffect(() => {
        if (analyticsCount) {
            const transformedData = transformApiData(analyticsCount);
            setChartData(transformedData);
        }
    }, [analyticsCount]);

    // Check if analyticsCount is empty, undefined, null, or length is zero
    const isNoData = !analyticsCount || analyticsCount.length === 0;

    return (
        <Box sx={{ backgroundColor: "#fff", mt: "20px", padding: "24px", borderRadius: "8px", height: '53vh' }}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography sx={{ color: "#58565f", fontSize: 18, mb: 3, fontWeight: 600 }}>
                        Monthly Recap Report
                    </Typography>
                </Grid>
            </Grid>

            {isFetchingAnalyticsCount ? (
                <Skeleton variant="rectangular" width="100%" height={400} sx={{ borderRadius: "8px", backgroundColor: '#eeebf8' }} />
            ) : isNoData ? (
                <Box sx={{display:'grid', justifyContent:'center'}}>
                    <img src={Bargraph} style={{height: 300, width: 300}}/>
                    <br/>
                    <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold', color: '#58565f', fontSize: 12 }}>
                        No Available Data to Display
                    </Typography>
                </Box>
            ) : (
                <ResponsiveContainer width="100%" height={400} style={{ paddingTop: "12px" }}>
                    <LineChart
                        data={chartData}
                        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip content={customTooltip} />
                        <Line type="monotone" dataKey="count" stroke="#82ca9d" />
                    </LineChart>
                </ResponsiveContainer>
            )}
        </Box>
    );
}
