import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import CustomIconButton from "../../../../../core/components/ui/CustomIconButton";
import superSignLogo from "../../../../../assets/icons/supersign-logo.png"
import CustomButton from "../../../../../core/components/ui/CustomButton";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import DocumentPreviewComponent from "./document-preview.component";

export default function DocumentEditorAppbarComponent ({handleSaveDocumentAsDraft, isSavingToDraft, documentById, decryptedFile}) {

  const navigate = useNavigate();
  const storedAccountHeaderName = localStorage.getItem("accountHeaderName")
  

  const [openPreview, setOpenPreview] = useState(false)
  
  const handleOpenPreview = () => {
    setOpenPreview(true)
  }

  const handleClosePreview = () => {
    setOpenPreview(false)
  }

  const handleShareDocument = () => {
    alert('Feature not available as of the moment.')
  }

  console.log('sssssssssssssssss', documentById)

  const status = documentById?.status === "COMPLETED" || documentById?.status === "ONGOING";

    return(
      <>
        <AppBar  position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor:'white' }}>
          <Toolbar>
          <CustomIconButton content={<img alt="" src={superSignLogo} onClick={() => navigate('/')} style={{height: 50}} />} color={'inherit'} style={`mr: '2'`}/>
          <Box sx={{flexGrow:1}}/>

          <Typography sx={{fontWeight: "bold", color: "rgba(0, 0, 0, 0.87)", fontSize: 24, mr: -14}}>
            {storedAccountHeaderName?.includes("@") ? storedAccountHeaderName.split("@")[0] : storedAccountHeaderName}
          </Typography>
          
          <Box sx={{ flexGrow: 1 }} />

            {/* <CustomButton label={'Share'} onClick={()=> handleShareDocument()} variant={'contained'} sx={{fontSize: 16, mr: 2,}}/> */}
            <CustomButton label={'Preview'} onClick={()=>handleOpenPreview()} variant={'outlined'} sx={{fontSize: 16, mr: 2, color: "#604CC3", border: '.5px solid #604CC3'}}/>
            <CustomButton disabled={status} onClick={()=>handleSaveDocumentAsDraft()} label={'Close'} variant={'outlined'} sx={{fontSize: 16, color: "#604CC3", border: '.5px solid #604CC3'}}/>
            {/* <CustomButton onClick={()=>window.location.href='/documents'} label={ 'Close'} variant={'contained'} sx={{fontSize: 16, ml: 2, bgcolor:'#fff', color: '#604CC3'}}/> */}
          </Toolbar>
        </AppBar>
        <DocumentPreviewComponent decryptedFile={decryptedFile} documentId={documentById?.id} openPreview={openPreview} handleClosePreview={handleClosePreview}/>
      </>
    )
}