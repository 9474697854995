import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CustomTypography from "../../../core/components/ui/CustomTypography";
import ProfileComponent from "../components/profile-component";
import { useEffect, useState } from "react";
import { auth } from '../../../firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import useAuthentication from "../hooks/useAuthentication";
import useWorkspaceManagement from "../../workspace-plugin/hooks/useWorkspaceManagement";


export default function ProfileScreen () {

    const { viewProfile, profileData, isFetchingProfileDetails } = useAuthentication();
  const { getWorkspaceList, workspaceList, isFetchingWorkspaceList } = useWorkspaceManagement();


    const [isPhotoUpdated, setPhotoUpdated] = useState(false);

    useEffect(() => { viewProfile();  },[])

    useEffect(() => {
        if(isPhotoUpdated){
            viewProfile();
        }
    },[isPhotoUpdated])

      useEffect(() => {
        const parameters = { page: 1, limit: 10, name: " " };
        getWorkspaceList(parameters);
      }, []);
    


    return(
        <Box sx={{backgroundColor: "#fff", borderRadius: "6px", padding: "26px 50px", paddingBottom: '10px',
            border: "1px solid rgb(158 158 158 / 27%)",
            boxShadow: "0px 3px 6px -2px rgb(0 0 0 / 0%), 0px 5px 8px 0px rgb(0 0 0 / 0%), 0px 2px 12px 0px rgb(0 0 0 / 0%)",
            mt: "1px", overflow: 'hidden !important'

        }}>
           <Box sx={{flexGrow: 1, margin: "0px auto auto"}}>
                <Grid container spacing={2}>
                    <Grid size={{xs: 12}} sx={{textAlign: "start", mb: 0, pb: 0}}>
                        <CustomTypography 
                            variant="h6" 
                            text={"Profile"}
                            customStyles={{mb: 0}} 
                        />
                    </Grid>

                    <Grid size={{xs: 12}} sx={{textAlign: "start", mt: -3, pt: 0}}>
                    <CustomTypography 
                            variant="subtitle2" 
                            text={"Manage your profile, explore workspaces, and access helpful footer links."}
                            customStyles={{mt: 0}} 
                        />
                    </Grid>
                </Grid>
                <ProfileComponent 
                    profile={profileData} 
                    isFetchingProfileDetails={isFetchingProfileDetails} 
                    viewProfile={viewProfile} 
                    setPhotoUpdated={setPhotoUpdated} 
                    workspaceList={workspaceList}
                    isFetchingWorkspaceList={isFetchingWorkspaceList}
                />
            </Box>
        </Box>
    )
}