const DOCUMENT_API_ENDPOINT =  process.env.REACT_APP_DOCUMENT_SIGNING_API_ENDPOINT;

export default {
   
    SAVE_DOCUMENT:                                                 DOCUMENT_API_ENDPOINT + "/document/save",
    DOCUMENT_ELEMENT_PROPERTIES:                                   DOCUMENT_API_ENDPOINT + "/document/element/properties",
    SAVE_DOCUMENT_TO_DRAFT:                                        DOCUMENT_API_ENDPOINT + "/document/draft",
    SIGNATORY_LIST_BY_DOCUMENT_ID:                                 DOCUMENT_API_ENDPOINT + '/api/v1/signatory/list',  
    UPDATE_SIGNATORY:                                              DOCUMENT_API_ENDPOINT + '/api/v1/signatory/update',  
    DELETE_SIGNATORY:                                              DOCUMENT_API_ENDPOINT + '/api/v1/signatory/delete',  
    UPDATE_EMAIL:                                                  DOCUMENT_API_ENDPOINT + '/api/v1/signatory/ongoing/update-email', 

    // Signatory 
    SAVE_SIGNATORY:                                                DOCUMENT_API_ENDPOINT + '/api/v1/signee/save',
    SIGNATORY_LIST:                                                DOCUMENT_API_ENDPOINT + '/api/v1/signee/history',  
    
    DOCUMENT_AUTOMATION_RECORD:                                    DOCUMENT_API_ENDPOINT + '/api/v1/document/reminder/records',  
}