import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField } from "@mui/material";
import useElements from "../../zustand/useElements";
import useSignatoryStore from "../../zustand/useSignatoryStore";
import DocumentEditorService from "../../services/document-editor-service";
import { useSnackbar } from "notistack";
import useSignatoryElementStore from "../../zustand/useSignatoryElementStore";
import { useEffect, useState } from "react";


export default function AddSignatoryDialogComponent (props) {

    const {openSignatoryDialog, handleCloseSignatory, setEmailSignatory, emailSignatory, element, label} = props;

    const {updateElement } = useElements();
    const { enqueueSnackbar } = useSnackbar();
    const addSignatory = useSignatoryStore((state) => state.addSignatory);
    const { signatoryEmailValue, setSignatoryEmailValue } = useSignatoryElementStore();
    const [isAddedSignatory, setIsAddedSignatory] = useState(false)

    const handleInputValue = (e) => {
        setEmailSignatory(e.target.value)
    };

    const handleAddSignatory = () => {
        let recently_added_signees = [emailSignatory]
        DocumentEditorService.saveSignatory(recently_added_signees).then((res) => {
            console.log('add signatory res aaaa', res)
            if(res.data.success){
                if (element.type === 'signatory') {
                    const updatedElement = {
                        ...element,
                        value: emailSignatory, // Set the new value
                    };
                    setIsAddedSignatory(true)
                    setSignatoryEmailValue(emailSignatory)
                    updateElement(updatedElement); // Call updateElement with the updated element
                    addSignatory(emailSignatory)
                    handleCloseSignatory()
                }

            }
        }).catch((error)=>{
            console.log('add signatory err', error)
            const errorMessage = error?.response?.data?.error || error.message;
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
            setIsAddedSignatory(false)
        })
        
    }

    useEffect(() => {
        if(isAddedSignatory){
        setSignatoryEmailValue(emailSignatory)
        }
    },[signatoryEmailValue, isAddedSignatory])

    return(
        <Dialog open={openSignatoryDialog} onClose={handleCloseSignatory} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 5}}}>
            <DialogTitle>{label}</DialogTitle>
            <Divider sx={{boxShadow:'0px 4px 10px rgba(0, 0, 0, 0.5);'}}/>
            <DialogContent>
                <TextField sx={{mt: 2, bgcolor:'#f5f5f5', 
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            border: 'none',
                        },
                    },}} onChange={handleInputValue} value={emailSignatory} fullWidth id="outlined-basic" label="Email" variant="outlined" />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseSignatory} variant="outlined" sx={{textTransform:'none', color: '#604CC3', borderColor:'#604CC3'}}>Cancel</Button>
                <Button onClick={handleAddSignatory} variant="contained" sx={{mr: 2, bgcolor: '#604CC3', textTransform:'none'}}>Add</Button>
            </DialogActions>
        </Dialog>
    )
}