import { useState } from "react";
import { useSnackbar } from "notistack";
import DashboardService from "../services/dashboard-service";


const useDashboard = () => {

    const [dashboardCount, setDashboardCount] = useState(null);
    const [isLoadingDashboardCount, setIsLoadingDashboardCount] = useState(false)   
    const [analyticsCount, setAnalyticsCount] = useState(null);
    const [isFetchingAnalyticsCount, setFetchingAnalyticsCount] = useState(false)

    const { enqueueSnackbar } = useSnackbar();

    

    const getDashboardCount = (parameters) => {
        setIsLoadingDashboardCount(true)
        DashboardService.getDashboardCount(parameters).then((res)=> {
            console.log('get dashboard count res', res.data.data)
            setIsLoadingDashboardCount(false)
            if(res){
                setDashboardCount(res?.data?.data)
            }
        }).catch((err)=>{
            console.log('get dashboard count err', err)
            setIsLoadingDashboardCount(false)
            const errorMessage = err?.response?.data?.error || err.message;
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }


    const getAnalyticsCount = (parameters) => {
        setFetchingAnalyticsCount(true)
        DashboardService.getAnalyticsCount(parameters).then((res)=> {
            console.log('getAnalytcisCount count res', res)
            setFetchingAnalyticsCount(false)
            if(res){
                setAnalyticsCount(res?.data.data)
            }
        }).catch((err)=>{
            console.log('getAnalytcisCount count err', err)
            setFetchingAnalyticsCount(false)
            const errorMessage = err?.response?.data?.error || err.message;
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    return { getDashboardCount, isLoadingDashboardCount, dashboardCount, getAnalyticsCount, analyticsCount, isFetchingAnalyticsCount }
}

export default useDashboard