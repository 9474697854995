import {useState} from "react";
import WorkspaceManagementService from "../services/api/workspace-management";
import EnterpriseProjectManagementService from "../services/api/enterprise-project-management";
import {useSnackbar} from "notistack";
import useWorkspaceStore from "../../workspace-plugin/zustand/useUpdateWorkspaceStore";
import useCreatedWorkspaceStore from "../../workspace-plugin/zustand/useCreatedWorkspaceStore";
import useUploadWorkspaceLogoStore from "../../workspace-plugin/zustand/useUploadWorkspaceLogoStore";
import useSelectedWorkspaceMemberStore from "../zustand/useSelectedWorkspaceMemberStore";

const useWorkspaceManagement = () => {

    const { enqueueSnackbar } = useSnackbar();
    const {setWorkspaceUpdated} = useWorkspaceStore();
    const {setCreatedWorkspaceData, setWorkspaceCreated} = useCreatedWorkspaceStore();
    const {setLogoSaved} = useUploadWorkspaceLogoStore();
    const {setWorkspaceOwner} = useSelectedWorkspaceMemberStore();

    const [isFetchingWorkspaceList, setFetchingWorkspaceList] = useState(false);
    const [workspaceList, setWorkspaceList] = useState([]);
    const [workspaceDetails, setWorkspaceDetails] = useState(null);
    const [workspaceRoles, setWorkspaceRoles] = useState([]);
    const [isCreatingWorkspace, setCreatingWorkspace] = useState(false);
    const [isEditingWorkspace, setEditingWorkspace] = useState(false);
    const [isFetchingWorkspaceDetails, setFetchingWorkspaceDetails] = useState(true);
    const [isLogoUploaded, setLogoUploaded] = useState(false);
    const [isUpdatingLogo, setUpdatingLogo] = useState(false);
    const [filteredWorkspaceRoles, setFilteredWorkspaceRoles] = useState([]);
    const [userWorkspaceRole, setUserWorkspaceRole] = useState(null);
    const [ownWorkspaceList, setOwnWorkspaceList] = useState([]);
    const [sharedWorkspaceList, setSharedWorkspaceList] = useState([]);

    
    const getWorkspaceList = (parameters) => {
        setFetchingWorkspaceList(true);
        setWorkspaceCreated(false);
        setWorkspaceUpdated(false);
        setCreatedWorkspaceData(null);

        WorkspaceManagementService.getWorkspaceList(parameters).then((res) => {
            if (res.data.success) {
                console.log("getWorkspaceList data xxxx", res);

                setFetchingWorkspaceList(false);
                setWorkspaceList(res.data.data);
                setLogoSaved(false);
            }

        }).catch((error) => {
            console.log("getWorkspaceList error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            setFetchingWorkspaceList(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
            setLogoSaved(false);
        })
    }

    const createWorkspace = (payload) => {
        setCreatingWorkspace(true);

        WorkspaceManagementService.createWorkspace(payload).then((res) => {
            if (res.data.success) {
                console.log("createWorkspace data xxxx", res);

                setWorkspaceCreated(true);
                setCreatingWorkspace(false);

                setCreatedWorkspaceData(res?.data?.data);

                enqueueSnackbar(`Successfully created ${res.data.data?.name} workspace`, {
                    variant: "success",
                    autoHideDuration: 2000
                })
            }

        }).catch((error) => {
            console.log("createWorkspace error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            setWorkspaceCreated(false);
            setCreatingWorkspace(false);
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    const viewWorkspace = (parameters) => {
        setFetchingWorkspaceDetails(true);
        setLogoUploaded(false);
        setWorkspaceUpdated(false);

        WorkspaceManagementService.viewWorkspace(parameters).then((res) => {
            if (res.data.success) {
                console.log("viewWorkspace data xxxx", res);

                setFetchingWorkspaceDetails(false);
                setWorkspaceDetails(res.data?.data);
                setWorkspaceOwner(res.data?.data);
            }

        }).catch((error) => {
            console.log("viewWorkspace error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;

            setWorkspaceOwner(null);
            setFetchingWorkspaceDetails(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    const editWorkspace = (payload) => {
        setEditingWorkspace(true);

        WorkspaceManagementService.editWorkspace(payload).then((res) => {
            if (res.data.success) {
                console.log("editWorkspace data xxxx", res);

                setWorkspaceUpdated(true);
                setEditingWorkspace(false);
                enqueueSnackbar(`Successfully updated ${res.data.data?.name} workspace`, {
                    variant: "success",
                    autoHideDuration: 2000
                })
            }

        }).catch((error) => {
            console.log("editWorkspace error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            setWorkspaceUpdated(false);
            setEditingWorkspace(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    const uploadWorkspaceLogo = (formData) => {
        setUpdatingLogo(true);

        WorkspaceManagementService.uploadWorkspaceLogo(formData).then((res) => {
            if (res.data.success) {
                console.log("uploadWorkspaceLogo data xxxx", res);

                setLogoUploaded(true);
                enqueueSnackbar(`Successfully uploaded logo`, {
                    variant: "success",
                    autoHideDuration: 2000
                })

                setUpdatingLogo(false);
                setLogoSaved(true);
            }

        }).catch((error) => {
            console.log("uploadWorkspaceLogo error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            setLogoUploaded(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
            setUpdatingLogo(false);
            setLogoSaved(false);
        })
    }

    const getWorkspaceRoles = () => {
        WorkspaceManagementService.getWorkspaceRoles().then((res) => {
            if (res.data.success) {
                console.log("getWorkspaceRoles data xxxxs", res);
                setWorkspaceRoles(res.data?.data);
            }

        }).catch((error) => {
            console.log("getWorkspaceRoles error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;

            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    const getWorkspaceRBACPermissions = (parameters) => {
        WorkspaceManagementService.getWorkspaceRBACPermissions(parameters).then((res) => {
            if (res.data.success) {
                console.log("getWorkspaceRBACPermissions data xxxx", res);

                setFilteredWorkspaceRoles(res?.data.data);
            }

        }).catch((error) => {
            console.log("getWorkspaceRBACPermissions error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            setFilteredWorkspaceRoles([]);
        })
    }

    const getUserWorkspaceRole = (parameters) => {
        WorkspaceManagementService.getUserWorkspaceRole(parameters).then((res) => {
            if (res.data.success) {
                console.log("getUserWorkspaceRole data xxxx", res);

                setUserWorkspaceRole(res.data.data);
            }

        }).catch((error) => {
            console.log("getUserWorkspaceRole error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    // const getOwnAndSharedWorkspaceList = (parameters) => {
    //     setFetchingWorkspaceList(true);
    //     setWorkspaceCreated(false);
    //     setWorkspaceUpdated(false);

    //     WorkspaceManagementService.getOwnAndSharedWorkspaceList(parameters).then((res) => {
    //         if (res.data.success) {
    //             console.log("getOwnAndSharedWorkspaceList data xxxx", res);

    //             setFetchingWorkspaceList(false);

    //             const own_workspaces = res?.data?.data?.data?.own;

    //             setOwnWorkspaceList(own_workspaces);
    //             setSharedWorkspaceList(res?.data?.data?.data?.shared);
    //             setLogoSaved(false);
    //         }

    //     }).catch((error) => {
    //         console.log("getOwnAndSharedWorkspaceList error xxxx", error);
    //         const errorMessage = error?.response?.data?.error || error.message;
            
    //         setFetchingWorkspaceList(false);
    //         enqueueSnackbar(errorMessage, {
    //             variant: "error",
    //             autoHideDuration: 2000
    //         })
    //         setLogoSaved(false);
    //     })
    // }

    const getOwnAndSharedWorkspaceList = (parameters) => {
        setFetchingWorkspaceList(true);
        setWorkspaceCreated(false);
        setWorkspaceUpdated(false);
    
        WorkspaceManagementService.getOwnAndSharedWorkspaceList(parameters)
            .then(async (res) => {
                if (res.data.success) {
                    console.log("getOwnAndSharedWorkspaceList data xxxx", res);
    
                    let own_workspaces = res?.data?.data?.data?.own || [];

                    setSharedWorkspaceList(res?.data?.data?.data?.shared || []);
                    setLogoSaved(false);
    
                    const workspacePromises = own_workspaces?.map(async (workspace) => {
                        if (workspace?.workspace?.has_ovcode_project) {
                            try {
                                const apiKeyResponse = await EnterpriseProjectManagementService.getProjectDetails({
                                    workspace_id: workspace?.workspace.id
                                });
                               
                                if (apiKeyResponse?.data.success) {
                                    return {
                                        ...workspace,
                                        project_id: apiKeyResponse?.data?.data.id
                                    }
                                } 

                            } catch (error) {
                                console.log("getProjectAPIKey error xxxx", error);
                            }
                        }

                        return workspace;
                    })
    
                    const updatedWorkspaces = await Promise.all(workspacePromises);

                    setOwnWorkspaceList(updatedWorkspaces);
                    setFetchingWorkspaceList(false);
                }
            }).catch((error) => {
                console.log("getOwnAndSharedWorkspaceList error xxxx", error);
                const errorMessage = error?.response?.data?.error || error.message;
                
                setFetchingWorkspaceList(false);
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                    autoHideDuration: 2000
                })
                setLogoSaved(false);
            })
    }
    

    return {isFetchingWorkspaceList, workspaceList, workspaceDetails, workspaceRoles, isCreatingWorkspace, isEditingWorkspace, isFetchingWorkspaceDetails,
        isLogoUploaded, getWorkspaceList, createWorkspace, viewWorkspace, editWorkspace, uploadWorkspaceLogo, getWorkspaceRoles, isUpdatingLogo, getWorkspaceRBACPermissions, filteredWorkspaceRoles,
        getUserWorkspaceRole, getOwnAndSharedWorkspaceList, userWorkspaceRole, ownWorkspaceList, sharedWorkspaceList,
    }

}

export default useWorkspaceManagement
