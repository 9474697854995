import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Button, List, ListItem, ListItemAvatar, Avatar, ListItemText, Box, Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import useOrderManagement from '../hooks/useOrderManagement';
import CustomTypography from '../../../core/components/ui/CustomTypography';
import tokenIcon from '../../../assets/icons/token.png';
import TokenManagementService from '../services/api/token-management';
import PaymentIcon from '@mui/icons-material/Payment';
import {ThreeDots} from "react-loader-spinner";


export default function PlaceOderComponent() {
    // const items = new URLSearchParams(window.location.search).get("orderData");
    const orderId = new URLSearchParams(window.location.search).get("orderId");
    // const parsedItems = items ? JSON.parse(atob(items)) : [];


    const { viewOrder, orderDataArray, payOrder, isPaying } = useOrderManagement();
    
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(orderId){
            let parameters = {"order_id" : orderId}
            viewOrder(parameters);
        }
    },[orderId])

    

    useEffect(() => {
        const fetchPackageDetails = async () => {
            setLoading(true);
            try {
                const itemsWithPackage = orderDataArray.filter(item => item.is_package && item.package_code);
                const itemsWithoutPackage = orderDataArray.filter(item => !item.is_package);

                // Consolidate `is_package === false` items by merging quantities and totals
                const consolidatedCustomItems = Object.values(
                    itemsWithoutPackage.reduce((acc, item) => {
                        const key = 'custom'; // Unique key for non-packages
                        if (!acc[key]) {
                            acc[key] = {
                                ...item,
                                quantity: Number(item.quantity) || 0,
                                total: Number(item.total) || 0,
                                packageName: "Custom",
                                tokenAmount: 0 // Assuming no token amount for custom
                            };
                        } else {
                            acc[key].quantity += Number(item.quantity) || 0;
                            acc[key].total += Number(item.total) || 0;
                        }
                        return acc;
                    }, {})
                );

                // Consolidate `is_package === true` items with the same package_code
                const consolidatedPackageItems = Object.values(
                    itemsWithPackage.reduce((acc, item) => {
                        const key = item.package_code; // Group by package_code
                        if (!acc[key]) {
                            acc[key] = {
                                ...item,
                                quantity: Number(item.quantity) || 0,
                                total: Number(item.total) || 0,
                                tokenAmount: Number(item.token_amount) || 0
                            };
                        } else {
                            acc[key].quantity += Number(item.quantity) || 0;
                            acc[key].total += Number(item.total) || 0;
                            acc[key].tokenAmount += Number(item.token_amount) || 0;
                        }
                        return acc;
                    }, {})
                );


                // console.log("consolidatedPackageItems here", consolidatedPackageItems)
                // Fetch details for the consolidated package items
                const packageCodes = Object.values(consolidatedPackageItems).map(item => item.package_code);
                // console.log("packageCodes here", packageCodes)
                const packageDetailsArray = await Promise.all(
                    packageCodes.map(async code => {
                        try {
                            const parameters = { 'code': code };
                            // console.log("parameters here", parameters)
                            const res = await TokenManagementService.viewTokenPackage(parameters);
                            // console.log("res here aaaaa", res)
                            return res.data.success
                                ? { code, name: res.data.data.name, token_amount: res.data.data.token_amount }
                                : { code, name: 'Unknown Package', token_amount: 0 };
                        } catch (error) {
                            console.error(`Error fetching details for ${code}:`, error);
                            return { code, name: 'Unknown Package', token_amount: 0 };
                        }
                    })
                );

                // Map package details to the consolidated package items
                const packageDetailsMap = packageDetailsArray.reduce((acc, detail) => {
                    acc[detail.code] = detail;
                    return acc;
                }, {});

                const mappedPackageItems = Object.values(consolidatedPackageItems).map(item => {
                    const pkgDetail = packageDetailsMap[item.package_code] || { name: 'Unknown Package', token_amount: 0 };

                    return {
                        ...item,
                        packageName: pkgDetail.name,
                        tokenAmount: pkgDetail.token_amount,
                    };
                });

                // Combine all items into a single array
                const updatedCartItems = [...consolidatedCustomItems, ...mappedPackageItems];

                setCartItems(updatedCartItems);
                setLoading(false);
            } catch (error) {
                console.error("Error processing items:", error);
                setLoading(false);
            }
        };

        if (orderDataArray) {
            fetchPackageDetails();
        } else {
            console.log("No cartItems found in the query string.");
        }
    }, [orderDataArray]);

    // console.log("cartItems here", cartItems)

    const combinedDescription = cartItems.map(item => item.packageName).join("\n");

    const grandTotal = cartItems.reduce((total, item) => total + item.total, 0);


    const handlePayment = () => {
        let parameters = {'order_id' : orderId }
        payOrder(parameters);
    }


    return (
        <Box sx={{ maxWidth: 1200, margin: '0 auto', padding: 2 }}>
            <Card sx={{ marginBottom: 3, border: '1px solid #c7c0ea' }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid size={12} sx={{ textAlign: "start", mb: 0, pb: 0 }}>
                            <CustomTypography
                                text={"Order Summary"}
                                customStyles={{ mb: 0, fontSize: '20px', fontWeight: 'bold' }}
                            />
                        </Grid>
                        <Grid size={12}>
                            <Grid container spacing={2}>
                                {loading ? (
                                    Array.from({ length: 3 }).map((_, index) => (
                                        <Grid key={index} size={{ xs: 12, md: 4 }}>
                                            <Skeleton variant="rectangular" width="100%" height={150} sx={{ borderRadius: '10px', backgroundColor: '#eeebf8' }} />
                                        </Grid>
                                    ))
                                ) : (cartItems.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <Grid size={{ xs: 12, md: 4 }}>
                                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', mt: 1 }}>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemAvatar sx={{ minWidth: 100 }}>
                                                        <Avatar alt="Package Icon" src={tokenIcon} sx={{ width: 80, height: 80 }} />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={item.packageName}
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    sx={{ color: 'text.primary', display: 'inline' }}
                                                                >
                                                                    {item.is_package ? item.package_code : item.packageName}
                                                                </Typography>
                                                                <br /><br />
                                                                {item.is_package ? item.quantity : item.token_amount} Tokens
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                        <Grid size={{ xs: 4, md: 2.6 }}>
                                            <Typography>Unit Price</Typography>
                                            <Typography sx={{ mt: 1 }}>${item.price}</Typography>
                                        </Grid>
                                        <Grid size={{ xs: 4, md: 2.6 }}>
                                            <Typography>{item.is_package ?  'Quantity' : 'Token Amount'}</Typography>
                                            <Typography sx={{ mt: 1 }}>{item.quantity}</Typography>
                                        </Grid>
                                        <Grid size={{ xs: 4, md: 2.6 }}>
                                            <Typography>Sub Total</Typography>
                                            <Typography sx={{ mt: 1 }}>${item.total}</Typography>
                                        </Grid>
                                    </React.Fragment>
                                 ))
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card sx={{ marginBottom: 3, border: '1px solid #c7c0ea' }}>
                <CardContent sx={{ backgroundColor: '#604CC3', color: '#fff' }}>
                    <Grid container spacing={2}>
                        <Grid size={12} sx={{ textAlign: "start", mb: 0, pb: 0 }}>
                            <CustomTypography text={"Payment Details"} customStyles={{ mb: 0, fontSize: '20px', fontWeight: 'bold', color: '#fff' }} />
                            <br />
                            <Grid container sx={{ mb: 1 }}>
                                <Grid size={{ xs: 6, md: 2 }}>
                                    <Typography sx={{ textAlign: 'start' }}>Curency :</Typography>
                                </Grid>
                                <Grid size={{ xs: 6, md: 3 }}>
                                    {loading ? <Skeleton variant="text" width="80%" sx={{backgroundColor: '#fff'}} /> : <Typography sx={{ textAlign: 'start' }}>USD</Typography> }
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mb: 1 }}>
                                <Grid size={{ xs: 6, md: 2 }}>
                                    <Typography sx={{ textAlign: 'start' }}>Description:</Typography> 
                                </Grid>
                                <Grid size={{ xs: 6, md: 3 }}>
                                    {loading ? 
                                        <Skeleton variant="text" width="80%" sx={{backgroundColor: '#fff'}} /> 
                                    :
                                        <Typography sx={{ textAlign: 'start', whiteSpace: 'pre-line' }}>
                                            {combinedDescription}
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mb: 1 }}>
                                <Grid size={{ xs: 6, md: 2 }}>
                                    <Typography sx={{ textAlign: 'start' }}>Total Payment :</Typography>
                                </Grid>
                                <Grid size={{ xs: 6, md: 3 }}>
                                    { loading ? <Skeleton variant="text" width="80%" sx={{backgroundColor: '#fff'}} /> : <Typography sx={{ textAlign: 'start' }}>${grandTotal.toFixed(2)}</Typography> }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid size={12} sx={{ justifyContent: 'flex-end', textAlign: 'end', mt: 2 }}>
                <Button
                    variant="contained"
                    sx={{ color: '#fff', backgroundColor: '#604CC3' }}
                    startIcon={isPaying && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                    onClick={handlePayment}
                >
                    {isPaying ? 'PROCESSING PAYMENT' : 'PAY NOW'}
                </Button>
            </Grid>
        </Box>
    );
}
