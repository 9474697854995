import { Avatar, Box, Skeleton, Typography, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Chip } from '@mui/material';
import useTokenManagement from '../hooks/useTokenManagement';
import React, { useEffect } from 'react';
import Grid from "@mui/material/Grid2";
import ProgrammingArrows from '../../../assets/icons/programming-arrows.svg';
import PaymentIcon from '../../../assets/icons/payment.png';
import TopUpIcon from '../../../assets/icons/topup.png';
import moment from "moment/moment";

export default function TokenTransactionsComponent(props) {

    const { tokenTransactions, isFetchingTransactions } = props;

    const groupedTransactions = tokenTransactions?.reduce((acc, transaction) => {
        const formattedDate = new Date(transaction.created_at).toLocaleString('en-US', { month: 'short', day: 'numeric' }) || "N / A";

        if (!acc[formattedDate]) {
            acc[formattedDate] = [];
        }
        acc[formattedDate].push(transaction);
        return acc;
    }, {});

    if (!isFetchingTransactions && tokenTransactions?.length === 0) {
        return <Typography sx={{ mt: 5 }}>No available Token Transactions</Typography>;
    }

    return (
        <Box>
            {isFetchingTransactions ? (
                <>
                    {Array.from({ length: 6 }).map((_, index) => (
                        <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
                            <Grid size={{ xs: 12, sm: 6 }} key={index}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Skeleton variant="circle" width={40} height={40} sx={{ backgroundColor: "#eeebf8" }} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<Skeleton variant="text" width="80%" sx={{ backgroundColor: "#eeebf8" }} />}
                                        secondary={<Skeleton variant="text" width="40%" sx={{ backgroundColor: "#eeebf8" }} />}
                                    />
                                </ListItem>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6 }} key={index} sx={{ justifyContent: 'flex-end' }}>
                                <ListItem sx={{ justifyContent: 'flex-end', mt: 2, textAlign: 'end' }}>
                                    <ListItemText
                                        primary={<Skeleton variant="text" width="80%" sx={{ backgroundColor: "#eeebf8" }} />}
                                        secondary={<Skeleton variant="text" width="40%" sx={{ backgroundColor: "#eeebf8" }} />}
                                    />
                                </ListItem>
                            </Grid>
                        </Grid>

                    ))}
                </>
            ) : (
                groupedTransactions && Object.entries(groupedTransactions).map(([date, transactions]) => (
                    <div key={date}>
                        <Typography variant="body2" sx={{ mt: 2, textAlign: 'start' }}>{date}</Typography>
                        {transactions.length > 0 ? (
                            transactions.map((transaction) => (
                                <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{ mt: 1 }} key={transaction.id}>
                                    <Grid size={{ xs: 12, sm: 6 }} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar sx={{ backgroundColor: '#604cc336' }}>
                                                    <img 
                                                    src={transaction.tnx_type === 'transfer' ? ProgrammingArrows : transaction.tnx_type === 'top_up' ? TopUpIcon : PaymentIcon } 
                                                    style={{ width: 22, height: 22 }} />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                                        {transaction.tnx_type === 'transfer' ? 'Transfer' : transaction.tnx_type === 'top_up' ? 'Top-Up' : 'Payment'}
                                                    </Typography>
                                                }
                                                secondary={
                                                    <React.Fragment>
                                                        {transaction.tnx_flow === "IN" ? (
                                                            <Chip
                                                                label="IN"
                                                                size="small"
                                                                variant="outlined"
                                                                sx={{
                                                                    borderRadius: '0.375rem',
                                                                    fontSize: '70%',
                                                                    lineHeight: 1,
                                                                    height: '24px',
                                                                    color: '#008000',
                                                                    borderColor: '#008000',
                                                                    backgroundColor: '#e0e5e9',
                                                                }}
                                                            />
                                                        ) : transaction.tnx_flow === "OUT" ? (
                                                            <Chip
                                                                label="OUT"
                                                                size="small"
                                                                variant="outlined"
                                                                sx={{
                                                                    borderRadius: '0.375rem',
                                                                    fontSize: '70%',
                                                                    lineHeight: 1,
                                                                    height: '24px',
                                                                    color: '#FF0000',
                                                                    borderColor: '#FF0000',
                                                                    backgroundColor: '#efdde9',
                                                                }}
                                                            />
                                                        ) : null}
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    </Grid>
                                    <Grid size={{ xs: 12, sm: 6 }}>
                                        <ListItem sx={{ justifyContent: 'flex-end', mt: 2, textAlign: 'end' }}>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                                        {`${transaction.tnx_flow === "IN" ? '+' : '-'}${transaction.value} Tokens`}
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography sx={{ fontSize: '14px', color: '#00000073' }}>
                                                        {(() => {
                                                            const now = moment();
                                                            const updatedAt = moment.utc(transaction.created_at).local();

                                                            const minutesAgo = now.diff(updatedAt, 'minutes');
                                                            const hoursAgo = now.diff(updatedAt, 'hours');
                                                            const daysAgo = now.diff(updatedAt, 'days');
                                                            const weeksAgo = Math.floor(daysAgo / 7);
                                                            // const monthsAgo = now.diff(updatedAt, 'months');
                                                            // const yearsAgo = now.diff(updatedAt, 'years');

                                                            if (daysAgo === 0) {
                                                                if (hoursAgo === 0) {
                                                                    return minutesAgo <= 1 ? '1m' : `${minutesAgo}m`;
                                                                }
                                                                return hoursAgo === 1 ? '1h' : `${hoursAgo}h`;
                                                            }

                                                            if (daysAgo <= 6) {
                                                                return daysAgo === 1 ? '1d' : `${daysAgo}d`;
                                                            }

                                                            // if (weeksAgo <= 4) {
                                                            return weeksAgo === 1 ? '1w' : `${weeksAgo}w`;
                                                            // }

                                                            // if (monthsAgo <= 12) {
                                                            //     return monthsAgo === 1 ? '1m' : `${monthsAgo}m`;
                                                            // }

                                                            // return yearsAgo === 1 ? '1y' : `${yearsAgo}y`;
                                                        })()}

                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </Grid>
                                </Grid>
                            ))
                        ) : (
                            <Typography variant="body2" sx={{ mt: 1 }}>No transactions for this date.</Typography>
                        )}
                    </div>
                ))
            )}
        </Box>
    );
}
