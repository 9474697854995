import { InputAdornment, TextField } from "@mui/material"


export default function CustomTextFieldw({ label, value, onChange, borderRadius, customButton, ...props }) {


    return (
        <TextField
            label={label}
            variant="outlined"
            value={value}
            onChange={onChange}
            sx={{
                "& .MuiOutlinedInput-root": {
                    borderRadius: borderRadius, // Custom border radius
                },
            }}
            {...props}
        />
    )
}