import React, { useState, useEffect, useRef } from 'react';
import { Box, Skeleton, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import useDashboard from '../hooks/useDashboard';
import PieGraph from '../../../assets/icons/pieGraph.png'

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} style={{ outline: 'none', pointerEvents: 'none' }}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
                stroke="none"
                style={{ outline: 'none', pointerEvents: 'none' }}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
                stroke="none"
                style={{ outline: 'none', pointerEvents: 'none' }}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333" style={{ fontSize: 12, outline: 'none', pointerEvents: 'none' }}>
                {payload.name}
            </text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999" style={{ outline: 'none', pointerEvents: 'none' }}>
                {value}
            </text>
        </g>
    );
};

export default function DashboardConsumerBaseComponent() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [data, setData] = useState([]);

    const { getDashboardCount, isLoadingDashboardCount, dashboardCount } = useDashboard();

    const [isEmail, setIsEmail] = useState(JSON.parse(localStorage.getItem('isEmail')));
    const [isWorkspace, setIsWorkspace] = useState(JSON.parse(localStorage.getItem('isWorkspace')));
    const [menuSelected, setMenuSelected] = useState(localStorage.getItem('menuSelected'));

    // Ref to track previous state values and avoid infinite loop
    const prevIsEmail = useRef(isEmail);
    const prevIsWorkspace = useRef(isWorkspace);
    const prevMenuSelected = useRef(menuSelected);

    useEffect(() => {
        const syncLocalStorage = () => {
            const storedIsEmail = JSON.parse(localStorage.getItem('isEmail'));
            const storedIsWorkspace = JSON.parse(localStorage.getItem('isWorkspace'));
            const storedMenuSelected = localStorage.getItem('menuSelected');

            // Check if stored values are different from previous ones, update state if necessary
            if (storedIsEmail !== prevIsEmail.current) {
                setIsEmail(storedIsEmail);
                prevIsEmail.current = storedIsEmail;
            }

            if (storedIsWorkspace !== prevIsWorkspace.current) {
                setIsWorkspace(storedIsWorkspace);
                prevIsWorkspace.current = storedIsWorkspace;
            }

            if (storedMenuSelected !== prevMenuSelected.current) {
                setMenuSelected(storedMenuSelected);
                prevMenuSelected.current = storedMenuSelected;
            }
        };

        // Set interval to sync localStorage every 500ms
        const intervalId = setInterval(syncLocalStorage, 500);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);  // Empty dependency array to run once on mount

    useEffect(() => {
        let parameters = {};

        if (isEmail) {
            parameters = {};
        } else if (isWorkspace) {
            parameters.workspace_id = menuSelected ? menuSelected : null;
        }

        getDashboardCount(parameters);
    }, [isEmail, isWorkspace, menuSelected]);

    useEffect(() => {
        if (dashboardCount) {
            const cardData = [
                {
                    title: "Draft",
                    count: dashboardCount?.drafts,
                    label: "Documents",
                    bgColor: "#e6e2f5",
                    borderColor: "#604CC3",
                    textColor: "#9f92da",
                    countColor: "#604CC3",
                },
                {
                    title: "Ongoing",
                    count: dashboardCount?.ongoing,
                    label: "Documents",
                    bgColor: "#efe7e9",
                    borderColor: "#FFA500",
                    textColor: "#f9bc51",
                    countColor: "#FFA500",
                },
                {
                    title: "Required Action",
                    count: dashboardCount?.required,
                    label: "Documents",
                    bgColor: "#e0e4f8",
                    borderColor: "#0178F2",
                    textColor: "#4f9ef4",
                    countColor: "#0178F2",
                },
                {
                    title: "Expired",
                    count: dashboardCount?.expired,
                    label: "Documents",
                    bgColor: "#efdde9",
                    borderColor: "#FF0000",
                    textColor: "#f94d51",
                    countColor: "#FF0000",
                },
                {
                    title: "Decline",
                    count: dashboardCount?.denied,
                    label: "Documents",
                    bgColor: "#e8ddeb",
                    borderColor: "#830221",
                    textColor: "#b26980",
                    countColor: "#830221",
                },
                {
                    title: "Completed",
                    count: dashboardCount?.completed,
                    label: "Documents",
                    bgColor: "#e0e5e9",
                    borderColor: "#008000",
                    textColor: "#4ea351",
                    countColor: "#008000",
                },
            ];

            // Transform cardData into format for the Pie chart
            const transformedData = cardData.map(item => ({
                name: item.title,
                value: item.count,
                fill: item.countColor, // Use the dynamic color from the cardData
            }));

            setData(transformedData);
        }
    }, [dashboardCount]);

    // Check if dashboardCount or the values are missing or zero
    const isNoData = !dashboardCount || Object.values(dashboardCount).every(value => value === 0 || value === null || value === undefined);

    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    return (
        <Box sx={{ backgroundColor: "#fff", mt: "20px", padding: "20px", pt: "24px", borderRadius: "8px", height: '53vh' }}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography sx={{ color: "#58565f", fontSize: 18, mb: 3, fontWeight: 600 }}>
                        Consumer Base
                    </Typography>
                </Grid>
            </Grid>

            {isLoadingDashboardCount ? (
                <Skeleton variant="circular" width={200} height={200} sx={{ mx: "auto", display: "block", backgroundColor: '#eeebf8' }} />
            ) : isNoData ? (
                <Box sx={{display:'grid', justifyContent:'center'}}>
                    <img src={PieGraph} style={{height: 300, width: 300}}/>
                    <br/>
                    <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold', color: '#58565f', fontSize: 12 }}>
                        No Available Data to Display
                    </Typography>
                </Box>
            ) : (
                <ResponsiveContainer width="100%" height={400} style={{ paddingTop: "12px" }}>
                    <PieChart>
                        <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={data}
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={80}
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.fill} />
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            )}
        </Box>
    );
}
