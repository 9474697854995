import { useState, useRef, useEffect } from 'react';
import {Typography, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, ListItemIcon, TextField, Stack} from '@mui/material';

import { useDrop, useDrag } from 'react-dnd'

import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import useSignatoryStore from "../../../zustand/useSignatoryStore";
import AlertDialogComponent from './alert-dialog.component';
import DocumentEditorService from "../../../services/document-editor-service";
import { useSearchParams } from 'react-router-dom';

const SigneeCardComponent = ({signee, index, onMoveCard, hasSigningOrder}) => {
  
    const [showEdit, setShowEdit] = useState(false)    
    const [email, setEmail] = useState(signee)    
    const [openAlertDialog, setOpenAlertDialog] = useState(false)    
    const [isLoadingUpdateSignatory, setIsLoadingUpdateSignatory] = useState(false)    
    const [isLoadingDeleteSignatory, setIsLoadingDeleteSignatory] = useState(false)    

    const updateSignatory = useSignatoryStore((state) => state.updateSignatory)
    const removeSignatory = useSignatoryStore((state) => state.removeSignatory)
    const [searchParams] = useSearchParams();
    const documentId = searchParams.get('id');

    const ref = useRef(null)
    const [{ handlerId }, drop] = useDrop({
        accept: "signees",
        collect(monitor) {
            return {handlerId: monitor.getHandlerId()}
        },
        hover(item, monitor) {
            if (!ref.current) return

            const dragIndex = item.index
            const hoverIndex = index

            if (dragIndex === hoverIndex) return

            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
    
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

            onMoveCard(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: "signees",
        item: () => {
        return { signee, index }
        },
        canDrag: hasSigningOrder,
        collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        }),
    }, [hasSigningOrder])

    drag(drop(ref))

    const handleShowEditSignee = () => {
        setShowEdit(true)
    }

    const handleOnChangeInputEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleOnEditSignee = () => {
        updateSignatory(signee, email)
        setShowEdit(false)
        // setIsLoadingUpdateSignatory(true)
        // DocumentEditorService.updateSignatory(documentId, signee, email).then((res) => {
        //     setIsLoadingUpdateSignatory(false)
        //     updateSignatory(signee, email)
        //     setShowEdit(false)
        // }).catch((e) => {
        //     setIsLoadingUpdateSignatory(false)
        // })
    }

    const handleCloseEdit = () => {
        setShowEdit(false)
    }

    const handleOnDeleteSignee = () => {
        removeSignatory(signee)
        handleCloseAlertDialog()
        // setIsLoadingDeleteSignatory(true)
        // DocumentEditorService.deleteSignatory(documentId, signee).then((res) => {
        //     setIsLoadingDeleteSignatory(false)
        //     removeSignatory(signee)
        // }).catch((e) => {
        //     setIsLoadingDeleteSignatory(false)
        // })
    }

    const handleCloseAlertDialog = () => {
        setOpenAlertDialog(false)
    }

    const handleDeleteSignee = () => {
        setOpenAlertDialog(true)
    }

    return(
        <>
            {/* <List ref={ref} sx={{border: '1px solid grey', cursor: hasSigningOrder ? 'all-scroll' : 'context-menu' , m: 1}}>
                <ListItem>
                    <ListItemIcon><DragIndicatorOutlinedIcon /></ListItemIcon>
                    <ListItemText primary={<Typography variant="body">{signee}</Typography>} />
                </ListItem>
            </List> */}
            {
                showEdit ? 
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    p={2}
                    sx={{border: '1px solid grey'}}x
                    m={1}
                >
                    <TextField label="Email" variant="standard" fullWidth placeholder='Enter email' value={email} onChange={handleOnChangeInputEmail} />
                    <IconButton disabled={isLoadingUpdateSignatory} onClick={handleOnEditSignee}>
                        {
                            isLoadingUpdateSignatory ? 
                                <CircularProgress size={20} />
                            :
                                <CheckIcon />
                        }
                    </IconButton>
                    <IconButton onClick={handleCloseEdit}><CloseIcon /></IconButton>
                </Stack>
                :
                <List ref={ref} sx={{border: '1px solid grey', m: 1}}>
                    <ListItem>
                        {/* <ListItemIcon><DragIndicatorOutlinedIcon /></ListItemIcon> */}
                        <ListItemText primary={<Typography variant="body">{signee}</Typography>} />
                        <ListItemSecondaryAction>
                            <IconButton onClick={handleShowEditSignee}><EditIcon /></IconButton>
                            <IconButton onClick={handleDeleteSignee}><DeleteIcon /></IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            }

            <AlertDialogComponent openAlertDialog={openAlertDialog} handleDelete={handleOnDeleteSignee} handleCloseAlertDialog={handleCloseAlertDialog} isLoadingDeleteSignatory={isLoadingDeleteSignatory} />
        </>
    )
}

export default SigneeCardComponent