import { Alert, AlertTitle, Avatar, Box, Button, List, ListItem, ListItemAvatar, ListItemText, Menu, Skeleton, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import tokenIcon from '../../../assets/icons/token.png';
import Grid from '@mui/material/Grid2';
import moment from "moment/moment";
import DeleteIcon from '@mui/icons-material/Delete';
import useOrderManagement from "../hooks/useOrderManagement";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";



export default function ProfileCartMenu({ anchorEl2, handleCloseCart, filteredOrders, getListOrder, isFetchingOrders, isEmail, isWorkspace, wallet_id, 
    profileData, item_created, order_placed, order_created }) {

    const navigate = useNavigate();

    const { cancelOrder, orderCancel, isCancelOrder } = useOrderManagement();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [currentOrderId, setCurrentOrderId] = useState(null);
    // console.log("filteredOrders xxx", filteredOrders)

    const forPaymentCount = filteredOrders?.filter(order => order?.status === "For Payment")?.length;

    const handleCheckoutClick = (order_id) => {
        if (forPaymentCount >= 3) {
            setOpenSnackbar(true);
        } else {
            if (window.location.pathname === "/token/checkout") {
                window.location.href = "/token/checkout?orderId=" + order_id;
                handleCloseCart();

            } else {
                navigate(`/token/checkout?orderId=${order_id}`);
                handleCloseCart();
            }
            
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const handleCancelOrder = (orderId) => {
        setCurrentOrderId(orderId); 
        let parameters = { "order_id": orderId }
        cancelOrder(parameters);
    }

    useEffect(() => {
        let parameters = null;
    
        const fetchOrders = () => {
            if (isEmail && profileData?.profile.walletId) {
                parameters = { wallet_id: profileData.profile.walletId };
            } else if (isWorkspace) {
                parameters = { wallet_id: wallet_id || null };
            }
    
            if (parameters) {
                getListOrder(parameters);
            }
        };

        if(orderCancel) {
            fetchOrders(); 
            window.location.href='/dashboard';
        } else if (item_created || order_placed || order_created) {
            fetchOrders();
        }

        // if(item_created || order_placed || order_created) {
        //     fetchOrders(); 
        // } else if (orderCancel) {
        //     fetchOrders();
        //     window.location.href='/dashboard';
        // }
    
        
    }, [item_created, order_placed, order_created, orderCancel, isEmail, isWorkspace, wallet_id, profileData]);
    



    return (
        <Menu
            anchorEl={anchorEl2}
            open={Boolean(anchorEl2)}
            onClose={handleCloseCart}
            sx={{ mt: 1 }}
        >
            {isFetchingOrders ? (
                <Grid container spacing={2}>
                    {[...Array(5)].map((_, index) => (
                        <Grid size={{ xs: 12 }} key={index}>
                            <Grid container>
                                <Grid size={{ xs: 12, sm: 12, md: 5, lg: 6 }}>
                                    <List sx={{ width: '100%', maxWidth: 280, bgcolor: 'background.paper', mt: 1 }}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar sx={{ minWidth: 120 }}>
                                                <Skeleton variant="circular" width={60} height={60} sx={{ backgroundColor: '#eeebf8' }} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={<Skeleton variant="text" width="80%" sx={{ backgroundColor: '#eeebf8' }} />}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography component="span" variant="body2" sx={{ color: 'text.primary', display: 'inline' }}>
                                                            <Skeleton variant="text" width="60%" sx={{ backgroundColor: '#eeebf8' }} />
                                                        </Typography>
                                                        <br />
                                                        <Skeleton variant="text" width="60%" sx={{ backgroundColor: '#eeebf8' }} />
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 12, md: 7, lg: 6 }} sx={{ textAlign: 'center', alignSelf: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Skeleton variant="rectangular" width={50} height={20} sx={{ backgroundColor: '#eeebf8' }} />
                                    </Box>
                                </Grid>
                            </Grid>


                        </Grid>
                    ))}
                </Grid>
            ) : filteredOrders?.length === 0 ? (
                <Box sx={{ m: 2 }}>
                    <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold', color: '#58565f', fontSize: 14 }}>
                        No Available Items to Display
                    </Typography>
                </Box>
            ) : (
                filteredOrders?.map((order, index) => (
                    <Grid container>
                        <Grid size={{ xs: 12, sm: 12, md: 5, lg: 6 }}>
                            <List sx={{ width: '100%', minWidth: 280, bgcolor: 'background.paper' }}>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar sx={{ minWidth: 70 }}>
                                        <Avatar
                                            alt="Package Icon"
                                            src={tokenIcon}
                                            sx={{ width: 60, height: 60 }}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<React.Fragment>
                                            <Typography
                                                sx={{ color: 'text.primary', display: 'inline', fontSize: 16 }}
                                            >
                                                <b>{order.token_amount}</b> Tokens
                                            </Typography></React.Fragment>}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{ color: 'text.primary', display: 'inline', fontSize: 12 }}
                                                >
                                                    {console.log("amount otder", order?.total_amount)}
                                                    {/* Total Price : <b>${((order?.total_amount)?.toFixed(2)) }</b> */}
                                                    Total Price : <b>${order?.total_amount === 0 ? order?.total_amount : order?.total_amount?.toFixed(2)}</b>
                                                    {/* <b>${order.total_amount}</b> */}
                                                </Typography>
                                                <br />
                                                <Typography
                                                    sx={{ color: 'text.primary', display: 'inline', fontSize: 12 }}
                                                >
                                                    {(() => {
                                                        const now = moment();
                                                        const updatedAt = moment.utc(order.updated_at).local();
                                                        const daysAgo = now.diff(updatedAt, 'days');

                                                        if (daysAgo === 0) {
                                                            const hoursAgo = now.diff(updatedAt, 'hours');
                                                            if (hoursAgo === 0) {
                                                                const minutesAgo = now.diff(updatedAt, 'minutes');
                                                                return minutesAgo <= 1 ? '1 minute ago' : `${minutesAgo} minutes ago`;
                                                            }
                                                            return hoursAgo === 1 ? '1 hour ago' : `${hoursAgo} hours ago`;
                                                        }

                                                        return daysAgo === 1 ? '1 day ago' : `${daysAgo} days ago`;
                                                    })()}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Grid>

                        <Grid size={{ xs: 12, sm: 12, md: 7, lg: 6 }} sx={{ textAlign: 'center', alignSelf: 'center', justifyContent: 'center', justifyItems: 'center' }}>
                            {order.status === "Pending" ?
                                <>
                                    <Button sx={{ textTransform: 'uppercase', color: '#FFA500', backgroundColor: '#fff', border: '1px solid #f9bc51' }}
                                        onClick={() => handleCheckoutClick(order?.order_id)}
                                    >
                                        Checkout
                                    </Button> &nbsp;
                                    <Button sx={{ color: '#f63539', backgroundColor: '#fff', border: '1px solid #ff00006b', alignSelf: 'center', justifyContent: 'center', margin: '0px' }}
                                        onClick={() => handleCancelOrder(order?.order_id)}
                                        startIcon={
                                            isCancelOrder && order.order_id === currentOrderId ? (
                                              <ThreeDots
                                                height="20"
                                                width="20"
                                                radius="9"
                                                color="#ff00006b"
                                                ariaLabel="creating-order"
                                                visible={true}
                                              />
                                            ) : null
                                          }
                                    >
                                        {order.order_id !== currentOrderId && <DeleteIcon sx={{ width: 20 }} />}
                                    </Button>
                                </>
                            :
                                <>
                                    <Button sx={{ color: '#fff', backgroundColor: '#604CC3' }}
                                        onClick={() => { navigate(`/place-order?orderId=${order.order_id}`); handleCloseCart(); }}
                                    >
                                        Pay Now
                                    </Button> &nbsp;
                                    <Button sx={{ color: '#f63539', backgroundColor: '#fff', border: '1px solid #ff00006b', alignSelf: 'center', justifyContent: 'center', margin: '0px' }}
                                        onClick={() => handleCancelOrder(order.order_id)}
                                        startIcon={
                                            isCancelOrder && order.order_id === currentOrderId ? (
                                              <ThreeDots
                                                height="20"
                                                width="20"
                                                radius="9"
                                                color="#ff00006b"
                                                ariaLabel="creating-order"
                                                visible={true}
                                              />
                                            ) : null
                                          }
                                    >
                                       {order.order_id !== currentOrderId && <DeleteIcon sx={{ width: 20 }} />}
                                    </Button>
                                </>
                            }
                        </Grid>
                    </Grid>
                ))
            )}
            <Snackbar open={openSnackbar}  onClose={handleSnackbarClose}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {/* <AlertTitle>Error</AlertTitle> */}
                    You have exceeded the limit of 3 'For Payment' orders. Please complete payment or cancel existing orders to continue.
                </Alert>
            </Snackbar>
        </Menu>
    )
}