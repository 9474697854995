import React from "react";
import {styled, Typography} from "@mui/material";

const StyledTypography = styled(Typography)(({theme, variant}) => ({
    marginBottom: theme.spacing(1), 
    color: "#58565F",

    ...(variant === 'h1' && {
        fontSize: '2.5rem',
        fontWeight: 'bold',
    }),
    ...(variant === 'h2' && {
        fontSize: '2rem',
        fontWeight: 'bold',
    }),
    ...(variant === 'h6' && {
        fontSize: "26px",
        fontWeight: "bold",
    }),
    ...(variant === 'subtitle1' && {
        fontSize: "20px",
        fontWeight:'bold',
        color:'#000000',
    }),
    ...(variant === 'subtitle2' && {
        fontSize: "16px",
    }),
    ...(variant === 'body1' && {
        fontSize: "16px",
        lineHeight: 1.5,
        color:'#000000',
    }),
    ...(variant === 'caption' && {
        fontSize: "14px",
        color: "rgba(88, 86, 95, 0.79)",
    }),
}));

const CustomTypography = ({variant, text, customStyles, ...props}) => {
    return (
        <StyledTypography 
            variant={variant} 
            sx={{
                ...customStyles
            }}  
            {...props}
            dangerouslySetInnerHTML={{__html: text}} 
        />
    )
}

export default CustomTypography