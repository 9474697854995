import { useSnackbar } from "notistack";
import UserService from "../services/user-service";
import { useState } from "react";

export default function useUser() {

    const { enqueueSnackbar } = useSnackbar();
    const [isTransferring, setTransferring] = useState(false);

    const tranferToken = (payload, handleCloseTransferTokenDialog) => {
        setTransferring(true);
        UserService.tranferToken(payload).then((res) => {
            if (res.data.success) {
                setTransferring(false);
                const successMessage = res.data.data.from.description;
                enqueueSnackbar(successMessage, {
                    variant: "success",
                    autoHideDuration: 2000
                })
                handleCloseTransferTokenDialog();
                window.location.reload();
               
            }
        }).catch((error) => {
            setTransferring(false);
            const errorMessage = error?.response?.data?.error || error.message;
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
            handleCloseTransferTokenDialog();
        })
    }

    return { tranferToken, isTransferring }
}