import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import GestureIcon from '@mui/icons-material/Gesture';
import BorderColorIcon from '@mui/icons-material/BorderColor';

const draggable_ui_elements = [
    {
        icon: <TextFieldsIcon sx={{color: '#604CC3'}}/>,
        label:'Textfield',
        type: 'textfield'
    },
    {
        icon:<GestureIcon sx={{color: '#604CC3'}}/>,
        label:'Signature',
        type: 'esignature'
    },
    {
        icon:<QrCodeOutlinedIcon sx={{color: '#604CC3'}}/>,
        label: 'QR Code',
        type: 'qrcode'
    },
    {
        icon:<CalendarMonthIcon sx={{color: '#604CC3'}}/>,
        label:'Date',
        type: 'date'
    },
    {
        icon:<BorderColorIcon sx={{color: '#604CC3'}}/>,
        label:'Signatory',
        type: 'signatory'
    },
]

export default draggable_ui_elements;