import routes from "../common/api-routes";
import { HttpPublicGet, HttpPublicPost } from "../../../../../core/services/common/HttpRequestBuilder";

const subscriptionKey = process.env.REACT_APP_SUPERSIGN_PRODUCT_SUB_KEY;

console.log("Subscription Key:", subscriptionKey); // Debug log

const getDocumentWithProperties = async (accessToken) => {
  
    try {
        const result = await HttpPublicGet(routes.GET_DOCUMENT_WITH_PROPERTIES_PUBLIC, null, accessToken, subscriptionKey);
        return result; 
    } catch (error) {
        console.error("Error fetching document properties:", error);
        throw error; 
    }
}

const signDocument = (email,document_id, signature_url,fillableElements, accessToken) => {
    let payload = {
        email,
        document_id,
        signature_url,
        fillableElements,
    };
    console.log("signDocument payload bbbb ", payload);
    
    let result = HttpPublicPost(routes.SIGN_DOCUMENT, null, payload, accessToken, subscriptionKey);
    return result;
}

const declineDocument = (document_id, email, denied_reason, accessToken) => {

    var payload = { document_id ,email,denied_reason };

    let result = HttpPublicPost(routes.DECLINE_DOCUMENT, null, payload, accessToken, subscriptionKey);
    return result;
}


const DocumentPublicService = {
    getDocumentWithProperties,
    signDocument,
    declineDocument
}

export default DocumentPublicService;
