import { useState, useEffect, useRef } from 'react';
import { Box, Backdrop, Drawer, IconButton, Typography, } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend'
import { styled, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DocumentSigneeAppbarComponent from '../components/document-signee-appbar.component';
import DocumentSigneeBoxComponent from '../components/document-signee-box.component';
import DeclineResonDialog from '../dialogs/reason-to-decline.dialog';
import DocumentElementSideDrawerComponent from '../components/document-element-side-drawer.component';
import { elementTypes } from '../common/staticVariables';
import { isMobile } from 'react-device-detect';
import '../../../../App.css';


const drawerWidth = 320;


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
        flexGrow: 1,
        padding: theme.spacing(0),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
        position: 'relative',
        variants: [
            {
                props: ({ open }) => open,
                style: {
                    transition: theme.transitions.create('margin', {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                    marginRight: 0,
                },
            },
        ],
    }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

const initialPdfResult = {
    filename: "",
    total_page: 0,
    elements: [],
    signatory: []
}


export default function DocumentPDFSigneeScreen({ hasContinued, backdropOpen, alertVisible, handleContinue, setAlertVisible, file_url,
    elements_to_plot, signatory, onDocumentLoadSuccess, onDocumentPageLoadSuccess, onClickFinish, currentUser = null, currentUserSignature,
    setCurrentUserSignature, isViewing = false, viewDownloadedPdf, getDocumentById, sender, signeeCCEmail, document_id, setOpenCancelledDialog,
    setFillableTextfield, setFillableDate, fillable_textfield, fillable_date, document_title, cc_emails
}) {
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [pdfResult, setPDFResult] = useState(initialPdfResult);
    const [elRefIndex, setElRefIndex] = useState(0);
    const [signatoriesCounter, setSignatoriesCounter] = useState(null);
    const [elements, setElements] = useState([]);
    const [signedCounter, setSignedCounter] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [openDeclined, setOpenDeclined] = useState(false);
    const [isDeclineClicked, setIsDeclineClicked] = useState(false);
    const [isFinishClicked, setIsFinishClicked] = useState(false);
    const [documentLoaded, setDocumentLoaded] = useState(false);
    const [currentElement, setCurrentElement] = useState(null)

    const elRefs = useRef([]);


    console.log('get signatories counter', signatoriesCounter)

    console.log("elRefs axx", elRefs);
    console.log("elements axx", elements);
    console.log('get cc emails from public', signeeCCEmail)


    useEffect(() => {
        console.log("pdfResult aa", pdfResult);
    }, [JSON.stringify(pdfResult)])

    useEffect(() => {
        console.log("elements 111111111", elements);
    }, [JSON.stringify(elements)])

    const onUpdateElements = (elements) => {
        setElements(elements)
    }

    const handleOnClickFinish = () => {
        setIsLoading(true)
        setIsFinishClicked(true);
        setIsDeclineClicked(false);
        onClickFinish(pdfResult, setIsLoading).then(() => {
            setIsLoading(false)
        }).catch(() => {
            setIsLoading(false)
        })
    }

    const handleNavigateSignatoryPages = (elRefs) => {
        let elRefsKeys = Object.keys(elRefs.current)

        console.log("elRefsKeys bbb", elRefsKeys);
        console.log("elRefIndex bbb", elRefIndex);

        const currentElRefsIndex = elRefsKeys[elRefIndex] ? parseInt(elRefsKeys[elRefIndex]) : parseInt(elRefsKeys[0]);

        console.log("currentElRefsIndex bbb", currentElRefsIndex);

        elRefs.current[currentElRefsIndex].scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
        let index = elRefIndex >= elRefsKeys.length - 1 ? 0 : elRefIndex + 1

        console.log("currentElRefsIndex bbb", currentElRefsIndex);

        setElRefIndex(index)
    }


    const onChangeSignature = (signature) => {
        console.log("signature onchange here", signature)
        setCurrentUserSignature(signature)
        setElements((prev) => prev.map(el => {
            if (el.type == elementTypes.signatory) {
                el.isSigned = true
                el.signature = signature
            }
            console.log("set signature onchange xxxxxx", el)
            return el
        }))
    }

    const onUpdateSignatoryCounter = (signatories_counter) => {
        console.log("updateSignatoryCounter aa", signatories_counter);
        setSignatoriesCounter(signatories_counter)
    }

    useEffect(() => {
        if (signatory.length > 0) {
            let signed_counter = signatory.filter((item) => item.status == "DONE").length
            setSignedCounter(signed_counter)
        }
    }, [JSON.stringify(signatory)])

    const handleClickOpen = () => {
        setOpenDeclined(true);
        setIsDeclineClicked(true);
        setIsFinishClicked(false);
    };

    const handleClose = () => {
        setOpenDeclined(false);
        setIsDeclineClicked(false);
    };

    const handleDecline = () => {
        setOpenDeclined(false);
        setIsDeclineClicked(false);
    };

    const DNDBackend = isMobile ? TouchBackend : HTML5Backend;

    return (
        <div class={isMobile ? "container" : ''}>
            <div class={isMobile ? "container-content" : ''}  >
                <DndProvider backend={DNDBackend}>
                    <Box sx={{ display: 'flex' }}>
                        <CssBaseline />
                        <DocumentSigneeAppbarComponent
                            hasContinued={hasContinued}
                            signatoriesCounter={signatoriesCounter}
                            signedCounter={signedCounter}
                            currentUser={currentUser}
                            handleOnClickFinish={handleOnClickFinish}
                            isDeclineClicked={isDeclineClicked}
                            isLoading={isLoading}
                            handleContinue={handleContinue}
                            handleDecline={handleDecline}
                            handleClickOpen={handleClickOpen}
                            isFinishClicked={isFinishClicked}
                            documentLoaded={documentLoaded}
                            handleDrawerOpen={handleDrawerOpen}
                            open={open}
                        />
                        <Backdrop open={backdropOpen} sx={{ zIndex: (theme) => theme.zIndex.appBar - 1 }} />
                        <Main open={open} >
                            <DrawerHeader />
                            <DocumentSigneeBoxComponent
                                file_url={file_url}
                                signatory={signatory}
                                elements_to_plot={elements_to_plot}
                                onUpdateElements={onUpdateElements}
                                pdfResult={pdfResult}
                                setPDFResult={setPDFResult}
                                elements={elements}
                                setElements={setElements}
                                currentUser={currentUser}
                                currentUserSignature={currentUserSignature}
                                elRefs={elRefs}
                                isViewing={isViewing}
                                onChangeSignature={onChangeSignature}
                                onUpdateSignatoryCounter={onUpdateSignatoryCounter}
                                viewDownloadedPdf={viewDownloadedPdf}
                                setFillableDate={setFillableDate}
                                setFillableTextfield={setFillableTextfield}
                                setDocumentLoaded={setDocumentLoaded}
                                fillable_textfield={fillable_textfield}
                                fillable_date={fillable_date}
                                setCurrentElement={setCurrentElement}
                                currentElement={currentElement}
                                documentLoaded={documentLoaded}
                                open={open}
                            />
                        </Main>
                        {documentLoaded &&
                            <DocumentElementSideDrawerComponent
                                document_title={document_title}
                                currentUserSignature={currentUserSignature}
                                signatoriesCounter={signatoriesCounter}
                                signedCounter={signedCounter}
                                signatory={signatory}
                                sender={sender}
                                cc_emails={cc_emails}
                                onChangeSignature={onChangeSignature}
                                setCurrentElement={setCurrentElement}
                                handleClickOpen={handleClickOpen}
                                isFinishClicked={isFinishClicked}
                                documentLoaded={documentLoaded}
                                handleOnClickFinish={handleOnClickFinish}
                                isDeclineClicked={isDeclineClicked}
                                isLoading={isLoading}
                                open={open}
                                handleDrawerClose={handleDrawerClose}
                            />
                        }
                    </Box>
                    {openDeclined &&
                        <DeclineResonDialog
                            openDeclined={openDeclined}
                            handleClose={handleClose}
                            handleDecline={handleDecline}
                            currentUser={currentUser}
                            document_id={document_id}
                            setOpenCancelledDialog={setOpenCancelledDialog}
                        />
                    }
                </DndProvider>
            </div>
        </div>
    );
}




