// src/App.js
import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { BrowserRouter, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import Routes from "./core/router";

const App = () => {
 
  const app_environment = process.env.REACT_APP_ENVIRONMENT;
  console.log('REACT_APP_ENVIRONMENT:', app_environment);

  // let router = createBrowserRouter(
  //   createRoutesFromElements(<Routes />)
  // );
 
  return (
    <div style={{ textAlign: 'center', cursor: 'default' }}>
      {/* <RouterProvider router={router} /> */}
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </div>
  );
};
 
export default App;
