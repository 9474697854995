import React, { useState } from "react";
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const CustomDocumentListPopperComponent = ({ menuItems, handleSelectMenu, row }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }


  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 300,
          }
        }}
      >
        {menuItems?.map((item, index) => (
          !item.disabled && (
            <MenuItem
              key={index}
              onClick={() => {
                handleClose();
                handleSelectMenu(item, row);
              }}
            >
              <ListItemIcon sx={{ color: item.color }}>{item.icon}</ListItemIcon>
              <ListItemText secondary={
                <Typography sx={{ fontSize: 14, color: item.color }} >
                  {item.text}
                </Typography>
              } />
            </MenuItem>
          )
        ))}
      </Menu>
    </>
  )
}

export default CustomDocumentListPopperComponent
