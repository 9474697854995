import { Box, Tabs, Tab, Card, CardContent, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';

import { useEffect, useState } from "react";
import CustomTypography from "../../../core/components/ui/CustomTypography";
import PurchaseTransactionsComponent from "../components/purchase.component";
import useTokenManagement from "../hooks/useTokenManagement";
import useOrderManagement from "../hooks/useOrderManagement";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function PurchaseTransactionsScreen() {

    const { getPurchaseTransactions, isFetchingPurchaseTransaction, purchaseTransactionList } = useTokenManagement();
    const { getListOrderItems, isFetchingOrders, orderList, viewOrder, orderLogs, orderData, orderDataArray } = useOrderManagement();

    const profileWalletId = localStorage.getItem('profile_wallet');
    const [isEmail, setIsEmail] = useState(JSON.parse(localStorage.getItem('isEmail')));
    const [isWorkspace, setIsWorkspace] = useState(JSON.parse(localStorage.getItem('isWorkspace')));
    const [wallet_id, setWalletId] = useState(localStorage.getItem('wallet_id'));



    const [currentTab, setCurrentTab] = useState(0);
    const [mergedData, setMergedData] = useState([]);


    useEffect(() => {
        const syncLocalStorage = () => {
            const storedIsEmail = JSON.parse(localStorage.getItem('isEmail'));
            const storedIsWorkspace = JSON.parse(localStorage.getItem('isWorkspace'));
            const storedWalletId = localStorage.getItem('wallet_id');

            if (storedIsEmail !== isEmail) setIsEmail(storedIsEmail);
            if (storedIsWorkspace !== isWorkspace) setIsWorkspace(storedIsWorkspace);
            if (storedWalletId !== wallet_id) setWalletId(storedWalletId);
        };

        const intervalId = setInterval(syncLocalStorage, 500);

        return () => clearInterval(intervalId);
    }, [isEmail, isWorkspace, wallet_id]);

    useEffect(() => {
        let parameters = {};

        if (isEmail) {
            parameters = { "walletId": profileWalletId }
            getPurchaseTransactions(parameters);
        } else if (isWorkspace) {
            parameters = { "walletId": wallet_id }
            getPurchaseTransactions(parameters);
        }

    }, [isEmail, isWorkspace, wallet_id]);

    useEffect(() => {
        let parameters = {};

        if (isEmail) {
            parameters = { "wallet_id": profileWalletId }
            getListOrderItems(parameters);
        } else if (isWorkspace) {
            parameters = { "wallet_id": wallet_id }
            getListOrderItems(parameters);
        }

    }, [isEmail, isWorkspace, wallet_id]);

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };


    const uniqueOrderList = [...new Map(orderList?.map(order => [order.order.order_id, order])).values()];
    const uniquePurchaseTransactionList = [...new Map(purchaseTransactionList?.map(txn => [txn.tnx_reference, txn])).values()];

    console.log("uniqueOrderListhere", uniqueOrderList)
    console.log("uniquePurchaseTransactionList", uniquePurchaseTransactionList)
    console.log("uniqiepurchaseTransactionList", purchaseTransactionList)
    console.log("uniqieorderList", orderList)

    useEffect(() => {
        if (uniqueOrderList && uniquePurchaseTransactionList) {
            const mergedData = uniquePurchaseTransactionList.map(transaction => {
                const matchingOrder = uniqueOrderList.find(order => order.order_id === transaction.tnx_reference);

                if (matchingOrder) {
                    return {
                        order_id: matchingOrder.order.order_id,
                        status: matchingOrder.order.status,
                        token_amount: transaction.token_amount, // token_amount from transaction
                        total_amount: transaction.price, // price from transaction
                        created_at: transaction.created_at,
                        currency: transaction.currency,
                        id: transaction.id,
                        tnx_reference: transaction.tnx_reference,
                        items: matchingOrder.items, // Include items from the matching order
                    };
                }
                return null; // Return null if there's no match (to be filtered out)
            }).filter(item => item !== null); // Remove nulls (no match cases)

            // Add unmatched orders directly to the merged data
            uniqueOrderList.forEach(order => {
                const matchingTransaction = uniquePurchaseTransactionList.find(transaction => transaction.tnx_reference === order.order_id);

                if (!matchingTransaction) {
                    // Add the order directly as-is if there's no matching transaction
                    mergedData.push({
                        ...order // Directly include the original order data
                    });
                }
            });

            setMergedData(mergedData);
        }
    }, [orderList, purchaseTransactionList]); // Re-run when orderList or purchaseTransactionList changes

    console.log("meregeData here", mergedData)


    const tabs = [
        {
            name: "All",
            component: <PurchaseTransactionsComponent data={mergedData} allData={mergedData} isFetchingOrders={isFetchingOrders} isFetchingPurchaseTransaction={isFetchingPurchaseTransaction} />,
        },
        {
            name: "For Checkout",
            component: (<PurchaseTransactionsComponent data={mergedData.filter((item) => item.order?.status === "Pending")} allData={mergedData} isFetchingOrders={isFetchingOrders} isFetchingPurchaseTransaction={isFetchingPurchaseTransaction} />),
        },
        {
            name: "For Payment",
            component: (<PurchaseTransactionsComponent data={mergedData.filter((item) => item.order?.status === "For Payment")} allData={mergedData} isFetchingOrders={isFetchingOrders} isFetchingPurchaseTransaction={isFetchingPurchaseTransaction} />),
        },
        {
            name: "Completed",
            component: (<PurchaseTransactionsComponent data={mergedData.filter((item) => item.order?.status === "Paid" || item.order?.status === "Completed")} allData={mergedData} isFetchingOrders={isFetchingOrders} isFetchingPurchaseTransaction={isFetchingPurchaseTransaction} />),
        },
        {
            name: "Cancelled",
            component: (<PurchaseTransactionsComponent data={mergedData.filter((item) => item.order?.status === "Cancelled")} allData={mergedData} isFetchingOrders={isFetchingOrders} isFetchingPurchaseTransaction={isFetchingPurchaseTransaction} />),
        },
    ];



    return (
        <Box sx={{ margin: "0px auto auto" }}>
            <Box sx={{ flexGrow: 1, margin: "0px auto auto", mb: '18px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ textAlign: "start", mb: 0, pb: 0 }}>
                        <CustomTypography
                            variant="h6"
                            text={"MY PURCHASE"}
                            customStyles={{ mb: 0, textTransform: "uppercase" }}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Card sx={{ mb: 3 }}>
                <CardContent>
                    <Tabs
                        value={currentTab}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="purchase tabs"
                    >
                        {tabs.map((tab, index) => (
                            <Tab key={index} label={tab.name} />
                        ))}
                    </Tabs>
                </CardContent>
            </Card>

            {tabs.map((tab, index) => (
                <TabPanel key={index} value={currentTab} index={index}>
                    {tab.component}
                </TabPanel>
            ))}

        </Box>
    );
}    
