import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import DocumentListTableComponent from "../components/document-list-table.component";
import useDocument from "../hooks/useDocument";
import { Box, Button, Typography } from "@mui/material";
import CustomTypography from "../../../core/components/ui/CustomTypography";
import AddBoxIcon from '@mui/icons-material/AddBox';
import useSearchDocumentStore from "../zustand/useSearchDocumentStore";

export default function DocumentListViewScreen () {

    const [searchParams] = useSearchParams();
    const { getDocumentList, documentList, isLoadingList, pagination, 
        filterDocumentByStatus, filterDocument, setIsLoadingList, isLoadingFilter, setIsLoadingFilter, cancelDocument} = useDocument()
    const {searchInput} = useSearchDocumentStore()
    
    const navigate = useNavigate()

    const [page, setPage] = useState(1);
    const [isEmail, setIsEmail] = useState(JSON.parse(localStorage.getItem('isEmail')));
    const [isWorkspace, setIsWorkspace] = useState(JSON.parse(localStorage.getItem('isWorkspace')));
    const [menuSelected, setMenuSelected] = useState(localStorage.getItem('menuSelected'));

    useEffect(() => {
        const syncLocalStorage = () => {
            const storedIsEmail = JSON.parse(localStorage.getItem('isEmail'));
            const storedIsWorkspace = JSON.parse(localStorage.getItem('isWorkspace'));
            const storedMenuSelected = localStorage.getItem('menuSelected');
            
            if (storedIsEmail !== isEmail) setIsEmail(storedIsEmail);
            if (storedIsWorkspace !== isWorkspace) setIsWorkspace(storedIsWorkspace);
            if (storedMenuSelected !== menuSelected) setMenuSelected(storedMenuSelected);
        };

        const intervalId = setInterval(syncLocalStorage, 500);

        return () => clearInterval(intervalId);
    }, [isEmail, isWorkspace, menuSelected]);

    console.log('searchParams', searchParams.get('status'))

    const status = searchParams.get('status')

    console.log('get document list here', documentList)

    const handleChangePagination = (event, page) => {
        setPage(page);
    }

    useEffect(() => {
        if(status || searchInput){
            setPage(1);
        }
    }, [status, searchInput]);

    useEffect(() => {
        let workspace_id = isWorkspace ? menuSelected : null

        
            filterDocumentByStatus(workspace_id, status, searchInput, page);
         
    }, [page, status, searchInput, isEmail, isWorkspace, menuSelected]);
    
    

    return(
        <Box sx={{mt: 2}}>
            {/* {!documentList || documentList?.length === 0 ? (
                <Box sx={{ mt: 30 }}>
                    <CustomTypography text="No documents created yet" variant="caption" />
                    <br />
                    <Button
                        sx={{ alignSelf: 'center', color: '#604CC3', textTransform: 'none' }}
                        startIcon={<AddBoxIcon />}
                        onClick={()=>navigate('/documents/create')}
                    >
                        Create Document
                    </Button>
                </Box>
            ) : ( */}
                <DocumentListTableComponent 
                    status={status} setPage={setPage} page={page} searchInput={searchInput} isLoadingFilter={isLoadingFilter}
                    documentList={documentList} pagination={pagination} setIsLoadingList={setIsLoadingList}
                    isLoadingList={isLoadingList} filterDocument={filterDocument} handleChangePagination={handleChangePagination} cancelDocument={cancelDocument}
                />
            {/* )} */}
        </Box>
    )
}