import routes from "../common/api-routes";
import {HttpAuthGet} from "../../../../core/services/common/HttpRequestBuilder";


const getDashboardCount = (parameters) => {
    let result = HttpAuthGet(routes.GET_DASHBOARD_COUNT, parameters);

    return result;
}

const getAnalyticsCount = (parameters) => {
    let result = HttpAuthGet(routes.GET_ANALYTICS_COUNT, parameters);

    return result;
}



const DashboardService = {
    getDashboardCount,
    getAnalyticsCount
}

export default DashboardService