import { useState } from "react";
import DocumentEditorService from "../services/document-editor-service";
import { useSnackbar } from "notistack";


const useDocumentEditor = () => {

    const [isSavingToDraft, setIsSavingTodraft] = useState(false)
    const [isSavingDocument, setIsSavingDocument] = useState(false)
    const [signatoryList, setSignatoryList] = useState(null)
    const [hasDoneSavedDocumentAsDraft, setHasDoneSavedDocumentAsDraft] = useState(false)
    const [hasDoneSavedDocument, setHasDoneSavedDocument] = useState(false)
    const [automationRecord, setAutomationRecord] = useState(null)
    const { enqueueSnackbar } = useSnackbar();

    const saveDocument = (documentId, oid, newElements, filename, updatedElements, deletedElementIds, signatory,
        cc, is_certificate, is_auto_remind, is_auto_expire, is_auto_scheduled, expired_days, auto_scheduled_date_time, total_page) => {
        setIsSavingDocument(true)
        setHasDoneSavedDocument(false)
        DocumentEditorService.saveDocument(documentId, oid, newElements, filename, updatedElements, deletedElementIds, signatory,
            cc, is_certificate, is_auto_remind, is_auto_expire, is_auto_scheduled, expired_days, auto_scheduled_date_time, total_page)
        .then((res) => {
            console.log('get saving document result aaaaa', res);
            setIsSavingDocument(false)
            setHasDoneSavedDocument(true)
            // window.location.href="/documents"
            // enqueueSnackbar('Success!', {
            //     variant: "success",
            //     autoHideDuration: 2000
            // })
        })
        .catch((error) => {
            const errorMessage = error?.response?.data?.error || error.message;
            setIsSavingDocument(false)
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
            console.error('Error saving document:', error); // Error handling for API call
        });
    }

    const saveDocumentAsDraft = (documentId, newElements, filename, updatedElements, deletedElementIds, signatory,
        cc, is_certificate, is_auto_remind, total_page) => {
            setIsSavingTodraft(true)
            setHasDoneSavedDocumentAsDraft(false)
        DocumentEditorService.saveDocumentAsDraft(documentId, newElements, filename, updatedElements, deletedElementIds, signatory,
            cc, is_certificate, is_auto_remind, total_page)
        .then((res) => {
            setIsSavingTodraft(false)
            setHasDoneSavedDocumentAsDraft(true)
            console.log('get saving document as draft result aaaaa', res);
        })
        .catch((error) => {
            setIsSavingTodraft(false)
            const errorMessage = error?.response?.data?.error || error.message;
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
            console.error('Error saving document as draft:', error); // Error handling for API call
        });
    }

    const signatoryListByDocumentId = (document_id) => {
        DocumentEditorService.signatoryListByDocumentId(document_id).then((res) => {
            setSignatoryList(res?.data?.data)
            console.log('signatoryListByDocumentId res', res?.data?.data)
        }).catch((error) => {
            const errorMessage = error?.response?.data?.error || error.message;
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        });
    }

    const documentAutomationRecord = (document_id) => {
        DocumentEditorService.documentAutomationRecord(document_id).then((res) => {
            setAutomationRecord(res?.data?.data)
            console.log('signatoryListByDocumentId res', res?.data?.data)
        }).catch((error) => {
            const errorMessage = error?.response?.data?.error || error.message;
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        });
    }

    return{
        saveDocument, saveDocumentAsDraft, hasDoneSavedDocumentAsDraft, hasDoneSavedDocument, isSavingToDraft, isSavingDocument, signatoryList, signatoryListByDocumentId, documentAutomationRecord, automationRecord
    }
}

export default useDocumentEditor;