import { Box } from "@mui/material";
import AppBarComponent from "../components/layout/app-bar.component";
import DocumentDrawer from "../components/layout/document-drawer";
import DrawerHeader from "../components/layout/drawer-header";
import { auth } from '../../firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import useAuthentication from "../../plugins/profile-plugin/hooks/useAuthentication";
import useOrderManagement from "../../plugins/token-plugin/hooks/useOrderManagement";

const drawerWidth = 300;

const IDLE_TIMEOUT = 15 * 60 * 1000; // 15 minutes

export default function MainLayotScreen({ children }) {
  const location = useLocation();
  const { getProfile, profile } = useAuthentication();
  // const { pendingOrder } = useOrderManagement();
  const [user, setUser] = useState(null);

  // useRef to store the idle timer ID (no need to trigger re-renders)
  const idleTimerRef = useRef(null);

  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unregisterAuthObserver();
  }, []);

  useEffect(() => {
    if (user) {
      getProfile();
    }
  }, [user]);

  // useEffect(() => { pendingOrder(); }, []);

  useEffect(() => {
    // Clear cartItems from localStorage when navigating to /dashboard
    if (location.pathname !== '/token') {
        localStorage.removeItem('cartItems');
        localStorage.removeItem('addedToCart');
    }
}, [location.pathname]);

  // useEffect(() => {
  //   if (!user) return;

  //   const resetIdleTimer = () => {
  //     // Clear previous timer if exists
  //     if (idleTimerRef.current) {
  //       clearTimeout(idleTimerRef.current);
  //     }

  //     // Set a new idle timer
  //     idleTimerRef.current = setTimeout(() => {
  //       signOut(auth);
  //       localStorage.removeItem('wallet_id');
  //       localStorage.removeItem('isEmail');
  //       localStorage.removeItem('isWorkspace');
  //       localStorage.removeItem('profile_wallet');
  //       localStorage.removeItem('menuSelected');
  //     }, IDLE_TIMEOUT);
  //   };

  //   // Set up event listeners to reset the idle timer on user activity
  //   const events = ['mousemove', 'mousedown', 'keypress', 'scroll', 'touchstart'];
  //   events.forEach(event => {
  //     window.addEventListener(event, resetIdleTimer);
  //   });

  //   // Start/reset the idle timer
  //   resetIdleTimer();

  //   // Cleanup on component unmount or when user logs out
  //   return () => {
  //     events.forEach(event => {
  //       window.removeEventListener(event, resetIdleTimer);
  //     });
  //     if (idleTimerRef.current) {
  //       clearTimeout(idleTimerRef.current);
  //     }
  //   };
  // }, [user]); // Only run the effect when the `user` state changes

  return (
    <>
      {(location.pathname !== "/accept") &&
        <>
          <AppBarComponent />
          <DocumentDrawer user={user} />
        </>
      }

      <Box component="main" sx={{
        flexGrow: 1, marginLeft: (location.pathname === "/accept") ? {} : `${drawerWidth}px`,
        p: (location.pathname !== "/accept") ? 4 : {}
      }}>
        {(location.pathname !== "/accept") && <DrawerHeader />}

        {children}
      </Box>
    </>
  );
}