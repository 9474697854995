import routes from "../common/api-routes";
import {HttpAuthGet, HttpAuthPost, HttpAuthPut, HttpAuthDelete, HttpAuthGetBlob} from "../../../../../core/services/common/HttpRequestBuilder";

const saveDocument = (documentId, oid, newElements = [], filename, updatedElements, deletedElementIds, signatory,
    cc, is_certificate, is_auto_remind, is_auto_expire, is_auto_scheduled, expired_days, auto_scheduled_date_time, total_page) => {

    var payload = {
        documentId,
        oid,
        newElements,
        filename,
        updatedElements: updatedElements,
        deletedElementIds: deletedElementIds,
        signatory: signatory,
        cc: cc,
        is_certificate: is_certificate,
        is_auto_remind: is_auto_remind,
        is_auto_expire: is_auto_expire,
        is_auto_scheduled: is_auto_scheduled,
        is_auto_scheduled: is_auto_scheduled,
        expired_days: expired_days,
        auto_scheduled_date_time: auto_scheduled_date_time,
        total_page: total_page
    }
    
    let result = HttpAuthPost(routes.SAVE_DOCUMENT, payload);

    return result;
}

const documentElementProperties = (documentId) => {

    let query_params = {
        document_id: documentId
    } 
    let result = HttpAuthGet(routes.DOCUMENT_ELEMENT_PROPERTIES, query_params);

    return result;
}

const saveDocumentAsDraft = (documentId, newElements = [], filename, updatedElements, deletedElementIds, signatory,
    cc, is_certificate, is_auto_remind, total_page) => {

    var payload = {
        documentId,
        newElements,
        filename,
        updatedElements: updatedElements,
        deletedElementIds: deletedElementIds,
        signatory: signatory,
        cc: cc,
        is_certificate: is_certificate,
        is_auto_remind: is_auto_remind,
        total_page: total_page
    }
    
    let result = HttpAuthPost(routes.SAVE_DOCUMENT_TO_DRAFT, payload);

    return result;
}

const saveSignatory = (recently_added_signees) => {

    var payload = {
        recently_added_signees,
    }
    
    let result = HttpAuthPost(routes.SAVE_SIGNATORY, payload);

    return result;
}

const signatoryList = () => {

    let query_params = null
    
    let result = HttpAuthGet(routes.SIGNATORY_LIST, query_params);

    return result;
}

const signatoryListByDocumentId = (document_id) => {
    let query_params = {
        document_id: document_id
    }

    let result = HttpAuthGet(routes.SIGNATORY_LIST_BY_DOCUMENT_ID, query_params);

    return result;
}

const updateSignatory = (document_id, email, updated_email) => {

    var payload = {
        document_id,
        email,
        updated_email,
    }
    
    let result = HttpAuthPut(routes.UPDATE_SIGNATORY, null, payload);

    return result;
}

const updateSignatoryv2 = (document_id, email, updated_email) => {

    var payload = {
        document_id,
        email,
        updated_email,
    }
    
    let result = HttpAuthPut(routes.UPDATE_EMAIL, null, payload);

    return result;
}

const deleteSignatory = (document_id, email) => {

    var query_params = {
        document_id,
        email,
    }
    
    let result = HttpAuthDelete(routes.DELETE_SIGNATORY, query_params);
    return result;
}

const documentAutomationRecord = (document_id) => {
    let query_params = {
        document_id: document_id
    }

    let result = HttpAuthGet(routes.DOCUMENT_AUTOMATION_RECORD, query_params);

    return result;
}

const updateSignatoryEmail = (document_id, email, updated_email) => {

    var payload = {
        document_id,
        email,
        updated_email,
    }
    
    let result = HttpAuthPut(routes.UPDATE_SIGNATORY, null, payload);

    return result;
}

const DocumentEditorService = {
    saveDocument: saveDocument,
    documentElementProperties: documentElementProperties,
    saveDocumentAsDraft: saveDocumentAsDraft,
    saveSignatory: saveSignatory,
    signatoryList: signatoryList,
    signatoryListByDocumentId : signatoryListByDocumentId,
    updateSignatory: updateSignatory,
    deleteSignatory: deleteSignatory,
    documentAutomationRecord: documentAutomationRecord,
    updateSignatoryEmail: updateSignatoryEmail,
    updateSignatoryv2 : updateSignatoryv2
}

export default DocumentEditorService