import { Box, Typography, Card, CardContent, Button } from "@mui/material";

export default function SuccessPaymentScreen() {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh", // Full viewport height
                backgroundColor: "#f4f4f9", // Optional background color
                padding: "20px",
            }}
        >
            <Card
                sx={{
                    backgroundColor: "#f9f9f9",
                    padding: "20px",
                    borderRadius: "12px",
                    boxShadow: 3,
                    textAlign: "center",
                    maxWidth: "500px",
                }}
            >
                <CardContent>
                    <Typography variant="h4" component="div" gutterBottom>
                        🎉 Thank You!
                    </Typography>
                    <Typography variant="body1" color="textSecondary" gutterBottom>
                        Your token purchase was successful! We appreciate your support and trust.
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ margin: "10px 0" }}>
                        You can now enjoy exclusive benefits and explore more features.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            marginTop: "20px",
                            padding: "10px 20px",
                            borderRadius: "8px",
                            color: "#fff",
                            backgroundColor: "#604CC3",
                        }}
                        onClick={() => window.location.href = '/dashboard'}
                    >
                        Go to Dashboard
                    </Button>
                </CardContent>
            </Card>
        </Box>
    );
}
