import React from "react";
import {Table, Skeleton, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from "@mui/material";
import { useLocation } from "react-router-dom";

const CustomTableSkeletonLoaderComponent = ({headers, hasAction}) => {

    const location = useLocation();
    const isTransactions =
        location.pathname === '/purchase-transactions' ||
        location.pathname === '/token-transactions' ||
        location.pathname === '/order-transactions';

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead sx={{
                    backgroundColor: isTransactions ? '#604CC3' : 'inherit', 
                    color: isTransactions ? 'white' : 'inherit', 
                }}>
                    <TableRow>
                        {headers.map((header, index) => (
                            <TableCell key={index} align="start" sx={{
                                color: isTransactions ? 'white' : 'inherit', 
                            }}>
                                {header}
                            </TableCell>
                        ))}

                        {hasAction && <TableCell align="center" sx={{ color: isTransactions ? 'white' : 'inherit'}}>Actions</TableCell>}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {Array.from(new Array(3)).map((_, rowIndex) => (
                        <TableRow key={rowIndex}>
                            {headers.map((header, cellIndex) => (
                                <TableCell key={cellIndex} align="start">
                                    <Skeleton variant="text" width="80%" sx={{backgroundColor: "#eeebf8"}} />
                                </TableCell>
                            ))}

                            {hasAction && (
                                <TableCell align="end">
                                    <Skeleton variant="text" width="80%" sx={{backgroundColor: "#eeebf8"}} />
                                </TableCell>
                            )}
                        </TableRow>
                    ))} 
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default CustomTableSkeletonLoaderComponent