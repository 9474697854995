import React, {useEffect, useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid2";
import {Box} from "@mui/material";
import CustomButton from "../../../core/components/ui/CustomButton";
import CustomTypography from "../../../core/components/ui/CustomTypography";
import CreateWorkspaceComponent from "../components/workspace-create.component";
import CustomDynamicTabsComponent from "../../../core/components/common/custom-dynamic-tabs.component";
import MyWorkspaceComponent from "../components/my-workspace.component";
import BusinessWorkspaceComponent from "../components/business-workspace.component";
import useCreatedWorkspaceStore from "../zustand/useCreatedWorkspaceStore";

const WorkspaceListScreen = () => {
    const {isWorkspaceCreated} = useCreatedWorkspaceStore();

    const [open, setOpen] = useState(false);


    useEffect(() => {
        if (isWorkspaceCreated) {
            setOpen(false); 
        }
    }, [isWorkspaceCreated])


    const handleClickOpen = () => {
        setOpen(true);
    }

    const tabs = [
        {name: "My Workspaces", component: <div><MyWorkspaceComponent /></div>, fontSize: 18, marginRight: "30px"},
        {name: "Business Workspaces", component: <div><BusinessWorkspaceComponent /></div>, fontSize: 18},
    ]


    return (
        <>
            <Box sx={{flexGrow: 1, margin: "0px auto auto"}}>
                <Grid container spacing={2}>
                    <Grid size={{xs: 6, sm: 6, md: 6, lg: 6, xl: 6}} sx={{textAlign: "start"}}>
                        <CustomTypography 
                            variant="h6" 
                            text={"Workspaces"}
                            customStyles={{mb: 0}} 
                        />
                    </Grid>

                    <Grid size={{xs: 6, sm: 6, md: 6, lg: 6, xl: 6}} sx={{textAlign: "end"}}>
                        <CustomButton 
                            variant="contained" 
                            startIcon={<AddIcon fontSize="large" />}
                            label="Create Workspace"
                            customStyles={{}}
                            onClick={handleClickOpen}
                        />    
                    </Grid>
                </Grid>

                <Grid size={{xs: 12}} sx={{textAlign: "start", mt: "2px"}}> 
                    <CustomTypography 
                        variant="body1" 
                        text={"Provides a flexible, organized, and collaborative environment where teams can work together on specific documents or goals while keeping everything compartmentalized, efficient, and easy to manage."}
                        customStyles={{color: "rgba(88, 86, 95, 0.79)"}}
                    />
                </Grid>

                <Grid container spacing={5} sx={{mt: 6}}>
                    <CustomDynamicTabsComponent tabs={tabs} />
                </Grid>
            </Box>

            {open &&
                <CreateWorkspaceComponent 
                    open={open} 
                    setOpen={setOpen} 
                />
            }
        </>
    )
}

export default WorkspaceListScreen