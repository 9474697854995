import React, {useState, useEffect} from "react";
import {Box, Typography, TableContainer, Paper} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CustomButton from "../../../core/components/ui/CustomButton";
import InviteMembersComponent from "./workspace-invite-members.component";
import useWorkspaceManagement from "../hooks/useWorkspaceManagement";
import useMembersManagement from "../hooks/useMembersManagement";
import useInvitedMemberStore from "../zustand/useUpdateInvitedMemberStore";
import CustomTableSkeletonLoaderComponent from "../../../core/components/common/custom-table-skeleton-loader.component";
import WorkspaceMembersTable from "./workspace-members.table";
import useSelectedWorkspaceMemberStore from "../zustand/useSelectedWorkspaceMemberStore";
import WorkspaceInvitedMembersTable from "./workspace-invited-members.table";
import ErrorIcon from '@mui/icons-material/Error';
import WorkspaceSigneesTable from "./workspace-signees.table";
import AddSigneeDialog from "./dialog/add-signee.dialog";
import useSigneeStore from "../zustand/useSigneeStore";
import useAuthentication from "../../profile-plugin/hooks/useAuthentication";

const member_headers = ["Email", "Workspace Role", "Status", "Actions"];
const invited_member_headers = ["Email", "Workspace Role", "Status", "Actions"];
const signees_headers = ["Email", "Status", "Actions"];

const WorkspaceMembersComponent = ({workspaceId}) => {
  const {workspaceRoles, getWorkspaceRoles, workspaceDetails, viewWorkspace, getWorkspaceRBACPermissions, filteredWorkspaceRoles} = useWorkspaceManagement();
  const {memberList, invitedMemberList, isFetchingMembers, isFetchingInvitees, getMemberList, getInvitedMemberList, countRemainingWorkspaceMembers, memberSlots,
    isFetchingWSGuestSigneeList, WSGuestSigneeList, getWSGuestSigneeList
  } = useMembersManagement();

  const {isMemberInvited, isInvitationCancelled, isInvitationResend, isGuestAddedAsMember} = useInvitedMemberStore();
  const {isMemberRemoved, isMemberStatusUpdated, isMemberRoleUpdated, workspaceOwner} = useSelectedWorkspaceMemberStore();
  const {isSigneeStatusUpdated, isSigneeDeleted, isSigneeAdded} = useSigneeStore();

  const {viewProfile, profileData} = useAuthentication();

  const [open, setOpen] = useState(false);
  const [canCountRemainingMembers, setCanCountRemainingMembers] = useState(false);
  const [canInviteMember, setCanInviteMember] = useState(false);
  const [canListMembers, setCanListMembers] = useState(false);
  const [canViewMember, setCanViewMember] = useState(false);
  const [canUpdateMemberStatus, setCanUpdateMemberStatus] = useState(false);
  const [canDeleteMember, setCanDeleteMember] = useState(false);
  const [canListInvitedMembers, setCanListInvitedMembers] = useState(false);
  const [canCancelInvitation, setCanCancelInvitation] = useState(false);
  const [canResendInvitation, setCanResendInvitation] = useState(false);
  const [canViewWorkspace, setCanViewWorkspace] = useState(false);
  const [canUpdateMemberRole, setCanUpdateMemberRole] = useState(false);
  const [openAddSignee, setOpenAddSignee] = useState(false);


  useEffect(() => { 
    viewProfile();  
  }, [])

  useEffect(() => {
    if (workspaceId) {
      const parameters = {
        workspace_id: workspaceId
      }
        
      getWorkspaceRBACPermissions(parameters);
    }
  }, [workspaceId])

   useEffect(() => {
    if (filteredWorkspaceRoles?.length) {
      const userPermissions = {
        canCountRemainingMembers: filteredWorkspaceRoles?.some(role => role["WORKSPACE_MEMBERS_COUNT_REMAINING"]),
        canInviteMember: filteredWorkspaceRoles?.some(role => role["WORKSPACE_INVITE_SEND"]),
        canListMembers: filteredWorkspaceRoles?.some(role => role["WORKSPACE_MEMBERS_LIST"]),
        canViewMember: filteredWorkspaceRoles?.some(role => role["WORKSPACE_MEMBERS_VIEW"]),
        canUpdateMemberStatus: filteredWorkspaceRoles?.some(role => role["WORKSPACE_MEMBERS_UPDATE_STATUS"]),
        canDeleteMember: filteredWorkspaceRoles?.some(role => role["WORKSPACE_MEMBERS_DELETE"]),
        canListInvitedMembers: filteredWorkspaceRoles?.some(role => role["WORKSPACE_INVITE_LIST"]),
        canCancelInvitation: filteredWorkspaceRoles?.some(role => role["WORKSPACE_INVITE_CANCEL"]),
        canResendInvitation: filteredWorkspaceRoles?.some(role => role["WORKSPACE_INVITE_RETRY"]),
        canViewWorkspace: filteredWorkspaceRoles?.some(role => role["WORKSPACE_VIEW"]),
        canUpdateMemberRole: filteredWorkspaceRoles?.some(role => role["WORKSPACE_MEMBERS_UPDATE_ROLE"]),
      }
        
      setCanCountRemainingMembers(userPermissions?.canCountRemainingMembers);
      setCanInviteMember(userPermissions?.canInviteMember);
      setCanListMembers(userPermissions?.canListMembers);
      setCanViewMember(userPermissions?.canViewMember);
      setCanUpdateMemberStatus(userPermissions?.canUpdateMemberStatus);
      setCanDeleteMember(userPermissions?.canDeleteMember);
      setCanListInvitedMembers(userPermissions?.canListInvitedMembers);
      setCanCancelInvitation(userPermissions?.canCancelInvitation);
      setCanResendInvitation(userPermissions?.canResendInvitation);
      setCanViewWorkspace(userPermissions?.canViewWorkspace);
      setCanUpdateMemberRole(userPermissions?.canUpdateMemberRole);
    }
  }, [(filteredWorkspaceRoles)]); 
       
  useEffect(() => {
    getWorkspaceRoles();
  }, [])

  useEffect(() => {
    const parameters = {
      workspace_id: workspaceId
    }

    const viewParameters = {
      id: workspaceId
    }
  
    if (canListMembers) {
      getMemberList(parameters);
    }

    if (canViewWorkspace) {
      viewWorkspace(viewParameters);
    }
  
    if (canCountRemainingMembers) {
      countRemainingWorkspaceMembers(parameters);
    }
  }, [workspaceId, canListMembers, canViewWorkspace, canCountRemainingMembers]);

  useEffect(() => {
    if (workspaceId && canListInvitedMembers) {
      const parameters = {
        workspace_id: workspaceId
      }
  
      getInvitedMemberList(parameters);
    }
  }, [workspaceId, canListInvitedMembers])

  useEffect(() => {
    if (workspaceId && canListInvitedMembers && (isInvitationCancelled || isInvitationResend)) {
      const parameters = {
        workspace_id: workspaceId
      }
  
      getInvitedMemberList(parameters);
    }
  }, [workspaceId, isInvitationCancelled, isInvitationResend, canListInvitedMembers]);

  useEffect(() => {
    const parameters = {
      workspace_id: workspaceId
    }
    
    if (workspaceId && canListInvitedMembers && isMemberInvited) {
      getInvitedMemberList(parameters);

      if (canCountRemainingMembers) {
        countRemainingWorkspaceMembers(parameters);
      } 
    }
  }, [workspaceId, isMemberInvited, canListInvitedMembers, canCountRemainingMembers])

  useEffect(() => {
    if (workspaceId && canListMembers && (isMemberRemoved || isGuestAddedAsMember || isMemberStatusUpdated || isMemberRoleUpdated)) {
      const parameters = {
        workspace_id: workspaceId
      }

      getMemberList(parameters);
    }
  }, [workspaceId, canListMembers, isMemberRemoved, isGuestAddedAsMember, isMemberStatusUpdated, isMemberRoleUpdated])

  useEffect(() => {
    if (workspaceId || (workspaceId && (isSigneeStatusUpdated || isSigneeDeleted || isSigneeAdded))) {
      const parameters = {
        workspace_id: workspaceId
      }
  
      getWSGuestSigneeList(parameters);
    }
  }, [workspaceId, isSigneeStatusUpdated, isSigneeDeleted, isSigneeAdded])


  const handleClickOpen = () => {
    setOpen(true);
  }


  return (
    <Box sx={{padding: "12px"}}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Box sx={{textAlign: "start"}}>
          <Typography variant="h6">Members</Typography>

          {(canCountRemainingMembers) &&
            <Typography variant="subtitle2" sx={{color: "rgb(88, 86, 95)", fontWeight: 400, fontSize: 15}}>
              Remaining Member Slots: <strong>{memberSlots?.remaining}</strong> 
            </Typography>
          }
        </Box>
         
        {(canInviteMember) &&
          <CustomButton 
            variant="contained" 
            startIcon={<AddIcon fontSize="small" />}
            label="Invite Member"
            customStyles={{
              fontSize: 14, 
              opacity: (memberSlots?.remaining === 0) ? "55%" : "100%", 
              cursor: (memberSlots?.remaining === 0) ? "not-allowed" : "pointer",
            }}
            onClick={(memberSlots?.remaining === 0) ? null : handleClickOpen}
          />
        }
      </Box>

      {(isFetchingMembers) ?
        <CustomTableSkeletonLoaderComponent 
          headers={member_headers} 
          hasAction={false}
        /> 
      : 
        (canListMembers) ? (
          <WorkspaceMembersTable 
            headers={member_headers} 
            data={memberList} 
            workspaceDetails={workspaceDetails}
            canViewMember={canViewMember}
            canUpdateMemberStatus={canUpdateMemberStatus}
            canDeleteMember={canDeleteMember}
            workspaceRoles={workspaceRoles}
            canUpdateMemberRole={canUpdateMemberRole}
            profileData={profileData}
          />
        )
      :
        <TableContainer component={Paper} sx={{padding: "35px 0px", border: "1px solid #58565f24", boxShadow: "none"}}>
          <Typography
            variant="body1"
            sx={{display: "flex", justifyContent: "center", alignItems: "center", gap: 1, color: "#58565f"}}
          >
            <ErrorIcon color="error" sx={{fontSize: 20}} />
              You do not have permission to view this content.
          </Typography>
        </TableContainer>
      }

      <Box sx={{textAlign: "start", mb: 3, mt: "27px"}}>
        <Typography variant="h6">Invited Members</Typography>

        {(canCountRemainingMembers) &&
          <Typography variant="subtitle2" sx={{color: "rgb(88, 86, 95)", fontWeight: 400, fontSize: 15}}>
            Remaining Invite Slots: <strong>{memberSlots?.invites}</strong> 
          </Typography>
        }
      </Box>

      {(isFetchingInvitees) ?
        <CustomTableSkeletonLoaderComponent 
          headers={invited_member_headers} 
          hasAction={false}
        /> 
      :
        (canListInvitedMembers) ? (
          <WorkspaceInvitedMembersTable 
            headers={invited_member_headers}
            data={invitedMemberList} 
            canCancelInvitation={canCancelInvitation}
            canResendInvitation={canResendInvitation}
          />
        )
      :
        <TableContainer component={Paper} sx={{padding: "35px 0px", border: "1px solid #58565f24", boxShadow: "none"}}>
          <Typography
            variant="body1"
            sx={{display: "flex", justifyContent: "center", alignItems: "center", gap: 1, color: "#58565f"}}
          >
            <ErrorIcon color="error" sx={{fontSize: 20}} />
              You do not have permission to view this content.
          </Typography>
        </TableContainer>
      }

      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{mb: "28px", mt: 4}}>
        <Box sx={{textAlign: "start"}}>
          <Typography variant="h6">Signees</Typography>
        </Box>
  
        {(workspaceOwner?.created_by === profileData?.profile?.userId) &&
          <CustomButton 
            variant="contained" 
            startIcon={<AddIcon fontSize="small" />}
            label="Add Signee"
            customStyles={{
              fontSize: 14, 
              opacity: "100%", 
              cursor: "pointer",
            }}
            onClick={(e) => setOpenAddSignee(true)}
          />
        }
      </Box>

      {(isFetchingWSGuestSigneeList) ?
        <CustomTableSkeletonLoaderComponent 
          headers={signees_headers} 
          hasAction={false}
        /> 
      :
        <WorkspaceSigneesTable 
          headers={signees_headers}
          data={WSGuestSigneeList} 
        />
      }

      {open &&
        <InviteMembersComponent 
          open={open} 
          setOpen={setOpen}
          workspaceRoles={workspaceRoles} 
          workspaceId={workspaceId}
        />
      }

      {openAddSignee &&
        <AddSigneeDialog
          open={openAddSignee} 
          setOpen={setOpenAddSignee}
          workspaceId={workspaceId}
        />
      }
    </Box>
  )
}

export default WorkspaceMembersComponent
