import { useState } from "react";
import DocumentService from "../services/document-service";
import { useSnackbar } from "notistack";
import {blobToBase64} from "../../../core/utils/blobToBase64Utils";


const useDocument = () => {

    const [documentById, setDocumentById] = useState(null);
    const [documentList, setDocumentList] = useState(null);
    const [decryptedFile, setDecryptedFile] = useState(null)
    const [isCreatingDocument, setIsCreatingDocument] = useState(null)
    const [isLoadingList, setIsLoadingList] = useState(false)
    const [pagination, setPagination] = useState(0)
    const [filterDocument, setFilterDocument] = useState(null)
    const [isLoadingFilter, setIsLoadingFilter] = useState(null)
    const [isFetchingHistory, setFetchingHistory] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [isResendingDocument, setResendingDocument] = useState(false);
    const [totalDocument, setTotalDocument] = useState(0);
    const [isCancelDocument, setIsCancelDocument] = useState(false);
    const [documentTransaction, setGetDocumentTransaction] = useState(null)
    const [requiredActionList, setRequiredActionList] = useState(null)
    const [createDocumentError, setCreateDocumentError] = useState(null)

    const { enqueueSnackbar } = useSnackbar();
    const limit = 10;

    const createDocument = (payload) => {
        setIsCreatingDocument(true)
        DocumentService.createDocument(payload).then((res)=> {
            console.log('create document res', res)
            if(res){
                // setDocument(res.data.data)
                setIsCreatingDocument(false)
                let document_id = res.data.data.id;
                window.location.href="/document-editor?id=" + document_id;
            }
        }).catch((err)=>{
            setIsCreatingDocument(false)
            console.log('create document err', err)

            const errorMessage = err?.response?.data?.error || err.message;

            if(errorMessage === "Insufficient Token Balance"){
                setCreateDocumentError(err)
            }
             
            if (errorMessage !== "Insufficient Token Balance"){
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        }
        })
    }

    const getDocumentById = (id) => {
        DocumentService.getDocumentById(id).then((res)=> {
            console.log('get document by id res', res)
            if(res){
                setDocumentById(res.data.data);
            }
        }).catch((err)=>{
            console.log('get document by id err', err)

            const errorMessage = err?.response?.data?.error || err.message;
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    const viewDocument = async (encrypted_file) => {
        try {
            const res = await DocumentService.viewDocument(encrypted_file);
            console.log('view decrypted file res', res);
    
            if (res) {
                const base64File = await blobToBase64(res.data);
                
                setDecryptedFile(base64File);
            }
        } catch (err) {
            console.log('view decrypted file err', err);
        }
    };
    

    const getDocumentList = (workspace_id, page) => {
        setIsLoadingList(true)
        DocumentService.getDocumentList(workspace_id, page, limit).then((res)=> {
            console.log('get document list res', res.data.data)
            setIsLoadingList(false)
            if(res){
                setDocumentList(res?.data?.data)
                setTotalDocument(res?.data?.total)

                if(res.data.data.length === 0){
                    setPagination(0)
                }else{
                var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
                setPagination(last_page)
                }
            }
        }).catch((err)=>{
            console.log('get document list err', err)
            setIsLoadingList(false)
            setPagination(0)

            const errorMessage = err?.response?.data?.error || err.message;
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    const filterDocumentByStatus = (workspace_id, status, title, page) => {
        
        setIsLoadingList(true)
        DocumentService.filterDocumentByStatus(workspace_id, status, title, page, limit).then((res)=> {
            console.log('get filter document by status res', res.data.data)
            if(res){
                setFilterDocument(res?.data?.data)
                setIsLoadingList(false)
                
                if(res.data.length === 0){
                    setPagination(0)
                }else{
                var last_page = Math.ceil(res.data.total / limit ); //set it depending on ui return - 18
                    setPagination(last_page)
                }
            }
        }).catch((err)=>{
            console.log('get filter document by status err', err)
            setPagination(0)

            const errorMessage = err?.response?.data?.error || err.message;
            setIsLoadingList(false)
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }


    const getDocumentHistory = (parameters) => {
        setFetchingHistory(true);
        DocumentService.getDocumentHistory(parameters).then((res)=> {
            console.log('get document history res', res.data.data)
            if(res){
                setFetchingHistory(false);
                setHistoryData(res.data.data);
            }
        }).catch((err)=>{
            console.log('get document histroy err', err)
            setFetchingHistory(false);
            const errorMessage = err?.response?.data?.error || err.message;
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }


    const resendDocument = (payload, handleCloseResendDialog) => {
        setResendingDocument(true);
        DocumentService.resendDocument(payload).then((res)=> {
            console.log('resend document res', res)
            if(res){
                setResendingDocument(false);
                handleCloseResendDialog();
                enqueueSnackbar("Document successfully send.", { 
                    variant: "success",
                    autoHideDuration: 3000
                });
                window.location.reload();
            }
        }).catch((err)=>{
            console.log('resend document err', err)
            setResendingDocument(false);
            const errorMessage = err?.response?.data?.error || err.message;
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    const cancelDocument = (documentById, cancel_reason, setOpenCancelConfirmation) => {
        let document_id = documentById;
        setIsCancelDocument(true)
        DocumentService.cancelDocument(document_id, cancel_reason).then((res)=>{
            setIsCancelDocument(false)
            setOpenCancelConfirmation(false)
            enqueueSnackbar("Document successfully canceled.", { 
                variant: "success",
                autoHideDuration: 3000
            });
            console.log('get cancel document res here', res)
        }).catch((err) => {
            console.log('get cancel reason err', err)
            const errorMessage = err?.response?.data?.error || err.message;
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
            setIsCancelDocument(false)
        })
    }

    const getDocumentTransactionPrice = (fileSize) => {
        DocumentService.getDocumentTransactionPrice(fileSize).then((res) => {
            setGetDocumentTransaction(res.data.data.data)
        }).catch((err) => {
            console.log('get cancel reason err', err)
            const errorMessage = err?.response?.data?.error || err.message;
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
            setIsCancelDocument(false)
        })
    }

    const getRequiredActionList = (workspace_id, page) => {
        setIsLoadingList(true)
        DocumentService.getRequiredActionList(workspace_id,page, limit).then((res) =>{
            setIsLoadingList(false)
            setRequiredActionList(res.data.data)
            console.log('getRequiredActionListgetRequiredActionList getRequiredActionList', res)
            if(res.data.data.length === 0){
                setPagination(0)
            }else{
            var last_page = Math.ceil(res.data.total / limit); //set it depending on ui return - 18
            setPagination(last_page)
            }
        }).catch((err) => {
            setIsLoadingList(false)
            console.log('getRequiredActionList err', err)
            const errorMessage = err?.response?.data?.error || err.message;
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
            setIsCancelDocument(false)
        })
    }


    return {createDocument, getDocumentById, documentById, getDocumentList, documentList, viewDocument, decryptedFile, 
        isCreatingDocument, isLoadingList, pagination, filterDocumentByStatus, filterDocument, setIsLoadingList, isLoadingFilter, setIsLoadingFilter,
        getDocumentHistory, isFetchingHistory, historyData, resendDocument, isResendingDocument, totalDocument, cancelDocument, isCancelDocument, getDocumentTransactionPrice, documentTransaction, getRequiredActionList, requiredActionList, createDocumentError,}
}

export default useDocument