import { Box } from '@mui/material';
import CustomTypography from '../../../core/components/ui/CustomTypography';
import Grid from '@mui/material/Grid2';
import CheckoutComponent from '../components/checkout.component';
import BackButtonComponent from '../../../core/components/common/custom-back-button.component';
import { useNavigate } from 'react-router-dom';
import useOrderManagement from '../hooks/useOrderManagement';
import { useEffect, useState } from 'react';


export default function CheckoutScreen() {

    const navigate = useNavigate();

    const { pendingOrder, hasPending } = useOrderManagement();

    const orderId = new URLSearchParams(window.location.search).get("orderId");
    const profileWalletId = localStorage.getItem('profile_wallet');
    const [isEmail, setIsEmail] = useState(JSON.parse(localStorage.getItem('isEmail')));
    const [isWorkspace, setIsWorkspace] = useState(JSON.parse(localStorage.getItem('isWorkspace')));
    const [wallet_id, setWalletId] = useState(localStorage.getItem('wallet_id'));

    const [isCleared, setIsCleared] = useState(false);


    useEffect(() => {
        const syncLocalStorage = () => {
            const storedIsEmail = JSON.parse(localStorage.getItem('isEmail'));
            const storedIsWorkspace = JSON.parse(localStorage.getItem('isWorkspace'));
            const storedWalletId = localStorage.getItem('wallet_id');

            if (storedIsEmail !== isEmail) setIsEmail(storedIsEmail);
            if (storedIsWorkspace !== isWorkspace) setIsWorkspace(storedIsWorkspace);
            if (storedWalletId !== wallet_id) setWalletId(storedWalletId);
        };

        const intervalId = setInterval(syncLocalStorage, 500);

        return () => clearInterval(intervalId);
    }, [isEmail, isWorkspace, wallet_id]);



    // useEffect(() => {
    //     let parameters = {};

    //     if (isEmail) {
    //         // if (profileWalletId) {
    //         //     parameters = { "walletId": profileWalletId }
    //         //     pendingOrder(parameters);
    //         // }
    //         navigateTo(`/token`);
    //     } else if (isWorkspace) {
    //         // parameters = { "walletId": wallet_id ? wallet_id : null }
    //         // pendingOrder(parameters);
    //         navigateTo(`/token`);
    //     }


    // }, [isEmail, isWorkspace, wallet_id, profileWalletId]);

    // const clearOrderId = () => {
    //     window.history.replaceState(null, '', '/');
    //     setIsCleared(true);
    // };


    const navigateTo = (path) => {
        if (window.location.pathname + window.location.search !== path) {
            // console.log("Navigating to:", path);
            navigate(path);
        } else {
            console.log("Already on the target path:", path);
        }
    };


    // useEffect(() => {
    //     let order_id = localStorage.getItem('orderId');

    //     console.log("checkoutxx isWorkspace:", isWorkspace);
    //     console.log("checkoutxx isEmail:", isEmail);
    //     console.log("checkoutxx hasPending:", hasPending);
    //     console.log("checkoutxx orderId from URL:", orderId);
    //     console.log("checkoutxx orderId from localStorage:", order_id);


    //     if (isWorkspace && hasPending) {
    //         if (orderId) {
    //             navigateTo(`/token/checkout?orderId=${orderId}`);
    //         } else if (order_id) {
    //             navigateTo(`/token?orderId=${order_id}`);
    //         }
    //     }

    //     else if (isWorkspace && !hasPending) {
    //         console.log("xxxxxxxxxxCondition: isWorkspace && !hasPending");
    //         console.log("xxxxxxxxxxorderId:", orderId);
    //         console.log("xxxxxxxxxxhasPending:", hasPending);

    //         clearOrderId();

    //         if (isCleared) {
    //             if (orderId) {
    //                 console.log("xxxxxxxxxxNavigating to:", `/token/checkout?orderId=${orderId}`);
    //                 //   navigateTo(`/token/checkout?orderId=${orderId}`);
    //                 window.location.href = `/token/checkout?orderId=${orderId}`;
    //             } else {
    //                 console.log("xxxxxxxxxxNavigating to: /token");
    //                 //   navigateTo(`/token`);
    //                 window.location.href = `/token`;
    //             }
    //         }


    //     } else if (isEmail && hasPending) {
    //         if (orderId) {
    //             navigateTo(`/token/checkout?orderId=${orderId}`);
    //         } else if (order_id) {
    //             navigateTo(`/token?orderId=${order_id}`);
    //         }
    //     } else if (isEmail && !hasPending) {
    //         clearOrderId();
    //         if (orderId) {
    //             navigate(`/token/checkout?orderId=${orderId}`)
    //         } else {
    //             console.log("buytoken hasPending :", hasPending)
    //             navigate(`/token`);
    //         }
    //     }
    // }, [hasPending, isWorkspace, isEmail, orderId])

    // if (!isWorkspace && !isEmail) {
    //     const searchParams = new URLSearchParams(window.location.search);
    //     searchParams.delete("orderId");
    //     navigate(`/current-page?${searchParams.toString()}`);
    // }




    // useEffect(() => {
    //     let order_id = localStorage.getItem('orderId');

    //     if(isWorkspace && hasPending){
    //         console.log("buytoken hasPending :", hasPending)
    //         navigate(`/token?orderId=${order_id}`);
    //     } else if (isWorkspace && !hasPending) {
    //         console.log("buytoken hasPending :", hasPending)
    //         navigate(`/token`);
    //     }
    // },[hasPending, isWorkspace])

    return (
        <Box sx={{ margin: "0px auto auto" }}>
            <Box sx={{
                backgroundColor: "transparent", padding: "26px 50px", paddingBottom: '10px',
                border: "none", boxShadow: "none", mt: "0px", overflow: 'hidden !important'
            }}>
                <Box sx={{ flexGrow: 1, margin: "0px auto auto" }}>
                    <Grid container spacing={2}>
                        <Grid size={12} sx={{ textAlign: "start", mb: 0, pb: 0 }}>
                            <CustomTypography
                                variant="h6"
                                text={"CHECKOUT"}
                                customStyles={{ mb: 0, textTransform: 'uppercase' }}
                            />
                        </Grid>
                    </Grid>

                    <br />

                    <CheckoutComponent 
                        profileWalletId={profileWalletId}
                        isEmail={isEmail}
                        isWorkspace={isWorkspace}
                        wallet_id={wallet_id}
                    />
                </Box>
            </Box>
        </Box>

    );
}
