import { Typography } from "@mui/material"

const CustomStatusLabel = ({label}) => {

      // Map labels to their respective colors
      const labelColors = {
        "draft": "#604CC3",
        "completed": "#008000",
        "ongoing": "#FFA500",
        "error": "#FF0000",
        "denied": "#830221",
        "cancelled": "#FF0000",
        "scheduled": "#bdb622",
        "expired": "#FF0000"
    };

    const normalizedLabel = String(label).toLowerCase();

    // Default color is red if label is not in the map
    const textColor = labelColors[normalizedLabel] || 'black';

    return (
        <div>
            <Typography style={{ color: textColor, fontSize: 14 }}>
                {label}
            </Typography>
        </div>
    )
}

export default CustomStatusLabel