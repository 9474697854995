import { Box, Button, ButtonGroup, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { format } from "date-fns";
import PendingIcon from '@mui/icons-material/Pending';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { useState } from "react";
import { useSnackbar } from "notistack";
import CloseIcon from '@mui/icons-material/Close';
import {ThreeDots} from "react-loader-spinner";
import useDocument from "../../hooks/useDocument";
import DocumentService from "../../services/document-service";
import DocumentEditorService from "../../../supersign-docs-plugin/document-editor-plugin/services/document-editor-service";

const ActionsComponent = ({list, email, documentList, editEmail, setEditEmail, index}) => {

    const [selected, setSelected] = useState(false)
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);
    
    const handleResendEmail = () => {
        setSelected(true)
        let payload ={
            document_id : documentList.id 
        }
        DocumentService.resendDocument(payload).then((res)=>{
            setSelected(false)
            if (res.data.success) {
                enqueueSnackbar("Email successfully sent.", { 
                  variant: "success",
                  autoHideDuration: 3000
                });
            }
        }).catch((error) => {
            enqueueSnackbar("Email unsuccessfully send.", { 
              variant: "error",
              autoHideDuration: 3000
            });
            setSelected(false)
          })
    }

    const handleEditEmail = (e) => {
        setEditEmail(index);
    }

    const handleUpdateEmail = () => {
        let document_id = documentList.id;
        let updated_email = email;
        console.log("list here", list)
        if(email){
            setLoading(true)
            if(list?.status === "PENDING") {
                DocumentEditorService.updateSignatory(document_id, list.email_address, updated_email).then((res)=>{
                    console.log('get updated email res here', res)
                    setLoading(false)
                    enqueueSnackbar("Email updated successfully.", { 
                        variant: "success",
                        autoHideDuration: 3000
                    });
                    window.location.reload()
                }).catch((err)=>{
                    console.log('get updated email error', err)
                    enqueueSnackbar(err.response.data.error, { 
                        variant: "error",
                        autoHideDuration: 3000
                    });
                    setLoading(false)
                })
            } else {
                DocumentEditorService.updateSignatoryv2(document_id, list.email_address, updated_email).then((res)=>{
                    console.log('get updated email res here', res)
                    setLoading(false)
                    enqueueSnackbar("Email updated successfully.", { 
                        variant: "success",
                        autoHideDuration: 3000
                    });
                    window.location.reload()
                }).catch((err)=>{
                    console.log('get updated email error', err)
                    enqueueSnackbar(err.response.data.error, { 
                        variant: "error",
                        autoHideDuration: 3000
                    });
                    setLoading(false)
                })
            }
           
           
        } else {
            enqueueSnackbar("Email is required.", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
        
    }

    const handleClose = () => {
        setEditEmail(null)
    }

    return(
        <>
            {editEmail === index ?
                <>
                    <Button onClick={handleUpdateEmail} id={list} sx={{textTransform:'none', fontSize: 12}}
                        startIcon={loading &&
                            <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />
                        }
                    >
                        {!loading && "Save"}
                    </Button>
                    <Button onClick={handleClose} sx={{textTransform:'none', fontSize: 12}}>
                        Cancel
                    </Button>
                </>
            :
                <>
                    <Button id={list} onClick={(e) => handleEditEmail(e)} sx={{textTransform:'none', fontSize: 12}} >
                        Edit
                    </Button>
                    <Button onClick={(e)=>handleResendEmail(e)} sx={{textTransform:'none', fontSize: 12}}  >
                        {selected ? <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} /> : 'Resend'}
                    </Button>
                </>
            }
        </>
    )
}

const TableHeader = () => {

    const filesTableHeader = [ 
        {label : 'Sequence'},
        {label : 'Email'},
        {label : 'Actions'},
        {label : 'Date'},
    ]

    return(

        <TableHead >
            <TableRow sx={{position: "sticky"}}>
                {filesTableHeader.map((value)=>{
                    return(
                        <TableCell sx={{fontWeight: 700, fontSize: 13, backgroundColor: '#604CC3', color: 'white', textAlign: value.label === 'Actions' && "center", pt: 1, pb: 1}}>{value.label}</TableCell>
                    )
                })}
            </TableRow>
        </TableHead>

    )
}

export default function DocumentSignatoriesDialogComponent (props) {

    const {openDocumentSignatories, setOpenDocumentSignatories, document_title, signatoryList,  documentDetails} = props;

    const [editEmail, setEditEmail] = useState(null);
    const [email, setEmail] = useState('')

    const handleClose = () => {
        setOpenDocumentSignatories(false)
    } 

    const handleUpdateEmail = (e) => {
        setEmail(e.target.value)
    }

    console.log('get document details data aaaa', documentDetails.document)

    return(
        <Dialog open={openDocumentSignatories} onClose={handleClose} maxWidth={'lg'} fullWidth={'lg'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 2}}}>
           <DialogTitle sx={{display:'flex', alignItems:'center'}}>
                <Typography sx={{fontWeight:'bold'}}>
                    {document_title}
                </Typography>
                <Box sx={{flexGrow:1}}/>
                <IconButton sx={{color:'#604CC3'}} onClick={handleClose}><CloseIcon/></IconButton>
           </DialogTitle>
           <DialogContent sx={{pr: 0, pl: 0}}>
                <Grid container>
                    <Grid item xs={3} sx={{borderRight:'1px solid gray', pr: 2, pl: 2}}>
                        <Box sx={{overflow:'auto', height: '60vh'}}>
                            {signatoryList?.map((list)=>{
                                return(
                                    <Typography sx={{display:'flex', alignItems:'center', fontSize: 14, mt: 2, mb: 2}}>
                                        {list.status == 'DONE' ? 
                                            <CheckCircleIcon sx={{color:'green', mr: 2}}/> 
                                        : list.status =='ONGOING' ? 
                                                 <WatchLaterIcon sx={{color:'orange', mr: 2}}/> 
                                            : 
                                                <PendingIcon sx={{color:'gray', mr: 2}}/>
                                        } {list.email_address}
                                    </Typography>
                                )
                            })}
                        </Box>
                        <Box sx={{display:'block'}}>
                            <Typography sx={{fontWeight:'bold', textAlign:'center', alignSelf:'center', color: documentDetails.document.status == 'PENDING' || documentDetails.document.status == 'ONGOING' ? 'orange' : documentDetails.document.status == 'COMPLETED' || documentDetails.document.status == 'DONE' ? 'green' :documentDetails.document.status == 'ERROR' || documentDetails.document.status == 'error' ? 'red' : 'inherit'}}>
                                {documentDetails.document.status}
                            </Typography>
                            {documentDetails.document.status == 'DONE' &&
                                <Typography sx={{textAlign:'center', fontSize: 12, color:'gray'}}>{format(new Date(documentDetails.document.updated_time), 'PPpp')}</Typography>
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={9} sx={{p: 2, overflow:'auto', width:'100%', height: '60vh'}}>
                        <TableContainer component={Paper} sx={{border: 'none', borderRadius: 1,  width: '100%', overflow:'auto'}} className="FilesTable">
                            <Table stickyHeader aria-label="sticky table">
                                <TableHeader/>
                                <TableBody>
                                    {signatoryList?.map((list, index)=>{
                                        return(
                                            <TableRow  sx={{'&:last-child td, &:last-child th': {border: 0}}} >
                                                <TableCell sx={{color: "#604CC3", fontWeight: 530, cursor: "default", pt: 1, pb: 1, fontSize: 12}} 
                                                    component="th" scope="row" 
                                                > 
                                                    {list.sequence}
                                                </TableCell>
                                                <TableCell align="left"sx={{ pt: 1, pb: 1, fontSize: 12}} > 
                                                    {editEmail === index ?(
                                                        <TextField
                                                            className="card-search"
                                                            variant="outlined"
                                                            autoComplete="off"
                                                            fullWidth
                                                            sx={{ "& .MuiInputBase-root": { height: 38, borderRadius: 1, backgroundColor:'white'} }}
                                                            inputProps={{ style: { padding: "10px", fontSize: 12 } }}
                                                            defaultValue={list.email_address ? list.email_address : email}
                                                            onChange={handleUpdateEmail}
                                                        />
                                                    ):(
                                                        <>{list.email_address}</>
                                                    )}
                                                    
                                                </TableCell>
                                                <TableCell align="center" sx={{ pt: 1, pb: 1}}> 
                                                    <ButtonGroup variant="text" aria-label="text button group">
                                                        {list.status === "DONE" ?
                                                            <CheckCircleIcon sx={{color:'green', height: 20, width: 20}}/>
                                                        :
                                                            <ActionsComponent index={index}  documentList={documentDetails.document} list={list}
                                                            editEmail={editEmail} email={email} setEditEmail={setEditEmail}
                                                            />
                                                        }
                                                        
                                                    </ButtonGroup>
                                                </TableCell>
                                                <TableCell align="left" sx={{ pt: 1, pb: 1, fontSize: 12}}> 
                                                    {format(new Date(list.updated_time), 'PPpp')}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
           </DialogContent>
        </Dialog>
    )
}