import { AppBar, Box, Dialog, IconButton, Toolbar, FormControl, Select, MenuItem } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DocumentViewScreen from "../../pages/DocumentViewScreen";
import CustomButton from "../../../../../core/components/ui/CustomButton";
import CustomTypography from "../../../../../core/components/ui/CustomTypography";
import DocumentPreviewPageComponent from "../document-view/document-preview.component";
import useElements from "../../zustand/useElements"
import useSignatoryStore from "../../zustand/useSignatoryStore"
import { useEffect, useState } from "react";
import { elementTypes } from "../../common/staticVariables";

export default function DocumentPreviewComponent (props) {
    
    const {documentId, openPreview, handleClosePreview, decryptedFile} = props;
    const elements = useElements((state) => state.elements);
    const setSignatory = useSignatoryStore((state) => state.setSignatory);
    const signatory = useSignatoryStore((state) => state.signatory);
    const setCurrentSelectedSignatory = useSignatoryStore((state) => state.setCurrentSelectedSignatory);
    const currentSelectedSignatory = useSignatoryStore((state) => state.currentSelectedSignatory);
    const [hasSetSignatories, setHasSetSignatories] = useState(false)

    useEffect(() => { 
        if (!hasSetSignatories) {
            let filtered_elements = elements.filter((item) => item.type == elementTypes.signatory && item.value != "").map((item) => item.value)
            if (filtered_elements.length > 0) {
                let signatories = [...new Set(filtered_elements)]
                
                if (signatories.length > 0) {
                    setSignatory(signatories)
                    setHasSetSignatories(true)
                }
            }
        }
    }, [hasSetSignatories, JSON.stringify(elements)])

    const handleSelectChange = (event) => {
        const value = event.target.value;
        console.log("value xxx11", value);
        setCurrentSelectedSignatory(value)
    }


    const [selectedSignatories, setSelectedSignatories] = useState('');

    const handleChange = (event) => {
        setSelectedSignatories(event.target.value);
    };

    const signatories = elements
    .filter(element => element.type === 'signatory')
    .filter((element, index, self) => 
        index === self.findIndex(e => e.value === element.value && element.value !== null)
    );
    

    console.log('get eeeeeeeeeeeeeeeeeeeeeeee', signatories)

    return(
        <Dialog fullScreen open={openPreview} onClose={handleClosePreview}>

            <AppBar sx={{ position: 'relative', bgcolor:'white' }}>
            <Toolbar>
                <CustomTypography text={'Viewing as:'} variant={'caption'} sx={{color:'black'}} />
                <FormControl>
                    <Select
                    defaultValue={"all"}
                    value={currentSelectedSignatory}
                    onChange={handleSelectChange}
                    sx={{
                        bgcolor: 'white',
                        height: '40px',
                        marginLeft: 2,
                        padding: '0 8px',
                        '& .MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center',
                        width: 200,
                        height: '100%',
                        }
                    }}
                    >
                    <MenuItem value="all">All</MenuItem>
                    {
                        signatory.map((item) => {
                        return(<MenuItem value={item}>{item}</MenuItem>)
                        })
                    }
                    </Select>
                </FormControl>
                <Box sx={{flexGrow:1}}/>
                <CustomButton onClick={()=>handleClosePreview()} label={<CloseIcon/>} variant={'none'} sx={{color: 'black'}}/>
                </Toolbar>
            </AppBar>
            <DocumentPreviewPageComponent selectedSignatories={selectedSignatories} decryptedFile={decryptedFile} documentById={documentId}/>
        </Dialog>
    )
}

