import React, {useState, useEffect} from "react";
import CustomDialog from "../../../core/components/ui/CustomDialog";
import {Select, FormControl, OutlinedInput, Box, MenuItem, CircularProgress} from "@mui/material";
import CustomTypography from "../../../core/components/ui/CustomTypography";
import CustomTextField from "../../../core/components/ui/CustomTextFieldV2";
import useMembersManagement from "../hooks/useMembersManagement";
import CustomDialogLoaderComponent from "../../../core/components/common/custom-dialog-loader.component";
import useInvitedMemberStore from "../zustand/useUpdateInvitedMemberStore";

const InviteMembersComponent = ({open, setOpen, workspaceRoles, workspaceId}) => {
    const {isSendingInvitation, inviteMember} = useMembersManagement();
    const {isMemberInvited, isGuestAddedAsMember, setMemberInvited, setGuestAddedAsMember} = useInvitedMemberStore();

    const [temporaryFields, setTemporaryFields] = useState({email: "", role: null});
    const [isValidEmail, setValidEmail] = useState(true);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isRoleSelected, setRoleSelected] = useState(false);
    const [updatedWorkspaceRoles, setUpdatedWorkspaceRoles] = useState([]);

    
    useEffect(() => {
        if (isMemberInvited || isGuestAddedAsMember) {
            handleClose(); 
        }
    }, [isMemberInvited, isGuestAddedAsMember])

    useEffect(() => {
        validateForm();
    }, [temporaryFields?.email, temporaryFields?.role]);

    useEffect(() => {
        if (workspaceRoles?.length > 0) {
            const filteredRoles = workspaceRoles
            .filter(role => role !== "WSGuest") 
            .map(role => (role === "WSAdmin" ? "Admin" : role === "WSMember" ? "Member" : role)); 

            setUpdatedWorkspaceRoles(filteredRoles);
        }
    }, [workspaceRoles]);


    const validateForm = () => {
        const {email, role} = temporaryFields;
        const isValid = email?.trim() !== "" && role?.trim() !== "";

        setIsFormValid(isValid);
    }

    const handleTextfieldChange = (key) => (e) => {
        const value = e.target.value;

        if (key === 'email') {
            validateAndSetEmail(value);

        } else {
            setTemporaryFields(prev => ({
                ...prev,
                [key]: value
            }));
        }
    }

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;

        return re.test(email);
    }

    const validateAndSetEmail = (email) => {
        setTemporaryFields(prev => ({
            ...prev,
            email
        }));
    
        if (email) {
            setValidEmail(validateEmail(email));

        } else {
            setValidEmail(true);
        }
    }
    
    const handleClose = () => {
        setOpen(false);
        setTemporaryFields({});
        setMemberInvited(false);
        setGuestAddedAsMember(false);
    }

    const handleInviteMember = (role) => {
        if (isFormValid && isValidEmail) {
            let payload = {
                "workspace_id": workspaceId,
                "is_required_id": false,
                "email": temporaryFields?.email,
                "role": (temporaryFields?.role === "Admin") ? "WSAdmin" : (temporaryFields?.role === "Member") ? "WSMember" : null
            }

            inviteMember(payload, role);    
        }
    }

    const actions = [
        {
            label: (temporaryFields?.role === "WSGuest") ? "Add as Guest" : "Send Invitation",
            onClick: () => {
                handleInviteMember(temporaryFields?.role);
            },
        }
    ]


    return (
        <>
            <CustomDialog
                    open={open}
                    onClose={(isSendingInvitation) ? null : handleClose}
                    title="Invite Members"
                    content={
                        <Box>
                            {(isSendingInvitation) ? 
                                <CustomDialogLoaderComponent 
                                    icon={<CircularProgress size={40} sx={{color: "#604CC3"}} />} 
                                    message={(temporaryFields?.role === "WSGuest") ? "Adding as guest..." : "Sending an invitation..."}
                                />
                            : 
                                <>
                                    <CustomTypography 
                                        variant="subtitle1" 
                                        text={"Email Address"} 
                                        customStyles={{marginBottom: 0, fontWeight: 400, color: "#58565F", fontSize: 18}}
                                    />

                                    <CustomTextField 
                                        placeholder={"Email Address"}
                                        variant={"filled"}
                                        onChange={handleTextfieldChange("email")}
                                        fullWidth={true}
                                        multiline={false}
                                        hiddenLabel
                                        value={temporaryFields?.email}
                                        helperText={
                                            <p style={{fontSize: 12, color: "red", margin: "2px 0px 0px -8px"}}>
                                                {(!isValidEmail) ? "Email Address is invalid" : ""}
                                            </p>
                                        }
                                    />   <br/> <br/> 

                                    <CustomTypography 
                                        variant="subtitle1" 
                                        text={"Workspace Role"} 
                                        customStyles={{marginBottom: 0, fontWeight: 400, color: "#58565F", fontSize: 18}}
                                    />

                                    <FormControl sx={{width: "100%"}}>
                                        <Select
                                            displayEmpty
                                            value={temporaryFields?.role}
                                            onChange={handleTextfieldChange("role")}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                if (selected === null) {
                                                    setRoleSelected(false);

                                                    return <span>Workspace Role</span>;
                                                } else {
                                                    setRoleSelected(true);
                                                }
                                                
                                                return selected                                       
                                            }}
                                            MenuProps={{PaperProps: {style: {maxHeight: 48 * 4.5 + 8,  width: 250}}}}  
                                            inputProps={{"aria-label": "Without label"}}
                                            sx={{
                                                color: (!isRoleSelected) ? "#8080809e" : "inherit",
                                                borderRadius: "2px",
                                                backgroundColor: "#604cc31c",
                                                borderBottom: "none",

                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: "none",
                                                },

                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    border: "none", 
                                                },

                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    border: "none", 
                                                },

                                                '& .MuiOutlinedInput-root': {
                                                    border: "none",
                                                    
                                                    '&:hover': {
                                                            backgroundColor: "#604cc336"
                                                    },
                                                    '&.Mui-focused': {
                                                        backgroundColor: "#604cc33d", 
                                                    },
                                                },

                                                '& .MuiInputBase-input::placeholder': {
                                                    color: "#acb0be"
                                                },
                                            }}  
                                        >
                                            <MenuItem disabled value="">
                                                <em>Select Workspace Role</em>
                                            </MenuItem>

                                            {updatedWorkspaceRoles?.map((role) => (
                                                <MenuItem key={role} value={role}>
                                                    {role}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl> 
                                </>
                            }
                        </Box>
                    }
                    actions={!isSendingInvitation && actions}
                    maxWidth={"sm"}
                    customStyles={{
                        actions: {
                            opacity: (isFormValid && isValidEmail && !isSendingInvitation) ? "100%" : "55%",
                            cursor: (isFormValid && isValidEmail && !isSendingInvitation) ? "pointer" : "not-allowed",
                            visibility: (isSendingInvitation) ? "hidden" : "visible",
                        }
                    }}
                    globalSx={{}}
                />
        </>
    )
}

export default InviteMembersComponent