import routes from "../../common/api-routes";
import {HttpAuthGet, HttpAuthPost} from "../../../../../core/services/common/HttpRequestBuilder";

const getWalletBalance = (parameters) => {
    let result = HttpAuthGet(routes.GET_WALLET_BALANCE, parameters);

    return result;
}

const getTokenTransactions = (parameters) => {
    let result = HttpAuthGet(routes.GET_TOKEN_TRANSACTIONS, parameters);

    return result;
}

const getPurchaseTransactions = (parameters) => {
    let result = HttpAuthGet(routes.GET_PURCHASE_TRANSACTIONS, parameters);

    return result;
}

const getTokenPackages = () => {
    let parameters = null;
    let result = HttpAuthGet(routes.GET_TOKEN_PACKAGES, parameters);

    return result;
}

const viewTokenPackage = (parameters) => {
    let result = HttpAuthGet(routes.VIEW_TOKEN_PACKAGE, parameters);

    return result;
}

const getTokenPrice = (parameters) => {
    let result = HttpAuthGet(routes.GET_TOKEN_PRICE, parameters);

    return result;
}

const TokenManagementService = {
    getWalletBalance,
    getTokenTransactions,
    getTokenPackages,
    viewTokenPackage,
    getPurchaseTransactions,
    getTokenPrice
}

export default TokenManagementService