import { useEffect } from "react";
import useOrderManagement from "../hooks/useOrderManagement";
import { Box } from "@mui/material";
import CustomTypography from "../../../core/components/ui/CustomTypography";
import Grid from "@mui/material/Grid2";
import ViewOrderComponent from "../components/view-order.component";


export default function ViewOrderDetailsScreen() {

    const code = new URLSearchParams(window.location.search).get("orderId");

    console.log("code here", code)

    const { isFetchingOrders, viewOrder, orderData } = useOrderManagement();

    useEffect(() => {

        if(code){
            let parameters = {
                'order_id': code
            }
            viewOrder(parameters);
        }

    },[code])


    return(
        <Box sx={{ margin: "0px auto auto" }}>

            <Box sx={{ flexGrow: 1, margin: "0px auto auto" }}>
                <Grid container spacing={2}>
                    <Grid size={{ xs: 12 }} sx={{ textAlign: "start", mb: 0, pb: 0 }}>
                        <CustomTypography
                            variant="h6"
                            text={"Order Details"}
                            customStyles={{ mb: 0 }}
                        />
                    </Grid>
                </Grid>

                <br />

                <Box 
                    sx={{
                        backgroundColor: "#fff", borderRadius: "6px", padding: "26px 50px", paddingBottom: '10px',
                        border: "1px solid #c7c0ea",
                        boxShadow: "0px 3px 6px -2px rgb(0 0 0 / 0%), 0px 5px 8px 0px rgb(0 0 0 / 0%), 0px 2px 12px 0px rgb(0 0 0 / 0%)",
                        mt: "1px", overflow: 'hidden !important' 
                    }}
                >
                    <ViewOrderComponent orderData={orderData} isFetchingOrders={isFetchingOrders} />
                </Box>
                
            </Box>
        </Box>
    )
}