import {create} from "zustand";

const useUploadWorkspaceLogoStore = create((set) => ({
    uploadedWorkspaceLogo: null,
    isLogoSaved: false,

    setUploadedWorkspaceLogo: (data) => set({uploadedWorkspaceLogo: data}),
    setLogoSaved: (status) => set({isLogoSaved: status}),
}));

export default useUploadWorkspaceLogoStore