import {useState} from "react";
import {useSnackbar} from "notistack";
import TokenManagementService from "../services/api/token-management";

const useTokenManagement = () => {

    const { enqueueSnackbar } = useSnackbar();
    const [isFetchingWalletBalance, setFetchingWalletBalance] = useState(false);
    const [walletBalance, setWalletBalance] = useState(0);
    const [tokenTransactions, setTokenTransactions] = useState([]);
    const [isFetchingTransactions, setFetchingTransactions] = useState(false);
    const [tokenPackages, setTokenPackages] = useState([]);
    const [isFetchingTokenPackages, setFetchingTokenPackages] = useState(false);
    const [packageDetails, setPackageDetails] = useState('');
    const [isFetchingPurchaseTransaction, setFetchingPurchaseTransaction] = useState(false);
    const [purchaseTransactionList, setPurchaseTransactionList] = useState([]);
    const [tokenPrice, setTokenPrice] = useState('');
    const [initialBalanceLoaded, setInitialBalanceLoaded] = useState(false);


    
    const getWalletBalance = (parameters) => {
        setFetchingWalletBalance(true);
 
        TokenManagementService.getWalletBalance(parameters).then((res) => {
            if (res.data.success) {
                console.log("getWalletBalance data xxxx", res);
                setInitialBalanceLoaded(true);
                setFetchingWalletBalance(false);
                setWalletBalance(res.data.data.balance)
            }

        }).catch((error) => {
            console.log("getWalletBalance error xxxx", error);
            setInitialBalanceLoaded(false);
            const errorMessage = error?.response?.data?.error || error.message;
            
            setFetchingWalletBalance(false);
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }


    const getTokenTransactions = (parameters) => {
        setFetchingTransactions(true);
        TokenManagementService.getTokenTransactions(parameters).then((res) => {
            if (res.data.success) {
                console.log("getTokenTransactions data xxxx", res);
                setFetchingTransactions(false);
                setTokenTransactions(res.data.data);
            }

        }).catch((error) => {
            console.log("getTokenTransactions error xxxx", error);
            setFetchingTransactions(false);
            const errorMessage = error?.response?.data?.error || error.message;
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }


    const getPurchaseTransactions = (parameters) => {
        setFetchingPurchaseTransaction(true);
        TokenManagementService.getPurchaseTransactions(parameters).then((res) => {
            if (res.data.success) {
                console.log("getPurchaseTransactions data xxxx", res);
                setFetchingPurchaseTransaction(false);
                setPurchaseTransactionList(res.data.data);
            }

        }).catch((error) => {
            console.log("getPurchaseTransactions error xxxx", error);
            setFetchingPurchaseTransaction(false);
            const errorMessage = error?.response?.data?.error || error.message;
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    const getTokenPackages = () => {
        setFetchingTokenPackages(true);
        TokenManagementService.getTokenPackages().then((res) => {
            if (res.data.success) {
                console.log("getTokenPackages data xxxx", res);
                setFetchingTokenPackages(false);
                setTokenPackages(res.data.data)
            }

        }).catch((error) => {
            console.log("getTokenPackages error xxxx", error);
            setFetchingTokenPackages(false);
            const errorMessage = error?.response?.data?.error || error.message;
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }


    const viewTokenPackage = (parameters) => {
        TokenManagementService.viewTokenPackage(parameters).then((res) => {
            if (res.data.success) {
                console.log("viewTokenPackage data xxxx", res);
                setPackageDetails(res.data.data);
            }

        }).catch((error) => {
            console.log("viewTokenPackage error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

       
    const getTokenPrice = (parameters) => {
 
        TokenManagementService.getTokenPrice(parameters).then((res) => {
            if (res.data.success) {
                console.log("getTokenPrice data xxxx", res);
                setTokenPrice(res.data.data.price);
            }

        }).catch((error) => {
            console.log("getTokenPrice error xxxx", error);
            const errorMessage = error?.response?.data?.error || error.message;
            enqueueSnackbar(errorMessage, {
                variant: "error",
                autoHideDuration: 2000
            })
        })
    }

    
    return {getWalletBalance, isFetchingWalletBalance, walletBalance, getTokenTransactions, tokenTransactions, isFetchingTransactions,
        getTokenPackages, tokenPackages, isFetchingTokenPackages, viewTokenPackage, packageDetails, getPurchaseTransactions, 
        isFetchingPurchaseTransaction, purchaseTransactionList, getTokenPrice, tokenPrice, initialBalanceLoaded
    }

}

export default useTokenManagement