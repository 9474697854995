import { useEffect, useState } from "react";
import useTokenManagement from "../hooks/useTokenManagement";
import { Card, CardContent, CardActions, Button, Typography, Box, TextField, FormControl, OutlinedInput, FormHelperText, Skeleton, useMediaQuery, useTheme, Divider } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Grid from '@mui/material/Grid2'; // Import Grid2 as Grid from @mui/material
import tokenIcon from '../../../assets/icons/token.png';
import useOrderManagement from "../hooks/useOrderManagement";
import { ThreeDots } from "react-loader-spinner";
import CustomTextField from "../../../core/components/ui/CustomTextfield";
import { useNavigate } from "react-router-dom";



export default function BuyTokenComponent({ onAddToCart, orderId, orderDataArray, setCartItems, setCartCount, orderData, setOrderDataListArray,
    viewOrder, cartItems, addedToCart, setAddedToCart
}) {

    const { getTokenPackages, tokenPackages, isFetchingTokenPackages, getTokenPrice, tokenPrice } = useTokenManagement();
    const { isCreatingOrder, createItem, deleteItem, itemDeleted, pendingOrder, deleteItemv2, hasPending, orderCreated, cancelOrder, orderCancel, getListOrder } = useOrderManagement();

    const navigate = useNavigate();

    const [customAmount, setCustomAmount] = useState("");
    const [error, setError] = useState(false);
    const [deletingItemId, setDeletingItemId] = useState(null);
    // const [addedToCart, setAddedToCart] = useState(() => {
    //     return JSON.parse(localStorage.getItem('addedToCart')) || [];
    // });
    const [addedCustomAmount, setAddedCustomAmount] = useState(() => {
        return JSON.parse(localStorage.getItem("addedCustomAmount")) || false;
    });
    //get pending here
    const profileWalletId = localStorage.getItem('profile_wallet');
    const [isEmail, setIsEmail] = useState(JSON.parse(localStorage.getItem('isEmail')));
    const [isWorkspace, setIsWorkspace] = useState(JSON.parse(localStorage.getItem('isWorkspace')));
    const [wallet_id, setWalletId] = useState(localStorage.getItem('wallet_id'));
    const [storedItems, setStoredItems] = useState(() => {
        return JSON.parse(localStorage.getItem('cartItems')) || [];
    });
    const [order_empty, setOrderEmpty] = useState(localStorage.getItem('orderEmpty'));

    useEffect(() => {
        const syncLocalStorage = () => {
            const storedIsEmail = JSON.parse(localStorage.getItem('isEmail'));
            const storedIsWorkspace = JSON.parse(localStorage.getItem('isWorkspace'));
            const storedWalletId = localStorage.getItem('wallet_id');
            const storedCustomAmount = JSON.parse(localStorage.getItem('addedCustomAmount'));
            const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
            const storedOrderEmpty = localStorage.getItem('orderEmpty');

            if (storedIsEmail !== isEmail) setIsEmail(storedIsEmail);
            if (storedIsWorkspace !== isWorkspace) setIsWorkspace(storedIsWorkspace);
            if (storedWalletId !== wallet_id) setWalletId(storedWalletId);
            if (storedCustomAmount !== addedCustomAmount) setAddedCustomAmount(storedCustomAmount);
            if (storedCartItems !== storedItems) setStoredItems(storedCartItems);
            if (storedOrderEmpty !== order_empty) setOrderEmpty(storedOrderEmpty);
        };

        const intervalId = setInterval(syncLocalStorage, 500);

        return () => clearInterval(intervalId);
    }, [isEmail, isWorkspace, wallet_id, addedToCart, cartItems, order_empty]);

    useEffect(() => {
        let parameters = {};

        if (isEmail) {
            if (profileWalletId) {
                parameters = { "walletId": profileWalletId }
                pendingOrder(parameters);
            }
        } else if (isWorkspace) {
            parameters = { "walletId": wallet_id ? wallet_id : null }
            pendingOrder(parameters);
        }

    }, [isEmail, isWorkspace, wallet_id, profileWalletId]);


    useEffect(() => {
        let parameters = { 'currency': 'USD' }
        let order_id = localStorage.getItem('orderId');

        if (hasPending) {
            console.log("buytoken hasPending :", hasPending)
            navigate(`/token?orderId=${order_id}`);
            getTokenPrice(parameters);
            getTokenPackages();
        } else {
            console.log("buytoken hasPending :", hasPending)
            setOrderDataListArray(null);
            navigate(`/token`);
            getTokenPrice(parameters);
            getTokenPackages();
        }
    }, [hasPending])


    useEffect(() => {
        let parameters = { 'currency': 'USD' }
        getTokenPrice(parameters);
    }, [])

    useEffect(() => { getTokenPackages(); }, []);

    useEffect(() => {
        if (orderCreated) {
            if (orderId) {
                let parameters = { "order_id": orderId };
                viewOrder(parameters);
            }
        }
    }, [orderCreated])

    useEffect(() => {
        if (orderDataArray) {
            // console.log("orderDataArray here", orderDataArray)
            // Find the entry with package_code === null (Custom Package)
            const customPackage = orderDataArray?.find(item => item?.package_code === null);
            if (customPackage) {
                setCustomAmount(customPackage?.quantity); // Set the quantity as the customAmount
            }
        }
    }, [orderDataArray]);

    useEffect(() => {
        if (orderDataArray) {
            const packageCodes = orderDataArray
                .map((item) => item?.package_code)
                .filter((code) => code !== null);

            // Check if any package_code is null
            const hasNullPackageCode = orderDataArray?.some((item) => item?.package_code === null);

            // console.log("hasNullPackageCodeheree", hasNullPackageCode)

            setAddedToCart(packageCodes); // Excludes null values
            setAddedCustomAmount(hasNullPackageCode); // Set to true if any null is found
        }
    }, [orderDataArray]);

    useEffect(() => {
        localStorage.setItem("addedToCart", JSON.stringify(addedToCart));
    }, [addedToCart]);

    
    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);

    useEffect(() => {
        localStorage.setItem("addedCustomAmount", JSON.stringify(addedCustomAmount));
    }, [addedCustomAmount]);


    const handleCustomAmountChange = (event) => {
        const value = event.target.value;

        if (value === "" || (/^[1-9]\d{0,5}$/.test(value) && value <= 999999)) {
            setCustomAmount(value);
            setError(false);
        } else if (value.length > 6) {
            setError(true);
        } else {
            setError(false);
        }
    };

    const handleAddToCart = (code, price) => {
        if (!addedToCart.includes(code)) {
            setAddedToCart((prev) => [...prev, code]);
            onAddToCart(code, price);
        }
    };


    const handleCreateItem = (identifier, price, amount) => {
        if (!orderId) return;

        setAddedToCart((prev) => [...prev, identifier]);

        const isCustom = identifier === 'Custom';

        const newItem = isCustom
            ? { type: 'custom', packageCode: identifier, price: amount, quantity: price }
            : { type: 'package', packageCode: identifier, price: price, quantity: 1 };


        const payload = {
            order_id: orderId,
            is_package: newItem.type === 'package' ? true : false,
            package_code: newItem.type === 'package' ? newItem.packageCode : null,
            token_amount: newItem.type === 'package' ? null : newItem.quantity,
        };

        // Call createItem with the payload
        createItem(payload);
    };


    const handleRemoveFromCart = (code) => {
        // console.log("handleRemoveFromCart orderDataArray", orderDataArray);
        // console.log("handleRemoveFromCart code", code);
        const item = orderDataArray.find(order => {
            // console.log("handleRemoveFromCart order.package_code", order.package_code);
            return code === "Custom" ? order.package_code === null : order.package_code === code;
        });
        // console.log("handleRemoveFromCart Found item:", item);


        if (item && orderId) {
            const itemId = item.id; // Retrieve item_id from the matched item

            setDeletingItemId(itemId); // Save the item ID being deleted

            const payload = {
                item_id: itemId,
                order_id: orderId,
            };

            deleteItem(payload); // Call the deleteItem function with the payload
        } else {
            // console.log("im here");

            const storedCart = JSON.parse(localStorage.getItem('cartItems')) || [];
            const storedAdded = JSON.parse(localStorage.getItem('addedToCart')) || [];

            // Filter cartItems by packageCode
            const updatedCart = storedCart.filter(item => item.packageCode !== code);

            // Filter addedToCart by code (since it's an array of strings, just compare directly)
            const updatedAdded = storedAdded.filter(item => item !== code);

            setAddedToCart(updatedAdded);  
            setCartItems(updatedCart);
            setCartCount(updatedCart.length);

            localStorage.setItem('cartItems', JSON.stringify(updatedCart));
            localStorage.setItem('addedToCart', JSON.stringify(updatedAdded));
           
            // console.log("updatedAdded:", updatedAdded);
    
        }
    };


    useEffect(() => {
        if (itemDeleted && deletingItemId) {
            // Update localStorage
            const cartItems = JSON.parse(localStorage.getItem('addedToCart')) || [];
            const updatedCartItems = cartItems.filter((item) => item !== deletingItemId);
            localStorage.setItem('addedToCart', JSON.stringify(updatedCartItems));
            

            setDeletingItemId(null); // Reset the deleting item ID
        }
    }, [itemDeleted, deletingItemId]);

    const handleCancelOrder = (orderId) => {
            let parameters = { "order_id": orderId }
            cancelOrder(parameters);
        }
        
        useEffect(() => {
            if (orderId) {
                // console.log("isCartEmpty here", order_empty)
                if(order_empty){
                    console.log("Cart is empty, cancelling order.");
                    handleCancelOrder(orderId);  // Trigger cancel order if cartItems is empty
                }
            }
        }, [order_empty, orderId]);
        
    
        useEffect(() => {
    
            let parameters = {};

            if(orderCancel){
                window.location.href="/token";
                if (isEmail) {
                    if (profileWalletId) {
                        parameters = { "wallet_id": profileWalletId }
                        getListOrder(parameters);
                    }
                } else if (isWorkspace) {
                    parameters = { "wallet_id": wallet_id ? wallet_id : null }
                    getListOrder(parameters);
                }
            }
        },[orderCancel, isEmail, isWorkspace, profileWalletId, wallet_id ])
 


    return (
        <Box>
            <Grid container spacing={5}>
                {isFetchingTokenPackages ? (
                    Array.from({ length: 3 }).map((_, index) => (
                        <Grid size={{ xs: 12, md: 4 }} key={index}  >
                            <Card sx={{ margin: 'auto', boxShadow: 3, border: '1px solid #c7c0ea' }}>
                                <CardContent>
                                    <Skeleton variant="text" width="60%" height={30} sx={{ backgroundColor: '#eeebf8' }} />
                                    <Grid container spacing={1} sx={{ mt: 2 }}>
                                        <Grid size={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Skeleton variant="rectangular" width="80%" height={100} sx={{ backgroundColor: '#eeebf8' }} />
                                        </Grid>
                                        <Grid size={7} sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Skeleton variant="text" width="80%" height={30} sx={{ backgroundColor: '#eeebf8' }} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} sx={{ mt: 2 }}>
                                        <Grid size={6}>
                                            <Skeleton variant="text" width="100%" height={20} sx={{ backgroundColor: '#eeebf8' }} />
                                        </Grid>
                                        <Grid size={6}>
                                            <Skeleton variant="text" width="60%" height={20} sx={{ backgroundColor: '#eeebf8' }} />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <br />
                            <Skeleton variant="rectangular" width="100%" height={45} sx={{ backgroundColor: '#eeebf8' }} />
                        </Grid>
                    ))
                ) : (
                    tokenPackages?.sort((a, b) => {
                        if (a.name.includes('Package 1') && !b.name.includes('Package 1')) {
                            return -1;
                        }
                        if (!a.name.includes('Package 1') && b.name.includes('Package 1')) {
                            return 1;
                        }
                        return 0;
                    }).map((pkg, index) => (
                        <Grid size={{ xs: 12, md: 4 }} key={index}  >
                            <Card sx={{ margin: 'auto', boxShadow: 3, border: '1px solid #c7c0ea' }}>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom sx={{ textAlign: 'left' }}>
                                        {pkg.name}
                                    </Typography>

                                    <Grid container spacing={1} sx={{ mt: 2 }}>
                                        <Grid size={5} sx={{ alignSelf: 'center', justifyContent: 'center', justifyItems: 'center' }}>
                                            <img src={tokenIcon} style={{ width: '80%' }} />
                                        </Grid>
                                        <Grid size={7} sx={{ alignSelf: 'center', justifyContent: 'flex-start', justifyItems: 'flex-start' }}>
                                            <Typography sx={{ color: '#58565f', fontWeight: 'bold', fontSize: 30 }}>{pkg.token_amount} Tokens</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} sx={{ mt: 2 }}>
                                        <Grid size={6}>
                                            <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'start', fontSize: 16 }}>
                                                {pkg.code}
                                            </Typography>
                                        </Grid>
                                        <Grid size={6}>
                                            <Typography variant="h6" sx={{ textAlign: 'right', fontWeight: 'bold', color: '#604CC3', fontSize: 16 }}>
                                                ${pkg.price}
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                            <br />
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: addedToCart && addedToCart?.includes(pkg.code) ? '#f23c40f5' : '#604CC3',
                                    margin: 'auto'
                                }}
                                fullWidth
                                startIcon={
                                    isCreatingOrder ? (<ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="creating-order" visible={true} />) :
                                        <ShoppingCartIcon />

                                }
                                onClick={() => {
                                    if (addedToCart && addedToCart?.includes(pkg.code)) {
                                        handleRemoveFromCart(pkg.code);
                                    } else if (orderId) {
                                        handleCreateItem(pkg.code, pkg.price);
                                    } else {
                                        handleAddToCart(pkg.code, pkg.price);
                                    }
                                }}
                            >
                                {addedToCart && addedToCart?.includes(pkg.code) ? 'Remove from Cart' : 'Add to Cart'}
                            </Button>

                        </Grid>
                    ))
                )}
            </Grid>
            <br />
            <Divider sx={{ borderWidth: 1.5 }} />
            <br />
            {isFetchingTokenPackages ?
                <Grid container spacing={2}>
                    <Grid size={{ xs: 12, md: 4 }}  >
                        <Card sx={{ margin: 'auto', boxShadow: 3, border: '1px solid #c7c0ea' }}>
                            <CardContent>
                                <Skeleton variant="text" width="60%" height={30} sx={{ backgroundColor: '#eeebf8' }} />
                                <Grid container spacing={1} sx={{ mt: 2 }}>
                                    <Grid size={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Skeleton variant="rectangular" width="80%" height={100} sx={{ backgroundColor: '#eeebf8' }} />
                                    </Grid>
                                    <Grid size={7} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Skeleton variant="text" width="80%" height={30} sx={{ backgroundColor: '#eeebf8' }} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} sx={{ mt: 2 }}>
                                    <Grid size={6}>
                                        <Skeleton variant="text" width="100%" height={20} sx={{ backgroundColor: '#eeebf8' }} />
                                    </Grid>
                                    <Grid size={6}>
                                        <Skeleton variant="text" width="60%" height={20} sx={{ backgroundColor: '#eeebf8' }} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <br />
                        <Skeleton variant="rectangular" width="100%" height={45} sx={{ backgroundColor: '#eeebf8' }} />
                    </Grid>
                </Grid>
            :
                <Grid container spacing={2}>
                    <Grid size={{ xs: 12 }}>
                        <Typography sx={{ textAlign: 'start', fontSize: 18, fontWeight: 500 }}>Need more tokens ?</Typography>
                    </Grid>
                    <Grid size={{ xs: 12, md: 4 }}>
                        <Card sx={{ margin: 'auto', boxShadow: 3, border: '1px solid #c7c0ea' }}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom sx={{ textAlign: 'left' }}>
                                    Add Tokens
                                </Typography>

                                <Grid container spacing={1} sx={{ mt: 2 }}>
                                    <Grid size={5} sx={{ alignSelf: 'center', justifyContent: 'center', justifyItems: 'center' }}>
                                        <img src={tokenIcon} style={{ width: '80%' }} />
                                    </Grid>
                                    <Grid size={7} sx={{ alignSelf: 'center', justifyContent: 'flex-start', justifyItems: 'flex-start', height: '90px' }}>
                                        <Typography sx={{ color: '#58565f', fontWeight: 'bold', fontSize: 24 }}>Tokens</Typography>
                                        <FormControl sx={{ width: '100%' }} variant="outlined">
                                            <OutlinedInput
                                                fullWidth
                                                type="number"
                                                value={customAmount}
                                                onChange={handleCustomAmountChange}
                                                placeholder={'0 Token'}
                                                inputProps={{
                                                    'aria-label': 'weight',
                                                    inputMode: 'numeric'
                                                }}
                                                sx={{
                                                    backgroundColor: error ? '#efdde9' : '#dcd7f1',
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: error ? '#f44336' : '#c7c0ea',
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: error ? '#f44336' : '#c7c0ea',
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: error ? '#f44336' : '#c7c0ea',
                                                    },
                                                    inputMode: 'numeric'
                                                }}
                                            />
                                            {error && (
                                                <FormHelperText id="outlined-weight-helper-text" sx={{ color: '#f44336', paddingLeft: 0, marginLeft: 0 }}>
                                                    You have exceeded the maximum limit.
                                                </FormHelperText>
                                            )}
                                        </FormControl>

                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} sx={{ mt: 2 }}>
                                    <Grid size={6}>
                                        <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'start', fontSize: 16 }}>
                                            Price
                                        </Typography>
                                    </Grid>
                                    <Grid size={6}>
                                        <Typography variant="h6" sx={{ textAlign: 'right', fontWeight: 'bold', color: '#604CC3', fontSize: 16 }}>
                                            {/* ${customAmount * tokenPrice} */}
                                            ${((customAmount * tokenPrice).toFixed(2))}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card>
                        <br />
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: addedCustomAmount ? '#f23c40f5' : '#604CC3', margin: 'auto' }}
                            fullWidth
                            startIcon={
                                isCreatingOrder ? (
                                    <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="creating-order" visible={true} />
                                ) : (
                                    <ShoppingCartIcon />
                                )
                            }
                            onClick={() => {
                                if (addedCustomAmount) {
                                    handleRemoveFromCart('Custom');
                                } else if (orderId) {
                                    handleCreateItem('Custom', customAmount, tokenPrice);
                                    setAddedCustomAmount(true);
                                } else {
                                    onAddToCart('Custom', customAmount, tokenPrice);
                                    setAddedCustomAmount(true);
                                }
                            }}
                            disabled={!customAmount}
                        >
                            {/* Add to Cart */}
                            {addedCustomAmount  ? 'Remove from Cart' : 'Add to Cart'}
                        </Button>
                    </Grid>
                </Grid>
            }
        </Box>
    )
}