import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid2";
import {Card, CardContent, Link, Chip, Box, Button, Typography} from "@mui/material";
import useEnterpriseProjectManagement from "../../hooks/useEnterpriseProjectManagement";
import CustomButton from "../../../../core/components/ui/CustomButton";
import AddProjectDialog from "../dialog/add-project.dialog";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ChangeProjectKeysDialog from "../dialog/change-project-keys.dialog";
import useEnterpriseProjectStore from "../../zustand/useEnterpriseProjectStore";
import WorkspaceProjectDetailsSkeleton from "../skeletons/project-details.skeleton";
import DeleteProjectDialog from "../dialog/delete-project.dialog";
import OVCodeLogo from "../../../../assets/icons/ovcode.png";

const WorkspaceProjectDetailsComponent = ({ workspaceId }) => {

    const {isFetchingProjectDetails, projectDetails, getProjectDetails, isNoAPIKeyConfigured} = useEnterpriseProjectManagement();
    const {isOVCodeKeysUpdated, isOVCodeKeysDeleted, isOVCodeKeysAdded} = useEnterpriseProjectStore();

    const [openAddProject, setOpenAddProject] = useState(false);
    const [openChangeProjectKeys, setOpenChangeProjectKeys] = useState(false);
    const [openDeleteProject, setOpenDeleteProject] = useState(false);


    useEffect(() => {
        if (workspaceId) {
            const parameters = {workspace_id: workspaceId}

            getProjectDetails(parameters);
        }
    }, [workspaceId])

    useEffect(() => {
        if ((workspaceId && (isOVCodeKeysAdded || isOVCodeKeysUpdated || isOVCodeKeysDeleted))) {
            const parameters = {workspace_id: workspaceId}

            getProjectDetails(parameters);
        }
    }, [workspaceId, isOVCodeKeysAdded, isOVCodeKeysUpdated, isOVCodeKeysDeleted])


    return (
        <Box sx={{padding: "18px 0px 40px"}}>
            {isFetchingProjectDetails && !isNoAPIKeyConfigured ?
                <WorkspaceProjectDetailsSkeleton />
            :
                (!isFetchingProjectDetails && projectDetails && !isNoAPIKeyConfigured) ?
                    <>
                        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", minHeight: "50vh", backgroundColor: "#f5f5f5", p: 2}}>
                            <Box
                                sx={{
                                    width: 510,
                                    height: 300,
                                    borderRadius: 4,
                                    background: "linear-gradient(135deg, #604CC3, #FF6600)",
                                    color: "#fff",
                                    padding: 2,
                                    boxShadow: 3,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Grid container justifyContent="space-between">
                                    <Box sx={{display: "block", textAlign: "start"}}>
                                        <Typography variant="h6" sx={{fontWeight: "bold", fontSize: 20}}>
                                            {projectDetails?.project_name}
                                        </Typography>

                                        <Typography variant="body2">
                                            {projectDetails?.business_name}
                                        </Typography>
                                    </Box>

                                    <Chip
                                        label={`${projectDetails?.credits?.toLocaleString()} Credits`}
                                        sx={{backgroundColor: "#fff", color: "#000", fontSize: 12, fontWeight: "bold"}}
                                    />
                                </Grid>

                                <Box sx={{textAlign: "start"}}>
                                    <Typography variant="body2">Subscription Key:</Typography>
                                    <Typography variant="body2" sx={{fontWeight: "bold", fontSize: 14}}>
                                        {projectDetails?.sub_key}
                                    </Typography>

                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                        API Key:
                                    </Typography>
                                    <Typography variant="body2" sx={{fontWeight: "bold", fontSize: 14}}>
                                        {projectDetails?.api_key}
                                    </Typography>
                                </Box>

                                <Grid container justifyContent="space-between" sx={{mt: 1}}>
                                    <Button
                                        variant="text"
                                        startIcon={<EditIcon sx={{color: "#fff", fontSize: 14}} />}
                                        sx={{textTransform: "none", color: "#fff", fontSize: 14}}
                                        onClick={() => setOpenChangeProjectKeys(true)}
                                    >
                                        Update
                                    </Button>

                                    <Button
                                        variant="text"
                                        startIcon={<DeleteIcon sx={{color: "#fff", fontSize: 14}} />}
                                        sx={{textTransform: "none", color: "#fff", fontSize: 14}}
                                        onClick={() => setOpenDeleteProject(true)}
                                    >
                                        Remove
                                    </Button>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                :
                    (!isFetchingProjectDetails && isNoAPIKeyConfigured &&  
                        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", minHeight: "50vh", backgroundColor: "#f5f5f5", p: 2}}>
                            <Card sx={{maxWidth: 520, width: "100%", textAlign: "center", p: 3, boxShadow: 3, borderRadius: 4, backgroundColor: "#fff"}}>   
                                <CardContent>
                                    <Box sx={{display: "flex", justifyContent: "center", mb: 2}}>
                                        <img alt="OVCode Logo" src={OVCodeLogo} style={{ width: 80, height: 80, marginRight: 16 }} />
                                    </Box>

                                    <Typography variant="h6" sx={{fontWeight: 600, color: "#222123", mb: 1}}>
                                        No OVCODE API key has been configured
                                    </Typography>

                                    <Typography variant="body2" sx={{fontSize: 14, color: "#555", mb: 1}}>
                                        Already have an OVCODE Enterprise account?&nbsp;
                                        <Link 
                                            href="https://dev-portal.ovcode.com" 
                                            target="_blank" 
                                            rel="noopener noreferrer" 
                                            underline="hover"
                                            sx={{fontWeight: 500, color: "#1976d2"}}
                                        >
                                            Get your OVCODE API Key
                                        </Link>.
                                    </Typography>

                                    <Typography variant="body2" sx={{ fontSize: 14, color: "#555", mb: 3 }}>
                                        Need an account?&nbsp;
                                        <Link 
                                            href="https://ovcode.com/join" 
                                            target="_blank" 
                                            rel="noopener noreferrer" 
                                            underline="hover"
                                            sx={{fontWeight: 500, color: "#1976d2"}}
                                        >
                                            Apply here
                                        </Link>.
                                    </Typography>

                                    <CustomButton 
                                        variant="contained" 
                                        startIcon={<AddIcon fontSize="small" />}
                                        label="ADD OVCODE API KEY"
                                        customStyles={{fontSize: 14, mt: 1}}
                                        onClick={() => setOpenAddProject(true)}
                                    />
                                </CardContent>
                            </Card>
                        </Box>   
                    )
            }

            {openAddProject &&
                <AddProjectDialog
                    open={openAddProject}
                    setOpen={setOpenAddProject}
                    workspace={workspaceId}
                    isWorkspaceId={true}
                />
            }

            {openChangeProjectKeys &&
                <ChangeProjectKeysDialog 
                    open={openChangeProjectKeys}
                    setOpen={setOpenChangeProjectKeys}
                    workspaceId={workspaceId}
                />
            }

            {openDeleteProject &&
                <DeleteProjectDialog
                    open={openDeleteProject}
                    setOpen={setOpenDeleteProject}
                    projectDetails={projectDetails}
                />
            }
        </Box>
    )
}

export default WorkspaceProjectDetailsComponent
