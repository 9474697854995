import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Rings } from 'react-loader-spinner'; 

function PrivateRoute({ children }) {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const auth = getAuth(); // Get the Firebase auth instance

        // Listen for changes in authentication state
        const unregisterAuthObserver = onAuthStateChanged(auth, (authUser) => {
            if (authUser) {
                console.log("User is authenticated", authUser);
                window.localStorage.setItem(authUser?.uid, authUser?.accessToken);
                setUser(authUser); // Set the authenticated user
            } else {
                console.log("No user is authenticated");
                setUser(null); // No user
            }
            setLoading(false); // Loading done
        });

        // Cleanup on component unmount
        return () => unregisterAuthObserver();
    }, []);

    // Display a loading indicator (spinner) while Firebase Auth is checking the user
    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Rings color="#3498db" height={280} width={280} />
            </div>
        );
    }

    // If user is authenticated, render the children (private content), else redirect to login
    return user ? children : <Navigate to="/login" />;
}

export { PrivateRoute };
