import React from "react";
import {IconButton, Dialog, DialogTitle, DialogContent, DialogActions} from "@mui/material";
import CustomButton from "./CustomButton";
import CloseIcon from "@mui/icons-material/Close";

const CustomDialog = ({open, onClose, maxWidth, title, content, actions, customStyles, globalSx}) => {

    return (
        <Dialog 
            open={open} 
            onClose={onClose} 
            maxWidth={maxWidth} 
            fullWidth 
            sx={{...globalSx}}
        >
            <DialogTitle sx={{fontSize: 22, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                {title}
                
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers sx={{borderBottom: "none", padding: "24px 24px 16px", ...globalSx?.content}}>
                {content}
            </DialogContent>

            {actions && (
                <DialogActions sx={{padding: "16px 20px 30px", ...globalSx?.actions}}>
                    {actions?.map((action, index) => (
                        <CustomButton 
                            key={index}
                            variant={action.variant ? action.variant : "contained"}
                            label={action.label}
                            customStyles={customStyles?.actions}
                            onClick={action.onClick}
                        />
                    ))}
                </DialogActions>
            )}
        </Dialog>
    )
}

export default CustomDialog
