import { Alert, Box, Button, Chip, Divider, Drawer,  ListItemText, MenuItem, MenuList, Toolbar, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { format } from "date-fns";
import moment from "moment";
import CreateIcon from '@mui/icons-material/Create';

export default function DocumentViewDrawerComponent (props) {

    const {signatoryList, documentById, automationRecord} = props;


    console.log('get document details', documentById)
    console.log('get signatory list by id aaaaaa', automationRecord)

    const signatoryCounter = signatoryList?.filter(item => item.status === "DONE").length;

    return(
        <Drawer
            sx={{
                width: 400,
                // flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 400,
                    boxSizing: 'border-box',
                    border:'none'
                },
                }}
                variant="permanent"
                anchor="right"
        >
            <Toolbar/>
            <br/>
            <Box sx={{textAlign:'start', p: 3}}>
                <Typography sx={{fontSize: 10, fontWeight:'bold'}}>
                    Document Name:
                </Typography>
                <Typography sx={{mb: 3, color: '#604CC3', fontSize: 20}}>
                    {documentById?.title}
                </Typography>

                <Typography sx={{fontSize: 12, textAlign:'start'}}>Created on {moment(documentById?.created_time).format("MMM D, YYYY, h:mm:ss A")}</Typography>
                {
                    documentById?.status &&
                    <>
                        <Typography sx={{fontSize: 16, my: 1, textAlign:'start', fontWeight: 'bold'}}>
                            Status: 
                            <Chip sx={{ml: 1}} 
                                label={documentById?.status}
                                color={
                                    documentById?.status == "COMPLETED" ? 
                                    "success"
                                    :
                                    documentById?.status == "ONGOING" ? 
                                    "warning"
                                    :
                                    documentById?.status == "SCHEDULED" ? 
                                    "info"
                                    :
                                    documentById?.status == "ERROR" ? 
                                    "error"
                                    :
                                    "primary"
                                }
                            />
                        </Typography>
                        <Divider />
                    </>
                }
                {
                    documentById?.status == "COMPLETED" ? 
                        (documentById?.is_auto_expire && automationRecord?.data?.DocumentExpire?.expired_date) &&
                        <Typography sx={{fontSize: 16, my: 1, fontWeight: 'bold'}}>
                            Expires on: {moment(automationRecord?.data?.DocumentExpire?.expired_date).format("MMM D, YYYY")}
                        </Typography>
                    :
                    documentById?.status == "ONGOING" ?
                    <>
                        <Typography sx={{fontSize: 12, my: 1, textAlign:'start', fontWeight: 'bold'}}>
                            Auto Reminder:
                            <Chip sx={{ml: 1}} 
                                variant="outlined"
                                size="small"
                                label={documentById?.is_auto_remind ? "Enabled" : "Disabled"}
                                color={
                                    documentById?.is_auto_remind ? 
                                    "success"
                                    :
                                    "error"
                                }
                            />
                        </Typography>
                        <Typography sx={{fontSize: 12, my: 1, textAlign:'start', fontWeight: 'bold'}}>
                            Auto Expire:
                            <Chip sx={{ml: 1}} 
                                variant="outlined"
                                size="small"
                                label={documentById?.is_auto_expire ? "Enabled" : "Disabled"}
                                color={
                                    documentById?.is_auto_expire ? 
                                    "success"
                                    :
                                    "error"
                                }
                            />
                        </Typography>
                    </>
                    :
                    documentById?.status == "SCHEDULED" ?
                    <>
                        {
                            automationRecord?.data?.AutoScheduled?.auto_scheduled_date_time &&
                            <Typography sx={{fontSize: 16, textAlign:'start', my: 1, fontWeight: 'bold'}}>
                                {`Scheduled on: ${moment(automationRecord?.data?.AutoScheduled?.auto_scheduled_date_time).format("MMM D, YYYY, h:mm:ss A")}`}
                            </Typography>
                        }
                        <Typography sx={{fontSize: 12, my: 1, textAlign:'start', fontWeight: 'bold'}}>
                            Auto Reminder:
                            <Chip sx={{ml: 1}} 
                                variant="outlined"
                                size="small"
                                label={documentById?.is_auto_remind ? "Enabled" : "Disabled"}
                                color={
                                    documentById?.is_auto_remind ? 
                                    "success"
                                    :
                                    "error"
                                }
                            />
                        </Typography>
                        <Typography sx={{fontSize: 12, my: 1, textAlign:'start', fontWeight: 'bold'}}>
                            Auto Expire:
                            <Chip sx={{ml: 1}} 
                                variant="outlined"
                                size="small"
                                label={documentById?.is_auto_expire ? "Enabled" : "Disabled"}
                                color={
                                    documentById?.is_auto_expire ? 
                                    "success"
                                    :
                                    "error"
                                }
                            />
                        </Typography>
                    </>
                    :
                    null
                }
                
                
                <Divider/>
                
                <Box sx={{mt: 4, mb: 4}}>
                    <Typography sx={{fontWeight:'bold', fontSize: 15}}>
                        Signatories
                    </Typography>
                    <MenuList sx={{ maxHeight: 200, // Set maximum height for scrolling
                    overflowY: 'auto', }}>
                        {signatoryList?.map((signatoryList) => {
                            return (
                                // <MenuItem disabled>
                                    <ListItemText secondary={signatoryList.email_address} sx={{fontSize: 10, color:'gray', ml: 2}}/>
                                // </MenuItem>
                            )
                        })}
                        {signatoryList?.length === 0 && 
                            <Typography sx={{color:'gray', fontSize: 12, textAlign:'center', mt: 2}}>
                                No signatories added.
                            </Typography>
                        }
                    </MenuList>
                </Box>
                <Divider/>
                <Box sx={{mt: 4}}>
                    <Typography sx={{fontWeight:'bold', fontSize: 15}}>
                        CC Emails
                    </Typography>
                    <MenuList sx={{ maxHeight: 200, // Set maximum height for scrolling
                    overflowY: 'auto', }}>
                        {documentById?.cc_emails?.map((emails) => {
                            return (
                                // <MenuItem disabled>
                                // {console.log('emails', emails)}
                                    <ListItemText secondary={emails} sx={{fontSize: 10, color:'gray,', ml: 2}}/>
                                // </MenuItem>
                            )
                        })}
                        {documentById?.cc_emails === null && 
                            <Typography sx={{color:'gray', fontSize: 12, textAlign:'center', mt: 2, mb: 2}}>
                                No cc added.
                            </Typography>
                        }
                    </MenuList>
                    
                </Box>
                <Divider/>
                
                
                    
                    {/* <Typography sx={{fontWeight:'bold', fontSize: 15}}>
                        Total Signed:
                    </Typography>
                    <Box sx={{flexGrow:1}}/>
                    <Typography sx={{fontWeight:'bold', fontSize: 14}}>07/10</Typography> */}
                    <Alert variant="filled" action={signatoryList ? `${ signatoryCounter}/${signatoryList?.length}` : ''} icon={<CreateIcon />} sx={{alignItems:'center', bgcolor:'#604CC3', pr: 5, mt: 2}}>
                        <Typography sx={{fontWeight:'bold', fontSize: 15}}>
                            Total Signed:
                        </Typography>
                    </Alert>
                
            </Box>
        </Drawer>
    )
}