import React, {useEffect} from "react";
import {Dialog, DialogTitle, DialogContent, Typography, Box} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InvalidInvitationImage from "../../../../assets/images/no-workspace2.png";
import useSelectedWorkspaceMemberStore from "../../zustand/useSelectedWorkspaceMemberStore";

const InvalidWorkspaceInvitationDialog = (props) => {

  const {open, setOpen} = props;
  const setInvalidWorkspaceInvitation = useSelectedWorkspaceMemberStore((state) => state.setInvalidWorkspaceInvitation);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setInvalidWorkspaceInvitation(false);
        setOpen(false); 
      }, 3200);

      return () => clearTimeout(timer);
    }
  }, [open, setOpen]);


  const handleClose = () => {
    setOpen(false);
    setInvalidWorkspaceInvitation(false);
  }


  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth="sm">
      <DialogTitle sx={{display: "flex", justifyContent: "center", padding: "24px 10px", gap: 1, fontWeight: 500, color: "#fff", background: "linear-gradient(135deg, #B71C1C, #D32F2F, #FF1744, #FF5252)"}}>
        <ErrorOutlineIcon sx={{color: "#ffff", fontSize: 30}} /> Invalid Workspace Invitation
      </DialogTitle>

      <DialogContent sx={{textAlign: "center", p: "12px 24px 45px !important"}}>
        <Box display="flex" justifyContent="center" my={2}>
          <img src={InvalidInvitationImage} alt="" width={150} height={150} />
        </Box>

        <Typography variant="body1" sx={{color: "#58565f"}}>
          The workspace invitation link is invalid or has expired. 
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default InvalidWorkspaceInvitationDialog;
