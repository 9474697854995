import { Box } from "@mui/material";
import RequiredActionListTableComponent from "../components/required-action-component/required-action-list-table.component";
import { useEffect, useRef, useState } from "react";
import useAuthentication from "../../profile-plugin/hooks/useAuthentication";


export default function DocumentRequiredActionScreen () {

    const { viewProfile, profileData, isFetchingProfileDetails } = useAuthentication();

     const [isEmail, setIsEmail] = useState(JSON.parse(localStorage.getItem('isEmail')));
    const [isWorkspace, setIsWorkspace] = useState(JSON.parse(localStorage.getItem('isWorkspace')));
    const [menuSelected, setMenuSelected] = useState(localStorage.getItem('menuSelected'));
    const [wallet_id, setWalletId] = useState(localStorage.getItem('wallet_id'));
    
        // Ref to track previous state values and avoid infinite loop
    const prevIsEmail = useRef(isEmail);
    const prevIsWorkspace = useRef(isWorkspace);
    const prevMenuSelected = useRef(menuSelected);
    const prevWalletId = useRef(wallet_id)

    useEffect(() => {
        const syncLocalStorage = () => {
        const storedIsEmail = JSON.parse(localStorage.getItem('isEmail'));
        const storedIsWorkspace = JSON.parse(localStorage.getItem('isWorkspace'));
        const storedMenuSelected = localStorage.getItem('menuSelected');
        const storedWalletId = localStorage.getItem('wallet_id');

            // Check if stored values are different from previous ones, update state if necessary
            if (storedIsEmail !== prevIsEmail.current) {
                setIsEmail(storedIsEmail);
                prevIsEmail.current = storedIsEmail;
            }

            if (storedIsWorkspace !== prevIsWorkspace.current) {
                setIsWorkspace(storedIsWorkspace);
                prevIsWorkspace.current = storedIsWorkspace;
            }

            if (storedMenuSelected !== prevMenuSelected.current) {
                setMenuSelected(storedMenuSelected);
                prevMenuSelected.current = storedMenuSelected;
            }

            if (storedWalletId !== prevWalletId.current) {
                setWalletId(storedWalletId);
                prevWalletId.current = storedWalletId;
            }
        };

        // Set interval to sync localStorage every 500ms
        const intervalId = setInterval(syncLocalStorage, 500);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);  // Empty dependency array to run once on mount

    useEffect(() => {
        viewProfile()
    },[])

    let workspace_id = isWorkspace ? menuSelected : null

    return(
        <Box>
            <RequiredActionListTableComponent isWorkspace={isWorkspace} menuSelected={menuSelected} />
        </Box>
    )
}