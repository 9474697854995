const TOKEN_MANAGEMENT_API_ENDPOINT = process.env.REACT_APP_TOKEN_MANAGEMENT_API_ENDPOINT;
const ORDER_MANAGEMENT_API_ENDPOINT = process.env.REACT_APP_ORDER_API_ENDPOINT;
   
export default { 

//TOKEN LEDGER
GET_WALLET_BALANCE:                                              TOKEN_MANAGEMENT_API_ENDPOINT + "/api/v1/token/balance"  ,
GET_TOKEN_PRICE :                                                TOKEN_MANAGEMENT_API_ENDPOINT + "/api/v1/exchange-rate/retrieve",

//TOKEN TRANSACTIONS
GET_TOKEN_TRANSACTIONS:                                          TOKEN_MANAGEMENT_API_ENDPOINT + "/api/v1/token-transactions/wallet",
GET_PURCHASE_TRANSACTIONS:                                       TOKEN_MANAGEMENT_API_ENDPOINT + "/api/v1/purchase-transactions/wallet",

//TOKEN PACKAGE
GET_TOKEN_PACKAGES:                                              TOKEN_MANAGEMENT_API_ENDPOINT + "/api/v1/token-package/list",
VIEW_TOKEN_PACKAGE:                                              TOKEN_MANAGEMENT_API_ENDPOINT + "/api/v1/token-package/view",

//ORDER
CREATE_ORDER:                                                    ORDER_MANAGEMENT_API_ENDPOINT + "/api/v1/orders/create",
LIST_ORDER:                                                      ORDER_MANAGEMENT_API_ENDPOINT + "/api/v1/orders/list",
LIST_ORDER_ITEMS:                                                ORDER_MANAGEMENT_API_ENDPOINT + "/api/v1/orders/list/items",
VIEW_ORDER:                                                      ORDER_MANAGEMENT_API_ENDPOINT + "/api/v1/orders/view",
PLACE_ORDER:                                                     ORDER_MANAGEMENT_API_ENDPOINT + "/api/v1/orders/place",
CANCEL_ORDER:                                                    ORDER_MANAGEMENT_API_ENDPOINT + "/api/v1/orders/cancel",
ORDER_LIST_STATUS:                                               ORDER_MANAGEMENT_API_ENDPOINT + "/api/v1/orders/list/status",
PENDING_ORDER:                                                   ORDER_MANAGEMENT_API_ENDPOINT + "/api/v1/orders/pending",

//ORDER_ITEM
CREATE_ITEM:                                                     ORDER_MANAGEMENT_API_ENDPOINT + "/api/v1/orders/items/create",
UPDATE_ITEM:                                                     ORDER_MANAGEMENT_API_ENDPOINT + "/api/v1/orders/items/update",
DELETE_ITEM:                                                     ORDER_MANAGEMENT_API_ENDPOINT + "/api/v1/orders/items/delete",

//PAYMENT
PAY_ORDER:                                                       ORDER_MANAGEMENT_API_ENDPOINT + "/api/v1/payments/pay"

}