import React, {useState} from "react";
import {Button, Box, InputAdornment, TextField, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, CircularProgress} from "@mui/material";
import CustomButton from "../../../../core/components/ui/CustomButton";
import CustomDialogLoaderComponent from "../../../../core/components/common/custom-dialog-loader.component";
import useMembersManagement from "../../hooks/useMembersManagement";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from "@mui/icons-material/Add";

const AddSigneeEmail = ({open, setOpen, workspaceId}) => {
  const {isAddingAsSignee, addWSGuestSignee} = useMembersManagement();

  const [emails, setEmails] = useState([""]);
  const [validEmails, setValidEmails] = useState(emails?.map(() => true));

  const handleAddField = () => {
    setEmails([...emails, ""]);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleRemoveField = (index) => {
    setEmails(emails.filter((_, i) => i !== index));
    setValidEmails(validEmails.filter((_, i) => i !== index)); 
  }

  const validateEmail = (email) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(email);
  }

  const handleEmailChange = (index, value) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = value;

    const updatedValidEmails = [...validEmails];
    updatedValidEmails[index] = value === "" || validateEmail(value); 

    setEmails(updatedEmails);
    setValidEmails(updatedValidEmails);
  }

  const handleAddSigneeEmail = () => {
    const filteredEmails = emails?.filter(email => email.trim() !== "");

    const payload = {
      workspace_id: workspaceId,
      emails: filteredEmails
    }

    addWSGuestSignee(payload, setOpen);
  }

  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      maxWidth={"sm"} 
      fullWidth 
    >
      <DialogTitle sx={{fontSize: 22, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        Add Signee Email

        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers sx={{borderBottom: "none", padding: "24px 30px 30px"}}>
        {(isAddingAsSignee) ? 
          <CustomDialogLoaderComponent 
            icon={<CircularProgress size={40} sx={{color: "#604CC3"}} />} 
            message="Saving..." 
          />
        : 
          <>
            {emails?.map((email, index) => (
              <TextField
                key={index}
                variant="outlined"
                fullWidth
                placeholder="Email Address"
                onChange={(e) => handleEmailChange(index, e.target.value)}
                slotProps={{
                  input: {  
                    endAdornment: (
                      <InputAdornment position="end">
                        {emails.length > 1 && index !== 0 && (
                          <IconButton onClick={() => handleRemoveField(index)} size="small">
                            <DeleteForeverIcon sx={{color: "red"}} />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  },
                  formHelperText: {
                    sx: {backgroundColor: "white !important", mr: "0px !important", ml: "0px !important"}
                  }
                }}
                sx={{mt: 2, backgroundColor: "#eeebf8", borderRadius: "2px", 
                  "& fieldset": {
                    border: "none",
                  }
                }}
                helperText={
                  <p style={{fontSize: 12, color: "red", margin: "2px 0px 0px 2px"}}>
                    {(email !== "" && !validEmails[index]) ? "Email Address is invalid" : ""}
                  </p>
                }
              />
            ))}

            <Box sx={{ml: "auto", textAlign: "end", mr: "-9px"}}>
              <Button 
                variant="text" 
                startIcon={<AddIcon fontSize="small" />}
                sx={{textTransform: "none", fontSize: 16, fontWeight: 400}}
                onClick={(e) => handleAddField(e)}
              >
                Add
              </Button>
            </Box>
          </>
        }
      </DialogContent>

      <DialogActions sx={{padding: "16px 36px 34px", display: (isAddingAsSignee) ? "none" : {}}}>
        <CustomButton 
          variant="contained" 
          label={"Save"}
          sx={{width: "100px",
            opacity: ((validEmails.includes(false) || emails?.every(email => email.trim() === "")) && !isAddingAsSignee) ? "55%" : "100%",
            cursor: ((validEmails.includes(false) || emails?.every(email => email.trim() === "")) && !isAddingAsSignee) ? "not-allowed" : "pointer",
            visibility: (isAddingAsSignee) ? "hidden" : "visible",
          }}
          onClick={(validEmails.includes(false) || emails?.every(email => email.trim() === "")) ? null : handleAddSigneeEmail}
        />
      </DialogActions>
    </Dialog>
  )
}

export default AddSigneeEmail
