import {FirebaseAuth} from "../../plugins/authentication-plugin";
import DashboardScreen from "../../plugins/dashboard-plugin/pages/DashboardScreen";
import TemplateScreen from "../../plugins/template-plugin/pages/TemplateScreen";
import { DocumentEditorScreen, DocumentViewScreen } from "../../plugins/supersign-docs-plugin/document-editor-plugin";
import {DocumentCreateScreen, DocumentListViewScreen, DocumentScreen, DocumentSharedScreen} from '../../plugins/documents-plugin'
import {WorkspaceListScreen, WorkspaceViewScreen} from "../../plugins/workspace-plugin";
import DocumentSigneeScreen from "../../plugins/supersign-docs-plugin/document-signing-plugin/pages/DocumentSigneeScreen";
import WorkspaceAcceptInvitePage from "../../plugins/workspace-plugin/pages/workspace-accept-invite.page";
import ProfileScreen from "../../plugins/profile-plugin/pages/ProfileScreen";
import LandingConfirmationComponent from "../components/landing2/landing-confirmation.component";
import AuthLinkScreen from "../../plugins/authentication-plugin/pages/AuthLinkScreen";
import BuyTokenScreen from "../../plugins/token-plugin/pages/BuyTokenScreen";
import CheckoutScreen from "../../plugins/token-plugin/pages/CheckoutScreen";
import TokenPluginPage from "../../plugins/token-plugin/pages/TokenPluginPage";
import PurchaseTransactionsScreen from "../../plugins/token-plugin/pages/PurchaseTransactionsScreen";
import OrderTransactionsScreen from "../../plugins/token-plugin/pages/OrderTransactionsScreen";
import ViewOrderDetailsScreen from "../../plugins/token-plugin/pages/ViewOrderDetailsScreen";
import SuccessPaymentScreen from "../pages/SuccessPaymentScreen";
import { PlaceOrderScreen } from "../../plugins/token-plugin";
import TokenScreen from "../../plugins/token-plugin/pages/TokenScreen";
import DocumentRequiredActionScreen from "../../plugins/documents-plugin/pages/DocumentRequiredActionScreen";
import LandingScreen from "../pages/LandingScreen";

export const routes = [

    
    {
        path: "/",
        element: <LandingScreen/>,
        isPrivate: false
    },

    {
        path: "/login",
        element: <FirebaseAuth/>,
        isPrivate: false
    },
    
    {
        path: "/dashboard",
        element: <DashboardScreen/>,
        isPrivate: true,
    },

    {
        path: "/auth/link",
        element: <AuthLinkScreen/>,
        isPrivate: false,
    },

    {
        path: "/documents",
        element: <DocumentScreen/>,
        isPrivate: true,
        children: [
            {
                index: true,
                element: <DocumentListViewScreen/>
            },
            {
                path: "create",
                element: <DocumentCreateScreen/>,
            },
            {
                path: "shared",
                element: <DocumentSharedScreen/>,
            },
            
        ],
        
    },
    {
        path: "/templates",
        element: <TemplateScreen/>,
        isPrivate: true,
    },

    {
        path: "/workspaces",
        element: <WorkspaceListScreen />,
        isPrivate: true,
    },

    {
        path: "/workspaces/view",
        element: <WorkspaceViewScreen />,
        isPrivate: true,
    },

    {
        path: "/document-editor",
        element: <DocumentEditorScreen/>,
        isPrivate: true,
    },

    {
        path: "/view-document",
        element: <DocumentViewScreen/>,
        isPrivate: true,
    },

    {
        path: "/sign",
        element: <DocumentSigneeScreen/>,
        isPrivate: false,
    },
    
    {
        path: "/accept",
        element: <WorkspaceAcceptInvitePage />,
        isPrivate: false,
    },

    {
        path: "/profile",
        element: <ProfileScreen />,
        isPrivate: true,
    },

    {
        path: "/my-token",
        element: <TokenScreen />,
        isPrivate: true,
    },


    {
        path: "/token",
        element: <TokenPluginPage />,
        isPrivate: true,
        children: [
            {
                path: "",
                element: <BuyTokenScreen />
            },
            {
                path: "checkout",
                element: <CheckoutScreen />
            },
        ],
    },

    {
        path: "/order-details",
        element: <ViewOrderDetailsScreen />,
        isPrivate: true,
    },

    {
        path: "/place-order",
        element: <PlaceOrderScreen />,
        isPrivate: true,
    },
    
    {
        path: "/purchase-transactions",
        element: <PurchaseTransactionsScreen />,
        isPrivate: true,
    },


    {
        path: "/order-transactions",
        element: <OrderTransactionsScreen />,
        isPrivate: true,
    },



    {
        path: "/landing-confirmation",
        element: <LandingConfirmationComponent />,
        isPrivate: false,
    },

    {
        path: "/thankyou",
        element: <SuccessPaymentScreen />,
        isPrivate: true,
    },

    {
        path: "/required-action",
        element: <DocumentRequiredActionScreen />,
        isPrivate: true,
    },

 
]
