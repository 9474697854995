import {useEffect, useState} from "react";
import {Alert, Box, Button, Grid2, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress} from "@mui/material";
import {useSnackbar} from "notistack";
import {FileUploader} from "react-drag-drop-files";
import useUploadWorkspaceLogoStore from "../../../plugins/workspace-plugin/zustand/useUploadWorkspaceLogoStore";
import CustomDialogLoaderComponent from "./custom-dialog-loader.component";

const CustomFileUploaderComponent = (props) => {

    const {open, setOpen, title, maxWidth, handleSave, isLoading} = props;

    const {enqueueSnackbar} = useSnackbar();
    const {setUploadedWorkspaceLogo} = useUploadWorkspaceLogoStore();

    const [uploadedFile, setUploadedFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);
    const [fileSize, setFileSize] = useState("0");


    useEffect(() => {
        if (uploadedFile) {
            const sizeInBytes = uploadedFile?.size;
            const sizeInKilobytes = bytesToKilobytes(sizeInBytes);
    
            setFileSize(sizeInKilobytes);
        }
    }, [uploadedFile])


    const handleChange = (file) => {      
        if (file) {
            setUploadedFile(file);
            setUploadedWorkspaceLogo(file);
            setFilePreview(URL.createObjectURL(file));

        } else {
            enqueueSnackbar("Invalid file type. Please upload an image.", {
                variant: "error",
                autoHideDuration: 2000
            });
        }
    }

    const bytesToKilobytes = (bytes) => {
        const kilobytes = bytes / 1024;

        return kilobytes;
    }

    const handleClose = () => {
        setOpen(false);
    }
       
    const handleResetFile = () => {
        setUploadedWorkspaceLogo(null);
        setUploadedFile(null);
        setFilePreview(null);
        setFileSize("0"); 
    }


    return(
        <Dialog 
            open={open} 
            onClose={handleClose} 
            maxWidth={maxWidth} 
            fullWidth 
        >
            <DialogTitle sx={{fontSize: 22}}>
                {title}
            </DialogTitle>
            
            <DialogContent dividers sx={{borderBottom: "none", padding: "24px 24px 16px"}}>
                {(isLoading) ? 
                    <CustomDialogLoaderComponent 
                        icon={<CircularProgress size={40} sx={{color: "#604CC3"}} />} 
                        message="Saving..." 
                    />
                :  
                    <>
                        {filePreview &&
                            <Grid2 sx={{textAlign: "end"}}>
                                <Button onClick={(e) => handleResetFile(e)} sx={{cursor: "pointer", textTransform: "none", borderRadius: 0, fontSize: 14, color: "#604CC3"}}>
                                    Clear
                                </Button>
                            </Grid2>
                        }

                        <Grid2 item xs={12}>
                            {(uploadedFile) ? 
                                <div 
                                    style={{
                                        position: "relative",
                                        display: "flex",
                                        alignItems: "center",
                                        minWidth: "100% !important",
                                        maxWidth: "100% !important",
                                        border: "dashed 2px #604CC3",
                                        borderRadius: "3px",
                                        cursor: "pointer",
                                        flexGrow: 0,
                                        height: "210px",
                                        justifyContent: "center",
                                        margin: "auto",
                                        transition: "border-color .15s linear",
                                        backgroundColor:'white'
                                    }}
                                >
                                    <img alt="signature-preview" src={filePreview} style={{height: 150, objectFit: "contain", width: "100%"}} />
                                </div>

                            :
                                <FileUploader
                                    hoverTitle=""
                                    multiple={false}
                                    handleChange={handleChange}
                                    name="file"
                                    label="Drag and Drop file here or click here to upload"
                                    types={["svg", "jpg", "jpeg", "png", "jfif"]}
                                    onTypeError={(err) => {
                                        enqueueSnackbar("Invalid file type. Only image files are supported.", {
                                            variant: "error",
                                            autoHideDuration: 3500
                                        });
                                    }}
                                    children={
                                        <div
                                            style={{
                                                position: "relative",
                                                display: "flex",
                                                alignItems: "center",
                                                minWidth: "100% !important",
                                                maxWidth: "100% !important",
                                                border: "dashed 2px #604CC3",
                                                borderRadius: "3px",
                                                cursor: "pointer",
                                                flexGrow: 0,
                                                height: "250px",
                                                justifyContent: "center",
                                                margin: "auto",
                                                transition: "border-color .15s linear",
                                                backgroundColor:'white'
                                                
                                            }}
                                        >
                                            <p style={{color: "#666", fontSize: 15}}>
                                                Drag and Drop file here or click here to upload
                                            </p>
                                        </div>
                                    }
                                />
                            }
                        </Grid2>

                        <Alert severity={(fileSize <= 1024 && fileSize !== 0) ? "info" : "error"} sx={{mt: 2}}>
                            Maximum of 1MB file size
                        </Alert>
                    </>
                }
            </DialogContent>

            {(!isLoading) && 
                <DialogActions sx={{padding: "16px 24px 24px !important"}}>
                    <Box sx={{textAlign: "end"}}>
                        <Button variant="outlined" sx={{textTransform: "none", borderColor: "#604CC3", color: "#604CC3", width: 120, fontSize: 16}} 
                            onClick={(e) => handleClose()}
                        >
                            Close
                        </Button>

                        &nbsp; &nbsp;

                        <Button variant="contained" sx={{backgroundColor: '#604CC3',  boxShadow: 'none', textTransform: "none", width: 120, fontSize: 16,
                            opacity: (fileSize <= 1024 && fileSize !== "0") ? "100%" : "55%", 
                            cursor: (fileSize <= 1024 && fileSize !== "0") ? "pointer" : "not-allowed"}}
                            onClick={(fileSize <= 1024 && fileSize !== "0") ? handleSave : null}
                        >
                            Save
                        </Button>
                    </Box>
                </DialogActions>
            }
        </Dialog>
    )
}

export default CustomFileUploaderComponent