import routes from "../common/api-routes";
import {HttpAuthGet, HttpAuthPost, HttpAuthPut, HttpAuthDelete, HttpAuthGetBlob, HttpAuthPostParams} from "../../../../core/services/common/HttpRequestBuilder";

const createDocument = (payload) => {
    let result = HttpAuthPost(routes.CREATE_DOCUMENT, payload);

    return result;
}

const getDocumentById = (id) => {

    let query_params = {
        id: id
    }

    let result = HttpAuthGet(routes.GET_DOCUMENT_BY_ID, query_params);

    return result;
}


const getDocumentList = (workspace_id, page, limit) => {
    let query_params = {
        workspace_id: workspace_id,
        page: page,
        limit: limit
    }
    let result = HttpAuthGet(routes.GET_DOCUMENT_LIST, query_params);

    return result;
}

const viewDocument = (encrypted_file) => {

    let query_params = {
        encrypted_file: encrypted_file
    }

    let result = HttpAuthGetBlob(routes.VIEW_DOCUMENT, query_params);

    return result;
}

const filterDocumentByStatus = (workspace_id, status, title, page, limit) => {
    let query_params = {
        workspace_id: workspace_id,
        status: status,
        title: title,
        page: page,
        limit: limit
    }

    let result = HttpAuthGet(routes.DOCUMENT_FILTER_STATUS, query_params);
    
    return result;
}


const getDocumentHistory = (parameters) => {
    let result = HttpAuthGet(routes.GET_DOCUMENT_HISTORY, parameters);

    return result;
}

const resendDocument = (payload) => {
    let result = HttpAuthPost(routes.DOCUMENT_RESEND, payload);

    return result;
}

const cancelDocument = (document_id, cancel_reason) => {

    let query_params = {
        document_id: document_id,
        cancel_reason: cancel_reason
    }
    let result = HttpAuthPostParams(routes.CANCEL_DOCUMENT, query_params);

    return result;
}

const getDocumentTransactionPrice = (fileSize) => {
    let query_params = {
        fileSize: fileSize,
    }

    let result = HttpAuthGet(routes.DOCUMENT_TRANSACTION_PRICE, query_params);
    
    return result;
}

const getRequiredActionList = (workspace_id, page, limit) => {
    let query_params = {
        workspace_id: workspace_id,
        page: page,
        limit: limit
    }

    let result = HttpAuthGet(routes.REQUIRED_ACTION_LIST, query_params);
    
    return result;
}


const DocumentService = {
    createDocument: createDocument,
    getDocumentById: getDocumentById,
    getDocumentList: getDocumentList,
    viewDocument: viewDocument,
    filterDocumentByStatus: filterDocumentByStatus,
    getDocumentHistory,
    resendDocument,
    cancelDocument,
    getDocumentTransactionPrice,
    getRequiredActionList
}

export default DocumentService