const minElementWidth = 40
const minElementHeight = 20

const elementTypes = {
    userSignature: 'esignature',
    qrcode: 'qrcode',
    textField: 'textfield',
    date: 'date',
    signatory: 'signatory',
}

const fillable_elementTypes = {
    fillable_textfield: 'fillable_textfield',
    fillable_date: 'fillable_date',
}

export {minElementHeight, minElementWidth, elementTypes, fillable_elementTypes}