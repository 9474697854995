import axios from "axios";
import {QueryParamsFormat} from "./HttpUtils";

// Actual Request using Axios
const HttpActualGet = (path, parameters, azureSubscriptionKey, accessToken) => {
    
    let headers = {}

    if (accessToken) {
        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        };
    }
    
    if (azureSubscriptionKey) {
        headers = {
            ...headers,
            'Ocp-Apim-Subscription-Key': azureSubscriptionKey
        };
        
    }
    
    let apiEndpoint = path;
    let queryParams = QueryParamsFormat(parameters);
    
    if (queryParams) {
        queryParams =  "?" + queryParams;
    } else {
        queryParams = '';
    }

    console.log("apiEndpoint", apiEndpoint);
    console.log("parameters", parameters);
    console.log("headers", headers);
    console.log("azureSubscriptionKey", azureSubscriptionKey);
    

    let result = axios.get(apiEndpoint + queryParams, {
        headers: headers
    });

    console.log("HttpActualGet result", result);
    
    return result
}

const HttpActualGetBlob = (path, parameters, azureSubscriptionKey, accessToken) => {
    
    let headers = {}

    if (accessToken) {
        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`,
        }
        
    }
    
    if (azureSubscriptionKey) {
        headers = {
            ...headers,
            'Ocp-Apim-Subscription-Key': azureSubscriptionKey
        };
        
    }
    
    
    let apiEndpoint = path;
    let queryParams = QueryParamsFormat(parameters);
    
    if (queryParams) {
        queryParams =  "?" + queryParams;
    } else {
        queryParams = '';
    }

    console.log("apiEndpoint", apiEndpoint);
    console.log("parameters", parameters);
    console.log("headers", headers);
    console.log("azureSubscriptionKey", azureSubscriptionKey);
    

    let result = axios.get(apiEndpoint + queryParams, {
        headers: headers,
        responseType: "blob"
    });

    console.log("HttpActualGet result", result);
    
    return result
}


const HttpActualPost = (path, payload, azureSubscriptionKey, accessToken) => {

    let headers = {}

    if (accessToken) {
        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        };
    }
    
    if (azureSubscriptionKey) {
        headers = {
            ...headers,
            'Ocp-Apim-Subscription-Key': azureSubscriptionKey
        };
        
    }

    let apiEndpoint = path;
    
    console.log("apiEndpoint", apiEndpoint);
    console.log("payload", payload);
    console.log("headers", headers);
    console.log("azureSubscriptionKey", azureSubscriptionKey);
    

    let result = axios.post(apiEndpoint, payload, {
        headers: headers
    });

    console.log("HttpActualPost result", result);
    return result
}

const HttpActualPostParams = (path, parameters, azureSubscriptionKey, accessToken) => {
    let headers = {}

    if (accessToken) {
        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        };
    }
    
    if (azureSubscriptionKey) {
        headers = {
            ...headers,
            'Ocp-Apim-Subscription-Key': azureSubscriptionKey
        };
        
    }
    
    let apiEndpoint = path;
    let queryParams = QueryParamsFormat(parameters);
    
    if (queryParams) {
        queryParams =  "?" + queryParams;
    } else {
        queryParams = '';
    }

    console.log("apiEndpoint", apiEndpoint);
    console.log("parameters", parameters);
    console.log("headers", headers);
    console.log("azureSubscriptionKey", azureSubscriptionKey);
    

    let result = axios.post(apiEndpoint + queryParams, {}, { headers });

    console.log("HttpActualGet result", result);
    
    return result
}

const HttpActualPut = (path, parameters = null, payload = null, azureSubscriptionKey, accessToken) => {
    
    let headers = {}

    if (accessToken) {
        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        };
    }
    
    if (azureSubscriptionKey) {
        headers = {
            ...headers,
            'Ocp-Apim-Subscription-Key': azureSubscriptionKey
        };
        
    }


    let apiEndpoint = path;
    let queryParams = QueryParamsFormat(parameters);
    
    if (queryParams) {
        queryParams =  "?" + queryParams;
    } else {
        queryParams = '';
    }

    console.log("apiEndpoint", apiEndpoint);
    console.log("queryParams", queryParams);
    console.log("payload", payload);
    console.log("headers", headers);
    console.log("azureSubscriptionKey", azureSubscriptionKey);
    

    let result = axios.put(apiEndpoint + queryParams, payload,{
        headers: headers
    });

    console.log("HttpActualPut result", result);

    return result;
}

const HttpActualPut1 = (path, payload, azureSubscriptionKey, accessToken) => {

    let headers = {}

    if (accessToken) {
        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        };
    }
    
    if (azureSubscriptionKey) {
        headers = {
            ...headers,
            'Ocp-Apim-Subscription-Key': azureSubscriptionKey
        };
        
    }

    let apiEndpoint = path;
    
    console.log("apiEndpoint", apiEndpoint);
    console.log("payload", payload);
    console.log("headers", headers);
    console.log("azureSubscriptionKey", azureSubscriptionKey);
    

    // let result = axios.put(apiEndpoint, { data: payload, headers: headers })
    let result = axios.put(apiEndpoint, payload, { headers: headers })

    console.log("HttpActualPut1 result", result);
    return result
}


const HttpActualDelete = (path, parameters = null, azureSubscriptionKey, accessToken) => {
    
    let headers = {}

    if (accessToken) {
        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        };
    }
    
    if (azureSubscriptionKey) {
        headers = {
            ...headers,
            'Ocp-Apim-Subscription-Key': azureSubscriptionKey
        };
        
    }


    let apiEndpoint = path;
    let queryParams = QueryParamsFormat(parameters);
    
    if (queryParams) {
        queryParams =  "?" + queryParams;
    } else {
        queryParams = '';
    }

    console.log("apiEndpoint", apiEndpoint);
    console.log("queryParams", queryParams);
    console.log("headers", headers);
    console.log("azureSubscriptionKey", azureSubscriptionKey);
    

    let result = axios.delete(apiEndpoint + queryParams,{
        headers: headers
    });

    console.log("HttpActualDelete result", result);

    return result;
}

const HttpActualDelete1 = (path, payload, azureSubscriptionKey, accessToken) => {

    let headers = {}

    if (accessToken) {
        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        };
    }
    
    if (azureSubscriptionKey) {
        headers = {
            ...headers,
            'Ocp-Apim-Subscription-Key': azureSubscriptionKey
        };
        
    }

    let apiEndpoint = path;
    
    console.log("apiEndpoint", apiEndpoint);
    console.log("payload", payload);
    console.log("headers", headers);
    console.log("azureSubscriptionKey", azureSubscriptionKey);
    

    let result = axios.delete(apiEndpoint, { data: payload, headers: headers })

    console.log("HttpActualDelete1 result", result);
    return result
}

const HttpActualPostMultipart = (path, payload, azureSubscriptionKey, accessToken) => {

    let headers = {}

    if (accessToken) {
        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        };
    }
    
    if (azureSubscriptionKey) {
        headers = {
            ...headers,
            'Ocp-Apim-Subscription-Key': azureSubscriptionKey
        };
        
    }

    let apiEndpoint = path;

    console.log("payload", payload);
    console.log("headers", headers);
    console.log("azureSubscriptionKey", azureSubscriptionKey);
    

    let result = axios.post(apiEndpoint, payload, {
        headers: headers
    });

    console.log("HttpActualPostMultipart result", result);
    return result
}

const HttpActualPublicGet = async (path, parameters, accessToken, subscriptionKey) => {
    let headers = {};

    if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
    }

    if (subscriptionKey) {
        headers['Ocp-Apim-Subscription-Key'] = subscriptionKey;
    }

    let apiEndpoint = path;
    let queryParams = QueryParamsFormat(parameters);

    if (queryParams) {
        queryParams = "?" + queryParams;
    } else {
        queryParams = '';
    }

    console.log("apiEndpoint", apiEndpoint);
    console.log("parameters", parameters);
    console.log("headers", headers);
    console.log("subscriptionKey", subscriptionKey);
    

    try {
        let result = await axios.get(apiEndpoint + queryParams, { headers });
        console.log("HttpActualPublicGet result", result);
        return result;
    } catch (error) {
        console.error("HttpActualPublicGet error: ", error);
        throw error;
    }
};


const HttpActualPublicPost = (path, parameters, payload, accessToken, subscriptionKey) => {
    
    let token;

    if (accessToken) {
        token = "Bearer " + accessToken;
    }

    let headerParameters = {
        headers: {
            Authorization: token,
            "Ocp-Apim-Subscription-Key": subscriptionKey
        }
    }

    console.log("HttpActualPublicPost headerParameters xxxxx", headerParameters);

    let apiEndpoint = path;

    console.log("apiEndpoint xxxx", apiEndpoint);


    let queryParams = QueryParamsFormat(parameters);
    
    if (queryParams) {
        queryParams =  "?" + queryParams;
    } else {
        queryParams = '';
    }

    let result = axios.post(apiEndpoint+ queryParams, payload, headerParameters);

    console.log("HttpActualPublicGet result xxxx", result);
    
    return result
}


const HttpActualPublicPost2 = (path, payload, azureSubscriptionKey) => {

    let headers = {}

    if (azureSubscriptionKey) {
        headers = {
            ...headers,
            'Ocp-Apim-Subscription-Key': azureSubscriptionKey
        };
        
    }
    
    let apiEndpoint = path;

    console.log("apiEndpoint xxxx", apiEndpoint);
    console.log("headers xxxx", headers);

    let result = axios.post(apiEndpoint, payload, {
        headers: headers
    });

    console.log("HttpActualPublicPost2 result xxxx", result);
    
    return result
}




const HttpActualPublicPostMultipart = (path, payload, accessToken, subscriptionKey) => {

        let token = accessToken;
        if(token)
            token = "Bearer " + token;
    
        let headerParameters = {
            headers: {
                Authorization : token, 
                "Ocp-Apim-Subscription-Key": subscriptionKey,
                'Content-Type': 'multipart/form-data'
            }
        }
        console.log("headerParameters", headerParameters)
        let apiEndpoint = path;
        let result = axios.post(apiEndpoint, payload, headerParameters);
    
        console.log("HttpActualPostMultipart result xxxx", result);
    
        return result
    }

    const HttpActualCustomPublicGet = async (path, parameters, subscriptionKey) => {
        let headers = {};
    
        if (subscriptionKey) {
            headers['Ocp-Apim-Subscription-Key'] = subscriptionKey;
        }
    
        let apiEndpoint = path;
        let queryParams = QueryParamsFormat(parameters);
    
        if (queryParams) {
            queryParams = "?" + queryParams;

        } else {
            queryParams = '';
        }
    
        console.log("apiEndpoint", apiEndpoint);
        console.log("parameters", parameters);
        console.log("headers", headers);
        console.log("subscriptionKey", subscriptionKey);
        
    
        try {
            let result = await axios.get(apiEndpoint + queryParams, {headers});

            console.log("HttpActualCustomPublicGet result", result);
            return result;

        } catch (error) {
            console.error("HttpActualCustomPublicGet error: ", error);
            
            throw error;
        }
    }

    const HttpCustomActualDelete = (path, payload, azureSubscriptionKey, accessToken) => {
        let headers = {}
    
        if (accessToken) {
            headers = {
                ...headers,
                Authorization: `Bearer ${accessToken}`
            };
        }
        
        if (azureSubscriptionKey) {
            headers = {
                ...headers,
                'Ocp-Apim-Subscription-Key': azureSubscriptionKey
            };
        }
    
        let apiEndpoint = path;
        
        console.log("apiEndpoint", apiEndpoint);
        console.log("payload", payload);
        console.log("headers", headers);
        console.log("azureSubscriptionKey", azureSubscriptionKey);
        
        let result = axios.delete(apiEndpoint, {data: payload, headers: headers})
    
        console.log("HttpCustomActualDelete result", result);
        return result
    }

export {HttpActualGet, HttpActualPost, HttpActualPut, HttpActualDelete, HttpActualPostMultipart, HttpActualGetBlob, HttpActualPublicGet, 
    HttpActualPublicPost, HttpActualPublicPostMultipart, HttpActualPostParams, HttpActualDelete1, HttpActualPut1, HttpActualPublicPost2,
    HttpActualCustomPublicGet, HttpCustomActualDelete
}
