import React, {useState} from "react";
import {Menu, MenuItem, IconButton, ListItemIcon, ListItemText} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const CustomMenuPopperComponent = ({menuItems, customIconStyles, customLabelStyles, customPopperIconStyles}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }


  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon sx={{...customPopperIconStyles}} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 300,
          }
        }}
      >
        {menuItems?.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleClose();
              item.onClick();
            }}
          >
            <ListItemIcon sx={customIconStyles}>
              {item.icon}
            </ListItemIcon>

            <ListItemText sx={{...customLabelStyles}} primary={item.label} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default CustomMenuPopperComponent
