import {Button} from "@mui/material";
import {styled} from "@mui/material/styles";

const StyledButton = styled(Button)(({variant}) => ({
    ...(variant === "text" && {
        color: "#604CC3",
        fontSize: 17,
    }),
    ...(variant === "contained" && {
        color: "#fff",
        backgroundColor: "#604CC3",
        fontSize: "16px"
    }),
    
    ...(variant === "outlined" && {
        backgroundColor: "transparent",
        height: 30,
        fontSize: "16px",
        color: "#604CC3",
        borderColor: "#745fe0",
        "&:hover": {
            backgroundColor: "#604cc329",
        }
    }),
    
    textTransform: "none", 
    padding: '8px 16px',
    fontWeight: "400", 
}));

const CustomButton = ({variant, startIcon, label, onClick, customStyles, disabled, ...props }) => {
    return (
        <StyledButton 
            variant={variant} 
            startIcon={startIcon} 
            label={label}
            onClick={onClick} 
            disabled={disabled}
            sx={{
                ...customStyles 
            }} 
            {...props}
        >
            {label}
        </StyledButton>
    );
}

export default CustomButton
