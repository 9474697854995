import {useState, useCallback} from "react";
import {Box, Typography, TextField, Snackbar, Alert} from "@mui/material";
import Grid from "@mui/material/Grid2";
import LandingBanner from "../../../assets/images/sample-landing-banner.png";
import CustomButton from "../ui/CustomButton";
import {getAuth, sendSignInLinkToEmail} from "firebase/auth";

const auth = getAuth();

const BannerComponent = ({breakpoints}) => {
    const [email, setEmail] = useState("");
    const [isValidEmail, setValidEmail] = useState(true);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const validateEmail = (email) => {
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(email);
    }
    
    const handleEmailChange = (e) => {
        const value = e.target.value;

        setErrorMsg(null);
        setEmail(value);

        if (value) {
            setValidEmail(validateEmail(value));

        } else {
            setValidEmail(true);
        }
    }

    const handleStartFreeTrial = useCallback(async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMsg(null);

        const settings = {
            url: getAuthUrl(),
            handleCodeInApp: true,
        }
    
        try {
            await sendSignInLinkToEmail(auth, email, settings);
            
            window.localStorage.setItem("emailForSignIn", email);
            setEmail("");
            setSnackbarOpen(true); 
                
        } catch (err) {
            setErrorMsg(err.message);

        } finally {
            setLoading(false);
        }
    }, [email]);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    }


    return (
        <Box sx={{width: (breakpoints == "xs") ? "90%" : "73%", margin: "auto"}}>
            <Grid container spacing={2}>
                <Grid size={{xs: 12, sm: 5, md: 5}} sx={{textAlign: "start", mt: 7}}>
                    <Typography variant="h3" sx={{color: "#5b3cc4", fontWeight: 600, 
                        fontSize: (breakpoints === "xl") ? 50 : (breakpoints === "lg") ? 40 : (breakpoints === "md") ? 30 : 30
                    }}>
                        The Simplest Way to 
                    </Typography>

                    <Typography variant="h3" sx={{color: "#5b3cc4", fontWeight: 600, 
                        fontSize: (breakpoints === "xl") ? 50 : (breakpoints === "lg") ? 40 : (breakpoints === "md") ? 30 : 30
                    }}>
                        Sign Documents
                    </Typography>

                    <Typography variant="h5" sx={{color: "#FF6600", fontWeight: 400, 
                        fontSize: (breakpoints === "xl") ? 22 : (breakpoints === "lg") ? 20 : (breakpoints === "md") ? 16 : 16
                    }}>
                        Fast, Secure, and Legally Binding
                    </Typography>

                    <Typography variant="subtitle1" sx={{color: "#424546", fontWeight: 400, mt: "66px",
                        fontSize: (breakpoints === "xl") ? 18 : (breakpoints === "lg") ? 16 : {}
                    }}>
                        Get started today with 100 free tokens.
                    </Typography>

                    <Typography variant="subtitle1" sx={{color: "#424546", fontWeight: 400, 
                        fontSize: (breakpoints === "xl") ? 18 : (breakpoints === "lg") ? 16 : {}
                    }}>
                        No credit card required.
                    </Typography>

                    <Box sx={{display: "flex"}}>
                        <TextField
                            variant="outlined"
                            placeholder="Email Address"
                            value={email}
                            onChange={(e) => handleEmailChange(e)}
                            sx={{mt: 2, backgroundColor: "#fff", borderRadius: "2px", color: "red", 
                                width: (breakpoints === "xl" || breakpoints === "lg") ?"65%" : (breakpoints == "md") ? "50%" : "50%",
                                "& fieldset": {
                                    borderColor: "#604CC366",
                                }
                            }}
                            slotProps={{
                                formHelperText: {
                                    sx: {backgroundColor: "#ede9fe !important", mr: "0px !important", ml: "0px !important", mt: "0px !important"}
                                }
                            }}
                            helperText={
                                <p style={{fontSize: 13, color: "red", margin: "2px 0px 0px 2px"}}>
                                    {(!isValidEmail && !errorMsg) ? "Email Address is invalid" : (errorMsg) ? errorMsg : ""}
                                </p>
                            }
                        /> &nbsp;&nbsp;

                        <CustomButton 
                            variant="contained" 
                            label="Start Free Trial"
                            customStyles={{
                                height: "55px",
                                marginTop: "16px",
                                fontWeight: 500,
                                backgroundColor: "#5b3cc4",
                                fontSize: (breakpoints === "xl") ? 16 : 14,
                                opacity: (isValidEmail && email && !isLoading) ? "100%" : "55%",
                                cursor: (isValidEmail && email && !isLoading) ? "pointer" : "not-allowed",
                            }}
                            onClick={(isValidEmail && email && !isLoading) ? handleStartFreeTrial : null}
                        />   
                    </Box>
                </Grid>

                <Grid size={{xs: 12, sm: 7, md: 7}}>
                    <img alt="" src={LandingBanner} style={{
                        height: (breakpoints == "xl" || breakpoints == "lg") ? 433 : (breakpoints == "md") ? 350 : 350,
                        width: (breakpoints == "xl" || breakpoints == "lg") ? 617 : (breakpoints == "md") ? 480 : 480,
                        objectFit: "contain", marginTop: "16px",
                        display: (breakpoints === "xs") ? "none" : {}}}
                    />
                </Grid>
            </Grid>

            <Snackbar open={snackbarOpen} autoHideDuration={3500} onClose={handleSnackbarClose}>
                <Alert variant="filled" severity="success" onClose={handleSnackbarClose} sx={{width: "100%"}}>
                    A sign-in email has been sent. Please check your inbox to complete the sign-in process.
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default BannerComponent

function getAuthUrl() {
    const origin = window.location.origin;
    const path = "/auth/link";

    return [origin, path].join("");
}