
import {IconButton, TextField, Typography} from "@mui/material";

export default function CustomTextfieldComponent ({value, onChange, onKeyDown, type, placeholder, ...props}) {

    return (
        <TextField
            placeholder={placeholder}
            variant="outlined"
            margin="normal"
            type={type}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            {...props}
        />
    )
}