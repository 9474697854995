import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DocumentPDFSigneeScreen from './DocumentPDFSigneeScreen';
import TokenExpiredDialog from '../dialogs/token-expired-dialog.component';
import DocumentPublicService from '../services/document-public-service';
import DocumentPublicUserAccountService from '../services/document-public-user-account-service';
import { useSnackbar } from 'notistack';
import FinishNotificationDialog from '../dialogs/finish-notification.dialog';
import DocumentCancelledDialog from '../dialogs/document-cancelled-dialog.component';
import useFillableElementsStore from "../zustand/useFillableElementsStore";
import useDocumentPublicAccount from '../hooks/useDocumentPublicAccount';
import { elementTypes } from '../common/staticVariables';

export default function DocumentSigneeScreen() {
  const navigate = useNavigate();
  const enqueueSnackbar = useSnackbar();

  const accessToken = (new URLSearchParams(window.location.search)).get("token");

  const { getSenderEmail, sender_email } = useDocumentPublicAccount();

  const [fileUrl, setFileUrl] = useState(null);
  const [alertVisible, setAlertVisible] = useState(true);
  const [backdropOpen, setBackdropOpen] = useState(true);
  const [isSigning, setIsSigning] = useState(false);

  const [signatories, setSignatories] = useState([]);
  const [elements, setElements] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserSignature, setCurrentUserSignature] = useState(null);
  const [documentId, setDocumentId] = useState(null);
  const [sender, setSender] = useState(null);
  const [senderOID, setSenderOID] = useState(null)
  const [signeeCCEmail, setSigneeCCEmail] = useState(null);
  const [document_status, setDocumentStatus] = useState(null);
  const [cancel_reason, setReason] = useState("");
  const [fillable_textfield, setFillableTextfield] = useState('');
  const [fillable_date, setFillableDate] = useState('');
  const [document_title, setDocumentTitle] = useState('');

  const [hasContinued, setHasContinued] = useState(localStorage.getItem('hasContinued') === 'true');

  const [isTokenExpired, setOpenTokenExpiredDialog] = useState(false);
  const [openFinishDialog, setOpenFinishDialog] = useState(false);
  const [openCancelledDialog, setOpenCancelledDialog] = useState(false);
  const [isSigned, setSigned] = useState(false);
  const [isDeclined, setDeclined] = useState(false);
  const setFillableElements = useFillableElementsStore((state) => state.setFillableElements)
  
  
  const handleContinue = () => {
    setAlertVisible(false);
    setBackdropOpen(false);
    setIsSigning(true);
    localStorage.setItem('hasContinued', 'true');
    setHasContinued(true);
  };

  const subKey = "&subscription-key=03bae5e150c3439aae644a0618d550f3";

  useEffect(() => {
    const newHasContinued = localStorage.getItem('hasContinued') === 'true';
    setHasContinued(newHasContinued);

    if (newHasContinued) {
      setAlertVisible(false);
      setBackdropOpen(false);
    } else {
      setAlertVisible(true);
      setBackdropOpen(true);
    }
  }, [accessToken]);

  useEffect(() => {
    DocumentPublicService.getDocumentWithProperties(accessToken).then(({ data }) => {
      console.log("getDocumentWithProperties public data xxxx", data);

      if (data.success) {
        setDocumentTitle(data?.data.document.title);
        setSigneeCCEmail(data?.data?.cc_emails);
        console.log("res elements aa", data.data.elements);
        setSenderOID(data.data.document.oid)
        let file_url = data.data.document_url + subKey;
        console.log("res file_url aa", file_url);
        let signatories = data.data.signatories
        console.log("res signatories aa", signatories);
        let current_user = data.data.user
        console.log("res current_user aa", current_user);
        let document_id = data.data.document.id
        console.log("res document_id aa", document_id);

        let elements = data.data.elements.filter((item) => item.type != "signatory" && item.type != "fillable_textfield" && item.type != "fillable_date").map((el) => {

          console.log("res el aa", el);

          return {
            "id": el.id,
            "type": el.type,
            "value": el.value,
            "x": parseFloat(el.x_axis),
            "y": parseFloat(el.y_axis),
            "font_size": el.font_size,
            "page_no": el.page_no,
            "width": parseFloat(el.width),
            "height": parseFloat(el.height),
          }
        })

        console.log("res elements xx", elements);

        let fillable_elements = data.data.elements.filter((item) => (item.type == "signatory" || item.type == "fillable_textfield" || item.type == "fillable_date") && item.value == current_user).map((el) => {

          console.log("res el aa", el);

          let signee = signatories.find((item) => item.email_address == el.value)

          console.log("res signee aa", signee);

          let signature = signee?.status == "DONE" ? signee?.signature_url : null

          console.log("res signature aa", signature);

          return {
            "id": el.id,
            "type": el.type,
            "value": el.value,
            "x": parseFloat(el.x_axis),
            "y": parseFloat(el.y_axis),
            "font_size": parseFloat(el.font_size),
            "page_no": el.page_no,
            "date_format": el.date_format,
            "width": parseFloat(el.width),
            "height": parseFloat(el.height),
            "signature": signature,
            "el_value": null,
            "isSigned": !!signature,
            "disabled": !!signature
          }
        })

        console.log("res fillable_elements xx", fillable_elements);
        setFillableElements(fillable_elements)

        console.log("res public file_url aa", file_url);
        console.log("res public signatories aa", signatories);
        console.log("res public elements aa", [...elements, ...fillable_elements]);
        console.log("res public current_user aa", current_user);

        setFileUrl(file_url)
        setSignatories(signatories)
        setElements([...elements, ...fillable_elements])
        setCurrentUser(current_user)
        setDocumentId(document_id)
      } else {
        console.log("data xaa", data);
        enqueueSnackbar(data.error, {
          variant: "error",
          autoHideDuration: 3000
        });
      }
    }).catch((e) => {


      setDocumentStatus(e?.response?.data?.data?.status);

      let tokenExpired = "Token is expired.";


      console.log("getDocumentWithProperties error xxxx", e?.response?.data);
      console.log("getDocumentWithProperties xxxx", e?.response?.data?.data);

      if (e?.response.data.error === "Error occurred. You have already finished signing." || e?.response.data.error === "Document status is not ONGOING.") {
        setOpenFinishDialog(true);
        setSigned(true);
      } else if (e?.response?.data == tokenExpired) {
        setOpenTokenExpiredDialog(true);
      } else if (e?.response?.data?.data?.status === "CANCELLED" || e?.response?.data?.data?.status === "DENIED") {
        setOpenCancelledDialog(true);
        setReason(e?.response?.data?.data?.cancel_reason);
        setDeclined(true);
      } else if (e?.response?.data?.error?.includes("JWT expired")) {
        setOpenTokenExpiredDialog(true);
      } 

      // if (e?.response?.data?.data?.status === "COMPLETED") {
      //   setOpenFinishDialog(true);
      // } else if (e?.response?.data?.data?.status == tokenExpired) {
      //   setOpenTokenExpiredDialog(true);
      // } else if (e?.response?.data?.data?.status === "CANCELLED" || e?.response?.data?.data?.status === "DENIED") {
      //   setOpenCancelledDialog(true);
      //   setReason(e?.response?.data?.data?.cancel_reason);
      // }


      if (e.response?.data.error == tokenExpired) {
        enqueueSnackbar(tokenExpired, {
          variant: "error",
          autoHideDuration: 3000
        });
      }


    })
  }, [])

  console.log("signeeCCEmail here", signeeCCEmail)

  useEffect(()=>{

    let parameters = {}

      if(senderOID){

        parameters.userId = senderOID
        getSenderEmail(parameters, accessToken);

      }
  },[JSON.stringify(senderOID)])

  useEffect(() => {
    if (currentUser) {
      DocumentPublicUserAccountService.getPublicSignature(accessToken).then(({ data }) => {
        console.log("signature data here", data)
        let signature = data?.data?.signature
        console.log("signature xxxxx1", signature)
        if (signature) {
          setCurrentUserSignature(`${signature}?t=${Date.now()}`);
        }
      });
    }
  }, [currentUser]);






  const handleOnFinishSignee = async (pdf_result) => {
    console.log("signee finished!", pdf_result);
    console.log("currentUser finished!", currentUser);
    console.log("fillable_textfield finished!", fillable_textfield);
    console.log("fillable_date finished!", fillable_date);


    localStorage.removeItem('hasContinued');

    let signatoryEmail = currentUser.email;

    // Adjust this if currentUser is an object
    let signatureElement = pdf_result.elements.find((el) =>
      el.value && el.value.trim().toLowerCase() === currentUser.trim().toLowerCase()
    ); // Assuming currentUser has an email property
    const signature_url = signatureElement?.signature; // Use optional chaining to avoid errors if not found
    let matchingElements = pdf_result.elements.filter((el) => el.value === currentUser.email)


    console.log('get signature url hereeeeeeeeeeeeee', signatureElement)
    console.log('signature_url xxx', signature_url)
    console.log("EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE", matchingElements);

    if (!signature_url) {
      enqueueSnackbar("Please sign to proceed", {
        variant: "warning",
        autoHideDuration: 3000
      });
      return; 
    }

    try {

      const fillable_elements = pdf_result.elements.filter((el) => el.type == "fillable_textfield" || el.type == "fillable_date")
      console.log("currentUser finished!", currentUser);
      console.log("documentId finished!", documentId);
      console.log("signature_url finished!", signature_url);
      console.log("fillable_elements finished!", fillable_elements);

      const { data } = await DocumentPublicService.signDocument(currentUser, documentId, signature_url, fillable_elements, accessToken);
      console.log("signDocument data response xxxx", data);
      if (data?.success) {
        setOpenFinishDialog(true);
      } else {
        console.error("signDocument error response:", data?.error || "Unknown error");
        enqueueSnackbar(data?.error || "Please try again.", {
          variant: "error",
          autoHideDuration: 3000
        });
      }
    } catch (e) {
      console.log("signDocument catch error response xxxx", e.response?.data?.error || "Unknown error");
      enqueueSnackbar(e.response?.data?.error || "An error occurred.", {
        variant: "error",
        autoHideDuration: 3000
      });
    }
  };


  const handleCloseFinishDialog = () => { setOpenFinishDialog(false); }
  const handleCloseCancelledDialog = () => { setOpenCancelledDialog(false); }




  console.log("currentUserSignaturehere", currentUserSignature)
  console.log("currentUserhere", currentUserSignature)

  return (
    <>
      {!isTokenExpired &&
        <DocumentPDFSigneeScreen
          file_url={fileUrl}
          elements_to_plot={elements}
          signatory={signatories}
          hasContinued={hasContinued}
          backdropOpen={backdropOpen}
          alertVisible={alertVisible}
          handleContinue={handleContinue}
          isSigning={isSigning}
          currentUser={currentUser}
          currentUserSignature={currentUserSignature}
          setCurrentUserSignature={setCurrentUserSignature}
          onClickFinish={handleOnFinishSignee}
          setOpenCancelledDialog={setOpenCancelledDialog}
          document_id={documentId}
          setFillableDate={setFillableDate}
          setFillableTextfield={setFillableTextfield}
          document_title={document_title}
          sender={sender_email}
          cc_emails={signeeCCEmail}
        />
      }

      {isTokenExpired &&
        <TokenExpiredDialog
          isTokenExpired={isTokenExpired}
        />
      }

      {openCancelledDialog &&
        <DocumentCancelledDialog
          documentStatus={document_status}
          cancel_reason={cancel_reason}
          openCancelledDialog={openCancelledDialog}
          handleCloseCancelledDialog={handleCloseCancelledDialog}
        />
      }

      {openFinishDialog &&
        <FinishNotificationDialog
          openFinishDialog={openFinishDialog}
          handleCloseFinishDialog={handleCloseFinishDialog}
          currentUser={currentUser}
        />
      }



    </>
  );
}
