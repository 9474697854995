import React, {useEffect} from "react";
import CustomDialog from "../../../../core/components/ui/CustomDialog";
import {Box, Avatar} from "@mui/material";
import {stringToAvatarEmail} from "../../../../core/utils/avatarImageUtil";
import CustomTypography from "../../../../core/components/ui/CustomTypography";
import useMembersManagement from "../../hooks/useMembersManagement";
import WorkspaceViewMemberSkeleton from "../skeletons/workspace-view-member.skeleton";

const WorkspaceViewMemberDialog = (props) => {
    const {open, setOpen, selectedMember} = props;
  
    const {memberDetails, isFetchingMemberDetails, getViewMemberDetails} = useMembersManagement();


    useEffect(() => {
        if (selectedMember) {
            getViewMemberDetails(selectedMember?.user_id, selectedMember?.workspace_id);
        }
    }, [selectedMember])

    
    const handleClose = () => {
        setOpen(false);
    }

    const globalSx = {
       content: {
        border: "none",
       } 
    }


    return (
        <>
            <CustomDialog
                open={open}
                onClose={handleClose}
                title={"Profile"}
                content={
                    <Box>
                        {(isFetchingMemberDetails) ?
                            <WorkspaceViewMemberSkeleton />
                        :
                            <Box sx={{padding: "0px 40px 40px"}}>
                                <Box sx={{display: "flex", textAlign: "center"}}>
                                    <Avatar
                                        src={memberDetails?.photo || null}
                                        {...(memberDetails?.photo ? {} : (memberDetails?.email) ? stringToAvatarEmail(memberDetails?.email) : {})} 
                                        style={{
                                            cursor: "default",
                                            width: 110,
                                            height: 110,
                                            backgroundColor: "#fff",
                                            color: '#604CC3',
                                            fontSize: 50,
                                            border: "5px solid #604CC3",
                                            fontWeight: 'bold'
                                        }}
                                        slotProps={{img: {style: {objectFit: "contain"}}}}
                                    />

                                    <Box sx={{display: "block", textAlign: "start", mt: "30px", ml: "18px"}}>
                                        <CustomTypography
                                            variant="subtitle1"
                                            text={memberDetails?.email}
                                            customStyles={{marginBottom: 0, fontWeight: "bold", color: "#58565F", fontSize: 20}}
                                        />

                                        <CustomTypography
                                            variant="subtitle1"
                                            text={memberDetails?.walletId}
                                            customStyles={{ marginBottom: 0, fontWeight: 400, color: "#58565fe6", fontSize: 16}}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        }
                    </Box>
                }
                actions={null}
                maxWidth={"sm"}
                globalSx={globalSx}
            />  
        </>
    )
}

export default WorkspaceViewMemberDialog