import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import DocumentEditorPageComponent from "./document-editor-page.component";
import { getWidthByPercentage } from "../../utils/ProportionDimenUtil";
import samplePdfContent from "../../../../../assets/samplePdf.pdf"
import useElements from "../../zustand/useElements"
import CreateEsignatureCommon from "../../../../../core/components/common/digital-signature/create-esignature-common";
import useProfileStore from "../../../../profile-plugin/zustand/useUpdateProfileStore";
import { elementTypes } from "../../common/staticVariables";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function DocumentEditorBoxComponent(props) {

  const {documentById, decryptedFile} = props;

  const [numPages, setNumPages] = useState(null);

  const pdfPages = useElements((state) => state.pdfPages);
  const addPDFPage = useElements((state) => state.addPDFPage);
  const elements = useElements((state) => state.elements);
  const removeElement = useElements((state) => state.removeElement);
  const profileESignature = useProfileStore((state) => state.profileESignature);
  
  const [openEsignatureDialog, setOpenEsignatureDialog] = useState(false)
  console.log('pdfPages BBBBBB', pdfPages)

  const pdfEditorRef = useRef()

  function handleOnLoadDocumentSuccess(pdf) {    
    setNumPages(pdf.numPages); // Set the total number of pages
    handleSetPDFPages(pdf)
  }

  const handleSetPDFPages = (pdf) => {
    console.log("pdf 1111", pdfPages);

    [...Array(pdf.numPages)].map((item, i) => {
        let pageNum = i + 1
        pdf.getPage(pageNum).then((page) => {

            let pdfEditorWrapperWidth = pdfEditorRef?.current?.clientWidth

            let percentage = 80
            let percentageWidth = getWidthByPercentage(percentage, pdfEditorWrapperWidth)

            let rotate = page.rotate
            let pageNo = page?.pageNumber
            let obj = {
                numPages: pdf.numPages,
                adjustedPDFWidth: percentageWidth,
                pageNo,
                rotate,
            }
            addPDFPage(obj)
        })
    })
}

const handleCloseEsignatureDialog = () => {
  if (!profileESignature) {
    let element = elements.find((item) => item.type == elementTypes.userSignature)
    removeElement(element.id)
  }
  setOpenEsignatureDialog(false)
}

  return (
    <Box ref={pdfEditorRef} display="flex" sx={{bgcolor: '#EEEBF8', height:'100%'}} justifyContent="center" alignItems="center">
      <Document
        file={decryptedFile} 
        loading="Loading template..."
        onLoadSuccess={handleOnLoadDocumentSuccess}
      >
        <Stack direction="column" justifyContent="space-evenly" alignItems="center">
          {pdfPages.map((page, i) => {
            return(
                <>
                  <DocumentEditorPageComponent page={page} documentById={documentById} index={i} setOpenEsignatureDialog={setOpenEsignatureDialog} handleCloseEsignatureDialog={handleCloseEsignatureDialog} />
                </>
            )
          })}
        </Stack>
      </Document>

      <CreateEsignatureCommon openEsignatureDialog={openEsignatureDialog} handleCloseEsignatureDialog={handleCloseEsignatureDialog} />
    </Box>
  );
}
