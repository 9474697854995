import { getReverseProportionDimens, getReverseProportionCoords, getProportionalCoords, getProportionalDimens, getProportionalFontSize, getReverseProportionFontSize } from "../utils/ProportionDimenUtil";

export const useElementHook = () => {

    const getProportionalElementCoords = (propX, propY, dropRefCurrentWidth, dropRefCurrentHeight, rotation, pageOriginalWidth, pageOriginalHeight) => {
        let isLandscape = rotation == 90 || rotation == 270 ? true : false
    
        let pageWidth = isLandscape ? pageOriginalHeight : pageOriginalWidth
        let pageHeight = isLandscape ? pageOriginalWidth : pageOriginalHeight
        
        let {x,y} = getProportionalCoords(dropRefCurrentWidth, dropRefCurrentHeight, pageWidth, pageHeight, propX, propY)
        return {x,y}
    }
    
    const getProportionalElementDimens = (width, height, dropRefCurrentWidth, dropRefCurrentHeight, rotation, pageOriginalWidth, pageOriginalHeight) => {
        let isLandscape = rotation == 90 || rotation == 270 ? true : false
    
        let pageWidth = isLandscape ? pageOriginalHeight : pageOriginalWidth
        let pageHeight = isLandscape ? pageOriginalWidth : pageOriginalHeight
    
        let test = getProportionalDimens(dropRefCurrentWidth, dropRefCurrentHeight, pageWidth, pageHeight, 290, 56)
    
        return getProportionalDimens(dropRefCurrentWidth, dropRefCurrentHeight, pageWidth, pageHeight, width, height)
    }
    
    const getReverseProportionElementCoords = (propX, propY, dropRefCurrentWidth, dropRefCurrentHeight, rotation, pageOriginalWidth, pageOriginalHeight, isOriginalPageDimensRotated = true) => {
        let isLandscape = rotation == 90 || rotation == 270 ? true : false
    
        let pageWidth = isOriginalPageDimensRotated && isLandscape ? pageOriginalHeight : pageOriginalWidth
        let pageHeight = isOriginalPageDimensRotated && isLandscape ? pageOriginalWidth : pageOriginalHeight
        
        let {x,y} = getReverseProportionCoords(dropRefCurrentWidth, dropRefCurrentHeight, pageWidth, pageHeight, propX, propY)
        
        return {x,y}
    }
    
    const getReverseProportionElementDimens = (rotation, dropRefCurrentWidth, dropRefCurrentHeight, pageOrigWidth, pageOrigHeight, width, height, isOriginalPageDimensRotated = true) => {
        let isLandscape = rotation == 90 || rotation == 270 ? true : false
    
        let pageWidth = isOriginalPageDimensRotated && isLandscape ? pageOrigHeight : pageOrigWidth
        let pageHeight = isOriginalPageDimensRotated && isLandscape ? pageOrigWidth : pageOrigHeight
    
        let {w, h} = getReverseProportionDimens(dropRefCurrentWidth, dropRefCurrentHeight, pageWidth, pageHeight, width, height)
        return {w,h}
    }
    
    const getProportionalElementFontSize = (rotation, dropRefCurrentWidth, pageOrigWidth, pageOrigHeight, font_size) => {
        let isLandscape = rotation == 90 || rotation == 270 ? true : false
        let pageWidth = isLandscape ? pageOrigHeight : pageOrigWidth
    
        return getProportionalFontSize(dropRefCurrentWidth, pageWidth, font_size)
    }
    
    const getReverseProportionalElementFontSize = (rotation, dropRefCurrentWidth, pageOrigWidth, pageOrigHeight, font_size, isOriginalPageDimensRotated = true) => {
        let isLandscape = rotation == 90 || rotation == 270 ? true : false
        let pageWidth = isOriginalPageDimensRotated && isLandscape ? pageOrigHeight : pageOrigWidth
    
        return getReverseProportionFontSize(dropRefCurrentWidth, pageWidth, font_size)
    }
    
    return {getProportionalElementCoords, getProportionalElementDimens, getReverseProportionElementCoords, getReverseProportionElementDimens, 
        getProportionalElementFontSize, getReverseProportionalElementFontSize}
}