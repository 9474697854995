const DOCUMENT_API_ENDPOINT =  process.env.REACT_APP_DOCUMENT_SIGNING_API_ENDPOINT;

export default {
   
    // DOCUMENT MANAGEMENT
    CREATE_DOCUMENT:                                                 DOCUMENT_API_ENDPOINT + "/document/create",
    GET_DOCUMENT_BY_ID:                                              DOCUMENT_API_ENDPOINT + "/document",
    GET_DOCUMENT_LIST:                                               DOCUMENT_API_ENDPOINT + "/document/list",
    VIEW_DOCUMENT:                                                   DOCUMENT_API_ENDPOINT + "/document/view",
    GET_DOCUMENT_HISTORY:                                            DOCUMENT_API_ENDPOINT + "/document/history",
    DOCUMENT_FILTER_STATUS:                                          DOCUMENT_API_ENDPOINT + "/document/search",
    DOCUMENT_RESEND :                                                DOCUMENT_API_ENDPOINT + "/document/resend",
    CANCEL_DOCUMENT :                                                DOCUMENT_API_ENDPOINT + "/document/cancel",
    DOCUMENT_TRANSACTION_PRICE:                                      DOCUMENT_API_ENDPOINT + "/document/calculate/transaction-price",
    REQUIRED_ACTION_LIST:                                            DOCUMENT_API_ENDPOINT + "/document/required/action/list",
}