import { Box, IconButton, Pagination, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useSearchDocumentStore from "../../zustand/useSearchDocumentStore";
import useDocument from "../../hooks/useDocument";
import CustomStatusLabel from "../../../../core/components/common/custom-status-label.component";
import { format } from "date-fns";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RequiredListSkeleton from "../skeleton/required-list-skeleton";

const headers = [
    { label: "Title" },
    // { label: "Link" },
    { label: "Status" },
    { label: "Last Date Modified" },
    { label: "Actions" }
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
[`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: theme.palette.common.black,
    borderRadius: 5,
    borderBottom: 'none',
},
[`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: 'none',
},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
backgroundColor: 'white',
'&:last-child td, &:last-child th': {
    border: 0,
},
}));

export default function RequiredActionListTableComponent (props) {

    const {isWorkspace, menuSelected} = props;

    // isWorkspace={isWorkspace} menuSelected={menuSelected} 

    const {getRequiredActionList, requiredActionList, isLoadingList} = useDocument()

    const [page, setPage] = useState(1);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    useEffect(()=>{
        let workspace_id = isWorkspace ? menuSelected : null
        getRequiredActionList(workspace_id, page);
    },[isWorkspace, menuSelected])

    console.log('get data', requiredActionList)

    return(
        <Box>
            <Typography sx={{textAlign:'start', fontSize: 20, fontWeight:'bold', mb: 2, }} >
                Required Action
            </Typography>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ bgcolor: 'white' }}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ borderBottom: 'none' }}>
                                {headers.map((row) => (
                                    <StyledTableCell key={row.label}>{row.label}</StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {isLoadingList ?
                            <RequiredListSkeleton/>
                        :
                            <TableBody>
                                {requiredActionList?.map((row) => {

                                    return (
                                    <StyledTableRow>
                                        <StyledTableCell
                                        sx={{ color: '#978AD8', cursor: 'pointer' }} onClick={() => window.open(row.document_required_link, '_blank')}
                                        >
                                        {row.title}
                                        </StyledTableCell>
                                        
                                        {/* <StyledTableCell sx={{cursor:'pointer', width: 700}} onClick={() => window.open(row.document_required_link, '_blank')}>
                                            <Typography sx={{color:'#978AD8', textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", width: 650, }}>
                                                {row.document_required_link}
                                            </Typography>
                                        </StyledTableCell> */}
                                        <StyledTableCell>
                                            <CustomStatusLabel label={row.status} />
                                        </StyledTableCell>
                                        <StyledTableCell>{format(new Date(row.updated_time), 'PPpp')}</StyledTableCell>
                                        <StyledTableCell>
                                            <IconButton disabled>
                                                <MoreVertIcon/>
                                            </IconButton>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        }
                        

                    </Table>
                </TableContainer>

                {requiredActionList?.length=== 0 && !isLoadingList &&  <Typography sx={{textAlign:'center', fontSize: 12, color:'gray', p: 5}}>No required action data.</Typography>}
                <Box display="flex" justifyContent="center" mt={2} mb={2}>
                    <Pagination
                        // count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        sx={{
                            "& .MuiPaginationItem-root.Mui-selected": {
                                color: "#fff",
                                backgroundColor: "#5959D8"
                            }
                        }} 
                    />
                </Box>
            </Paper>
        </Box>
        
    )
}