import { create } from "zustand";

// Store for handling filename
const useFilename = create((set) => ({
  filename: "",
  file: null,
  fileSize: null,
  setFilename: (newFilename) => set({ filename: newFilename }),
  setFile: (newFile) => set({ file: newFile }),
  setFileSize: (fileSize) => set({ fileSize }),
  resetFilename: () => set({ filename: "" }),
  resetFile: () => set({ file: null, fileSize: null, filename: null }),
}));


// Export both stores
export default useFilename;
