import {Box, ListItemText, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import uploadIcon from "../../../assets/icons/upload.png";
import signatoryIcon from "../../../assets/icons/signatory.png";
import signingIcon from "../../../assets/icons/document-signing.png";

const EasyStepsComponent = ({breakpoints}) => {

  return (
    <Box sx={{backgroundColor: "#5b3cc4 !important", position: "relative", overflow: "hidden", 
      padding: (breakpoints == "xs" || breakpoints == "sm") ? "25px 10px 20px" : "80px 80px 20px"
    }}>
      <Box
        sx={{
          height: '60vh',
          backgroundColor: '#5b3cc4',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1,

          '&::after': {
            content: '""',
            width: 0,
            height: 0,
            borderLeft: "50vw solid transparent",
            borderRight: "50vw solid transparent",
            borderTop: (breakpoints == "xs" || breakpoints == "sm") ? "50px solid #ede9fe" : "102px solid #ede9fe",
            position: "absolute",
            top: 0,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: -1,
          }
        }}
      />

      <Grid container direction="column" alignItems="center" justifyContent="center" sx={{height: "100%", position: "relative", zIndex: 2, mt: 5}}>
        <Grid size={{xs: 12}}>
          <Typography sx={{ fontWeight: 'bold', fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 30 : 45, color: '#fff', textAlign: 'center' }}>
            3 Easy Steps
          </Typography>
        </Grid>

        <Grid size={{xs: 12}} sx={{mt: (breakpoints == "xs" || breakpoints == "sm") ? -2 : 5, justifyContent: "center", alignContent: "center"}}>
          <Grid container justifyContent="center" spacing={5}>
            <Grid size={{xs: 4}} textAlign="center">
              <img alt="" src={uploadIcon} style={{width: "25%", height: "60%", objectFit: "contain"}} />

              <ListItemText 
                primary={
                  <Typography sx={{fontSize: 28, color: 'white', textAlign: 'center'}}>
                    STEP 1
                  </Typography>
                } 
                secondary={
                  <Typography sx={{fontSize: 22, color: 'white', textAlign: 'center'}}>
                    Upload Document
                  </Typography>
                } 
              />
            </Grid>

            <Grid size={{xs: 4}} textAlign="center">
              <img alt="" src={signatoryIcon} style={{width: "25%", height: "60%", objectFit: "contain"}} />

              <ListItemText 
                primary={
                  <Typography sx={{fontSize: 28, color: "#fff", textAlign: "center"}}>
                    STEP 2
                  </Typography>
                } 
                secondary={
                  <Typography sx={{fontSize: 22, color: "#fff", textAlign: "center"}}>
                    Add Signatory
                  </Typography>
                } 
              />
            </Grid>

            <Grid size={{xs: 4}} textAlign="center">
              <img alt="" src={signingIcon} style={{width: "25%", height: "60%", objectFit: "contain"}} />

              <ListItemText 
                primary={
                  <Typography sx={{fontSize: 28, color: "#fff", textAlign: "center"}}>
                    STEP 3
                  </Typography>
                } 
                secondary={
                  <Typography sx={{fontSize: 22, color: "#fff", textAlign: "center"}}>
                    Start Document Signing
                  </Typography>
                } 
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EasyStepsComponent