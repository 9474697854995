import routes from "../../common/api-routes";
import {HttpAuthGet, HttpAuthPost, HttpAuthPut, HttpAuthPostMultipart} from "../../../../../core/services/common/HttpRequestBuilder";

const getWorkspaceList = (parameters) => {
    let result = HttpAuthGet(routes.LIST_WORKSPACE, parameters);

    return result;
}

const createWorkspace = (payload) => {
    let result = HttpAuthPost(routes.CREATE_WORKSPACE, payload);

    return result;
}

const viewWorkspace = (parameters) => {
    let result = HttpAuthGet(routes.VIEW_WORKSPACE, parameters);

    return result;
}

const editWorkspace = (payload) => {
    let parameters = null;
    let result = HttpAuthPut(routes.EDIT_WORKSPACE, parameters, payload);

    return result;
}

const uploadWorkspaceLogo = (formData) => {
    let result = HttpAuthPostMultipart(routes.UPLOAD_WORKSPACE_LOGO, formData);
    
    return result;
}

const getWorkspaceRoles = () => {
    let parameters = null;
    let result = HttpAuthGet(routes.GET_WORKSPACE_ROLES, parameters);

    return result;
}

const getWorkspaceRBACPermissions = (parameters) => {
    let result = HttpAuthGet(routes.GET_WORKSPACE_RBAC_PERMISSIONS, parameters);

    return result;
}

const getUserWorkspaceRole = (parameters) => {
    let result = HttpAuthGet(routes.GET_WORKSPACE_ROLE, parameters);

    return result;
}

const getOwnAndSharedWorkspaceList = (parameters) => {
    let result = HttpAuthGet(routes.GET_OWN_AND_SHARED_WORKSPACE, parameters);

    return result;
}



const WorkspaceManagementService = {
    getWorkspaceList: getWorkspaceList,
    createWorkspace: createWorkspace,
    viewWorkspace: viewWorkspace,
    editWorkspace: editWorkspace,
    uploadWorkspaceLogo: uploadWorkspaceLogo,
    getWorkspaceRoles: getWorkspaceRoles,
    getWorkspaceRBACPermissions: getWorkspaceRBACPermissions,
    getUserWorkspaceRole: getUserWorkspaceRole,
    getOwnAndSharedWorkspaceList: getOwnAndSharedWorkspaceList
}

export default WorkspaceManagementService