const AUTHENTICATION_API_ENDPOINT =  process.env.REACT_APP_AUTHENTICATION_API_ENDPOINT;

export default {
   
    // AUTHENTICATION
    GET_PROFILE:                                                 AUTHENTICATION_API_ENDPOINT + "/api/v1/user_accounts",
    UPLOAD_PROFILE_PHOTO:                                        AUTHENTICATION_API_ENDPOINT + "/api/v1/user_accounts/upload/photo",
    UPLOAD_SIGNATURE:                                            AUTHENTICATION_API_ENDPOINT + "/api/v1/user_accounts/upload/signature",
    IS_SIGNATURE_SETUP:                                          AUTHENTICATION_API_ENDPOINT + "/api/v1/user_accounts/update/is-signature-setup",

    //TRANSFER TOKEN
    TRANSFER_TOKEN:                                              AUTHENTICATION_API_ENDPOINT + "/api/v1/token/transfer/workspace"


 
}