import { Edit } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Card, CardContent, ClickAwayListener, Fade, FormControl, IconButton, InputLabel, MenuItem, 
    OutlinedInput, Popper, Select, TextField, Tooltip, Typography, InputBase, Autocomplete, List, ListItem, ListItemButton  } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { useEffect, useState, useRef } from "react";
import DocumentEditorService from "../../services/document-editor-service";
import useElements from "../../zustand/useElements";
import OpenWithIcon from '@mui/icons-material/OpenWith';
import CreateEsignatureCommon from "../../../../../core/components/common/digital-signature/create-esignature-common";
import useDigitalSignature from "../../zustand/useDigitalSignature";
import useSignatoryStore from "../../zustand/useSignatoryStore";
import MembersManagementService from "../../../../workspace-plugin/services/api/members-management";
import { elementTypes, fillable_elementTypes } from "../../common/staticVariables";
import { calculateLines } from "../../utils/TextFieldUtil";
import useSignatoryElementStore from "../../zustand/useSignatoryElementStore";


function SignatoryField ({element, handleOpenSignatory, handleOnIsEditing}) {

    const ITEM_HEIGHT = 38;
    const ITEM_PADDING_TOP = 1;

    const MenuProps = {
    PaperProps: {
        sx: {
            maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
            bgcolor:'gray',
            '&:hover': {
                bgcolor: 'gray', 
            },
        },
    },};

    const [names, setNames] = useState(null)
    const [selectedSignatory, setSelectedSignatory] = useState('')
    const updateElement = useElements((state) => state.updateElement)
    const elements = useElements((state) => state.elements)
    const signatory = useSignatoryStore((state) => state.signatory)
    const addSignatory = useSignatoryStore((state) => state.addSignatory)
    const updateSignatory = useSignatoryStore((state) => state.updateSignatory)
    const [isEmail, setIsEmail] = useState(JSON.parse(localStorage.getItem('isEmail')));
    const setHasPDFChanges = useElements((state) => state.setHasPDFChanges);
    const [isWorkspace, setIsWorkspace] = useState(JSON.parse(localStorage.getItem('isWorkspace')));
    const [menuSelected, setMenuSelected] = useState(localStorage.getItem('menuSelected'));
    const [searchQuery, setSearchQuery] = useState("");
    const { signatoryEmailValue, setSignatoryEmailValue } = useSignatoryElementStore();

    const workspaceUser = isWorkspace && menuSelected ;

    

    const handleSearchChange = (event) => {
      setSearchQuery(event.target.value);
    };

    useEffect(() => {
        const syncLocalStorage = () => {
            const storedIsEmail = JSON.parse(localStorage.getItem('isEmail'));
            const storedIsWorkspace = JSON.parse(localStorage.getItem('isWorkspace'));
            const storedMenuSelected = localStorage.getItem('menuSelected');
            
            if (storedIsEmail !== isEmail) setIsEmail(storedIsEmail);
            if (storedIsWorkspace !== isWorkspace) setIsWorkspace(storedIsWorkspace);
            if (storedMenuSelected !== menuSelected) setMenuSelected(storedMenuSelected);
        };

        const intervalId = setInterval(syncLocalStorage, 500);

        return () => clearInterval(intervalId);
    }, [isEmail, isWorkspace, menuSelected]);

    const handleChange = (e) => {
        setSelectedSignatory(e.target.value)
        console.log("e.target.value aaaaaxx", e.target.value);

        if (element.type === elementTypes.signatory) {
            setHasPDFChanges(true)

            const old_email = element.value;
            const new_email = e.target.value;

            console.log("elements bxxx", elements);
            let remainingFillableElements = elements.filter((el) => el.id != element.id && el.value == old_email)
            let remainingSignatories= remainingFillableElements.filter((el) => el.type == elementTypes.signatory && el.value == old_email)
            console.log("remainingSignatories bxxx", remainingSignatories);

            if (remainingSignatories.length == 0) {
              let updated_filable_elements = remainingFillableElements.filter((el) =>
                el.type != elementTypes.signatory && el.id != element.id && old_email == el.value
              );

              console.log("updated_filable_elements bxxx", updated_filable_elements);

              if(updated_filable_elements.length > 0){
                updated_filable_elements.forEach((item) => {
                  item.value = new_email;
                  updateElement(item);
                });
              }
            }

            let hasExistingSignatory = signatory.some((sig) => sig === old_email)
            console.log("element aaaaaxx", element);
            console.log("elements bbbbbbbxx", elements);
            console.log("signatory aaaaa", signatory);
            console.log("hasExistingSignatory xx", hasExistingSignatory);

            const updatedElement = {
              ...element,
              value: new_email, 
            };
            updateElement(updatedElement); 
            
            if (!hasExistingSignatory) {
              addSignatory(e.target.value)
            }

            // setSignatoryEmailValue(e.target.value)
        }
    }

    useEffect(() => {
          if(selectedSignatory){
            setSignatoryEmailValue(selectedSignatory)
          }
      },[signatoryEmailValue, selectedSignatory])

    const filteredEmails = names?.filter((email) =>
      email.includes(searchQuery.toLowerCase())
    );


    useEffect(() => {
      const fetchData = async () => {
        try {
          if (workspaceUser) {
            const parameters = { workspace_id: menuSelected };
            const res = await MembersManagementService.listMembersEmail(parameters);
            console.log('get list workspace', res.data.data);
            setNames(res?.data?.data);
          } else {
            const res = await DocumentEditorService.signatoryList();
            const emailsArray = res.data.data.recently_added_signees
              .replace(/[\[\]]/g, '') // Remove brackets
              .replace(/,$/, '')     // Remove trailing comma
              .split(', ');          // Split into array
            console.log('get list emailsArray', emailsArray);
            setNames(emailsArray);
          }
        } catch (err) {
          console.error('Error fetching data', err);
        }
      };
    
      fetchData();
    }, []);
    

    console.log('get selected signatory', selectedSignatory)

    return (
      
        <FormControl sx={{width: 200, borderColor: 'white', p: 1, '&:hover': {
        backgroundColor: 'transparent', 
        },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'white', 
              },
              '&:hover fieldset': {
                borderColor: 'white',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white',
              },
            }}}>
            <Select
                fullWidth 
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={element.value || selectedSignatory}
                onChange={(e) => {
                e.stopPropagation();
                handleChange(e);
                }}
                sx={{borderColor:'white !important', height: 30}}
                input={<OutlinedInput />}
                MenuProps={MenuProps} displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                onOpen={()=>handleOnIsEditing(true)}
                onClose={()=>handleOnIsEditing(false)}
            >
              {workspaceUser ?
              // <TextField
              //   label="Search Email"
              //   variant="outlined"
              //   fullWidth
              //   defaultValue={searchQuery}
                
              //   onChange={(e) => {
              //     e.stopPropagation();
              //     handleSearchChange(e)
              //   }}
              //   style={{ marginBottom: "20px" }}
              // />
              <></>
              :
              <Button value="" fullWidth sx={{color:'white',bgcolor:'#604CC3', mb: 1}} onClick={() => handleOpenSignatory(element, 'Add Signatory')}>
                  Add Signee
              </Button>
              }
                
              {filteredEmails?.map((name) => (
                <MenuItem 
                  key={name} 
                  value={name}  sx={{bgcolor:'#604CC3',
                    '&:hover': {
                      backgroundColor: 'transparent', // Remove hover color on menu items
                    },
                    '&.Mui-selected': {
                      backgroundColor: 'transparent', // Remove selected background color
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: 'transparent', // Remove hover effect on selected item
                    },
                  }}
                >
                    <Typography sx={{color:'white', fontSize: 13,}}>
                        {name}
                    </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
    )
}


export default function DocumentEditorPopperComponent (props)  {
    const {handleOpenSignatory, handleDelete, element, isElementClicked, handleOnIsEditing, handleOnMouseEnter, handleDuplicateElements, 
        textareaRef, fillableTextFieldRef, dateRef, fillableDateRef, dateFormat, handleFormatChange} = props;

    const [openEsignatureDialog, setOpenEsignatureDialog] = useState(false)
    const updateElement = useElements((state) => state.updateElement)

    const { signatureUrl } = useDigitalSignature();

    const [fontSize, setFontSize] = useState(element.fontSize || 18); // Default font size
    const [elementFontSize, setElementFontSize] = useState(element.fontSize || 18); // Default font size
    const [openFontSizeDropdown, setOpenFontSizeDropdown] = useState(false); // Popper visibility
    const anchorRef = useRef(null); // Reference for the Popper anchor
    const fontSizes = [12, 14, 16, 18, 20, 24, 36, 48]; // Predefined font sizes

    useEffect(() => {
        if (element.type == elementTypes.textField) {
          updateElement({...element, fontSize});
        } else if (element.type == "fillable_textfield") {
          updateElement({...element, fontSize});
        } else if (element.type == elementTypes.date) {
          updateElement({...element, fontSize});
        } else if (element.type == "fillable_date") {
          updateElement({...element, fontSize});
        }
    }, [elementFontSize])

    const handleEdit = () => {
      setOpenEsignatureDialog(true)
    }

    const handleCloseEsignatureDialog = () => {
      setOpenEsignatureDialog(false)
    }

    const actions = [
      {title: 'Drag', type: 'draggableTooltip', icon: <OpenWithIcon sx={{color: '#e5e5e5', cursor: 'all-scroll', height: 15, width: 15}} onMouseDown={() => setOpenFontSizeDropdown(false)} onMouseEnter={() => handleOnMouseEnter(true)}/> },
      {title: 'Edit', type: '', icon: <Edit fontSize="small" sx={{ color: 'white', height: 15, width: 15 }} />, onClick: handleEdit },
      {title: "Duplicate", type: 'duplicate', icon: <ContentCopyIcon onMouseEnter={() => handleOnMouseEnter(false)} sx={{color: 'white', height: 15, width: 15}}
            onClick={() => handleDuplicateElements(element)} 
        />, 
        },
      {title: 'Delete', type: 'delete', icon: <DeleteIcon fontSize="small" sx={{ color: '#f74848' }} />, onClick: handleDelete}
    ]

    // Increment and Decrement Handlers
    const incrementFontSize = () => {
      setFontSize((prev) => {
        const fontSize = Math.min(prev + 1, 100)
        setElementFontSize(fontSize)
        return fontSize
      }); // Max font size
    };

    const decrementFontSize = () => {
      setFontSize((prev) => {
        const fontSize = Math.max(prev - 1, 1)
        setElementFontSize(fontSize)
        return fontSize
      }); // Min font size
    };

    // Handle manual input changes
    const handleInputChange = (event) => {
      const value = event.target.value;
      setFontSize(value ? parseInt(value, 10) || "" : ""); // Allow empty input
    };

    // Handle Enter key press to confirm font size
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        const value = parseInt(fontSize, 10);
        if (!isNaN(value)) {
          const font_size = Math.max(1, Math.min(value, 100))
          setFontSize(font_size); // Validate range 1–100
          setElementFontSize(font_size)
        } else {
          setFontSize(12); // Reset to default if invalid input
          setElementFontSize(12)
        }
        setOpenFontSizeDropdown(false); // Close Popper on Enter
      }
    };

    // Handle font size selection from Popper
    const handleFontSizeSelect = (size) => {
      setFontSize(size);
      setElementFontSize(size)
      setOpenFontSizeDropdown(false); // Close Popper after selection
    };

    // Toggle Popper visibility
    const togglePopper = () => {
      setOpenFontSizeDropdown((prev) => !prev);
    };

    return(
      // 
      <>
        <Fade direction="up" in={isElementClicked} mountOnEnter unmountOnExit>
          <Box onMouseEnter={() => handleOnMouseEnter(false)} sx={{zIndex: 9999, bgcolor: "#474747",  position: 'absolute', mt: -5, width: 'auto', borderRadius: '4px', justifyContent:'end'}}>
              <Card sx={{bgcolor:'#604CC3', display:'flex',alignItems:'center'}}>
                {(element.type === 'signatory') && 
                    <SignatoryField  element={element} handleOpenSignatory={handleOpenSignatory} handleOnIsEditing={handleOnIsEditing} />
                }
                {(element.type === elementTypes.date || element.type == "fillable_date") &&
                  <FormControl fullWidth variant="outlined" margin="dense" style={{padding: 0, margin: 0, marginLeft: 8}} >
                      {/* <InputLabel>Date Format</InputLabel> */}
                      <Select onOpen={()=>handleOnIsEditing(true)} style={{height: 27,padding: 0, color: 'white'}}
                        onClose={()=>handleOnIsEditing(false)} value={dateFormat} onChange={handleFormatChange} >
                          <MenuItem value="MM/dd/yyyy">MM/DD/YYYY</MenuItem>
                          <MenuItem value="dd/MM/yyyy">DD/MM/YYYY</MenuItem>
                          <MenuItem value="yyyy-MM-dd">YYYY-MM-DD</MenuItem>
                          <MenuItem value="MMMM dd, yyyy">MMMM DD, YYYY</MenuItem>
                      </Select>
                  </FormControl>
                }
                {(element.type === elementTypes.textField || element.type == "fillable_textfield" || element.type == elementTypes.date || element.type == "fillable_date") && 
                  <Box display="flex" alignItems="center" gap={1}>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={fontSize}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    inputRef={anchorRef} // Reference for the Popper anchor
                    onClick={togglePopper} // Show Popper on click
                    inputProps={{
                      style: { textAlign: "center", width: 50, height: 20, color: 'white' }, // Center alignment
                    }}
                  />
            
                  {/* Arrow Buttons */}
                  <Box display="flex" flexDirection="column">
                      <KeyboardArrowUp sx={{fontSize: 16, cursor: 'pointer', color: 'white'}} onClick={incrementFontSize} />
                      <KeyboardArrowDown sx={{fontSize: 16, cursor: 'pointer', color: 'white'}} onClick={decrementFontSize} />
                  </Box>
            
                  {/* Popper with Font Size List */}
                  <Popper open={openFontSizeDropdown} anchorEl={anchorRef.current} placement="bottom-start" sx={{zIndex: 99}}>
                    <List
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: 4,
                        background: "#fff",
                        boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                        padding: 0,
                        margin: 0,
                        maxHeight: 200,
                        overflowY: "auto",
                      }}
                    >
                      {fontSizes.map((size) => (
                        <ListItem key={size} disablePadding>
                          <ListItemButton onClick={() => handleFontSizeSelect(size)}>
                            {size}
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Popper>
                </Box>
                }
                {actions.map((action)=>{
                  if(action.title == 'Edit' && element.type != 'esignature') return null;

                  return(
                    <Tooltip title={action.title} placement="top">
                      <IconButton sx={{"&:hover": {backgroundColor: "#474747"}}} onClick={action.onClick}>
                        {action.icon}
                      </IconButton>
                    </Tooltip>
                  )
                })}
              </Card>
            </Box>
        </Fade>
        <CreateEsignatureCommon openEsignatureDialog={openEsignatureDialog} handleCloseEsignatureDialog={handleCloseEsignatureDialog} />
      </>
    )
}