import React from "react";
import {Skeleton, Container, Paper, Divider, Box} from "@mui/material";

const WorkspaceAcceptInviteSkeleton = (props) => {
  
    return (
        <Container maxWidth="md" sx={{width: 650}}>
            <Paper elevation={3} sx={{mt: 20, boxShadow: "0px 3px 6px -2px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.08), 0px 2px 12px 0px rgba(0, 0, 0, 0.05)"}}>
                <Box sx={{p: "30px 10px 12px"}}>
                    <Skeleton variant="rectangular" sx={{backgroundColor: "#eeebf8", margin: "auto"}} height={55} width={65} />

                    <Skeleton variant="rectangular" height={25} width={"60%"}
                        sx={{backgroundColor: "#eeebf8", margin: "auto", marginTop: "20px !important", marginBottom: "8px !important"}} 
                    />
                </Box>

                <Divider sx={{border: "2.5px solid #604CC3"}} />

                <Box sx={{padding: "42px 32px"}}>
                    <>
                        <Skeleton variant="rectangular" sx={{backgroundColor: "#eeebf8", m: "0px auto 30px"}} height={25} width={"80%"} /> 

                        <Skeleton variant="rectangular" sx={{backgroundColor: "#eeebf8", mb: 1}} height={23} width={"60%"} /> 

                        <Skeleton variant="rectangular" sx={{backgroundColor: "#eeebf8", mt: "35px"}} height={23} width={"100%"} />   
                        <Skeleton variant="rectangular" sx={{backgroundColor: "#eeebf8", mt: "10px"}} height={23} width={"100%"} />   
                        <Skeleton variant="rectangular" sx={{backgroundColor: "#eeebf8", mt: "10px"}} height={23} width={"100%"} />      

                        <Box sx={{mt: "40px", display: "flex", justifyContent: "flex-end"}}>
                            <Skeleton variant="rectangular" sx={{backgroundColor: "#eeebf8"}} height={35} width={"25%"} />   
                            &nbsp; &nbsp;

                            <Skeleton variant="rectangular" sx={{backgroundColor: "#eeebf8"}} height={35} width={"25%"} />   
                        </Box> 
                    </>    
                </Box>
            </Paper>
        </Container>
    )
}

export default WorkspaceAcceptInviteSkeleton
