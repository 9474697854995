import { Avatar, Box, Button, Card, CardActions, CardContent, Divider, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Typography, Snackbar, Alert } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useEffect, useState } from "react";
import { format } from 'date-fns';
import tokenIcon from '../../../assets/icons/token.png';
import { useNavigate } from "react-router-dom";





const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

export default function PurchaseTransactionsComponent(props) {

    const { data, isFetchingOrders, isFetchingPurchaseTransaction, allData } = props;

    const navigate = useNavigate();

    const [openSnackbar, setOpenSnackbar] = useState(false);

    const forPaymentCount = allData?.filter(order => order.order.status === "For Payment").length;


    const handleCheckoutClick = (order_id) => {
        if (forPaymentCount >= 3) {
            setOpenSnackbar(true);
        } else {
            navigate(`/token/checkout?orderId=${order_id}`);
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };


    useEffect(() => {
        console.log("purchasexxxx data here xxxx", data)
    }, [data])

    const statusColors = {
        Checkout: "#FFA500",
        Cancelled: "#FF0000",
        Completed: "#008000",
        "Pay Now": "#604CC3"
    };


    if (isFetchingOrders || isFetchingPurchaseTransaction) {
        return (
            <Box sx={{ padding: '0px !important' }}>
                {[...Array(3)].map((_, index) => (
                    <Card sx={{ mb: 2 }} key={index}>
                        <CardContent sx={{ padding: 5 }}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid size={{ xs: 12, sm: 6 }} sx={{ justifyContent: 'flex-start' }}>
                                    <Skeleton variant="text" width="80%" height={24} sx={{ backgroundColor: '#eeebf8' }} />
                                </Grid>
                                <Grid size={{ xs: 12, sm: 6 }} sx={{ justifyContent: 'flex-end' }}>
                                    <Skeleton variant="text" width="50%" height={24} sx={{ backgroundColor: '#eeebf8' }} />
                                </Grid>
                            </Grid>
                            <Divider />
                            {[...Array(2)].map((_, idx) => (
                                <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 2 }} key={idx}>
                                    <Grid size={{ xs: 12, sm: 6 }}>
                                        <ListItem disablePadding>
                                            <ListItemAvatar>
                                                <Skeleton variant="circular" width={40} height={40} sx={{ backgroundColor: '#eeebf8' }} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={<Skeleton variant="text" width="70%" sx={{ backgroundColor: '#eeebf8' }} />}
                                                secondary={<Skeleton variant="text" width="50%" sx={{ backgroundColor: '#eeebf8' }} />}
                                            />
                                        </ListItem>
                                    </Grid>
                                    <Grid size={{ xs: 12, sm: 6 }} sx={{ justifyContent: 'flex-end' }}>
                                        <Skeleton variant="text" width="30%" sx={{ backgroundColor: '#eeebf8' }} />
                                    </Grid>
                                </Grid>
                            ))}
                            <Divider />
                            <Grid size={{ xs: 12 }} sx={{ textAlign: 'end', mt: 5 }}>
                                <Skeleton variant="text" width="30%" sx={{ backgroundColor: '#eeebf8' }} />
                            </Grid>
                        </CardContent>
                    </Card>
                ))}
            </Box>
        );
    }

    if (data.length === 0) {
        return (
            <Box sx={{ padding: '0px !important' }}>
                <Card sx={{ mb: 2 }} >
                    <CardContent sx={{ padding: 5 }}>
                        <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold', color: '#58565f' }}>
                            No available data to display
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        )
    }

    return (
        <Box sx={{ padding: '0px !important' }}>
            {data.map((orderData, index) => {
                const order = orderData.order || orderData; // Handle cases where `order` exists
                const items = orderData.items || [];
                const displayStatus = (order?.status === "Paid") ? "Completed" : (order?.status === "Pending") ? 'Checkout' : (order?.status === "For Payment") ? 'Pay Now' : (order?.status);

                return (
                    <Card sx={{ mb: 2 }} >
                        <CardContent sx={{ padding: 5 }}>
                            <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 1 }} >
                                <Grid size={{ xs: 12, sm: 6 }} sx={{ justifyContent: 'flex-start' }}>
                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14, textAlign: 'start' }}>
                                        {format(new Date(order?.created_at), 'PP')}
                                    </Typography>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 6 }} sx={{ justifyContent: 'flex-end', textAlign: 'end' }}>
                                    <Typography
                                        sx={{
                                            textAlign: 'end',
                                            textTransform: 'uppercase',
                                            fontWeight: 500,
                                            color: statusColors[displayStatus],
                                            cursor: (order?.status === "Pending" || order?.status === "For Payment") ? 'pointer' : 'default'
                                        }}
                                        onClick={() => {
                                            if (order?.status === "For Payment") {
                                                navigate(`/place-order?orderId=${order?.order_id}`);
                                            } else if (order?.status === "Pending") {
                                                handleCheckoutClick(order?.order_id);
                                            }
                                        }}
                                    >
                                        {displayStatus}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            {items?.map((itemData) => {
                                return (
                                    <>
                                        <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
                                            <Grid size={{ xs: 12, sm: 6 }} >
                                                <ListItem disablePadding>
                                                    <ListItemAvatar>
                                                        <Avatar >
                                                            <img src={tokenIcon} alt="Package Icon" style={{ width: '40px', height: '40px' }} />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={<Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                                            {itemData?.package_code ? itemData?.package_code : 'Custom Package'}
                                                        </Typography>}
                                                        secondary={`${itemData?.token_amount ? `${itemData.token_amount} Tokens` : "0 Token"}`}
                                                    />
                                                </ListItem>
                                            </Grid>
                                            <Grid size={{ xs: 12, sm: 6 }} sx={{ justifyContent: 'flex-end' }}>
                                                <Typography sx={{ textAlign: 'end' }}>$ {itemData?.total}</Typography>
                                            </Grid>

                                        </Grid>

                                    </>

                                )
                            })}
                            <Divider />
                            <Grid size={{ xs: 12 }} sx={{ textAlign: 'end', mt: 5 }}>
                                <Typography>Total : $ {order?.total_amount}</Typography>
                            </Grid>

                        </CardContent>

                    </Card>
                )
            })}

            <Snackbar open={openSnackbar} onClose={handleSnackbarClose}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    You have exceeded the limit of 3 'For Payment' orders. Please complete payment or cancel existing orders to continue.
                </Alert>
            </Snackbar>

        </Box>
    )

}