import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, MenuItem, OutlinedInput, Select, Skeleton, styled, Typography } from "@mui/material";
import CustomDialog from "../../../core/components/common/custom-dialog.component";
import { useState } from "react";
import CustomTextField from "../../../core/components/ui/CustomTextField3";
import useUser from "../hooks/useUser";
import Grid from '@mui/material/Grid2';
import { ThreeDots } from "react-loader-spinner";
import CloseIcon from '@mui/icons-material/Close';
import WalletBalanceButton from "../../../core/components/common/wallet-balance-button";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function TransferTokenScreen(props) {
    const { openTranferToken, handleCloseTransferTokenDialog, workspaceList, isFetchingWorkspaceList, walletBalance, isFetchingWalletBalance } = props;

    const { tranferToken, isTransferring } = useUser();

    const [menuSelected, setMenuSelected] = useState(null);
    const [amount, setAmount] = useState("");
    const [error, setError] = useState("");

    const owner = localStorage.getItem('menuSelected');

    const handleSelectChange = (event) => {
        const value = event.target.value;
        console.log("value here", value);
        setMenuSelected(value);
    };

    const handleAmountChange = (event) => {
        const inputAmount = event.target.value;
        const numericValue = parseFloat(inputAmount);

        if (isNaN(numericValue) || numericValue <= 0) {
            setError("Please enter a valid amount.");
        } else if (numericValue > walletBalance) {
            setError("Token Balance insufficient. Please enter a sufficient amount.");
        } else if (numericValue > 999999) {
            setError("The maximum allowed amount is 999999.");
        } else {
            setError("");
        }
        setAmount(inputAmount);
    };

    const handleTransferToken = () => {
        // Validation checks
        if (!menuSelected) {
            setError("Please select a workspace.");
            return;
        }

        if (!amount || parseFloat(amount) <= 0) {
            setError("Please enter a valid amount.");
            return;
        }

        if (parseFloat(amount) > walletBalance) {
            setError("Token Balance insufficient.");
            return;
        }

        if (parseFloat(amount) > 999999) {
            setError("The maximum allowed amount is 999999.");
            return;
        }

        // Reset error and proceed
        setError("");
        const payload = {
            to: menuSelected,
            amount: parseFloat(amount),
        };

        tranferToken(payload, handleCloseTransferTokenDialog);
    };

    console.log("menuSelectedxx", menuSelected)

    const formatBalance = () => {
        if (walletBalance === 0 || walletBalance === 1) {
            return `${walletBalance.toLocaleString()} TOKEN`;
        }
        return `${walletBalance.toLocaleString()} TOKENS`;
    };

    const renderWorkspaceList = () => {
        if (isFetchingWorkspaceList) {
            return (
                <MenuItem disabled>
                    <Skeleton variant="text" sx={{ fontSize: "1.5rem", backgroundColor: "#eeebf8" }} />
                </MenuItem>
            );
        }

        if (workspaceList?.length === 0) {
            return <MenuItem disabled>No workspaces available</MenuItem>;
        }

        const workspaceData = workspaceList.data;

        return workspaceData?.map((workspace) => (
            <MenuItem key={workspace?.workspace.id} value={workspace?.workspace.wallet_id}>
                {workspace?.workspace.name}
            </MenuItem>
        ));
    };

    const content = isFetchingWorkspaceList ? (
        <p>Fetching .... </p>
    ) : (
        <Box sx={{ padding: 5, pt: 3, pb: 3 }}>
            <Grid size={12}>
                <Typography
                    sx={{
                        fontSize: 20,
                        borderRadius: 1,
                        textTransform: 'none',
                        // fontWeight: 'bold',
                        // color: '#604CC3',
                        width: 'auto',
                        textAlign: 'center'
                    }}
                >
                    Balance : <WalletBalanceButton walletBalance={walletBalance} isFetchingWalletBalance={isFetchingWalletBalance} /> 
                </Typography>
            </Grid>
            <Grid container spacing={1} sx={{ mt: 3 }}>
                {/* <Grid size={{ xs: 12, md: 12 }} sx={{ alignSelf: 'center' }}>
                    <Typography>From</Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 12 }} sx={{ alignSelf: 'center' }}>
                    <CustomTextField
                        value={owner}
                        variant={"outlined"}
                        fullWidth={true}
                        multiline={false}
                        hiddenLabel
                        InputProps={{
                            readOnly: true,
                            cursor: 'not-allowed'
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#aca3e0',
                                    borderRadius: 0
                                },
                                '&:hover fieldset': {
                                    borderColor: '#aca3e0',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#aca3e0',
                                },
                            },
                            backgroundColor: '#f6f8fb',
                            cursor: 'not-allowed',
                            '& .MuiInputBase-input': {
                                color: '#666'
                            }
                        }}
                    />
                </Grid>
                <br /> */}
                <Grid size={{ xs: 12, md: 12 }} sx={{ alignSelf: 'center' }}>
                    <Typography>To</Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 12 }} sx={{ alignSelf: 'center' }}>
                    <FormControl fullWidth >
                        <Select
                            displayEmpty
                            value={menuSelected}
                            onChange={handleSelectChange}
                            input={<OutlinedInput />}
                            sx={{
                                borderRadius: 0,
                                backgroundColor: "#f6f8fb",
                                borderColor: "#aca3e0",
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "#aca3e0",
                                        borderRadius: 0,
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "#aca3e0",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#aca3e0",
                                    },
                                },
                                "& .MuiInputBase-input": {
                                    color: "#666",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#aca3e0",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#aca3e0",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#aca3e0",
                                },
                            }}
                            renderValue={(menuSelected) => {
                                if (!menuSelected) {
                                    return <Typography sx={{ color: '#babbbd' }}>Select Workspace</Typography>;
                                }

                                const selectedWorkspace = workspaceList?.data?.find(
                                    (workspace) => workspace?.workspace.wallet_id === menuSelected
                                );

                                return selectedWorkspace?.workspace.name || menuSelected;
                            }}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            {renderWorkspaceList()}
                        </Select>
                    </FormControl>
                </Grid>
                <br />
                <Grid size={{ xs: 12, md: 12 }} sx={{ alignSelf: 'center' }}>
                    <Typography>Enter Amount</Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 12 }} sx={{ alignSelf: 'center' }}>
                    <CustomTextField
                        placeholder={'0 Token'}
                        value={amount}
                        onChange={handleAmountChange}
                        error={!!error}
                        helperText={error || ""}
                        variant={"outlined"}
                        fullWidth
                        multiline={false}
                        hiddenLabel
                        type="number"
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "#aca3e0",
                                    borderRadius: 0,
                                },
                                "&:hover fieldset": {
                                    borderColor: "#aca3e0",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "#aca3e0",
                                },
                            },
                            backgroundColor: "#f6f8fb",
                            "& .MuiInputBase-input": {
                                color: "#666",
                            },
                        }}
                    />
                </Grid>
            </Grid>

            <Grid size={12} sx={{ justifyContent: 'flex-end', textAlign: 'end', mt: 5 }}>
                <Button
                    variant="contained"
                    sx={{ color: '#fff', backgroundColor: '#604CC3' }}
                    startIcon={isTransferring && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                    onClick={handleTransferToken}
                    disabled={!amount}
                >
                    {isTransferring ? 'PROCESSING' : 'SEND'}
                </Button>
            </Grid>

        </Box>
    );

    return (
        <BootstrapDialog
            onClose={handleCloseTransferTokenDialog}
            aria-labelledby="customized-dialog-title"
            open={openTranferToken}
        >
            <DialogTitle sx={{ m: 0, p: 2, backgroundColor: '#604CC3', color: '#fff' }} id="customized-dialog-title" >
                TRANSFER TOKEN
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleCloseTransferTokenDialog}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: '#fff',
                })}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                {content}
            </DialogContent>
        </BootstrapDialog>
    );
}
