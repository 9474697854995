import { Box, Typography } from "@mui/material";
import PageHeaderComponent from "../../../core/components/common/custom-page-header.component";



export default function TemplateScreen () {

    return(
        <Box sx={{textAlign:'start'}}>
            <PageHeaderComponent text={'Templates'}/>
            <Typography sx={{mt: 35, textAlign:'center'}}>
                Ongoing features
            </Typography>
        </Box>
    )
}