import { Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";

export default function ProfileDetailsSkeletonComponent() {
    return (
        <Grid container spacing={2} sx={{ mt: 6, padding: "30px 50px" }}>
            <Grid size={{ xs: 12, sm: 6, md: 3 }} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                <Skeleton variant="circular" width={100} height={100} sx={{backgroundColor: "#eeebf8"}} />
                <Skeleton variant="text" width={200} height={40} sx={{ mt: 2, backgroundColor: "#eeebf8" }} />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 9 }} sx={{ justifyContent: 'flex-start', textAlign: 'start', paddingLeft: 0, paddingTop: 0, paddingRight: '180px' }}>
                <Skeleton variant="rectangular" width={200} height={40} sx={{ mt: 2, backgroundColor: "#eeebf8" }} />
                <Skeleton variant="text" width={150} height={30}  sx={{backgroundColor: "#eeebf8"}} />
                <Skeleton variant="text" width="100%" height={40} sx={{ mt: 1, backgroundColor: "#eeebf8" }} />
                <Skeleton variant="text" width={150} height={30} sx={{ mt: 3, backgroundColor: "#eeebf8" }} />
                <Skeleton variant="text" width="100%" height={40} sx={{ mt: 1, backgroundColor: "#eeebf8" }} />
                <Skeleton variant="rectangular" width="100%" height={150} sx={{ mt: 3, backgroundColor: "#eeebf8" }} />
            </Grid>
        </Grid >
    );
}
