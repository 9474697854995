import { useEffect, useState } from "react";
import useDocument from "../../../documents-plugin/hooks/useDocument";
import DocumentViewAppbarComponent from "../components/document-view-box.component.jsx/document-view-appbar.component";
import { Box, CssBaseline, Drawer, Toolbar } from "@mui/material";
import DocumentViewPageComponent from "../components/document-view-box.component.jsx/document-view-page.component";
import { useLocation } from "react-router-dom";
import DocumentViewDrawerComponent from "../components/document-view-box.component.jsx/document-view-drawer.component";
import useDocumentEditor from "../hooks/useDocumentEditor";


export default function DocumentViewScreen (props) {

    const {documentId} = props;


    const id = new URLSearchParams(window.location.search).get("id");

    const document_id = documentId ? documentId : id;

    const {getDocumentById, documentById, viewDocument, decryptedFile} = useDocument();
    const {signatoryList, signatoryListByDocumentId, documentAutomationRecord, automationRecord} = useDocumentEditor();

    const location = useLocation();

    useEffect(()=>{
        if(documentById){
            let url = documentById?.document_url;

            if (!url.startsWith("http://") && !url.startsWith("https://")) {
                url = "http://" + url;
            }

            let pdfUrl = new URL(url);

            let encrypted_file = pdfUrl.searchParams.get('e');

            viewDocument(encrypted_file)

        // setEncryptedFile(encrypted_file)
        }
    },[documentById])

    useEffect(()=>{
        if(document_id){
            getDocumentById(document_id)
            documentAutomationRecord(document_id)
        }
    },[document_id])

    useEffect(()=>{
        if(document_id){
            signatoryListByDocumentId(document_id)
        }
    },[document_id])
    
    return(
        <Box sx={{ display: 'flex' }}>
            <CssBaseline/>
            <DocumentViewAppbarComponent />
            
            <Box component="main" sx={{ flexGrow: 1, mt: 1, textAlign:'center' }}>
                <Toolbar />

                <DocumentViewPageComponent decryptedFile={decryptedFile} documentById={document_id} documentDetails={documentById}/>
                
            </Box>
            {/* Drawer */}
            <DocumentViewDrawerComponent documentById={documentById} signatoryList={signatoryList} automationRecord={automationRecord}/>
        </Box>
    )
}