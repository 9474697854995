import { Box, Stack, Tooltip, Typography } from '@mui/material';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {useDragHook} from '../../hooks/useDragHook'
import elements from '../../common/elements';
import signatoryElements from '../../common/signatory-elements';
import { useEffect } from 'react';

export default function DocumentDraggableElementComponent(prop){

    const {draggable_ui_element, signatories} = prop;
    const element = elements.find((item)=> item.type === draggable_ui_element.type)

    const {dragRef} = useDragHook('element_fields', element)

    return(
        <Box sx={{
                height: '45px', p: 1, 
                color: 'draggable_element.text.primary',
                bgcolor: signatories?.length > 0 ? '#ffe2a3' : '#DFDBF3', 
                borderColor: '#16840100', 
                borderStyle: 'solid',
                cursor: 'all-scroll'
            }}
            ref={dragRef}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0.5}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0.5}
                    sx={{flex: 1, pl: 1, alignSelf: 'center', transition: 'padding 0.15s ease 0s'}}
                >
                    <Typography sx={{fontSize: 13, color: 'rgba(24, 24, 24, 0.85)'}}>
                        {draggable_ui_element.label}
                    </Typography>
                </Stack>
                    <>
                        {draggable_ui_element.icon}
                    </>
            </Stack>
        </Box>
    )
}