import routes from "../common/api-routes";
import { HttpAuthPost, HttpAuthPut } from "../../../../core/services/common/HttpRequestBuilder";


const tranferToken = (payload) => {
    let result = HttpAuthPost(routes.TRANSFER_TOKEN, payload);

    return result;
}

const updateSignatureSetupFirstLogin = (isSetup) => {
    let query_params = {
        isSignatureSetup: isSetup
    } 
    let result = HttpAuthPut(routes.IS_SIGNATURE_SETUP, query_params);
    return result;
}


const UserService = { tranferToken, updateSignatureSetupFirstLogin }

export default UserService