import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

export const useBlocker = (blocker, when = true) => {
    const { navigator } = useContext(NavigationContext);
  
    useEffect(() => {
      if (!when) return;
  
      const push = navigator.push;
  
      navigator.push = (...args) => {
        const allowNavigation = blocker(args);
        if (allowNavigation) {
          push(...args);
        }
      };
  
      return () => {
        navigator.push = push; // Restore the original method on cleanup
      };
    }, [blocker, navigator, when]);
}