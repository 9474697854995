import { Box, AppBar, Toolbar, Button, ListItem, ListItemAvatar, Avatar, Menu, MenuItem, List, ListItemText, ListItemButton, ListItemIcon, Divider, Skeleton, styled, Badge, Typography, IconButton, Chip, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { auth } from '../../../firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { stringAvatar, stringToAvatarEmail } from "../../utils/avatarImageUtil";
import CustomIconButton from "../ui/CustomIconButton";
import supersignLogo from '../../../assets/icons/supersign-logo.png';
import WorkspacesIcon from "../../../assets/icons/menu.png";
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import LogoutIcon from "../../../assets/icons/logout.png";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import useAuthentication from "../../../plugins/profile-plugin/hooks/useAuthentication";
import HistoryIcon from '@mui/icons-material/History';
import useTokenManagement from "../../../plugins/token-plugin/hooks/useTokenManagement";
import WalletBalanceButton from "../common/wallet-balance-button";
import useWorkspaceManagement from "../../../plugins/workspace-plugin/hooks/useWorkspaceManagement";
import useSelectedWorkspaceStore from "../../../plugins/workspace-plugin/zustand/useSelectedWorkspaceStore";
import TokenIcon from '@mui/icons-material/Token';
import TransferTokenScreen from "../../../plugins/profile-plugin/pages/TransferTokenScreen";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import useOrderManagement from "../../../plugins/token-plugin/hooks/useOrderManagement";
import ProfileCartMenu from "../../../plugins/token-plugin/components/profile-cart.component";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationScreen from "../../../plugins/notification-plugin/pages/NotificationScreen";
import useNotification from "../../../plugins/notification-plugin/hooks/useNotification";
import CustomButton from "../ui/CustomButton";
import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import useEnterpriseProjectManagement from "../../../plugins/workspace-plugin/hooks/useEnterpriseProjectManagement";
import useWidth from "../../utils/useWidth";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#604CC3',
        color: '#fff',
    },
}));

export default function AppBarComponent({ children }) {

    const breakpoints = useWidth();
    const navigate = useNavigate();
    const location = useLocation();
    const { isFetchingProfileDetails, viewProfile, profileData } = useAuthentication();
    const { getWalletBalance, isFetchingWalletBalance, walletBalance, initialBalanceLoaded } = useTokenManagement();
    const { getWorkspaceList, workspaceList, isFetchingWorkspaceList } = useWorkspaceManagement();
    const { getListOrder, isFetchingOrders, orderList } = useOrderManagement();
    const { getNotification, notificationData , fetchingNotification} = useNotification();
    const {getProjectCredits, projectCredits} = useEnterpriseProjectManagement();

    const [isEmail, setIsEmail] = useState(JSON.parse(localStorage.getItem('isEmail')));
    const [isWorkspace, setIsWorkspace] = useState(JSON.parse(localStorage.getItem('isWorkspace')));
    const [menuSelected, setMenuSelected] = useState(localStorage.getItem('menuSelected'));
    const [wallet_id, setWalletId] = useState(localStorage.getItem('wallet_id'));
    const [order_placed, setOrderPlaced] = useState(JSON.parse(localStorage.getItem('orderPlaced')));
    const [order_created, setOrderCreated] = useState(JSON.parse(localStorage.getItem('orderCreated')));
    const [order_empty, setOrderEmpty] = useState(JSON.parse(localStorage.getItem('orderEmpty')));
    const [item_created, setItemCreated] = useState(JSON.parse(localStorage.getItem('itemCreated')));

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [openTranferToken, setOpenTransferToken] = useState(false);
    const [switchAccountList, setSwitchAccountList] = useState([]);
    const [hideAlert, setHideAlert] = useState(false)
    const [notification_count, setNotificationCount] = useState(0);
    const [workspaceCredits, setWorkspaceCredits] = useState(0);

    const selectedWorkspace = useSelectedWorkspaceStore((state) => state.selectedWorkspace)
    const setSelectedWorkspace = useSelectedWorkspaceStore((state) => state.setSelectedWorkspace)


    useEffect(() => {
        const parameters = { page: 1, limit: 10, name: " " };
        getWorkspaceList(parameters);
    }, []);

    useEffect(() => { 
        viewProfile();
        getNotification();
     }, [])


     useEffect(() => {
        const syncLocalStorage = () => {
            const storedIsEmail = JSON.parse(localStorage.getItem('isEmail'));
            const storedIsWorkspace = JSON.parse(localStorage.getItem('isWorkspace'));
            const storedMenuSelected = localStorage.getItem('menuSelected');
            const storedWalletId = localStorage.getItem('wallet_id');
            const storedOrder = JSON.parse(localStorage.getItem('orderPlaced'));
            const storedOrderCreated = JSON.parse(localStorage.getItem('orderCreated'));
            const storedOrderEmpty = JSON.parse(localStorage.getItem('orderEmpty'));
            const storedItemCreated = JSON.parse(localStorage.getItem('itemCreated'));

            if (storedIsEmail !== isEmail) setIsEmail(storedIsEmail);
            if (storedIsWorkspace !== isWorkspace) setIsWorkspace(storedIsWorkspace);
            if (storedMenuSelected !== menuSelected) setMenuSelected(storedMenuSelected);
            if (storedWalletId !== wallet_id) setWalletId(storedWalletId);
            if (storedOrder !== order_placed) setOrderPlaced(storedOrder);
            if (storedOrderCreated !== order_created) setOrderCreated(storedOrderCreated);
            if (storedOrderEmpty !== order_empty) setOrderEmpty(storedOrderEmpty);
            if (storedItemCreated !== item_created) setItemCreated(storedItemCreated);
        };

        const intervalId = setInterval(syncLocalStorage, 500);

        return () => clearInterval(intervalId);
    }, [isEmail, isWorkspace, menuSelected, wallet_id, order_placed, order_created, order_empty, item_created]);

    useEffect(() => {
        let parameters = {};

        if (isEmail) {
            if (profileData) {
                parameters = { "wallet_id": profileData && profileData?.profile.walletId }
                getListOrder(parameters);
            }
        } else if (isWorkspace) {
            parameters = { "wallet_id" : wallet_id ? wallet_id : null }
            getListOrder(parameters);
        }


    }, [isEmail, isWorkspace, wallet_id, profileData]);

    useEffect(() => {
           let parameters;
       
           const fetchOrders = () => {
               if (isEmail && profileData?.profile.walletId) {
                   parameters = { "wallet_id": profileData.profile.walletId };
               } else if (isWorkspace) {
                   parameters = { "wallet_id": wallet_id || null };
               }
       
               if (parameters) {
                   getListOrder(parameters);
               }
           };
       
           if (item_created || order_placed || order_created || order_empty) {
               fetchOrders();
           } 
       }, [item_created, order_placed, order_created, order_empty, isEmail, isWorkspace, wallet_id, profileData]);

   
     useEffect(() => {
        if (workspaceList && profileData) {
            let acc_profile = {
                name: profileData.profile.email,
                logo: profileData.profile.photo,
                id: profileData.profile.userId
            }

            let workspaces = workspaceList?.data?.map((item) => ({name: item.workspace.name, logo: item.workspace.logo, id: item.workspace.id, wallet_id: item.workspace.wallet_id})) || []
            const switch_acc_list = [acc_profile, ...workspaces];
            setSwitchAccountList(switch_acc_list)

            const storedMenuSelected = localStorage.getItem('menuSelected');
            console.log("storedMenuSelected bbb", parseInt(storedMenuSelected));
            console.log("switch_acc_list bbb", switch_acc_list);

            let currentSelectedAccount = switch_acc_list.find((item) => item.id == parseInt(storedMenuSelected))

            if (currentSelectedAccount) {
                setSelectedWorkspace(currentSelectedAccount)
            } else {
                setSelectedWorkspace(acc_profile)
            }
        }
     }, [JSON.stringify(workspaceList), JSON.stringify(profileData)])

     useEffect(() => {

        console.log("selectedWorkspace here", selectedWorkspace)
        if(selectedWorkspace != profileData?.profile?.email){
            localStorage.removeItem('cartItems');
            localStorage.removeItem('addedCustomAmount');
            localStorage.removeItem('addedToCart');
            localStorage.removeItem('orderPlaced');
            localStorage.removeItem('orderCreated');
        } 

     },[selectedWorkspace, profileData])

    // console.log("orderList xxx", orderList)

    const filteredOrders = orderList?.filter(order =>  order?.status === "Pending" || order?.status === "For Payment" );

    useEffect(() => {
        let parameters = {};

        if (isEmail) {
            if (profileData) {
                localStorage.setItem('profile_wallet', profileData?.profile.walletId);
                parameters = { "walletId": profileData && profileData?.profile.walletId }
                getWalletBalance(parameters);
            }
        } else if (isWorkspace) {
            parameters.walletId = wallet_id ? wallet_id : null;
            getWalletBalance(parameters);
        }

    }, [isEmail, isWorkspace, wallet_id, profileData]);

    useEffect(() => {
        if (selectedWorkspace?.name?.includes("@")) {
            setWorkspaceCredits(0);
        } else {
            const parameters = {workspace_id: selectedWorkspace?.id};
            getProjectCredits(parameters);
        }
    }, [selectedWorkspace])

    useEffect(() => {
        if (projectCredits) {
            setWorkspaceCredits(projectCredits);
        }
    }, [projectCredits])
 

    const navbarMenu = {
        0: '/dashboard',
        1: '/documents',
        2: '/templates',
    }

    const handleMenu = (e) => {
        navigate(navbarMenu[e] || '/')
    }

    const menuButtonColor = '#604CC3';

    const menuButton = [
        { label: 'Dashboard', color: '#604CC3' },
        { label: 'Documents', color: '#604CC3' },
        { label: 'Templates', color: '#604CC3' },
    ]

    const drawerWidth = 300;

    const handleAvatarClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleCart = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleCloseCart = () => {
        setAnchorEl2(null);
    };

    const handleNotification = (event) => {
        setAnchorEl3(event.currentTarget);
    };

    const handleCloseNotification = () => {
        setAnchorEl3(null);
    };

    const handleSignOut = () => {
        signOut(auth);
        localStorage.removeItem("invitationDetails");
        localStorage.removeItem("hasSignInUsingEmailAddress");
        localStorage.removeItem('wallet_id');
        localStorage.removeItem('isEmail');
        localStorage.removeItem('isWorkspace');
        localStorage.removeItem('profile_wallet');
        localStorage.removeItem('menuSelected');
        localStorage.removeItem('cartItems');
        localStorage.removeItem('addedCustomAmount');
        localStorage.removeItem('addedToCart');
        localStorage.removeItem('orderId');
        localStorage.removeItem('orderPlaced');
        localStorage.removeItem('orderCreated');
        localStorage.clear()
        handleClose();
    };

    const handleCloseTransferTokenDialog = () => {
        setOpenTransferToken(false);
    };

    const handleRedirectToWorkspace = () => {
        navigate("/workspaces");
        handleClose()
    }

    const handleSwitchAccount = (acc) => {
        if (acc?.name?.includes('@')) {
            localStorage.setItem('isEmail', 'true');
            localStorage.setItem('isWorkspace', 'false');
            localStorage.removeItem('wallet_id'); 
            localStorage.setItem('menuSelected', acc.name);
            let acc_profile = {
                name: acc.name,
                logo: acc.logo,
                id: acc.id
            }
            setSelectedWorkspace(acc_profile)
        } else {
            let workspace = {
                name: acc.name, 
                logo: acc.logo, 
                id: acc.id,
                wallet_id: acc.wallet_id,
            }
            setSelectedWorkspace(workspace)
            localStorage.setItem('isEmail', 'false');
            localStorage.setItem('isWorkspace', 'true');
            localStorage.setItem('menuSelected', acc.id);
            localStorage.setItem('wallet_id', acc.wallet_id);
        }
        handleClose()
    }

    const handleCloseAlert = () => {
        setHideAlert(false);
    }

    useEffect(() => {
        let timer;
        if (initialBalanceLoaded) {
            if (walletBalance <= 100) {
                setHideAlert(true);
                timer = setTimeout(() => {
                    setHideAlert(false); 
                }, 5000);
            } else {
                setHideAlert(false); 
            }
        }
    
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [initialBalanceLoaded, walletBalance]);


    
    const groupedNotifications = notificationData?.reduce((acc, current) => {
        const { notification, recipient } = current;
        const { title, message, updated_at, sender, reference } = notification;

        // Create a unique key for grouping
        const groupKey = `${title}-${message}-${sender}-${reference}`;

        if (!acc[groupKey]) {
            acc[groupKey] = { ...current, count: 1 }; // Add a count property
        } else {
            acc[groupKey].count += 1; // Increment count for similar notifications
        }

        return acc;
    }, {});

    // Convert grouped object back to an array
    const combinedNotifications = Object.values(groupedNotifications);
    const unreadCount = combinedNotifications?.filter((data) => !data.recipient?.is_read).length;
    
    return (
        <>
            <AppBar position="fixed" sx={{ bgcolor: 'white' }}>
                <Toolbar variant="dense">
                    <img alt="" src={supersignLogo} style={{height: "100%", width: (breakpoints === "xs" || breakpoints === "sm") ? "120px" : "170px", cursor: "pointer"}} onClick={() => window.location.href = '/dashboard'} />
                    <Box sx={{ flexGrow: .04 }} />
                    <Button component={Link} to="/dashboard" 
                        sx={{ textTransform: 'none', color: location.pathname === '/dashboard' ? '#604CC3' : 'gray', width: 120,
                            '&:hover': {
                                color: '#604CC3', // Optional: hover color
                            }
                        }}
                    >
                        Dashboard
                    </Button>
                    <Button component={Link} to="/documents" 
                        sx={{ textTransform: 'none', color: location.pathname === '/documents' ? '#604CC3' : 'gray', width: 120,
                            '&:hover': {
                                color: '#604CC3', // Optional: hover color
                            }
                        }}
                    >
                        Documents
                    </Button>
                    {/* <Button component={Link} to="/templates" sx={{ textTransform: 'none', color: '#604CC3', width: 120 }}>
                        Templates
                    </Button> */}

                    <Box sx={{ flexGrow: 1 }} />

                    <Typography sx={{ fontWeight: "bold", color: "rgba(0, 0, 0, 0.87)", fontSize: 24 }}>
                        {selectedWorkspace?.name?.includes("@") ? selectedWorkspace?.name?.split("@")[0] : selectedWorkspace?.name}
                    </Typography>


                    <Box sx={{ flexGrow: 1 }} />

                    {projectCredits !== 0 && workspaceCredits !== 0 &&
                        <Button
                            sx={{
                                background: "linear-gradient(135deg, #604CC3, #FF6600)",
                                color: "#fff",
                                boxShadow: 3,
                                display: "flex",
                                cursor: "default",
                                textTransform: "none",
                            }}
                        >
                            {projectCredits?.toLocaleString()} Credits
                        </Button>
                    } &nbsp;&nbsp;&nbsp;&nbsp;

                    <WalletBalanceButton walletBalance={walletBalance} isFetchingWalletBalance={isFetchingWalletBalance} />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <StyledBadge badgeContent={unreadCount} color='primary'  onClick={handleNotification} sx={{cursor: 'pointer'}} >
                        <NotificationsNoneIcon style={{ color: '#604CC3' }} />
                    </StyledBadge>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <StyledBadge badgeContent={filteredOrders && filteredOrders?.length} color='primary' onClick={handleCart} sx={{cursor: 'pointer'}} >
                        <ShoppingCartOutlinedIcon style={{ color: '#604CC3' }} />
                    </StyledBadge>
                    &nbsp;&nbsp;
                    <>
                        {isFetchingProfileDetails ? (
                            <ListItem sx={{ width: "auto" }}>
                                <ListItemAvatar sx={{ minWidth: 0 }}>
                                    <Skeleton variant="circular" width={50} height={50} sx={{ backgroundColor: '#eeebf8' }} />
                                </ListItemAvatar>
                            </ListItem>
                        ) : (
                            <>
                                {
                                    selectedWorkspace &&
                                    <ListItem sx={{ width: "auto" }}>
                                        <ListItemAvatar sx={{ minWidth: 0 }}>
                                            <Avatar
                                                {
                                                    ...(selectedWorkspace?.logo
                                                    ? { src: selectedWorkspace?.logo }
                                                    : selectedWorkspace?.name?.includes('@')
                                                    ? stringToAvatarEmail(selectedWorkspace?.name)
                                                    : stringAvatar(selectedWorkspace?.name))
                                                }
                                                onClick={handleAvatarClick}
                                                style={{
                                                    cursor: "pointer",
                                                    width: 40,
                                                    height: 40,
                                                    backgroundColor: "#fff",
                                                    color: '#604CC3',
                                                    fontSize: 16,
                                                    alignItems: "center",
                                                    border: "5px solid #604CC3",
                                                    fontWeight: 'bold'
                                                }}
                                            />
                                        </ListItemAvatar>
                                    </ListItem>
                                }
                            </>
                        )}
                    </>

                </Toolbar>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    sx={{ mt: 1 }}
                >
                    <List sx={{ width: '100%', minWidth: 250, bgcolor: 'background.paper' }}>
                        {
                            selectedWorkspace &&
                            <ListItem sx={{cursor: "pointer"}} onClick={() => handleSwitchAccount(selectedWorkspace)}>
                                <ListItemAvatar sx={{ minWidth: 80 }}>
                                    <Avatar
                                        {
                                            ...(selectedWorkspace?.logo
                                                ? { src: selectedWorkspace?.logo }
                                                : selectedWorkspace?.name?.includes('@')
                                                ? stringToAvatarEmail(selectedWorkspace?.name)
                                                : stringAvatar(selectedWorkspace?.name))
                                        }
                                        style={{
                                            width: 35,
                                            height: 35,
                                            backgroundColor: "#fff",
                                            color: '#604CC3',
                                            fontSize: 16,
                                            alignItems: "center",
                                            border: "5px solid #604CC3",
                                            fontWeight: 'bold'
                                        }}
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={selectedWorkspace?.name}
                                />
                            </ListItem>
                        }
                        <Divider />
                        {
                            switchAccountList.filter((item) => item.name != selectedWorkspace.name).map((item) => {
                                return(
                                    <ListItem sx={{cursor: "pointer", px: 3}} onClick={() => handleSwitchAccount(item)}>
                                        <ListItemAvatar sx={{ minWidth: 80 }}>
                                            <Badge
                                                overlap="circular"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                badgeContent={
                                                    <CachedIcon sx={{bgcolor: 'white', borderRadius: '50%', color: "#604CC3"}} />
                                                }
                                                >
                                                <Avatar
                                                    {
                                                        ...(item.logo
                                                        ? { src: item.logo }
                                                        : item?.name?.includes('@')
                                                        ? stringToAvatarEmail(item.name)
                                                        : stringAvatar(item.name))
                                                    }
                                                    style={{
                                                        width: 25,
                                                        height: 25,
                                                        backgroundColor: "#fff",
                                                        color: '#604CC3',
                                                        fontSize: 12,
                                                        alignItems: "center",
                                                        border: "5px solid #604CC3",
                                                        fontWeight: 'bold'
                                                    }}
                                                />
                                            </Badge>
                                        </ListItemAvatar>
                                        <ListItemText
                                            sx={{fontSize: 12}}
                                            primary={item.name}
                                            // secondary={
                                            //     <b style={{ color: '#604CC3' }}>
                                            //         {walletBalance === 0 || walletBalance === 1 ? `${walletBalance} Token Left` : `${walletBalance} Tokens Left`}
                                            //     </b>
                                            // }
                                        />
                                    </ListItem>
                                )
                            })
                        }
                        <Divider />
                        <ListItem sx={{cursor: "pointer"}}>
                            <CustomButton 
                                fullWidth 
                                variant="outlined" 
                                startIcon={<SwitchAccountIcon />}
                                sx={{ textTransform: "capitalize", p: 2, mt: 2 }}
                                color="secondary"
                                label={"See all workspaces"}
                                onClick={handleRedirectToWorkspace}
                            />   
                        </ListItem>
                        <br />
                        <Divider sx={{ borderColor: 'rgba(96, 76, 195, 0.21)' }} />
                        <ListItemButton sx={{ pl: "36px !important" }} onClick={() => { navigate('/profile'); handleClose(); }}>
                            <ListItemIcon sx={{ minWidth: 40 }}>
                                <AccountCircleIcon sx={{ color: '#604CC3' }} />
                            </ListItemIcon>
                            <ListItemText primary="Profile" />
                        </ListItemButton>
                        {workspaceList?.length !== 0 &&
                            <ListItemButton sx={{ pl: "36px !important" }} disableRipple onClick={() => { navigate('/my-token'); handleClose(); }}>
                                <ListItemIcon sx={{ minWidth: 40 }}>
                                    <TokenIcon sx={{ color: '#604CC3' }} />
                                </ListItemIcon>
                                <ListItemText primary="My Token" />
                            </ListItemButton>
                        }
                        <ListItemButton sx={{ pl: "36px !important" }} onClick={() => { navigate('/workspaces'); handleClose(); }}>
                            <ListItemIcon sx={{ minWidth: 40 }}>
                                <img alt="" src={WorkspacesIcon} style={{ height: 22 }} />
                            </ListItemIcon>
                            <ListItemText primary="Workspaces" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: "36px !important" }} onClick={() => { navigate('/purchase-transactions'); handleClose(); }}>
                            <ListItemIcon sx={{ minWidth: 40 }}>
                                <HistoryIcon sx={{ color: '#604CC3' }} />
                            </ListItemIcon>
                            <ListItemText primary="My Purchase" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: "36px !important" }} onClick={() => { handleSignOut() }}>
                            <ListItemIcon sx={{ minWidth: 40 }}>
                                <img alt="" src={LogoutIcon} style={{ height: 22 }} />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItemButton>
                    </List>
                </Menu>

                {anchorEl2 &&
                    <ProfileCartMenu
                        anchorEl2={anchorEl2}
                        handleCloseCart={handleCloseCart}
                        filteredOrders={filteredOrders}
                        getListOrder={getListOrder}
                        isFetchingOrders={isFetchingOrders}
                        wallet_id={wallet_id}
                        isWorkspace={isWorkspace}
                        isEmail={isEmail}
                        profileData={profileData}
                        item_created={item_created}
                        order_created={order_created}
                        order_placed={order_placed}
                    />
                }

                {anchorEl3 &&
                    <NotificationScreen
                        anchorEl3={anchorEl3}
                        handleCloseNotification={handleCloseNotification}
                        notificationData={notificationData}
                        fetchingNotification={fetchingNotification}
                        getNotification={getNotification}
                        combinedNotifications={combinedNotifications}
                    />
                }

                {openTranferToken &&
                    <TransferTokenScreen
                        openTranferToken={openTranferToken}
                        handleCloseTransferTokenDialog={handleCloseTransferTokenDialog}
                        workspaceList={workspaceList}
                        isFetchingWorkspaceList={isFetchingWorkspaceList}
                        walletBalance={walletBalance}
                        isFetchingWalletBalance={isFetchingWalletBalance}
                    />
                }
            </AppBar>
            {console.log("walletBalancehereeeee", walletBalance)}
            {isFetchingWalletBalance ? 
            null 
            :
            (hideAlert && (
                    <Box
                      sx={{
                        position: "fixed",
                        bottom: 20, // Adjusts how far from the bottom the cards are
                        right: 20, // Adjusts how far from the right the cards are
                        display: "flex",
                        flexDirection: "column",
                        gap: 2, // Space between cards
                        zIndex: 1000, // Ensure visibility above other elements
                      }}
                    >
                      <Alert
                        variant="filled"
                        severity="warning"
                        sx={{ alignSelf: 'center', alignItems: 'center' }}
                        action={<IconButton onClick={handleCloseAlert}><CloseIcon /></IconButton>}
                      >
                        Tokens running low.
                        <Button
                          startIcon={<AddShoppingCartIcon />}
                          variant="contained"
                          sx={{ color: '#fff', textTransform: 'none', ml: 2, bgcolor: 'orange' }}
                        //   sx={{ color: '#604CC3', textTransform: 'none', ml: 2, border:  '1px solid #604CC3', background: '#fff' }}
                          onClick={() => navigate('/token')}
                        >
                          Buy Now
                        </Button>
                      </Alert>
                    </Box>
                  )
            )
            
            }
        </>
    );
}