import React, {useState} from "react";
import {ListItemIcon, ListItemText, Menu, MenuItem, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockedIcon from "../../../assets/icons/block.png";
import useMembersManagement from "../hooks/useMembersManagement";
import SendIcon from "../../../assets/icons/send.png";

const CustomMenuPopperComponent = (props) => {
  const {customIconStyles, data} = props;

  const {updateWorkspaceSigneeStatus, deleteWSGuestSignee, sendSigneeInvitation} = useMembersManagement();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }
    
  const handleClose = () => {
    setAnchorEl(null);
  }
  
  const handleUpdateStatus = (e, data, type) => {
    if (type === "activate") {
      let payload = {
        id: data?.id,
        workspace_id: data?.workspace_id,
        status: 1
      }

      updateWorkspaceSigneeStatus(payload);
      
    } else if (type === "inactivate") {
      let payload = {
        id: data?.id,
        workspace_id: data?.workspace_id,
        status: 0
      }

      updateWorkspaceSigneeStatus(payload);
    }
  }

  const handleRemoveSigneeClick = (e, data) => {
    const payload = {
      workspace_id: data?.workspace_id,
      email: data?.email
    }

    deleteWSGuestSignee(payload, data?.email);
  }

  const handleSendInvitation = (e, data) => {
    const payload = {
      id: data?.id,
      workspace_id: data?.workspace_id,
      role: "WSMember",
    }
    
    sendSigneeInvitation(payload, data?.email);
  }

  const menuItems = [
    ...(data.status === "Active" ? 
      [{
        icon: <img alt="" src={SendIcon} style={{height: 15, marginLeft: "4px"}} />,
        label: "Send Invitation",
        onClick: (e) => handleSendInvitation(e, data),
      }] 
    : 
      []),
    {
      icon: <CheckCircleIcon sx={{color: "#40BF7E", fontSize: 21}} />,
      label: "Activate Status",
      onClick: (e) => handleUpdateStatus(e, data, "activate"),
    },
    {
      icon: <img alt="" src={BlockedIcon} style={{height: 17, width: 17}} />,
      label: "Deactivate Status",
      onClick: (e) => handleUpdateStatus(e, data, "inactivate"),
    },
    {
      icon: <DeleteIcon fontSize="small" sx={{color: "#dd533e"}} />,
      label: "Remove Signee",
      onClick: (e) => handleRemoveSigneeClick(e, data),
    },
  ]
    
    
  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
    
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{paper: {style: {maxHeight: 300}}}}
      >
        {menuItems?.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleClose();
              item.onClick();
            }}
            sx={{display: (
                (item.label === "Activate Status" && data?.status === "Active") ||
                (item.label === "Deactivate Status" && data?.status === "Inactive") 
              ) ? "none" 
            : 
              {}}}
          >
            <ListItemIcon sx={customIconStyles}>
              {item.icon}
            </ListItemIcon>
    
            <ListItemText sx={{ml: "-3px"}} primary={item.label} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

const WorkspaceSigneesTable = (props) => {
  const {headers, data} = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }


  return (
    <Paper sx={{width: "100%", overflow: "hidden"}}>
      <TableContainer>
        <Table>
          <TableHead sx={{backgroundColor: "inherit", color: "inherit"}}>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell key={index} align="start" sx={{color: "inherit"}}>
                  {header}
                </TableCell>
              ))}                   
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.length > 0 ? (
              data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((signee) => (
                <TableRow key={signee.id}>
                  <TableCell>{signee.email}</TableCell>

                  <TableCell sx={{color: 
                      (signee.status === "Active") ? "#40BF7E" 
                    : 
                      (signee.status === "Inactive") ? "#FF0000" 
                    : 
                      "inherit"
                    }}
                  >
                    {signee.status}
                  </TableCell>

                  <TableCell align="start">
                    <CustomMenuPopperComponent data={signee} />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={headers?.length} align="center">
                  No data to display
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {data?.length !== 0 &&
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }
    </Paper>
  )
}

export default WorkspaceSigneesTable
