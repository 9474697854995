import React from "react";
import { Dialog, DialogContent, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";


const TokenExpiredDialog = (props) => {

    const gotoLink = () => {
        window.open("/", "_self")
    }

    return (
        <Dialog open={true} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{ style: { boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5 } }}>
            <DialogContent sx={{ padding: "30px 24px 35px 24px" }}>
                <Grid container justifyContent="center" direction="column" sx={{textAlign: 'center', margin: "auto"}}>
                    <Grid size={12} sx={{ margin: "auto" }}>
                        <Typography variant="h6" sx={{ fontWeight: "bold", color: "red" }}>
                            Link Expired
                        </Typography>
                    </Grid>


                    <Grid size={12} sx={{ margin: "auto" }}>
                        <Typography variant="subtitle2" sx={{ color: "#000", textAlign: 'center', fontSize: 13, fontWeight: 'normal', fontStyle: 'italic' }}>
                            {/* Please contact document creator to resend email. */}
                            Kindly check your email for the updated link. <br/>
                            If you are unable to find it, please contact the document creator to request a resent email. <br/>
                            Thank you.
                        </Typography>
                    </Grid>

                    <Grid size={12} sx={{ margin: "auto", mt: "22px" }}>
                        <Button variant="contained" sx={{ width: 220, backgroundColor: 'red', borderRadius: 10, boxShadow: 'none' }} onClick={gotoLink}>
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default TokenExpiredDialog