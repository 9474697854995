import { Avatar, Box, Card, CardContent, List, ListItem, ListItemAvatar, ListItemText, styled, Typography, useScrollTrigger } from "@mui/material";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import useOrderManagement from "../hooks/useOrderManagement";
import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid2';
import CustomTypography from '../../../core/components/ui/CustomTypography';
import CustomTableSkeletonLoaderComponent from "../../../core/components/common/custom-table-skeleton-loader.component";
import CustomTableComponent from "../../../core/components/common/custom-table.component";
import CustomDialog from "../../../core/components/common/custom-dialog.component";
import { format } from 'date-fns';
import tokenIcon from '../../../assets/icons/token.png';



const order_transactions_header = ["Order ID", "Order Status", "Last Date Modified"];

const CustomTimelineItem = styled(TimelineItem)(({ theme }) => ({
    '&:before': {
        content: 'none',
    },
}));

export default function OrderTransactionsScreen() { 
    return(
        <p></p>
    )
}

//     const { getListOrder, isFetchingOrders, orderList, viewOrder, orderLogs, orderData, orderDataArray } = useOrderManagement();


//     useEffect(() => { getListOrder(); }, [])



//     const [filteredTransactionsList, setFilteredTransactionsList] = useState();
//     const [openViewDetails, setOpenViewDetails] = useState(false);
//     const [order_id, setOrderId] = useState('');


//     useEffect(() => {
//         const modifiedTransactionList = orderList?.map((order) => ({
//             orderId: order.order_id || "N / A",
//             orderStatus: order.status || "N / A",
//             lastDateModified: order.updated_at || "N / A"
//         }));

//         setFilteredTransactionsList(modifiedTransactionList);
//     }, [orderList]);



//     const handleOpenViewDetailsDialog = (order_id) => {
//         setOpenViewDetails(true);
//         setOrderId(order_id);
//     };

//     const handleCloseViewDetailsDialog = () => {
//         setOpenViewDetails(false);
//     };

//     useEffect(() => {
//         if (order_id) {
//             let parameters = { "order_id": order_id }
//             viewOrder(parameters);
//         }
//     }, [order_id])


//     console.log("filteredTransactionsListxxx", filteredTransactionsList)
//     console.log("order_id xxxx", order_id)
//     console.log("orderLogs", orderLogs)
//     console.log("orderData", orderData)

//     const content = (
//         <Box>
//             <Card sx={{ marginBottom: 3, border: '1px solid #c7c0ea' }}>
//                 <CardContent sx={{ backgroundColor: '#604CC3', color: '#fff' }}>
//                     <Grid size={12} sx={{ textAlign: "start", mb: 0, pb: 0 }}>
//                         <CustomTypography
//                             text={"Order Logs"}
//                             customStyles={{ mb: 0, fontSize: '20px', fontWeight: 'bold', color: '#fff' }}
//                         />
//                     </Grid>
//                     {/* {orderLogs && orderLogs.history.map((item, index) => (
//                     <Grid container spacing={2}>
//                             <Grid size={12} sx={{ textAlign: "start", mb: 0, pb: 0 }}>Order ID : {item?.order_id}  </Grid>
//                             <Grid size={12} sx={{ textAlign: "start", mb: 0, pb: 0 }}>Last Date Modified : {item?.changed_at} </Grid>
//                             <Grid size={12} sx={{ textAlign: "start", mb: 0, pb: 0 }}>Status : {item?.status} </Grid>
//                         </Grid>
//                     ))} */}
//                     <Timeline position="right">
//                         {orderLogs && orderLogs.history.map((item, index) => (
//                             <CustomTimelineItem key={index}>
//                                 <TimelineSeparator>
//                                     <TimelineConnector />
//                                     <TimelineDot />
//                                     <TimelineConnector />
//                                 </TimelineSeparator>
//                                 <TimelineContent sx={{ py: '12px', px: 2 }}>
//                                     <Typography variant="body2">
//                                         {format(new Date(item && item?.changed_at), 'PPpp')}
//                                     </Typography>
//                                     <Typography sx={{ fontSize: 18 }}>
//                                         {item.status}
//                                     </Typography>
//                                     <Typography sx={{ fontSize: 14 }}>{item?.order_id}</Typography>
//                                 </TimelineContent>
//                             </CustomTimelineItem>
//                         ))}
//                     </Timeline>
//                 </CardContent>
//             </Card>

//             <Card sx={{ marginBottom: 3, border: '1px solid #c7c0ea' }}>
//                 <CardContent sx={{ backgroundColor: '#fff' }}>
//                     <Grid size={12} sx={{ textAlign: "start", mb: 0, pb: 0 }}>
//                         <CustomTypography
//                             text={"Order List"}
//                             customStyles={{ mb: 0, fontSize: '20px', fontWeight: 'bold', color: '#604CC3' }}
//                         />
//                     </Grid>
//                     <Grid size={12}>
//                         <Grid container spacing={2}>
//                             {orderDataArray.map((item, index) => (
//                                 <React.Fragment key={index}>
//                                     <Grid size={{ xs: 12, md: 6 }}>
//                                         <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', mt: 1 }}>
//                                             <ListItem alignItems="flex-start">
//                                                 <ListItemAvatar sx={{ minWidth: 50 }}>
//                                                     <Avatar alt="Package Icon" src={tokenIcon} sx={{ width: 40, height: 40 }} />
//                                                 </ListItemAvatar>
//                                                 <ListItemText
//                                                     secondary={
//                                                         <React.Fragment>
//                                                             <Typography
//                                                                 component="span"
//                                                                 variant="body2"
//                                                                 sx={{ color: 'text.primary', display: 'inline' }}
//                                                             >
//                                                                 {item.is_package ? item.package_code : item.packageName}
//                                                             </Typography>
//                                                             <br />
//                                                             {item.is_package ? item.quantity : item.token_amount} Tokens
//                                                         </React.Fragment>
//                                                     }
//                                                 />
//                                             </ListItem>
//                                         </List>
//                                     </Grid>
//                                     <Grid size={{ xs: 6, md: 3 }}>
//                                         <Typography sx={{ fontSize: 15 }}>Unit Price</Typography>
//                                         <Typography sx={{ mt: 1, fontSize: 14 }}>${item.price}</Typography>
//                                     </Grid>
//                                     <Grid size={{ xs: 6, md: 3 }}>
//                                         <Typography sx={{ fontSize: 15 }}>{item.is_package ? 'Quantity' : 'Token Amount'}</Typography>
//                                         <Typography sx={{ mt: 1, fontSize: 14 }}>{item.quantity}</Typography>
//                                     </Grid>
//                                 </React.Fragment>
//                             ))}
//                         </Grid>
//                     </Grid>
//                 </CardContent>
//             </Card>
//         </Box>
//     );

//     return (
//         <Box sx={{ margin: "0px auto auto" }}>
//             <Box sx={{ flexGrow: 1, margin: "0px auto auto" }}>
//                 <Grid container spacing={2}>
//                     <Grid size={12} sx={{ textAlign: "start", mb: 0, pb: 0 }}>
//                         <CustomTypography
//                             variant="h6"
//                             text={"ORDER HISTORY"}
//                             customStyles={{ mb: 0, textTransform: 'uppercase' }}
//                         />
//                     </Grid>
//                 </Grid>

//                 <br /> <br />

//                 {(isFetchingOrders) ?
//                     <CustomTableSkeletonLoaderComponent
//                         headers={order_transactions_header}
//                         hasAction={false}
//                     />
//                     :
//                     <CustomTableComponent
//                         headers={order_transactions_header}
//                         hasAction={false}
//                         data={filteredTransactionsList}
//                         handleOpenViewDetailsDialog={handleOpenViewDetailsDialog}
//                     />
//                 }

//             </Box>
//             <CustomDialog
//                 open={openViewDetails}
//                 onClose={handleCloseViewDetailsDialog}
//                 content={content}
//             />
//         </Box>
//     )

// }