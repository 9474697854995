import React, { useEffect, useState } from "react";
import CustomDialog from "../../../../core/components/ui/CustomDialog";
import {Box, CircularProgress} from "@mui/material";
import CustomTypography from "../../../../core/components/ui/CustomTypography";
import CustomTextField from "../../../../core/components/ui/CustomTextFieldV2";
import CustomDialogLoaderComponent from "../../../../core/components/common/custom-dialog-loader.component";
import useEnterpriseProjectManagement from "../../hooks/useEnterpriseProjectManagement";

const ChangeProjectKeysDialog = (props) => {
    const {open, setOpen, workspaceId} = props;

    const {projectAPIKeys, getProjectAPIKey, isChangingKeys, changeProjectKeys} = useEnterpriseProjectManagement();

    const [subKey, setSubKey] = useState("");
    const [apiKey, setApiKey] = useState("");


    useEffect(() => {
        if (workspaceId) {
            const parameters = {workspace_id: workspaceId}

            getProjectAPIKey(parameters);
        }
    }, [workspaceId])


    const handleTextfieldChange = (e, name) => {
        if (name === "apiKey") {
            setApiKey(e.target.value);
        } else {
            setSubKey(e.target.value);
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleChangeProjectKeys = () => {
        if ((subKey !== projectAPIKeys?.sub_key || apiKey !== projectAPIKeys?.api_key) && subKey && apiKey) {
            const payload = {
                workspace_id: workspaceId,
                subscription_key: subKey,
                api_key: apiKey
            }

            changeProjectKeys(payload, setOpen);
        }
    }

    const actions = [
        {
            label: "Update",
            onClick: () => {
                handleChangeProjectKeys();
            },
        }
    ]

    const globalSx = {
        actions: {padding: "15px 20px 30px 30px"}
    }


    return (
        <>
            <CustomDialog
                open={open}
                onClose={(isChangingKeys) ? null : handleClose}
                title="Change OVCODE API Key"
                content={
                    <Box>
                        {(isChangingKeys) ?
                            <CustomDialogLoaderComponent
                                icon={<CircularProgress size={40} sx={{ color: "#604CC3" }} />}
                                message={"Updating project keys..."}
                            />
                        :
                            <>
                                <CustomTypography
                                    variant="subtitle1"
                                    text={"Subscription Key"}
                                    customStyles={{ marginBottom: 0, fontWeight: 400, color: "#58565F", fontSize: 18 }}
                                />

                                <>
                                    <CustomTextField
                                        placeholder={"Subscription Key"}
                                        variant={"filled"}
                                        onChange={(e) => handleTextfieldChange(e, "subKey")}
                                        value={subKey}
                                        fullWidth={true}
                                        multiline={false}
                                        hiddenLabel
                                    />  <br /><br />
                                </>

                                <CustomTypography
                                    variant="subtitle1"
                                    text={"API Key"}
                                    customStyles={{ marginBottom: 0, fontWeight: 400, color: "#58565F", fontSize: 18, mt: "5px"}}
                                />

                                <CustomTextField
                                    placeholder={"API Key"}
                                    variant={"filled"}
                                    onChange={(e) => handleTextfieldChange(e, "apiKey")}
                                    value={apiKey}
                                    fullWidth={true}
                                    multiline={false}
                                    hiddenLabel
                                />
                            </>
                        }
                    </Box>
                }
                actions={(!isChangingKeys && actions) ? actions : []}
                maxWidth={"sm"}
                customStyles={{
                    actions: {
                        opacity: (!isChangingKeys && (subKey === projectAPIKeys?.sub_key && apiKey === projectAPIKeys?.api_key)) ? "50%" : (!subKey || !apiKey) ? "50%" : "100%",
                        cursor: (!isChangingKeys && (subKey === projectAPIKeys?.sub_key && apiKey === projectAPIKeys?.api_key)) ? "not-allowed" : (!subKey || !apiKey) ? "not-allowed" : "pointer",
                        visibility: (isChangingKeys) ? "hidden" : "visible",
                    }
                }}
                globalSx={globalSx}
            />
        </>
    )
}

export default ChangeProjectKeysDialog