import { Avatar, Box, IconButton, ListItem, ListItemAvatar, ListItemText, styled, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import CustomIconButton from "../../../../core/components/ui/CustomIconButton";
import superSignLogo from "../../../../assets/icons/supersign-logo.png";
import CustomButton from "../../../../core/components/ui/CustomButton";
import Grid from '@mui/material/Grid2';
import { stringAvatar, stringToAvatarEmail } from "../../../../core/utils/avatarImageUtil";
import { useNavigate } from "react-router-dom";
import { BorderColor } from "@mui/icons-material";
import { useEffect, useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ThreeDots } from "react-loader-spinner";
import useFillableElementsStore from "../zustand/useFillableElementsStore";
import { elementTypes } from "../common/staticVariables";
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';

const drawerWidth = 320;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
      },
    },
  ],
}));

export default function DocumentSigneeAppbarComponent({ hasContinued, signatoriesCounter, signedCounter, currentUser, handleOnClickFinish, isDeclineClicked,
  isLoading, handleContinue, handleClickOpen, isFinishClicked, documentLoaded, handleDrawerOpen, open
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const fillable_elements = useFillableElementsStore((state) => state.fillable_elements)
  const [filledElementCounter, setFilledElementCounter] = useState(0)

  const commonSpacing = { my: 2 };
  const commonPadding = { p: 1 };

  useEffect(() => {
    if (fillable_elements.length > 0) {
      console.log("fillable_elements xxxx", fillable_elements);
      let filled_element_counter = fillable_elements
        .filter((el) => (el.type === elementTypes.signatory && el.signature)
          || (el.type === "fillable_textfield" && el.el_value)
          || (el.type === "fillable_date" && el.el_value)).length
      console.log("filled_element_counter xxxx", filled_element_counter);
      setFilledElementCounter(filled_element_counter)
    }
  }, [JSON.stringify(fillable_elements)])

  function getRandomColor() {
    const letters = '0123456789';
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = Math.floor(Math.random() * 128);
      color += ('0' + value.toString(16)).slice(-2);
    }
    return color;
  }

  const avatarStyle = {
    cursor: "default",
    width: 50,
    height: 50,
    backgroundColor: getRandomColor(),
    color: '#fff',
    fontSize: 16,
    alignItems: "center",
    fontWeight: 'bold',
  };

  const isMobile = useMediaQuery('(max-width:600px)');

  console.log("signatoriesCounter here", signatoriesCounter)
  console.log("signedCounter here", signedCounter)
  console.log("hasContinued here", hasContinued)

  return (
    <AppBar position="fixed" open={open} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: 'white', boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 11%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 3%)' }} >
      <Toolbar sx={{ padding: '0px !important' }}>
        {hasContinued ?
          <>
            <CustomIconButton content={<img alt="" src={superSignLogo} style={{ height: 42 }} />} color={'inherit'} style={`mr: '2'`} />
            <Box sx={{ flexGrow: isMobile ? 0 : 1 }} />
            <Typography align="center" sx={{ color: "black", flexGrow: 1 }} noWrap component="div">
              <b style={{ fontSize: isMobile ? 12 : 18 }}>
                {
                  <>
                    {filledElementCounter == fillable_elements.length && <CheckCircleIcon sx={{ color: "#357a38", mr: 1, verticalAlign: "middle" }} />}
                    {filledElementCounter} / {fillable_elements.length} {filledElementCounter == fillable_elements.length ? "All required action completed" : "required action left"}
                  </>
                }
              </b>
            </Typography>
            <Box sx={{ flexGrow: isMobile ? 0 : 1 }} />
            {isMobile && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
            {!isMobile &&
              <>
                <CustomButton
                  label={'Decline'}
                  variant={'outlined'}
                  sx={{ height: isMobile ? 30 : 40, fontSize: isMobile ? 14 : 16, mr: isMobile ? 1 : 3, textTransform: 'uppercase', border: '1px solid red', color: 'red' }}
                  onClick={handleClickOpen}
                  disabled={isFinishClicked || !documentLoaded}
                />
                <CustomButton
                  label={'Finish & Submit'}
                  variant={'contained'}
                  sx={{ height: isMobile ? 30 : 40, fontSize: isMobile ? 14 : 16, textTransform: 'uppercase' }}
                  onClick={handleOnClickFinish}
                  startIcon={isLoading && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                  disabled={isDeclineClicked || !documentLoaded || filledElementCounter !== fillable_elements.length}
                />
              </>
            }
            {isMobile ? <></> : <> &nbsp; &nbsp;</>}
            {documentLoaded &&
              <Tooltip title="See More Details">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerOpen}
                  sx={[open && { height: isMobile ? 30 : 40, fontSize: isMobile ? 14 : 16, textTransform: 'uppercase', display: 'none' }]}
                >
                  <MenuIcon fontSize='large' sx={{ color: '#604CC3' }} />
                </IconButton>
              </Tooltip>
            }
            &nbsp;&nbsp;&nbsp;&nbsp;
          </>
        :
          <Box sx={{ flexGrow: 1, color: '#000' }}>
            <Grid container spacing={2} sx={{ textAlign: 'start', alignItems: 'center' }}>
              <Grid size={12}>
                <Typography sx={{ color: '#000', ...commonSpacing, ...commonPadding, fontSize: isMobile ? 16 : 22, marginBottom: 0, paddingBottom: 0, fontWeight: 'medium', paddingLeft: '32px', paddingRight: '24px' }}>
                  Please review & act on these documents
                </Typography>
              </Grid>
              <Grid size={12}>
                {currentUser &&
                  <ListItem sx={{ ...commonSpacing, ...commonPadding, marginTop: 0, paddingTop: 0, marginBottom: 1, paddingLeft: '32px', paddingRight: '24px' }}>
                    <ListItemAvatar>
                      <Avatar
                        alt="User Avatar"
                        {...stringToAvatarEmail(currentUser)}
                        style={avatarStyle}
                      />
                    </ListItemAvatar>
                    &nbsp;
                    <ListItemText primary={currentUser} />
                  </ListItem>
                }
              </Grid>
            </Grid>
            <Box sx={{ backgroundColor: '#DFDBF3', color: '#000', borderRadius: 0, width: '100%', paddingLeft: '32px', paddingRight: '24px' }}>
              <Grid container sx={{ pt: 1, pb: 1 }} >
                <Grid size={{ xs: 12, md: 6 }} sx={{ justifyContent: 'center', alignSelf: 'center' }}>
                  <Typography style={{ fontWeight: 'medium', fontSize:  16, marginLeft: 10, textAlign: 'start' }}>Please review the documents below.</Typography>
                </Grid>
                {currentUser && documentLoaded &&
                  <Grid size={{ xs: 12, md: 6 }} sx={{ justifyContent: 'flex-end', textAlign: 'end', alignSelf: 'center' }}>
                    <CustomButton
                      label={'Continue'}
                      variant={'contained'}
                      sx={{ fontSize:  14, mr: 2, height: 30, mb:  0.5, mt: isMobile ? 1.5 : 0 }}
                      onClick={handleContinue}
                    />
                    <CustomButton
                      label={'Decline'}
                      variant={'contained'}
                      sx={{ fontSize:  14, mr: isMobile ? 0 : 2, height: 30, mb: 0.5, border: '1px solid red', color: 'red', backgroundColor: '#fff', mt: isMobile ? 1.5 : 0  }}
                      onClick={handleClickOpen}
                      disabled={isFinishClicked}
                    />
                  </Grid>
                }
              </Grid>
            </Box>
          </Box>
        }
      </Toolbar>
    </AppBar>
  );
}
