import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"

const AlertDialogComponent = ({openAlertDialog, handleCloseAlertDialog, handleDelete, isLoadingDeleteSignatory}) => {
    return(
        <Dialog open={openAlertDialog} onClose={handleCloseAlertDialog}>
            <DialogTitle>Confirm Delete Signatory</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Are you sure you want delete? Removing Signee will remove also all its assigned fields. 
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseAlertDialog} color="primary">
                Cancel
            </Button>
            <Button disabled={isLoadingDeleteSignatory} startIcon={isLoadingDeleteSignatory ? <CircularProgress size={20} /> : null} onClick={handleDelete} color="error" autoFocus>
                Delete
            </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AlertDialogComponent